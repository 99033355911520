// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  Home,
  Signup,
  Login,
  CaregiverDefault,
  ForgotPassword,
  GoogleCalendar,
  ConfirmCoverage,
  Greminder,
  TermsAndConditions,
  Download,
} from './'

export default {
  path: 'auth',
  childRoutes: [
    { path: ':company/:type', component: Home, noSignIn: true }, // type is the campaign - default is used when there's no campaign
    { path: ':company/signup/:type', component: Signup, noSignIn: true },
    { path: ':company/login/:type', component: Login, noSignIn: true },
    {
      path: 'v2/:company/:type',
      component: CaregiverDefault,
      noSignIn: true,
    },
    { path: 'v2/:company/login/:type', component: Login, noSignIn: true },
    {
      path: ':company/forgot-password/:type',
      component: ForgotPassword,
      noSignIn: true,
    },
    {
      path: 'v2/:company/forgot-password/:type',
      component: ForgotPassword,
      noSignIn: true,
    },
    { path: 'calendar', component: GoogleCalendar },
    {
      path: ':company/confirm-coverage/:type',
      component: ConfirmCoverage,
      noSignIn: true,
    },
    { path: ':company/:type/calendar', component: GoogleCalendar },
    { path: 'greminder', component: Greminder },
    { path: 'terms-and-conditions', component: TermsAndConditions },
    { path: ':company/:type/download', component: Download },
  ],
}
