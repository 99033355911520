import React, { useCallback, useEffect, useRef } from 'react'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import useWindowSize from '../../../common/useWindowResize'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function Filters({ params, setParams }) {
  const { t, defaultLanguage } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const ref = useRef(null)
  useOutsideAlerter(ref, () => setOpen(false))
  const { isDesktop } = useWindowSize()

  const initialState = {
    eligibility: {
      title: t('coach_dashboard.eligibility'),
      open: false,
      options: [
        {
          name: t('coach_dashboard.eligible'),
          value: 'Eligible',
        },
        {
          name: t('coach_dashboard.ineligible'),
          value: 'Ineligible',
        },
      ],
      selected: new Set(),
      type: 'exclusive',
    },
    protocol: {
      title: t('provider_dashboard.alert_type'),
      open: false,
      options: [
        {
          name: t('provider_dashboard.mood'),
          value: 'mood',
        },
        {
          name: t('provider_dashboard.assessment'),
          value: 'assessment',
        },
      ],
      selected: new Set(),
      type: 'exclusive',
    },
    schedule: {
      title: t('coach_dashboard.calls'),
      open: false,
      options: [
        {
          name: t('coach_dashboard.has_call_scheduled'),
          value: 'has_call_scheduled',
        },
        {
          name: t('coach_dashboard.no_call_scheduled'),
          value: 'does_not_have_call',
        },
      ],
      selected: new Set(),
      type: 'exclusive',
    },
  }

  const [options, setOptions] = React.useState(initialState)
  const [filtersSize, setFiltersSize] = React.useState(0)

  // update options when language changes
  useEffect(() => {
    if (t('coach_dashboard.eligibility') !== options.eligibility.title) {
      setOptions(initialState)
    }
  }, [defaultLanguage])

  useEffect(() => {
    const size = Object.keys(options).reduce(
      (acc, key) => acc + options[key].selected.size,
      0
    )
    setFiltersSize(size)
  }, [options])

  const toggle = name => {
    setOptions({
      ...options,
      [name]: {
        ...options[name],
        open: !options[name].open,
      },
    })
  }

  const toggleOption = (name, value) => {
    if (options[name].type === 'exclusive') {
      // if the option is already selected, remove it
      if (options[name].selected.has(value)) {
        setOptions({
          ...options,
          [name]: {
            ...options[name],
            selected: new Set(),
          },
        })
        return
      }

      setOptions({
        ...options,
        [name]: {
          ...options[name],
          selected: new Set([value]),
        },
      })
      return
    }

    const selected = options[name].selected
    if (selected.has(value)) {
      selected.delete(value)
    } else {
      selected.add(value)
    }

    setOptions({
      ...options,
      [name]: {
        ...options[name],
        selected,
      },
    })
  }

  const applyFilters = useCallback(() => {
    const eligibility = Array.from(options.eligibility.selected).join(',')
    const alert_type = Array.from(options.protocol.selected).join(',')

    const has_call_scheduled = Array.from(options.schedule.selected).includes(
      'has_call_scheduled'
    )

    const does_not_have_call = Array.from(options.schedule.selected).includes(
      'does_not_have_call'
    )

    setParams(prevState => ({
      ...prevState,
      eligibility_status: eligibility ? eligibility : '',
      has_call_scheduled,
      does_not_have_call,
      alert_type: alert_type ? alert_type : '',
      reset: true,
    }))
    setOpen(false)
  }, [options, params, setParams])

  const clearFilters = useCallback(() => {
    setParams(prevState => ({
      ...prevState,
      eligibility_status: '',
      has_call_scheduled: false,
      does_not_have_call: false,
      alert_type: '',
      reset: true,
    }))
    setOptions(initialState)
    setOpen(false)
    // window.location.reload()
  }, [params, setParams, setOptions, initialState])

  return (
    <div
      className="search-container-wrapper"
      style={{
        width: !isDesktop ? 'min-content' : '6.0625rem',
      }}
    >
      <div
        className="search-container-filter"
        onClick={() => setOpen(!open)}
        style={{ backgroundColor: open ? '#D3D0CC' : '#FFFFFF' }}
      >
        <Icon name={'filter'} color={open ? '#908E87' : '#343434'} />

        <p
          className="search-container-filter-text"
          style={{ color: open ? '#908E87' : '#343434' }}
        >
          {t('coach_dashboard.refine')}{' '}
          {filtersSize > 0 && <>({filtersSize})</>}
        </p>
      </div>
      {open && (
        <div className="search-container-filter-container" ref={ref}>
          <div className="search-container-filter-container-header">
            <p>{t('coach_dashboard.refine')}</p>
          </div>

          <div className="search-container-filter-container-body">
            {Object.keys(options).map(key => (
              <div key={key}>
                <button
                  className="search-container-filter-container-body-item"
                  type="button"
                  onClick={() => toggle(key)}
                >
                  <p>
                    {options[key].title}{' '}
                    <strong>({options[key].selected.size})</strong>
                  </p>

                  <Icon
                    name="chevronRight"
                    size={6}
                    color="#555555"
                    style={{
                      transform: options[key].open && 'rotate(90deg)',
                      transition: 'all 0.3s ease',
                    }}
                  />
                </button>

                <div className="search-container-filter-container-body-item-options">
                  {options[key].open &&
                    options[key].options.map(option => (
                      <div
                        className="search-container-filter-container-body-item-options-item"
                        key={option.value}
                        onClick={() => toggleOption(key, option.value)}
                      >
                        <button type="button">
                          <Icon
                            name="checkbox"
                            active={options[key].selected.has(option.value)}
                          />
                        </button>
                        <p>{option.name}</p>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          <div className="search-container-filter-container-footer">
            <button
              className="search-container-filter-container-footer-button apply"
              type="button"
              onClick={applyFilters}
            >
              {t('coach_dashboard.apply_filters')}
            </button>
            <button
              className="search-container-filter-container-footer-button reset"
              type="button"
              onClick={clearFilters}
            >
              {t('coach_dashboard.reset_filters')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
