import React from 'react';

export default function EmailIcon({
  className,
  size,
  color = '#343434',
  style = {},
}) {
  return (
    <svg
      width={size * 1.42}
      height={size}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
    >
      <path
        d="M18 0.333252H1.99998C1.56193 0.345835 1.14526 0.525468 0.835388 0.835342C0.525513 1.14522 0.345896 1.56187 0.333313 1.99992V11.9999C0.333313 12.4419 0.508912 12.8659 0.821472 13.1784C1.13403 13.491 1.55795 13.6666 1.99998 13.6666H18C18.442 13.6666 18.8659 13.491 19.1785 13.1784C19.491 12.8659 19.6666 12.4419 19.6666 11.9999V1.99992C19.6486 1.56367 19.4672 1.15014 19.1585 0.8414C18.8498 0.532661 18.4362 0.351281 18 0.333252ZM18 1.66659C18.0438 1.66541 18.0873 1.67374 18.1276 1.691C18.1679 1.70826 18.2039 1.73405 18.2333 1.76659L10.6 7.46658L10.5333 7.53325C10.1902 7.66647 9.80973 7.66647 9.46665 7.53325L9.39996 7.46658L1.76666 1.76659C1.79602 1.73405 1.83205 1.70826 1.87233 1.691C1.91262 1.67374 1.95617 1.66541 1.99998 1.66659H18ZM18 12.3333H1.99998C1.95544 12.3362 1.91078 12.3296 1.869 12.3139C1.82722 12.2982 1.78931 12.2737 1.75775 12.2422C1.72619 12.2106 1.70172 12.1727 1.68601 12.1309C1.67031 12.0891 1.6637 12.0445 1.66665 11.9999V3.33325L8.56663 8.49992C8.99122 8.78256 9.48992 8.93336 9.99998 8.93336C10.51 8.93336 11.0087 8.78256 11.4333 8.49992L18.3333 3.33325V11.9999C18.3333 12.0883 18.2982 12.1731 18.2357 12.2356C18.1732 12.2981 18.0884 12.3333 18 12.3333Z"
        fill={color}
      />
    </svg>
  );
}
