import React from 'react'
import { Modal } from 'react-bootstrap'
import Icon from '../../../common/components/Icon'
export default function SuccessAdditionModal({
    title = 'Success!',
    description,
    onHide,
    show,
}) {
    return (
        <Modal className="success-addition-modal" show={show} onHide={onHide}>
            <SuccessIcon />
            <p className="success-addition-modal__title">{title}</p>
            <p className="success-addition-modal__description">{description}</p>
            <button onClick={onHide} className="success-addition-modal__close" type='button'>
                <Icon name={'close'} color={'#343434'} />
            </button>
        </Modal>
    )
}

const SuccessIcon = () => (
    <svg
        width="47"
        height="47"
        viewBox="0 0 47 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3760_20213)">
            <path
                d="M23.5005 45.8071C35.8203 45.8071 45.8076 35.8199 45.8076 23.5C45.8076 11.1801 35.8203 1.19287 23.5005 1.19287C11.1806 1.19287 1.19336 11.1801 1.19336 23.5C1.19336 35.8199 11.1806 45.8071 23.5005 45.8071Z"
                stroke="#A49F9E"
                stroke-width="2"
                stroke-miterlimit="10"
            />
            <path
                d="M15.0312 23.7386L21.115 29.9416L33.9983 17.0583"
                stroke="#EA734F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_3760_20213">
                <rect width="47" height="47" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
