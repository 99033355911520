import React, { useEffect } from 'react';

export default function useEntranceAnimation() {
  const [initialOpacity, setInitialOpacity] = React.useState(0);

  useEffect(() => {
    setTimeout(() => {
      setInitialOpacity(1);
    }, 500);
  }, []);

  return {
    initialOpacity,
  };
}
