export const relative = {
  years: '{years}Y',
  year: '1Y',
  months: '{months}MO',
  month: '1MO',
  weeks: '{weeks}W',
  week: '1W',
  day: '1D',
  days: '{days}D',
  hours: '{hours}H',
  minute: '1MIN',
  minutes: '{minutes}MIN',
  seconds: '{seconds}S',
  now: 'Now',
  never: 'Never',
};
