export const SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN =
  'SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN';
export const SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS =
  'SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE =
  'SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE';
export const SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR =
  'SUBSCRIPTIONS_GET_LIST_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR';
export const SUBSCRIPTIONS_CREATE_CONTRACT_BEGIN =
  'SUBSCRIPTIONS_CREATE_CONTRACT_BEGIN';
export const SUBSCRIPTIONS_CREATE_CONTRACT_SUCCESS =
  'SUBSCRIPTIONS_CREATE_CONTRACT_SUCCESS';
export const SUBSCRIPTIONS_CREATE_CONTRACT_FAILURE =
  'SUBSCRIPTIONS_CREATE_CONTRACT_FAILURE';
export const SUBSCRIPTIONS_CREATE_CONTRACT_DISMISS_ERROR =
  'SUBSCRIPTIONS_CREATE_CONTRACT_DISMISS_ERROR';
export const SUBSCRIPTIONS_UPDATE_CONTRACT_BEGIN =
  'SUBSCRIPTIONS_UPDATE_CONTRACT_BEGIN';
export const SUBSCRIPTIONS_UPDATE_CONTRACT_SUCCESS =
  'SUBSCRIPTIONS_UPDATE_CONTRACT_SUCCESS';
export const SUBSCRIPTIONS_UPDATE_CONTRACT_FAILURE =
  'SUBSCRIPTIONS_UPDATE_CONTRACT_FAILURE';
export const SUBSCRIPTIONS_UPDATE_CONTRACT_DISMISS_ERROR =
  'SUBSCRIPTIONS_UPDATE_CONTRACT_DISMISS_ERROR';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_BEGIN =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_BEGIN';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_SUCCESS =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_FAILURE =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_FAILURE';
export const SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_DISMISS_ERROR =
  'SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_CONTRACT_SUBSCRIPTION_DISMISS_ERROR';
('SUBSCRIPTIONS_UPDATE_CONTRACT_DISMISS_ERROR');
('SUBSCRIPTIONS_CREATE_CONTRACT_DISMISS_ERROR');
('SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR');
