import React from 'react'

export default function CircleBoxIcon({
    size = 13,
    color = '#D3D0CC',
    style = {},
    active,
}) {
    if (active) {
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ ...style }}
            >
                <circle cx="6.5" cy="6.5" r="6" stroke="#E8744F" />
                <circle cx="6.5" cy="6.5" r="4.5" fill="#E8744F" />
            </svg>
        )
    }

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...style }}
        >
            <circle cx="6.5" cy="6.5" r="6" fill="white" stroke={color} />
        </svg>
    )
}
