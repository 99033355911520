export const HOME_FETCH_AUTH_TOKEN_BEGIN = 'HOME_FETCH_AUTH_TOKEN_BEGIN';
export const HOME_FETCH_AUTH_TOKEN_SUCCESS = 'HOME_FETCH_AUTH_TOKEN_SUCCESS';
export const HOME_FETCH_AUTH_TOKEN_FAILURE = 'HOME_FETCH_AUTH_TOKEN_FAILURE';
export const HOME_FETCH_AUTH_TOKEN_DISMISS_ERROR =
  'HOME_FETCH_AUTH_TOKEN_DISMISS_ERROR';
export const HOME_RESET_PASSWORD_BEGIN = 'HOME_RESET_PASSWORD_BEGIN';
export const HOME_RESET_PASSWORD_SUCCESS = 'HOME_RESET_PASSWORD_SUCCESS';
export const HOME_RESET_PASSWORD_FAILURE = 'HOME_RESET_PASSWORD_FAILURE';
export const HOME_RESET_PASSWORD_DISMISS_ERROR =
  'HOME_RESET_PASSWORD_DISMISS_ERROR';
export const HOME_CONFIRM_RESET_PASSWORD_BEGIN =
  'HOME_CONFIRM_RESET_PASSWORD_BEGIN';
export const HOME_CONFIRM_RESET_PASSWORD_SUCCESS =
  'HOME_CONFIRM_RESET_PASSWORD_SUCCESS';
export const HOME_CONFIRM_RESET_PASSWORD_FAILURE =
  'HOME_CONFIRM_RESET_PASSWORD_FAILURE';
export const HOME_CONFIRM_RESET_PASSWORD_DISMISS_ERROR =
  'HOME_CONFIRM_RESET_PASSWORD_DISMISS_ERROR';
export const HOME_REGISTER_ACCOUNT_BEGIN = 'HOME_REGISTER_ACCOUNT_BEGIN';
export const HOME_REGISTER_ACCOUNT_SUCCESS = 'HOME_REGISTER_ACCOUNT_SUCCESS';
export const HOME_REGISTER_ACCOUNT_FAILURE = 'HOME_REGISTER_ACCOUNT_FAILURE';
export const HOME_REGISTER_ACCOUNT_DISMISS_ERROR =
  'HOME_REGISTER_ACCOUNT_DISMISS_ERROR';
export const HOME_UNSUBSCRIBE_USER_BEGIN = 'HOME_UNSUBSCRIBE_USER_BEGIN';
export const HOME_UNSUBSCRIBE_USER_SUCCESS = 'HOME_UNSUBSCRIBE_USER_SUCCESS';
export const HOME_UNSUBSCRIBE_USER_FAILURE = 'HOME_UNSUBSCRIBE_USER_FAILURE';
export const HOME_UNSUBSCRIBE_USER_DISMISS_ERROR =
  'HOME_UNSUBSCRIBE_USER_DISMISS_ERROR';
export const HOME_ACCEPT_COACH_INVITATION_BEGIN =
  'HOME_ACCEPT_COACH_INVITATION_BEGIN';
export const HOME_ACCEPT_COACH_INVITATION_SUCCESS =
  'HOME_ACCEPT_COACH_INVITATION_SUCCESS';
export const HOME_ACCEPT_COACH_INVITATION_FAILURE =
  'HOME_ACCEPT_COACH_INVITATION_FAILURE';
export const HOME_ACCEPT_COACH_INVITATION_DISMISS_ERROR =
  'HOME_ACCEPT_COACH_INVITATION_DISMISS_ERROR';
export const HOME_FETCH_COACH_INVITATION_BEGIN =
  'HOME_FETCH_COACH_INVITATION_BEGIN';
export const HOME_FETCH_COACH_INVITATION_SUCCESS =
  'HOME_FETCH_COACH_INVITATION_SUCCESS';
export const HOME_FETCH_COACH_INVITATION_FAILURE =
  'HOME_FETCH_COACH_INVITATION_FAILURE';
export const HOME_FETCH_COACH_INVITATION_DISMISS_ERROR =
  'HOME_FETCH_COACH_INVITATION_DISMISS_ERROR';
export const HOME_PAYMENT_CHECKOUT_BEGIN = 'HOME_PAYMENT_CHECKOUT_BEGIN';
export const HOME_PAYMENT_CHECKOUT_SUCCESS = 'HOME_PAYMENT_CHECKOUT_SUCCESS';
export const HOME_PAYMENT_CHECKOUT_FAILURE = 'HOME_PAYMENT_CHECKOUT_FAILURE';
export const HOME_PAYMENT_CHECKOUT_DISMISS_ERROR =
  'HOME_PAYMENT_CHECKOUT_DISMISS_ERROR';
export const HOME_NOBLE_COACH_INVITATION_BEGIN =
  'HOME_NOBLE_COACH_INVITATION_BEGIN';
export const HOME_NOBLE_COACH_INVITATION_SUCCESS =
  'HOME_NOBLE_COACH_INVITATION_SUCCESS';
export const HOME_NOBLE_COACH_INVITATION_FAILURE =
  'HOME_NOBLE_COACH_INVITATION_FAILURE';
export const HOME_NOBLE_COACH_INVITATION_DISMISS_ERROR =
  'HOME_NOBLE_COACH_INVITATION_DISMISS_ERROR';
export const HOME_CREATE_STRIPE_ACCOUNT_LINK_BEGIN =
  'HOME_CREATE_STRIPE_ACCOUNT_LINK_BEGIN';
export const HOME_CREATE_STRIPE_ACCOUNT_LINK_SUCCESS =
  'HOME_CREATE_STRIPE_ACCOUNT_LINK_SUCCESS';
export const HOME_CREATE_STRIPE_ACCOUNT_LINK_FAILURE =
  'HOME_CREATE_STRIPE_ACCOUNT_LINK_FAILURE';
export const HOME_CREATE_STRIPE_ACCOUNT_LINK_DISMISS_ERROR =
  'HOME_CREATE_STRIPE_ACCOUNT_LINK_DISMISS_ERROR';
export const HOME_REFRESH_STRIPE_ACCOUNT_LINK_BEGIN =
  'HOME_REFRESH_STRIPE_ACCOUNT_LINK_BEGIN';
export const HOME_REFRESH_STRIPE_ACCOUNT_LINK_SUCCESS =
  'HOME_REFRESH_STRIPE_ACCOUNT_LINK_SUCCESS';
export const HOME_REFRESH_STRIPE_ACCOUNT_LINK_FAILURE =
  'HOME_REFRESH_STRIPE_ACCOUNT_LINK_FAILURE';
export const HOME_REFRESH_STRIPE_ACCOUNT_LINK_DISMISS_ERROR =
  'HOME_REFRESH_STRIPE_ACCOUNT_LINK_DISMISS_ERROR';
export const HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_BEGIN =
  'HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_BEGIN';
export const HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_SUCCESS =
  'HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_SUCCESS';
export const HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_FAILURE =
  'HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_FAILURE';
export const HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_DISMISS_ERROR =
  'HOME_CHECK_STATUS_STRIPE_ACCOUNT_LINK_DISMISS_ERROR';
export const HOME_GET_INVOICES_STRIPE_BEGIN = 'HOME_GET_INVOICES_STRIPE_BEGIN';
export const HOME_GET_INVOICES_STRIPE_SUCCESS =
  'HOME_GET_INVOICES_STRIPE_SUCCESS';
export const HOME_GET_INVOICES_STRIPE_FAILURE =
  'HOME_GET_INVOICES_STRIPE_FAILURE';
export const HOME_GET_INVOICES_STRIPE_DISMISS_ERROR =
  'HOME_GET_INVOICES_STRIPE_DISMISS_ERROR';
export const HOME_GET_ACTIVE_SUBSCRIPTION_BEGIN =
  'HOME_GET_ACTIVE_SUBSCRIPTION_BEGIN';
export const HOME_GET_ACTIVE_SUBSCRIPTION_SUCCESS =
  'HOME_GET_ACTIVE_SUBSCRIPTION_SUCCESS';
export const HOME_GET_ACTIVE_SUBSCRIPTION_FAILURE =
  'HOME_GET_ACTIVE_SUBSCRIPTION_FAILURE';
export const HOME_GET_ACTIVE_SUBSCRIPTION_DISMISS_ERROR =
  'HOME_GET_ACTIVE_SUBSCRIPTION_DISMISS_ERROR';
export const HOME_CREATE_PRODUCT_SUBSCRIPTION_BEGIN =
  'HOME_CREATE_PRODUCT_SUBSCRIPTION_BEGIN';
export const HOME_CREATE_PRODUCT_SUBSCRIPTION_SUCCESS =
  'HOME_CREATE_PRODUCT_SUBSCRIPTION_SUCCESS';
export const HOME_CREATE_PRODUCT_SUBSCRIPTION_FAILURE =
  'HOME_CREATE_PRODUCT_SUBSCRIPTION_FAILURE';
export const HOME_CREATE_PRODUCT_SUBSCRIPTION_DISMISS_ERROR =
  'HOME_CREATE_PRODUCT_SUBSCRIPTION_DISMISS_ERROR';
export const HOME_CREATE_PRICE_SUBSCRIPTION_BEGIN =
  'HOME_CREATE_PRICE_SUBSCRIPTION_BEGIN';
export const HOME_CREATE_PRICE_SUBSCRIPTION_SUCCESS =
  'HOME_CREATE_PRICE_SUBSCRIPTION_SUCCESS';
export const HOME_CREATE_PRICE_SUBSCRIPTION_FAILURE =
  'HOME_CREATE_PRICE_SUBSCRIPTION_FAILURE';
export const HOME_CREATE_PRICE_SUBSCRIPTION_DISMISS_ERROR =
  'HOME_CREATE_PRICE_SUBSCRIPTION_DISMISS_ERROR';
export const HOME_GET_LIST_PRODUCT_SUBSCRIPTION_BEGIN =
  'HOME_GET_LIST_PRODUCT_SUBSCRIPTION_BEGIN';
export const HOME_GET_LIST_PRODUCT_SUBSCRIPTION_SUCCESS =
  'HOME_GET_LIST_PRODUCT_SUBSCRIPTION_SUCCESS';
export const HOME_GET_LIST_PRODUCT_SUBSCRIPTION_FAILURE =
  'HOME_GET_LIST_PRODUCT_SUBSCRIPTION_FAILURE';
export const HOME_GET_LIST_PRODUCT_SUBSCRIPTION_DISMISS_ERROR =
  'HOME_GET_LIST_PRODUCT_SUBSCRIPTION_DISMISS_ERROR';
