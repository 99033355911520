import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import useWindowSize from '../common/useWindowResize'
import Pill from './components/Pill'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'

export default function Home() {
    const { company: name, type } = useParams()

    const { getSettingsByName, settings: data } = useGetSettingsByName()

    React.useEffect(() => {
        getSettingsByName({ name, type })
    }, [name])

    useEffect(() => {
        if (data) {
            console.log('data', data)
        }
    }, [data])

    const isDesktop = useWindowSize().width > 768

    const { defaultLanguage } = useSetDefaultLanguage()

    useEffect(() => {
        // get element with .theme-light .theme-bg class and set background color to white
        const element = document.querySelector('.theme-light .theme-bg')
        if (element) {
            element.style.backgroundColor = 'white'
        }
    }, [])

    if (!data) return null

    return (
        <div className="auth-home">
            <div className="navbar">
                <img src={data.globals.logo} alt="Bloom Logo" />
                <div className="right-container">
                    {data.pages[defaultLanguage].home.content.CTA && (
                        <div className="questions">
                            <p>Have questions?</p>
                            <a href="#">
                                {' '}
                                <p className="schedule">
                                    {
                                        data.pages[defaultLanguage].home.content
                                            .CTA
                                    }
                                </p>
                            </a>
                        </div>
                    )}

                    <Link
                        to={
                            `/auth/${name}/login/${type}` +
                            '?lang=' +
                            defaultLanguage
                        }
                    >
                        <button
                            className="login-btn"
                            style={{
                                backgroundColor: data.globals.colors.primary,
                            }}
                        >
                            {
                                data.pages[defaultLanguage].home.content
                                    .login_button
                            }
                        </button>
                    </Link>
                </div>
            </div>
            <div className="content">
                <div className="left-side">
                    <h1>
                        {data.pages[defaultLanguage].home.content.main_text && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.pages[
                                        defaultLanguage
                                    ].home.content.main_text
                                        .split('<span>')
                                        .join(
                                            '<span style="text-decoration: underline; text-decoration-color: ' +
                                                data.globals.colors.secondary +
                                                '; font-weight: 400;">'
                                        ),
                                }}
                            />
                        )}
                    </h1>
                    {data &&
                        data.pages[defaultLanguage].home.content
                            .description && (
                            <p className="description">
                                {
                                    data.pages[defaultLanguage].home.content
                                        .description
                                }
                            </p>
                        )}
                    <Link
                        to={
                            data.pages[defaultLanguage]?.confirm_coverage
                                ? `/auth/${name}/confirm-coverage/${type}`
                                : `/auth/${name}/signup/${type}` +
                                  '?lang=' +
                                  defaultLanguage
                        }
                        style={{ maxWidth: 'max-content' }}
                    >
                        <button
                            className="login-btn"
                            style={{
                                backgroundColor: data.globals.colors.primary,
                            }}
                        >
                            {
                                data.pages[defaultLanguage].home.content
                                    .signup_button
                            }
                        </button>
                    </Link>
                    {isDesktop &&
                        data.pages[defaultLanguage].home.content.CTA2 && (
                            <Pill
                                image={
                                    data.pages[defaultLanguage].home.media
                                        .pill_illustration
                                }
                                color={data.globals.colors.tertiary}
                            />
                        )}
                </div>
                <img
                    src={data.pages[defaultLanguage].home.media.home_image}
                    alt="home-illustration"
                />
                {!isDesktop &&
                    data.pages[defaultLanguage].home.content.CTA2 && (
                        <Pill
                            image={
                                data.pages[defaultLanguage].home.media
                                    .pill_illustration
                            }
                            color={data.globals.colors.tertiary}
                        />
                    )}
            </div>
        </div>
    )
}

Home.propTypes = {}
Home.defaultProps = {}
