import React, { useEffect, useMemo } from 'react'
// import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import ReactPhoneInput from 'react-phone-input-2'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFetchAuthToken } from '../home/redux/fetchAuthToken'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSelfSignUp } from './redux/selfSignUp'
import { SignupSchema } from './schemas/SignupSchema'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'
import Icon from '../common/components/Icon'
import { useCreateEmployerCoverage } from './redux/createEmployerCoverage'
import SelfSignupProgress from './components/SelfSignupProgress'

export default function Signup() {
  const history = useHistory()

  const {
    getSettingsByName,
    settings: data,
    getSettingsByNamePending,
  } = useGetSettingsByName()

  const { createEmployerCoverage } = useCreateEmployerCoverage()

  const { defaultLanguage } = useSetDefaultLanguage()

  const urlParams = new URLSearchParams(window.location.search)
  let confirm_coverage = urlParams.get('confirm_coverage')

  if (confirm_coverage) {
    confirm_coverage = atob(confirm_coverage)
    confirm_coverage = JSON.parse(confirm_coverage)
    // console.log('confirm_coverage', confirm_coverage)
  }

  const { company: name, type } = useParams()

  useEffect(() => {
    getSettingsByName({ name, type })
    console.log('data campaign', data)
  }, [name])

  const [showPasswords, setShowPasswords] = React.useState({
    password: false,
    password2: false,
  })

  const { selfSignUp } = useSelfSignUp()
  const { fetchAuthToken } = useFetchAuthToken()

  const [error, setError] = React.useState('') // error from the backend
  const preventCopyPaste = e => {
    e.preventDefault()
  }

  const confirmCoverage = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const confirm_coverage = urlParams.get('confirm_coverage')
    if (!confirm_coverage) return null
    return JSON.parse(atob(confirm_coverage))
  }, [])

  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async values => {
    if (loading) return
    setLoading(true)
    let fields = {
      ...values,
      company: data.globals.companyId,
      is_signed_terms_and_conditions: values.terms,
      ...(!data.subscription.isDefaultStripe
        ? {
            // with contract
            company_alias: name,
            campaign_alias: type,
          }
        : {
            // stringified object
            creation_origin: {
              company_alias: name,
              ...(type === 'default' ? {} : { campaign_alias: type }),
            },
            company_alias: name,
            campaign_alias: type,
          }),

      /* extra: {
                insurance_coverage: confirm_coverage,
            }, */
    }

    selfSignUp({
      ...fields,
      withContract: !data.subscription.isDefaultStripe,
    })
      .then(() => {
        fetchAuthToken({
          email: values.email,
          password: values.password,
        }).then(() => {
          if (data.globals?.temp_stripe) {
            window.location.href = data.globals.temp_stripe
            return
          }

          if (!confirmCoverage) {
            if (data.subscription.isDefaultStripe) {
              history.push(
                `/subscriptions/default/${name}/${type}` +
                  '?lang=' +
                  defaultLanguage
              )
            } else {
              history.push(`/auth/${name}/${type}/calendar`)
            }
            return
          }

          createEmployerCoverage({
            employer_name: confirmCoverage.employer,
            subscriber_id_last_digits: confirmCoverage.last_4_digits,
            is_covered: confirmCoverage.insured,
          }).then(() => {
            if (data.subscription.isDefaultStripe) {
              history.push(
                `/subscriptions/default/${name}/${type}` +
                  '?lang=' +
                  defaultLanguage
              )
            } else {
              history.push(`/auth/${name}/${type}/calendar`)
            }
          })
        })
      })
      .catch(error => {
        if (error.response) {
          console.error(error.response)
          const errorKey = Object.keys(error.response.data)[0]
          setError(error.response.data[errorKey])
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!data) return
    if (data.test) return

    const checkbox = document.getElementById('terms')

    if (!checkbox) return

    checkbox.className = 'checkbox-styles'

    const checkboxStyles = document.createElement('style')
    checkboxStyles.innerHTML = `
      input[type='checkbox'] {
        height: max-content;
        margin-top: 0.15rem;
      }
      input[type='checkbox']::after {
        width: 11px;
        height: 11px;
        border: 1px solid ${data.globals.colors.secondary};
        border-radius: 2px;
        background-color: #fff;
      }
      input[type='checkbox']:checked::after {
        background-color: ${data.globals.colors.secondary};
      }
    `

    document.head.appendChild(checkboxStyles)
  }, [data])

  if (getSettingsByNamePending || !data) return null

  const signupSchema = SignupSchema(
    data.pages[defaultLanguage].sign_up.input_alerts
  )

  if (!signupSchema) return null

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        //alert(JSON.stringify(values, null, 2));
        handleSubmit(values)
        setSubmitting(false)
      }}
      validationSchema={signupSchema}
    >
      {props => (
        <div className="auth-signup">
          <div className="auth-signup-navbar">
            <div className="auth-signup-navbar__logo-container">
              <Link to={`/auth/${name}/${type}` + '?lang=' + defaultLanguage}>
                <img src={data.globals.logo} alt="logo" />
              </Link>
            </div>

            <SelfSignupProgress settings={data} />
          </div>
          <div className="content-container">
            {data.pages[defaultLanguage].sign_up.media.background_image && (
              <img
                src={data.pages[defaultLanguage].sign_up.media.background_image}
                alt="background"
                className="background-image"
              />
            )}

            {data.pages[defaultLanguage].sign_up.media?.background_video && (
              <video autoPlay muted loop className="video-background">
                <source
                  src={
                    data.pages[defaultLanguage].sign_up.media.background_video
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <div className="signup-container">
              <h1>
                {data.pages[defaultLanguage].sign_up.content.welcome_title}
              </h1>
              <p>
                {data.pages[defaultLanguage].sign_up.content.welcome_message}
              </p>
              <Form>
                <div className="form-group">
                  <Field
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder={
                      data.pages[defaultLanguage].sign_up.input_placeholders
                        .first_name + ' *'
                    }
                  />
                  {props.errors.first_name && props.touched.first_name && (
                    <p className="error-message">
                      <ErrorMessage name="first_name" />
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <Field
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder={
                      data.pages[defaultLanguage].sign_up.input_placeholders
                        .last_name + ' *'
                    }
                  />{' '}
                  {props.errors.last_name && props.touched.last_name && (
                    <p className="error-message">
                      <ErrorMessage name="last_name" />
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder={
                      data.pages[defaultLanguage].sign_up.input_placeholders
                        .email + ' *'
                    }
                    onCopy={e => preventCopyPaste(e)}
                    onPaste={e => preventCopyPaste(e)}
                    onCut={e => preventCopyPaste(e)}
                    onChange={e => {
                      props.setFieldValue('email', e.target.value.toLowerCase())
                    }}
                  />{' '}
                  {props.errors.email && props.touched.email && (
                    <p className="error-message">
                      <ErrorMessage name="email" />{' '}
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <Field
                    type="email"
                    name="confirm_email"
                    id="confirm_email"
                    placeholder={
                      data.pages[defaultLanguage].sign_up.input_placeholders
                        .confirm_email + ' *'
                    }
                    onChange={e => {
                      props.setFieldValue(
                        'confirm_email',
                        e.target.value.toLowerCase()
                      )
                    }}
                    onCopy={e => preventCopyPaste(e)}
                    onPaste={e => preventCopyPaste(e)}
                    onCut={e => preventCopyPaste(e)}
                  />{' '}
                  {props.errors.confirm_email && props.touched.confirm_email && (
                    <p className="error-message">
                      <ErrorMessage name="confirm_email" />{' '}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <div className="password-container">
                    <Field
                      type={showPasswords.password ? 'text' : 'password'}
                      name="password"
                      id="password"
                      onClick={e => {
                        props.setFieldTouched('password', true)
                      }}
                      handleBlur={props.handleBlur}
                      placeholder={
                        data.pages[defaultLanguage].sign_up.input_placeholders
                          .password + ' *'
                      }
                      onCopy={e => preventCopyPaste(e)}
                      onPaste={e => preventCopyPaste(e)}
                      onCut={e => preventCopyPaste(e)}
                    />
                    <button
                      onClick={() =>
                        setShowPasswords({
                          ...showPasswords,
                          password: !showPasswords.password,
                        })
                      }
                      role="button"
                    >
                      <Icon
                        name="eye"
                        size={20}
                        color="#343434"
                        active={showPasswords.password}
                      />
                    </button>
                  </div>
                  {props.touched.password && (
                    <p className="error-message">
                      <ErrorMessage name="password" />
                    </p>
                  )}
                </div>
                <div className="form-group">
                  <div className="password-container">
                    <Field
                      type={showPasswords.password2 ? 'text' : 'password'}
                      name="password2"
                      id="password2"
                      placeholder={
                        data.pages[defaultLanguage].sign_up.input_placeholders
                          .confirm_password + ' *'
                      }
                      onCopy={e => preventCopyPaste(e)}
                      onPaste={e => preventCopyPaste(e)}
                      onCut={e => preventCopyPaste(e)}
                    />{' '}
                    <button
                      onClick={() =>
                        setShowPasswords({
                          ...showPasswords,
                          password2: !showPasswords.password2,
                        })
                      }
                      role="button"
                    >
                      <Icon
                        name="eye"
                        size={20}
                        color="#343434"
                        active={showPasswords.password2}
                      />
                    </button>
                  </div>

                  {props.errors.password2 && (
                    <p className="error-message">
                      <ErrorMessage name="password2" />
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <ReactPhoneInput
                    defaultCountry="us"
                    preferredCountries={['us']}
                    value={props.values.phone_number}
                    placeholder={
                      data.pages[defaultLanguage].sign_up.input_placeholders
                        .phone_number + ' *'
                    }
                    inputExtraProps={{
                      autocomplete: 'off',
                      name: 'phone_number',
                      id: 'phone_number',
                    }}
                    onClick={e => {
                      props.setFieldTouched('phone_number', true)
                    }}
                    onChange={phoneNumber => {
                      let phoneNumberString = phoneNumber.replace(/[() -]/g, '')
                      // if it doesn't have a +, add +1 to the beginning
                      props.setFieldValue('phone_number', phoneNumberString)
                      console.log('phoneNumber', props.values.phone_number)
                    }}
                    enableTerritories={false}
                    enableAreaCodes={true}
                    areaCodes={{
                      us: [],
                    }}
                  />
                  {props.touched.phone_number && (
                    <p className="error-message">
                      <ErrorMessage name="phone_number" />
                    </p>
                  )}
                </div>

                {/*   <ul className="password-requirements">
                  <p className="title">Your password must contain</p>
                  <li className="password-requirements__item">
                    <span className="password-requirements__item__icon">+</span>
                    <p className="password-requirements__item__text">
                      8 or more characters
                    </p>
                  </li>

                  <li className="password-requirements__item">
                    <span className="password-requirements__item__icon">+</span>
                    <p className="password-requirements__item__text">
                      uppercase letters
                    </p>
                  </li>
                  <li className="password-requirements__item">
                    <span className="password-requirements__item__icon">+</span>
                    <p className="password-requirements__item__text">
                      1 or more numbers/symbols
                    </p>
                  </li>
                  <li className="password-requirements__item">
                    <span className="password-requirements__item__icon">+</span>
                    <p className="password-requirements__item__text">
                      lowercase letters
                    </p>
                  </li>
                </ul> */}
                <div className="terms-check">
                  <Field type="checkbox" name="terms" id="terms" />
                  <div className="terms-container">
                    <p
                      className="agree"
                      style={{
                        color: data.globals.colors.primary,
                      }}
                      onClick={() =>
                        props.setFieldValue('terms', !props.values.terms)
                      }
                    >
                      {data.pages[defaultLanguage].sign_up.content.tos_message}
                    </p>
                    <p>
                      <a
                        href={
                          data.pages[defaultLanguage].sign_up.content.terms_link
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.pages[defaultLanguage].sign_up.content.terms_text}
                      </a>{' '}
                      {data.pages[defaultLanguage].sign_up.content.concatenator}{' '}
                      <a
                        href={
                          data.pages[defaultLanguage].sign_up.content
                            .privacy_link
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {
                          data.pages[defaultLanguage].sign_up.content
                            .privacy_text
                        }
                      </a>
                    </p>
                    {props.errors.terms && props.touched.terms && (
                      <p className="error-message">
                        <ErrorMessage name="terms" />
                      </p>
                    )}
                  </div>
                </div>
                <div className="terms-check">
                  <Field type="checkbox" name="terms" id="terms" />
                  <div className="terms-container">
                    <p
                      className="agree"
                      style={{
                        color: data.globals.colors.primary,
                      }}
                      onClick={() =>
                        props.setFieldValue('terms', !props.values.terms)
                      }
                    >
                      I agree to receive SMS notifications for account updates
                      and reminders.
                    </p>
                  </div>
                </div>
                {error && <p className="error-message">{error}</p>}
                <button
                  type="submit"
                  className="signup-btn"
                  disabled={props.isSubmitting}
                  style={{
                    backgroundColor: data.globals.colors.primary,
                  }}
                >
                  {
                    data.pages[defaultLanguage].sign_up.content
                      .create_account_button
                  }
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}

Signup.propTypes = {}
Signup.defaultProps = {}

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password2: '',
  phone_number: '',
  terms: false,
}
