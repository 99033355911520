import React, { useEffect, useContext } from 'react';
import { CompetencyContext } from '../../CompetencyPage';

export default function NextCompetency() {
  const {
    selectedCompetency,
    setNextCompetency,
    setPrevCompetency,
    selectedCompetencyIndex,
    allStages,
    nextCompetency,
    prevCompetency,
  } = useContext(CompetencyContext);

  // if selectedCompetency.next is null, set nextCompetency to selectedCompetencyIndex + 1
  useEffect(() => {
    if (selectedCompetency) {
      if (
        !selectedCompetency.next &&
        selectedCompetencyIndex !== -1 &&
        allStages.length > selectedCompetencyIndex + 1
      ) {
        setNextCompetency(
          allStages[selectedCompetencyIndex + 1]['competencies'][0],
        );
        console.log('nextCompetency', nextCompetency);
      }

      if (
        !selectedCompetency.prev &&
        selectedCompetencyIndex !== -1 &&
        selectedCompetencyIndex > 0
      ) {
        setPrevCompetency(
          allStages[selectedCompetencyIndex - 1]['competencies'][
            allStages[selectedCompetencyIndex - 1]['competencies'].length - 1
          ],
        );
        console.log('prevCompetency', prevCompetency);
      }
    }
  }, [selectedCompetency, selectedCompetencyIndex]);
  return null;
}
