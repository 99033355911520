import React from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import nobleLogo from '../../images/noble-logo-circle.png';

export default function InfoModal({ show, onHide }) {
  const reactAppVersion = process.env.REACT_APP_VERSION;
  const reactAppName = process.env.REACT_APP_NAME;

  return (
    <Modal className="modal-info" centered show={show} onHide={onHide}>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={5} className="modal-info-noble-logo">
              <img src={nobleLogo} alt="Noble" />
            </Col>
            <Col xs={7}>
              <h4>Noble App</h4>
              <p>
                {/* Version <strong>{reactAppVersion}</strong> */}
                Version <strong>2.1.0</strong>
              </p>
              <p>
                Release Date: <strong>February 09th, 2023</strong>
              </p>
              <h6>What's new?</h6>
              <ul>
                <li>RTM User Profile</li>
                <li>RTM Coach Dashboard</li>
                <li>Edit and Delete Assessment and CPT code records</li>
                <li>Alerts for critical scores</li>
                <li>Tooltips for Assessment scores</li>
                <li>Fixed bugs and better site optimization</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

InfoModal.propTypes = {};
InfoModal.defaultProps = {};
