import React from 'react';
import { Modal } from 'react-bootstrap';

export default function SuccessModal({
  title = 'Success',
  toggleModal,
  show,
  setShowSuccess,
}) {
  return (
    <Modal show={show} className="success-modal" centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Invitation sent to caregiver</p>
      </Modal.Body>

      <button
        onClick={() => {
          setShowSuccess(false);
          toggleModal();
        }}
      >
        Close
      </button>
    </Modal>
  );
}
