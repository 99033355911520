import { useCallback } from 'react'
import { useCreateAssessmentScore } from '../../../../redux/createAssessmentScore'
import { useFetchUnifiedSummary } from '../../../../redux/fetchUnifiedSummary'
import { useUpdateAssessment } from '../../../../redux/updateAssessment'
import { isValidDate } from '../../../../utils/isValidDate'

export default function useHandleSubmit({
    onHide,
    userId,
    setParams,
    update = false,
    setShowSuccessModal,
}) {
    const { createAssessmentScore } = useCreateAssessmentScore()
    const { fetchUnifiedSummary } = useFetchUnifiedSummary()
    const { updateAssessment } = useUpdateAssessment()

    const handleSubmit = useCallback(
        async (values, { setFieldError }) => {
            if (isValidDate(values.year, values.month, values.day) === false) {
                setFieldError('date', 'Invalid date')
                return
            }

            if (update) {
                const res = await updateAssessment({
                    id: values.id,
                    assessment_id: values.assessment_id,
                    score: values.score,
                    assessment_result_link: values.assessment_result_link,
                    date: `${values.year}-${values.month}-${values.day}`,
                    user_id: userId,
                })

                if (res) {
                    setParams(params => ({
                        ...params,
                        page: 1,
                        resetResults: true,
                    }))
                    setShowSuccessModal(true)
                    onHide()
                }
                return
            }

            const res = await createAssessmentScore({
                assessment_id: values.assessment_id,
                score: values.score,
                assessment_result_link: values.assessment_result_link,
                date: `${values.year}-${values.month}-${values.day}`,
                user_id: userId,
            })

            if (res) {
                setParams(params => ({
                    ...params,
                    page: 1,
                    resetResults: true,
                }))
                setShowSuccessModal(true)
                onHide()
            }
        },
        [
            createAssessmentScore,
            fetchUnifiedSummary,
            onHide,
            userId,
            setParams,
            update,
            updateAssessment,
        ]
    )

    return { handleSubmit }
}
