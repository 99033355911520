import React from 'react';

export default function LocationIcon({
  className = '',
  size = 12,
  color = 'var(--primary-dark-green)',
  style = {},
}) {
  return (
    <svg
      width={size}
      height={size * 1.3}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
    >
      <g clipPath="url(#clip0_192_801)">
        <path
          d="M5.85684 4.01965C5.35885 4.01972 4.8763 4.20889 4.49138 4.55493C4.10647 4.90097 3.84302 5.38247 3.74591 5.91739C3.64881 6.45231 3.72406 7.00756 3.95884 7.48853C4.19362 7.96951 4.57341 8.34644 5.0335 8.55512C5.49359 8.7638 6.00552 8.79132 6.48205 8.63298C6.95859 8.47463 7.37025 8.14023 7.64691 7.68675C7.92356 7.23327 8.04809 6.68875 7.99927 6.14599C7.95046 5.60323 7.73132 5.09579 7.37919 4.71014C6.97539 4.26804 6.42781 4.01968 5.85684 4.01965ZM5.85684 7.64697C5.58865 7.64695 5.32877 7.54509 5.12147 7.35874C4.91416 7.17239 4.77227 6.91308 4.71997 6.62501C4.66767 6.33693 4.70819 6.0379 4.83463 5.77887C4.96106 5.51984 5.1656 5.31684 5.41338 5.20446C5.66116 5.09207 5.93685 5.07726 6.19349 5.16254C6.45013 5.24782 6.67182 5.42791 6.82081 5.67214C6.96979 5.91637 7.03685 6.20961 7.01055 6.50192C6.98424 6.79422 6.86621 7.06749 6.67657 7.27517C6.45912 7.51319 6.16428 7.64692 5.85684 7.64697Z"
          fill={color}
        />
        <path
          d="M5.87513 0.00368463H5.78642C4.69725 0.0171747 3.63321 0.363769 2.71435 1.00436C1.79548 1.64495 1.05837 2.55404 0.586195 3.62905C0.114015 4.70406 -0.0744424 5.90219 0.0420877 7.08827C0.158618 8.27435 0.575497 9.40115 1.24569 10.3415L1.26195 10.3632L5.43156 15.6228C5.4787 15.6802 5.5363 15.726 5.60064 15.7573C5.66498 15.7887 5.73462 15.8049 5.80505 15.8049C5.87547 15.8049 5.94511 15.7887 6.00945 15.7573C6.07379 15.726 6.1314 15.6802 6.17854 15.6228L10.3963 10.3632L10.4147 10.3399C11.0851 9.39954 11.5022 8.27267 11.6189 7.08646C11.7357 5.90026 11.5474 4.70193 11.0753 3.62671C10.6032 2.55148 9.86609 1.64216 8.94719 1.00135C8.02828 0.360545 6.96414 0.013757 5.87484 0.00012207L5.87513 0.00368463ZM9.64079 9.65494L5.81037 14.4352L2.02313 9.65818C1.46589 8.87663 1.12034 7.93934 1.02588 6.95316C0.93141 5.96697 1.09183 4.97155 1.48884 4.08037C1.88585 3.18918 2.50348 2.43807 3.27134 1.91266C4.03921 1.38725 4.9264 1.10866 5.83181 1.10866C6.73722 1.10866 7.62442 1.38725 8.39228 1.91266C9.16014 2.43807 9.77777 3.18918 10.1748 4.08037C10.5718 4.97155 10.7322 5.96697 10.6377 6.95316C10.5433 7.93934 10.1977 8.87663 9.6405 9.65818L9.64079 9.65494Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_192_801">
          <rect
            width="11.6433"
            height="15.8025"
            fill="white"
            transform="translate(0.00927734 0.00012207)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
