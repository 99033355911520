import React from 'react';
import Icon from '../../../../common/components/Icon';

export default function FilterToastItem({
  setToastState,
  toastState,
  setName,
  switchName,
  title,
}) {
  return (
    <div
      className="filter-toast-item"
      onClick={() =>
        setToastState({
          ...toastState,
          [switchName]: !toastState[switchName],
        })
      }
    >
      <p>
        {title}{' '}
        <strong>
          (
          {toastState[setName].has('canceled')
            ? toastState[setName].size - 3
            : toastState[setName].size}
          )
        </strong>
      </p>
      <div
        style={{
          transform: toastState[switchName] ? 'rotate(90deg)' : 'rotate(0deg)',
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <Icon name="chevronRight" size={5} color="#555555" />
      </div>
    </div>
  );
}
