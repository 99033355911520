import React, { useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import Icon from '../../common/components/Icon';

export default function SubscriptionTable({
  columns,
  data,
  title,
  modal,
  onRowClick,
}) {
  const Modal = modal;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);

  return (
    <div className="subscriptions-internal-subscriptions-main-content">
      <div className="main-header">
        <p>{title}s</p>
        <button onClick={handleShow} className="add-sub ">
          <Icon name="plus" size={12} color="#FFFFFF" />
          <span>Add {title}</span>
        </button>
      </div>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={onRowClick ? onRowClick(row.original) : () => {}}
                style={{
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
              >
                {row.cells.map((cell) => {
                  if (cell.column.id === 'active') {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.value ? 'Active' : 'Inactive'}
                      </td>
                    );
                  }
                  return (
                    <td {...cell.getCellProps()}>{<div>{cell.value}</div>}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal showModal={showModal} setShowModal={() => setShowModal(false)} />
    </div>
  );
}

SubscriptionTable.propTypes = {};
SubscriptionTable.defaultProps = {};
