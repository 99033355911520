import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import Icon from '../../../common/components/Icon';
import ProgressBar from '../../../dashboard/components/ClientsCard/ProgressBar';
import { useFetchRoadmaps } from '../../../dashboard/redux/fetchRoadmaps';
import { useFetchUser } from '../../../user/redux/fetchUser';
import RoadmapsContainerSkeleton from './RoadmapsContainerSkeleton';
export default function RoadmapsContainer() {
  const { roadmaps, fetchRoadmapsPending, fetchRoadmaps } = useFetchRoadmaps();
  const { user, fetchUser } = useFetchUser();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchRoadmaps({
      assignedCoaches: true,
      asStudent: true,
    });
  }, [fetchRoadmaps]);

  useEffect(() => {
    console.log('roadmaps', roadmaps);
  }, [roadmaps]);

  useEffect(() => {
    const roadmapsGrid = document.querySelector('.roadmaps-container');
    // modify the class of the roadmaps container
    const roadmapContainers = document.querySelectorAll('.roadmap-container');
    if (roadmapContainers && roadmapsGrid) {
      roadmapContainers.forEach(roadmapContainer => {
        roadmapContainer.style['min-width'] = `${(roadmapsGrid.offsetWidth / 3) - 23}px`;
      });
    }
  }, [fetchRoadmapsPending, roadmaps]);

  const [roadmapCardWidth, setRoadmapCardWidth] = useState(0);

  useEffect(() => {
    const roadmapCard = document.querySelector('.roadmap-container');
    if (roadmapCard) {
      setRoadmapCardWidth(roadmapCard.offsetWidth);
    }
  }, [roadmaps]);


  if (fetchRoadmapsPending || !user) {
    return <RoadmapsContainerSkeleton />;
  }

  return (
    <div className="roadmaps-container">
      <header>
        <div className="roadmaps-container-left">
          <h3>Hi, {user.first_name}!</h3>
          <p> Let's learn something new today! </p>
        </div>
        <button className="browse-all">
          <p>Browse all</p>
          <Icon name="arrow-right" color="#E8744F" size={13} />
        </button>
      </header>

      <button
        className="roadmaps-container-arrow"
        onClick={() => setCurrentIndex(currentIndex - 1)}
        disabled={currentIndex === 0}
      >
        {' < '}
      </button>
      <button
        className="roadmaps-container-arrow arrow-right"
        onClick={() => setCurrentIndex(currentIndex + 1)}
        disabled={
          roadmaps &&
          roadmaps.results
            .filter((r) => r?.is_published).length <= currentIndex + 3
        }
      >
        {' > '}
      </button>

      <div
        className="roadmaps-container-grid"
        style={{
          // move 266px to the left for each card
          transform: `translateX(-${currentIndex * (roadmapCardWidth + 16)}px)`,
          transition: 'transform 0.5s ease',
        }}
      >


        {roadmaps &&
          roadmaps.results
            .filter((r) => r?.is_published)
            .sort((a, b) => b?.stats.total_progress - a?.stats.total_progress)
            .map((roadmap, index) => (
              <div className="roadmap-container" key={index}
                style={{
                  opacity: currentIndex - index - 1 > 1 || index - currentIndex - 1 > 1 || index < currentIndex ? 0 : 1,
                  transition: 'opacity 0.5s ease',
                }}
              >
                <div
                  className="roadmap-container-header"
                  style={{
                    backgroundImage: `url(${roadmap?.icon})`,
                    backgroundColor: '#517282',
                  }}
                />
                <div className="roadmap-container-footer">
                  <p className="title">{roadmap.title}</p>
                  <ProgressBar
                    percentage={roadmap.stats.total_progress}
                    color="#E8744F"
                  />

                  <button className="continue-btn">
                    <svg
                      width="11"
                      height="13"
                      viewBox="0 0 11 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2696 5.63931C10.9241 6.02646 10.9241 6.97354 10.2696 7.36069L6.22672 9.75216L2.23294 12.1096C1.56632 12.5031 0.724609 12.0226 0.724609 11.2485V6.5V1.75151C0.724609 0.977418 1.56632 0.496854 2.23294 0.890353L6.22672 3.24784L10.2696 5.63931Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}

      </div>

      <div className="roadmaps-container-steps">
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <button
              className="roadmaps-container-step"
              onClick={() => setCurrentIndex(i)}
              style={{
                backgroundColor: currentIndex === i ? '#343434' : '#D3D0CC',
              }}
            />
          ))}
      </div>
    </div>
  );
}
