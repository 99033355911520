import { useEffect } from 'react';

export default function ScrollToBottom({ ...props }) {
  useEffect(() => {
    const domNode = props.actionItemParent.current;

    if (
      domNode &&
      props.actionItemResponses?.length > 0 &&
      props.centerEdit === false
    ) {
      domNode.scrollTop = domNode.scrollHeight; // scroll to bottom
    }
  }, [props.actionItemResponses]);
  return null;
}
