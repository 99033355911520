import React, { Fragment } from 'react'
import { useGetEmployerCoverage } from '../../../../auth/redux/getEmployerCoverage'
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage'
import Icon from '../../../../common/components/Icon'
export default function EmployerCoverageInformation({ user }) {
    const { t } = useTranslation()
    const { employerCoverage, getEmployerCoverage } = useGetEmployerCoverage()

    React.useEffect(() => {
        getEmployerCoverage({ userId: user.id })
    }, [])

    if (!employerCoverage) return null
    
    return (
        <Fragment>
            <div className="profile-details-section__header mrm-mt-2">
                <p className="profile-details-section__header__title">
                    {t('client_profile.employer_coverage.title')}
                </p>
                <p
                    className="profile-details-section__header__edit"
                    role="button"
                ></p>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="user" size={16} color="#595959" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            {t(
                                'client_profile.employer_coverage.employer_name'
                            )}
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {employerCoverage && employerCoverage.employer_name
                            ? employerCoverage.employer_name
                            : 'N/A'}
                    </p>
                </div>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="health-id" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            {t(
                                'client_profile.employer_coverage.subscriber_id_last_digits'
                            )}
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        ••••
                        {employerCoverage &&
                        employerCoverage.subscriber_id_last_digits
                            ? employerCoverage.subscriber_id_last_digits
                            : 'N/A'}
                    </p>
                </div>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="shield" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            {t('client_profile.employer_coverage.is_covered')}
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {employerCoverage
                            ? employerCoverage.is_covered
                                ? t('client_profile.employer_coverage.yes')
                                : 'No'
                            : 'N/A'}
                    </p>
                </div>
            </div>
        </Fragment>
    )
}
