import { dashboard } from './dashboard'
import { relative } from './relative'
import { dropdown } from './dropdown'
import { messages } from './messages'
import { profile } from './profile'
import { coach_dashboard } from './coach_dashboard'
import { provider_dashboard } from './provider_dashboard'
import { calls } from './calls'
import { client_profile } from './client_profile'

export const es = {
    dashboard,
    relative,
    dropdown,
    messages,
    profile,
    coach_dashboard,
    provider_dashboard,
    calls,
    client_profile,
}
