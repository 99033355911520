import clsx from 'clsx'
import { Formik } from 'formik'
import React, { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import Icon from '../../../../common/components/Icon'
import clipboard from '../../../assets/signup/clipboard.svg'
import { aboutYourselfSchema } from '../validationSchemas'
import { useUpdateUser } from '../../../../user/redux/updateUser'
import ReactPhoneInput from 'react-phone-input-2'
import { useFetchUser } from '../../../../user/redux/fetchUser'
import { useHistory } from 'react-router-dom'

export default function MobileAboutYourself({ setStep }) {
    const DatePickerButton = forwardRef(
        ({ value, onClick, className }, ref) => (
            <button
                ref={ref}
                onClick={onClick}
                type="button"
                className={clsx('btn-due-date btn-center', className)}
            >
                <p>{value || 'mm/dd/yyyy'}</p>
                <Icon name="date" size={20} />
            </button>
        )
    )

    const { updateUser } = useUpdateUser()
    const { user } = useFetchUser()
    const history = useHistory()

    const genders = {
        male: 1,
        female: 2,
        'prefer-not-to-say': 3,
    }

    return (
        <div className="content center">
            <img src={clipboard} alt="RPM Logo" id="rpm-logo" />
            <h1 className="title">Tell us about yourself. </h1>

            <Formik
                initialValues={{
                    gender: '',
                    month: '',
                    day: '',
                    year: '',
                    date: '',
                    phone_number: '',
                }}
                validationSchema={aboutYourselfSchema}
                onSubmit={values => {
                    console.log(values)
                    updateUser({
                        gender: genders[values.gender],
                        date_of_birth: `${values.year}-${values.month}-${values.day}`,
                        first_name: user.first_name || '',
                        last_name: user.last_name || '',
                        username: user.username,
                        phone_number: values.phone_number,
                    })
                        .then(res => {
                            console.log(res)
                            history.push('/rpm/signup/verify-your-address')
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form"
                        style={{
                            height: '70vh',
                        }}
                    >
                        <div className="inputs-wrapper">
                            <p className="form-title">When were you born?</p>
                            <div className="input-wrapper">
                                <DatePicker
                                    selected={
                                        values.date
                                            ? new Date(values.date)
                                            : null
                                    }
                                    onChange={date => {
                                        console.log(date)
                                        setFieldValue(
                                            'date',
                                            date.toLocaleDateString('en-US')
                                        )

                                        setFieldValue(
                                            'month',
                                            `${date.getMonth() + 1}`
                                        )
                                        setFieldValue(
                                            'day',
                                            `${date.getDate()}`
                                        )
                                        setFieldValue(
                                            'year',
                                            `${date.getFullYear()}`
                                        )
                                    }}
                                    dateFormat="MM/dd/yyyy"
                                    className="input input-date"
                                    placeholderText="MM/DD/YYYY"
                                    customInput={<DatePickerButton />}
                                />
                            </div>

                            <p className="form-title">
                                What is your assigned gender?
                            </p>

                            <div className="genders-grid">
                                <button
                                    className={`gender ${
                                        values.gender === 'male'
                                            ? 'selected'
                                            : ''
                                    }`}
                                    type="button"
                                    onClick={() => {
                                        if (values.gender === 'male') {
                                            setFieldValue('gender', '')
                                            return
                                        }
                                        setFieldValue('gender', 'male')
                                    }}
                                >
                                    Male
                                </button>
                                <button
                                    className={`gender ${
                                        values.gender === 'female'
                                            ? 'selected'
                                            : ''
                                    }`}
                                    type="button"
                                    onClick={() => {
                                        if (values.gender === 'female') {
                                            setFieldValue('gender', '')
                                            return
                                        }
                                        setFieldValue('gender', 'female')
                                    }}
                                >
                                    Female
                                </button>
                                <button
                                    className={`prefer-not-to-say ${
                                        values.gender === 'prefer-not-to-say'
                                            ? 'selected'
                                            : ''
                                    }`}
                                    type="button"
                                    onClick={() => {
                                        if (
                                            values.gender ===
                                            'prefer-not-to-say'
                                        ) {
                                            setFieldValue('gender', '')
                                            return
                                        }
                                        setFieldValue(
                                            'gender',
                                            'prefer-not-to-say'
                                        )
                                    }}
                                >
                                    Prefer not to say
                                </button>
                            </div>

                            {errors && errors.gender && (
                                <p className="error-message">{errors.gender}</p>
                            )}

                            <p className="form-title mrm-mt-1">
                                What is your phone number?
                            </p>

                            <div className="input-wrapper">
                                <ReactPhoneInput
                                    inputClass="input"
                                    country={'us'}
                                    value={values.phone_number}
                                    onChange={phoneNumber => {
                                        let phoneNumberString = phoneNumber.replace(
                                            /[() -]/g,
                                            ''
                                        )
                                        // if it is longer than 15 characters then return
                                        if (phoneNumberString.length > 15)
                                            return

                                        setFieldValue(
                                            'phone_number',
                                            phoneNumberString
                                        )
                                        console.log(
                                            'Phone Number',
                                            values.phone_number
                                        )
                                    }}
                                />
                            </div>

                            {errors && errors.phone_number && (
                                <p className="error-message">
                                    {errors.phone_number}
                                </p>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="submit-button"
                            onSubmit={handleSubmit}
                        >
                            Next
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    )
}
