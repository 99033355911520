import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  USER_CREATE_REFUND_FOR_CLIENT_BEGIN,
  USER_CREATE_REFUND_FOR_CLIENT_SUCCESS,
  USER_CREATE_REFUND_FOR_CLIENT_FAILURE,
  USER_CREATE_REFUND_FOR_CLIENT_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function createRefundForClient(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: USER_CREATE_REFUND_FOR_CLIENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.apiRootUrl}/subscriptions/create-refund-for-client`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: USER_CREATE_REFUND_FOR_CLIENT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: USER_CREATE_REFUND_FOR_CLIENT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateRefundForClientError() {
  return {
    type: USER_CREATE_REFUND_FOR_CLIENT_DISMISS_ERROR,
  };
}

export function useCreateRefundForClient() {
  const dispatch = useDispatch();

  const { createRefundForClientPending, createRefundForClientError } =
    useSelector(
      (state) => ({
        createRefundForClientPending: state.user.createRefundForClientPending,
        createRefundForClientError: state.user.createRefundForClientError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(createRefundForClient(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateRefundForClientError());
  }, [dispatch]);

  return {
    createRefundForClient: boundAction,
    createRefundForClientPending,
    createRefundForClientError,
    dismissCreateRefundForClientError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_CREATE_REFUND_FOR_CLIENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createRefundForClientPending: true,
        createRefundForClientError: null,
      };

    case USER_CREATE_REFUND_FOR_CLIENT_SUCCESS:
      // The request is success
      return {
        ...state,
        createRefundForClientPending: false,
        createRefundForClientError: null,
      };

    case USER_CREATE_REFUND_FOR_CLIENT_FAILURE:
      // The request is failed
      return {
        ...state,
        createRefundForClientPending: false,
        createRefundForClientError: action.data.error,
      };

    case USER_CREATE_REFUND_FOR_CLIENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createRefundForClientError: null,
      };

    default:
      return state;
  }
}
