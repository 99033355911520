import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { default as React, forwardRef, useCallback } from 'react'
import { Form, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Controller, useForm, useWatch } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import Switch from 'react-switch'
import modalFullScreen from '../../../common/modalFullScreen'
import Icon from '../../common/components/Icon'
import { useTranslation } from '../../translations/redux/setDefaultLanguage'
import {
  useAddCoachCall,
  useGetUpcomingCallsParticipants,
} from '../redux/hooks'
import { upcomingCallsSchema } from './schema'
import { desktopMultiSelectStyles } from './styles/multiSelectStyles'

function AddCallModal({ show, onHide, userId }) {
  const { addCoachCall } = useAddCoachCall()

  const {
    upcomingCallsParticipants,
    getUpcomingCallsParticipants,
  } = useGetUpcomingCallsParticipants()

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(upcomingCallsSchema),
  })

  useWatch({ control })

  const onError = (errors, e) => console.log('onError', errors)

  const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className={clsx('form-control hover date-picker-cal', {
        'text-muted': !value,
      })}
    >
      <Icon name="calendar" size={24} />
      {value || 'Select Date & Time'}
    </div>
  ))

  const handleError = useCallback(
    err =>
      Object.keys(err).forEach(key => {
        const errors = err[key]
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' })
        }
      }),
    [setError]
  )

  const multiselectList = {
    upcomingCallsParticipants: upcomingCallsParticipants
      ? upcomingCallsParticipants.results.map(participant => ({
          id: participant.id,
          full_name: `${participant.first_name} ${participant.last_name}`,
        }))
      : null,
  }

  const [loading, setLoading] = React.useState(false)

  const handleSaveClick = useCallback(
    async ({
      call_name,
      user_provided_timestamp,
      time_zone,
      user_provided_link,
      is_recurrent,
      selected_participants,
      use_upheal_link,
      include_creator,
      interval,
    }) => {
      user_provided_timestamp = new Date(user_provided_timestamp).valueOf()

      if (!!!user_provided_link) user_provided_link = null
      else if (
        !user_provided_link?.match(/^https?:\/\//) &&
        !!user_provided_link
      )
        user_provided_link = 'http://' + user_provided_link

      setLoading(true)

      addCoachCall({
        call_name,
        user_provided_timestamp,
        time_zone: dayjs.tz?.guess(),
        user_provided_link,
        participants: selected_participants.map(e => e.id) || [],
        is_recurrent,
        use_upheal_link,
        include_creator,
        interval,
        ...(userId && { user_id: userId }),
      })
        .then(() => {
          setLoading(false)
          onHide()
        })
        .catch(e => {
          setLoading(false)

          handleError(e.response.data)

          console.log(e)
        })
    },
    [addCoachCall, handleError, onHide]
  )

  const loadOptions = inputValue => {
    return getUpcomingCallsParticipants({
      search: inputValue,
    })
      .then(res => {
        console.log(res)
        return res.results.map(user => ({
          value: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
        }))
      })
      .catch(e => handleError(e.response.data))
  }

  const { t } = useTranslation()

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="dashboard-coach-add-calls-page-modal"
    >
      <Modal.Header>
        <button onClick={() => onHide()}>Cancel</button>
        <h2>{t('calls.add_new_call')}</h2>
        <button
          onClick={handleSubmit(handleSaveClick, onError)}
          type="submit"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form className="mrm-px-1">
          <Controller
            render={({ onChange, value }) => (
              <div className="call-types">
                <div
                  className={`call-type ${clsx({
                    active: value === true,
                  })}`}
                  onClick={() => {
                    setValue('include_creator', true)
                  }}
                >
                  <Icon name="circle-box" size={13} active={value === true} />
                  <p>{t('calls.personal')}</p>
                </div>

                <div
                  className={`call-type ${clsx({
                    active: value === false,
                  })}`}
                  onClick={() => {
                    setValue('include_creator', false)
                  }}
                >
                  <Icon name="circle-box" size={13} active={value === false} />
                  <p>{t('calls.third_party')} </p>
                </div>
              </div>
            )}
            name="include_creator"
            control={control}
            defaultValue={true}
          />
          {errors.include_creator && (
            <p className="error-message">{errors.include_creator.message}</p>
          )}
          <Form.Group controlId="call_name">
            <Form.Label>{t('calls.call_name')}</Form.Label>
            <Form.Control
              name="call_name"
              type="text"
              ref={register}
              placeholder={t('calls.call_name')}
              className="call-name-input"
            />
            {errors.call_name && (
              <p className="error-message">{errors.call_name.message}</p>
            )}
          </Form.Group>

          <Form.Group
            controlId="user_provided_timestamp"
            className="mb-0 d-flex flex-column mb-4"
          >
            <Form.Label>{t('calls.date_time')}</Form.Label>
            <Controller
              name="user_provided_timestamp"
              control={control}
              render={({ onChange, value }) => (
                <DatePicker
                  required
                  selected={value}
                  showTimeSelect
                  minDate={new Date()}
                  timeIntervals={5}
                  dateFormat="MMMM d @ h:mm aa"
                  customInput={
                    <DatePickerButton
                      className={clsx({
                        'is-invalid': errors.user_provided_timestamp,
                      })}
                    />
                  }
                  onChange={onChange}
                  className="ml-0"
                />
              )}
            />
            {errors.user_provided_timestamp && (
              <p className="error-message">
                {errors.user_provided_timestamp.message}
              </p>
            )}
          </Form.Group>

          <Form.Group controlId="time_zone" className="d-none">
            <Form.Control
              name="time_zone"
              type="text"
              ref={register}
              value={dayjs.tz?.guess()}
            />
          </Form.Group>

          <Form.Group controlId="selected_participants">
            <Form.Label>{t('calls.participants')}</Form.Label>
            <Controller
              name="selected_participants"
              control={control}
              defaultValue={[]}
              render={({ onChange, value }) => (
                <AsyncSelect
                  isMulti
                  name="selected_participants"
                  placeholder={t('calls.select_participants')}
                  value={value}
                  getOptionValue={option => option.id}
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={inputValue => {
                    if (!multiselectList.upcomingCallsParticipants) return
                    onChange(inputValue || [])
                  }}
                  ref={register}
                  styles={desktopMultiSelectStyles}
                />
              )}
            />
            {// show the selected partipant here
            watch('selected_participants')?.length > 0 &&
              upcomingCallsParticipants?.results &&
              upcomingCallsParticipants.results.filter(participant =>
                watch('selected_participants').find(
                  selectedParticipant =>
                    selectedParticipant.id === participant.id
                )
              ).length > 0 && (
                <p
                  className="error-message"
                  style={{
                    color: '#343434',
                    fontWeight: '400',
                  }}
                >
                  {upcomingCallsParticipants &&
                    upcomingCallsParticipants.results
                      .filter(participant =>
                        watch('selected_participants').find(
                          selectedParticipant =>
                            selectedParticipant.id === participant.id
                        )
                      )
                      .map(participant => participant.email)
                      .join(', ')}
                </p>
              )}

            {errors.selected_participants && (
              <p className="error-message">
                {errors.selected_participants.message}
              </p>
            )}
          </Form.Group>

          <Form.Group controlId="user_provided_link">
            <Form.Label>
              {t('calls.meeting_link')}
              (zoom, google meet)
            </Form.Label>
            <Form.Control
              name="user_provided_link"
              type="text"
              ref={register}
              placeholder={
                watch('use_upheal_link')
                  ? t('calls.using_upheal_link')
                  : t('calls.meeting_link')
              }
              disabled={watch('use_upheal_link')}
            />
            {errors.user_provided_link && (
              <p className="error-message">
                {errors.user_provided_link.message}
              </p>
            )}
          </Form.Group>
          <Controller
            name="use_upheal_link"
            control={control}
            defaultValue={false}
            render={({ onChange, value }) => (
              <div
                className="use-upheal-link"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!value) {
                    setValue('user_provided_link', '')
                    setError('user_provided_link', {
                      message: '',
                    })
                  }
                  onChange(!value)
                }}
              >
                <button type="button">
                  <Icon name="checkbox" color={'#D9D9D9'} active={value} />
                </button>
                <p>{t('calls.use_upheal_link')}</p>
              </div>
            )}
          />

          {errors.use_upheal_link && (
            <p className="error-message">{errors.use_upheal_link.message}</p>
          )}

          <Form.Group
            controlId="is_recurrent"
            className="mb-0 d-flex flex-column mb-4"
          >
            <Form.Label>{t('calls.recurs_weekly')}</Form.Label>
            <Controller
              name="is_recurrent"
              control={control}
              defaultValue={false}
              render={({ onChange, value }) => (
                <Switch
                  onChange={onChange}
                  checked={!!value}
                  onColor="#343434"
                />
              )}
            />
          </Form.Group>

          {/***
           *
           * add interval (0 or 1) to the api call
           */}

          <Form.Group
            controlId="interval"
            className={watch('is_recurrent') ? '' : 'd-none'}
          >
            <Controller
              name="interval"
              control={control}
              defaultValue={0}
              render={({ value }) => (
                <div className="intervals">
                  <button onClick={() => setValue('interval', 0)} type="button">
                    <Icon name="circle-box" size={13} active={value === 0} />
                    <p>Every week</p>
                  </button>
                  <button onClick={() => setValue('interval', 1)} type="button">
                    <Icon name="circle-box" size={13} active={value === 1} />
                    <p>Every other week</p>
                  </button>
                </div>
              )}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default modalFullScreen(AddCallModal)
