import React from 'react';
import { Modal } from 'react-bootstrap';

export default function SuccessModal({
  show,
  onHide,
  title,
  message,
  children,
}) {
  return (
    <Modal show={show} className="success-modal" centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        {children}
      </Modal.Body>

      <button
        onClick={() => {
          onHide();
        }}
      >
        Close
      </button>
    </Modal>
  );
}
