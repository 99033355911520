import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    USER_PROFILE_SETTINGS_BEGIN,
    USER_PROFILE_SETTINGS_SUCCESS,
    USER_PROFILE_SETTINGS_FAILURE,
    USER_PROFILE_SETTINGS_DISMISS_ERROR,
} from './constants'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'
import config from '../../../common/config'

export function profileSettings(
    args = {
        method: 'get',
        data: {},
    }
) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: USER_PROFILE_SETTINGS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios({
                ...createAxiosConfigWithAuth(getState()),
                method: args.method,
                data: args.data,
                url: `${config.apiRootUrl}/profile/settings`,
            })

            doRequest.then(
                res => {
                    console.log('settings in req', res)
                    dispatch({
                        type: USER_PROFILE_SETTINGS_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: USER_PROFILE_SETTINGS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissProfileSettingsError() {
    return {
        type: USER_PROFILE_SETTINGS_DISMISS_ERROR,
    }
}

export function useProfileSettings() {
    const dispatch = useDispatch()

    const { state } = useSelector(
        state => ({
            state,
        }),
        shallowEqual
    )

    const {
        settings,
        profileSettingsPending,
        profileSettingsError,
    } = useSelector(
        state => ({
            settings: state.user.settings,
            profileSettingsPending: state.user.profileSettingsPending,
            profileSettingsError: state.user.profileSettingsError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(profileSettings(...args))
        },
        [dispatch]
    )

    useEffect(() => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

        if (settings && settings?.current_timezone !== tz && tz) {
            console.log('settings in useeffect', settings)
            console.log('tz in useeffect', tz)
            axios({
                ...createAxiosConfigWithAuth(state),
                method: 'put',
                data: { current_timezone: tz },
                url: `${config.apiRootUrl}/profile/settings`,
            })
        }
    }, [settings])

    const boundDismissError = useCallback(() => {
        return dispatch(dismissProfileSettingsError())
    }, [dispatch])

    return {
        settings,
        profileSettings: boundAction,
        profileSettingsPending,
        profileSettingsError,
        dismissProfileSettingsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case USER_PROFILE_SETTINGS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                profileSettingsPending: true,
                profileSettingsError: null,
            }

        case USER_PROFILE_SETTINGS_SUCCESS:
            // The request is success
            return {
                ...state,
                profileSettingsPending: false,
                profileSettingsError: null,
                settings: action.data,
            }

        case USER_PROFILE_SETTINGS_FAILURE:
            // The request is failed
            return {
                ...state,
                profileSettingsPending: false,
                profileSettingsError: action.data.error,
            }

        case USER_PROFILE_SETTINGS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                profileSettingsError: null,
            }

        default:
            return state
    }
}
