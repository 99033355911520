import React from 'react';

export default function DateIcon({ size, color = '#343434', style = {} }) {
  return (
    <svg
      width={size * 1.05}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4865 17.3875H3.40357C2.50134 17.386 1.63647 17.0269 0.998495 16.389C0.360517 15.751 0.00145875 14.8861 0 13.9839V4.3965C0.00145875 3.49426 0.360517 2.62939 0.998495 1.99142C1.63647 1.35344 2.50134 0.994379 3.40357 0.99292H14.9327C15.8349 0.994379 16.6998 1.35344 17.3378 1.99142C17.9758 2.62939 18.3348 3.49426 18.3363 4.3965V9.54323C18.3319 11.6233 17.5033 13.6168 16.032 15.087C14.5606 16.5573 12.5666 17.3845 10.4865 17.3875V17.3875ZM3.40357 2.64782C2.9398 2.64782 2.49501 2.83205 2.16707 3.15999C1.83913 3.48793 1.6549 3.93272 1.6549 4.3965V13.9839C1.6549 14.4477 1.83913 14.8924 2.16707 15.2204C2.49501 15.5483 2.9398 15.7326 3.40357 15.7326H10.4865C12.1281 15.7311 13.7021 15.0787 14.8634 13.9185C16.0247 12.7582 16.6785 11.1848 16.6814 9.54323V4.3965C16.6814 4.16686 16.6361 3.93946 16.5483 3.72731C16.4604 3.51515 16.3316 3.32237 16.1692 3.15999C16.0068 2.99761 15.814 2.86881 15.6019 2.78093C15.3897 2.69305 15.1623 2.64782 14.9327 2.64782H3.40357Z"
        fill={color}
      />
      <path
        d="M11.4573 8.70476H5.59893C5.37948 8.70476 5.16902 8.61759 5.01384 8.46241C4.85866 8.30723 4.77148 8.09677 4.77148 7.87732C4.77148 7.65786 4.85866 7.4474 5.01384 7.29222C5.16902 7.13704 5.37948 7.04987 5.59893 7.04987H11.4573C11.6767 7.04987 11.8872 7.13704 12.0424 7.29222C12.1975 7.4474 12.2847 7.65786 12.2847 7.87732C12.2847 8.09677 12.1975 8.30723 12.0424 8.46241C11.8872 8.61759 11.6767 8.70476 11.4573 8.70476V8.70476Z"
        fill={color}
      />
      <path
        d="M8.5281 12.5H5.59893C5.37948 12.5 5.16902 12.4128 5.01384 12.2576C4.85866 12.1025 4.77148 11.892 4.77148 11.6725C4.77148 11.4531 4.85866 11.2426 5.01384 11.0874C5.16902 10.9323 5.37948 10.8451 5.59893 10.8451H8.5281C8.74756 10.8451 8.95802 10.9323 9.1132 11.0874C9.26838 11.2426 9.35555 11.4531 9.35555 11.6725C9.35555 11.892 9.26838 12.1025 9.1132 12.2576C8.95802 12.4128 8.74756 12.5 8.5281 12.5V12.5Z"
        fill={color}
      />
      <path
        d="M5.59893 3.63526C5.37948 3.63526 5.16902 3.54808 5.01384 3.3929C4.85866 3.23773 4.77148 3.02726 4.77148 2.80781V0.827449C4.77148 0.607996 4.85866 0.397531 5.01384 0.242354C5.16902 0.0871773 5.37948 0 5.59893 0C5.81839 0 6.02885 0.0871773 6.18403 0.242354C6.33921 0.397531 6.42638 0.607996 6.42638 0.827449V2.80781C6.42638 3.02726 6.33921 3.23773 6.18403 3.3929C6.02885 3.54808 5.81839 3.63526 5.59893 3.63526V3.63526Z"
        fill={color}
      />
      <path
        d="M12.7376 3.63526C12.5182 3.63526 12.3077 3.54808 12.1525 3.3929C11.9973 3.23773 11.9102 3.02726 11.9102 2.80781V0.827449C11.9102 0.607996 11.9973 0.397531 12.1525 0.242354C12.3077 0.0871773 12.5182 0 12.7376 0C12.9571 0 13.1675 0.0871773 13.3227 0.242354C13.4779 0.397531 13.5651 0.607996 13.5651 0.827449V2.80781C13.5651 3.02726 13.4779 3.23773 13.3227 3.3929C13.1675 3.54808 12.9571 3.63526 12.7376 3.63526V3.63526Z"
        fill={color}
      />
    </svg>
  );
}
