import React, { useEffect } from 'react';
import Icon from './components/Icon';

export default function BottomSheet({ children, onClose, ...props }) {
  const [containerSize, setContainerSize] = React.useState({ y: 400 });

  const handler = (touchMoveEvent) => {
    const startSize = containerSize;
    const startPosition = touchMoveEvent.changedTouches[0].pageY;

    document.body.style.overflow = 'hidden';

    function onTouchMove(touchMoveEvent) {
      const newSize =
        startSize.y + (startPosition - touchMoveEvent.changedTouches[0].pageY);

      if (newSize < 150) onClose ? onClose() : setContainerSize({ y: 150 });
      else if (newSize > window.innerHeight - 200)
        setContainerSize({ y: window.innerHeight - 200 });
      else
        setContainerSize({
          y: newSize,
        });

      touchMoveEvent.preventDefault();
      document.body.style.overflow = 'hidden';
    }

    function onTouchEnd() {
      document.body.removeEventListener('touchmove', onTouchMove);

      document.body.style.overflowY = 'auto';
    }

    document.body.addEventListener('touchmove', onTouchMove, {
      passive: false,
    });

    document.body.addEventListener('touchend', onTouchEnd, {
      once: true,
      passive: false,
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      if (containerSize.y > window.innerHeight - 200)
        setContainerSize({ y: window.innerHeight - 200 });
    });
  });

  return (
    <div
      className={'bottom-sheet scrollable ' + props.className}
      style={{
        ...props.style,
      }}
    >
      <div
        className="bottom-sheet__container"
        style={{ height: containerSize.y, maxHeight: '400px' }}
      >
        <div
          onTouchStart={handler}
          className="pt-3"
          onClick={() => {
            if (containerSize.y >= 300) {
              onClose ? onClose() : setContainerSize({ y: 200 });
            } else {
              onClose
                ? onClose()
                : setContainerSize({ y: window.innerHeight - 200 });
            }
          }}
        >
          <Icon name="line" size={5} color="#DEDCDC" />
        </div>

        {children}
      </div>
    </div>
  );
}
