const assessmentStatus = ['red', 'yellow', 'green'];

const iconColor = (comp) => {
  if (comp.last_assessment_status) {
    if (!comp.last_assessment_approved && !comp.last_assessment_rejected) {
      return assessmentStatus[Number(comp.last_assessment_status) - 1];
    } else {
      return assessmentStatus[Number(comp.last_assessment_status) - 1];
    }
  }
  return null;
};

export { iconColor };
