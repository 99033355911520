import React from 'react'
import { DesktopHeader } from '../common'
import useWindowSize from '../common/useWindowResize'
import CheckIn from './components/dashboard/CheckIn'
import GrowthSessions from './components/dashboard/GrowthSessions'
import MobileRecords from './components/dashboard/MobileRecords'
import MyTeam from './components/dashboard/MyTeam'
import RoadmapsContainer from './components/dashboard/RoadmapsContainer'
import Sidebar from './components/dashboard/Sidebar'
// import PropTypes from 'prop-types';

export default function Dashboard() {
    const { width } = useWindowSize()
    const isMobile = width < 768
    return (
        <div
            className="rpm-dashboard"
            style={{
                background: '#f0ece8',
            }}
        >
            <DesktopHeader />
            <div className="rpm-dashboard-grid">
                {isMobile && <MobileRecords />}

                <RoadmapsContainer />
                <CheckIn />
                <MyTeam />
                <GrowthSessions />

                {/**  tablet and desktop */}
                {!isMobile && <Sidebar />}
            </div>
        </div>
    )
}

Dashboard.propTypes = {}
Dashboard.defaultProps = {}
