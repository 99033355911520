import { Formik } from 'formik'
import React, { useEffect } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { useFetchUser } from '../../../../user/redux/fetchUser'
import { useUpdateUser } from '../../../../user/redux/updateUser'
import clipBoard from '../../../assets/signup/clipboard.svg'
import { isValidDate } from '../../../utils/isValidDate'
import { aboutYourselfSchema } from '../validationSchemas'

export default function AboutYourself({ setStep }) {
    const { updateUser } = useUpdateUser()
    const { user, fetchUser } = useFetchUser()

    useEffect(() => {
        fetchUser()
    }, [])

    const genders = {
        male: 1,
        female: 2,
        'prefer-not-to-say': 3,
    }

    if (!user) return null

    return (
        <div className="sidebar-content">
            <img src={clipBoard} alt="RPM Logo" id="overhead-icon" />
            <h1>Tell us about yourself</h1>

            <Formik
                initialValues={{
                    gender: '',
                    month: '',
                    day: '',
                    year: '',
                    date: '',
                    phone_number: '',
                }}
                validationSchema={aboutYourselfSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    console.log(values)

                    // if date is not valid then return
                    if (!isValidDate(values.year, values.month, values.day)) {
                        setFieldError('date', 'Invalid date')
                        return
                    }

                    setSubmitting(false)

                    updateUser({
                        gender: genders[values.gender],
                        date_of_birth: `${values.year}-${values.month}-${values.day}`,
                        first_name: user.first_name || '',
                        last_name: user.last_name || '',
                        username: user.username,
                        phone_number: values.phone_number,
                    })
                        .then(res => {
                            console.log(res)
                            setStep(3)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit} className="form">
                        <p className="description-bold">When were you born?</p>

                        <div className="date-picker-container">
                            <input
                                type="text"
                                onChange={handleChange}
                                value={values.birthDate}
                                placeholder="MM"
                                className="small-input"
                                id="month"
                                name="month"
                            />
                            <p>/</p>
                            <input
                                type="text"
                                placeholder="DD"
                                className="small-input"
                                id="day"
                                name="day"
                                onChange={handleChange}
                            />
                            <p>/</p>
                            <input
                                type="text"
                                placeholder="YYYY"
                                className="medium-input"
                                id="year"
                                name="year"
                                onChange={handleChange}
                            />
                        </div>
                        <p className="error-message">
                            {errors.date && touched.date && errors.date}
                        </p>
                        <p className="description-bold">
                            What is your assigned gender?
                        </p>
                        <div className="genders-grid">
                            <button
                                className={`gender ${
                                    values.gender === 'male' ? 'selected' : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    if (values.gender === 'male') {
                                        setFieldValue('gender', '')
                                        return
                                    }
                                    setFieldValue('gender', 'male')
                                }}
                            >
                                Male
                            </button>
                            <button
                                className={`gender ${
                                    values.gender === 'female' ? 'selected' : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    if (values.gender === 'female') {
                                        setFieldValue('gender', '')
                                        return
                                    }
                                    setFieldValue('gender', 'female')
                                }}
                            >
                                Female
                            </button>
                            <button
                                className={`prefer-not-to-say ${
                                    values.gender === 'prefer-not-to-say'
                                        ? 'selected'
                                        : ''
                                }`}
                                type="button"
                                onClick={() => {
                                    if (values.gender === 'prefer-not-to-say') {
                                        setFieldValue('gender', '')
                                        return
                                    }
                                    setFieldValue('gender', 'prefer-not-to-say')
                                }}
                            >
                                Prefer not to say
                            </button>
                        </div>

                        <p className="description-bold">Phone Number</p>

                        <ReactPhoneInput
                            defaultCountry="us"
                            value={values.phone_number}
                            placeholder="Phone Number *"
                            inputExtraProps={{
                                name: 'phone_number',
                                id: 'phone_number',
                            }}
                            onChange={phoneNumber => {
                                let phoneNumberString = phoneNumber.replace(
                                    /[() -]/g,
                                    ''
                                )
                                // if it is longer than 15 characters then return
                                if (phoneNumberString.length > 15) return

                                setFieldValue('phone_number', phoneNumberString)
                                console.log('Phone Number', values.phone_number)
                            }}
                        />

                        {errors.phone_number &&
                            touched.phone_number &&
                            errors.phone_number && (
                                <p className="error-message">
                                    {errors.phone_number}
                                </p>
                            )}
                        <button
                            type="submit"
                            className="next-button"
                            id="next"
                            onClick={handleSubmit}
                        >
                            Next
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    )
}
