export const profile = {
  view_profile: 'View Profile',
  edit: 'Edit',
  account_type: 'Account Type',
  email: 'Email',
  phone: 'Phone',
  date_joined: 'Date Joined',
  last_login: 'Last Login',
  my_coaches: 'My Coaches',
  my_clients: 'My Clients',
  go_back: 'Go Back',
  my_profile: 'My Profile',
  resend_invitation: 'Resend Invitation',
  subscription: 'Subscription',
};
