import React from 'react'

export default function CaseIcon({ size = 22, color = '#595959' }) {
    return (
        <svg
            width={size}
            height={size / 1.1}
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.982143 7.40741C0.787651 7.40741 0.601124 7.48545 0.463598 7.62437C0.326071 7.76328 0.24881 7.95169 0.24881 8.14815C0.24881 8.3446 0.326071 8.53302 0.463598 8.67193C0.601124 8.81085 0.787651 8.88889 0.982143 8.88889H21.2667C21.4612 8.88889 21.6477 8.81085 21.7852 8.67193C21.9227 8.53302 22 8.3446 22 8.14815V6.51852C21.9991 5.77225 21.7053 5.0568 21.1828 4.52911C20.6604 4.00142 19.9521 3.70458 19.2133 3.7037H16.136V1.89286C16.1354 1.39054 15.9375 0.908992 15.5856 0.554051C15.2337 0.199109 14.7567 -0.000175487 14.2594 1.15954e-07H7.73994C7.24322 0.000700353 6.76705 0.200372 6.41587 0.555219C6.06469 0.910066 5.86719 1.39112 5.86667 1.89286V3.7037H2.78929C2.05003 3.70388 1.34106 4.00041 0.81808 4.52818C0.2951 5.05594 0.00086745 5.77179 0 6.51852V17.1852C0.00121198 17.9313 0.295195 18.6466 0.817534 19.1742C1.33987 19.7018 2.04797 19.9988 2.78667 20H19.2133C19.952 19.9986 20.66 19.7016 21.1823 19.174C21.7046 18.6464 21.9986 17.9313 22 17.1852V8.46561C22 8.26915 21.9227 8.08074 21.7852 7.94183C21.6477 7.80291 21.4612 7.72487 21.2667 7.72487C21.0722 7.72487 20.8856 7.80291 20.7481 7.94183C20.6106 8.08074 20.5333 8.26915 20.5333 8.46561V17.1852C20.533 17.5387 20.3938 17.8776 20.1463 18.1276C19.8989 18.3776 19.5633 18.5182 19.2133 18.5185H2.78667C2.43658 18.5185 2.10083 18.378 1.85329 18.128C1.60574 17.8779 1.46667 17.5388 1.46667 17.1852V6.51852C1.46649 6.34337 1.50052 6.16991 1.56679 6.00807C1.63307 5.84622 1.73029 5.69917 1.8529 5.57532C1.97551 5.45148 2.12109 5.35327 2.28132 5.28632C2.44155 5.21938 2.61327 5.18501 2.78667 5.18518H19.2133C19.5634 5.18518 19.8992 5.32566 20.1467 5.57571C20.3943 5.82576 20.5333 6.1649 20.5333 6.51852V7.40741H0.982143ZM14.6667 3.7037H7.33333V1.89286C7.33368 1.78397 7.37661 1.67964 7.45277 1.60259C7.52893 1.52554 7.63215 1.482 7.73994 1.48148H14.2594C14.3673 1.48183 14.4707 1.52528 14.547 1.60236C14.6233 1.67943 14.6663 1.78386 14.6667 1.89286V3.7037Z"
                fill={color}
            />
        </svg>
    )
}
