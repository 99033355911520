import React from 'react';
import ProgressWheel from './ProgressWheel';

export default function StatsHeader() {
  const progress = 50;

  return (
    <div className="stats-sidebar-header">
      <div className="stats-sidebar-header-left">
        <h2 className="title"> You're almost there. </h2>
        <p className="days">13/16 days tracked</p>
        <p className="days-left">
          <span>5 days </span>left in eligibility period
        </p>
      </div>

      <div className="stats-sidebar-header-right">
        <ProgressWheel progress={progress} />
      </div>
    </div>
  );
}
