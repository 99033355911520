import clsx from 'clsx';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from '../../common/components/Icon';
import { useTranslation } from '../../translations/redux/setDefaultLanguage';

const Roadmap = ({
  data,
  unseen,
  className,
  onAssignClick = null,
  onDeleteClick = null,
  hideProgress,
  goToRoadmapPage = true,
  user = null,
  ...other
}) => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  return (
    <Card {...other} className={clsx('dashboard-roadmap-card', className)}>
      <div className="roadmap-modal">
        <button
          onClick={
            () => {
              if (!goToRoadmapPage) return;
              history.push({
                pathname: `/roadmap/${data.id}`,
                search: user ? `?user=${user.id}` : '',
                state: { backLink: location },
              });
            }
          }
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            border: 0, padding: 0, margin: 0, textAlign: 'left', cursor: goToRoadmapPage ? 'pointer' : 'default'
          }}
        >
          <Card.Body className="mrm-p-0_5">
            {unseen && <span className="roadmap-dot dot float-left" />}
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '0fr 1fr 0fr',
                alignItems: 'center',
                gap: '1rem',
                paddingLeft: '0.5rem',
              }}
            >
              <div
                style={{
                  width: '4rem',
                  height: '4rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#C4C4C4',
                  borderRadius: '20%',
                }}
              >
                {data.icon ? (
                  <Card.Img
                    alt={data.title}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                    src={data.icon}
                  />
                ) : (
                  <Icon name="roadmapsecondary" size={25} />
                )}
              </div>
              <div>
                <p className="mb-0 card-progress-title">{data.title}</p>
                {!hideProgress && (
                  <>
                    <div className="progress mrm-my-0_25">
                      <div
                        className="progress-bar"
                        style={{
                          width: `${data.stats?.total_progress ||
                            data?.progress?.toFixed()
                            }%`,
                        }}
                      />
                    </div>
                    <p className="progress-description">
                      {data.stats?.total_progress.toFixed() ||
                        data?.progress?.toFixed() ||
                        0}
                      % {t('dashboard.my_roadmaps.completed')}
                    </p>
                  </>
                )}
              </div>
            </div>
          </Card.Body>
        </button>
        {onAssignClick !== null ? (
          data.assigned ? (
            <> </>
          ) : (
            <button onClick={onAssignClick}>
              {data.assigned === 'requesting' && <></>}
              <Icon name="plus" />
            </button>
          )
        ) : (
          <></>
        )}
        {onDeleteClick !== null ? (
          data ? (
            <button onClick={() => setShowDeleteModal(true)} type="button">
              {data.assigned === 'requesting' && <></>}
              <Icon name="minus" size={2} />
            </button>
          ) : (
            <> </>
          )
        ) : (
          <></>
        )}
      </div>
      <DeleteConfirmation
        show={showDeleteModal}
        onDelete={onDeleteClick}
        onHide={() => setShowDeleteModal(false)}
      />
    </Card>
  );
};

const DeleteConfirmation = ({ show, onHide, onDelete }) => {
  return (
    <Modal show={show} className="delete-confirmation-modal">
      <Modal.Header>
        <h1>Are you sure you want to remove this roadmap?</h1>
      </Modal.Header>
      <Modal.Body>
        <p className="description">
          Your client will no longer be able to see this roadmap.
        </p>
        <button className="remove" onClick={onDelete} type="button">
          Remove Roadmap
        </button>
        <button className="go-back" onClick={onHide} type="button">
          Go back
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default Roadmap;

Roadmap.propTypes = {};
Roadmap.defaultProps = {};
