export const AUTH_SELF_SIGN_UP_BEGIN = 'AUTH_SELF_SIGN_UP_BEGIN'
export const AUTH_SELF_SIGN_UP_SUCCESS = 'AUTH_SELF_SIGN_UP_SUCCESS'
export const AUTH_SELF_SIGN_UP_FAILURE = 'AUTH_SELF_SIGN_UP_FAILURE'
export const AUTH_SELF_SIGN_UP_DISMISS_ERROR = 'AUTH_SELF_SIGN_UP_DISMISS_ERROR'
export const AUTH_GET_SETTINGS_BY_NAME_BEGIN = 'AUTH_GET_SETTINGS_BY_NAME_BEGIN'
export const AUTH_GET_SETTINGS_BY_NAME_SUCCESS =
  'AUTH_GET_SETTINGS_BY_NAME_SUCCESS'
export const AUTH_GET_SETTINGS_BY_NAME_FAILURE =
  'AUTH_GET_SETTINGS_BY_NAME_FAILURE'
export const AUTH_GET_SETTINGS_BY_NAME_DISMISS_ERROR =
  'AUTH_GET_SETTINGS_BY_NAME_DISMISS_ERROR'
export const AUTH_CREATE_USER_CHILD_INVITATION_BEGIN =
  'AUTH_CREATE_USER_CHILD_INVITATION_BEGIN'
export const AUTH_CREATE_USER_CHILD_INVITATION_SUCCESS =
  'AUTH_CREATE_USER_CHILD_INVITATION_SUCCESS'
export const AUTH_CREATE_USER_CHILD_INVITATION_FAILURE =
  'AUTH_CREATE_USER_CHILD_INVITATION_FAILURE'
export const AUTH_CREATE_USER_CHILD_INVITATION_DISMISS_ERROR =
  'AUTH_CREATE_USER_CHILD_INVITATION_DISMISS_ERROR'
export const AUTH_SET_DEFAULT_LANGUAGE = 'AUTH_SET_DEFAULT_LANGUAGE'
export const AUTH_CREATE_EMPLOYER_COVERAGE_BEGIN =
  'AUTH_CREATE_EMPLOYER_COVERAGE_BEGIN'
export const AUTH_CREATE_EMPLOYER_COVERAGE_SUCCESS =
  'AUTH_CREATE_EMPLOYER_COVERAGE_SUCCESS'
export const AUTH_CREATE_EMPLOYER_COVERAGE_FAILURE =
  'AUTH_CREATE_EMPLOYER_COVERAGE_FAILURE'
export const AUTH_CREATE_EMPLOYER_COVERAGE_DISMISS_ERROR =
  'AUTH_CREATE_EMPLOYER_COVERAGE_DISMISS_ERROR'
export const AUTH_GET_EMPLOYER_COVERAGE_BEGIN =
  'AUTH_GET_EMPLOYER_COVERAGE_BEGIN'
export const AUTH_GET_EMPLOYER_COVERAGE_SUCCESS =
  'AUTH_GET_EMPLOYER_COVERAGE_SUCCESS'
export const AUTH_GET_EMPLOYER_COVERAGE_FAILURE =
  'AUTH_GET_EMPLOYER_COVERAGE_FAILURE'
export const AUTH_GET_EMPLOYER_COVERAGE_DISMISS_ERROR =
  'AUTH_GET_EMPLOYER_COVERAGE_DISMISS_ERROR'
