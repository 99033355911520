import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useFetchUser } from '../../user/redux/fetchUser'
import { usePaymentCheckout } from '../redux/paymentCheckout'
import { useGetInvoicesStripe } from '../redux/getInvoicesStripe'
import Icon from '../../common/components/Icon'
import Loader from '../../common/Loader'
import { prepareErrorMessage } from '../../common/uiHelpers'
import * as ZapierApi from '../../../common/zapier'

export default function CheckoutForm({
    productInfo,
    onSubmit,
    iconColor = '#fff',
    trial = 0,
    customText = {
        title: 'Try free for 30 days',
        description:
            'Gain full access to all of the benefits of our coaching program - and pay nothing until your trial ends.',
        after_trial_ends: 'After trial ends',
        credit_card: 'Credit Card',
        pay_now: 'Paying now',
        not_billed_until: 'You will not be billed until',
        not_billed_for: ' for ',
        start_subscription_button: 'Start your monthly subscription',
        billed: 'You will be billed',
        on_the: 'on the',
        each_month: 'of each month',
        cancel_anytyme: '. Cancel any time.',
        go_to_dashboard_button: 'Go to Dashboard',
        terms_text: 'Terms and Conditions',
        terms_link: 'https://noble.health/terms-of-service/',
        subscribe: 'Subscribe',
    },
}) {
    const price_id = productInfo?.id

    // trial comes in seconds, convert to date like May 1, 2022 and do not include GMT
    const trialBillingDate =
        trial > 0
            ? new Date(
                  new Date().getTime() + trial * 1000 * 60 * 60 * 24
              ).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
              })
            : null

    const [error, setError] = useState(null)
    const [email, setEmail] = useState('')
    const stripe = useStripe()
    const elements = useElements()

    const [message, setMessage] = useState(null)

    const { getInvoicesStripe } = useGetInvoicesStripe()

    const [isLoading, setIsLoading] = useState(false)
    const { paymentCheckout } = usePaymentCheckout()
    const { user } = useFetchUser()

    useEffect(() => {
        setEmail(user?.email)
    }, [user])

    const history = useHistory()
    const location = useLocation()

    const handleSubmit = async event => {
        setIsLoading(true)
        event.preventDefault()
        const card = elements.getElement(CardElement)

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        })

        if (error) {
            setError(error.response)
            setIsLoading(false)
        } else {
            console.log({
                price_id,
                payment_method_id: paymentMethod.id,
                trial,
            })

            const values = {
                price_id,
                payment_method_id: paymentMethod.id,
            }

            // if company allows trial then add it to the values
            if (trial > 0) values.trial_end = trial

            paymentCheckout(values)
                .then(response => {
                    if (response.data.message !== 'Success') {
                        setMessage(response.data.message)
                    }
                })
                .then(() => {
                    getInvoicesStripe()
                        .then(res => {
                            console.log(res)
                            ZapierApi.sendContactForm(
                                user?.first_name,
                                user?.last_name,
                                user?.email,
                                'checkout'
                            )
                        })
                        .then(() => {
                            onSubmit
                                ? onSubmit()
                                : history.push('/onboarding-confirmation')
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(error => {
                    console.log(
                        'ERROR PAYMENT CHECKOUT',
                        prepareErrorMessage(error)
                    )
                    setMessage(error?.response?.data?.CardError?.[0])
                    setIsLoading(false)
                })
        }
    }

    const cardStyle = {
        style: {
            base: {
                padding: '12px',
                backgroundColor: 'white',
                color: '#32325d',
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#32325d',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    }

    // get today's date
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')

    if (isNaN(productInfo?.unit_amount / 100)) return null

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className="email-input">
                {' '}
                <Icon name="email" size={14} color={iconColor} />
                <input
                    className="form-input email-checkout"
                    id="email"
                    name="name"
                    type="email"
                    placeholder="jenny.rosen@example.com"
                    required
                    value={email}
                    disabled
                />
            </div>

            <CardElement id="card-element" options={cardStyle} />
            <div className="card-errors" role="alert">
                {error}
            </div>
            <div className="pre-checkout-price">
                <p className="check-description">
                    {!trial
                        ? // You will be billed $`PRICE` on the `DATE` of each month. Cancel any time.
                          `${customText.billed} $${productInfo?.unit_amount /
                              100 || 0} 
                          ${customText.on_the} 
                              ${dd}
                          ${customText.each_month}
                          ${customText.cancel_anytyme}`
                        : // YOU WILL NOT BE BILLED UNTIL `DATE` FOR $`PRICE`
                          // trial is in seconds so we need to convert it to days
                          `${customText.not_billed_until} ${trialBillingDate} ${
                              customText.not_billed_for
                          } $${productInfo?.unit_amount / 100 || 0}`}
                </p>
                <div className="paying-now">
                    <p>{customText.pay_now}</p>
                    {productInfo && (
                        <h3>
                            {trial
                                ? '$0.00'
                                : `$${productInfo?.unit_amount / 100 || 0}`}
                        </h3>
                    )}
                </div>
            </div>

            <button
                disabled={isLoading || !stripe || !elements || !price_id}
                id="submit"
            >
                <span id="button-text">
                    {isLoading ? (
                        <Loader position="static" />
                    ) : location.pathname.includes('default/') ? (
                        customText.start_subscription_button
                    ) : (
                        customText.subscribe
                    )}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}
