import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Icon from '../../../common/components/Icon'
import AvatarBackground from '../../assets/provider-dashboard/avatar-background.png'
import { useClearAllAlerts } from '../../redux/clearAllAlerts'
import { getEligibilityColor } from '../../utils/getEligibilityColor'

export default function AlertsModal({
    show = false,
    onHide = () => {},
    alerts = [],
    user,
}) {
    const history = useHistory()
    const { clearAllAlerts } = useClearAllAlerts()

    if (!user) return null

    const { photo, first_name, last_name, eligibility, id } = user

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide()
            }}
            className="rpm-provider-record-alerts-modal"
        >
            <div className="rpm-provider-record-alerts-modal__alert-header">
                <Fragment>
                    <div className="rpm-provider-record-alerts-modal__alert-header__left">
                        <div className="rpm-provider-record-alerts-modal__alert-header__left__alert-icon-container">
                            <div className="rpm-provider-record-alerts-modal__alert-header__left__alert-icon-container__square" />
                            <p className="rpm-provider-record-alerts-modal__alert-header__left__alert-icon-container__icon">
                                !
                            </p>
                        </div>
                        <p className="rpm-provider-record-alerts-modal__alert-header__left__alert-text">
                            {alerts.length || 0} alerts
                        </p>
                    </div>
                    <div
                        className="rpm-provider-record-alerts-modal__alert-header__right"
                        role="button"
                        onClick={() => onHide()}
                    >
                        <Icon name="close" color={'#FFFFFF'} />
                    </div>
                </Fragment>
            </div>
            <div className="rpm-provider-record-alerts-modal__alert-body">
                <div className="rpm-provider-record-alerts-modal__alert-body__header">
                    <div className="rpm-provider-record-alerts-modal__alert-body__header__profile-container">
                        <div className="rpm-provider-record-alerts-modal__alert-body__header__profile-container__profile-image">
                            <img
                                src={photo || AvatarBackground}
                                alt="profile"
                                style={{
                                    border: photo ? '2px solid #c4c4c4' : 0,
                                    borderRadius: photo
                                        ? '0.35rem 0.35rem 0rem 0.35rem'
                                        : 0,
                                }}
                            />
                            {!photo && (
                                <div className="rpm-provider-record-alerts-modal__alert-body__header__profile-container__profile-image__initials">
                                    {first_name[0]}
                                    {last_name[0]}
                                </div>
                            )}
                        </div>
                        <div className="rpm-provider-record-alerts-modal__alert-body__header__profile-container__details">
                            {/*  <span className="rpm-provider-record-alerts-modal__alert-body__header__profile-container__details__tag">
                                                    NEW
                                                </span> */}
                            <span className="rpm-provider-record-alerts-modal__alert-body__header__profile-container__details__name">
                                {first_name} {last_name}
                            </span>
                            <span
                                className="rpm-provider-record-alerts-modal__alert-body__header__profile-container__details__diagnosis"
                                role="button"
                                onClick={() =>
                                    history.push(`/rpm/client-profile/${id}`)
                                }
                            >
                                View Profile{' '}
                                <Icon
                                    name="arrow-right"
                                    style={{ marginLeft: '.25rem' }}
                                />
                            </span>
                        </div>
                    </div>

                    <div
                        className="rpm-provider-record-alerts-modal__alert-body__header__status-container"
                        style={{
                            borderColor: getEligibilityColor({
                                eligibility,
                            }),
                        }}
                    >
                        <div
                            className="rpm-provider-record-alerts-modal__alert-body__header__status-container__dot"
                            style={{
                                backgroundColor: getEligibilityColor({
                                    eligibility,
                                }),
                            }}
                        />
                        <p
                            className="rpm-provider-record-alerts-modal__alert-body__header__status-container__text"
                            style={{
                                color: getEligibilityColor({
                                    eligibility,
                                }),
                            }}
                        >
                            {eligibility || 'Pending'}
                        </p>
                    </div>
                </div>
                <div className="rpm-provider-record-header-alert-popup-body">
                    <div
                        style={{
                            height: '80%',
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {alerts &&
                            alerts.map(
                                (
                                    { type, title, score, timestamp, user },
                                    index
                                ) => (
                                    <div
                                        className="rpm-provider-record-header-alert-popup-body-item"
                                        key={index}
                                        style={{
                                            marginBottom:
                                                index === alerts.length - 1
                                                    ? '1.5rem'
                                                    : '0',
                                        }}
                                    >
                                        <div className="rpm-provider-record-header-alert-popup-body-item-left">
                                            <Icon name="hrv" />
                                            <p>{title}</p>
                                        </div>
                                        <div className="rpm-provider-record-header-alert-popup-body-item-right">
                                            <div className="rpm-provider-record-header-alert-popup-body-item-right-text">
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {score}
                                                </p>
                                            </div>
                                            <div className="rpm-provider-record-header-alert-popup-body-item-right-date">
                                                <p>
                                                    {new Date(
                                                        timestamp
                                                    ).toLocaleDateString(
                                                        'en-US',
                                                        {
                                                            month: 'short',
                                                            day: 'numeric',
                                                        }
                                                    )}
                                                </p>
                                                <p>
                                                    {new Date(
                                                        timestamp
                                                    ).toLocaleTimeString(
                                                        'en-US',
                                                        {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                    <p
                        className="rpm-provider-record-header-alert-popup-body-item-clear-all"
                        role="button"
                        style={{
                            position: 'absolute',
                            bottom: '1.5rem',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                        onClick={() => {
                            clearAllAlerts({ userId: id }).then(() => {
                                onHide()
                            })
                        }}
                    >
                        Clear All
                    </p>
                </div>
            </div>
        </Modal>
    )
}
