import React from 'react'
import AddDateIcon from './AddDateIcon'
import AmexIcon from './AmexIcon'
import ArrowRightIcon from './ArrowRightIcon'
import AssessmentsIcon from './AssesstmentsIcon'
import AttachmentIcon from './AttachmentIcon'
import BioIcon from './BioIcon'
import CalendarIcon from './CalendarIcon'
import CaseIcon from './CaseIcon'
import CheckIcon from './CheckIcon'
import CheckRadioIcon from './CheckRadioIcon'
import CheckboxIcon from './CheckboxIcon'
import ChevronDownIcon from './ChevronDownIcon'
import ChevronLeft from './ChevronLeft'
import ChevronRight from './ChevronRight'
import CircleBoxIcon from './CircleBoxIcon'
import CircleCheckIcon from './CircleCheckIcon'
import CircleIcon from './CircleIcon'
import ClientIcon from './ClientIcon'
import ClientsIcon from './ClientsIcon'
import ClockIcon from './ClockIcon'
import CloseIcon from './CloseIcon'
import CompanyIcon from './CompanyIcon'
import CreateGroupIcon from './CreateGroupIcon'
import DashboardIcon from './DashboardIcon'
import DateIcon from './DateIcon'
import DeleteIcon from './DeleteIcon'
import DiagnosisIcon from './DiagnosisIcon'
import EmailIcon from './EmailIcon'
import EyeIcon from './EyeIcon'
import FaqIcon from './FaqIcon'
import FilterIcon from './FilterIcon'
import GroupIcon from './GroupIcon'
import HandClickIcon from './HandClickIcon'
import HeadsetIcon from './HeadsetIcon'
import HealthIdIcon from './HealthIdIcon'
import HelpIcon from './HelpIcon'
import HomeIcon from './HomeIcon'
import HrvIcon from './HrvIcon'
import InfoIcon from './InfoIcon'
import LabIcon from './LabIcon'
import LargeLineIcon from './LargeLineIcon'
import LineIcon from './LineIcon'
import LocationIcon from './LocationIcon'
import LockIcon from './LockIcon'
import LogoutIcon from './LogoutIcon'
import MastercardIcon from './MastercardIcon'
import MessageIcon from './MessageIcon'
import MicrophoneIcon from './MicrophoneIcon'
import MinimalistCheck from './MinimalistCheck'
import MinusIcon from './MinusIcon'
import ModernCalendar from './ModernCalendar'
import MoodIcon from './MoodIcon'
import NotesIcon from './NotesIcon'
import NotificationsIcon from './NotificationsIcon'
import NpiIcon from './NpiIcon'
import PayoutsIcon from './PayoutsIcon'
import PaypalIcon from './PaypalIcon'
import PenIcon from './PenIcon'
import PhoneIcon from './PhoneIcon'
import PlusIcon from './PlusIcon'
import ProtocolIcon from './ProtocolIcon'
import ProviderIcon from './ProviderIcon'
import RadioInputIcon from './RadioInputIcon'
import RecursIcon from './RecursIcon'
import RedirectIcon from './RedirectIcon'
import RoadmapIcon from './RoadmapIcon'
import RoadmapSecondaryIcon from './RoadmapSecondaryIcon'
import SearchIcon from './SearchIcon'
import SendIcon from './SendIcon'
import SettingsIcon from './SettingsIcon'
import ShieldIcon from './ShieldIcon'
import SimpleCheckIcon from './SimpleCheckIcon'
import SleepIcon from './SleepIcon'
import SortIcon from './SortIcon'
import SpainFlag from './SpainFlag'
import StepLineIcon from './StepLineIcon'
import StepsIcon from './StepsIcon'
import StripeIcon from './StripeIcon'
import SubscriptionIcon from './SubscriptionIcon'
import SubscriptionsIcon from './SubscriptionsIcon'
import SyncIcon from './SyncIcon'
import ThreeDotIcon from './ThreeDotIcon'
import USAFlag from './USAFlag'
import UserIcon from './UserIcon'
import VisaIcon from './VisaIcon'
import WalletIcon from './WalletIcon'
import WriteIcon from './WriteIcon'

const icons = {
    check: CheckIcon,
    user: UserIcon,
    lock: LockIcon,
    sort: SortIcon,
    home: HomeIcon,
    message: MessageIcon,
    notification: NotificationsIcon,
    settings: SettingsIcon,
    plus: PlusIcon,
    roadmap: RoadmapIcon,
    roadmapsecondary: RoadmapSecondaryIcon,
    minus: MinusIcon,
    circle: CircleIcon,
    group: GroupIcon,
    email: EmailIcon,
    phone: PhoneIcon,
    date: DateIcon,
    visa: VisaIcon,
    line: LineIcon,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    subscription: SubscriptionIcon,
    largeline: LargeLineIcon,
    send: SendIcon,
    dashboard: DashboardIcon,
    clients: ClientsIcon,
    subscriptions: SubscriptionsIcon,
    payouts: PayoutsIcon,
    threedots: ThreeDotIcon,
    close: CloseIcon,
    wallet: WalletIcon,
    clock: ClockIcon,
    circlecheck: CircleCheckIcon,
    amex: AmexIcon,
    mastercard: MastercardIcon,
    stripe: StripeIcon,
    paypal: PaypalIcon,
    calendar: CalendarIcon,
    search: SearchIcon,
    help: HelpIcon,
    logout: LogoutIcon,
    step: StepLineIcon,
    bio: BioIcon,
    info: InfoIcon,
    write: WriteIcon,
    simplecheck: SimpleCheckIcon,
    headset: HeadsetIcon,
    location: LocationIcon,
    company: CompanyIcon,
    checkradio: CheckRadioIcon,
    pen: PenIcon,
    delete: DeleteIcon,
    microphone: MicrophoneIcon,
    attachment: AttachmentIcon,
    creategroup: CreateGroupIcon,
    assessments: AssessmentsIcon,
    client: ClientIcon,
    filter: FilterIcon,
    'radio-input': RadioInputIcon,
    checkbox: CheckboxIcon,
    'es-NI-flag': SpainFlag,
    'en-US-flag': USAFlag,
    'arrow-right': ArrowRightIcon,
    sync: SyncIcon,
    faq: FaqIcon,
    'add-date': AddDateIcon,
    steps: StepsIcon,
    sleep: SleepIcon,
    hrv: HrvIcon,
    notes: NotesIcon,
    lab: LabIcon,
    'chevron-down': ChevronDownIcon,
    case: CaseIcon,
    'health-id': HealthIdIcon,
    shield: ShieldIcon,
    'modern-calendar': ModernCalendar,
    mood: MoodIcon,
    'minimalist-check': MinimalistCheck,
    redirect: RedirectIcon,
    'hand-click': HandClickIcon,
    diagnosis: DiagnosisIcon,
    provider: ProviderIcon,
    protocol: ProtocolIcon,
    npi: NpiIcon,
    'circle-box': CircleBoxIcon,
    recurs: RecursIcon,
    eye: EyeIcon,
}

const Icon = ({ name, size, color, style, active, className }) => {
    const IconComponent = icons[name]
    if (IconComponent)
        return <IconComponent {...{ size, color, style, active, className }} />
    else throw new Error('Icon name not found.')
}

export default Icon
