import React from 'react';
import useWindowSize from '../../../../common/useWindowResize';

export default function AddMoreParticipants({
  addMoreParticipants,
  setAddMoreParticipants,
  selectedConversation,
  editGroup,
  setSelectedConversation,
  search,
  setSearch,
  recipients,
  user,
}) {
  const { width } = useWindowSize();

  let isDesktop = width > 992;

  return (
    <div
      className="new-message"
      style={{
        maxHeight: isDesktop ? '43vh' : '55vh',
      }}
    >
      <div
        className="input-container"
        style={{
          marginTop: '1rem',
          marginBottom: '0',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <p>Select group members</p>
          {addMoreParticipants && (
            <button
              style={{
                backgroundColor: 'var(--primary-dark)',
                color: 'var(--primary-white)',
                padding: '0.5rem',
                width: 'max-content',
                borderRadius: '5px',
                fontSize: '12px',
              }}
              onClick={() => {
                editGroup({
                  participants: [
                    ...selectedConversation.participants.map(
                      (participant) => participant.id,
                    ),
                  ],
                });
                setAddMoreParticipants(false);
              }}
            >
              Save
            </button>
          )}
        </div>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
        />
      </div>
      {selectedConversation.participants.length > 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: isDesktop ? '35vw' : '90vw',
            padding: '0 1rem .5rem 1rem',
            cursor: 'pointer',
            paddingTop: '1rem',
          }}
        >
          {selectedConversation.participants.map((participant, idx) => {
            return (
              <img
                key={idx}
                onClick={() => {
                  if (participant.username !== user.username) {
                    setSelectedConversation({
                      ...selectedConversation,
                      participants: [
                        ...selectedConversation.participants.filter(
                          (p) => p.username !== participant.username,
                        ),
                      ],
                    });
                  }
                }}
                src={
                  participant.photo
                    ? participant.photo
                    : `https://ui-avatars.com/api/?name=${
                        participant.first_name[0] + participant.last_name[0]
                      }&background=718371&color=fff&size=128`
                }
                style={{
                  width: '2rem',
                  height: '2rem',
                  objectFit: 'contain',
                  borderRadius: '.3rem',
                }}
              />
            );
          })}
        </div>
      )}
      {selectedConversation.type === 'group' && (
        <div className="recipients">
          {recipients &&
            recipients
              .filter(
                // filter out selectedParticipants
                (recipient) =>
                  !selectedConversation.participants.find(
                    (participant) =>
                      participant.username === recipient.username,
                  ),
              )
              .map((recipient, idx) => {
                return (
                  <div
                    key={idx}
                    className="recipient"
                    style={{ cursor: 'pointer', width: '100%' }}
                    onClick={() => {
                      setSelectedConversation({
                        ...selectedConversation,
                        participants: [
                          ...selectedConversation.participants,
                          recipient,
                        ],
                      });
                    }}
                  >
                    <img
                      src={
                        recipient.photo
                          ? recipient.photo
                          : `https://ui-avatars.com/api/?name=${
                              recipient.first_name[0] + recipient.last_name[0]
                            }&background=718371&color=fff&size=128`
                      }
                    />
                    <h3 className="name">
                      {}
                      {recipient?.first_name} {recipient?.last_name}
                    </h3>
                    <p className="email">{recipient.email}</p>
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
}
