import axios from 'axios';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import config from '../../../common/config';
import {
  MESSAGES_SEND_PUSH_NOTIFICATIONS_BEGIN,
  MESSAGES_SEND_PUSH_NOTIFICATIONS_DISMISS_ERROR,
  MESSAGES_SEND_PUSH_NOTIFICATIONS_FAILURE,
  MESSAGES_SEND_PUSH_NOTIFICATIONS_SUCCESS,
} from './constants';

export function sendPushNotifications({
  user, // this is the user who sent the message
  message,
  counterpartId,
  type, // this is the type of message (photo, video, text)
  counterpart, // counterpart's first_name + last_name
}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: MESSAGES_SEND_PUSH_NOTIFICATIONS_BEGIN,
    });

    console.log('working');

    const sender = user.first_name;
    const counterpartTitle =
      counterpart || user.first_name + ' ' + user.last_name;
    const username = user.username;

    const isCoachOrAdmin =
      user.groups.includes('Coach') ||
      getState().user.user.groups.includes('Admin');

    const belongsToParentGuidance = user.company_name === 'ParentGuidance.org';

    let experienceId = '@kollynlund/noble-rn';

    if (isCoachOrAdmin && belongsToParentGuidance) {
      experienceId = '@kollynlund/parentguidance-coaching';
    }

    let url = `${
      belongsToParentGuidance
        ? config.parentGuidanceExpoUrl
        : config.nobleExpoUrl
    }/messages/${counterpartTitle}/${username}/${type}/${counterpart}`
      .replace(/ /g, '%20')
      .replace(/'/g, '%27');

    console.log('url', url);
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.pushNotificationsUrl}/pushnotifications/chat/${counterpartId}`,
        {
          sender,
          message,
          url,
          experienceId,
        },
        createAxiosConfigWithAuth(getState()),
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: MESSAGES_SEND_PUSH_NOTIFICATIONS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MESSAGES_SEND_PUSH_NOTIFICATIONS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSendPushNotificationsError() {
  return {
    type: MESSAGES_SEND_PUSH_NOTIFICATIONS_DISMISS_ERROR,
  };
}

export function useSendPushNotifications() {
  const dispatch = useDispatch();

  const { sendPushNotificationsPending, sendPushNotificationsError } =
    useSelector(
      (state) => ({
        sendPushNotificationsPending:
          state.messages.sendPushNotificationsPending,
        sendPushNotificationsError: state.messages.sendPushNotificationsError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(sendPushNotifications(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSendPushNotificationsError());
  }, [dispatch]);

  return {
    sendPushNotifications: boundAction,
    sendPushNotificationsPending,
    sendPushNotificationsError,
    dismissSendPushNotificationsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MESSAGES_SEND_PUSH_NOTIFICATIONS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        sendPushNotificationsPending: true,
        sendPushNotificationsError: null,
      };

    case MESSAGES_SEND_PUSH_NOTIFICATIONS_SUCCESS:
      // The request is success
      return {
        ...state,
        sendPushNotificationsPending: false,
        sendPushNotificationsError: null,
      };

    case MESSAGES_SEND_PUSH_NOTIFICATIONS_FAILURE:
      // The request is failed
      return {
        ...state,
        sendPushNotificationsPending: false,
        sendPushNotificationsError: action.data.error,
      };

    case MESSAGES_SEND_PUSH_NOTIFICATIONS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        sendPushNotificationsError: null,
      };

    default:
      return state;
  }
}
