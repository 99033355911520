import React from 'react'

export default function HandClickIcon({ size = 14, color = '#414042', style = {} }) {
    return (
        <svg
            width={size}
            height={1.5714 * size}
            viewBox="0 0 14 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <g clip-path="url(#clip0_3023_3334)">
                <path
                    d="M12.0533 9.17515C11.9281 9.17515 11.8095 9.18833 11.6844 9.21468C11.5526 8.4175 10.8608 7.81139 10.0241 7.81139C9.89896 7.81139 9.77378 7.82456 9.65519 7.85092C9.51684 7.06692 8.82507 6.46739 8.00154 6.46739C7.88954 6.46739 7.77096 6.48056 7.65896 6.50692V6.07868C7.65896 5.14974 6.9079 4.39868 5.97896 4.39868C5.05001 4.39868 4.29896 5.14974 4.29896 6.07868V10.6312C4.18696 10.6048 4.06837 10.5916 3.95637 10.5916C3.02743 10.5916 2.27637 11.3427 2.27637 12.2716V15.5723C2.27637 15.6316 2.28296 15.6843 2.29613 15.6975C2.4806 18.7149 4.98413 21.0735 8.00813 21.0735C11.0321 21.0735 13.7333 18.5041 13.7333 15.3483V10.8486C13.7333 9.91962 12.9823 9.16856 12.0533 9.16856V9.17515ZM4.97096 14.841C5.3399 14.841 5.64296 14.538 5.64296 14.169V6.08527C5.64296 5.89421 5.79449 5.74268 5.98554 5.74268C6.1766 5.74268 6.32813 5.89421 6.32813 6.08527V12.1464C6.32813 12.5154 6.63119 12.8184 7.00013 12.8184C7.36907 12.8184 7.67213 12.5154 7.67213 12.1464V8.16056C7.67213 7.96951 7.82366 7.81798 8.01472 7.81798C8.20578 7.81798 8.35731 7.96951 8.35731 8.16056V12.9502C8.35731 13.3192 8.66037 13.6222 9.02931 13.6222C9.39825 13.6222 9.70131 13.3192 9.70131 12.9502V9.49798C9.70131 9.30692 9.85284 9.15539 10.0439 9.15539C10.235 9.15539 10.3865 9.30692 10.3865 9.49798V13.9516C10.3865 14.3206 10.6895 14.6236 11.0585 14.6236C11.4274 14.6236 11.7305 14.3206 11.7305 13.9516V10.8552C11.7305 10.6641 11.882 10.5126 12.0731 10.5126C12.2641 10.5126 12.4157 10.6641 12.4157 10.8552V15.3549C12.4157 17.7728 10.4458 19.7427 8.0279 19.7427C5.61001 19.7427 3.64013 17.7794 3.64013 15.3615V12.2782C3.64013 12.0872 3.79166 11.9356 3.98272 11.9356C4.17378 11.9356 4.32531 12.0872 4.32531 12.2782V14.1756C4.32531 14.5446 4.62837 14.8476 4.99731 14.8476L4.97096 14.841Z"
                    fill={color}
                />
                <path
                    d="M5.97889 3.58175C6.34124 3.58175 6.63772 3.28528 6.63772 2.92293V1.01893C6.63772 0.656578 6.34124 0.360107 5.97889 0.360107C5.61654 0.360107 5.32007 0.656578 5.32007 1.01893V2.92293C5.32007 3.28528 5.61654 3.58175 5.97889 3.58175Z"
                    fill={color}
                />
                <path
                    d="M3.50168 5.40003C3.50168 5.03768 3.20521 4.74121 2.84285 4.74121H0.938853C0.5765 4.74121 0.280029 5.03768 0.280029 5.40003C0.280029 5.76239 0.5765 6.05886 0.938853 6.05886H2.84285C3.20521 6.05886 3.50168 5.76239 3.50168 5.40003Z"
                    fill={color}
                />
                <path
                    d="M3.15902 4.09571C3.28419 4.22089 3.4489 4.28677 3.62019 4.28677C3.79149 4.28677 3.96278 4.22089 4.08796 4.09571C4.3449 3.83877 4.3449 3.42371 4.08796 3.16677L2.73737 1.81618C2.48702 1.56583 2.05878 1.56583 1.80843 1.81618C1.55149 2.07312 1.55149 2.48818 1.80843 2.74512L3.15902 4.09571Z"
                    fill={color}
                />
                <path
                    d="M8.12001 4.28677C8.29789 4.28677 8.46259 4.22089 8.58118 4.09571L9.93177 2.74512C10.0569 2.61995 10.1228 2.45524 10.1228 2.27736C10.1228 2.09948 10.0569 1.93477 9.93177 1.81618C9.68142 1.56583 9.25977 1.56583 9.00283 1.81618L7.65224 3.16677C7.52706 3.29195 7.46118 3.45665 7.46118 3.63454C7.46118 3.81242 7.52706 3.97712 7.65224 4.1023C7.77742 4.22748 7.94212 4.29336 8.11342 4.29336L8.12001 4.28677Z"
                    fill={color}
                />
                <path
                    d="M8.4165 5.40003C8.4165 5.76239 8.71297 6.05886 9.07533 6.05886H10.9859C11.3483 6.05886 11.6447 5.76239 11.6447 5.40003C11.6447 5.03768 11.3483 4.74121 10.9859 4.74121H9.07533C8.71297 4.74121 8.4165 5.03768 8.4165 5.40003Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3023_3334">
                    <rect
                        width={size / 1.0406}
                        height={size * 1.48}
                        fill="white"
                        transform="translate(0.280029 0.360107)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
