// This is a legacy page, I deeply apologize for the inconvenience.
// I know, I know... I'm sorry.
// I tried to make this page as simple as possible.

import clsx from 'clsx';
import queryString from 'query-string';
import 'rc-slider/assets/index.css';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Masonry from 'react-masonry-css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers';
import { DesktopHeader, Loader } from '../common';
import useQuery from '../common/useQuery';
import useWindowResize from '../common/useWindowResize';
import { useFetchUser } from '../user/redux/hooks';
import ActionItemInfo from './components/ActionItemInfo/ActionItemInfo';
import CompetencyPageFooter from './components/CompetencyPage/CompetencyPageFooter';
import MainContainer from './components/CompetencyPage/MainContainer';
import ReviewingCard from './components/CompetencyPage/ReviewingCard';
import Responses from './components/Responses/Responses';
import ClearInterval from './hooks/useEffects/ClearInterval';
import FetchAssignedUsers from './hooks/useEffects/FetchAssignedUsers';
import FetchRoadmapData from './hooks/useEffects/FetchRoadmapData';
import NextCompetency from './hooks/useEffects/NextCompetency';
import SubmitRecentActivity from './hooks/useEffects/SubmitRecentActivity';
import {
  useFetchCompetencyActionItemAssessments,
  useFetchStageCompetencies,
} from './redux/hooks';

// create competency context
export const CompetencyContext = createContext();

export default function CompetencyPage() {
  const editorRef = React.useRef(null);

  const { actionItems, fetchCompetencyActionItemAssessments } =
    useFetchCompetencyActionItemAssessments();

  const history = useHistory();
  const { roadmapId, stageId, competencyId } = useParams();
  const location = useLocation();

  const windowSize = useWindowResize();

  const queryParams = queryString.parse(location.search);

  let actionItemId = Number(queryParams?.actionItemId);

  const [student, setStudent] = useState(null);
  const { competencies, fetchStageCompetencies } = useFetchStageCompetencies();

  const { user } = useFetchUser();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();
  const query = useQuery();

  // delete params function
  const deleteParam = (key) => {
    const params = new URLSearchParams(location.search);
    params.delete(key);
    history.push(`${location.pathname}?${params}`);
  };

  const studentId = query && Number(query.get('user'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const selectedCompetency = competencies[competencyId];

  const refetchCompetency = useCallback(async () => {
    fetchStageCompetencies({ roadmapId, stageId, competencyId }).catch(
      unauthorizedErrorHandler,
    );
  }, [
    fetchStageCompetencies,
    roadmapId,
    stageId,
    competencyId,
    unauthorizedErrorHandler,
  ]);

  const [allStages, setAllStages] = useState([]);
  const [nextCompetency, setNextCompetency] = useState(null);
  const [prevCompetency, setPrevCompetency] = useState(null);

  // find selectedCompetency index in allStages.competencies
  const selectedCompetencyIndex = useMemo(() => {
    if (!allStages || !selectedCompetency) return -1;
    return allStages.findIndex((s) =>
      s.competencies.find((c) => c.id === selectedCompetency.id),
    );
  }, [allStages, selectedCompetency, competencies]);

  useEffect(() => {
    if (!competencyId) {
      history.push('/dashboard/roadmaps');
      return;
    }
  }, [
    competencyId,
    history,
    studentId,
    fetchStageCompetencies,
    roadmapId,
    stageId,
    unauthorizedErrorHandler,
  ]);

  useEffect(() => {
    async function fetchCompetencyData() {
      if (!competencyId) return;

      fetchStageCompetencies({
        roadmapId,
        stageId,
        competencyId,
        studentId,
        attachment: true,
      });
    }

    fetchCompetencyData();
  }, [fetchStageCompetencies, roadmapId, stageId, competencyId, studentId]);

  let defaultBackLink = `/roadmap/${roadmapId}/`;

  if (studentId) {
    defaultBackLink = `/roadmap/${roadmapId}/?user=${studentId}`;
  }

  const defaultBackLinkParams = `?${
    !!studentId ? `user=${studentId}&` : ``
  }scrollToStage=${stageId}`;

  const [studentUser, setStudentUser] = useState(null);

  const [showActionItemContainer, setShowActionItemContainer] = useState(null); // null = not set, { roadmapId, stageId, competencyId, actionItemId } = set

  useEffect(() => {
    console.log('showActionItemContainer', showActionItemContainer);
  }, [showActionItemContainer]);

  const fetchActionItemHandlingErrors = useCallback(() => {
    fetchCompetencyActionItemAssessments({
      roadmapId,
      stageId,
      competencyId,
      actionItemId,
      attachment: true,
      student: studentId,
    }).catch((err) => {});
  }, [
    fetchCompetencyActionItemAssessments,
    roadmapId,
    stageId,
    competencyId,
    actionItemId,
    unauthorizedErrorHandler,
    history,
    studentId,
  ]);

  useEffect(() => {
    if (actionItemId) {
      fetchActionItemHandlingErrors();
    }
  }, [actionItemId, fetchActionItemHandlingErrors]);

  const selectedActionItem = showActionItemContainer
    ? actionItems[showActionItemContainer.actionItemId]
    : null;

  useEffect(() => {
    if (selectedActionItem) {
      console.log('selectedActionItem', selectedActionItem);
    }
  }, [selectedActionItem]);

  useEffect(() => {
    if (actionItemId) {
      setShowActionItemContainer({
        roadmapId,
        stageId,
        competencyId,
        actionItemId,
      });
    }
  }, [actionItemId]);

  let showRightColumn = false;
  if (user?.features?.competency_notes_journal_enabled) showRightColumn = true;
  if (selectedCompetency?.content) showRightColumn = true;

  let isDesktop = windowSize.width > 991;
  let isMobile = windowSize.width < 767;

  if (!selectedCompetency) {
    return <Loader />;
  }

  return (
    <CompetencyContext.Provider
      value={{
        setStudent,
        studentUser,
        setStudentUser,
        selectedCompetency,
        setNextCompetency,
        setPrevCompetency,
        selectedCompetencyIndex,
        setAllStages,
        allStages,
        nextCompetency,
        prevCompetency,
        query,
        user,
        editorRef,
        roadmapId,
        studentId,
        competencyId,
        fetchStageCompetencies,
        refetchCompetency,
        competencies,
        stageId,
        student,
        prevCompetency,
        defaultBackLink,
        defaultBackLinkParams,
        // when the user click on an action item
        setShowActionItemContainer,
        showActionItemContainer,
        showRightColumn,
        actionItemId,
        selectedActionItem,
      }}
    >
      <div className="roadmap-competency-page">
        <DesktopHeader />
        <div className="container-lg">
          <ReviewingCard
            studentId={studentId}
            studentUser={studentUser}
            showActionItemContainer={showActionItemContainer}
          />
          <Masonry
            breakpointCols={{ default: 1 }}
            className="my-masonry-grid"
            columnClassName={clsx('my-masonry-grid_column')}
          >
            <div
              className="assessments-and-discuss-wrapper"
              style={{
                gridTemplateColumns: `${
                  showActionItemContainer && isDesktop ? '1fr 0.55fr' : '1fr'
                }`,
                width: showActionItemContainer
                  ? '100%'
                  : !showActionItemContainer && isMobile
                  ? '100%'
                  : '70%',
                margin: showActionItemContainer ? '0' : '0 auto',
                transition: 'width 0.5s',
                paddingBottom:
                  showActionItemContainer && isMobile ? '290px' : '0',
              }}
            >
              <div
                className="assessment-and-action-container"
                style={{
                  maxHeight:
                    showActionItemContainer && isDesktop ? '47rem' : '100%',
                }}
              >
                {!showActionItemContainer && <MainContainer />}

                {showActionItemContainer && (
                  <ActionItemInfo
                    show={showActionItemContainer !== false}
                    onHide={() => {
                      setShowActionItemContainer(null);
                      // delete actionItemId from current path
                      deleteParam('actionItemId');
                    }}
                  />
                )}
              </div>
              {showActionItemContainer && <Responses />}
            </div>
          </Masonry>
          {!showActionItemContainer && <CompetencyPageFooter />}
        </div>
      </div>

      {/* UseEffects below this line */}
      <FetchAssignedUsers />
      <NextCompetency />
      <ClearInterval />
      <SubmitRecentActivity />
      <FetchRoadmapData />
      {/* UseEffects above this line */}
    </CompetencyContext.Provider>
  );
}

CompetencyPage.propTypes = {};
CompetencyPage.defaultProps = {};
