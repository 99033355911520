import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogout } from '../../../common/redux/logout'
import { useFetchAuthToken } from '../../../home/redux/fetchAuthToken'
import { useFetchUser } from '../../../user/redux/fetchUser'
import background from '../../assets/download-app/download-app-mobile-background.png'
import getItOnAppStore from '../../assets/download-app/get-it-on-app-store.svg'
import getItOnGooglePlay from '../../assets/download-app/get-it-on-google-play.svg'
import OverlaidBackground from '../../assets/download-app/overlaid-background.svg'

export default function MobileDownloadApp() {
    const { logout } = useLogout()
    const history = useHistory()

    const handleLogOut = useCallback(() => {
        logout()
        history.push('/log-in')
    }, [logout, history])

    const { authToken } = useFetchAuthToken()

    const { user } = useFetchUser()

    if (authToken && !user) return null

    return (
        <div
            className="rpm-download-app-mobile"
            style={{
                backgroundImage: `url(${OverlaidBackground})`,
            }}
        >
            {authToken && (
                <button
                    type="button"
                    onClick={handleLogOut}
                    className="rpm-download-app-mobile__logout"
                >
                    Log out
                </button>
            )}
            <div className="rpm-download-app-mobile__content">
                {authToken ? (
                    <h1>
                        {user.company_name} proudly partners with Noble to
                        provide Coaching.
                    </h1>
                ) : (
                    <h1>This feature is only available on the app.</h1>
                )}
                <div className="rpm-download-app-mobile__content__get-it-on">
                    <a
                        href="https://apps.apple.com/ni/app/noble/id1606431994?l=en"
                        target="_blank"
                        rel="noopener noreferrer"
                        role="button"
                    >
                        <img src={getItOnAppStore} alt="Get it on App store" />
                    </a>
                    <a
                        href="https://vqr.vc/WB9OG6bzW"
                        target="_blank"
                        rel="noopener noreferrer"
                        role="button"
                    >
                        <img
                            src={getItOnGooglePlay}
                            alt="Get it on Google play"
                        />
                    </a>
                </div>

                <img
                    src={background}
                    alt="background"
                    className="rpm-download-app-mobile__content__background"
                />
            </div>
        </div>
    )
}
