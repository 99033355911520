import React from 'react'
import Icon from '../../common/components/Icon'

export default function Messages() {
    return (
        <section className="chat-messages">
            <header className="chat-messages-header">
                <h2 className="chat-messages-header-title"> John Doe </h2>
                <Icon name="threedots" size={4} />
            </header>

            <div className="chat-messages-list">
                {Array(10)
                    .fill(0)
                    .map((_, index) => (
                        <div className="chat-message" key={index}>
                            {index % 2 !== 0 && (
                                <div className="chat-message-avatar not-mine" />
                            )}
                            <div
                                className={`chat-message-content ${
                                    index % 2 === 0 ? 'mine' : 'not-mine'
                                }`}
                            >
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit.
                            </div>

                            {index % 2 === 0 && (
                                <div className="chat-message-avatar mine" />
                            )}
                        </div>
                    ))}
            </div>

            <div className="chat-messages-input-container">
                <div className="chat-messages-input-wrapper">
                    <input
                        className="chat-messages-input"
                        placeholder="Type a message"
                    />
                    <Icon name="send" size={18} color="#6D786E" />
                </div>
            </div>
        </section>
    )
}
