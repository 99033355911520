import { useContext } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { AnalyticsContext } from '../Home';

function FilterCard() {
  const { filters, setFilters } = useContext(AnalyticsContext);

  return (
    <div className="filter-card">
      <h2>Filter</h2>
      <div className="input-container">
        <div className="form-group">
          <label htmlFor="startDate">Start Date</label>
          <DatePicker
            selected={filters.startDate}
            showTimeSelect
            minDate={new Date()}
            timeIntervals={15}
            dateFormat="MMMM d @ h:mm aa"
            onChange={(date) => {
              setFilters({ ...filters, startDate: date });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="endDate">End Date</label>
          <DatePicker
            selected={filters.endDate}
            showTimeSelect
            minDate={new Date()}
            timeIntervals={15}
            dateFormat="MMMM d @ h:mm aa"
            onChange={(date) => {
              setFilters({ ...filters, startDate: date });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subscription_status">Subscription Status</label>
          <Select
            placeholder="Subscription Status"
            styles={{
              control: (styles) => {
                return {
                  ...styles,
                  width: '100%',
                };
              },
              container: (styles) => {
                return {
                  ...styles,
                  width: '100%',
                };
              },
              valueContainer: (styles) => {
                return {
                  ...styles,
                  color: '#343434',
                };
              },
            }}
            options={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
              { value: 'none', label: 'None' },
              { value: 'cancelled', label: 'Cancelled' },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default FilterCard;
