import { useCallback, useContext, useEffect, useRef } from 'react';
import { useUnauthorizedErrorHandler } from '../../../../common/apiHelpers';
import { CompetencyContext } from '../../CompetencyPage';
import { useSubmitRecentCompetency } from '../../redux/submitRecentCompetency';

export default function SubmitRecentActivity() {
  const recentTimer = useRef(null);
  const { submitRecentCompetency } = useSubmitRecentCompetency();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();
  const { roadmapId, competencyId, studentId } = useContext(CompetencyContext);

  const submitRecentActivity = useCallback(() => {
    recentTimer.current = setTimeout(() => {
      submitRecentCompetency({
        roadmap_id: roadmapId,
        studentId,
        competency: competencyId,
      }).catch(unauthorizedErrorHandler);
    }, 3000);
  }, [
    roadmapId,
    competencyId,
    studentId,
    submitRecentCompetency,
    unauthorizedErrorHandler,
  ]);

  useEffect(() => {
    if (!!studentId) return;
    submitRecentActivity();
    return () => {
      clearTimeout(recentTimer.current);
    };
  }, [studentId, submitRecentActivity]);
  return null;
}
