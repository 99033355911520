import React, { useContext } from 'react';
import { useWindowSize } from '../../../../common/windowsSize';
import { InvitationContext } from '../InvitationContext';

export default function StepsCounter() {
  const size = useWindowSize();
  const { step } = useContext(InvitationContext);
  if (step < 4) {
    return (
      <p
        className="step"
        style={{ padding: size.width < 768 ? '0 1rem' : '0' }}
      >
        {step}/3
      </p>
    );
  } else {
    return <></>;
  }
}
