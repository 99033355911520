import React from "react";
import Icon from "../../common/components/Icon";

export default function Conversations() {
    return (
        <section className='chat-conversations'>
            <div className='chat-conversations-header'>
                <h1 className='chat-conversations-header-title'>
                    Messages
                         </h1>
                <Icon name='write' size={24} color='#000' />
            </div>
            <div className="chat-conversations-search-container">
                <Icon name='search' size={20} color='#363E44' />
                <input className="chat-conversations-search" placeholder="Search" />
            </div>
            <div className="chat-conversations-list">
                {
                    Array(10).fill(0).map((_, index) => (
                        <div className="chat-conversation" key={index}>
                            <div className="chat-conversation-info">
                                <div className="chat-conversation-avatar" />
                                <div className="chat-conversation-name-container">
                                    <p className='chat-conversation-name'> John Doe </p>
                                    <p className='chat-conversation-last-message'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                </div>
                            </div>
                            <p className='chat-conversation-last-message-time'> 2:30 PM </p>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}
