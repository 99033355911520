import axios from 'axios';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import config from '../../../common/config';
import {
  AUTH_SELF_SIGN_UP_BEGIN,
  AUTH_SELF_SIGN_UP_DISMISS_ERROR,
  AUTH_SELF_SIGN_UP_FAILURE,
  AUTH_SELF_SIGN_UP_SUCCESS,
} from './constants';

export function selfSignUp(args = {}) {
  return (dispatch) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: AUTH_SELF_SIGN_UP_BEGIN,
    });

    let withContract = args.withContract || false;

    let url = `${config.apiRootUrl}/auth/`;

    if (withContract) {
      url += 'self-sign-up-with-contract/';
    } else {
      url += 'self-sign-up/';
    }

    console.log('url', url);

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(url, args);
      doRequest.then(
        (res) => {
          dispatch({
            type: AUTH_SELF_SIGN_UP_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: AUTH_SELF_SIGN_UP_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSelfSignUpError() {
  return {
    type: AUTH_SELF_SIGN_UP_DISMISS_ERROR,
  };
}

export function useSelfSignUp() {
  const dispatch = useDispatch();

  const { selfSignUpPending, selfSignUpError } = useSelector(
    (state) => ({
      selfSignUpPending: state.auth.selfSignUpPending,
      selfSignUpError: state.auth.selfSignUpError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(selfSignUp(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSelfSignUpError());
  }, [dispatch]);

  return {
    selfSignUp: boundAction,
    selfSignUpPending,
    selfSignUpError,
    dismissSelfSignUpError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_SELF_SIGN_UP_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        selfSignUpPending: true,
        selfSignUpError: null,
      };

    case AUTH_SELF_SIGN_UP_SUCCESS:
      // The request is success
      return {
        ...state,
        selfSignUpPending: false,
        selfSignUpError: null,
      };

    case AUTH_SELF_SIGN_UP_FAILURE:
      // The request is failed
      return {
        ...state,
        selfSignUpPending: false,
        selfSignUpError: action.data.error,
      };

    case AUTH_SELF_SIGN_UP_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        selfSignUpError: null,
      };

    default:
      return state;
  }
}
