import React, { useContext } from 'react';
import Icon from '../../../common/components/Icon';
import LikeHand from '../../images/LikeHand.png';
import { Link } from 'react-router-dom';
import { InvitationContext } from '../InvitationContext';
import { useWindowSize } from '../../../../common/windowsSize';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

export default function Step5() {
  const { step, setStep, setFirstVals, setSelectedRoadmaps, firstVals } =
    useContext(InvitationContext);

  const size = useWindowSize();
  const { t } = useTranslation();

  if (step === 5) {
    return (
      <div className="step-5">
        <img
          src={LikeHand}
          className="like-hand"
          alt="drawing of a hand giving a like."
        />
        <h1>{t('dashboard.onboarding.fifth_step.title')}</h1>
        <p>
          {t('dashboard.onboarding.fifth_step.description', {
            name: firstVals?.first_name,
          })}
        </p>
        <button
          onClick={() => {
            setStep(1);
            setFirstVals({});
            setSelectedRoadmaps([]);
          }}
          className="invite-another"
        >
          <Icon name="plus" />{' '}
          {t('dashboard.onboarding.fifth_step.invite_another')}
        </button>
        {size.width < 768 && (
          <Link to="/">
            <p className="done">{t('dashboard.onboarding.fifth_step.done')}</p>
          </Link>
        )}
      </div>
    );
  } else return <div></div>;
}
