import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Icon from '../../../common/components/Icon'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useFetchAssessments } from '../../redux/fetchAssessments'
import { useFetchUnifiedSummary } from '../../redux/fetchUnifiedSummary'
import ActivityCard from './ActivityCard'

export default function ActivitySection({ params, setParams, userId }) {
  const {
    fetchUnifiedSummary,
    unifiedSummary,
    fetchUnifiedSummaryPending,
  } = useFetchUnifiedSummary()

  const { fetchAssessments, assessments } = useFetchAssessments()

  const [results, setResults] = React.useState([])

  useEffect(() => {
    fetchUnifiedSummary(params)
  }, [])

  useEffect(() => {
    fetchAssessments()
  }, [])

  useEffect(() => {
    console.log('unifiedSummary: ', unifiedSummary)

    if (unifiedSummary?.results && !params.resetResults) {
      const nextPage = unifiedSummary.next?.split('page=')[1]?.split('&')[0]
      setParams({
        ...params,
        page: Number(nextPage - 1),
      })
      setResults([
        ...results,
        ...unifiedSummary.results.filter(record => {
          const recordObj = record

          const date = recordObj.date
          const id = recordObj.id

          delete recordObj.date
          delete recordObj.id

          const values = Object.values(recordObj)
          const hasMeasurement = values.some(value => value !== null)

          recordObj.date = date
          recordObj.id = id

          return hasMeasurement
        }),
      ])
    }
  }, [unifiedSummary])

  useEffect(() => {
    if (params.resetResults) {
      setResults([])
      setParams({ ...params, resetResults: false })
      fetchUnifiedSummary({ ...params, page: 1 })
      return
    }
  }, [params.resetResults])

  const { t } = useTranslation()

  return (
    <div className="rpm-client-profile__container__right-body__records">
      {!unifiedSummary && results.length === 0 && (
        <div className="w-full gap-1 flex flex-col">
          {Array.from({ length: 10 }).map((_, idx) => (
            <LoaderCard key={idx} />
          ))}
        </div>
      )}
      {unifiedSummary && results.length === 0 && (
        <p
          className="rpm-client-profile__container__right-body__records__end"
          style={{ border: 'none' }}
        >
          {t('client_profile.no_activity_has_been_logged')}
        </p>
      )}

      {results && results.length > 0 && (
        <InfiniteScroll
          className="w-full gap-1 flex flex-col"
          pageStart={0}
          loadMore={() => {
            fetchUnifiedSummary({
              ...params,
              page: params.page + 1,
            })
          }}
          hasMore={unifiedSummary && unifiedSummary.next}
          loader={<LoaderCard />}
          useWindow={false}
        >
          {results &&
            results.length > 0 &&
            results
              .filter(
                record =>
                  record.assessments?.length ||
                  record.mood ||
                  record.moods.length > 0
              )
              .map((record, idx) => {
                /***
                 * record date is in the format of "2023-08-12", make it like "Aug 12, 2023"
                 */

                const dateStr = new Date(
                  record.date + 'T12:00:00'
                ).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })

                return (
                  <div
                    className="rpm-client-profile__container__right-body__records__record"
                    key={idx}
                  >
                    <div className="rpm-client-profile__container__right-body__records__record__header">
                      <p className="rpm-client-profile__container__right-body__records__record__header__date">
                        {dateStr}
                      </p>
                    </div>

                    <div className="rpm-client-profile__container__right-body__records__record__measurements">
                      {record.assessments &&
                        record.assessments.length > 0 &&
                        record.assessments.map((assessment, idx) => {
                          const icons = {
                            'GAD-7': 'notes',
                            'PHQ-9': 'hand-click',
                          }
                          return (
                            <ActivityCard
                              key={idx}
                              title={
                                assessment.assessment_name +
                                ' ' +
                                t('client_profile.assessment')
                              }
                              score={assessment.score | 0}
                              recordData={{
                                ...assessment,
                                userId,
                              }}
                              params={params}
                              setParams={setParams}
                              icon={
                                <Icon
                                  name={
                                    icons[assessment.assessment_name] || 'notes'
                                  }
                                  color="#343434"
                                  size={15}
                                />
                              }
                              link={assessment.assessment_result_link}
                              subtitle={`${t(
                                'client_profile.valid_until'
                              )}: ${dateStr}`}
                              color={assessment.color}
                              backgroundColor={assessment.bg_color || null}
                              ranges={
                                (assessments &&
                                  assessments.find(
                                    ({ name }) =>
                                      name === assessment.assessment_name
                                  )?.settings?.ranges) ||
                                null
                              }
                            />
                          )
                        })}
                      {record.activity_summary && (
                        <ActivityCard
                          title={t('client_profile.daily_steps')}
                          score={record?.activity_summary?.steps | 0}
                          icon={<Icon name="steps" color="#343434" size={15} />}
                          subtitle={`${t(
                            'client_profile.valid_until'
                          )}: ${dateStr}`}
                        />
                      )}

                      {record.program_engagement !== null &&
                        record.program_engagement !== 0 && (
                          <ActivityCard
                            title={t('client_profile.engagement')}
                            score={record.program_engagement}
                            icon={<Icon name="hand-click" size={18} />}
                            subtitle={`${t(
                              'client_profile.valid_until'
                            )}: ${dateStr}`}
                            hideScore={true}
                          />
                        )}

                      {record.sleep && (
                        <ActivityCard
                          title={t('client_profile.sleep')}
                          score={
                            record?.sleep?.total_time_in_bed
                              ? `${Math.floor(
                                  record?.sleep?.total_time_in_bed / 60
                                )}H`
                              : '0'
                          }
                          icon={<Icon name="sleep" />}
                          subtitle={`${t(
                            'client_profile.valid_until'
                          )}: ${dateStr}`}
                        />
                      )}
                      {record.hrv && record.hrv.length > 0 && (
                        <ActivityCard
                          title="HRV"
                          score={Math.floor(record.hrv[0].daily_rmssd) || 0}
                          subtitle={`Valid until: ${dateStr}`}
                          icon={<Icon name="hrv" size={18} />}
                        />
                      )}
                      {record.moods &&
                        record.moods.length > 0 &&
                        record.moods.map((mood, idx) => (
                          <ActivityCard
                            title={t('client_profile.emotional_check_in')}
                            score={Math.floor(mood.value) === 0 ? '--' : Math.floor(mood.value)}
                            subtitle={
                              mood?.mood_type_settings?.metric_name ||
                              'How are you feeling?'
                            }
                            icon={<Icon name="mood" />}
                            color={mood.color}
                            backgroundColor={mood.bg_color}
                            isMood={true}
                            notes={mood.notes}
                            createdAt={
                              // get the hour and minute
                              new Date(mood.created_at).toLocaleTimeString(
                                'en-US',
                                {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                }
                              )
                            }
                          />
                        ))}
                    </div>
                  </div>
                )
              })}
        </InfiniteScroll>
      )}

      {results && results.length > 0 && (
        <p className="rpm-client-profile__container__right-body__records__end">
          {t('client_profile.you_have_reached_the_end_of_this_health_history')}
        </p>
      )}
    </div>
  )
}

const LoaderCard = () => (
  <div className="activity-card-skeleton">
    <div className="activity-card-skeleton__profile" />
    <div className="activity-card-skeleton__name" />
    <div className="activity-card-skeleton__description" />
    <div className="activity-card-skeleton__score" />
  </div>
)
