import React from 'react'

export default function MoodIcon({ size = 22, color = '#343434' }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_2326_2392)">
                <path
                    d="M4.06882 6.22559C3.05122 6.22559 3.04482 7.81279 4.06882 7.81279C5.09282 7.81279 5.09282 6.22559 4.06882 6.22559Z"
                    fill={color}
                />
                <path
                    d="M10.5458 7.81279C11.5634 7.81279 11.5698 6.22559 10.5458 6.22559C9.52183 6.22559 9.52183 7.81279 10.5458 7.81279Z"
                    fill={color}
                />
                <path
                    d="M14.4561 7.56961C14.2641 3.78081 11.1345 0.76001 7.30092 0.76001C3.46732 0.76001 0.120117 3.97921 0.120117 7.94081C0.120117 11.9024 3.25612 15.0256 7.13452 15.1088C7.33292 18.8912 10.4625 21.9184 14.2961 21.9184C18.1297 21.9184 21.4769 18.6992 21.4769 14.7376C21.4769 10.776 18.3409 7.65281 14.4625 7.56961H14.4561ZM1.40012 7.94081C1.40012 4.68321 4.04332 2.04001 7.30092 2.04001C10.5585 2.04001 13.0353 4.53601 13.1825 7.65281C11.7425 7.87681 10.4433 8.52961 9.41932 9.48321C9.38732 9.45761 9.35532 9.43201 9.32332 9.40641C8.73452 9.01601 8.03052 8.81121 7.30092 8.81121C6.57132 8.81121 5.86732 9.01601 5.27212 9.40641C4.68332 9.79041 4.24172 10.3472 4.03052 10.9616C3.92172 11.2752 4.08812 11.6208 4.40172 11.736C4.70892 11.8448 5.06732 11.672 5.17612 11.3648C5.45132 10.5776 6.32812 10.0272 7.30092 10.0272C7.78092 10.0272 8.22892 10.1616 8.60012 10.392C7.85132 11.3712 7.34572 12.5488 7.18572 13.8352C3.98572 13.7712 1.40652 11.1536 1.40652 7.94081H1.40012ZM14.2961 20.6384C11.0449 20.6384 8.39532 17.9952 8.39532 14.7376C8.39532 11.48 11.0385 8.83681 14.2961 8.83681C17.5537 8.83681 20.1969 11.48 20.1969 14.7376C20.1969 17.9952 17.5537 20.6384 14.2961 20.6384Z"
                    fill={color}
                />
                <path
                    d="M11.0642 13.0288C10.0466 13.0288 10.0402 14.616 11.0642 14.616C12.0882 14.616 12.0882 13.0288 11.0642 13.0288Z"
                    fill={color}
                />
                <path
                    d="M17.5473 13.0288C16.5297 13.0288 16.5233 14.616 17.5473 14.616C18.5713 14.616 18.5713 13.0288 17.5473 13.0288Z"
                    fill={color}
                />
                <path
                    d="M17.1954 15.6464C16.8882 15.5376 16.5298 15.7104 16.421 16.0176C16.1458 16.8048 15.269 17.3552 14.2962 17.3552C13.3234 17.3552 12.4466 16.8048 12.1714 16.0176C12.0626 15.7104 11.7106 15.5376 11.397 15.6464C11.2434 15.6976 11.1218 15.8064 11.0514 15.9536C10.981 16.1008 10.9746 16.2672 11.0258 16.4144C11.2434 17.0352 11.685 17.5856 12.2674 17.9696C12.8562 18.36 13.5602 18.5648 14.2962 18.5648C15.0322 18.5648 15.7298 18.36 16.3186 17.9696C16.9074 17.5856 17.349 17.0288 17.5602 16.4144C17.669 16.1008 17.5026 15.7552 17.189 15.64L17.1954 15.6464Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_2326_2392">
                    <rect
                        width="21.3568"
                        height="21.1584"
                        fill="white"
                        transform="translate(0.120117 0.76001)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
