import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useLocation } from 'react-router-dom';
import usePrivateLabelledSettings from '../common/usePrivateLabelledSettingsHook';
// import PropTypes from 'prop-types';
import logo from './images/logo.png';
import woman from './images/woman.png';
import { useNobleCoachInvitation } from './redux/nobleCoachInvitation';

export default function CoachSignup() {
  const [validated, setValidated] = useState(false);
  const location = useLocation();

  const privateLabelledSettings = usePrivateLabelledSettings();
  const queryParams = qs.parse(location.search.slice(1));

  let signupCompanyName = privateLabelledSettings.signupCompanyNameField;

  if (!signupCompanyName && queryParams['company-name'])
    signupCompanyName = queryParams['company-name'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { nobleCoachInvitation } = useNobleCoachInvitation();
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = useCallback(
    (event, formState) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity()) {
        const data = {
          first_name: formState.formFirstName,
          last_name: formState.formLastName,
          email: formState.formEmail,
          phone_number: '+1 107 582-3407',
          bio: '',
          groups: 'Coach',
          roadmaps_info: 286,
          is_approved: true,
        };

        nobleCoachInvitation(data)
          .then(() => setSuccessMessage('Coach invited!'))
          .catch((error) => {
            console.log('SIGN UP ERROR', error);
          });
      }
      setValidated(true);
    },
    [nobleCoachInvitation],
  );

  return (
    <div className="coach-signup">
      <div className="login_sidebar mrm-mx-10">
        <img src={logo} className="logo" alt="company-logo" />
        <div className="login_form">
          <div className="form_c">
            <h3>Invite a coach</h3>
            <p></p>
            <InviteForm
              isLoading={false}
              validated={validated}
              handleSubmit={handleSubmit}
            />
            <p>{successMessage}</p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="login_content">
          <img src={woman} className="woman" alt="woman-illustration" />
          <h1>Sign up messaging</h1>
          <p>
            This is a chance to showcase what the app can do. Because what if
            the MHP didn’t give a full overview of all the benefits?
          </p>
        </div>
      </div>
    </div>
  );
}

CoachSignup.propTypes = {};
CoachSignup.defaultProps = {};

function InviteForm({ isLoading, validated, handleSubmit, passwordError }) {
  const [formState, setFormState] = useState({});

  const handleFormFieldChange = (event) => {
    const updatedFormState = Object.assign({}, formState);
    updatedFormState[event.target.id] = event.target.value;
    setFormState(updatedFormState);
  };

  return (
    <Row>
      {isLoading ? (
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Submitting...</span>
          </Spinner>
        </Col>
      ) : (
        <Col className="mrm-mt-0_5">
          {/*<h1 className="mrm-mb-2_5">{signupCompanyName ? `${signupCompanyName} Sign up` : "Sign up"}</h1>*/}
          <Form
            className="mrm-mb-1_5"
            validated={validated}
            onSubmit={(event) => handleSubmit(event, formState)}
          >
            <Form.Group controlId="formFirstName">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3391 12.3286C12.638 11.7402 11.84 11.2734 10.9794 10.9485C12.1066 10.2968 12.9855 9.3001 13.4816 8.11081C13.9777 6.92152 14.0638 5.60518 13.7265 4.36327C13.3892 3.12136 12.6472 2.02229 11.6141 1.23428C10.581 0.446267 9.31367 0.0127224 8.00607 0C6.70214 0.0168855 5.43954 0.452137 4.41049 1.23949C3.38144 2.02684 2.64232 3.12317 2.30565 4.36155C1.96898 5.59993 2.05321 6.91253 2.54551 8.09952C3.03782 9.2865 3.91122 10.2828 5.03276 10.9369C4.1734 11.2695 3.37603 11.7398 2.67299 12.3286C1.19674 13.5731 0.245905 15.3142 0.00645229 17.2113C-0.00747062 17.3094 0.00120489 17.4093 0.0318382 17.5037C0.0624716 17.598 0.11428 17.6844 0.183435 17.7564C0.248608 17.8325 0.329874 17.8937 0.421571 17.9358C0.513268 17.9779 0.613193 17.9998 0.714383 18H15.2978C15.3986 17.9976 15.4978 17.9747 15.5891 17.9328C15.6805 17.8908 15.7621 17.8308 15.8287 17.7564C15.8937 17.6821 15.9421 17.5952 15.9705 17.5012C15.999 17.4073 16.007 17.3085 15.9939 17.2113C15.7531 15.3172 14.8074 13.5778 13.3391 12.3286ZM3.51071 5.89175C3.52466 5.02089 3.80012 4.1735 4.30249 3.45604C4.80485 2.73858 5.51171 2.18306 6.33426 1.85925C7.15681 1.53545 8.05836 1.45781 8.92566 1.63608C9.79296 1.81436 10.5873 2.24061 11.2089 2.86127C11.8306 3.48193 12.2517 4.26933 12.4195 5.12455C12.5873 5.97976 12.4943 6.86464 12.152 7.66803C11.8098 8.47141 11.2337 9.15747 10.496 9.64001C9.7584 10.1225 8.89213 10.38 8.00607 10.3802C6.80541 10.3679 5.65852 9.88888 4.81613 9.0478C3.97375 8.20673 3.50441 7.07202 3.51071 5.89175ZM1.5757 16.6082C1.902 15.3553 2.61009 14.2303 3.6051 13.384C4.82735 12.3443 6.38968 11.772 8.00607 11.772C9.62246 11.772 11.1848 12.3443 12.407 13.384C13.402 14.2303 14.1101 15.3553 14.4364 16.6082H1.5757Z"
                  fill="#6D786E"
                />
              </svg>
              <Form.Control
                required
                type="text"
                placeholder="First Name"
                value={formState.formFirstName || ''}
                onChange={handleFormFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3391 12.3286C12.638 11.7402 11.84 11.2734 10.9794 10.9485C12.1066 10.2968 12.9855 9.3001 13.4816 8.11081C13.9777 6.92152 14.0638 5.60518 13.7265 4.36327C13.3892 3.12136 12.6472 2.02229 11.6141 1.23428C10.581 0.446267 9.31367 0.0127224 8.00607 0C6.70214 0.0168855 5.43954 0.452137 4.41049 1.23949C3.38144 2.02684 2.64232 3.12317 2.30565 4.36155C1.96898 5.59993 2.05321 6.91253 2.54551 8.09952C3.03782 9.2865 3.91122 10.2828 5.03276 10.9369C4.1734 11.2695 3.37603 11.7398 2.67299 12.3286C1.19674 13.5731 0.245905 15.3142 0.00645229 17.2113C-0.00747062 17.3094 0.00120489 17.4093 0.0318382 17.5037C0.0624716 17.598 0.11428 17.6844 0.183435 17.7564C0.248608 17.8325 0.329874 17.8937 0.421571 17.9358C0.513268 17.9779 0.613193 17.9998 0.714383 18H15.2978C15.3986 17.9976 15.4978 17.9747 15.5891 17.9328C15.6805 17.8908 15.7621 17.8308 15.8287 17.7564C15.8937 17.6821 15.9421 17.5952 15.9705 17.5012C15.999 17.4073 16.007 17.3085 15.9939 17.2113C15.7531 15.3172 14.8074 13.5778 13.3391 12.3286ZM3.51071 5.89175C3.52466 5.02089 3.80012 4.1735 4.30249 3.45604C4.80485 2.73858 5.51171 2.18306 6.33426 1.85925C7.15681 1.53545 8.05836 1.45781 8.92566 1.63608C9.79296 1.81436 10.5873 2.24061 11.2089 2.86127C11.8306 3.48193 12.2517 4.26933 12.4195 5.12455C12.5873 5.97976 12.4943 6.86464 12.152 7.66803C11.8098 8.47141 11.2337 9.15747 10.496 9.64001C9.7584 10.1225 8.89213 10.38 8.00607 10.3802C6.80541 10.3679 5.65852 9.88888 4.81613 9.0478C3.97375 8.20673 3.50441 7.07202 3.51071 5.89175ZM1.5757 16.6082C1.902 15.3553 2.61009 14.2303 3.6051 13.384C4.82735 12.3443 6.38968 11.772 8.00607 11.772C9.62246 11.772 11.1848 12.3443 12.407 13.384C13.402 14.2303 14.1101 15.3553 14.4364 16.6082H1.5757Z"
                  fill="#6D786E"
                />
              </svg>{' '}
              <Form.Control
                required
                type="text"
                placeholder="Last Name"
                value={formState.formLastName || ''}
                onChange={handleFormFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3516 0H2.83303C2.08166 0 1.36107 0.302187 0.829774 0.840083C0.298479 1.37798 0 2.10752 0 2.86822V11.5464C0 12.3071 0.298479 13.0367 0.829774 13.5746C1.36107 14.1125 2.08166 14.4147 2.83303 14.4147H15.3516C16.103 14.4147 16.8236 14.1125 17.3549 13.5746C17.8862 13.0367 18.1846 12.3071 18.1846 11.5464V2.86822C18.1846 2.10752 17.8862 1.37798 17.3549 0.840083C16.8236 0.302187 16.103 0 15.3516 0ZM2.83303 1.47088H15.3516C15.7188 1.47087 16.0711 1.6177 16.3319 1.8794C16.5926 2.14111 16.7407 2.4965 16.7439 2.86822V3.27272L9.9156 8.43306C9.67757 8.61216 9.38889 8.70887 9.09232 8.70887C8.79576 8.70887 8.50708 8.61216 8.26905 8.43306L1.44073 3.27272V2.86822C1.44392 2.4965 1.59202 2.14111 1.85278 1.8794C2.11354 1.6177 2.46585 1.47087 2.83303 1.47088ZM15.3516 12.9438H2.83303C2.46585 12.9438 2.11354 12.797 1.85278 12.5353C1.59202 12.2736 1.44392 11.9182 1.44073 11.5464V5.11132L7.39735 9.6588C7.88527 10.0326 8.48043 10.2349 9.09232 10.2349C9.70421 10.2349 10.2994 10.0326 10.7873 9.6588L16.7439 5.11132V11.5464C16.7407 11.9182 16.5926 12.2736 16.3319 12.5353C16.0711 12.797 15.7188 12.9438 15.3516 12.9438Z"
                  fill="#6D786E"
                />
              </svg>
              <Form.Control
                required
                type="email"
                placeholder="Email"
                value={formState.formEmail || ''}
                onChange={handleFormFieldChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Send Invitation
            </Button>
          </Form>
        </Col>
      )}
    </Row>
  );
}
