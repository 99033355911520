import clsx from 'clsx';
import React from 'react';
import { useWindowSize } from '../../../../common/windowsSize';

export default function ReviewingCard({ byRoadmap = false, ...props }) {
  const { studentId, studentUser, showActionItemContainer } = props;

  const size = useWindowSize();
  let isMobile = size.width < 767;

  return (
    <React.Fragment>
      {studentId ? (
        <div
          className={clsx('reviewing-card mrm-mb-1')}
          style={{
            width: showActionItemContainer
              ? '100%'
              : !showActionItemContainer && isMobile
              ? '100%'
              : byRoadmap
              ? '60%'
              : '70%',
            margin: showActionItemContainer ? '1rem 0' : '1rem auto',
            transition: 'width 0.5s',
          }}
        >
          Reviewing: {studentUser?.first_name} {studentUser?.last_name}
        </div>
      ) : null}
    </React.Fragment>
  );
}
