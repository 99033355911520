import React, { useContext } from 'react';
import { useWindowSize } from '../../../../common/windowsSize';
import Roadmap from '../Roadmap';
import Icon from '../../../common/components/Icon';
import { InvitationContext } from '../InvitationContext';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

export default function Step4({ body }) {
  const {
    isLoading,
    setStep,
    firstVals,
    selectedRoadmaps,
    subSelected,
    saveButton,
    step,
  } = useContext(InvitationContext);
  const size = useWindowSize();

  const { t } = useTranslation();
  if (body) {
    return (
      <>
        {step === 4 && !isLoading && (
          <div className={`step-4 ${size.width < 768 ? 'mrm-px-1' : ''}`}>
            <div className="header">
              <p>{t('dashboard.onboarding.fourth_step.client_information')}</p>
              <button onClick={() => setStep(1)}>
                <p>{t('dashboard.onboarding.fourth_step.edit')}</p>
              </button>
            </div>
            <div className="client-info">
              <h1 className={size.width < 768 ? '' : 'mrm-pt-1'}>
                {firstVals?.first_name}
              </h1>
              <p>{firstVals?.email}</p>
            </div>
            <hr />
            <div className="header">
              <p>{t('dashboard.onboarding.fourth_step.assigned_roadmap')}</p>
              <button onClick={() => setStep(2)}>
                <p>{t('dashboard.onboarding.fourth_step.edit')}</p>
              </button>
            </div>
            <div className="roadmaps-grid">
              {selectedRoadmaps?.map((r) => (
                <Roadmap data={r} key={r.id} hideProgress={true} />
              ))}
            </div>
            <hr />
            <div className="header">
              <p>{t('dashboard.onboarding.fourth_step.subscription')}</p>
              <button onClick={() => setStep(3)}>
                <p>{t('dashboard.onboarding.fourth_step.edit')}</p>
              </button>
            </div>
            <div className={`invitation-sub `}>
              <h3>{subSelected?.name}</h3>

              <p>
                {subSelected?.price?.unit_amount / 100} /{' '}
                {t('dashboard.onboarding.fourth_step.month_abbr')}
              </p>
            </div>
          </div>
        )}

        {step < 4 && size.width < 768 && (
          <div className="steps-container-lines">
            <Icon name="step" color={step === 1 ? '#343434' : '#C6C4C4'} />
            <Icon name="step" color={step === 2 ? '#343434' : '#C6C4C4'} />
            <Icon name="step" color={step === 3 ? '#343434' : '#C6C4C4'} />
          </div>
        )}

        <div className="invitation-button-container">
          {step === 4 && !isLoading ? saveButton : ''}
        </div>
      </>
    );
  } else if (step === 4) {
    return (
      <div
        className={`step-4 ${size.width < 768 ? 'mrm-px-1' : ''}`}
        style={{
          marginTop: size.width < 768 ? '' : '-1rem',
        }}
      >
        <h1>{t('dashboard.onboarding.fourth_step.title')}</h1>
      </div>
    );
  } else {
    return <></>;
  }
}
