import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_CLEAR_ALL_ALERTS_BEGIN,
    RPM_CLEAR_ALL_ALERTS_SUCCESS,
    RPM_CLEAR_ALL_ALERTS_FAILURE,
    RPM_CLEAR_ALL_ALERTS_DISMISS_ERROR,
} from './constants'

import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function clearAllAlerts(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_CLEAR_ALL_ALERTS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.delete(
                `${config.apiRootUrl}/rpm/alerts/clear-all/${args.userId}/`,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_CLEAR_ALL_ALERTS_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_CLEAR_ALL_ALERTS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissClearAllAlertsError() {
    return {
        type: RPM_CLEAR_ALL_ALERTS_DISMISS_ERROR,
    }
}

export function useClearAllAlerts() {
    const dispatch = useDispatch()

    const { clearAllAlertsPending, clearAllAlertsError } = useSelector(
        state => ({
            clearAllAlertsPending: state.rpm.clearAllAlertsPending,
            clearAllAlertsError: state.rpm.clearAllAlertsError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(clearAllAlerts(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissClearAllAlertsError())
    }, [dispatch])

    return {
        clearAllAlerts: boundAction,
        clearAllAlertsPending,
        clearAllAlertsError,
        dismissClearAllAlertsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_CLEAR_ALL_ALERTS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                clearAllAlertsPending: true,
                clearAllAlertsError: null,
            }

        case RPM_CLEAR_ALL_ALERTS_SUCCESS:
            // The request is success
            return {
                ...state,
                clearAllAlertsPending: false,
                clearAllAlertsError: null,
            }

        case RPM_CLEAR_ALL_ALERTS_FAILURE:
            // The request is failed
            return {
                ...state,
                clearAllAlertsPending: false,
                clearAllAlertsError: action.data.error,
            }

        case RPM_CLEAR_ALL_ALERTS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                clearAllAlertsError: null,
            }

        default:
            return state
    }
}
