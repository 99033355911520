import uniqBy from 'lodash/uniqBy';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUnauthorizedErrorHandler } from '../../../../common/apiHelpers';
import { CompetencyContext } from '../../CompetencyPage';
import { useFetchRoadmap } from '../../redux/fetchRoadmap';
import { useFetchRoadmapStagesInfinite } from '../../redux/fetchRoadmapStagesInfinite';

export default function FetchRoadmapData() {
  const {
    roadmapId,
    query,
    studentId,
    user,
    fetchStageCompetencies,
    competencies,
    setAllStages,
  } = useContext(CompetencyContext);
  const history = useHistory();
  const { unauthorizedErrorHandler } = useUnauthorizedErrorHandler();
  const { roadmaps, fetchRoadmap } = useFetchRoadmap();
  const { stages, fetchRoadmapStagesInfinite } =
    useFetchRoadmapStagesInfinite();

  useEffect(() => {
    if (!roadmapId || !query || !user) {
      return;
    }
    const effectiveStudentId = studentId || user.id;
    async function fetchRoadmapData() {
      await fetchRoadmap({ roadmapId });
      const stages = await fetchRoadmapStagesInfinite({
        roadmapId,
        studentId: effectiveStudentId,
        ordering: 'order',
      });
      for (const stage of stages) {
        stage.competencies = await fetchStageCompetencies({
          roadmapId,
          studentId: effectiveStudentId,
          stageId: stage.id,
        });
        setAllStages((s) =>
          uniqBy([...s, stage], 'id').sort((a, b) =>
            a.order > b.order ? 1 : -1,
          ),
        );
      }
    }
    fetchRoadmapData()
      .catch(unauthorizedErrorHandler)
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          history.push('/dashboard/roadmaps');
        } else {
          throw err;
        }
      });
  }, [
    query,
    fetchRoadmap,
    studentId,
    user,
    roadmapId,
    fetchRoadmapStagesInfinite,
    fetchStageCompetencies,
    unauthorizedErrorHandler,
    history,
  ]);

  useEffect(() => {
    if (!roadmaps[roadmapId]) return;
    const newStageData = roadmaps[roadmapId].stage_ids
      .map((sid) => stages[sid])
      .filter((s) => !!s);
    newStageData.forEach((s) => {
      s.competencies = s.competency_ids
        .map((cid) => competencies[cid])
        .filter((c) => !!c);
      s.competencies.sort((a, b) => {
        if (a.order != null && b.order != null)
          return a.order > b.order ? 1 : -1;
        return 0;
      });
    });
    newStageData.sort((a, b) => (a.order > b.order ? 1 : -1));
    setAllStages(newStageData);
  }, [roadmapId, roadmaps, stages, competencies]);

  return null;
}
