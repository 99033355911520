import { useEffect } from 'react';
import { useUnauthorizedErrorHandler } from '../../../common/apiHelpers';
import { useFetchGroupChats } from '../../messages/redux/fetchGroupChats';
import { useFetchUser } from '../../user/redux/fetchUser';
import { useGetSubscriptionByCoachAndUser } from '../../user/redux/getSubscriptionByCoachAndUser';
import { useFetchAuthToken } from '../redux/fetchAuthToken';

export default function PendoEffect() {
  const { userApproved, authToken } = useFetchAuthToken();
  const { fetchGroupChats } = useFetchGroupChats();
  const { getSubscriptionByCoachAndUser } = useGetSubscriptionByCoachAndUser();
  const { user } = useFetchUser();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();

  const userIsAuthenticatedAndApproved = user && userApproved && authToken;

  useEffect(() => {
    if (userIsAuthenticatedAndApproved) {
      console.log(user);
      if (window.pendo !== undefined) {
        pendo.initialize({
          visitor: {
            id: user.id, // Required if user is logged in
            email: user.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: user.first_name + ' ' + user.last_name, // Recommended if using Pendo Feedback
            role: user.groups,

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      }
      const getSubscription = async () => {
        const data = await getSubscriptionByCoachAndUser({
          id: user.id,
        })
          .then((res) => {
            console.table('getSubscription: ', res);
            if (window.pendo !== undefined) {
              pendo.initialize({
                account: {
                  id: res.data.id, // Required if using Pendo Feedback
                  name: res.data.plan?.name, // Optional
                  is_paying: res.data?.current_state == 'active' ? true : false, // Recommended if using Pendo Feedback
                  monthly_value: res.data?.plan?.unit_amount, // Recommended if using Pendo Feedback
                  // planLevel:    // Optional
                  planPrice: res.data.plan?.unit_amount, // Optional
                  creationDate: res.data?.created_at, // Optional
                  stripe_connected_account: user?.stripe_connected_account, // Optional

                  // You can add any additional account level key-values here,
                  // as long as it's not one of the above reserved names.
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        console.table(data);
      };
      getSubscription();
    }
  }, [
    userIsAuthenticatedAndApproved,
    fetchGroupChats,
    unauthorizedErrorHandler,
  ]);
  return null;
}
