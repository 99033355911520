import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import * as serviceWorker from './serviceWorker'
import './styles/index.scss'
window.React = React

import * as Sentry from '@sentry/react'

if (process.env.REACT_APP_ENV === 'production')
    Sentry.init({
        dsn:
            'https://0af7bbecb6cb4c94a100f41464d23701@o1107721.ingest.sentry.io/4505160572207104',
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_VERSION,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
