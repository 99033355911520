import { Formik } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import Icon from '../../../common/components/Icon'
import { useAddUser } from '../../../dashboard/redux/addUser'
import ReactPhoneInput from 'react-phone-input-2'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function NewStaffModal({ show, onHide = () => {} }) {
    const { addUser } = useAddUser()
    const handleSubmit = async (values, { setSubmitting }) => {
        const {
            first_name,
            last_name,
            suffix,
            email,
            phone_number,
            provider_npi,
            groups,
        } = values

        const payload = {
            first_name,
            last_name: suffix ? `${last_name} ${suffix}` : last_name,
            email,
            phone_number,
            provider_npi,
            groups,
            coach_write_only: [],
            cohort: [],
        }

        console.log(payload)

        addUser(payload).then(() => onHide())
    }

    const chooseAccessLevel = ({ level, setFieldValue, values }) => {
        if (values.groups.includes(level))
            setFieldValue(
                'groups',
                values.groups.filter(l => l !== level)
            )
        else setFieldValue('groups', [...values.groups, level])
    }

    const { t } = useTranslation()

    if (!t('provider_dashboard')) return null

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required(
            t('provider_dashboard.first_name') +
                ' ' +
                t('provider_dashboard.is_required')
        ),
        last_name: Yup.string().required(
            t('provider_dashboard.last_name') +
                ' ' +
                t('provider_dashboard.is_required')
        ),
        suffix: Yup.string(),
        email: Yup.string()
            .email('Invalid email')
            .required(
                t('provider_dashboard.email') +
                    ' ' +
                    t('provider_dashboard.is_required')
            ),
        phone_number: Yup.string().required(
            t('provider_dashboard.phone_number') +
                ' ' +
                t('provider_dashboard.is_required')
        ),
        provider_npi: Yup.string().required(
            t('provider_dashboard.provider_npi') +
                ' ' +
                t('provider_dashboard.is_required')
        ),
        groups: Yup.array().required(
            t('provider_dashboard.access_level') +
                ' ' +
                t('provider_dashboard.is_required')
        ),
    })

    return (
        <Modal show={show} onHide={onHide} className="new-staff-modal">
            <h1> {t('provider_dashboard.add_staff')}</h1>

            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    suffix: '',
                    email: '',
                    phone_number: '',
                    provider_npi: '',
                    groups: [],
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="first_name" className="sr-only">
                                {t('provider_dashboard.first_name')}
                            </label>
                            <div className="input-wrapper">
                                <div className="icon-wrapper">
                                    <Icon name="user" color={'#6D786E'} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="first_name"
                                    placeholder={
                                        t('provider_dashboard.first_name') + '*'
                                    }
                                    onChange={handleChange}
                                    value={values.first_name}
                                />
                            </div>
                            {errors.first_name && touched.first_name && (
                                <div className="error-message">
                                    {errors.first_name}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="last_name" className="sr-only">
                                {t('provider_dashboard.last_name')}
                            </label>
                            <div className="input-wrapper">
                                <div className="icon-wrapper">
                                    <Icon name="user" color={'#6D786E'} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="last_name"
                                    placeholder={
                                        t('provider_dashboard.last_name') + '*'
                                    }
                                    onChange={handleChange}
                                    value={values.last_name}
                                />
                            </div>

                            {errors.last_name && touched.last_name && (
                                <div className="error-message">
                                    {errors.last_name}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="suffix" className="sr-only">
                                {t('provider_dashboard.suffix')}
                            </label>
                            <div className="input-wrapper">
                                <div className="icon-wrapper">
                                    <Icon name="user" color={'#6D786E'} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="suffix"
                                    placeholder={`${t(
                                        'provider_dashboard.suffix'
                                    )} (i.e., M.D., P.A.)`}
                                    onChange={handleChange}
                                    value={values.suffix}
                                />
                            </div>

                            {errors.suffix && touched.suffix && (
                                <div className="error-message">
                                    {errors.suffix}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="email" className="sr-only">
                                {t('provider_dashboard.email')}
                            </label>
                            <div className="input-wrapper">
                                <div className="icon-wrapper">
                                    <Icon
                                        name="email"
                                        color={'#6D786E'}
                                        size={13}
                                    />
                                </div>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder={
                                        t('provider_dashboard.email') + '*'
                                    }
                                    onChange={handleChange}
                                    value={values.email}
                                />
                            </div>

                            {errors.email && touched.email && (
                                <div className="error-message">
                                    {errors.email}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone_number" className="sr-only">
                                {t('provider_dashboard.phone_number')}
                            </label>

                            <div className="input-wrapper">
                                <div className="icon-wrapper">
                                    <Icon
                                        name="phone"
                                        color={'#6D786E'}
                                        size={14}
                                    />
                                </div>
                                <ReactPhoneInput
                                    defaultCountry="us"
                                    value={values.phone_number}
                                    placeholder={
                                        t('provider_dashboard.phone_number') +
                                        '*'
                                    }
                                    inputExtraProps={{
                                        name: 'phone_number',
                                        id: 'phone_number',
                                    }}
                                    onChange={phoneNumber => {
                                        let phoneNumberString = phoneNumber.replace(
                                            /[() -]/g,
                                            ''
                                        )
                                        // if it is longer than 15 characters then return
                                        if (phoneNumberString.length > 15)
                                            return

                                        setFieldValue(
                                            'phone_number',
                                            phoneNumberString
                                        )
                                        console.log(
                                            'Phone Number',
                                            values.phone_number
                                        )
                                    }}
                                />
                            </div>

                            {errors.phone_number && touched.phone_number && (
                                <div className="error-message">
                                    {errors.phone_number}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="provider_npi" className="sr-only">
                                {t('provider_dashboard.provider_npi')}
                            </label>

                            <div className="input-wrapper">
                                <div className="icon-wrapper">
                                    <Icon name="npi" color={'#6D786E'} />
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="provider_npi"
                                    placeholder={
                                        t('provider_dashboard.provider_npi') +
                                        '*'
                                    }
                                    onChange={handleChange}
                                    value={values.provider_npi}
                                />
                            </div>

                            {errors.provider_npi && touched.provider_npi && (
                                <div className="error-message">
                                    {errors.provider_npi}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="access_level">
                                {t('provider_dashboard.access_level')}
                            </label>
                            <div className="flex items-center">
                                <div className="checkbox-wrapper">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            chooseAccessLevel({
                                                level: 'Admin',
                                                setFieldValue,
                                                values,
                                            })
                                        }
                                    >
                                        <Icon
                                            name="checkbox"
                                            color={'#40413F'}
                                            active={values.groups.includes(
                                                'Admin'
                                            )}
                                        />
                                    </button>
                                    <label htmlFor="access_level">
                                        {t('provider_dashboard.admin')}
                                    </label>
                                </div>
                                <div className="checkbox-wrapper">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            chooseAccessLevel({
                                                level: 'Coach',
                                                setFieldValue,
                                                values,
                                            })
                                        }
                                    >
                                        <Icon
                                            name="checkbox"
                                            color={'#40413F'}
                                            active={values.groups.includes(
                                                'Coach'
                                            )}
                                        />
                                    </button>
                                    <label htmlFor="access_level">
                                        {t('provider_dashboard.coach')}
                                    </label>
                                </div>
                                <div className="checkbox-wrapper">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            chooseAccessLevel({
                                                level: 'User',
                                                setFieldValue,
                                                values,
                                            })
                                        }
                                    >
                                        <Icon
                                            name="checkbox"
                                            color={'#40413F'}
                                            active={values.groups.includes(
                                                'User'
                                            )}
                                        />
                                    </button>
                                    <label htmlFor="access_level">
                                        {t('provider_dashboard.user')}
                                    </label>
                                </div>
                            </div>
                            {errors.groups && touched.groups && (
                                <div className="error-message">
                                    {errors.groups}
                                </div>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="submit-button"
                            onSubmit={handleSubmit}
                        >
                            {t('provider_dashboard.add_staff_member')}
                        </button>

                        <button
                            type="button"
                            className="cancel-button"
                            onClick={onHide}
                        >
                            {t('provider_dashboard.cancel')}
                        </button>
                    </form>
                )}
            </Formik>
        </Modal>
    )
}
