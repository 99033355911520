import React from 'react';

export default function FaqIcon({ size = 19, color = '#908E87', style={style} }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="9.5" cy="9.5" r="8.5" stroke={color} stroke-width="2" />
      <path
        d="M8.96392 10.94C8.96392 10.676 9.00392 10.444 9.08392 10.244C9.17192 10.036 9.27992 9.852 9.40792 9.692C9.53592 9.532 9.67192 9.388 9.81592 9.26C9.96792 9.124 10.1079 8.996 10.2359 8.876C10.3639 8.748 10.4679 8.62 10.5479 8.492C10.6359 8.364 10.6799 8.224 10.6799 8.072C10.6799 7.832 10.5799 7.644 10.3799 7.508C10.1879 7.372 9.93592 7.304 9.62392 7.304C9.30392 7.304 9.01192 7.38 8.74792 7.532C8.49192 7.684 8.28392 7.896 8.12392 8.168L6.33592 7.208C6.64792 6.672 7.09992 6.244 7.69192 5.924C8.28392 5.596 9.01992 5.432 9.89992 5.432C10.5159 5.432 11.0599 5.52 11.5319 5.696C12.0119 5.864 12.3879 6.116 12.6599 6.452C12.9319 6.788 13.0679 7.2 13.0679 7.688C13.0679 8 13.0199 8.272 12.9239 8.504C12.8279 8.736 12.7039 8.94 12.5519 9.116C12.4079 9.292 12.2519 9.456 12.0839 9.608C11.9159 9.752 11.7559 9.892 11.6039 10.028C11.4599 10.164 11.3399 10.304 11.2439 10.448C11.1479 10.592 11.0999 10.756 11.0999 10.94H8.96392ZM10.0319 14.108C9.63992 14.108 9.31592 13.984 9.05992 13.736C8.80392 13.488 8.67592 13.192 8.67592 12.848C8.67592 12.496 8.80392 12.204 9.05992 11.972C9.31592 11.732 9.63992 11.612 10.0319 11.612C10.4399 11.612 10.7679 11.732 11.0159 11.972C11.2719 12.204 11.3999 12.496 11.3999 12.848C11.3999 13.192 11.2719 13.488 11.0159 13.736C10.7679 13.984 10.4399 14.108 10.0319 14.108Z"
        fill={color}
      />
    </svg>
  );
}
