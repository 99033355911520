export const coach_dashboard = {
           upcoming_calls: 'Próximas Llamadas',
           search: 'Buscar',
           refine: 'Refinar',
           apply_filters: 'Aplicar Filtros',
           reset_filters: 'Reiniciar Filtros',
           eligibility: 'Elegibilidad',
           eligible: 'Elegible',
           ineligible: 'No elegible',
           search_results_for: 'Resultados de búsqueda para',
           clients: 'Clientes',
           columns: {
               name: 'Nombre',
               clients: 'Clientes',
               status: 'Estado',
               diagnosis: 'Diagnóstico',
               alerts: 'Alertas',
           },
           pending: 'Pendiente',
           resend_invitation: 'Reenviar invitación',
           touch_points: 'Puntos de contacto',
           engagement: 'Compromiso',
           eligibility_period: 'Período de elegibilidad',
           days_left: 'Días restantes',
           not_available: 'No disponible',
           ends: 'termina',
           of: 'de',
           clear_all: 'Limpiar todo',
           view_profile: 'Ver perfil',
           alerts: 'Alertas',
           alert: 'Alerta',
           no_alerts: 'No hay alertas',
           view_all: 'Ver todo',
           edit: 'Editar',
           delete: 'Eliminar',
           calls: 'Llamadas',
           has_call_scheduled: 'Tiene llamada programada',
           no_call_scheduled: 'No hay llamada programada',
           alert_type: 'Tipo de alerta',
           mood: 'Estado de ánimo',
           assessment: 'Evaluación',
       }
