import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const MinusIcon = ({
  size = 14,
  color = 'white',
  style = {},
  active = false,
}) => {
  return (
    <svg
      width={size * 4.67}
      height={size}
      style={{ ...style }}
      viewBox="0 0 14 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41418 1.92261H12.4309"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MinusIcon;
