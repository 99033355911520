import React from 'react'
import { useParams } from 'react-router-dom'
import { useFetchAssignedUsers } from '../../../dashboard/redux/fetchAssignedUsers'

export function useClientProfile() {
    const [user, setUser] = React.useState(null)

    const params = useParams()
    const userId = Number(params.userId)

    const { fetchAssignedUsers } = useFetchAssignedUsers()

    const fetchUser = async () => {
        fetchAssignedUsers({ userId })
            .then(assignedUsers => {
                console.log('para marvin: ', assignedUsers)
                setUser(assignedUsers.results.find(u => u.id === userId))
                // console.log('the user is: ', assignedUsers.results.find(u => u.id === userId))
            })
            .catch(err => console.log(err.response))
    }

    React.useEffect(() => {
        fetchUser()
    }, [])

    return { user, fetchUser, userId }
}
