import React, { Fragment, useEffect } from 'react';
import NobleAndFitbit from '../../assets/fitbit-connection/fitbit-connected.png';
import useQuery from '../../../common/useQuery';
import { useFitbitOauth } from '../../redux/fitbitOauth';
import { useHistory } from 'react-router-dom';

export default function Step2({ currentStep, setCurrentStep }) {

  const query = useQuery();
  const authCode = query && query.get('code');
  const history = useHistory();

  const { fitbitOauth } = useFitbitOauth();

  useEffect(() => {
    if (authCode) {
      fitbitOauth({ auth_code: authCode });
    }
  }, [authCode])

  return (
    <Fragment>
      <div
        className="step"
        style={{
          opacity: currentStep === 2 ? 1 : 0,
          zIndex: currentStep === 2 ? 1 : -1,
          transition: 'all ease-in-out 0.5s',
        }}
      >
        <h1 className="title">Connection Successful</h1>
        <img
          src={NobleAndFitbit}
          alt="Noble and Fitbit logo"
          className="noble-and-fitbit"
        />

        <p className="description">
          Your Fitbit and Noble accounts are now connected.
        </p>
      </div>

      <button
        className="done-button"
        type="button"
        style={{
          opacity: currentStep === 2 ? 1 : 0,
          transition: 'all ease-in-out 0.5s',
          position: 'absolute',
          zIndex: currentStep === 2 ? 1 : -1,
          bottom: '2rem',
        }}
        onClick={() => history.push('/rpm/dashboard')}
      >
        Get Started
      </button>
    </Fragment>
  );
}
