import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../features/common/components/Icon';
import { useTranslation } from '../translations/redux/setDefaultLanguage';

export default function Sidebar() {
  const { t } = useTranslation();
  return (
    <div className="sidebar d-none d-lg-block">
      <h1 className="s-header">Manage Subscriptions</h1>
      <div className="payment-items">
        {/* <Link to={'/dashboard/payments'} className="payment-item">
          <Icon name="dashboard" color="#343434" size={25} />

          <h3> Dashboard </h3>
        </Link> */}
        <Link to={'/dashboard/clients'} className="payment-item">
          <Icon name="clients" color="#343434" size={25} />
          <h3> Clients </h3>{' '}
        </Link>
        <Link to={'/dashboard/subscriptions'} className="payment-item">
          <Icon name="subscriptions" color="#343434" size={25} />
          <h3> Subscriptions </h3>{' '}
        </Link>
        <a
          href="https://dashboard.stripe.com/payments"
          target="_blank"
          rel="noopener noreferrer"
          className="payment-item"
        >
          <Icon name="payouts" color="#343434" size={25} />
          <h3>{t('dashboard.my_payouts.title')}</h3>{' '}
        </a>
      </div>
    </div>
  );
}

Sidebar.propTypes = {};
Sidebar.defaultProps = {};
