import React from "react";

export default function RoadmapsContainerSkeleton() {
    return <div className="roadmaps-container roadmaps-container-skeleton">
        <header className="roadmap-skeleton" />
        <div className="roadmaps-container-grid">
            {
                Array(3).fill(0).map((_, i) => (
                    <div className="roadmap-container-skeleton roadmap-skeleton" key={i} >
                        <div className="roadmap-container-footer-skeleton" >
                            <div className="roadmap-container-footer-skeleton-title roadmap-skeleton" />
                            <div className="roadmap-container-footer-skeleton-subtitle roadmap-skeleton" />
                        </div>
                    </div>
                ))
            }

        </div>

    </div>;
}
