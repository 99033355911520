import clsx from 'clsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Card from 'react-bootstrap/Card'
import Masonry from 'react-masonry-css'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers'
import { useHasActiveSubcription } from '../../common/permissions'
import {
  CurrentUserIsCoach,
  CurrentUserIsOnlyUser,
  CurrentUserIsUser,
} from '../../common/roles'
import { CustomDialog, DesktopHeader, Loader } from '../common'
import usePagination from '../common/usePagination'
import useWindowResize from '../common/useWindowResize'
import { useDeleteUserRoadmap } from '../manage/redux/deleteUserRoadmap'
import Calls from '../support-group/Calls'
import { useTranslation } from '../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../user/redux/hooks'
import CoachUpcommingCalls from './CoachUpcomingCalls'
import ClientsCard from './components/ClientsCard/ClientsCard'
import Roadmap from './components/Roadmap'
import RoadmapsCarousel from './components/RoadmapsCarousel'
import womanWithCat from './images/woman-with-cat.svg'
import { useFetchRoadmaps } from './redux/hooks'

// START OF LAZY COMPONENTS //

const ClientMessagePrompt = React.lazy(() =>
  import('./components/ClientMessagePrompt')
)

const CoachMessagePrompt = React.lazy(() =>
  import('./components/CoachMessagePrompt')
)

const MemoizedRoadmapsCarousel = React.memo(RoadmapsCarousel)
const MemoizedUpcomingCalls = React.memo(CoachUpcommingCalls)
const MemoizedCalls = React.memo(Calls)

// END OF LAZY COMPONENTS //

dayjs.extend(relativeTime)

export default function AdminPage() {
  const { isActive } = useHasActiveSubcription()

  const { user } = useFetchUser()

  let isCoach = CurrentUserIsCoach()

  const history = useHistory()
  const location = useLocation()

  const windowSize = useWindowResize()

  const { roadmaps, fetchRoadmapsPending, fetchRoadmaps } = useFetchRoadmaps()
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler()
  usePagination({
    fetchAction: fetchRoadmaps,
    actionArgs: {
      assignedCoaches: true,
      asStudent: true,
    },
    requestNextPage: () => roadmaps && roadmaps.next && !fetchRoadmapsPending,
  })

  useEffect(() => {
    if (!user) return

    if (
      !user.user_is_internal_coach &&
      (user.groups.includes('Coach') || user.groups.includes('Admin'))
    ) {
      window.location.href = '/rpm/provider-dashboard'
    }

    if (!user.user_is_internal_coach) {
      history.push('/dashboard/legacy')
      return
    }

    if (!user.company_is_rtm) return

    if (location.pathname === '/dashboard/legacy') return

    if (
      user.user_is_internal_coach &&
      (user.groups.includes('Coach') || user.groups.includes('Admin'))
    ) {
      window.location.href = '/rpm/coach-dashboard'
    }
  }, [user, location.pathname])

  useMemo(() => {
    fetchRoadmaps({ assignedCoaches: false }).catch(unauthorizedErrorHandler)
  }, [fetchRoadmaps, unauthorizedErrorHandler])

  const { deleteUserRoadmap } = useDeleteUserRoadmap()

  const [archiveDialog, setArchiveDialog] = useState(false)
  const handleArchiveDialogHide = useCallback(() => setArchiveDialog(false), [])

  const handleArchiveRoadmapYes = roadmapId => {
    deleteUserRoadmap({ roadmapId, userId: user.id }).then(() => {
      history.push('/')
    })
  }

  let onlyUser = CurrentUserIsOnlyUser()
  let isUser = CurrentUserIsUser()

  const { t } = useTranslation()

  if (!roadmaps || !user) {
    return <Loader />
  }

  return (
    <Suspense fallback={<Loader />}>
      <div
        className={clsx('dashboard-admin-page', {
          'mrm-mt-1':
            window.location.pathname === '/dashboard/admin' &&
            windowSize.width < 991,
        })}
      >
        <DesktopHeader />
        {/** Dashboard */}
        <div className="d-lg-block desktop-page-container container-lg">
          <Masonry
            breakpointCols={{ default: 2, 991: 1 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {isCoach && !user.user_is_internal_coach && <CoachMessagePrompt />}
            {(onlyUser || user.user_is_internal_coach) && (
              <ClientMessagePrompt />
            )}

            {isCoach && <ClientsCard />}

            {windowSize.width > 991 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {(isActive === true || isCoach) && (
                  <Card className="mrm-p-1_5 mrm-pt-2 mrm-pb-2_5 col-12 upcoming-calls-container">
                    <MemoizedUpcomingCalls />
                  </Card>
                )}
                {user && user.company_name !== 'Noble' && <MemoizedCalls />}{' '}
              </div>
            )}

            {isCoach && (
              <div className="roadmaps-carousel-admin-page">
                <p className="title">
                  {t('dashboard.roadmaps_carousel.title')}
                </p>
                {roadmaps.results.filter(r => r?.is_published).length > 2 ? (
                  <MemoizedRoadmapsCarousel show={2} infiniteLoop={true}>
                    {/** Map img 10 times */}
                    {roadmaps.results
                      .filter(roadmap => roadmap?.is_published)
                      .map((roadmap, idx) => (
                        <div className="roadmap-parent-container" key={idx}>
                          <div
                            className="roadmap-container"
                            style={{
                              backgroundImage: `url(${roadmap.icon ||
                                womanWithCat})`,
                              backgroundColor: '#517282',
                            }}
                            onClick={() => {
                              history.push(`/roadmap/${roadmap.id}`)
                            }}
                          >
                            <div className="roadmap-title">
                              <h3>{roadmap.title}</h3>
                            </div>
                          </div>
                        </div>
                      ))}
                  </MemoizedRoadmapsCarousel>
                ) : (
                  <div className="d-flex">
                    {roadmaps.results
                      .filter(r => r?.is_published)
                      .map((roadmap, idx) => (
                        <div
                          className="roadmap-parent-container mrm-mt-1"
                          key={idx}
                          style={{
                            width:
                              roadmaps.results.filter(r => r?.is_published)
                                .length === 2
                                ? '50%'
                                : '100%',
                            ...(windowSize.width > 991 && {
                              marginTop: '3rem',
                            }),
                          }}
                        >
                          <div
                            className="roadmap-container"
                            style={{
                              backgroundImage: `url(${roadmap.icon ||
                                womanWithCat})`,
                              backgroundColor: '#517282',
                            }}
                            onClick={() => {
                              history.push(`/roadmap/${roadmap.id}`)
                            }}
                          >
                            <div className="roadmap-title">
                              <h3>{roadmap.title}</h3>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
            {windowSize.width < 991 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {(isActive === true || isCoach) && (
                  <Card className="mrm-p-1_5 mrm-pt-2 mrm-pb-2_5 col-12 upcoming-calls-container">
                    <MemoizedUpcomingCalls />
                  </Card>
                )}
                {user && user.company_name !== 'Noble' && <Calls />}{' '}
              </div>
            )}

            {isUser && (
              <Card className="mrm-pt-2 mrm-pb-2_5 mrm-px-1_5 col-12 roadmap-grid-db-parent">
                <div className="roadmap-grid-db">
                  <div className="roadmap-grid-header">
                    <p>
                      {t('dashboard.my_roadmaps.title')}{' '}
                      <strong>
                        ({roadmaps.results.filter(r => r.is_published).length})
                      </strong>
                    </p>
                  </div>
                  {roadmaps.results
                    .filter(r => r.is_published)
                    .map((r, idx) => (
                      <div key={idx}>
                        <Link
                          to={`/roadmap/${r.id}`}
                          key={r.id}
                          className="no-format"
                        >
                          <Roadmap data={r} />
                        </Link>
                      </div>
                    ))}
                  <CustomDialog
                    show={archiveDialog}
                    text={{
                      caption: user.features.roadmaps_are_archived
                        ? 'Archive Roadmap?'
                        : 'Unassign Roadmap? Your progress will be saved.',
                      yes: 'Yes',
                    }}
                    onHide={handleArchiveDialogHide}
                    onYes={handleArchiveRoadmapYes}
                  />
                </div>
              </Card>
            )}
          </Masonry>
        </div>
      </div>
    </Suspense>
  )
}

AdminPage.propTypes = {}
AdminPage.defaultProps = {}
