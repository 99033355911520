import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_GET_LATEST_UNIFIED_SUMMARY_BEGIN,
  RPM_GET_LATEST_UNIFIED_SUMMARY_SUCCESS,
  RPM_GET_LATEST_UNIFIED_SUMMARY_FAILURE,
  RPM_GET_LATEST_UNIFIED_SUMMARY_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'



export function getLatestUnifiedSummary(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_GET_LATEST_UNIFIED_SUMMARY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

    const { has_call_scheduled, search, page, eligibility_status, alert_type, reset, ordering} = args
     const doRequest = axios.get(
         `${config.apiRootUrl}/rpm/latest-unified-summary`,
         {
             params: {
                  ...(has_call_scheduled && { has_call_scheduled }),
                  search,
                  page,
                  ...(eligibility_status && { eligibility_status }),
                  ...(alert_type && { alert_type }),
                  ...(ordering, { ordering })
             },
             ...createAxiosConfigWithAuth(getState()),
         }
     )
      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_GET_LATEST_UNIFIED_SUMMARY_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_GET_LATEST_UNIFIED_SUMMARY_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetLatestUnifiedSummaryError() {
  return {
    type: RPM_GET_LATEST_UNIFIED_SUMMARY_DISMISS_ERROR,
  };
}

export function useGetLatestUnifiedSummary() {
  const dispatch = useDispatch();

  const { getLatestUnifiedSummaryPending, getLatestUnifiedSummaryError, latestUnifiedSummary } = useSelector(
    state => ({
      latestUnifiedSummary: state.rpm.latestUnifiedSummary,
      getLatestUnifiedSummaryPending: state.rpm.getLatestUnifiedSummaryPending,
      getLatestUnifiedSummaryError: state.rpm.getLatestUnifiedSummaryError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getLatestUnifiedSummary(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetLatestUnifiedSummaryError());
  }, [dispatch]);

  return {
    latestUnifiedSummary,
    getLatestUnifiedSummary: boundAction,
    getLatestUnifiedSummaryPending,
    getLatestUnifiedSummaryError,
    dismissGetLatestUnifiedSummaryError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_GET_LATEST_UNIFIED_SUMMARY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getLatestUnifiedSummaryPending: true,
        getLatestUnifiedSummaryError: null,
      };

    case RPM_GET_LATEST_UNIFIED_SUMMARY_SUCCESS:
      // The request is success
      return {
        ...state,
        latestUnifiedSummary: action.data,
        getLatestUnifiedSummaryPending: false,
        getLatestUnifiedSummaryError: null,
      };

    case RPM_GET_LATEST_UNIFIED_SUMMARY_FAILURE:
      // The request is failed
      return {
        ...state,
        getLatestUnifiedSummaryPending: false,
        getLatestUnifiedSummaryError: action.data.error,
      };

    case RPM_GET_LATEST_UNIFIED_SUMMARY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getLatestUnifiedSummaryError: null,
      };

    default:
      return state;
  }
}
