import React from 'react'

export default function ShieldIcon({
    size = 18,
    color = '#595959',
    className,
}) {
    return (
        <svg
            width={size}
            height={size * 1.111111111}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M12.6276 1H5.05746C5.05746 3.2033 3.24475 4.98253 1 4.98253V5.90442C1 11.2698 4.01492 16.1926 8.84254 18.7094C13.6702 16.1926 16.6851 11.2698 16.6851 5.90442V4.98253C14.4403 4.98253 12.6276 3.2033 12.6276 1Z"
                stroke={color}
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.84277 7.0293V12.1181"
                stroke={color}
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.4355 9.57422H6.25098"
                stroke={color}
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
