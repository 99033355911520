import * as Yup from 'yup'

// This is the validation of the form.

// regex for phone number starting with +
const phoneRegEx = /^\+[0-9]{1,3}[- ]?[0-9]{1,3}[- ]?[0-9]{1,3}[- ]?[0-9]{1,3}$/

export const ClientInfoSchema = Yup.object().shape(
    {
        first_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        last_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email')
            .required('Required'),
        phone_number: Yup.string().matches(
            phoneRegEx,
            'Invalid phone number, must start with +'
        ),

        company: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        coaching: Yup.array()
            .ensure()
            .when('company', {
                is: company => company === 'Bloom',
                then: Yup.array().required('Required'),
                otherwise: Yup.array(),
            }),
        // insurance
        policy_holder: Yup.string(),
        policy_holder_dob: Yup.string(),
        group_number: Yup.string(),
        insurance_provider: Yup.string(),
        member_id: Yup.string().when('insurance_provider', {
            is: insurance_provider =>
                insurance_provider !== '' &&
                insurance_provider !== undefined &&
                insurance_provider !== null,
            then: Yup.string().required('Member ID is required'),
            otherwise: Yup.string(),
        }),
        // address
        address_1: Yup.string(),
        address_2: Yup.string(),
        zip: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'Must be only digits')
            .min(5, 'Must be exactly 5 digits')
            .max(5, 'Must be exactly 5 digits'),
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
    },
)
