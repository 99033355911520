import React from 'react';

export default function FocusEdit({ ...props }) {
  const { editRef } = props;
  React.useEffect(() => {
    if (editRef.current) {
      const editNode = editRef.current;
      editNode.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [editRef.current]);
  return null;
}
