import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import useWindowResize from '../../../common/useWindowResize'
import { useFetchUser } from '../../../user/redux/fetchUser'

export default function ActionButton({
    resolution,
    markedDone,
    updateActionItem,
    setShowScreenCapture,
}) {
    const { replaceStringWithSynonyms } = useFetchUser()
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const windowSize = useWindowResize()

    function toggleItemComplete() {
        updateActionItem({
            marked_done: !markedDone,
            date_marked_done: markedDone ? null : dayjs().format('YYYY-MM-DD'),
        }).then(() => {
            setButtonState({
                ...buttonState,
                text: !markedDone ? 'Unmark Done' : 'Mark Complete',
            })
            hideConfirmModal()
        })
    }

    function hideConfirmModal() {
        setShowConfirmModal(false)
    }

    const [buttonState, setButtonState] = useState({
        text: '',
        action: null,
    })

    useEffect(() => {
        switch (resolution) {
      /*       case 'mark_complete':
                setButtonState({
                    text: markedDone ? 'Unmark Done' : 'Mark Complete',
                    action: markedDone
                        ? () => setShowConfirmModal(true)
                        : toggleItemComplete,
                })
                break */

            case 'attach_screen_recording':
                setButtonState({
                    text: 'Add Screen Recording',
                    action: () => setShowScreenCapture(true),
                })
                break
            default:
                break
        }
    }, [resolution, markedDone])

    return (
        <>
            <Modal centered show={showConfirmModal} onHide={hideConfirmModal}>
                <Modal.Header>
                    <Modal.Title>
                        <h1>Unmark Item Done?</h1>
                        <p>
                            {replaceStringWithSynonyms(
                                'This will erase your approved status and your coach will have to reapprove it.'
                            )}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Button variant="destructive" onClick={toggleItemComplete}>
                    Confirm
                </Button>
                <div className="border-thin" />
                <Button variant="secondary" onClick={hideConfirmModal}>
                    Cancel
                </Button>
            </Modal>
            {buttonState.text && (
                <Button
                    onClick={buttonState.action}
                    variant={windowSize.width > 991 ? 'gray' : 'white'}
                    className="w-100"
                >
                    {buttonState.text}
                </Button>
            )}
        </>
    )
}
