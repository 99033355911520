import _ from 'lodash';

import { App } from '../features/home';
import { UserNotApproved } from '../features/common';
import homeRoute from '../features/home/route';
import userRoute from '../features/user/route';
import dashboardRoute from '../features/dashboard/route';
import roadmapRoute from '../features/roadmap/route';
import messagesRoute from '../features/messages/route';
import notificationsRoute from '../features/notifications/route';
import manageRoute from '../features/manage/route';
import WelcomePage from '../features/home/WelcomePage';
import supportRoute from '../features/support/route';
import subscriptionsRoute from '../features/subscriptions/route';
import supportGroupRoute from '../features/support-group/route';
import authRoute from '../features/auth/route';
import analyticsRoute from '../features/analytics/route';
import rpmRoute from '../features/rpm/route';
import translationsRoute from '../features/translations/route';
import chatRoute from '../features/chat/route';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
const childRoutes = [
  homeRoute,
  userRoute,
  dashboardRoute,
  roadmapRoute,
  messagesRoute,
  notificationsRoute,
  manageRoute,
  supportRoute,
  subscriptionsRoute,
  supportGroupRoute,
  authRoute,
  analyticsRoute,
  rpmRoute,
  translationsRoute,
  chatRoute,
];

const routes = [
  {
    path: '/',
    component: App,
    childRoutes: [
      ...childRoutes,
      {
        path: 'user-not-approved',
        name: 'User not approved',
        component: UserNotApproved,
      },
      {
        path: '*',
        name: 'Page not found',
        component: WelcomePage,
        isIndex: true,
        noSignIn: true,
      },
    ].filter((r) => r.component || (r.childRoutes && r.childRoutes.length > 0)),
  },
];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child) => child.isIndex);
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
