import React, { useEffect, useMemo, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import DesktopHeader from '../common/DesktopHeader'
import { useGetSettingsByName } from '../auth/redux/getSettingsByName'
import { useFetchUser } from '../user/redux/fetchUser'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export default function Campaigns() {
  const [tableData, setTableData] = useState([
    {
      name: 'Loading...',
    },
  ])

  const { getSettingsByName } = useGetSettingsByName()
  const { user } = useFetchUser()

  useEffect(() => {
    if (!user) return
    getSettingsByName({
      company_id: user.company_id,
    }).then(res => {
      console.log('res', res)
      setTableData(res)
    })
  }, [user])

  useEffect(() => {
    console.log('tableData', tableData)
  }, [tableData])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Link',
        accessor: 'link',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      tableData.map(data => {
        return {
          id: data.alias,
          name: data.alias,
          link:
            window.location.origin +
            `/auth/${data.alias}/default`,
        }
      }),
    [tableData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy)

  function onRowClick(row) {
    return () => {
      window.location.href = `/auth/${row.name.toLowerCase()}/default`
    }
  }

  if (!user) return null
  if (!user.groups.includes('Admin')) return null


  return (
    <div className="home-campaigns">
      <DesktopHeader />
      <Container>
        <div className="home-campaigns wrapper">
          <div className="home-campaigns-sidebar">
            <h1 className="sidebar-header">Manage</h1>
            <h3 className="sidebar-subheader">Campaigns</h3>
          </div>
          <div className="home-campaigns-main-content">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={onRowClick ? onRowClick(row.original) : () => {}}
                      style={{
                        cursor: onRowClick ? 'pointer' : 'default',
                      }}
                    >
                      {row.cells.map(cell => {
                        if (cell.column.id === 'active') {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.value ? 'Active' : 'Inactive'}
                            </td>
                          )
                        }
                        return (
                          <td {...cell.getCellProps()}>
                            {<div>{cell.value}</div>}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  )
}

Campaigns.propTypes = {}
Campaigns.defaultProps = {}
