import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DesktopHeader } from '../common';
import Icon from '../common/components/Icon';
import { useCreatePriceSubscription } from '../home/redux/createPriceSubscription';
import { useCreateProductSubscription } from '../home/redux/createProductSubscription';
import { useGetListProductSubscription } from '../home/redux/getListProductSubscription';
import { useFetchUser } from '../user/redux/fetchUser';
import { useUpdateProductSubscription } from '../user/redux/updateProductSubscription';
import TabSelector from './components/TabSelector';
import Sidebar from './Sidebar';
import { useWindowSize } from '../../common/windowsSize';
import { Loader } from '../common';

export default function Subscriptions() {
  const size = useWindowSize();
  const { user } = useFetchUser();
  const { getListProductSubscription } = useGetListProductSubscription();
  const { updateProductSubscription } = useUpdateProductSubscription();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const { createProductSubscription } = useCreateProductSubscription();
  const { createPriceSubscription } = useCreatePriceSubscription();

  const [editMode, setEditMode] = useState(false);
  const [createProduct, setCreateProduct] = useState(false);
  const [subscription, setSubscription] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [productId, setProductId] = React.useState('');

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(productId);
    updateProductSubscription({ id: productId, name: subscription })
      .then((res) => {
        console.log(res);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getProducts = () => {
      try {
        getListProductSubscription({
          coach_id: user?.id,
        }).then((res) => {
          setProducts(res.data.results);
          console.log('products', res.data);
        });
      } catch {
        console.log('error in getting products');
      }
    };
    getProducts();
  }, [getListProductSubscription, user]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="menu-item-container-subs"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Icon name="threedots" size={4} color="#C4C4C4" />
    </div>
  ));

  const handleCreateSubscriptionSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    createProductSubscription({
      name: subscription,
    }).then((res) => {
      createPriceSubscription({
        product: res.data.id,
        unit_amount: Number(price * 100).toFixed(0) * 1,
      })
        .then((res) => {
          console.log(res);
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const handleProductPrice = (e) => {
    setPrice(e.target.value);
  };

  return (
    <>
      <DesktopHeader />
      <div className="dashboard-subscriptions">
        <Sidebar />
        <div className="tab d-lg-none">
          <TabSelector />
        </div>

        <div
          className={`main-content ${
            !editMode && !createProduct ? '' : 'd-none'
          }`}
        >
          <div className="main-header">
            <p>Subscriptions</p>
            <button
              onClick={() => {
                setCreateProduct(true);
              }}
              className="add-sub "
            >
              <Icon name="plus" size={12} color="#FFFFFF" />
              <span>Add New</span>
            </button>
          </div>
          <table className="subscriptions-table">
            <thead>
              <tr className="header">
                <th className="name">Name</th>
                <th className="d-none d-lg-block">Created</th>
                <th>Price</th>
                <th className="last"></th>
              </tr>
            </thead>
            <tbody>
              {products &&
                products.map((product, idx) => {
                  // map date to readable format
                  const date = new Date(product.created_at);
                  const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  };
                  const created = date.toLocaleDateString('en-US', options);

                  return (
                    <tr key={idx}>
                      <td>
                        <div className="user">
                          <div className="title">
                            {' '}
                            <h3>{product.name}</h3>
                            {product.price?.unit_amount ? (
                              <p className="date d-lg-none">
                                ${product.price?.unit_amount / 100}.00 / month
                              </p>
                            ) : (
                              <p>Without price</p>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className={size.width < 768 && 'd-none'}>
                        <p className="date">{created}</p>
                      </td>
                      <td>
                        {product.price?.unit_amount ? (
                          <p className="date">
                            ${product.price?.unit_amount / 100}
                          </p>
                        ) : (
                          <p>Without price</p>
                        )}
                      </td>
                      <td className="first">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />

                          <Dropdown.Menu>
                            <button
                              onClick={() => {
                                setEditMode(true);
                                setPrice(`${product.price?.unit_amount / 100}`);
                                setSubscription(product.name);
                                setProductId(product.id);
                              }}
                            >
                              Edit
                            </button>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className={`main-content ${editMode ? '' : 'd-none'}`}>
          {loading && <Loader position="static" />}
          <div className="main-content-inside">
            <div className={`main-header ${loading && 'd-none'}`}>
              <div className="edit-subscription">
                <button onClick={() => setEditMode(false)}>
                  <Icon name="close" size={25} color="#343434" />
                </button>
                <p>Edit Subscription</p>
              </div>

              <button
                className="save d-none d-lg-flex"
                type="submit"
                form="update-product-form"
              >
                Save Changes
              </button>
            </div>
            <div className={`subscription-details ${loading && 'd-none'}`}>
              <form id="update-product-form" onSubmit={handleSubmit}>
                <label className="real-labels">Subscription Name</label>
                <input
                  placeholder="Subscription Name"
                  id="subscription"
                  name="subscription"
                  type="text"
                  value={subscription}
                  onChange={(e) => setSubscription(e.target.value)}
                  className="sub-name"
                />
                <label className="real-labels">Monthly Price</label>

                <label className="month-label">/mo</label>
                <input
                  placeholder="0.00"
                  id="price"
                  name="price"
                  type="number"
                  value={price}
                  className="price-input"
                  disabled
                />
                <label className="price-label">$</label>
              </form>
              <button
                className="save d-lg-none"
                type="submit"
                form="update-product-form"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>

        {/* Create a Subscription */}

        {createProduct && !editMode && (
          <div className={`dashboard-create-subscription `}>
            {loading && <Loader position="static" />}
            <div className={`main-content-inside ${loading && 'd-none'}`}>
              <div className="main-header">
                <div className="create">
                  <button
                    onClick={() => {
                      setCreateProduct(false);
                    }}
                  >
                    <Icon name="close" size={22} color="#6D786E" />
                  </button>
                  <p>Create new subscription</p>
                </div>
                <button
                  className="save-sub d-none d-lg-flex"
                  type="submit"
                  form="create-product-form"
                >
                  <span>Save Subscription</span>
                </button>
              </div>

              <div className="subscriptions">
                <h3>Subscription Details</h3>
                <div className="subscription-details">
                  <form
                    id="create-product-form"
                    onSubmit={handleCreateSubscriptionSubmit}
                  >
                    <label>Subscription Name</label>
                    <input
                      placeholder="Basic"
                      id="subscription"
                      name="subscription"
                      type="text"
                      value={subscription}
                      onChange={(e) => {
                        setSubscription(e.target.value);
                        console.log(subscription);
                      }}
                    />
                    <label>Monthly Price</label>
                    <input
                      placeholder="$0.00"
                      id="price"
                      name="price"
                      type="number"
                      value={price}
                      onChange={handleProductPrice}
                    />
                    <button
                      className="save-sub d-lg-none"
                      type="submit"
                      form="create-product-form"
                    >
                      <span>Save Subscription</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

Subscriptions.propTypes = {};
Subscriptions.defaultProps = {};
