import axios from 'axios'
import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import config from '../../../common/config'
import {
  AUTH_GET_SETTINGS_BY_NAME_BEGIN,
  AUTH_GET_SETTINGS_BY_NAME_DISMISS_ERROR,
  AUTH_GET_SETTINGS_BY_NAME_FAILURE,
  AUTH_GET_SETTINGS_BY_NAME_SUCCESS,
} from './constants'

export function getSettingsByName(args = {}) {
  return dispatch => {
    // optionally you can have getState as the second argument
    dispatch({
      type: AUTH_GET_SETTINGS_BY_NAME_BEGIN,
    })

    args.filter = args.filter ? args.filter : 'alias'
    args.type = args.type ? args.type : 'default'

    let url = `${config.apiRootUrl}/company/settings/list/`

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(url, {
        params: {
          /* ?${args.filter}=${args.name}&campaigns_alias=${args.type} */
          ...(args.company_id
            ? {
                company_id: args.company_id,
                include_all_campaigns: true,
              }
            : {
                [args.filter]: args.name,
                campaigns_alias: args.type,
              }),
        },
      })
      doRequest.then(
        res => {
          dispatch({
            type: AUTH_GET_SETTINGS_BY_NAME_SUCCESS,
            data: args.company_id
              ? res.data.results
              : args.type && args.type.toLowerCase() === 'default'
              ? res.data.results[0]?.settings
              : res.data.results[0]?.campaigns_alias[0].settings,
          })
          resolve(
            args.company_id
              ? res.data.results
              : args.type && args.type.toLowerCase() === 'default'
              ? res.data.results[0]?.settings
              : res.data.results[0]?.campaigns_alias[0].settings
          )
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: AUTH_GET_SETTINGS_BY_NAME_FAILURE,
            data: { error: err },
          })
          reject(err)
        }
      )
    })

    return promise
  }
}

export function dismissGetSettingsByNameError() {
  return {
    type: AUTH_GET_SETTINGS_BY_NAME_DISMISS_ERROR,
  }
}

export function useGetSettingsByName() {
  const dispatch = useDispatch()

  const {
    settings,
    getSettingsByNamePending,
    getSettingsByNameError,
  } = useSelector(
    state => ({
      settings: state.auth.settings,
      getSettingsByNamePending: state.auth.getSettingsByNamePending,
      getSettingsByNameError: state.auth.getSettingsByNameError,
    }),
    shallowEqual
  )

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getSettingsByName(...args))
    },
    [dispatch]
  )

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetSettingsByNameError())
  }, [dispatch])

  return {
    settings,
    getSettingsByName: boundAction,
    getSettingsByNamePending,
    getSettingsByNameError,
    dismissGetSettingsByNameError: boundDismissError,
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_GET_SETTINGS_BY_NAME_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getSettingsByNamePending: true,
        getSettingsByNameError: null,
      }

    case AUTH_GET_SETTINGS_BY_NAME_SUCCESS:
      // The request is success
      return {
        ...state,
        getSettingsByNamePending: false,
        getSettingsByNameError: null,
        settings: action.data,
      }

    case AUTH_GET_SETTINGS_BY_NAME_FAILURE:
      // The request is failed
      return {
        ...state,
        getSettingsByNamePending: false,
        getSettingsByNameError: action.data.error,
      }

    case AUTH_GET_SETTINGS_BY_NAME_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getSettingsByNameError: null,
      }

    default:
      return state
  }
}
