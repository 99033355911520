import React from 'react';

export default function BioIcon({ className, size = 64, style = {} }) {
  return (
    <svg
      width={size}
      height={size / 0.9}
      viewBox="0 0 67 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
    >
      <path
        d="M57.2414 5.41373H2.68739C1.97465 5.41373 1.2911 5.12854 0.787119 4.62091C0.283135 4.11327 0 3.42477 0 2.70687C0 1.98896 0.283135 1.30046 0.787119 0.792822C1.2911 0.285187 1.97465 0 2.68739 0H57.2414C57.9542 0 58.6377 0.285187 59.1417 0.792822C59.6457 1.30046 59.9288 1.98896 59.9288 2.70687C59.9288 3.42477 59.6457 4.11327 59.1417 4.62091C58.6377 5.12854 57.9542 5.41373 57.2414 5.41373Z"
        fill="#6D786E"
      />
      <path
        d="M23.7386 21.0233H2.68739C1.97465 21.0233 1.2911 20.7381 0.787119 20.2305C0.283135 19.7228 0 19.0343 0 18.3164C0 17.5985 0.283135 16.91 0.787119 16.4024C1.2911 15.8947 1.97465 15.6096 2.68739 15.6096H23.7386C24.4514 15.6096 25.1349 15.8947 25.6389 16.4024C26.1429 16.91 26.426 17.5985 26.426 18.3164C26.426 19.0343 26.1429 19.7228 25.6389 20.2305C25.1349 20.7381 24.4514 21.0233 23.7386 21.0233Z"
        fill="#6D786E"
      />
      <path
        d="M20.9617 36.6328H2.68739C1.97465 36.6328 1.2911 36.3477 0.787119 35.84C0.283135 35.3324 0 34.6439 0 33.926C0 33.2081 0.283135 32.5196 0.787119 32.0119C1.2911 31.5043 1.97465 31.2191 2.68739 31.2191H20.9617C21.6744 31.2191 22.3579 31.5043 22.8619 32.0119C23.3659 32.5196 23.649 33.2081 23.649 33.926C23.649 34.6439 23.3659 35.3324 22.8619 35.84C22.3579 36.3477 21.6744 36.6328 20.9617 36.6328Z"
        fill="#6D786E"
      />
      <path
        d="M17.9159 52.2425H2.68739C1.97465 52.2425 1.2911 51.9573 0.787119 51.4496C0.283135 50.942 0 50.2535 0 49.5356C0 48.8177 0.283135 48.1292 0.787119 47.6216C1.2911 47.1139 1.97465 46.8287 2.68739 46.8287H17.9159C18.6287 46.8287 19.3122 47.1139 19.8162 47.6216C20.3202 48.1292 20.6033 48.8177 20.6033 49.5356C20.6033 50.2535 20.3202 50.942 19.8162 51.4496C19.3122 51.9573 18.6287 52.2425 17.9159 52.2425Z"
        fill="#6D786E"
      />
      <path
        d="M43.5799 45.5205C40.1956 45.5652 36.8745 44.5953 34.0389 42.734C31.2033 40.8727 28.9812 38.2041 27.6552 35.0675C26.3292 31.9309 25.9591 28.468 26.5921 25.119C27.225 21.77 28.8324 18.6862 31.2098 16.2597C33.5872 13.8333 36.6273 12.1737 39.9435 11.4919C43.2598 10.8102 46.7024 11.1372 49.8336 12.4313C52.9649 13.7253 55.6434 15.9281 57.5286 18.7594C59.4139 21.5907 60.4206 24.9228 60.4209 28.3319C60.4448 32.8589 58.6852 37.2107 55.5282 40.4329C52.3711 43.6551 48.0743 45.4847 43.5799 45.5205ZM43.5799 16.6021C41.2569 16.5573 38.9733 17.2108 37.0206 18.4791C35.068 19.7474 33.5348 21.573 32.6169 23.7229C31.699 25.8728 31.4379 28.2494 31.867 30.5494C32.2961 32.8495 33.3959 34.9685 35.026 36.6361C36.6562 38.3037 38.7427 39.4442 41.0192 39.9121C43.2958 40.3799 45.6591 40.1538 47.8075 39.2626C49.9559 38.3715 51.7919 36.8557 53.0812 34.9088C54.3705 32.9619 55.0546 30.6722 55.0461 28.3319C55.0579 26.8034 54.7707 25.2874 54.2009 23.8707C53.631 22.454 52.7897 21.1642 51.7249 20.075C50.6602 18.9858 49.3929 18.1185 47.9954 17.5226C46.5978 16.9267 45.0975 16.6139 43.5799 16.6021Z"
        fill="#6D786E"
      />
      <path
        d="M63.422 63.0248H23.7382C23.3575 63.0295 22.9803 62.9513 22.6325 62.7956C22.2846 62.6399 21.9742 62.4103 21.7226 62.1225C21.469 61.8274 21.2785 61.4828 21.1629 61.1102C21.0473 60.7377 21.0091 60.3451 21.0508 59.957C21.7374 54.4488 24.4165 49.389 28.5755 45.746C32.7941 42.2245 38.1011 40.2974 43.5801 40.2974C49.0591 40.2974 54.3661 42.2245 58.5847 45.746C62.7077 49.3738 65.3687 54.3972 66.0646 59.8668C66.1114 60.2447 66.0798 60.6283 65.9719 60.9933C65.864 61.3582 65.682 61.6967 65.4375 61.9872C65.1947 62.2952 64.8891 62.5471 64.5414 62.7261C64.1938 62.9051 63.812 63.007 63.422 63.0248ZM27.0974 57.6111H59.9284C58.9819 54.6416 57.2472 51.9895 54.9119 49.9416C51.6926 47.2408 47.6354 45.7618 43.4457 45.7618C39.256 45.7618 35.1988 47.2408 31.9795 49.9416C29.7 51.9571 28.0116 54.5626 27.0974 57.4758V57.6111Z"
        fill="#6D786E"
      />
    </svg>
  );
}
