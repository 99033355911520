export const client_profile = {
  eligible: 'Eligible',
  ineligible: 'Ineligible',
  pending: 'Pending',
  days_tracked: 'days tracked',
  days_left: 'days left',
  in_eligibility_period: 'in eligibility period',
  billing_cycle: 'Billing cycle',
  not_available: 'Not available',
  no_phone_number: 'No phone number',
  message: 'Message',
  care_team: 'Care team',
  you: 'You',
  current_roadmap: 'Current roadmap',
  add_assessment: 'Add assessment',
  add_cpt_code: 'Add CPT code',
  add_event: 'Add event',
  add_call: 'Add new call',
  send_clinical_record: 'Send Clinical Record',
  submit_medical_record: 'Submit Medical Record',
  confirm_submission: 'Confirm Submission',
  success: 'Success!',
  submission_was_sent_successfully: 'Submission was sent successfully',
  process_failed: 'Process failed.',
  error_occurred:
    'Unknown error has occurred. Please return to form and submit again.',
  activity: 'Activity',
  billing: 'Billing',
  profile_details: 'Profile details',
  policy_holder_dob: 'Policy holder Date of Birth',
  edit: 'Edit',
  add: 'Add',
  save: 'Save',
  update: 'Update',
  remove: 'Remove',
  assessment: 'Assessment',
  select_an_assessment: 'Select an assessment',
  date_completed: 'Date completed',
  yyyy: 'YYYY',
  date_cannot_be_future: 'Date cannot be in the future',
  score: 'Score',
  link_to_assessment_results: 'Link to assessment results',
  optional: 'Optional',
  delete: 'Delete',
  cancel: 'Cancel',
  please_select_an_assessment: 'Please select an assessment',
  please_enter_a_score: 'Please enter a score',
  please_enter_a_month: 'Please enter a month',
  please_enter_a_day: 'Please enter a day',
  please_enter_a_year: 'Please enter a year',
  invalid_date: 'Invalid date',
  cpt_code: 'CPT code',
  call_length_in_minutes: 'Call length in minutes',
  if_applicable: 'If applicable',
  call_time: 'Call time',
  notes: 'Notes',
  select_cpt_code: 'Select CPT code',
  please_select_a_cpt_code: 'Please select a CPT code',
  please_enter_a_positive_number: 'Please enter a positive number',
  no_activity_has_been_logged: 'No activity has been logged',
  daily_steps: 'Daily steps',
  engagement: 'Engagement',
  sleep: 'Sleep',
  emotional_check_in: 'Emotional Check-in',
  valid_until: 'Valid until',
  you_have_reached_the_end_of_this_health_history:
    'You have reached the end of this health history',
  delete_assessment_question: 'Delete assessment?',
  are_you_sure_you_want_to_delete_this_assessment_question:
    'Are you sure you want to delete this assessment?',
  deleting_this_assessment:
    'Deleting this assessment will delete the results from your client’s data tab. And may decrease compliance score.',
  personal_information: 'Personal information',
  account_type: 'Account type',
  admin: 'Admin',
  email_address: 'Email address',
  phone: 'Phone',
  company: 'Company',
  date_joined: 'Date joined',
  insurance_provider: 'Insurance provider',
  member_id: 'Member ID',
  policy_holder: 'Policy holder',
  group_number: 'Group number',
  contract: 'Contract',
  contract_type: 'Contract type',
  expires: 'Expires',
  created_by: 'Created by',
  length_of_call: 'Length of call',
  delete_cpt_record_question: 'Delete CPT record?',
  are_you_sure_you_want_to_delete_this_record:
    'Are you sure you want to delete this record?',
  next_call: 'Next Call',
  schedule: 'Schedule',
  events: 'Events',
  upheal: {
    title: 'Upheal Information',
    upheal_user_id: 'Upheal User ID',
    coach_meeting_room: 'Coach Meeting Room',
    client_session_url: 'Client Session URL',
  },
  employer_coverage: {
    title: 'Employer Coverage Information',
    employer_name: 'Employer Name',
    subscriber_id_last_digits: 'Subscriber ID Last Digits',
    is_covered: 'Is Covered',
    yes: 'Yes',
  },
  assign_a_coach_to_your_client: 'Assign a coach to your client',
  edit_care_team: 'Edit Care Team',
  save: 'Save',
  approved: 'Approved',
}
