export const DASHBOARD_FETCH_ROADMAPS_BEGIN = 'DASHBOARD_FETCH_ROADMAPS_BEGIN'
export const DASHBOARD_FETCH_ROADMAPS_SUCCESS =
    'DASHBOARD_FETCH_ROADMAPS_SUCCESS'
export const DASHBOARD_FETCH_ROADMAPS_FAILURE =
    'DASHBOARD_FETCH_ROADMAPS_FAILURE'
export const DASHBOARD_FETCH_ROADMAPS_DISMISS_ERROR =
    'DASHBOARD_FETCH_ROADMAPS_DISMISS_ERROR'
export const DASHBOARD_FETCH_ASSIGNED_USERS_BEGIN =
    'DASHBOARD_FETCH_ASSIGNED_USERS_BEGIN'
export const DASHBOARD_FETCH_ASSIGNED_USERS_SUCCESS =
    'DASHBOARD_FETCH_ASSIGNED_USERS_SUCCESS'
export const DASHBOARD_FETCH_ASSIGNED_USERS_FAILURE =
    'DASHBOARD_FETCH_ASSIGNED_USERS_FAILURE'
export const DASHBOARD_FETCH_ASSIGNED_USERS_DISMISS_ERROR =
    'DASHBOARD_FETCH_ASSIGNED_USERS_DISMISS_ERROR'
export const MANAGE_FETCH_COHORTS_BEGIN = 'MANAGE_FETCH_COHORTS_BEGIN'
export const MANAGE_FETCH_COHORTS_SUCCESS = 'MANAGE_FETCH_COHORTS_SUCCESS'
export const MANAGE_FETCH_COHORTS_FAILURE = 'MANAGE_FETCH_COHORTS_FAILURE'
export const MANAGE_FETCH_COHORTS_DISMISS_ERROR =
    'MANAGE_FETCH_COHORTS_DISMISS_ERROR'
export const MANAGE_ADD_USER_BEGIN = 'MANAGE_ADD_USER_BEGIN'
export const MANAGE_ADD_USER_SUCCESS = 'MANAGE_ADD_USER_SUCCESS'
export const MANAGE_ADD_USER_FAILURE = 'MANAGE_ADD_USER_FAILURE'
export const MANAGE_ADD_USER_DISMISS_ERROR = 'MANAGE_ADD_USER_DISMISS_ERROR'
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_BEGIN =
    'MANAGE_BULK_ASSIGN_USER_ROADMAPS_BEGIN'
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_SUCCESS =
    'MANAGE_BULK_ASSIGN_USER_ROADMAPS_SUCCESS'
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_FAILURE =
    'MANAGE_BULK_ASSIGN_USER_ROADMAPS_FAILURE'
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_DISMISS_ERROR =
    'MANAGE_BULK_ASSIGN_USER_ROADMAPS_DISMISS_ERROR'
export const DASHBOARD_COACH_CALLS_BEGIN = 'DASHBOARD_COACH_CALLS_BEGIN'
export const DASHBOARD_COACH_CALLS_SUCCESS = 'DASHBOARD_COACH_CALLS_SUCCESS'
export const DASHBOARD_COACH_CALLS_FAILURE = 'DASHBOARD_COACH_CALLS_FAILURE'
export const DASHBOARD_COACH_CALLS_DISMISS_ERROR =
    'DASHBOARD_COACH_CALLS_DISMISS_ERROR'
export const DASHBOARD_ADD_COACH_CALL_BEGIN = 'DASHBOARD_ADD_COACH_CALL_BEGIN'
export const DASHBOARD_ADD_COACH_CALL_SUCCESS =
    'DASHBOARD_ADD_COACH_CALL_SUCCESS'
export const DASHBOARD_ADD_COACH_CALL_FAILURE =
    'DASHBOARD_ADD_COACH_CALL_FAILURE'
export const DASHBOARD_ADD_COACH_CALL_DISMISS_ERROR =
    'DASHBOARD_ADD_COACH_CALL_DISMISS_ERROR'
export const DASHBOARD_USER_CALLS_BEGIN = 'DASHBOARD_USER_CALLS_BEGIN'
export const DASHBOARD_USER_CALLS_SUCCESS = 'DASHBOARD_USER_CALLS_SUCCESS'
export const DASHBOARD_USER_CALLS_FAILURE = 'DASHBOARD_USER_CALLS_FAILURE'
export const DASHBOARD_USER_CALLS_DISMISS_ERROR =
    'DASHBOARD_USER_CALLS_DISMISS_ERROR'
export const DASHBOARD_UPDATE_COACH_CALL_BEGIN =
    'DASHBOARD_UPDATE_COACH_CALL_BEGIN'
export const DASHBOARD_UPDATE_COACH_CALL_SUCCESS =
    'DASHBOARD_UPDATE_COACH_CALL_SUCCESS'
export const DASHBOARD_UPDATE_COACH_CALL_FAILURE =
    'DASHBOARD_UPDATE_COACH_CALL_FAILURE'
export const DASHBOARD_UPDATE_COACH_CALL_DISMISS_ERROR =
    'DASHBOARD_UPDATE_COACH_CALL_DISMISS_ERROR'
export const DASHBOARD_DELETE_COACH_CALL_BEGIN =
    'DASHBOARD_DELETE_COACH_CALL_BEGIN'
export const DASHBOARD_DELETE_COACH_CALL_SUCCESS =
    'DASHBOARD_DELETE_COACH_CALL_SUCCESS'
export const DASHBOARD_DELETE_COACH_CALL_FAILURE =
    'DASHBOARD_DELETE_COACH_CALL_FAILURE'
export const DASHBOARD_DELETE_COACH_CALL_DISMISS_ERROR =
    'DASHBOARD_DELETE_COACH_CALL_DISMISS_ERROR'

export const DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_BEGIN =
    'DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_BEGIN'
export const DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_SUCCESS =
    'DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_SUCCESS'
export const DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_FAILURE =
    'DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_FAILURE'
export const DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_DISMISS_ERROR =
    'DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_DISMISS_ERROR'
