import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ROADMAP_ACTION_ITEM_RESPONSE_BEGIN,
  ROADMAP_ACTION_ITEM_RESPONSE_SUCCESS,
  ROADMAP_ACTION_ITEM_RESPONSE_FAILURE,
  ROADMAP_ACTION_ITEM_RESPONSE_DISMISS_ERROR,
} from './constants';

import axios from 'axios';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import config from '../../../common/config';

export function actionItemResponse(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: ROADMAP_ACTION_ITEM_RESPONSE_BEGIN,
    });

    const {
      roadmapId,
      stageId,
      competencyId,
      actionItemId,
      message,
      method,
      responseId,
    } = args;

    let fetchActionItem;

    switch (method) {
      case 'POST':
        fetchActionItem = axios.post;
        break;
      case 'PUT':
        fetchActionItem = axios.put;
        break;
      case 'DELETE':
        fetchActionItem = axios.delete;
        break;
      case 'GET':
        fetchActionItem = axios.get;
        break;
      default:
        fetchActionItem = axios.get;
    }

    const promise = new Promise((resolve, reject) => {
      let url = `${config.apiRootUrl}/roadmaps/${roadmapId}/stages/${stageId}/competencies/${competencyId}/action-item-assessments/${actionItemId}/responses/`;

      if (method === 'PUT') {
        url += responseId + '/';
      }

      const doRequest =
        method === 'GET'
          ? fetchActionItem(url, createAxiosConfigWithAuth(getState()))
          : fetchActionItem(
              url,
              { message },
              createAxiosConfigWithAuth(getState()),
            );

      doRequest.then(
        (res) => {
          dispatch({
            type: ROADMAP_ACTION_ITEM_RESPONSE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: ROADMAP_ACTION_ITEM_RESPONSE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissActionItemResponseError() {
  return {
    type: ROADMAP_ACTION_ITEM_RESPONSE_DISMISS_ERROR,
  };
}

export function useActionItemResponse() {
  const dispatch = useDispatch();

  const { actionItemResponsePending, actionItemResponseError } = useSelector(
    (state) => ({
      actionItemResponsePending: state.roadmap.actionItemResponsePending,
      actionItemResponseError: state.roadmap.actionItemResponseError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(actionItemResponse(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissActionItemResponseError());
  }, [dispatch]);

  return {
    actionItemResponse: boundAction,
    actionItemResponsePending,
    actionItemResponseError,
    dismissActionItemResponseError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ROADMAP_ACTION_ITEM_RESPONSE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        actionItemResponsePending: true,
        actionItemResponseError: null,
      };

    case ROADMAP_ACTION_ITEM_RESPONSE_SUCCESS:
      // The request is success
      return {
        ...state,
        actionItemResponsePending: false,
        actionItemResponseError: null,
      };

    case ROADMAP_ACTION_ITEM_RESPONSE_FAILURE:
      // The request is failed
      return {
        ...state,
        actionItemResponsePending: false,
        actionItemResponseError: action.data.error,
      };

    case ROADMAP_ACTION_ITEM_RESPONSE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        actionItemResponseError: null,
      };

    default:
      return state;
  }
}
