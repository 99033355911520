import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { SupportContext } from '../../../Dashboard'

const UpdateValues = () => {
    // Grab values and submitForm from context
    const { setStepValues } = useContext(SupportContext)
    const { values } = useFormikContext()

    React.useEffect(() => {
        setStepValues(values)
    }, [values])
    return null
}

export default UpdateValues
