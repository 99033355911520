import React from 'react';

export default function DashboardIcon({ size, color = '#343434' }) {
  return (
    <svg
      width={size}
      height={size / 1.84}
      viewBox="0 0 24 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_962_551)">
        <path
          d="M23 13C22.7348 13 22.4804 12.8946 22.2929 12.7071C22.1054 12.5196 22 12.2652 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 12.2652 1.89464 12.5196 1.70711 12.7071C1.51957 12.8946 1.26522 13 1 13C0.734784 13 0.48043 12.8946 0.292893 12.7071C0.105357 12.5196 0 12.2652 0 12C0 8.8174 1.26428 5.76515 3.51472 3.51472C5.76515 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76515 24 8.8174 24 12C24 12.2652 23.8946 12.5196 23.7071 12.7071C23.5196 12.8946 23.2652 13 23 13Z"
          fill={color}
        />
        <path
          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44771 12.5523 4 12 4C11.4477 4 11 4.44771 11 5C11 5.55228 11.4477 6 12 6Z"
          fill={color}
        />
        <path
          d="M20 13H19C18.7348 13 18.4804 12.8946 18.2929 12.7071C18.1054 12.5196 18 12.2652 18 12C18 11.7348 18.1054 11.4804 18.2929 11.2929C18.4804 11.1054 18.7348 11 19 11H20C20.2652 11 20.5196 11.1054 20.7071 11.2929C20.8946 11.4804 21 11.7348 21 12C21 12.2652 20.8946 12.5196 20.7071 12.7071C20.5196 12.8946 20.2652 13 20 13ZM5 13H4C3.73478 13 3.48043 12.8946 3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929C3.48043 11.1054 3.73478 11 4 11H5C5.26522 11 5.51957 11.1054 5.70711 11.2929C5.89464 11.4804 6 11.7348 6 12C6 12.2652 5.89464 12.5196 5.70711 12.7071C5.51957 12.8946 5.26522 13 5 13ZM12 6C11.7348 6 11.4804 5.89464 11.2929 5.70711C11.1054 5.51957 11 5.26522 11 5V4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4V5C13 5.26522 12.8946 5.51957 12.7071 5.70711C12.5196 5.89464 12.2652 6 12 6Z"
          fill={color}
        />
        <path
          d="M7.04981 8.05005C7.60209 8.05005 8.04981 7.60233 8.04981 7.05005C8.04981 6.49776 7.60209 6.05005 7.04981 6.05005C6.49752 6.05005 6.0498 6.49776 6.0498 7.05005C6.0498 7.60233 6.49752 8.05005 7.04981 8.05005Z"
          fill={color}
        />
        <path
          d="M11.9999 13.0001C11.8683 13.0008 11.7378 12.9756 11.616 12.9258C11.4941 12.8761 11.3833 12.8028 11.2899 12.7101C11.1961 12.6171 11.1217 12.5065 11.071 12.3846C11.0202 12.2628 10.9941 12.1321 10.9941 12.0001C10.9941 11.8681 11.0202 11.7374 11.071 11.6155C11.1217 11.4936 11.1961 11.383 11.2899 11.2901L16.9999 5.64007C17.0895 5.53539 17.1998 5.45037 17.3239 5.39035C17.448 5.33032 17.5831 5.29659 17.7208 5.29127C17.8585 5.28595 17.9959 5.30916 18.1242 5.35943C18.2525 5.40971 18.3691 5.48597 18.4665 5.58342C18.564 5.68088 18.6402 5.79742 18.6905 5.92575C18.7408 6.05407 18.764 6.19141 18.7587 6.32912C18.7534 6.46684 18.7196 6.60198 18.6596 6.72604C18.5996 6.85011 18.5146 6.96042 18.4099 7.05007L12.7599 12.7101C12.6606 12.809 12.5416 12.8859 12.4107 12.9358C12.2798 12.9858 12.1398 13.0077 11.9999 13.0001ZM7.04987 8.05007C6.91826 8.05083 6.7878 8.02561 6.66596 7.97584C6.54413 7.92608 6.43331 7.85275 6.33987 7.76007L5.63987 7.05007C5.47604 6.85877 5.39044 6.61269 5.40016 6.36102C5.40988 6.10934 5.51421 5.8706 5.69231 5.69251C5.8704 5.51441 6.10914 5.41008 6.36082 5.40036C6.61249 5.39064 6.85857 5.47624 7.04987 5.64007L7.75987 6.34007C7.8536 6.43303 7.92799 6.54363 7.97876 6.66549C8.02953 6.78735 8.05567 6.91806 8.05567 7.05007C8.05567 7.18208 8.02953 7.31279 7.97876 7.43465C7.92799 7.55651 7.8536 7.66711 7.75987 7.76007C7.66643 7.85275 7.55562 7.92608 7.43378 7.97584C7.31194 8.02561 7.18148 8.05083 7.04987 8.05007Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_962_551">
          <rect width={size} height={size / 1.84} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
