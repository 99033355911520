import { useContext, useEffect } from 'react';
import { CompetencyContext } from '../../CompetencyPage';
import { replaceBadLinks } from '../../utils/replaceBadLinks';

export default function ClearInterval() {
  const { editorRef } = useContext(CompetencyContext);
  useEffect(() => {
    const intervalId = replaceBadLinks(editorRef);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return null;
}
