import React, { useEffect, useCallback } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useFetchUser } from '../../user/redux/fetchUser';
import { useFetchGlobalQuestions } from '../../manage/redux/fetchGlobalQuestions';
import { useFetchQuestionAnswers } from '../redux/fetchQuestionAnswers';
import { useAddQuestionAnswer } from '../redux/addQuestionAnswer';
import { useUpdateQuestionAnswer } from '../redux/updateQuestionAnswer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useWindowSize, useWindowsSize } from '../../../common/windowsSize.js';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object().shape({
  answer: yup.string().nullable(),
});

export default function AnswerModal(props) {
  const { show, onHide } = props;

  const size = useWindowSize();
  const { roadmapId, stageId, competencyId, answerId, questionId } =
    useParams();
  const history = useHistory();
  const { user } = useFetchUser();
  const { globalQuestions, fetchGlobalQuestions } = useFetchGlobalQuestions();
  const { questionAnswers, fetchQuestionAnswers } = useFetchQuestionAnswers();
  const { addQuestionAnswer } = useAddQuestionAnswer();
  const { updateQuestionAnswer } = useUpdateQuestionAnswer();

  const question =
    globalQuestions &&
    globalQuestions.find((x) => x.id === parseInt(questionId));

  const answer =
    questionAnswers &&
    answerId &&
    questionAnswers.find((x) => x.id === parseInt(answerId));

  useEffect(() => {
    if (
      !roadmapId ||
      !stageId ||
      !competencyId ||
      !user ||
      !questionId ||
      !show
    ) {
      return;
    }
    fetchGlobalQuestions({ roadmapId, stageId, competencyId });

    if (answerId) {
      fetchQuestionAnswers({ roadmapId, stageId, competencyId });
    }
  }, [
    roadmapId,
    stageId,
    competencyId,
    answerId,
    user,
    fetchGlobalQuestions,
    fetchQuestionAnswers,
  ]);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = useCallback(
    (err) =>
      Object.keys(err).forEach((key) => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
        }
      }),
    [setError],
  );

  const handleSaveClick = useCallback(
    ({ answer }) => {
      if (answerId != 'undefined') {
        console.log(answerId);
        updateQuestionAnswer({
          roadmapId,
          stageId,
          competencyId,
          parent: questionId,
          answer,
          answerId,
        })
          .then(() => {
            history.push(
              `/roadmap/${roadmapId}/stage/${stageId}/competency/${competencyId}`,
              { from: 'saving' },
            );
          })
          .catch((error) => {
            console.log(error);
            console.log('answerId', answerId);
          });
      } else {
        addQuestionAnswer({
          roadmapId,
          stageId,
          competencyId,
          parent: questionId,
          answer,
        })
          .then(() => {
            history.push(
              `/roadmap/${roadmapId}/stage/${stageId}/competency/${competencyId}`,
              { from: 'saving' },
            );
          })
          .catch((e) => console.log('error adding answer', e));
      }
    },
    [
      roadmapId,
      stageId,
      competencyId,
      questionId,
      answerId,
      addQuestionAnswer,
      updateQuestionAnswer,
      handleError,
    ],
  );

  const renderSaveButton = useCallback(
    () => (
      <button
        className="btn-save-question"
        onClick={handleSubmit(handleSaveClick)}
      >
        Save
      </button>
    ),
    [handleSaveClick, handleSubmit],
  );

  return (
    <Modal
      dialogClassName="answer-modal-width"
      show={show}
      onHide={onHide}
      className="answer-question-modal"
    >
      <Modal.Header className="answer-modal-header">
        <button onClick={onHide} className="cancel-btn">
          Cancel
        </button>
        {size.width < 768 && <h1>{question && question.question}</h1>}
        {renderSaveButton()}
      </Modal.Header>
      <Modal.Body>
        {size.width > 768 && <h1>{question && question.question}</h1>}
        <Form>
          <Form.Group controlId="coach_notes">
            <Form.Control
              name="answer"
              as="textarea"
              defaultValue={answer && answer.answer}
              isInvalid={errors.answer}
              ref={register}
              placeholder="Add answer"
              rows={3}
              className="mrm-mt-1"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
