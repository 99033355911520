import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_DELETE_ASSESSMENT_BEGIN,
    RPM_DELETE_ASSESSMENT_SUCCESS,
    RPM_DELETE_ASSESSMENT_FAILURE,
    RPM_DELETE_ASSESSMENT_DISMISS_ERROR,
} from './constants'
import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function deleteAssessment(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_DELETE_ASSESSMENT_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.delete(
                `${config.apiRootUrl}/rpm/assessment-score/${args.id}/`,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_DELETE_ASSESSMENT_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_DELETE_ASSESSMENT_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissDeleteAssessmentError() {
    return {
        type: RPM_DELETE_ASSESSMENT_DISMISS_ERROR,
    }
}

export function useDeleteAssessment() {
    const dispatch = useDispatch()

    const { deleteAssessmentPending, deleteAssessmentError } = useSelector(
        state => ({
            deleteAssessmentPending: state.rpm.deleteAssessmentPending,
            deleteAssessmentError: state.rpm.deleteAssessmentError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(deleteAssessment(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissDeleteAssessmentError())
    }, [dispatch])

    return {
        deleteAssessment: boundAction,
        deleteAssessmentPending,
        deleteAssessmentError,
        dismissDeleteAssessmentError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_DELETE_ASSESSMENT_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                deleteAssessmentPending: true,
                deleteAssessmentError: null,
            }

        case RPM_DELETE_ASSESSMENT_SUCCESS:
            // The request is success
            return {
                ...state,
                deleteAssessmentPending: false,
                deleteAssessmentError: null,
            }

        case RPM_DELETE_ASSESSMENT_FAILURE:
            // The request is failed
            return {
                ...state,
                deleteAssessmentPending: false,
                deleteAssessmentError: action.data.error,
            }

        case RPM_DELETE_ASSESSMENT_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                deleteAssessmentError: null,
            }

        default:
            return state
    }
}
