import React, { useEffect } from 'react';
import CancelSubscriptionModal from '../CancelSubscriptionModal';
import {
  useHasActiveSubcription,
  useHasCanceledSubscription,
} from '../../../../common/permissions';
import { useGetSubscriptionByCoachAndUser } from '../../redux/getSubscriptionByCoachAndUser';
import { useFetchUser } from '../../redux/fetchUser';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

export default function SubscriptionContainer({ desktop = true }) {
  const { user } = useFetchUser();
  const { getSubscriptionByCoachAndUser } = useGetSubscriptionByCoachAndUser();
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation();

  const { isActive, subscriptionState } = useHasActiveSubcription();

  let isCanceledButActive =
    subscriptionState === 'canceled but active' ? true : false;

  const { isCanceled } = useHasCanceledSubscription();

  const [subscription, setSubscription] = React.useState(null);

  useEffect(() => {
    getSubscriptionByCoachAndUser({ id: user?.id }).then((res) => {
      setSubscription(res.data);
      console.log('subdata', res.data);
    });
  }, [getSubscriptionByCoachAndUser, user]);

  if (!subscription?.plan?.name) return <></>;

  return (
    <div
      className={`subscription-container`}
      style={{ padding: desktop === true ? '2.5rem' : '2rem 1.5rem' }}
    >
      <p className="title">{t('profile.subscription')}</p>
      <div className="subscription-details-grid">
        <div className="subscription-details-grid-description">
          <h2>{subscription?.plan?.name}</h2>
        </div>
        <div
          className="subscription-details-grid-price"
          style={{ marginBottom: !desktop ? '-1.2rem' : '' }}
        >
          <h2>
            $
            {subscription?.plan?.unit_amount.toString().length < 3
              ? subscription?.plan?.unit_amount + '.00'
              : subscription?.plan?.unit_amount / 100 + '.00'}
          </h2>
          {isActive && !isCanceledButActive && (
            <button onClick={() => setShow(true)}>
              <p>Cancel Subscription</p>
            </button>
          )}

          {(isCanceled || isCanceledButActive) && (
            <a
              href="mailto:hello@noble.health"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Reactivate</p>
            </a>
          )}
        </div>
      </div>
      <CancelSubscriptionModal
        show={show}
        onHide={() => {
          setShow(false);
          window.location.reload(false);
        }}
      />
    </div>
  );
}
