export const profile = {
  view_profile: 'Ver perfil',
  edit: 'Editar',
  account_type: 'Tipo de cuenta',
  email: 'Email',
  phone: 'Teléfono',
  date_joined: 'Fecha de registro',
  last_login: 'Último inicio de sesión',
  my_coaches: 'Mis entrenadores',
  my_clients: 'Mis clientes',
  go_back: 'Regresar',
  my_profile: 'Mi perfil',
  resend_invitation: 'Reenviar invitación',
  subscription: 'Suscripción',
};
