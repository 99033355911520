import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_BEGIN,
  SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_SUCCESS,
  SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_FAILURE,
  SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function createSubscriptionOnboardingCallCenter(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.apiRootUrl}/subscriptions/create-subscription-onboarding-call-center/`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateSubscriptionOnboardingCallCenterError() {
  return {
    type: SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_DISMISS_ERROR,
  };
}

export function useCreateSubscriptionOnboardingCallCenter() {
  const dispatch = useDispatch();

  const {
    createSubscriptionOnboardingCallCenterPending,
    createSubscriptionOnboardingCallCenterError,
  } = useSelector(
    (state) => ({
      createSubscriptionOnboardingCallCenterPending:
        state.support.createSubscriptionOnboardingCallCenterPending,
      createSubscriptionOnboardingCallCenterError:
        state.support.createSubscriptionOnboardingCallCenterError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(createSubscriptionOnboardingCallCenter(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateSubscriptionOnboardingCallCenterError());
  }, [dispatch]);

  return {
    createSubscriptionOnboardingCallCenter: boundAction,
    createSubscriptionOnboardingCallCenterPending,
    createSubscriptionOnboardingCallCenterError,
    dismissCreateSubscriptionOnboardingCallCenterError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createSubscriptionOnboardingCallCenterPending: true,
        createSubscriptionOnboardingCallCenterError: null,
      };

    case SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_SUCCESS:
      // The request is success
      return {
        ...state,
        createSubscriptionOnboardingCallCenterPending: false,
        createSubscriptionOnboardingCallCenterError: null,
      };

    case SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_FAILURE:
      // The request is failed
      return {
        ...state,
        createSubscriptionOnboardingCallCenterPending: false,
        createSubscriptionOnboardingCallCenterError: action.data.error,
      };

    case SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createSubscriptionOnboardingCallCenterError: null,
      };

    default:
      return state;
  }
}
