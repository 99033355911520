export const dropdown = {
  profile: 'Profile',
  switch_company: 'Switch company',
  logout: 'Logout',
  assessments: 'Assessments',
  manage_subscriptions: 'Manage subscriptions',
  internal_subscriptions: 'Internal subscriptions',
  contracts: 'Contracts',
  accounts: 'Accounts',
  onboarding: 'Onboarding',
  roadmaps: 'Roadmaps',
  analytics: 'Analytics',
  help: 'Help',
  app_information: 'App information',
  language: 'Language',
  notifications: 'Notifications',
  campaigns: 'Campaigns',
};
