import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { xml2json } from '../../../../utils/xml2json'
import { useGetCityAndState } from '../../../../redux/getCityAndState'

export default function SetZip({ setZipError }) {
    const parser = new DOMParser()

    const { values, setFieldValue } = useFormikContext()
    const { getCityAndState } = useGetCityAndState()

    useEffect(() => {
        const getCityAndStatePromise = async () => {
            await getCityAndState({
                zipcode: values.zip,
            })
                .then(response => {
                    const data = `${response.data}`
                    const srcDOM = parser.parseFromString(
                        data,
                        'application/xml'
                    )
                    const res = xml2json(srcDOM)

                    const zipObject = res.CityStateLookupResponse.ZipCode

                    setFieldValue('city', zipObject.City)
                    setFieldValue('state', zipObject.State)
                    console.log('res', zipObject)

                    if (zipObject.Error) {
                        console.log('ERROR')
                        setZipError(
                            'Zip code not found.' +
                            ' ' + ' City and State are now editable'
                        )
                        setFieldValue('city', '')
                        setFieldValue('state', '')
                    } else {
                        setZipError(false)
                    }
                })
                .catch(err => {
                    console.log('getCityAndState', err)
                })
        }

        if (values.zip !== '00000') {
            if (values.zip && values.zip.length === 5) {
                getCityAndStatePromise()
            }
        }
    }, [values.zip])

    return null
}
