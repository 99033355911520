import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  USER_CANCEL_SUBSCRIPTION_BEGIN,
  USER_CANCEL_SUBSCRIPTION_SUCCESS,
  USER_CANCEL_SUBSCRIPTION_FAILURE,
  USER_CANCEL_SUBSCRIPTION_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import axios from 'axios';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function cancelSubscription(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: USER_CANCEL_SUBSCRIPTION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.put(
        `${config.apiRootUrl}/subscriptions/cancel-subscription`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: USER_CANCEL_SUBSCRIPTION_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: USER_CANCEL_SUBSCRIPTION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCancelSubscriptionError() {
  return {
    type: USER_CANCEL_SUBSCRIPTION_DISMISS_ERROR,
  };
}

export function useCancelSubscription() {
  const dispatch = useDispatch();

  const { cancelSubscriptionPending, cancelSubscriptionError } = useSelector(
    (state) => ({
      cancelSubscriptionPending: state.user.cancelSubscriptionPending,
      cancelSubscriptionError: state.user.cancelSubscriptionError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(cancelSubscription(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCancelSubscriptionError());
  }, [dispatch]);

  return {
    cancelSubscription: boundAction,
    cancelSubscriptionPending,
    cancelSubscriptionError,
    dismissCancelSubscriptionError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_CANCEL_SUBSCRIPTION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        cancelSubscriptionPending: true,
        cancelSubscriptionError: null,
      };

    case USER_CANCEL_SUBSCRIPTION_SUCCESS:
      // The request is success
      return {
        ...state,
        cancelSubscriptionPending: false,
        cancelSubscriptionError: null,
      };

    case USER_CANCEL_SUBSCRIPTION_FAILURE:
      // The request is failed
      return {
        ...state,
        cancelSubscriptionPending: false,
        cancelSubscriptionError: action.data.error,
      };

    case USER_CANCEL_SUBSCRIPTION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        cancelSubscriptionError: null,
      };

    default:
      return state;
  }
}
