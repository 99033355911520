import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useOutsideAlerter from '../../../../../common/useOutsideAlerter';
import Icon from '../../../../common/components/Icon';
import { ClientsCardContext } from '../ClientsCard';
import RoadmapsFilter from './RoadmapsFilter';
import StatusFilter from './StatusFilter';
import useWindowSize from '../../../../common/useWindowResize';
import BottomSheet from '../../../../common/BottomSheet';
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage';

export default function FilterToast() {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const {
    setAssignedUsersState,
    assignedUsersState,
    showFilterToast,
    setShowFilterToast,
  } = useContext(ClientsCardContext);

  // Filter Toast
  const [toastState, setToastState] = useState({
    openEnrolledRoadmaps: false,
    selectedEnrolledRoadmaps: new Set(),
    openStatus: false,
    selectedStatuses: new Set(),
  });

  const openFilter = useCallback(() => {
    setShowFilterToast(true);
  });

  const closeFilter = useCallback(() => {
    setShowFilterToast(false);
  });

  const filterToastRef = useRef(null);
  useOutsideAlerter(filterToastRef, closeFilter); // when clicked outside of the filterToast, close it

  useEffect(() => {
    if (
      toastState.selectedEnrolledRoadmaps.size !== 0 &&
      toastState.selectedStatuses.size !== 0
    )
      return;

    setToastState((prevState) => ({
      ...prevState,
      selectedEnrolledRoadmaps: new Set(assignedUsersState.filters.roadmaps),
      selectedStatuses: new Set(assignedUsersState.filters.status),
    }));
  }, [assignedUsersState]);

  const applyFilters = useCallback(async () => {
    setAssignedUsersState({
      ...toastState,
      filters: {
        roadmaps: Array.from(toastState.selectedEnrolledRoadmaps),
        status: Array.from(toastState.selectedStatuses),
      },
    });
    setShowFilterToast(false);
  }, [toastState, setAssignedUsersState, setShowFilterToast]);

  const resetFilters = useCallback(() => {
    setToastState({
      openEnrolledRoadmaps: false,
      selectedEnrolledRoadmaps: new Set(),
      openStatus: false,
      selectedStatuses: new Set(),
    });

    setAssignedUsersState({
      ...toastState,
      filters: {
        roadmaps: [],
        status: [],
      },
    });
    setShowFilterToast(false);
  }, [toastState, setAssignedUsersState]);

  return (
    <React.Fragment>
      <div className="filter-wrapper" onClick={openFilter}>
        <Icon name="filter" />
        <span className="filter-text">
          {t('dashboard.clients.filters.filter')}
        </span>
        {showFilterToast && width > 768 && (
          <div className="filter-toast" ref={filterToastRef}>
            <p className="title">
              {' '}
              {t('dashboard.clients.filters.filter').toUpperCase()}{' '}
            </p>
            <RoadmapsFilter
              setToastState={setToastState}
              toastState={toastState}
            />
            <StatusFilter
              setToastState={setToastState}
              toastState={toastState}
            />
            <div className="filter-buttons">
              <button
                type="button"
                className="apply-button"
                onClick={applyFilters}
              >
                {t('dashboard.clients.filters.apply_filters')}
              </button>
              <button
                type="button"
                className="clear-button"
                onClick={resetFilters}
              >
                {t('dashboard.clients.filters.reset_filters')}
              </button>
            </div>
          </div>
        )}
      </div>
      {width <= 768 && showFilterToast && (
        <BottomSheet
          className="filter-toast"
          onClose={closeFilter}
          style={{
            bottom: 0,
            top: 'auto',
            position: 'fixed',
            zIndex: 9999999,
            width: '100%',
            overflow: 'hidden',
            overscrollBehavior: 'contain',
          }}
        >
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              width: '100%',
              padding: '0',
              overscrollBehavior: 'contain',
            }}
          >
            <p className="title">
              {' '}
              {t('dashboard.clients.filters.filter').toUpperCase()}{' '}
            </p>
            <RoadmapsFilter
              setToastState={setToastState}
              toastState={toastState}
            />
            <StatusFilter
              setToastState={setToastState}
              toastState={toastState}
            />
            <div className="filter-buttons">
              <button
                type="button"
                className="apply-button"
                onClick={applyFilters}
              >
                {t('dashboard.clients.filters.apply_filters')}
              </button>
              <button
                type="button"
                className="clear-button"
                onClick={resetFilters}
              >
                {t('dashboard.clients.filters.reset_filters')}
              </button>
            </div>
          </div>
        </BottomSheet>
      )}
    </React.Fragment>
  );
}
