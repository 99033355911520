export const SUPPORT_GET_CITY_AND_STATE_BEGIN =
  'SUPPORT_GET_CITY_AND_STATE_BEGIN';
export const SUPPORT_GET_CITY_AND_STATE_SUCCESS =
  'SUPPORT_GET_CITY_AND_STATE_SUCCESS';
export const SUPPORT_GET_CITY_AND_STATE_FAILURE =
  'SUPPORT_GET_CITY_AND_STATE_FAILURE';
export const SUPPORT_GET_CITY_AND_STATE_DISMISS_ERROR =
  'SUPPORT_GET_CITY_AND_STATE_DISMISS_ERROR';
export const SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_BEGIN =
  'SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_BEGIN';
export const SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_SUCCESS =
  'SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_SUCCESS';
export const SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_FAILURE =
  'SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_FAILURE';
export const SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_DISMISS_ERROR =
  'SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_DISMISS_ERROR';
export const SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_BEGIN =
  'SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_BEGIN';
export const SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_SUCCESS =
  'SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_SUCCESS';
export const SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_FAILURE =
  'SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_FAILURE';
export const SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_DISMISS_ERROR =
  'SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_DISMISS_ERROR';
export const SUPPORT_GET_ACTIVE_CONTRACTS_BEGIN =
  'SUPPORT_GET_ACTIVE_CONTRACTS_BEGIN';
export const SUPPORT_GET_ACTIVE_CONTRACTS_SUCCESS =
  'SUPPORT_GET_ACTIVE_CONTRACTS_SUCCESS';
export const SUPPORT_GET_ACTIVE_CONTRACTS_FAILURE =
  'SUPPORT_GET_ACTIVE_CONTRACTS_FAILURE';
export const SUPPORT_GET_ACTIVE_CONTRACTS_DISMISS_ERROR =
  'SUPPORT_GET_ACTIVE_CONTRACTS_DISMISS_ERROR';
('SUPPORT_CREATE_SUBSCRIPTION_ONBOARDING_CALL_CENTER_DISMISS_ERROR');
('SUPPORT_CREATE_USER_AND_SUBSCRIPTION_CALLCENTER_ONBOARDING_DISMISS_ERROR');
('SUPPORT_GET_CITY_AND_STATE_DISMISS_ERROR');
