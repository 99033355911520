import React, { useEffect, useState } from 'react';
import getRelativeDay from '../../../../common/getRelativeDay';
import Icon from '../../../common/components/Icon';
import ResponseCard from './ResponseCard';
import ResponseInput from './ResponseInput';
import ResponseTextarea from './ResponseTextarea';
import ThreadComments from './ThreadComments';
import { useFetchUser } from '../../../user/redux/fetchUser';

export default function MobileResponses({ ...props }) {
  const {
    hasResponse,
    hasNoResponses,
    actionItemResponses,
    responseMessage,
    setResponseMessage,
    fetchActionItemResponses,
    setCenterEdit,
    ...responseCardProps
  } = props;

  const { user, fetchUser } = useFetchUser();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const inputRef = React.useRef(null);

  useEffect(() => {
    // AUTO RESIZE TEXTAREA
    const autoResize = () => {
      const textarea = inputRef.current;
      if (!textarea) return;

      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight - 20}px`;
    };
    autoResize();
  }, [responseMessage]);

  // first response is the latest one -- it's the start of the thread in the UI
  const firstResponse =
    hasResponse &&
    actionItemResponses
      .filter((response) => response.message !== '')
      .slice(-1)[0];

  const [showComments, setShowComments] = useState(false);

  if (!user) return null;

  let customAvatar = `https://ui-avatars.com/api/?name=${
    user.first_name[0] + user.last_name[0]
  }&background=343434&color=fff&size=128`;

  return (
    <div className="fixed-bottom">
      {hasResponse && (
        <div
          className="mobile-first-response"
          style={{
            height: showComments ? '100vh' : '290px',
            transition: 'height 1s ease-in-out, border-radius 1.5s ease-in-out',
            borderRadius: showComments ? '0' : '40px 40px 0 0',
          }}
        >
          <div className="mobile-first-response-header">
            <button
              onClick={() => setShowComments(false)}
              style={{
                zIndex: showComments ? '1' : '-1',
                opacity: showComments ? '1' : '0',
                transition: 'opacity 1s ease-in-out, z-index 1s ease-in-out',
                position: 'absolute',
                left: '0',
              }}
              disabled={props.isEditing}
            >
              <Icon name="chevronLeft" size={10} />
            </button>

            <p
              className="response-label"
              style={{
                left: showComments ? '40%' : '0',
                transition: 'left 1s ease-in-out',
              }}
            >
              {' '}
              Response{' '}
            </p>
          </div>

          <hr
            style={{
              opacity: showComments ? '1' : '0',
              transition: 'opacity 1s ease-in-out',
              position: 'absolute',
              top: '66px',
              width: '100%',
              zIndex: '999999',
            }}
          />

          <div
            className="mobile-all-responses-container"
            style={{
              height: showComments ? '100%' : 'auto',
              overflowY: showComments ? 'auto' : 'hidden',
            }}
          >
            {firstResponse && !props.isEditing && (
              <ResponseCard
                item={firstResponse}
                relativeTime={getRelativeDay(firstResponse?.created_at)}
                {...props}
                collapsed={!showComments}
                className="height-action-item-start"
                style={{
                  ...(showComments
                    ? {
                        marginTop: '1rem',
                        transition: 'margin-top 1s ease-in-out',
                      }
                    : {}),
                }}
                editCallback={() => {
                  setShowComments(true);
                }}
              />
            )}
            {!props.isEditing && (
              <button
                className="comment-btn-action-item-container"
                onClick={() => {
                  setShowComments(!showComments);
                }}
                disabled={showComments}
                style={{
                  opacity: showComments ? '0' : '1',
                  transition: 'opacity 1s ease-in-out',
                  zIndex: showComments ? '-1' : '1',
                  position: showComments ? 'absolute' : 'relative',
                }}
              >
                <Icon
                  name="message"
                  size={15}
                  color="var(--primary-dark-green)"
                />
                <p> Comment </p>
              </button>
            )}

            {!props.isEditing && (
              <p
                className="comments-length"
                onClick={() => setShowComments(true)}
                style={{
                  borderLeft: showComments ? 'none' : '3px solid #e5e5e5',
                  paddingLeft: showComments ? '0' : '.5rem',
                  fontSize: showComments ? '14px' : '12px',
                  transition:
                    'border-left 1s ease-in-out, padding-left 1s ease-in-out, font-size 1s ease-in-out',
                }}
              >
                {actionItemResponses.filter(
                  (response) => response.message !== '',
                ).length - 1}{' '}
                comment{''}
                {actionItemResponses.filter(
                  (response) => response.message !== '',
                ).length -
                  1 >
                1
                  ? 's'
                  : ''}
              </p>
            )}

            {props.isEditing && (
              <div className="w-full flex flex-col">
                <ResponseTextarea
                  {...props}
                  updateCallback={() => {
                    setShowComments(false);
                  }}
                />
              </div>
            )}

            {showComments && !props.isEditing && <ThreadComments {...props} />}

            {!props.isEditing && (
              <div
                className="mobile-send-action-item-response-container"
                style={{
                  opacity: showComments ? '1' : '0',
                  transition: 'opacity 1s ease-in-out',
                  zIndex: showComments ? '1' : '-1',
                }}
              >
                <div className="profile-pic-container">
                  <img
                    className="profile-pic"
                    src={user.photo || customAvatar}
                  />
                </div>
                <form
                  className="mobile-send-action-item-response"
                  style={{ borderRadius: 0 }}
                >
                  <textarea
                    style={{ resize: 'none' }}
                    type="text"
                    placeholder="Add comment..."
                    className="action-item-response-input"
                    value={responseMessage.message}
                    ref={inputRef}
                    onChange={(e) => {
                      setResponseMessage({
                        ...responseMessage,
                        message: e.target.value,
                      });
                    }}
                  />
                  <button
                    type="button"
                    className="action-item-response-button"
                    onClick={(e) => {
                      e.preventDefault();
                      setCenterEdit(false);
                      fetchActionItemResponses('POST');
                      setResponseMessage({ message: '' });
                    }}
                    disabled={responseMessage.message.length === 0}
                  >
                    <Icon name="send" color="#161824" size={20} />
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}

      {!responseCardProps.isEditing && <ResponseInput {...props} />}
    </div>
  );
}
