import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useExternalScripts } from '../../../auth/GoogleCalendar'
import { useGetSettingsByName } from '../../../auth/redux/getSettingsByName'
import { useSetDefaultLanguage } from '../../../auth/redux/setDefaultLanguage'

export default function EventsSection({ user }) {
  const { getSettingsByName, settings: data } = useGetSettingsByName()

  const { defaultLanguage } = useSetDefaultLanguage()

  const name = 'bloom'
  const type = 'default'

  useEffect(() => {
    getSettingsByName({ name, type })
  }, [user])

  useEffect(() => {
    console.log('data changed', data)
    console.log('defaultLanguage', defaultLanguage)
    }, [data])

  const scheduleButtonRef = useRef(null)

  // Callback to initialize the widget when the script is loaded
  const initializeBookingWidget = () => {
    if (!user) return

    if (window.GReminders && window.GReminders.BookingWidget) {
      const options = {
        anchorEl: scheduleButtonRef.current,
        fields: {
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone_number || '',
          email: user.email,
          c_company_id: user.company_id,
          c_customer_user_id: user.id,
          c_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          c_company_name: user.company_name,
          auto_submit: false,
          remember_me: false,
        },

        styles: {
          zIndex: 100,
        },
      }

      const onSuccess = (event_id, form_data) => {
        window.GReminders.BookingWidget.close()

        if (Boolean(data?.pages[defaultLanguage]?.download_app)) {
          window.location.href = `/auth/${name}/${type}/download`
        } else window.location.href = '/rpm/download-app'

        /* alert(
          'Success! ' +
            event_id +
            ' Customer Form Data: ' +
            JSON.stringify(form_data)
        ) */
      }

      const onError = message => {
        window.GReminders.BookingWidget.close()
        alert('Error! ' + message)
      }

      const bookingWidget = window.GReminders.BookingWidget

      bookingWidget.initialize(
        // This is the one that can be changed
        data?.pages[defaultLanguage]?.calendar.config.src,
        options
      )

      bookingWidget.open()
      bookingWidget.onSuccess(onSuccess)
      bookingWidget.onError(onError)
    }
  }

  // Load the external script with the callback
  useExternalScripts({
    url: 'https://app.greminders.com/widgets/booking.js',
    onLoad: initializeBookingWidget,
    user,
  })

  if (!Boolean(data?.pages[defaultLanguage]?.calendar)) return <p>hola</p>

  return (
    <div className="events-section">
      <input
        type="button"
        ref={scheduleButtonRef}
        value="Schedule With Me"
        style={{
          opacity: 0,
        }}
      />
    </div>
  )
}
