import React, { Fragment } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import Icon from '../../../common/components/Icon'
import { useOpenPeerConversation } from '../../../common/hooks/useOpenConversation'
import useWindowSize from '../../../common/useWindowResize'
import { useSendWelcomeEmail } from '../../../manage/redux/sendWelcomeEmail'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import AvatarBackground from '../../assets/provider-dashboard/avatar-background.png'
import Alerts from './Alerts'
import ResendInvitationConfirmation from './ResendInvitationConfirmation'

export default function DesktopRecords({
    clients,
    refetchClients,
    hasMore,
    fetchMoreUsers,
    getClientsPending,
}) {
    const { isDesktop } = useWindowSize()
    const history = useHistory()
    const { openPeerConversation } = useOpenPeerConversation()

    const [
        showInvitationConfirmation,
        setShowInvitationConfirmation,
    ] = React.useState(false)

    const { sendWelcomeEmail } = useSendWelcomeEmail()

    const { t } = useTranslation()

    if (!isDesktop) return null

    return (
        <Fragment>
            <ResendInvitationConfirmation
                show={showInvitationConfirmation}
                onHide={() => setShowInvitationConfirmation(false)}
            />
            <div className="rpm-provider-columns">
                <p className="rpm-provider-columns-name">
                    {t('coach_dashboard.columns.name')}
                </p>
                <div className="rpm-provider-columns-statuses">
                    <p>{t('coach_dashboard.columns.status')}</p>
                    <p>{t('coach_dashboard.columns.diagnosis')}</p>
                </div>
                <div className="rpm-provider-columns-alerts-and-chat">
                    <p className="rpm-provider-columns-alerts">
                        {t('coach_dashboard.columns.alerts')}
                    </p>
                    <p className="rpm-provider-columns-chat">
                        {t('coach_dashboard.columns.chat')}
                    </p>
                </div>
            </div>

            <div className="rpm-provider-records">
                {getClientsPending &&
                    Array.from({ length: 10 }).map((_, idx) => (
                        <div className="w-full flex flex-col" key={idx}>
                            <div className="skeleton-user-card">
                                <div className="skeleton-user-card__avatar" />
                                <div className="skeleton-user-card__user-info" />
                                <div className="skeleton-user-card__alerts-circle" />
                                <div className="skeleton-user-card__line" />

                                <div className="skeleton-user-card__info">
                                    <div className="skeleton-user-card__info__top" />
                                    <div className="skeleton-user-card__info__bottom" />
                                </div>
                                <div
                                    className="skeleton-user-card__info"
                                    style={{ left: '8.5rem' }}
                                >
                                    <div className="skeleton-user-card__info__top" />
                                    <div className="skeleton-user-card__info__bottom" />
                                </div>
                                <div
                                    className="skeleton-user-card__info"
                                    style={{
                                        left: 'auto',
                                        right: '37px',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <div className="skeleton-user-card__info__top" />
                                    <div className="skeleton-user-card__info__bottom" />
                                </div>
                            </div>
                        </div>
                    ))}

                {clients && clients.length > 0 && (
                    <InfiniteScroll
                        className="w-full"
                        pageStart={0}
                        loadMore={fetchMoreUsers}
                        hasMore={hasMore}
                        initialLoad={false}
                        loader={
                            <div
                                className="w-full flex flex-col"
                                style={{ marginTop: '1rem' }}
                            >
                                <div className="skeleton-user-card">
                                    <div className="skeleton-user-card__avatar" />
                                    <div className="skeleton-user-card__user-info" />
                                    <div className="skeleton-user-card__alerts-circle" />
                                    <div className="skeleton-user-card__line" />

                                    <div className="skeleton-user-card__info">
                                        <div className="skeleton-user-card__info__top" />
                                        <div className="skeleton-user-card__info__bottom" />
                                    </div>
                                    <div
                                        className="skeleton-user-card__info"
                                        style={{ left: '8.5rem' }}
                                    >
                                        <div className="skeleton-user-card__info__top" />
                                        <div className="skeleton-user-card__info__bottom" />
                                    </div>
                                    <div
                                        className="skeleton-user-card__info"
                                        style={{
                                            left: 'auto',
                                            right: '37px',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <div className="skeleton-user-card__info__top" />
                                        <div className="skeleton-user-card__info__bottom" />
                                    </div>
                                </div>
                            </div>
                        }
                        useWindow={false}
                    >
                        {clients.map(
                            (
                                {
                                    id,
                                    alerts,
                                    first_name,
                                    last_name,
                                    photo,
                                    eligibility,
                                    touch_points,
                                    engagement,
                                    eligibility_period,
                                    username,
                                    current_roadmap,
                                    icd_code,
                                },
                                index
                            ) => (
                                <div
                                    className="rpm-provider-record"
                                    key={index}
                                >
                                    <div className="rpm-provider-record-header">
                                        <div className="rpm-provider-record-header-status-container">
                                            <div
                                                className={`rpm-provider-record-header-status with-border ${eligibility &&
                                                    eligibility.toLowerCase()}`}
                                            >
                                                <div
                                                    className={`rpm-provider-record-header-status-dot ${eligibility &&
                                                        eligibility.toLowerCase()}`}
                                                ></div>
                                                <p
                                                    className={`rpm-provider-record-header-status-text ${eligibility &&
                                                        eligibility.toLowerCase()}`}
                                                >
                                                    {(eligibility &&
                                                        t(
                                                            `coach_dashboard.${eligibility.toLowerCase()}`
                                                        )) ||
                                                        t(
                                                            'coach_dashboard.pending'
                                                        )}
                                                </p>
                                            </div>
                                            <div className="rpm-provider-record-header-status">
                                                <div
                                                    className={`rpm-provider-record-header-status-text ${
                                                        eligibility
                                                            ? 'eligible'
                                                            : ''
                                                    }`}
                                                >
                                                    {icd_code ? icd_code : '-'}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="rpm-provider-record-header-user"
                                            onClick={() =>
                                                // use window instead of history
                                                (window.location.href = `/rpm/client-profile/${id}`)
                                            }
                                        >
                                            <div className="rpm-provider-record-header-user-avatar">
                                                <img
                                                    src={
                                                        photo ||
                                                        AvatarBackground
                                                    }
                                                    alt="avatar"
                                                    style={{
                                                        border: photo
                                                            ? '2px solid #c4c4c4'
                                                            : 0,
                                                        borderRadius: photo
                                                            ? '0.35rem 0.35rem 0rem 0.35rem'
                                                            : 0,
                                                    }}
                                                />
                                                {!photo && (
                                                    <p className="rpm-provider-record-header-user-avatar-text">
                                                        {first_name[0]}
                                                        {last_name[0]}
                                                    </p>
                                                )}
                                            </div>
                                            <p className="rpm-provider-record-header-user-text">
                                                {first_name} {last_name}
                                            </p>
                                        </div>

                                        <div className="rpm-alerts-wrapper">
                                            {eligibility ? (
                                                <Alerts
                                                    id={id}
                                                    alerts={alerts}
                                                    onHide={() =>
                                                        refetchClients()
                                                    }
                                                    first={index === 0}
                                                />
                                            ) : (
                                                <p
                                                    className="rpm-alerts-wrapper__resend-invitation"
                                                    role="button"
                                                    onClick={() => {
                                                        sendWelcomeEmail({
                                                            userId: id,
                                                        }).then(() =>
                                                            setShowInvitationConfirmation(
                                                                true
                                                            )
                                                        )
                                                    }}
                                                >
                                                    {t(
                                                        'coach_dashboard.resend_invitation'
                                                    )}
                                                </p>
                                            )}
                                            <button
                                                role="button"
                                                onClick={() => {
                                                    openPeerConversation({
                                                        id,
                                                        username,
                                                        first_name,
                                                        last_name,
                                                        photo,
                                                    })
                                                }}
                                            >
                                                <Icon
                                                    name={'message'}
                                                    size={16}
                                                    color="#6D786E"
                                                />
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        className="rpm-provider-record-body"
                                        style={{
                                            height: current_roadmap
                                                ? '3.8125rem'
                                                : '6.75rem',
                                        }}
                                    >
                                        <div className="rpm-provider-record-body-grid">
                                            <div className="rpm-provider-record-body-left">
                                                {/*   <div className="rpm-provider-record-body-left-item">
                                             <p className="rpm-provider-record-body-left-item-title">
                                                Next Check-In
                                            </p>
                                            <p className="rpm-provider-record-body-left-item-text">
                                                {next_check_in}
                                            </p> 
                                        </div> */}
                                                <div className="rpm-provider-record-body-left-item">
                                                    <p className="rpm-provider-record-body-left-item-title">
                                                        {t(
                                                            'coach_dashboard.touch_points'
                                                        )}
                                                    </p>
                                                    <p className="rpm-provider-record-body-left-item-text">
                                                        {touch_points.point ||
                                                            0}{' '}
                                                        {t(
                                                            'coach_dashboard.of'
                                                        )}{' '}
                                                        {touch_points.of || 0}
                                                    </p>
                                                </div>
                                                <div className="rpm-provider-record-body-left-item">
                                                    <p className="rpm-provider-record-body-left-item-title">
                                                        {t(
                                                            'coach_dashboard.engagement'
                                                        )}
                                                    </p>
                                                    <p
                                                        className="rpm-provider-record-body-left-item-text"
                                                        style={{
                                                            backgroundColor:
                                                                eligibility_period
                                                                    .burn_down_alert_colors
                                                                    .bg_color ||
                                                                '',
                                                            color:
                                                                eligibility_period
                                                                    .burn_down_alert_colors
                                                                    .color ||
                                                                '',
                                                        }}
                                                    >
                                                        {engagement || 0} / 16
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="rpm-provider-record-body-right">
                                                <div className="rpm-provider-record-body-left-item">
                                                    <p className="rpm-provider-record-body-left-item-title">
                                                        {t(
                                                            'coach_dashboard.eligibility_period'
                                                        )}
                                                    </p>
                                                    <p className="rpm-provider-record-body-left-item-text">
                                                        {eligibility_period ? (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        textDecoration:
                                                                            'underline',
                                                                    }}
                                                                >
                                                                    {eligibility_period
                                                                        ? eligibility_period.days_left
                                                                        : 0}{' '}
                                                                    {t(
                                                                        'coach_dashboard.days_left'
                                                                    )}
                                                                </span>{' '}
                                                                {t(
                                                                    'coach_dashboard.ends'
                                                                )}{' '}
                                                                {eligibility_period &&
                                                                    eligibility_period.date_end}
                                                            </>
                                                        ) : (
                                                            t(
                                                                'coach_dashboard.not_available'
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {current_roadmap && (
                                        <div
                                            className="rpm-provider-record-footer"
                                            role="button"
                                            onClick={() => {
                                                history.push(
                                                    `/roadmap/${current_roadmap.id}/?user=${id}`
                                                )
                                            }}
                                        >
                                            <p>
                                                <strong>
                                                    {current_roadmap.title}{' '}
                                                    {' > '}
                                                </strong>
                                                {
                                                    current_roadmap.competency_title
                                                }
                                            </p>

                                            <div className="rpm-provider-record-footer-progress">
                                                <div className="rpm-provider-record-footer-progress-bar">
                                                    <div
                                                        className="rpm-provider-record-footer-progress-bar-fill"
                                                        style={{
                                                            width: `${current_roadmap.progress}%`,
                                                        }}
                                                    />
                                                </div>
                                                <p>
                                                    {current_roadmap.progress}%
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </InfiniteScroll>
                )}
            </div>
        </Fragment>
    )
}
