import React from 'react'

export default function NotesIcon({ size = 10, color = '#000', ...props }) {
    return (
        <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{ transform: `scale(${size / 10})`, ...props.style }}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.09754 5.03077C7.28129 5.21634 7.27981 5.51574 7.09424 5.69949L4.80632 7.96501C4.62246 8.14706 4.32643 8.14754 4.14198 7.96608L3.04065 6.88257C2.85449 6.69942 2.85204 6.40003 3.0352 6.21387C3.21835 6.0277 3.51774 6.02526 3.7039 6.20841L4.47253 6.9646L6.42882 5.02748C6.61439 4.84373 6.91378 4.8452 7.09754 5.03077Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.320068 1.99337C0.320068 1.73222 0.531776 1.52051 0.79293 1.52051H2.50532C2.76647 1.52051 2.97818 1.73222 2.97818 1.99337C2.97818 2.25452 2.76647 2.46623 2.50532 2.46623H1.26579V10.5333H8.71149V2.46623H7.47196C7.21081 2.46623 6.9991 2.25452 6.9991 1.99337C6.9991 1.73222 7.21081 1.52051 7.47196 1.52051H9.18436C9.44551 1.52051 9.65722 1.73222 9.65722 1.99337V11.0062C9.65722 11.2673 9.44551 11.479 9.18436 11.479H0.79293C0.531776 11.479 0.320068 11.2673 0.320068 11.0062V1.99337Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.03247 0.712852C2.03247 0.451698 2.24418 0.23999 2.50533 0.23999H7.47198C7.73313 0.23999 7.94484 0.451698 7.94484 0.712852V3.26683C7.94484 3.52799 7.73313 3.73969 7.47198 3.73969H2.50533C2.24418 3.73969 2.03247 3.52799 2.03247 3.26683V0.712852ZM2.97819 1.18571V2.79397H6.99911V1.18571H2.97819Z"
                fill={color}
            />
        </svg>
    )
}
