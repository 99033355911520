import React, { Fragment } from 'react';
import ResponseInput from './ResponseInput';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import getRelativeDay from '../../../../common/getRelativeDay';
import ResponseCard from './ResponseCard';

export default function ThreadComments({ ...props }) {
  const {
    actionItemResponses,
    centerEdit,
    editRef,
    actionItemParent,
    isMobile,
  } = props;

  const location = useLocation();
  const params = queryString.parse(location.search);

  return (
    <Fragment>
      <div
        className="action-item-responses"
        ref={actionItemParent}

      >
        {actionItemResponses &&
          actionItemResponses
            .filter((response) => response.message !== '')
            .slice(0, -1)
            .reverse()
            .map((item, idx) => {
              let relativeTime = getRelativeDay(item?.created_at);
              return (
                <div
                  style={{
                    // if it the last item, then add a bottom margin
                    marginBottom:
                      idx ===
                        actionItemResponses
                          .filter((response) => response.message !== '')
                          .slice(0, -1).length -
                        1
                        ? '1rem'
                        : '',
                  }}
                >
                  <ResponseCard
                    key={idx}
                    item={item}
                    relativeTime={relativeTime}
                    className="left-border"
                    {...props}
                    {...(centerEdit && { ref: editRef })}
                  />
                  {
                    // if the response is not the last one, show a line
                    idx !==
                    actionItemResponses
                      .filter((response) => response.message !== '')
                      .slice(0, -1)
                      .reverse().length -
                    1 && <hr />
                  }
                </div>
              );
            })}
      </div>
      {!isMobile && <ResponseInput {...props} />}
    </Fragment>
  );
}
