import { useHistory, useLocation } from 'react-router-dom'

import { useUnauthorizedErrorHandler } from '../../common/apiHelpers'
import { useMarkNotificationRead } from '../notifications/redux/hooks'
import { useFetchUpdates } from './redux/hooks'
import { useFetchUser } from '../user/redux/hooks'
import useWindowResize from '../common/useWindowResize'

const coachNotificationVerbs = [
    'NEEDS_APPROVAL',
    'AI_NEEDS_APPROVAL',
    'AI_RESPONSE_UPDATED',
]

const mutualNotificationVerbs = ['NEW_FILE_ATTACHED', 'COMMENTED']

function useFollowNotification() {
    const history = useHistory()
    const location = useLocation()
    const windowSize = useWindowResize()

    const { markNotificationRead } = useMarkNotificationRead()
    const { fetchUpdates } = useFetchUpdates()
    const unauthorizedErrorHandler = useUnauthorizedErrorHandler()
    const { user } = useFetchUser()

    function followNotificationLink(n) {
        let url
        let state = { backLink: location }
        if (n.verb === 'NEW_USER') url = '/manage/accounts'
        else if (n.verb === 'NEW_DIRECT_MESSAGE') url = '/messages/default/'
        else if (n.verb === 'USER_SUBSCRIPTION_INVITATION')
            url = `/subscription-invitation-checkout/${n.target_object_id}/`
        else if (n.verb === 'COACH_ASSIGNED_STUDENT_TO_ROADMAP')
            url = `/roadmap/${n.target.id}/`
        else if (n.target) {
            const isMutualNotification =
                mutualNotificationVerbs.indexOf(n.verb) >= 0
            const competency = isMutualNotification
                ? n.target.competency
                : n.target
            url = `/roadmap/${competency.roadmap_id}/stage/${competency.stage_id}/competency/${competency.id}/`
            const queryParams = []
            if (
                (coachNotificationVerbs.indexOf(n.verb) >= 0 ||
                    (isMutualNotification &&
                        user &&
                        n.target.student_id !== user.id)) &&
                !n.notification_links_to_recipients_roadmap
            ) {
                queryParams.push(`user=${n.sender.id}`)
            }

            if (competency.action_item_id) {
                queryParams.push(`actionItem=${competency.action_item_id}`)
            }

            if (n.verb === 'COMMENTED') {
                queryParams.push('tab=comments')
            }

            if (n.verb === 'AI_RESPONSE_UPDATED') {
                queryParams.push(`actionItemId=${n.action_object_id}`)
            }

            if (queryParams.length > 0) {
                url = `${url}?${queryParams.join('&')}`
            }

            // if queryparams contains actionItemId, then use window.location.href instead of history.push
            if (queryParams.find(p => p.startsWith('actionItemId='))) {
                window.location.href = url
                return
            }
        } else {
            // Fallback: nowhere to go
            if (windowSize.width < 991) {
                url = '/notifications/default'
                state = undefined
            } else {
                url = ''
                state = undefined
            }
        }

        history.push(url, state)
    }

    function followNotification(n) {
        console.log('followNotification', n)
        if (n.read) {
            followNotificationLink(n)
        } else {
            markNotificationRead({ notificationId: n.id })
                .catch(unauthorizedErrorHandler)
                .then(() => {
                    fetchUpdates().catch(unauthorizedErrorHandler)
                    followNotificationLink(n)
                })
        }
    }

    return followNotification
}

export default useFollowNotification
