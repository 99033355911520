import axios from 'axios'
import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'
import config from '../../../common/config'
import {
    DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_BEGIN,
    DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_DISMISS_ERROR,
    DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_FAILURE,
    DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_SUCCESS,
} from './constants'

export function getUpcomingCallsParticipants(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.get(
                config.apiRootUrl + '/upcoming-calls/participants',
                {
                    params: {
                        search: args.search,
                    },

                    ...createAxiosConfigWithAuth(getState()),
                }
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissGetUpcomingCallsParticipantsError() {
    return {
        type: DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_DISMISS_ERROR,
    }
}

export function useGetUpcomingCallsParticipants() {
    const dispatch = useDispatch()

    const {
        getUpcomingCallsParticipantsPending,
        getUpcomingCallsParticipantsError,
        upcomingCallsParticipants,
    } = useSelector(
        state => ({
            upcomingCallsParticipants:
                state.dashboard.upcomingCallsParticipants,
            getUpcomingCallsParticipantsPending:
                state.dashboard.getUpcomingCallsParticipantsPending,
            getUpcomingCallsParticipantsError:
                state.dashboard.getUpcomingCallsParticipantsError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(getUpcomingCallsParticipants(...args))
        },
        [dispatch]
    )

    useEffect(() => {
        if (upcomingCallsParticipants === null) {
            boundAction()
        }
    }, [upcomingCallsParticipants, boundAction])

    const boundDismissError = useCallback(() => {
        return dispatch(dismissGetUpcomingCallsParticipantsError())
    }, [dispatch])

    return {
        upcomingCallsParticipants,
        getUpcomingCallsParticipants: boundAction,
        getUpcomingCallsParticipantsPending,
        getUpcomingCallsParticipantsError,
        dismissGetUpcomingCallsParticipantsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                getUpcomingCallsParticipantsPending: true,
                getUpcomingCallsParticipantsError: null,
            }

        case DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_SUCCESS:
            // The request is success
            return {
                ...state,
                upcomingCallsParticipants: action.data,
                getUpcomingCallsParticipantsPending: false,
                getUpcomingCallsParticipantsError: null,
            }

        case DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_FAILURE:
            // The request is failed
            return {
                ...state,
                getUpcomingCallsParticipantsPending: false,
                getUpcomingCallsParticipantsError: action.data.error,
            }

        case DASHBOARD_GET_UPCOMING_CALLS_PARTICIPANTS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                getUpcomingCallsParticipantsError: null,
            }

        default:
            return state
    }
}
