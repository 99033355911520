import React from 'react'
import { useField } from 'formik'
import Icon from '../../../../common/components/Icon'

const TextInput = ({ label, onChange, style = {}, ...props }) => {
    const [field, meta] = useField(props)
    return (
        <div className="custom-text-input-container" style={style}>
            <div className="support-input-container">
                <Icon
                    name={props.icon}
                    className="custom-icon"
                    size={12}
                    color={props.iconColor || 'var(--primary-dark-green)'}
                />
                <input
                    type={props.type || 'text'}
                    className="support-input"
                    {...field}
                    {...props}
                    onChange={(e) => {
                        field.onChange(e)
                        onChange && onChange(e)
                    }}
                    placeholder={props.placeholder}
                    maxLength={props.maxLength}
                />
            </div>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : props.error ? (
                <div className="error">{props.error}</div>
            ) : null}

            {props.message && <div className="message">{props.message}</div>}
        </div>
    )
}

export default TextInput
