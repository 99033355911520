import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_CREATE_ASSESSMENT_SCORE_BEGIN,
    RPM_CREATE_ASSESSMENT_SCORE_SUCCESS,
    RPM_CREATE_ASSESSMENT_SCORE_FAILURE,
    RPM_CREATE_ASSESSMENT_SCORE_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function createAssessmentScore(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_CREATE_ASSESSMENT_SCORE_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.post(
                `${config.apiRootUrl}/rpm/assessment-score/`,
                args,
                createAxiosConfigWithAuth(getState())
            )

            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_CREATE_ASSESSMENT_SCORE_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_CREATE_ASSESSMENT_SCORE_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissCreateAssessmentScoreError() {
    return {
        type: RPM_CREATE_ASSESSMENT_SCORE_DISMISS_ERROR,
    }
}

export function useCreateAssessmentScore() {
    const dispatch = useDispatch()

    const {
        createAssessmentScorePending,
        createAssessmentScoreError,
    } = useSelector(
        state => ({
            createAssessmentScorePending:
                state.rpm.createAssessmentScorePending,
            createAssessmentScoreError: state.rpm.createAssessmentScoreError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(createAssessmentScore(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissCreateAssessmentScoreError())
    }, [dispatch])

    return {
        createAssessmentScore: boundAction,
        createAssessmentScorePending,
        createAssessmentScoreError,
        dismissCreateAssessmentScoreError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_CREATE_ASSESSMENT_SCORE_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                createAssessmentScorePending: true,
                createAssessmentScoreError: null,
            }

        case RPM_CREATE_ASSESSMENT_SCORE_SUCCESS:
            // The request is success
            return {
                ...state,
                createAssessmentScorePending: false,
                createAssessmentScoreError: null,
            }

        case RPM_CREATE_ASSESSMENT_SCORE_FAILURE:
            // The request is failed
            return {
                ...state,
                createAssessmentScorePending: false,
                createAssessmentScoreError: action.data.error,
            }

        case RPM_CREATE_ASSESSMENT_SCORE_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                createAssessmentScoreError: null,
            }

        default:
            return state
    }
}
