import React, { Fragment, useEffect, useState } from 'react'
import nobleLogo from '../../../../../../src/features/home/images/logo.png'
import AboutYourself from './AboutYourself'
import ConfirmPassword from './ConfirmPassword'
import ContactInformation from './ContactInformation'
// import InsuranceInformation from './InsuranceInformation'
import { useParams } from 'react-router-dom'
import { useFetchAuthToken } from '../../../../home/redux/fetchAuthToken'

export default function Sidebar() {
    const [step, setStep] = useState(0)

    const { stepLabel } = useParams()
    const { authToken } = useFetchAuthToken()

    const steps = {
        'confirm-password': 1,
        'about-yourself': 2,
        'verify-your-address': 3,
/*         'insurance-information': 4,
 */    }

    useEffect(() => {
        if (stepLabel) {
            setStep(steps[stepLabel])
            return;
        }
        setStep(1)
    }, [stepLabel])
    

    return (
        <nav className="sidebar">
            <img src={nobleLogo} alt="Noble Logo" id="logo" />
            {step === 1 && <ConfirmPassword setStep={setStep} />}

            {!!authToken && (
                <Fragment>
                    {step === 2 && <AboutYourself setStep={setStep} />}
                    {step === 3 && <ContactInformation setStep={setStep} />}
{/*                     {step === 4 && <InsuranceInformation setStep={setStep} />}
 */}                </Fragment>
            )}
        </nav>
    )
}
