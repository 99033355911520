import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Icon from '../common/components/Icon'
import Loader from '../common/Loader'
import { useResetPassword } from '../home/redux/resetPassword'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'

export default function ForgotPassword() {
    const { getSettingsByName, settings: data } = useGetSettingsByName()
    const { resetPassword } = useResetPassword()

    const { defaultLanguage } = useSetDefaultLanguage()

    const history = useHistory()
    const [error, setError] = React.useState('')

    const states = {
        writing: 'writing',
        loading: 'loading',
        success: 'success',
    }
    const [loadingState, setLoadingState] = React.useState(states.writing)

    const location = useLocation()

    const { company: name, type } = useParams()

    useEffect(() => {
        getSettingsByName({ name, type })
    }, [name])

    const handleSubmit = async values => {
        setLoadingState(states.loading)
        resetPassword(values)
            .then(() => {
                setLoadingState(states.success)
            })
            .catch(error => {
                if (error.response) {
                    const errorKey = Object.keys(error.response.data)[0]
                    setError(error.response.data[errorKey])
                }
            })
    }

    if (!data) return null

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(
                data.pages[defaultLanguage].forgot_password.input_alerts
                    .invalid_email
            )
            .required(
                data.pages[defaultLanguage].forgot_password.input_alerts
                    .email_required
            ),
    })

    return (
        <div className="auth-forgot-password">
            <div className="auth-forgot-password-navbar">
                <Link
                    to={
                        (location.pathname.split('/').includes('v2')
                            ? `/auth/v2/${name}/${type}`
                            : `/auth/${name}/${type}`) +
                        '?lang=' +
                        defaultLanguage
                    }
                >
                    <img src={data.globals.logo} alt="logo" />
                </Link>
            </div>
            <div className="auth-forgot-password-content">
                {data.pages[defaultLanguage].forgot_password.media.background_video && (
                    <video autoPlay muted loop className="video-background">
                        <source src={data.pages[defaultLanguage].forgot_password.media.background_video} type="video/mp4" />
                    </video>
                )}

                {data.pages[defaultLanguage].forgot_password.media.background_image && (
                    <img
                        src={data.pages[defaultLanguage].forgot_password.media.background_image}
                        alt="background"
                        className="background-image"
                    />
                )}

                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={forgotPasswordSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log(values)
                        handleSubmit(values)
                        setSubmitting(false)
                    }}
                >
                    {props => (
                        <Form>
                            <div className="auth-forgot-password-form">
                                <div className="auth-forgot-password-form-header">
                                    <h1>
                                        {loadingState !== states.success &&
                                            data.pages[defaultLanguage]
                                                .forgot_password.content
                                                .reset_password_title}
                                        {loadingState === states.success &&
                                            data.pages[defaultLanguage]
                                                .forgot_password.content
                                                .success_title}
                                    </h1>
                                    <p>
                                        {loadingState !== states.success &&
                                            data.pages[defaultLanguage]
                                                .forgot_password.content
                                                .reset_password_description}
                                        {loadingState === states.success &&
                                            data.pages[defaultLanguage]
                                                .forgot_password.content
                                                .success_description}
                                    </p>
                                </div>
                                {loadingState !== states.success && (
                                    <div className="auth-forgot-password-form-body">
                                        <div className="auth-forgot-password-form-body-input">
                                            <div className="auth-forgot-password-input-wrapper">
                                                <Icon
                                                    name="email"
                                                    size={18}
                                                    color="#6D786E"
                                                />
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    placeholder={
                                                        data.pages[
                                                            defaultLanguage
                                                        ].forgot_password
                                                            .input_placeholders
                                                            .email + ' *'
                                                    }
                                                />
                                            </div>
                                            {props.errors.email && (
                                                <p className="error-message">
                                                    <ErrorMessage name="email" />
                                                </p>
                                            )}
                                        </div>
                                        {error && (
                                            <p className="error-message">
                                                {error}
                                            </p>
                                        )}
                                        <button
                                            type="submit"
                                            style={{
                                                backgroundColor:
                                                    data.globals.colors.primary,
                                            }}
                                        >
                                            {loadingState === states.writing &&
                                                data.pages[defaultLanguage].forgot_password.content.reset_password_button}
                                            {loadingState ===
                                                states.loading && (
                                                <Loader
                                                    position={'static'}
                                                    animation="border"
                                                />
                                            )}
                                        </button>
                                        {loadingState !== states.success && (
                                            <Link
                                                to={
                                                    (location.pathname
                                                        .split('/')
                                                        .includes('v2')
                                                        ? `/auth/v2/${name}/login/${type}`
                                                        : `/auth/${name}/login/${type}`) +
                                                    '?lang=' +
                                                    defaultLanguage
                                                }
                                            >
                                                <p className="i-remember">
                                                    {
                                                        data.pages[
                                                            defaultLanguage
                                                        ].forgot_password
                                                            .content
                                                            .remember_now
                                                    }
                                                </p>
                                            </Link>
                                        )}
                                    </div>
                                )}
                                {loadingState === states.success && (
                                    <button
                                        className="success"
                                        style={{
                                            backgroundColor:
                                                data.globals.colors.primary,
                                        }}
                                        onClick={() =>
                                            history.push(
                                                (location.pathname
                                                    .split('/')
                                                    .includes('v2')
                                                    ? `/auth/v2/${name}/login/${type}`
                                                    : `/auth/${name}/login/${type}`) +
                                                    '?lang=' +
                                                    defaultLanguage
                                            )
                                        }
                                    >
                                        {
                                            data.pages[defaultLanguage]
                                                .forgot_password.content
                                                .go_to_login_button
                                        }
                                    </button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

ForgotPassword.propTypes = {}
ForgotPassword.defaultProps = {}
