import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'
import Slider from './Slider'
import backgroundImage from '../../../assets/signup/girl-on-computer.png';

export default function DesktopSignup() {
    const location = useLocation()

    const { p } = qs.parse(location.search.replace('?', ''))
    let params = {}
    try {
        params = JSON.parse(atob(p))
    } catch {}

    const { user } = params

    return (
        <div className="rpm-signup-desktop">
            <Sidebar />
            {user && !user.groups.includes('Coach') ? <Slider /> : <img src={backgroundImage} alt="background" className='background-image' />}
        </div>
    )
}
