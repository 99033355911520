import React from 'react';

export default function SubscriptionIcon({ size, color }) {
  return (
    <svg
      width={size * 1.09}
      height={size}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_506_485)">
        <path
          d="M14.4499 13.9768C14.052 13.7502 13.639 13.5513 13.2136 13.3814C14.5711 12.585 15.6296 11.3668 16.2271 9.91321C16.8246 8.45963 16.9282 6.85078 16.522 5.33289C16.1158 3.815 15.2223 2.47169 13.9781 1.50856C12.7339 0.545437 11.2076 0.0155496 9.63281 0C8.06246 0.0206378 6.54188 0.552612 5.30258 1.51493C4.06328 2.47725 3.17314 3.8172 2.76768 5.33078C2.36222 6.84436 2.46366 8.44865 3.05655 9.89941C3.64944 11.3502 4.7013 12.5679 6.05199 13.3673C5.01931 13.7784 4.05973 14.3528 3.21007 15.0683C1.43436 16.5911 0.289728 18.7182 -0.00129496 21.0361C-0.0130377 21.1561 9.85269e-05 21.2772 0.0372969 21.3919C0.0744952 21.5067 0.13496 21.6125 0.214929 21.7029C0.294898 21.7934 0.392661 21.8664 0.502137 21.9175C0.611612 21.9686 0.730458 21.9967 0.851281 22H18.4143C18.4509 22.0142 18.4915 22.0142 18.528 22C18.6628 21.4745 18.6628 20.9237 18.528 20.3982C18.528 20.299 18.3007 20.3131 18.0591 20.3131C17.306 20.3131 15.9703 20.3131 15.9703 20.3131H1.88858C2.28573 18.7835 3.13779 17.4097 4.33263 16.3724C5.59627 15.2803 7.16724 14.6043 8.83069 14.4369C10.4941 14.2694 12.1688 14.6187 13.6257 15.4369C13.6257 15.4369 14.7056 14.2603 14.6204 14.0193L14.4499 13.9768ZM4.21896 7.20103C4.23576 6.13664 4.5675 5.10094 5.17251 4.22405C5.77751 3.34716 6.6288 2.66818 7.61941 2.27242C8.61003 1.87666 9.69579 1.78176 10.7403 1.99966C11.7848 2.21756 12.7415 2.73852 13.4901 3.49711C14.2387 4.2557 14.746 5.21807 14.948 6.26333C15.1501 7.30859 15.038 8.39012 14.6259 9.37203C14.2137 10.354 13.5199 11.1925 12.6315 11.7822C11.7432 12.372 10.6999 12.6867 9.63281 12.6869C8.91516 12.685 8.205 12.5413 7.54341 12.2639C6.88182 11.9865 6.28194 11.581 5.77847 11.0708C5.27499 10.5607 4.87793 9.95598 4.61024 9.29173C4.34255 8.62749 4.20956 7.91688 4.21896 7.20103Z"
          fill={color}
        />
        <path
          d="M19.5239 11C18.6387 11 17.7733 11.2619 17.0372 11.7525C16.3011 12.2432 15.7274 12.9405 15.3886 13.7564C15.0499 14.5724 14.9612 15.4702 15.1339 16.3363C15.3066 17.2025 15.7329 17.9981 16.3589 18.6226C16.9849 19.2471 17.7824 19.6723 18.6507 19.8446C19.519 20.0169 20.419 19.9285 21.2368 19.5905C22.0547 19.2526 22.7538 18.6802 23.2456 17.9459C23.7374 17.2116 24 16.3483 24 15.4652C24 14.281 23.5284 13.1452 22.689 12.3078C21.8495 11.4704 20.7111 11 19.5239 11ZM19.5239 18.924C18.8382 18.924 18.1679 18.7211 17.5977 18.3411C17.0275 17.961 16.5831 17.4208 16.3207 16.7888C16.0583 16.1568 15.9896 15.4614 16.1234 14.7904C16.2572 14.1195 16.5874 13.5032 17.0723 13.0195C17.5572 12.5358 18.175 12.2064 18.8475 12.0729C19.5201 11.9394 20.2172 12.0079 20.8508 12.2697C21.4843 12.5315 22.0258 12.9748 22.4068 13.5436C22.7877 14.1124 22.9911 14.7811 22.9911 15.4652C22.9873 16.3814 22.6208 17.259 21.9714 17.9068C21.322 18.5546 20.4423 18.9202 19.5239 18.924Z"
          fill={color}
        />
        <path
          d="M22.1528 14.0052C22.0871 13.9444 22.0008 13.9106 21.9112 13.9106C21.8216 13.9106 21.7353 13.9444 21.6696 14.0052L18.8277 16.8402L17.5631 15.5786C17.5314 15.5469 17.4937 15.5218 17.4523 15.5046C17.4108 15.4874 17.3664 15.4786 17.3215 15.4786C17.2766 15.4786 17.2322 15.4874 17.1907 15.5046C17.1493 15.5218 17.1116 15.5469 17.0799 15.5786C17.0326 15.6422 17.0071 15.7192 17.0071 15.7984C17.0071 15.8775 17.0326 15.9545 17.0799 16.0181L18.5862 17.5207C18.6179 17.5522 18.6556 17.5772 18.697 17.5943C18.7385 17.6113 18.7829 17.62 18.8277 17.6199C18.9171 17.6149 19.0023 17.5799 19.0693 17.5207L22.1528 14.4871C22.1846 14.4555 22.2098 14.418 22.227 14.3766C22.2442 14.3353 22.2531 14.2909 22.2531 14.2462C22.2531 14.2014 22.2442 14.1571 22.227 14.1157C22.2098 14.0744 22.1846 14.0368 22.1528 14.0052Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
