import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowSize from '../../common/useWindowResize';
import { useFetchAuthToken } from '../../home/redux/fetchAuthToken';
import CaregiverForm from './caregiverModal/CaregiverForm';
import ClientForm from './caregiverModal/ClientForm';
import TabSelector from './caregiverModal/TabSelector';
import SuccessModal from './SuccessModal';

export default function CaregiverModal({ data, show, toggleModal }) {
  let isMobile = useWindowSize().width < 768;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      document.querySelector('.modal-dialog').style.margin = 0;
      document.querySelector('.modal-content').style['min-height'] = '100vh';
      document.querySelector('.modal-content').style['border-radius'] = '0';
    }
  }, [show]);

  const { authToken } = useFetchAuthToken();

  const [selectedTab, setSelectedTab] = React.useState('left');
  const [showSuccess, setShowSuccess] = React.useState(false);

  if (showSuccess)
    return (
      <SuccessModal
        show={showSuccess}
        toggleModal={toggleModal}
        setShowSuccess={setShowSuccess}
      />
    );

  return (
    <Modal className="caregiver-modal" show={show} onHide={toggleModal}>
      <h1 className="title">Create an Account</h1>
      <p className="description">
        Our coaches can help you navigate the road ahead.
      </p>
      <TabSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === 'left' && <ClientForm data={data} />}

      {selectedTab === 'right' &&
        (authToken ? (
          <CaregiverForm data={data} setShowSuccess={setShowSuccess} />
        ) : (
          <ClientForm data={data} caregiver />
        ))}
    </Modal>
  );
}
