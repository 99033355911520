import React from 'react';

export default function LineIcon({ size, color = '#6E7E6D' }) {
  return (
    <svg
      width={size * 5.5}
      height={size}
      viewBox="0 0 22 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2"
        y1="2"
        x2="20"
        y2="2"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}
