import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
// import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Toast from 'react-bootstrap/Toast'

import Header from '../common/Header.js'
import { prepareErrorMessage } from '../common/uiHelpers'

import Icon from '../common/components/Icon/index.js'
import ForgotPasswordIllustration from './images/forgot-password.svg'
import { useResetPassword } from './redux/hooks'
import Loader from '../common/Loader.js'

function ForgotPasswordForm({ isLoading, validated, handleSubmit, submitted }) {
    return (
        <div className="reset-password-form">
            {isLoading && (
                <div className="loading-overlay">
                    <Loader animation="border" size="lg" position={'static'} />
                </div>
            )}
            <img src={ForgotPasswordIllustration} alt="forgot password" />
            {!submitted && <h2>Don't worry.</h2>}
            {!!submitted && <h2>Check your email</h2>}
            {!submitted && (
                <p className="reset-instructions">
                    We'll help you recover your password. <br />
                    Enter the email address you used to sign up and we'll email
                    you instructions to reset your password.
                </p>
            )}
            {!!submitted && (
                <p className="reset-instructions">
                    If we found a matching user you’ll receive an email with
                    instructions to reset your password.
                </p>
            )}

            {!!submitted && (
                <div className="help-instructions">
                    <h3>Didn't receive the email? Try the following:</h3>

                    <ul>
                        {[
                            'Wait a few minutes for the email to arrive.',
                            'Confirm the email address used to reset your password is the one used to signup.',
                            'Check your spam folder.',
                            'Contact Member Services for further assistance.',
                        ].map((item, index) => (
                            <li key={index}>
                                <span className="icon">+</span>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {!submitted && (
                    <Form.Group controlId="formEmail">
                        <Form.Control
                            required
                            type="email"
                            placeholder="Email"
                        />
                    </Form.Group>
                )}
                {!submitted && (
                    <button
                        className="send-instructions"
                        type="submit"
                        disabled={isLoading}
                    >
                        Send Instructions
                    </button>
                )}

                {!!submitted && (
                    <Link to="/log-in">
                        <button className="send-instructions" type="button">
                            Back to Log In
                        </button>
                    </Link>
                )}
            </Form>
        </div>
    )
}

export default function ForgotPasswordPage() {
    const [validated, setValidated] = useState(false)
    const {
        resetPassword,
        resetPasswordPending,
        resetPasswordError,
        dismissResetPasswordError,
    } = useResetPassword()
    const history = useHistory()

    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = event => {
        const form = event.currentTarget

        event.preventDefault()
        event.stopPropagation()

        if (form.checkValidity()) {
            resetPassword({
                email: form.elements.formEmail.value,
            })
                .then(() => {
                    setSubmitted(true)
                })
                .catch(() => {
                    console.warn('Error sending email to reset password')
                })
        }

        setValidated(true)
    }

    const errorMessage = resetPasswordError
        ? prepareErrorMessage(resetPasswordError)
        : undefined

    return (
        <div className="home-forgot-password-page">
            <p className="back-button-container-mobile d-md-none">
                <Icon name="chevronLeft" size={10} />
                <Link to="log-in">
                    <button>Back to Log In</button>
                </Link>
            </p>
            <Container className="entry">
                <Row className="justify-content-center">
                    <Col xs={12} className="d-lg-none">
                        <ForgotPasswordForm
                            isLoading={resetPasswordPending}
                            handleSubmit={handleSubmit}
                            validated={validated}
                            submitted={submitted}
                        />
                    </Col>
                    <div className="desktop-wrapper">
                        <p className="back-button-container">
                            <Link to="log-in">
                                <button>
                                    <Icon name="chevronLeft" size={10} />
                                    Back to Log In
                                </button>
                            </Link>
                        </p>
                        <div className="d-none d-lg-block">
                            <ForgotPasswordForm
                                isLoading={resetPasswordPending}
                                handleSubmit={handleSubmit}
                                validated={validated}
                                submitted={submitted}
                            />
                        </div>
                    </div>
                    <Col xs={12}>
                        <Toast
                            onClose={() => dismissResetPasswordError()}
                            show={!!errorMessage}
                            delay={3000}
                            autohide
                            className="mx-auto"
                        >
                            <Toast.Header>
                                <strong className="mr-auto text-danger">
                                    Error
                                </strong>
                            </Toast.Header>
                            <Toast.Body>{errorMessage}</Toast.Body>
                        </Toast>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

ForgotPasswordPage.propTypes = {}
ForgotPasswordPage.defaultProps = {}
