import React from 'react';

export default function InfoIcon({ size = 20, color = '#6D786E', style = {} }) {
  return (
    <svg
      width={size}
      height={size * 1.1}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <path
        d="M9.50524 0C7.62528 0 5.78754 0.544657 4.22442 1.5651C2.66129 2.58553 1.44298 4.03592 0.723547 5.73285C0.00411837 7.42978 -0.184117 9.29703 0.182645 11.0985C0.549407 12.8999 1.45469 14.5547 2.78402 15.8534C4.11336 17.1522 5.80703 18.0367 7.65086 18.395C9.4947 18.7533 11.4059 18.5694 13.1427 17.8665C14.8796 17.1637 16.3641 15.9733 17.4086 14.4462C18.453 12.919 19.0105 11.1235 19.0105 9.28673C19.0073 6.8247 18.0048 4.4644 16.2229 2.72348C14.441 0.982561 12.0252 0.00313435 9.50524 0ZM9.50524 17.152C7.91303 17.152 6.35658 16.6907 5.0327 15.8265C3.70883 14.9622 2.677 13.7338 2.06768 12.2966C1.45837 10.8594 1.29895 9.278 1.60957 7.75228C1.9202 6.22657 2.68692 4.82511 3.81278 3.72513C4.93864 2.62515 6.37308 1.87605 7.9347 1.57257C9.49631 1.26908 11.115 1.42484 12.586 2.02015C14.057 2.61545 15.3143 3.62357 16.1989 4.91701C17.0835 6.21045 17.5556 7.73112 17.5556 9.28673C17.5524 11.3718 16.7032 13.3705 15.1942 14.8449C13.6851 16.3192 11.6393 17.1489 9.50524 17.152Z"
        fill={color}
      />
      <path
        d="M9.79829 7.22426C9.70151 7.2065 9.6019 7.2065 9.50513 7.22426C9.3023 7.22426 9.10778 7.29872 8.96435 7.43124C8.82093 7.56376 8.74036 7.7435 8.74036 7.93091V13.7607C8.7414 13.8996 8.78669 14.035 8.87056 14.1502C8.95444 14.2654 9.07318 14.3553 9.21197 14.4085C9.30874 14.4263 9.40835 14.4263 9.50513 14.4085C9.70796 14.4085 9.90248 14.334 10.0459 14.2015C10.1893 14.069 10.2699 13.8893 10.2699 13.7018V7.8838C10.2714 7.74296 10.2273 7.60492 10.1432 7.4874C10.0592 7.36988 9.93908 7.27825 9.79829 7.22426Z"
        fill={color}
      />
      <path
        d="M9.50527 6.33598C10.0483 6.33598 10.4886 5.94466 10.4886 5.46194C10.4886 4.97921 10.0483 4.58789 9.50527 4.58789C8.96221 4.58789 8.52197 4.97921 8.52197 5.46194C8.52197 5.94466 8.96221 6.33598 9.50527 6.33598Z"
        fill={color}
      />
    </svg>
  );
}
