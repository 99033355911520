import React from 'react';
import GetItOnStores from '../../assets/fitbit-connection/get-it-on-stores.svg';
import NobleAppLogo from '../../assets/fitbit-connection/noble-app-logo.svg';
import WatchAndLogo from '../../assets/fitbit-connection/watch-and-logo.png';
import FitbitAppLogo from '../../assets/fitbit-connection/app-logo-fitbit.png';

export default function Step0({ currentStep, setCurrentStep }) {
  return (
    <div
      className="step"
      style={{
        opacity: currentStep === 0 ? 1 : 0,
        transition: 'all ease-in-out 0.5s',
      }}
    >
      <h1 className="title">Getting Started is Easy</h1>
      <img src={FitbitAppLogo} alt="Fitbit Logo" className="logo" />
      <p className="description">Download the Fitbit App</p>
      <img src={GetItOnStores} alt="Get it on stores" className="stores" />
      <img src={WatchAndLogo} alt="Watch and logo" className="watch" />
      <p className="description">Pair your Fibit with the Fitbit App</p>
      <img src={NobleAppLogo} alt="Noble Logo" className="logo" />
      <p className="description">Return to Noble and resume connecting</p>
      <button
        className="done-button"
        onClick={() => setCurrentStep((step) => step + 1)}
        type="button"
      >
        I've done these steps
      </button>
    </div>
  );
}
