{
  /** This component is the mobile version of the sidebar */
}

import React from 'react';
import Device from './records/Device';
import Footer from './records/Footer';
import HealthDataHeader from './records/HealthDataHeader';
import StatsHeader from './records/StatsHeader';
import Records from './records/Records';

export default function MobileRecords() {
  return (
    <div className="mobile-records">
      <div className="mobile-records-header">
        <StatsHeader />
        <Device />
        <Footer />
      </div>
      <HealthDataHeader />
      <div className="health-data-records-container">
        <Records />
      </div>
    </div>
  );
}
