import React from 'react'

export default function ThreeDotIcon({ size, color = '#343434', style }) {
    return (
        <svg
            width={size}
            height={size * 4}
            style={{ zIndex: 1 }}
            viewBox="0 0 4 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="2"
                cy="2"
                r="2"
                transform="rotate(90 2 2)"
                fill={color}
            />
            <circle
                cx="2"
                cy="8"
                r="2"
                transform="rotate(90 2 8)"
                fill={color}
            />
            <circle
                cx="2"
                cy="14"
                r="2"
                transform="rotate(90 2 14)"
                fill={color}
            />
        </svg>
    )
}
