export const calls = {
    add_new_call: 'Agregar nueva llamada',
    edit_call: 'Editar llamada',
    personal: 'Personal',
    third_party: 'Terceros',
    call_name: 'Nombre de la llamada',
    date_time: 'Fecha/Hora',
    participants: 'Participantes',
    select_participants: 'Seleccionar participantes',
    meeting_link: 'Enlace de la reunión',
    using_upheal_link: 'Usando el enlace de Upheal',
    use_upheal_link: 'Usar enlace de Upheal',
    recurs_weekly: 'Repetir semanalmente',
    edit: 'Editar',
    save: 'Guardar',
    delete_call: 'Eliminar llamada',
    are_you_sure_you_want_to_delete_this_call: '¿Estás seguro de que quieres eliminar esta llamada?',
    delete_call: 'Eliminar llamada',
    common: {
        cancel: 'Cancelar',
    }
}
