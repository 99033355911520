import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Loader } from '../../common';
import { useCreateInternalProductSubscription } from '../redux/createInternalProductSubscription';
import { useCreatePriceSubscription } from '../../home/redux/createPriceSubscription';

export default function InternalProductModal({ showModal, setShowModal }) {
  const { createInternalProductSubscription } =
    useCreateInternalProductSubscription();
  const { createPriceSubscription } = useCreatePriceSubscription();

  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [subscription, setSubscription] = React.useState('');
  const [price, setPrice] = React.useState('');

  const isValidated = () => {
    if (subscription === '') {
      return false;
    }
    if (price === '') {
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setErrors([]);
    setShowError(false);
    setShowModal(false);
  };

  const handleCreateSubscriptionSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    setShowError(false);

    if (!isValidated()) {
      setErrors(['Please fill in the Name and Monthly Price fields']);
      setShowError(true);
      setLoading(false);
      return;
    }

    createInternalProductSubscription({
      name: subscription,
    })
      .then((res) => {
        createPriceSubscription({
          product: res.data.id,
          unit_amount: Number(price * 100).toFixed(0) * 1,
        })
          .then((res) => {
            if (res.status === 201) {
              setLoading(false);
              setShowError(false);
              setShowModal(false);
              window.location.reload(false);
            }
            console.error('Error response', res);
          })
          .catch((err) => {
            console.error('Error response', err);
          });
      })
      .catch((err) => {
        console.error('Error response', err.response);
      });
  };

  const handleProductPrice = (e) => {
    setPrice(e.target.value);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      className="subscriptions-internal-subscriptions-modal"
    >
      <Modal.Header>
        <Button variant="secondary" onClick={() => handleClose()}>
          Cancel
        </Button>
        <h2>Add Internal Subscriptions</h2>
        <Button
          variant="primary"
          type="submit"
          onClick={handleCreateSubscriptionSubmit}
        >
          Save
        </Button>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader position="static" />}
        {showError && <p className="error-message">{errors}</p>}
        <form
          id="create-product-form"
          onSubmit={handleCreateSubscriptionSubmit}
        >
          <label>Subscription Name</label>
          <input
            placeholder="Basic"
            id="subscription"
            name="subscription"
            type="text"
            value={subscription}
            onChange={(e) => {
              setSubscription(e.target.value);
            }}
          />
          <label>Monthly Price</label>
          <input
            placeholder="$0.00"
            id="price"
            name="price"
            type="number"
            value={price}
            onChange={handleProductPrice}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
}
