import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_FETCH_ASSESSMENTS_BEGIN,
    RPM_FETCH_ASSESSMENTS_SUCCESS,
    RPM_FETCH_ASSESSMENTS_FAILURE,
    RPM_FETCH_ASSESSMENTS_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function fetchAssessments(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_FETCH_ASSESSMENTS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.get(
                `${config.apiRootUrl}/rpm/assessments/`,
                {
                    params: args,
                    ...createAxiosConfigWithAuth(getState()),
                }
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_FETCH_ASSESSMENTS_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_FETCH_ASSESSMENTS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissFetchAssessmentsError() {
    return {
        type: RPM_FETCH_ASSESSMENTS_DISMISS_ERROR,
    }
}

export function useFetchAssessments() {
    const dispatch = useDispatch()

    const {
        fetchAssessmentsPending,
        fetchAssessmentsError,
        assessments,
    } = useSelector(
        state => ({
            assessments: state.rpm.assessments,
            fetchAssessmentsPending: state.rpm.fetchAssessmentsPending,
            fetchAssessmentsError: state.rpm.fetchAssessmentsError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(fetchAssessments(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissFetchAssessmentsError())
    }, [dispatch])

    return {
        assessments,
        fetchAssessments: boundAction,
        fetchAssessmentsPending,
        fetchAssessmentsError,
        dismissFetchAssessmentsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_FETCH_ASSESSMENTS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                fetchAssessmentsPending: true,
                fetchAssessmentsError: null,
            }

        case RPM_FETCH_ASSESSMENTS_SUCCESS:
            // The request is success
            return {
                ...state,
                assessments: action.data.results,
                fetchAssessmentsPending: false,
                fetchAssessmentsError: null,
            }

        case RPM_FETCH_ASSESSMENTS_FAILURE:
            // The request is failed
            return {
                ...state,
                fetchAssessmentsPending: false,
                fetchAssessmentsError: action.data.error,
            }

        case RPM_FETCH_ASSESSMENTS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                fetchAssessmentsError: null,
            }

        default:
            return state
    }
}
