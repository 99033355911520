import React from 'react';

export default function CheckboxIcon({
  size = 13,
  color = '#9DA16B',
  style = {},
  active = false,
}) {
  if (active)
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="13" height="13" rx="3" fill={color} />
        <path
          d="M10.8236 4.15622C10.7125 4.05604 10.5637 4 10.4088 4C10.2539 4 10.1052 4.05604 9.99405 4.15622L5.11439 8.68626L2.95759 6.68398C2.84478 6.59782 2.70135 6.55391 2.55545 6.56088C2.40956 6.56786 2.27175 6.6252 2.16906 6.72167C2.06638 6.81813 2.00624 6.94674 2.00046 7.08225C1.99468 7.21776 2.04366 7.35039 2.13781 7.45409L4.7045 9.84595C4.75852 9.89544 4.82254 9.93452 4.89289 9.96095C4.96323 9.98738 5.03851 10.0006 5.11439 9.99998C5.27025 9.99987 5.42025 9.94482 5.53404 9.84595L10.8236 4.92632C10.8794 4.87645 10.9238 4.81665 10.9541 4.75049C10.9844 4.68433 11 4.61317 11 4.54127C11 4.46937 10.9844 4.39821 10.9541 4.33205C10.9238 4.26589 10.8794 4.20609 10.8236 4.15622V4.15622Z"
          fill="white"
        />
      </svg>
    );

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="12" height="12" rx="2.5" stroke="#C4C4C4" />
      <rect x="0.5" y="0.5" width="12" height="12" rx="2.5" stroke="#C4C4C4" />
      <rect x="0.5" y="0.5" width="12" height="12" rx="2.5" stroke="#C4C4C4" />
    </svg>
  );
}
