// InvitationModal.js Fields

import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

export const useFields = () => {
  const { t } = useTranslation();

  const fields = [
    { type: 'string', label: 'First Name', name: 'first_name' },
    { type: 'string', label: 'Last Name', name: 'last_name' },
    { type: 'string', label: 'Email', name: 'email' },
  ];

  return {
    fields: fields.map((field) => ({
      ...field,
      label: t(`dashboard.onboarding.first_step.${field.name}`),
    })),
  };
};
