import dayjs from 'dayjs'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { CurrentUserIsCoachOrAdmin } from '../../common/roles'
import { Loader } from '../common'
import Icon from '../common/components/Icon'
import Card from '../support-group/components/Card'
import { useTranslation } from '../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../user/redux/fetchUser'
import {
    useFetchCoachUpcomingCalls,
    useFetchUserUpcomingCalls,
} from './redux/hooks'
import AddCallModal from './UpcomingCalls/AddCallModal'
import EditCallModal from './UpcomingCalls/EditCallModal'

import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)

function AdminPageCallHeader({ coachCalls, fetchCoachCalls, userCalls }) {
    const currentUserIsCoachOrAdmin = CurrentUserIsCoachOrAdmin()

    const [showAddCallModal, setShowAddCallModal] = useState(false)

    const handleAddCallModalHide = useCallback(() => {
        setShowAddCallModal(false)
        fetchCoachCalls()
    }, [fetchCoachCalls])

    const { t } = useTranslation()

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <h2 className="upcoming-calls-title theme-text-normal mrm-mb-1 mrm-mt-1">
                    {t('dashboard.upcoming_calls.title')}
                    {coachCalls && coachCalls.count > 0 && (
                        <span className="text-muted">({coachCalls.count})</span>
                    )}
                    {userCalls && userCalls.count > 0 && (
                        <span className="text-muted">({userCalls.count})</span>
                    )}
                </h2>

                {currentUserIsCoachOrAdmin && (
                    <button
                        className="text-muted"
                        onClick={() => setShowAddCallModal(true)}
                    >
                        <div className="coach-add-calls-btn plus-btn">
                            <Icon name="plus" />
                        </div>
                    </button>
                )}
            </div>
            <AddCallModal
                show={showAddCallModal}
                onHide={handleAddCallModalHide}
            />
        </div>
    )
}

export default function CoachUpcommingCalls() {
    const { user } = useFetchUser()

    const { userCalls, fetchUserUpcomingCalls } = useFetchUserUpcomingCalls()

    const { coachCalls, fetchCoachCalls } = useFetchCoachUpcomingCalls()

    const userGroups = user ? user.groups : []

    const isCoach =
        (user ? !!user.features.coaches_can_add_calls : false) &&
        userGroups.includes('Coach')

    useEffect(() => {
        isCoach ? fetchCoachCalls() : fetchUserUpcomingCalls()
    }, [isCoach, fetchUserUpcomingCalls, fetchCoachCalls])

    useLayoutEffect(() => {
        // make all cards height equal
        const dropInCards = document.querySelectorAll('.drop-in-card')
        let maxHeight = 0
        dropInCards.forEach(card => {
            const height = card.clientHeight
            if (height > maxHeight) {
                maxHeight = height
            }
        })
        dropInCards.forEach(card => {
            card.style['min-height'] = `${maxHeight}px`
        })
    }, [userCalls && userCalls.count, coachCalls && coachCalls.count])

    const [showAll, setshowAll] = useState(4)

    useEffect(() => {
        fetchCoachCalls()
    }, [fetchCoachCalls])

    return (
        <div className="dashboard-coach-add-calls">
            <AdminPageCallHeader
                coachCalls={coachCalls}
                handleViewAll={setshowAll}
                fetchCoachCalls={fetchCoachCalls}
                userCalls={userCalls}
            />
            <Container className="d-contents">
                <Col>
                    {user && (
                        <CallContent
                            coachCalls={coachCalls}
                            userCalls={userCalls}
                            fetchCoachCalls={fetchCoachCalls}
                            showAll={showAll}
                            user={user}
                        />
                    )}
                </Col>
            </Container>
        </div>
    )
}

const CallContent = ({ coachCalls, userCalls, fetchCoachCalls, user }) => {
    const [callId, setCallId] = useState(null)

    const currentUserIsCoachOrAdmin = CurrentUserIsCoachOrAdmin()

    const [showEditCallModal, setShowEditCallModal] = useState(false)

    const [deleteCall, setDeleteCall] = useState(false)

    const handleEditCallModalHide = useCallback(() => {
        setShowEditCallModal(false)
        setDeleteCall(null)

        fetchCoachCalls()
    }, [fetchCoachCalls])

    const { t } = useTranslation()

    useEffect(() => {
        document.querySelectorAll('.modal').forEach(modal => {
            if (modal.classList.contains('show')) {
                modal.classList.remove('show')
            } else {
                modal.classList.add('show')
            }
        })
    }, [])

    if (!user || (!coachCalls && !userCalls)) return <Loader />

    if (coachCalls) {
        if (coachCalls.results.length === 0) {
            return (
                <div className="no-data">
                    <div className="phone-container">
                        <Icon name="phone" size={13} color={'#C4C4C4'} />
                    </div>
                    <p>{t('dashboard.upcoming_calls.no_calls')}</p>
                </div>
            )
        } else {
            const lastCoachCalls = coachCalls.results
                .sort(function(x, y) {
                    return x.meeting_timestamp - y.meeting_timestamp
                })
                .map(call => {
                    return { ...call, name: call.call_name }
                })

            return (
                <div className="group-calls-list">
                    {lastCoachCalls.map(call => (
                        <div>
                            <Card
                                key={call.id}
                                call={call}
                                onEdit={() => {
                                    setCallId(call.id)
                                    setShowEditCallModal(true)
                                }}
                                onDelete={() => {
                                    setCallId(call.id)
                                    setDeleteCall(call.id)
                                    setShowEditCallModal(true)
                                }}
                                canEdit={currentUserIsCoachOrAdmin}
                                icon="phone"
                            />
                        </div>
                    ))}
                    {coachCalls && coachCalls.results && (
                        <EditCallModal
                            callId={callId}
                            show={showEditCallModal}
                            onHide={handleEditCallModalHide}
                            deleteCall={deleteCall}
                            coachCalls={coachCalls.results}
                        />
                    )}
                </div>
            )
        }
    }
    if (userCalls) {
        if (userCalls.count === 0) {
            return (
                <div className="no-data">
                    <div className="phone-container">
                        <Icon name="phone" size={13} color={'#C4C4C4'} />
                    </div>
                    <p>{t('dashboard.upcoming_calls.no_calls')}.</p>
                </div>
            )
        } else {
            const lastUserCalls = userCalls.results
                .sort(function(x, y) {
                    return x.meeting_timestamp - y.meeting_timestamp
                })
                .map(call => {
                    return { ...call, name: call.call_name }
                })

            console.log(lastUserCalls, 'lastUserCalls')

            return (
                <div className="group-calls-list">
                    {lastUserCalls.map((call, idx) => (
                        <div key={idx}>
                            <Card
                                key={call.id}
                                call={call}
                                onEdit={() => {
                                    setCallId(call.id)
                                    setShowEditCallModal(true)
                                }}
                                onDelete={() => {
                                    setCallId(call.id)
                                    setDeleteCall(call.id)
                                    setShowEditCallModal(true)
                                }}
                                canEdit={currentUserIsCoachOrAdmin}
                                icon="phone"
                            />
                            {coachCalls && coachCalls.results && (
                                <EditCallModal
                                    callId={callId}
                                    show={showEditCallModal}
                                    onHide={handleEditCallModalHide}
                                    deleteCall={deleteCall}
                                    coachCalls={coachCalls.results}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )
        }
    }
}

CoachUpcommingCalls.propTypes = {}
CoachUpcommingCalls.defaultProps = {}
