import React, { useContext } from 'react';
import { useWindowSize } from '../../../../common/windowsSize';
import Icon from '../../../common/components/Icon';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';
import { InvitationContext } from '../InvitationContext';
export default function Step3({ body }) {
  const {
    subscriptionsList,
    setSubSelected,
    subSelected,
    handleCreateProductSubmit,
    subscription,
    setSubscription,
    price,
    setAddSubscriptionForm,
    setStep,
    step,
    addSubscriptionForm,
    setPrice,
  } = useContext(InvitationContext);
  const size = useWindowSize();

  const handleProductPrice = (e) => {
    setPrice(e.target.value);
  };

  const { t } = useTranslation();

  if (body) {
    return (
      <>
        <div
          className={`invitation-subs-grid ${
            step === 3 && addSubscriptionForm === false && subscriptionsList
              ? ' '
              : 'd-none'
          }`}
        >
          {subscriptionsList &&
            subscriptionsList.map((s) => (
              <div
                className="invitation-sub"
                key={s.id}
                onClick={() => setSubSelected(s)}
              >
                <div className="select">
                  <button type="button">
                    <Icon
                      name="circlecheck"
                      active={s.id === subSelected?.id ? true : false}
                    />
                  </button>
                  <h3>{s.name}</h3>
                </div>
                {s.price ? (
                  <p>
                    ${s.price.unit_amount / 100} /{' '}
                    {t('dashboard.onboarding.third_step.month_abbr')}
                  </p>
                ) : (
                  <p>{t('dashboard.onboarding.third_step.no_price')}</p>
                )}
              </div>
            ))}{' '}
        </div>

        {((step === 3 && addSubscriptionForm === true) ||
          (!subscriptionsList?.[0]?.id && step === 3)) && (
          <div className="subscription-details-invitation">
            <form id="product-form" onSubmit={handleCreateProductSubmit}>
              <input
                placeholder={t(
                  'dashboard.onboarding.third_step.subscription_placeholder',
                )}
                id="subscription"
                name="subscription"
                type="text"
                value={subscription}
                onChange={(e) => {
                  setSubscription(e.target.value);
                  console.log(subscription);
                }}
              />
              <label className="month-label">/mo</label>
              <input
                placeholder="0.00"
                id="price"
                name="price"
                type="number"
                value={price}
                onChange={handleProductPrice}
                className="price-input"
              />
              <label className="price-label">$</label>
            </form>
          </div>
        )}

        <div className="invitation-button-container">
          {step === 3 && addSubscriptionForm === false && (
            <button
              className="add-new-subs mrm-pb-1"
              onClick={() => {
                setAddSubscriptionForm(true);
              }}
            >
              <Icon name="plus" color="#343434" size={10} />
              <p>
                {t('dashboard.onboarding.third_step.add_new_subscriptions')}
              </p>
            </button>
          )}
          {step === 3 && (
            <>
              {addSubscriptionForm || !subscriptionsList?.[0]?.id ? (
                <button
                  type="submit"
                  form="product-form"
                  className="invitation-submit"
                  disabled={!price}
                >
                  {t('dashboard.onboarding.third_step.next')}
                </button>
              ) : (
                <button
                  onClick={() => setStep(4)}
                  className="invitation-submit"
                  disabled={subSelected === null}
                >
                  {t('dashboard.onboarding.third_step.next')}
                </button>
              )}
            </>
          )}
        </div>
      </>
    );
  } else if (step === 3) {
    return (
      <div className={`${size.width < 768 ? 'mrm-px-1' : ''}`}>
        <h1>{t('dashboard.onboarding.third_step.title')}</h1>
        <p className="description">
          {t('dashboard.onboarding.third_step.description')}
        </p>
        {addSubscriptionForm ? (
          <p className="mrm-mt-1">
            {t('dashboard.onboarding.third_step.create_subscription')}
          </p>
        ) : (
          <p className="mrm-mt-1">
            {t('dashboard.onboarding.third_step.choose_subscription')}
          </p>
        )}
      </div>
    );
  } else return <div></div>;
}
