export function useHandleCreateProductSubmit({
  setAddSubscriptionForm,
  setSubscriptionsList,
  createPriceSubscription,
  createProductSubscription,
  user,
  price,
  subscription,
  getListProductSubscription,
}) {
  const handleCreateProductSubmit = (e) => {
    e.preventDefault();
    createProductSubscription({
      name: subscription,
    })
      .then((res) => {
        createPriceSubscription({
          product: res.data.id,
          unit_amount: Number(price * 100).toFixed(0) * 1,
        }).then((res) => {
          // After creating a subscription, get all the subscriptions again
          getListProductSubscription({ coach_id: user.id }).then((res) => {
            setSubscriptionsList(res.data.results);
          });
          setAddSubscriptionForm(false);
          console.log(res);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return { handleCreateProductSubmit };
}
