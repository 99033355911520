import { useSendPushNotifications } from '../redux/sendPushNotifications'

export default function useUploadMediaFile({
    selectedConversation,
    setSelectedMessages,
    setUploadedFile,
    createMediaMessage,
    uploadMedia,
    clearBlobUrl,
    mediaBlobUrl,
    uploadedFile,
    user,
}) {
    const { sendPushNotifications } = useSendPushNotifications()
    const uploadMediaFile = async () => {
        console.log('uploadMediaFile')

        let file = uploadedFile
        setUploadedFile(null)

        if (!mediaBlobUrl && file.type?.startsWith('audio')) return

        let blob = mediaBlobUrl?.valueOf()

        if (file && user) {
            setSelectedMessages(prevSelectedMessages => {
                return [
                    ...prevSelectedMessages,
                    {
                        id: Math.floor(Math.random() * 1000000),
                        user: {
                            id: user.id,
                            username: user.username,
                            photo: user.photo,
                            first_name: user.first_name,
                            last_name: user.last_name,
                        },
                        timestamp: Date.now(),
                        media: file?.type?.startsWith('image')
                            ? 'photo'
                            : 'audio',
                        type: file?.type?.startsWith('image')
                            ? 'photo'
                            : 'audio',
                        mediaUrl: file?.type?.startsWith('image')
                            ? URL.createObjectURL(file)
                            : blob,
                        text: 'none',
                        local: true,
                    },
                ]
            })

            createMediaMessage({
                type: file?.type?.startsWith('image') ? 'photo' : 'audio',
                userId: user.username,
                counterpartId:
                    selectedConversation.type === 'group'
                        ? selectedConversation.id
                        : selectedConversation.user.username,
                threadId:
                    selectedConversation.type === 'group'
                        ? selectedConversation.id
                        : selectedConversation.user.username,
                data: {
                    from: 'web app',
                    ...(selectedConversation.type === 'group' && {
                        participants: selectedConversation.participants,
                    }),
                    user: {
                        id: user.id,
                        first_name: user.first_name || 'A',
                        last_name: user.last_name || 'A',
                        photo: user.photo,
                        username: user.username,
                    },
                },
            })
                .then(res => {
                    // console.log response of fetch post request
                    console.log(res.body, 'uploadMediaFile res')

                    uploadMedia({
                        uploadUrl: res.data?.uploadURL,
                        file: file,
                    })
                })
                .then(() => {
                    setUploadedFile(null)
                    clearBlobUrl()
                    sendPushNotifications({
                        user,
                        message: file.type.startsWith('image')
                            ? 'Media Message'
                            : 'Audio Message',
                        counterpartId: selectedConversation.user.username,
                        type: file.type.startsWith('image') ? 'photo' : 'audio',
                        counterpart: selectedConversation.user,
                    }).catch(err => {
                        console.log(err)
                    })
                })
        }
    }
    return { uploadMediaFile }
}
