import useWindowSize from '../features/common/useWindowResize';
import { useLayoutEffect } from 'react';

const modalFullScreen = (Modal) => (props) => {
  const isMobile = useWindowSize().width < 768;

  useLayoutEffect(() => {
    if (isMobile) {
      const modalContent = document.getElementsByClassName('modal-content')[0];
      modalContent.style.width = '100vw';
      modalContent.style.height = '100vh';
      modalContent.style['border-radius'] = '0';
      document.getElementsByClassName('modal-dialog')[0].style.margin = '0';
    }
  }, [props.show]);

  return <Modal {...props} />;
};

export default modalFullScreen;
