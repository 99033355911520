import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import Icon from '../../../common/components/Icon'
import { desktopMultiSelectStyles } from '../../../dashboard/UpcomingCalls/styles/multiSelectStyles'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import useGetDropdownAssessments from './new-assessment-modal/hooks/useGetDropdownAssessments'
import useHandleSubmit from './new-assessment-modal/hooks/useHandleSubmit'
import SuccessAdditionModal from './SuccessAdditionModal'

export default function NewAssessmentModal({
    show,
    onHide = () => {},
    userId,
    setParams,
    updateData = null,
    showDeleteModal = () => {},
}) {
    const { assessments } = useGetDropdownAssessments()

    const [showSuccessModal, setShowSuccessModal] = React.useState(false)

    const { handleSubmit } = useHandleSubmit({
        onHide,
        userId,
        setParams,
        update: !!updateData,
        setShowSuccessModal,
    })

    useEffect(() => {
        if (!updateData) return

        console.log('updateData', updateData)

        console.log(
            'assessment',
            assessments.find(
                assessment => assessment.id === updateData.assessment_id
            )
        )
        // look for "Select an assessment" in the html and change it to the assessment name
    }, [updateData, assessments])

    const { t } = useTranslation()

    const validationSchema = Yup.object().shape({
        assessment_id: Yup.string().required(
            t('client_profile.please_select_an_assessment')
        ),
        score: Yup.number().required(t('client_profile.please_enter_a_score')),
        assessment_result_link: Yup.string(),
        month: Yup.string().required(t('client_profile.please_enter_a_month')),
        day: Yup.string().required(t('client_profile.please_enter_a_day')),
        year: Yup.string().required(t('client_profile.please_enter_a_year')),
    })

    if (!assessments || !assessments.length) return null

    return (
        <>
            <SuccessAdditionModal
                show={showSuccessModal}
                description={`Assessment has been ${
                    updateData ? 'updated' : 'added'
                } to client profile.`}
                onHide={() => setShowSuccessModal(false)}
            />
            <Modal show={show} onHide={onHide} className="new-assessment-modal">
                <h1>{updateData ? 'Edit' : 'Add'} Assessment </h1>

                <Formik
                    initialValues={
                        updateData
                            ? updateData
                            : {
                                  assessment_id: '',
                                  score: '',
                                  assessment_result_link: '',
                                  month: '',
                                  day: '',
                                  year: '',
                                  date: '',
                                  min_score: '',
                                  max_score: '',
                              }
                    }
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="assessment_id">
                                    Assessment
                                </label>
                                <div className="assessment-wrapper">
                                    <Icon
                                        name="notes"
                                        size={15}
                                        className="notes-icon"
                                    />
                                    <Select
                                        options={assessments.map(
                                            assessment => ({
                                                value: assessment.id,
                                                label: assessment.name,
                                            })
                                        )}
                                        styles={desktopMultiSelectStyles}
                                        {...(updateData && {
                                            defaultValue: {
                                                value: updateData.assessment_id,
                                                label: assessments.find(
                                                    assessment =>
                                                        assessment.id ===
                                                        updateData.assessment_id
                                                ).name,
                                            },
                                        })}
                                        onChange={value => {
                                            console.log(value)
                                            setFieldValue(
                                                'assessment_id',
                                                value.value
                                            )
                                            setFieldValue(
                                                'min_score',
                                                assessments.find(
                                                    assessment =>
                                                        assessment.id ===
                                                        value.value
                                                ).min_score
                                            )
                                            setFieldValue(
                                                'max_score',
                                                assessments.find(
                                                    assessment =>
                                                        assessment.id ===
                                                        value.value
                                                ).max_score
                                            )

                                            setFieldValue('score', '')
                                        }}
                                        value={assessments.find(
                                            assessment =>
                                                assessment.value ===
                                                values.assessment_id
                                        )}
                                        placeholder={'Select an assessment'}
                                    />
                                </div>
                            </div>
                            {errors.assessment_id && touched.assessment_id && (
                                <p className="error-message">
                                    {errors.assessment_id}
                                </p>
                            )}
                            <div className="form-group">
                                <label htmlFor="date">Date Completed</label>
                                <div className="date-wrapper">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="month"
                                        placeholder="MM"
                                        onChange={handleChange}
                                        value={values.month}
                                    />
                                    <p> / </p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="day"
                                        placeholder="DD"
                                        onChange={handleChange}
                                        value={values.day}
                                    />
                                    <p> / </p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        placeholder="YYYY"
                                        onChange={handleChange}
                                        value={values.year}
                                    />
                                </div>
                            </div>
                            {values.day &&
                                values.month &&
                                values.year &&
                                new Date( // if date is greater than today, disable delete button
                                    `${values.year}-${values.month}-${values.day}`
                                ) > new Date() && (
                                    <div className="error-message">
                                        Date cannot be in the future
                                    </div>
                                )}
                            {errors.month ||
                            errors.day ||
                            errors.year ||
                            errors.date ? (
                                <p className="error-message">
                                    {errors.month ||
                                        errors.day ||
                                        errors.year ||
                                        errors.date}
                                </p>
                            ) : null}
                            <div className="form-group">
                                <label htmlFor="score">Score</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={e => {
                                        const { value } = e.target

                                        // only allow numbers
                                        if (isNaN(value)) return

                                        if (updateData && !values.min_score) {
                                            setFieldValue(
                                                'min_score',
                                                assessments.find(
                                                    assessment =>
                                                        assessment.id ===
                                                        values.assessment_id
                                                )?.min_score
                                            )

                                            setFieldValue(
                                                'max_score',
                                                assessments.find(
                                                    assessment =>
                                                        assessment.id ===
                                                        values.assessment_id
                                                )?.max_score
                                            )
                                        }

                                        if (value < values.min_score) {
                                            setFieldValue(
                                                'score',
                                                values.min_score
                                            )
                                        } else if (value > values.max_score) {
                                            setFieldValue(
                                                'score',
                                                values.max_score
                                            )
                                        } else {
                                            setFieldValue('score', value)
                                        }
                                    }}
                                    value={values.score}
                                    id="score"
                                    placeholder={
                                        values.min_score ||
                                        assessments.find(
                                            assessment =>
                                                assessment.id ===
                                                values.assessment_id
                                        )?.min_score ||
                                        '0' +
                                            ' - ' +
                                            (values.max_score ||
                                                assessments.find(
                                                    assessment =>
                                                        assessment.id ===
                                                        values.assessment_id
                                                )?.max_score ||
                                                '0')
                                    }
                                />
                            </div>
                            {errors.score && touched.score && (
                                <p className="error-message">{errors.score}</p>
                            )}
                            <div className="form-group">
                                <label htmlFor="assessment_result_link">
                                    Link to assessment results (optional)
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="assessment_result_link"
                                    placeholder="assessments.noble.health"
                                    onChange={handleChange}
                                    value={values.assessment_result_link}
                                />
                            </div>
                            {errors.assessment_result_link &&
                                touched.assessment_result_link && (
                                    <p className="error-message">
                                        {errors.assessment_result_link}
                                    </p>
                                )}

                            <button
                                type="submit"
                                className="submit-button"
                                onSubmit={handleSubmit}
                                disabled={
                                    new Date( // if date is greater than today, disable delete button
                                        `${values.year}-${values.month}-${values.day}`
                                    ) > new Date()
                                }
                            >
                                {updateData ? 'Save' : 'Add'} Assessment
                            </button>

                            {updateData && (
                                <button
                                    type="button"
                                    className="submit-button"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #E8744F',
                                        color: '#E8744F',
                                        marginTop: '0.625rem',
                                    }}
                                    onClick={() => {
                                        showDeleteModal()
                                        onHide()
                                    }}
                                >
                                    {t('client_profile.delete')}{' '}
                                    {t('client_profile.assessment')}
                                </button>
                            )}

                            <button
                                type="button"
                                className="cancel-button"
                                onClick={onHide}
                            >
                                {t('client_profile.cancel')}
                            </button>
                        </form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
