import { useContext, useEffect, useRef } from 'react'
import Linkify from 'react-linkify'
import { AudioPlayerProvider } from 'react-use-audio-player'
import getRelativeDay from '../../../../common/getRelativeDay'
import { AudioPlayingContext } from '../contexts/AudioPlayingProvider'
import AudioMessagePlayer from './audio/AudioMessagePlayer'

export default function Messages({ selectedMessages, user }) {
  const elementRef = useRef()

  useEffect(() => {
    // if selectedmessages is changed scroll to bottom
    if (elementRef.current) {
      elementRef.current.scrollTop = elementRef.current.scrollHeight
    }
  }, [selectedMessages])

  const {
    messagePlaying,
    setMessagePlaying,
    recordingAudio,
    setRecordingAudio,
  } = useContext(AudioPlayingContext)

  return (
    <div className="messages" ref={elementRef}>
      {selectedMessages &&
        selectedMessages.map((message, idx) => {
          return (
            <div key={idx} style={{ position: 'relative' }}>
              {/** timestamp */}
              <p
                style={{
                  fontSize: '0.6rem',
                  position: 'absolute',
                  fontWeight: '300',
                  top: '0',
                  ...((message.user.id === user.id && {
                    right: '0',
                    marginRight: '2.5rem',
                  }) || {
                    left: '0',
                    marginLeft: '2.5rem',
                  }),
                }}
              >
                {message?.user?.first_name || ''}{' '}
                {message?.user?.last_name || ''}{' '}-{' '}
                {getRelativeDay(message?.timestamp)}
              </p>
              <div
                className="message"
                style={{
                  marginTop: '1rem',
                }}
              >
                <div className="message-header">
                  {message.user.id !== user.id && (
                    <img
                      src={
                        message?.user.photo
                          ? message?.user.photo
                          : `https://ui-avatars.com/api/?name=${message?.user
                              ?.first_name[0] +
                              message.user?.last_name[0]}&background=${
                              message.user.id !== user.id ? 'F8F5F5' : '88A088'
                            }&color=${
                              message.user.id !== user.id ? '88A088' : 'F8F5F5'
                            }&size=128`
                      }
                      style={{
                        borderRadius: '5px',
                        borderTopRightRadius: '0px',
                      }}
                    />
                  )}
                  {!message?.media && (
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText) => (
                        <a
                          target="_blank"
                          href={decoratedHref}
                          style={{
                            color:
                              message.user.id !== user.id
                                ? 'var(--primary-dark-green)'
                                : 'var(--primary-white)',
                            fontWeight: '300',
                            textDecoration: 'underline',
                          }}
                        >
                          {decoratedText}{' '}
                        </a>
                      )}
                    >
                      <p
                        className="message-body"
                        style={{
                          borderRadius:
                            message.user.id !== user.id
                              ? '0px 10px 10px 10px'
                              : '10px 0px 10px 10px',
                          backgroundColor:
                            message.user.id !== user.id
                              ? 'var(--primary-white)'
                              : 'var(--primary-green)',
                          color:
                            message.user.id !== user.id
                              ? 'var(--primary-dark-green)'
                              : 'var(--primary-white)',

                          ...(message.user.id === user.id
                            ? {
                                marginLeft: '2.5rem',
                              }
                            : {
                                marginRight: '2.5rem',
                              }),
                        }}
                      >
                        {message?.text}
                      </p>
                    </Linkify>
                  )}

                  {message?.media === 'audio' && (
                    <div
                      style={{
                        display: 'flex',
                        position: 'relative',
                        width: '100%',
                        justifyContent:
                          message.user.id !== user.id
                            ? 'flex-start'
                            : 'flex-end',
                        ...((message.user.id === user.id && {
                          right: '0',
                          marginLeft: '2.5rem',
                        }) || {
                          left: '0',
                          marginRight: '2.5rem',
                        }),
                      }}
                    >
                      <AudioPlayerProvider>
                        {message.mediaUrl && (
                          <AudioMessagePlayer
                            url={message.mediaUrl}
                            mine={message.user.id === user.id}
                            messagePlaying={messagePlaying}
                            setMessagePlaying={setMessagePlaying}
                            recordingAudio={recordingAudio}
                            setRecordingAudio={setRecordingAudio}
                          />
                        )}
                      </AudioPlayerProvider>
                    </div>
                  )}

                  {message?.media === 'photo' && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent:
                          message.user.id !== user.id
                            ? 'flex-start'
                            : 'flex-end',
                        ...((message.user.id === user.id && {
                          right: '0',
                          marginLeft: '2.5rem',
                        }) || {
                          left: '0',
                          marginRight: '2.5rem',
                        }),
                      }}
                    >
                      <img
                        src={message?.mediaUrl}
                        style={{
                          width: 'auto',
                          height: 'auto',
                          objectFit: 'contain',
                          maxHeight: '200px',
                          maxWidth: '70vw',
                        }}
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = message.local
                            ? URL.createObjectURL(message.mediaUrl)
                            : message.mediaUrl
                        }}
                      />
                    </div>
                  )}
                  {message.user.id === user.id && (
                    <img
                      src={
                        message?.user.photo
                          ? message?.user.photo
                          : `https://ui-avatars.com/api/?name=${message?.user
                              ?.first_name[0] +
                              message.user
                                ?.last_name[0]}&background=718371&color=fff&size=128`
                      }
                      style={{
                        borderRadius: '5px',
                        borderTopLeftRadius: '0px',
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}
