import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Controller, useForm, useWatch } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import Switch from 'react-switch'
import modalFullScreen from '../../../common/modalFullScreen'
import Icon from '../../common/components/Icon'
import {
    useDeleteCoachCall,
    useGetUpcomingCallsParticipants,
    useUpdateCoachCall,
} from '../redux/hooks'
import { upcomingCallsSchema } from './schema'
import { desktopMultiSelectStyles } from './styles/multiSelectStyles'

import ActionMenu from '../../common/ActionMenu'
import { useTranslation } from '../../translations/redux/setDefaultLanguage'

function EditCallModal({
    callId,
    show,
    onHide,
    deleteCall = null,
    userId,
    coachCalls,
}) {
    const { updateCoachCall, updateCoachCallPending } = useUpdateCoachCall()

    const defaultCallValues = coachCalls.find(call => call.id === callId)

    const {
        upcomingCallsParticipants,
        getUpcomingCallsParticipants,
    } = useGetUpcomingCallsParticipants()

    const multiselectList = {
        upcomingCallsParticipants: upcomingCallsParticipants
            ? upcomingCallsParticipants.results.map(item => ({
                  id: item.id,
                  full_name: `${item.first_name} ${item.last_name}`,
              }))
            : defaultCallValues?.meeting_participants?.invited?.map(
                  participant => ({
                      id: participant.id,
                      full_name: `${participant.first_name} ${participant.last_name}`,
                  })
              ),
    }

    const {
        register,
        handleSubmit,
        control,
        errors,
        setError,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(upcomingCallsSchema),
    })

    useWatch({ control })

    const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
        <div
            ref={ref}
            onClick={onClick}
            className={clsx('form-control hover', { 'text-muted': !value })}
        >
            <FontAwesomeIcon icon={faCalendar} size="sm" className="mr-2" />
            {value || 'Select Date & Time'}
        </div>
    ))

    const handleError = useCallback(
        err =>
            Object.keys(err).forEach(key => {
                const errors = err[key]
                if (errors.length) {
                    setError(key, { message: errors[0], type: 'remote' })
                }
            }),
        [setError]
    )

    const loadOptions = inputValue => {
        return getUpcomingCallsParticipants({
            search: inputValue,
        })
            .then(res => {
                console.log(res)
                return res.results.map(user => ({
                    value: `${user.first_name} ${user.last_name}`,
                    label: `${user.first_name} ${user.last_name}`,
                    id: user.id,
                }))
            })
            .catch(e => handleError(e.response.data))
    }

    const handleSaveClick = useCallback(
        ({
            call_id,
            call_name,
            user_provided_timestamp,
            user_provided_link,
            selected_participants,
            is_recurrent,
            use_upheal_link,
            include_creator,
            interval,
        }) => {
            user_provided_timestamp = new Date(
                user_provided_timestamp
            ).valueOf()

            if (
                !user_provided_link?.match(/^https?:\/\//) &&
                user_provided_link
            )
                user_provided_link = 'http://' + user_provided_link

            updateCoachCall({
                call_id,
                call_name,
                user_provided_timestamp,
                user_provided_link,
                participants: selected_participants.map(item => item.id),
                is_recurrent,
                use_upheal_link,
                interval,
                include_creator,
                ...(userId && { user_id: userId }),
            })
                .then(() => onHide())
                .catch(e => handleError(e.response.data))
        },
        [updateCoachCall, handleError, onHide]
    )

    const { t } = useTranslation()
    const { deleteCoachCall } = useDeleteCoachCall()

    const deleteCallHandler = useCallback(() => {
        deleteCoachCall({ callId, userId })
            .then(() => onHide())
            .catch(e => console.log(e.response.data))
    }, [callId, deleteCoachCall, onHide])

    const [detailMenu, setDetailMenu] = useState(deleteCall)

    useEffect(() => {
        if (deleteCall) {
            setDetailMenu(deleteCall)
        }
    }, [deleteCall])

    const handleUserDetailClick = useCallback(
        callId => () => setDetailMenu(callId),
        []
    )

    const handleHideDetailMenu = useCallback(() => {
        setDetailMenu(false)
        onHide()
    }, [onHide])

    const actionMenuItems = useCallback(() => {
        const items = [
            { label: t('calls.are_you_sure_you_want_to_delete_this_call') },
            {
                label: t('calls.delete_call'),
                onClick: deleteCallHandler,
                className: 'text-danger',
            },
        ]
        return items
    }, [deleteCallHandler])

    useEffect(() => {
        if (defaultCallValues) {
            setValue('include_creator', defaultCallValues?.include_creator)
            setValue('is_recurrent', defaultCallValues?.is_recurrent)
            setValue('use_upheal_link', defaultCallValues?.use_upheal_link)
        }
    }, [defaultCallValues, setValue])

    if (deleteCall)
        return (
            <ActionMenu
                show={!!detailMenu}
                onHide={handleHideDetailMenu}
                items={actionMenuItems()}
            />
        )

    if (!defaultCallValues) return null

    const defaultDateValue = new Date(defaultCallValues?.meeting_timestamp)

    return (
      <Modal
        className="dashboard-coach-add-calls-page-modal"
        show={show}
        onHide={onHide}
        centered
      >
        <Modal.Header>
          {!updateCoachCallPending && (
            <>
              <button onClick={() => onHide()}>Cancel</button>
              <h2 className="text-center">
                {t('calls.edit')}:{defaultCallValues?.call_name}
              </h2>
              <button onClick={handleSubmit(handleSaveClick)}>
                {t('calls.save')}
              </button>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form className="mrm-px-1">
            <Controller
              render={({ onChange, value }) => (
                <div className="call-types">
                  <div
                    className={`call-type ${clsx({
                      active: value === true,
                    })}`}
                    onClick={() => {
                      setValue('include_creator', true)
                    }}
                  >
                    <Icon name="circle-box" size={13} active={value === true} />
                    <p>{t('calls.personal')}</p>
                  </div>

                  <div
                    className={`call-type ${clsx({
                      active: value === false,
                    })}`}
                    onClick={() => {
                      setValue('include_creator', false)
                    }}
                  >
                    <Icon
                      name="circle-box"
                      size={13}
                      active={value === false}
                    />
                    <p>{t('calls.third_party')} </p>
                  </div>
                </div>
              )}
              name="include_creator"
              control={control}
              defaultValue={true}
            />
            {errors.include_creator && (
              <p className="error-message">{errors.include_creator.message}</p>
            )}
            <Form.Group controlId="call_name">
              <Form.Label>{t('calls.call_name')}</Form.Label>
              <Form.Control
                name="call_name"
                type="text"
                defaultValue={defaultCallValues?.call_name}
                ref={register}
              />
              {errors.call_name && (
                <p className="error-message">{errors.call_name.message}</p>
              )}
            </Form.Group>
            <Form.Group
              controlId="user_provided_timestamp"
              className="mb-0 d-flex flex-column mb-4"
            >
              <Form.Label>{t('calls.date_time')}</Form.Label>
              <Controller
                name="user_provided_timestamp"
                control={control}
                defaultValue={defaultDateValue}
                render={({ onChange, value }) => (
                  <DatePicker
                    selected={value}
                    showTimeSelect
                    minDate={new Date()}
                    timeIntervals={5}
                    dateFormat="MMMM d @ h:mm aa"
                    customInput={
                      <DatePickerButton
                        className={clsx({
                          'is-invalid': errors.user_provided_timestamp,
                        })}
                      />
                    }
                    onChange={onChange}
                    className="text-primary ml-0"
                  />
                )}
              />
              {errors.user_provided_timestamp && (
                <p className="error-message">
                  {errors.user_provided_timestamp.message}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="selected_participants">
              <Form.Label>{t('calls.participants')}</Form.Label>
              <Controller
                name="selected_participants"
                control={control}
                defaultValue={defaultCallValues?.meeting_participants?.invited.map(
                  participant => ({
                    value: `${participant.first_name} ${participant.last_name}`,
                    label: `${participant.first_name} ${participant.last_name}`,
                    id: participant.id,
                  })
                )}
                render={({ onChange, value }) => (
                  <AsyncSelect
                    isMulti
                    name="selected_participants"
                    placeholder={t('calls.select_participants')}
                    value={value}
                    getOptionValue={option => option.id}
                    defaultOptions
                    loadOptions={loadOptions}
                    onChange={inputValue => {
                      if (!multiselectList.upcomingCallsParticipants) return
                      console.log('inputValue', inputValue)
                      onChange(inputValue || [])
                    }}
                    ref={register}
                    styles={desktopMultiSelectStyles}
                  />
                )}
              />
              {// show the selected partipant here
              watch('selected_participants')?.length > 0 &&
                upcomingCallsParticipants?.results &&
                upcomingCallsParticipants.results.filter(participant =>
                  watch('selected_participants').find(
                    selectedParticipant =>
                      selectedParticipant.id === participant.id
                  )
                ).length > 0 && (
                  <p
                    className="error-message"
                    style={{
                      color: '#343434',
                      fontWeight: '400',
                    }}
                  >
                    {upcomingCallsParticipants &&
                      upcomingCallsParticipants.results
                        .filter(participant =>
                          watch('selected_participants').find(
                            selectedParticipant =>
                              selectedParticipant.id === participant.id
                          )
                        )
                        .map(participant => participant.email)
                        .join(', ')}
                  </p>
                )}
              {errors.selected_participants && (
                <p className="error-message">
                  {errors.selected_participants.message}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="user_provided_link">
              <Form.Label>
                {t('calls.meeting_link')}
                (zoom, google meet)
              </Form.Label>
              <Form.Control
                name="user_provided_link"
                type="text"
                defaultValue={defaultCallValues?.meeting_link || ''}
                ref={register}
                placeholder={
                  watch('use_upheal_link')
                    ? t('calls.using_upheal_link')
                    : t('calls.meeting_link')
                }
                disabled={watch('use_upheal_link')}
              />
              {errors.user_provided_link && (
                <p className="error-message">
                  {errors.user_provided_link.message}
                </p>
              )}
            </Form.Group>{' '}
            <Controller
              name="use_upheal_link"
              control={control}
              defaultValue={defaultCallValues?.use_upheal_link ? true : false}
              render={({ onChange, value }) => (
                <div
                  className="use-upheal-link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setValue('user_provided_link', '')
                    setError('user_provided_link', {
                      message: '',
                    })

                    onChange(!value)
                  }}
                >
                  <button type="button">
                    <Icon name="checkbox" color={'#D9D9D9'} active={value} />
                  </button>
                  <p>{t('calls.use_upheal_link')}</p>
                </div>
              )}
            />
            {errors.use_upheal_link && (
              <p className="error-message">{errors.use_upheal_link.message}</p>
            )}
            <Form.Group
              controlId="is_recurrent"
              className="mb-0 d-flex flex-column mb-2"
            >
              <Form.Label>{t('calls.recurs_weekly')}</Form.Label>
              <Controller
                name="is_recurrent"
                control={control}
                defaultValue={defaultCallValues?.is_recurrent}
                render={({ onChange, value }) => (
                  <Switch
                    onChange={onChange}
                    checked={!!value}
                    onColor="#343434"
                  />
                )}
              />
            </Form.Group>
            <Form.Group controlId="call_id" className="d-none">
              <Form.Control
                name="call_id"
                type="number"
                defaultValue={callId}
                ref={register}
              />
            </Form.Group>
            <Form.Group
              controlId="interval"
              className={watch('is_recurrent') ? '' : 'd-none'}
            >
              <Controller
                name="interval"
                control={control}
                defaultValue={defaultCallValues?.interval}
                render={({ value }) => (
                  <div className="intervals">
                    <button
                      onClick={() => setValue('interval', 0)}
                      type="button"
                    >
                      <Icon name="circle-box" size={13} active={value === 0} />
                      <p>Every week</p>
                    </button>
                    <button
                      onClick={() => setValue('interval', 1)}
                      type="button"
                    >
                      <Icon name="circle-box" size={13} active={value === 1} />
                      <p>Every other week</p>
                    </button>
                  </div>
                )}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <div className="border-thin" />

        <button className="delete-btn" onClick={handleUserDetailClick(callId)}>
          <Icon name="delete" color="#fff" />
          &nbsp;&nbsp; {t('calls.delete_call')}
        </button>

        {detailMenu && (
          <ActionMenu
            show={!!detailMenu}
            onHide={handleHideDetailMenu}
            items={actionMenuItems()}
          />
        )}
      </Modal>
    )
}

export default modalFullScreen(EditCallModal)
