import { useFormikContext } from 'formik'
import React from 'react'
import Switch from 'react-switch'
import { CurrentUserIsAdmin } from '../../../../common/roles'

export default function Switches() {
    const { setFieldValue, values } = useFormikContext()
    const isAdmin = CurrentUserIsAdmin()

    return (
        <div className="form-group flex flex-col gap-1">
            <div className="flex items-center gap-1">
                <Switch
                    onChange={() => {
                        setFieldValue('is_approved', !values.is_approved)
                    }}
                    checked={values.is_approved}
                    onColor="#343434"
                />
                <p>Approved</p>
            </div>
            {isAdmin && (
                <div className="flex items-center gap-1">
                    <Switch
                        onChange={() => {
                            if (values.is_internal_coach) {
                                setFieldValue('create_upheal_account', false)
                            }
                            setFieldValue(
                                'is_internal_coach',
                                !values.is_internal_coach
                            )
                        }}
                        checked={values.is_internal_coach}
                        onColor="#343434"
                    />
                    <p>Internal Coach</p>
                </div>
            )}
            {isAdmin &&
                values.is_internal_coach &&
                values?.groups?.includes('Coach') && (
                    <div className="flex items-center gap-1">
                        <Switch
                            onChange={() => {
                                setFieldValue(
                                    'create_upheal_account',
                                    !values.create_upheal_account
                                )
                            }}
                            checked={values.create_upheal_account}
                            onColor="#343434"
                        />
                        <p>Create Upheal Account</p>
                    </div>
                )}
        </div>
    )
}
