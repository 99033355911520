import React from 'react';

export default function CheckRadioIcon({
  size = 24,
  color = 'var(--primary-light-green)',
  borderColor = 'var(--primary-gray-lightest)',
  style = {},
  className = '',
  active = false,
}) {
  if (active) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        style={{ ...style }}
      >
        <circle
          cx="10.5558"
          cy="10.4444"
          r="9.94444"
          fill={color}
          stroke={color}
        />
        <path
          d="M5.33362 10.918L8.54875 14.3611L19.6947 3.26385"
          stroke="#F8F5F5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        style={{ ...style }}
      >
        <circle cx="8.57164" cy="8.54869" r="7.42564" stroke={borderColor} />
      </svg>
    );
  }
}
