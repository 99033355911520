import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-duotone-svg-icons';
import UserAvatar from '../../../common/UserAvatar';
import useFollowNotification from '../../../common/useFollowNotificationHook';

const nonCompetencyNotificationVerbs = ['NEW_FILE_ATTACHED', 'COMMENTED'];

function renderNotificationMessage(n) {
  switch (n.verb) {
    case 'COACH_ASSIGNED_STUDENT_TO_ROADMAP':
      return (
        <Fragment>
          <strong>
            {n.sender.first_name} {n.sender.last_name}
          </strong>
          &nbsp;
          <span>{n.verb_display.slice(0, -1)}</span>&nbsp;
          {n?.target?.title}
        </Fragment>
      );
    default:
      let competency = n.target
        ? nonCompetencyNotificationVerbs.indexOf(n.verb) >= 0
          ? n.target.competency
          : n.target
        : null;
      return (
        <Fragment>
          {n.sender && (
            <strong>
              {n.sender.first_name} {n.sender.last_name}
            </strong>
          )}
          &nbsp;
          <span>{n.verb_display}</span>&nbsp;
          {competency ? (
            <strong>
              {competency.stage_title} {competency.title}
            </strong>
          ) : null}
        </Fragment>
      );
  }
}

export default function NotificationsList({ notifications }) {
  const followNotification = useFollowNotification();

  return (
    <div className="notifications-components-default-page-notifications-list">
      {notifications.results.length === 0 && (
        <div className="no-notifications">
          <FontAwesomeIcon icon={faBell} className="mx-auto d-block" /> No
          notifications
        </div>
      )}
      {notifications.results.length > 0 &&
        notifications.results.map((n, idx) => {
          return (
            <Fragment key={idx}>
              <Row
                className="notification-row"
                noGutters
                onClick={() => followNotification(n)}
              >
                <span
                  style={{
                    opacity: !n.read ? 1 : 0,
                  }}
                  className="unread-marker"
                />
                <UserAvatar user={n.sender} className="avatar" size="md" />
                <Col className="name-and-date-grid">
                  <p className="theme-text-secondary notification-secondary">
                    {dayjs(n.timestamp).fromNow()}
                  </p>
                  <p className="theme-text-primary notification-primary">
                    {renderNotificationMessage(n)}
                  </p>
                </Col>
              </Row>
            </Fragment>
          );
        })}
    </div>
  );
}

NotificationsList.propTypes = {};
NotificationsList.defaultProps = {};
