import { ErrorMessage, useFormikContext } from 'formik';
import { Fragment, useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUpdateUserAvatar } from '../../../manage/redux/updateUserAvatar';
import Slider from 'rc-slider';

export default function UploadProfilePictureForm() {
  const { setFieldValue, values } = useFormikContext();
  const { updateUserAvatar } = useUpdateUserAvatar();

  const photoRef = useRef(null);

  const [showPhotoEdit, setShowPhotoEdit] = useState(false);
  const [showPhotoChange, setShowPhotoChange] = useState(false);

  const handleChangeClick = useCallback(() => {
    if (values.photo) {
      setShowPhotoChange(true);
    } else {
      photoRef.current.click();
    }
  }, [values, setShowPhotoEdit]);

  const handleAvatarUpdate = useCallback(
    (data) =>
      updateUserAvatar({ userId: values?.id, data }).then(() =>
        window.location.reload(),
      ),
    [values?.id, updateUserAvatar],
  );

  const onRemove = useCallback(
    () =>
      updateUserAvatar({ userId: values?.id, data: null }).then(() =>
        window.location.reload(),
      ),
    [values?.id, updateUserAvatar],
  );

  const handleUpdatePhoto = useCallback(
    (data) => {
      setShowPhotoChange(false);
      handleAvatarUpdate(data);
    },
    [handleAvatarUpdate, setShowPhotoChange],
  );

  const handlePhotoUpload = useCallback(() => photoRef.current.click(), []);

  const handleRemovePhoto = useCallback(() => {
    setShowPhotoChange(false);
    onRemove();
  }, [onRemove]);

  return (
    <div className="profile-picture">
      {values.photo || (values.first_name && values.last_name) ? (
        <Fragment>
          <img
            src={
              values.photo
                ? // if values is an url, use it as is else use URL.createObjectURL()
                  typeof values.photo === 'string'
                  ? values.photo
                  : URL.createObjectURL(values.photo)
                : `https://ui-avatars.com/api/?name=${
                    values.first_name[0] + values.last_name[0]
                  }&background=718371&color=fff&size=128`
            }
          />
          <div>
            <label className="label">
              {values.first_name && values.last_name ? (
                <Fragment>
                  {values.first_name} {values.last_name}
                </Fragment>
              ) : (
                'Profile Photo'
              )}
            </label>
            <div className="control">
              <input
                name="photo"
                type="file"
                className="d-none"
                ref={photoRef}
                onChange={(e) => {
                  setFieldValue('photo', e.target.files[0]);
                  setShowPhotoEdit(true);
                  e.target.value = null;
                }}
              />
              <button
                className="change-profile-picture-underline"
                onClick={handleChangeClick}
                type="button"
              >
                Change Profile Photo
              </button>
              <ErrorMessage name="photo" component="div" />
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <input
            name="avatar"
            type="file"
            className="d-none"
            onChange={(e) => {
              const [file] = e.target.files;
              if (!file.type.startsWith('image')) return;
              setFieldValue('photo', file);
            }}
            ref={photoRef}
          />
          <button
            type="button"
            className="upload-button"
            onClick={handleChangeClick}
          >
            {' '}
            Upload Profile Photo{' '}
          </button>
        </Fragment>
      )}
      <AvatarEditModal
        show={showPhotoEdit}
        onHide={() => setShowPhotoEdit(false)}
        photo={values.photo}
        uploadFileName={`photo_${values?.id}.jpg`}
        onUpdate={handleUpdatePhoto}
      />
      <AvatarChangeModal
        show={showPhotoChange}
        onHide={() => setShowPhotoChange(false)}
        onRemovePhoto={handleRemovePhoto}
        onPhotoUpload={handlePhotoUpload}
      />
    </div>
  );
}

const AvatarEditModal = ({
  show,
  photo,
  onHide,
  uploadFileName,
  onUpdate,
  defaultScale = 1.2,
}) => {
  const [photoScale, setPhotoScale] = useState(defaultScale);

  const editorRef = useRef(null);

  const handleCropPhoto = useCallback(() => {
    const canvas = editorRef.current.getImageScaledToCanvas();
    canvas.toBlob(
      (blob) => {
        const data = new FormData();
        data.append('photo', blob, uploadFileName);
        onUpdate(data);
        onHide();
      },
      'image/jpeg',
      95,
    );
  }, [onHide, uploadFileName, onUpdate]);

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title className="w-full">
          <h1
            style={{
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            Zoom/Position Profile Photo
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 photo-edit-body">
        {photo && (
          <AvatarEditor
            image={photo}
            ref={editorRef}
            width={250}
            height={250}
            border={50}
            borderRadius={250}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={photoScale}
            rotate={0}
            style={{ width: '100%', height: 'auto', background: 'black' }}
          />
        )}
        <div className="scale-slider">
          <Slider
            defaultValue={defaultScale}
            min={0.8}
            max={2}
            step={0.01}
            onChange={setPhotoScale}
            handleStyle={{
              border: '#343434',
              background: '#fff',
            }}
            trackStyle={{ background: '#343434' }}
          />
        </div>
        <div className="edit-actions">
          <Button
            variant="secondary"
            className="font-weight-bold"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: '#343434', color: '#fff' }}
            onClick={handleCropPhoto}
          >
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const AvatarChangeModal = ({ show, onPhotoUpload, onRemovePhoto, onHide }) => (
  <Modal centered show={show} onHide={onHide}>
    <Modal.Header>
      <Modal.Title className="w-100">
        <h1
          style={{
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          Change Profile Photo
        </h1>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="photo-change-body">
      <Link onClick={onPhotoUpload}>Upload New Photo</Link>
      <div className="border-thin" />
      <Link onClick={onRemovePhoto} className="remove">
        Remove Current Photo
      </Link>
      <div className="border-thin" />
      <Link onClick={onHide}>Cancel</Link>
    </Modal.Body>
  </Modal>
);
