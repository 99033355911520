import React from 'react';
import Icon from '../../../../common/components/Icon';
import FitbitLogo from '../../../assets/dashboard/fitbit-logo.png';

export default function Device() {
  return (
    <div className="records-device">
      <div className="device-left">
        <div className="device-icon">
          <img src={FitbitLogo} alt="fitbit logo" />
        </div>
        <div className="device-name">
          <p className="device-title">Fitbit</p>
          <p className="device-status">Connected</p>
        </div>
      </div>
      <div className="device-right">
        <p> Manage</p>
        <Icon name="arrow-right" size={10} />
      </div>
    </div>
  );
}
