import * as Yup from 'yup';

export const SubscriptionSchema = Yup.object().shape({
  product: Yup.string().required('Product is required'),
  text_message_alert: Yup.bool(),
  client_email_alert: Yup.bool(),
  is_trial: Yup.bool(),
  // if is_trial is true, then trial_end is required
  trial_end: Yup.date().when('is_trial', {
    is: true,
    then: Yup.date().required('Trial end date is required'),
    otherwise: Yup.date(),
  }),
  by_sms_or_default: Yup.bool().required('By SMS or Default is required'),
});
