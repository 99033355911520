import React from 'react';

export default function Banner() {
  if (!(new Date() < new Date('6/4/2022 8:00 PM'))) return null;

  return (
    <div
      style={{
        backgroundColor: '#D6D9D4',
        borderRadius: '0.5rem',
        marginBottom: '2rem',
        color: 'var(--primary-dark)',
        width: '100%',
        padding: '1rem',
      }}
    >
      <p
        style={{
          fontSize: '0.8rem',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          Please note:
        </span>{' '}
        Noble will be down for scheduled maintance this Saturday (6/4/2022) from
        6:00 PM - 8:00 PM MST. You will not be able to login during this time.
      </p>
    </div>
  );
}
