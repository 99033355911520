import { useContext, useEffect } from 'react';
import useQuery from '../../../common/useQuery';
import { useFetchAssignedUsers } from '../../../dashboard/redux/fetchAssignedUsers';
import { CompetencyContext } from '../../CompetencyPage';

export default function FetchAssignedUsers() {
  const query = useQuery();

  const { fetchAssignedUsers, assignedUsers } = useFetchAssignedUsers();
  const studentId = query && Number(query.get('user'));
  const { setStudent, setStudentUser } = useContext(CompetencyContext);

  /*   useEffect(() => {
    fetchAssignedUsers({ studentId });
  }, [studentId]);
 */
  useEffect(() => {
    if (!!studentId) {
      fetchAssignedUsers({ userId: studentId }).then((res) =>
        setStudent(res.results[0]),
      );
    }
  }, [fetchAssignedUsers, studentId]);

  useEffect(() => {
    if (assignedUsers) {
      setStudentUser(assignedUsers.results.find((u) => u.id === studentId));
    }
  }, [assignedUsers, studentId]);

  return null;
}
