import React from 'react';
import ResponseCard from './ResponseCard';
import getRelativeDay from '../../../../common/getRelativeDay';

export default function ThreadStart({ ...props }) {
  const { actionItemResponses } = props;
  return (
    <React.Fragment>
      {
        // first response is the latest one -- it's the start of the thread in the UI
        actionItemResponses
          .filter((response) => response.message !== '')
          .slice(-1)
          .map((item, idx) => {
            let relativeTime = getRelativeDay(item?.created_at);
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                  marginBottom: '0.5rem',
                  width: '100%',
                }}
              >
                <ResponseCard
                  key={idx}
                  item={item}
                  relativeTime={relativeTime}
                  {...props}
                  className="height-action-item-start"
                />
                <hr />
                <p className="responses-length pb-2">
                  {actionItemResponses.filter(
                    (response) => response.message !== '',
                  ).length - 1}{' '}
                  comments
                </p>
              </div>
            );
          })
      }
    </React.Fragment>
  );
}
