import clsx from 'clsx';
import 'rc-slider/assets/index.css';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import { useUnauthorizedErrorHandler } from '../../../../common/apiHelpers';
import Icon from '../../../common/components/Icon';
import Loader from '../../../common/Loader';
import useQuery from '../../../common/useQuery';
import { useFetchGlobalQuestions } from '../../../manage/redux/fetchGlobalQuestions';
import {
  useAddCompetencyAttachment,
  useDeleteCompetencyAttachment,
  useFetchCompetencyActionItemAssessments,
  useFetchQuestionAnswers,
} from '../../redux/hooks';
import AttachmentModal, {
  AttachmentList,
} from '../ActionItemEditing/Attachment';
import SelfReflection from '../SelfReflection';
import ActionItems from './ActionItems';
import GlobalQuestions from './CompetencyLearnTab/GlobalQuestions';

export default function CompetencyLearnTab({
  roadmapId,
  competency,
  user,
  refetchCompetency,
}) {
  const [attachment, setAttachment] = useState(null);

  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();
  const fileEl = useRef({});
  const {
    actionItems,
    fetchCompetencyActionItemAssessmentsPending,
    fetchCompetencyActionItemAssessments,
  } = useFetchCompetencyActionItemAssessments();

  const { globalQuestions, fetchGlobalQuestions, fetchGlobalQuestionsPending } =
    useFetchGlobalQuestions();
  const { questionAnswers, fetchQuestionAnswers, fetchQuestionAnswersPending } =
    useFetchQuestionAnswers();

  const {
    addCompetencyAttachment,
    addCompetencyAttachmentPending,
    addCompetencyAttachmentReset,
    addCompetencyAttachmentProgress,
    addCompetencyAttachmentError,
  } = useAddCompetencyAttachment();

  const query = useQuery();
  const { deleteCompetencyAttachment } = useDeleteCompetencyAttachment();
  const location = useLocation();

  const studentId = useMemo(() => {
    if (!user) {
      return undefined;
    }
    if (user.groups.includes('Admin') || user.groups.includes('Coach')) {
      if (query) {
        const queryUser = query.get('user');
        if (queryUser) {
          return Number(queryUser);
        }
      }
      return user.id;
    } else {
      return undefined;
    }
  }, [user, query]);

  // This effect assumes competency prop is always defined, i.e. props can be accessed without triggering TypeError
  useEffect(() => {
    if (!roadmapId || !competency.stage || !competency.id || !user) {
      return;
    }
    // TODO: Add another .catch to handle rest of errors
    const args = {
      roadmapId,
      stageId: competency.stage,
      competencyId: competency.id,
      student: studentId,
    };
    fetchCompetencyActionItemAssessments(args)
      .then((res) => console.log('fetchCompetencyActionItemAssessments', res))
      .catch((err) =>
        console.log('fetchCompetencyActionItemAssessments', err.response),
      );
    fetchGlobalQuestions({
      roadmapId,
      stageId: competency.stage,
      competencyId: competency.id,
    })
      .then((res) => console.log('fetchGlobalQuestions', res))
      .catch((err) => console.log('fetchGlobalQuestions', err.response));
    fetchQuestionAnswers({
      roadmapId,
      stageId: competency.stage,
      competencyId: competency.id,
      studentId,
    })
      .then((res) => console.log('fetchQuestionAnswers', res))
      .catch((err) => console.log('fetchQuestionAnswers', err.response));
  }, [
    user,
    roadmapId,
    studentId,
    fetchCompetencyActionItemAssessments,
    fetchGlobalQuestions,
    fetchQuestionAnswers,
    unauthorizedErrorHandler,
    competency.stage,
    competency.id,
    competency.last_assessment_status,
  ]);

  const editorRef = useRef(null);

  const replaceBadLinks = (ref) => {
    const intervalId = setInterval(() => {
      if (!!ref.current) {
        clearInterval(intervalId);
        const badLinks = ref.current.querySelectorAll('a:not([href^="http"])');

        badLinks.forEach(function (link) {
          link.setAttribute('href', `http://${link.getAttribute('href')}`);
        });
      }
    }, 10);

    return intervalId;
  };

  useEffect(() => {
    const intervalId = replaceBadLinks(editorRef);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const competencyActionItems = useMemo(
    () =>
      competency &&
      competency.action_item_ids
        .map((aid) => actionItems[aid])
        .filter((ai) => !!ai)
        .sort((a, b) => (a.order > b.order ? 1 : -1)),
    [competency, actionItems],
  );

  const handleAttachmentDelete = useCallback(
    (attachmentId) =>
      deleteCompetencyAttachment({
        roadmapId,
        competency,
        attachmentId,
      }),
    [roadmapId, competency, deleteCompetencyAttachment],
  );

  const handleFileChange = useCallback(() => {
    addCompetencyAttachmentReset();
    setAttachment({
      type: fileEl.current.attachType,
      path: fileEl.current.files[0].name,
    });
  }, [addCompetencyAttachmentReset]);

  const handleAttachConfirmHide = useCallback(() => {
    fileEl.current.value = null;
    setAttachment(null);
  }, []);

  const handleConfirmUpload = useCallback(() => {
    const data = new FormData();
    data.append('attachment', fileEl.current.files[0]);
    data.append('file_category', 'ATTACHMENT');
    let userId = user.id;
    // If Coach user, get student user from url params
    if (user.groups.includes('Coach') || user.groups.includes('Admin')) {
      const params = new URLSearchParams(location.search);
      const _userId = params.get('user') && Number(params.get('user'));
      if (_userId) userId = _userId;
      data.append('user', userId);
    }

    addCompetencyAttachment({
      roadmapId,
      competency,
      data,
    });
  }, [user, roadmapId, competency, addCompetencyAttachment, location]);

  const handleAddFileClick = useCallback(
    (type) => () => {
      fileEl.current.attachType = type;
      fileEl.current.click();
    },
    [],
  );
  return (
    <div className="roadmap-components-competency-page-competency-learn-tab tab-container">
      {competency &&
        competency.description &&
        !!competency.description.trim().length && (
          <Row className="description-container">
            <Col>
              <p
                className="theme-text embed mrm-mt-1 story-desc"
                ref={editorRef}
                dangerouslySetInnerHTML={{
                  __html: competency.description.replace(
                    /href/g,
                    `target="_blank" href`,
                  ),
                }}
              />

              <div className="border mrm-mt-1 d-lg-none" />
            </Col>
          </Row>
        )}

      <div className="d-flex flex-column">
        {user && (
          <SelfReflection
            className="order-3"
            roadmapId={roadmapId}
            competency={competency}
            user={user}
            refetchCompetency={refetchCompetency}
            student={studentId}
          />
        )}

        {competencyActionItems && competencyActionItems.length > 0 && (
          <Fragment>
            {competency.ai_visible && (
              <ActionItems
                className={true ? 'order-1' : 'order-0'}
                roadmapId={roadmapId}
                competency={competency}
                actionItems={competencyActionItems}
                user={user}
                studentId={studentId}
                refetchCompetency={refetchCompetency}
              />
            )}
          </Fragment>
        )}

        {(!fetchGlobalQuestionsPending ||
          !fetchQuestionAnswersPending ||
          globalQuestions ||
          questionAnswers) && (
          <GlobalQuestions
            questions={globalQuestions}
            answers={questionAnswers}
            roadmapId={roadmapId}
            competency={competency}
            user={user}
          />
        )}

        {competency.attachments_visible && competency?.attachments?.length > 0 && (
          <div className="order-3">
            <div className="border d-lg-none" />
            <div className="header">
              <div className="text">
                <h2>Attachments</h2>
              </div>
              {user && user.features.can_attach_files && (
                <Link
                  className={clsx('add-attachment', {
                    'mrm-pt-1':
                      competency.attachments.filter(
                        (att) => att.user_id !== null,
                      ).length === 0,
                  })}
                  onClick={handleAddFileClick('ATTACHMENT')}
                  to={location}
                >
                  <Icon name="plus" size={13} color="#FFFFFF" />
                </Link>
              )}
            </div>

            <AttachmentList
              data={competency.attachments.filter(
                (att) => att.user_id !== null,
              )}
              onDelete={handleAttachmentDelete}
              user={user}
            />
          </div>
        )}
      </div>

      <input type="file" ref={fileEl} onChange={handleFileChange} hidden />
      <AttachmentModal
        show={!!attachment}
        data={attachment}
        onConfirm={handleConfirmUpload}
        onAnotherFile={handleAddFileClick(attachment && attachment.type)}
        pending={addCompetencyAttachmentPending}
        progress={addCompetencyAttachmentProgress}
        error={addCompetencyAttachmentError}
        onHide={handleAttachConfirmHide}
      />
    </div>
  );
}

CompetencyLearnTab.propTypes = {};
CompetencyLearnTab.defaultProps = {};
