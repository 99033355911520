import React, { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SignupBackground from '../../../assets/signup-background.png'
import MobileAboutYourself from './MobileAboutYourself'
import MobileConfirmPassword from './MobileConfirmPassword'
import MobileContactInformation from './MobileContactInformation'
// import MobileInsuranceInformation from './MobileInsuranceInformation'

import { useFetchAuthToken } from '../../../../home/redux/fetchAuthToken'

export default function MobileSignup() {
    const [step, setStep] = React.useState(1)

    const { authToken } = useFetchAuthToken()

    const steps = {
        'confirm-password': 1,
        'about-yourself': 2,
        'verify-your-address': 3,
       /*  'insurance-information': 4, */
    }

    const { stepLabel } = useParams()

    useEffect(() => {
        if (stepLabel) {
            setStep(steps[stepLabel])
        }
    }, [stepLabel])

    return (
        <div className="rpm-signup-mobile">
            <img
                src={SignupBackground}
                alt="background"
                className="background"
            />
            <div className="overlay" />
            {step === 1 && <MobileConfirmPassword setStep={setStep} />}
            {!!authToken && (
                <Fragment>
                    {step === 2 && <MobileAboutYourself setStep={setStep} />}
                    {step === 3 && (
                        <MobileContactInformation setStep={setStep} />
                    )}
                    {/* {step === 4 && (
                        <MobileInsuranceInformation setStep={setStep} />
                    )} */}
                </Fragment>
            )}
        </div>
    )
}
