// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  DashboardPage,
  AdminPage,
  CoachAddUserPage,
  CoachAddCallsPage,
  CoachEditCallsPage,
  UpcomingCallsPage,
  Subscriptions,
  Payouts,
  Clients,
  ConnectToGetPaid,
  OnboardingInvitationPage,
} from './';

export default {
  path: 'dashboard',
  childRoutes: [
    { path: '', component: DashboardPage },
    { path: 'admin', component: AdminPage },
    { path: 'coach', component: AdminPage },
    { path: 'roadmaps', component: AdminPage },
    { path: 'legacy', component: AdminPage },
    { path: 'coach-add-user', component: CoachAddUserPage },
    { path: 'upcoming-calls', component: UpcomingCallsPage },
    { path: 'coach-add-calls', component: CoachAddCallsPage },
    { path: 'coach-edit-calls/:callId', component: CoachEditCallsPage },
    { path: 'payments', component: Subscriptions },
    { path: 'subscriptions', component: Subscriptions },
    { path: 'payouts', component: Payouts },
    { path: 'clients', component: Clients },
    { path: 'connect-to-get-paid', component: ConnectToGetPaid },
    { path: 'onboarding-invitation', component: OnboardingInvitationPage },
  ],
};
