import axios from 'axios';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import {
  HOME_PAYMENT_CHECKOUT_BEGIN,
  HOME_PAYMENT_CHECKOUT_DISMISS_ERROR,
  HOME_PAYMENT_CHECKOUT_FAILURE,
  HOME_PAYMENT_CHECKOUT_SUCCESS,
} from './constants';
import config from '../../../common/config';

export function paymentCheckout(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: HOME_PAYMENT_CHECKOUT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let url = `${config.apiRootUrl}/subscriptions/create-noble-subscription`;
      if (args?.trial_end) url += '-with-trial-end';
      delete args.trial_end;
      const doRequest = axios.post(
        url,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_PAYMENT_CHECKOUT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_PAYMENT_CHECKOUT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissPaymentCheckoutError() {
  return {
    type: HOME_PAYMENT_CHECKOUT_DISMISS_ERROR,
  };
}

export function usePaymentCheckout() {
  const dispatch = useDispatch();

  const { paymentCheckoutPending, paymentCheckoutError } = useSelector(
    (state) => ({
      paymentCheckoutPending: state.home.paymentCheckoutPending,
      paymentCheckoutError: state.home.paymentCheckoutError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(paymentCheckout(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissPaymentCheckoutError());
  }, [dispatch]);

  return {
    paymentCheckout: boundAction,
    paymentCheckoutPending,
    paymentCheckoutError,
    dismissPaymentCheckoutError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_PAYMENT_CHECKOUT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        paymentCheckoutPending: true,
        paymentCheckoutError: null,
      };

    case HOME_PAYMENT_CHECKOUT_SUCCESS:
      // The request is success
      return {
        ...state,
        paymentCheckoutPending: false,
        paymentCheckoutError: null,
      };

    case HOME_PAYMENT_CHECKOUT_FAILURE:
      // The request is failed
      return {
        ...state,
        paymentCheckoutPending: false,
        paymentCheckoutError: action.data.error,
      };

    case HOME_PAYMENT_CHECKOUT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        paymentCheckoutError: null,
      };

    default:
      return state;
  }
}
