import React from 'react';

export default function UsaFlag({ size = 14, style = {} }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clip-path="url(#clip0_4155_2380)">
        <path
          d="M10.7298 1.07852H3.27002C3.49817 0.933333 3.73669 0.798519 3.98557 0.680988C4.03051 0.663704 4.07545 0.639506 4.12039 0.618765C4.15841 0.601481 4.1999 0.580741 4.23792 0.566914C4.28632 0.542716 4.33125 0.525432 4.37965 0.504691C5.17471 0.186667 6.04928 0.00345679 6.96187 0H6.9999C8.37224 0 9.65125 0.397531 10.7298 1.07852Z"
          fill="#B22234"
        />
        <path
          d="M12.0435 2.15713H1.95654C2.34716 1.74232 2.78617 1.38281 3.27358 1.07861H10.7299C11.2138 1.38281 11.6528 1.74232 12.0469 2.15713H12.0435Z"
          fill="white"
        />
        <path
          d="M12.8936 3.22859H1.10596C1.17164 3.12488 1.23732 3.02809 1.30645 2.9313C1.32028 2.91402 1.33065 2.90019 1.33756 2.88637C1.52769 2.63056 1.72818 2.38859 1.95287 2.15698H12.0432C12.3613 2.48538 12.6447 2.84143 12.8936 3.23204V3.22859Z"
          fill="#B22234"
        />
        <path
          d="M13.464 4.30724H0.535645C0.577126 4.21045 0.618607 4.1102 0.667003 4.01341C0.729225 3.88897 0.791447 3.76107 0.857126 3.63662C0.936632 3.49835 1.01614 3.36008 1.10601 3.23218H12.8937C13.1114 3.5744 13.305 3.93045 13.464 4.3107V4.30724Z"
          fill="white"
        />
        <path
          d="M13.8132 5.38565H0.186523C0.2764 5.01231 0.390474 4.65281 0.535659 4.30713H13.4641C13.6092 4.65281 13.7268 5.01231 13.8132 5.38565Z"
          fill="#B22234"
        />
        <path
          d="M13.976 6.46422H0.0244141C0.0244141 6.41237 0.0278709 6.36052 0.0382412 6.31558C0.0382412 6.25336 0.0486116 6.19805 0.0555252 6.14274C0.0555252 6.11854 0.058982 6.09435 0.0658955 6.0736C0.0693523 6.0183 0.0762659 5.96299 0.0831795 5.91114C0.110834 5.73484 0.145402 5.562 0.186883 5.38916H13.8135C13.8965 5.7383 13.9553 6.0978 13.976 6.46768V6.46422Z"
          fill="white"
        />
        <path
          d="M14 6.99991C14 7.18312 13.9965 7.35942 13.9758 7.53572H0.0241975C0.00691358 7.35942 0 7.17967 0 6.99991C0 6.82016 0.00345679 6.64041 0.0241975 6.46411H13.9758C13.9931 6.64041 14 6.82016 14 6.99991Z"
          fill="#B22234"
        />
        <path
          d="M13.976 7.53589C13.9518 7.90577 13.8965 8.26527 13.8135 8.61441H0.186883C0.10392 8.26527 0.0451548 7.90577 0.0244141 7.53589H13.976Z"
          fill="white"
        />
        <path
          d="M13.8132 8.61426C13.7233 8.98759 13.6092 9.3471 13.4641 9.69278H0.535659C0.390474 9.3471 0.272943 8.98759 0.186523 8.61426H13.8132Z"
          fill="#B22234"
        />
        <path
          d="M13.464 9.69287C13.305 10.0697 13.1149 10.4292 12.8937 10.7714H1.10601C0.888237 10.4292 0.694657 10.0731 0.535645 9.69287H13.464Z"
          fill="white"
        />
        <path
          d="M12.8936 10.7712C12.6447 11.1584 12.3613 11.5179 12.0432 11.8463H1.95633C1.6383 11.5179 1.35485 11.1619 1.10596 10.7712H12.8936Z"
          fill="#B22234"
        />
        <path
          d="M12.0435 11.843C11.6529 12.2578 11.2139 12.6173 10.7265 12.9215H3.27016C2.78621 12.6173 2.3472 12.2578 1.95312 11.843H12.0435Z"
          fill="white"
        />
        <path
          d="M10.7298 12.9214C9.65125 13.6024 8.37224 13.9999 6.9999 13.9999C5.62755 13.9999 4.34854 13.6024 3.27002 12.9214H10.7298Z"
          fill="#B22234"
        />
        <path
          d="M6.96198 0V7.5358H0.0241975C0.00691358 7.35951 0 7.17975 0 7C0 6.82025 0.00345679 6.64049 0.0241975 6.4642C0.0241975 6.41235 0.0276543 6.36049 0.0380247 6.31556C0.0380247 6.25333 0.0483951 6.19802 0.0553086 6.14272C0.0553086 6.11852 0.0587654 6.09432 0.065679 6.07358C0.0691358 6.01827 0.0760494 5.96296 0.082963 5.91111C0.110617 5.73481 0.145185 5.56198 0.186667 5.38914C0.276543 5.0158 0.390617 4.6563 0.535802 4.31062C0.577284 4.21383 0.618765 4.11358 0.66716 4.01679C0.729383 3.89235 0.791605 3.76444 0.857284 3.64C0.93679 3.50173 1.0163 3.36346 1.10617 3.23556C1.17185 3.13185 1.23753 3.03506 1.30667 2.93827C1.32049 2.92099 1.33086 2.90716 1.33778 2.89333C1.5279 2.63753 1.7284 2.39556 1.95309 2.16395C2.3437 1.74914 2.78272 1.38963 3.27012 1.08543C3.49827 0.940247 3.73679 0.805432 3.98568 0.687901C4.03062 0.670617 4.07556 0.64642 4.12049 0.625679C4.15852 0.608395 4.2 0.587654 4.23802 0.573827C4.28642 0.54963 4.33136 0.532346 4.37975 0.511605C5.17481 0.186667 6.04938 0.00345679 6.96198 0Z"
          fill="#3C3B6E"
        />
        <path
          d="M3.42894 1.07861L3.52573 1.37244H3.83684L3.58449 1.55911L3.68128 1.85639L3.42894 1.66972L3.17659 1.85639L3.26992 1.55911L3.01758 1.37244H3.33215L3.42894 1.07861Z"
          fill="white"
        />
        <path
          d="M5.20237 1.07861L5.29916 1.37244H5.61027L5.35793 1.55911L5.45472 1.85639L5.20237 1.66972L4.95003 1.85639L5.04336 1.55911L4.79102 1.37244H5.10558L5.20237 1.07861Z"
          fill="white"
        />
        <path
          d="M1.86314 3.22858L1.90808 3.36339L1.72142 3.22858L1.65574 3.18018L1.59006 3.22858L1.39994 3.36339L1.44833 3.22858L1.50018 3.06957L1.31006 2.93129C1.32389 2.91401 1.33426 2.90018 1.34117 2.88636H1.55895L1.65574 2.59253L1.74907 2.88636H2.06018L1.80784 3.07302L1.85969 3.23204L1.86314 3.22858Z"
          fill="white"
        />
        <path
          d="M3.42894 2.58911L3.52573 2.8864H3.83684L3.58449 3.06961L3.68128 3.36689L3.42894 3.18368L3.17659 3.36689L3.26992 3.06961L3.01758 2.8864H3.33215L3.42894 2.58911Z"
          fill="white"
        />
        <path
          d="M5.20237 2.58911L5.29916 2.8864H5.61027L5.35793 3.06961L5.45472 3.36689L5.20237 3.18368L4.95003 3.36689L5.04336 3.06961L4.79102 2.8864H5.10558L5.20237 2.58911Z"
          fill="white"
        />
        <path
          d="M1.65599 4.09985L1.75278 4.39714H2.06389L1.81154 4.58035L1.90833 4.87763L1.65599 4.69442L1.40364 4.87763L1.49697 4.58035L1.24463 4.39714H1.5592L1.65599 4.09985Z"
          fill="white"
        />
        <path
          d="M3.42894 4.09985L3.52573 4.39714H3.83684L3.58449 4.58035L3.68128 4.87763L3.42894 4.69442L3.17659 4.87763L3.26992 4.58035L3.01758 4.39714H3.33215L3.42894 4.09985Z"
          fill="white"
        />
        <path
          d="M5.20237 4.09985L5.29916 4.39714H5.61027L5.35793 4.58035L5.45472 4.87763L5.20237 4.69442L4.95003 4.87763L5.04336 4.58035L4.79102 4.39714H5.10558L5.20237 4.09985Z"
          fill="white"
        />
        <path
          d="M0.0553699 6.14282L0.134876 6.39171L0.0380859 6.31566C0.0380859 6.25344 0.0484563 6.19813 0.0553699 6.14282Z"
          fill="white"
        />
        <path
          d="M0.290609 5.90771L0.065918 6.07018C0.0693748 6.01488 0.0762883 5.95957 0.0832019 5.90771H0.290609Z"
          fill="white"
        />
        <path
          d="M1.65599 5.61035L1.75278 5.90764H2.06389L1.81154 6.09085L1.90833 6.38813L1.65599 6.20492L1.40364 6.38813L1.49697 6.09085L1.24463 5.90764H1.5592L1.65599 5.61035Z"
          fill="white"
        />
        <path
          d="M3.42894 5.61035L3.52573 5.90764H3.83684L3.58449 6.09085L3.68128 6.38813L3.42894 6.20492L3.17659 6.38813L3.26992 6.09085L3.01758 5.90764H3.33215L3.42894 5.61035Z"
          fill="white"
        />
        <path
          d="M5.20237 5.61035L5.29916 5.90764H5.61027L5.35793 6.09085L5.45472 6.38813L5.20237 6.20492L4.95003 6.38813L5.04336 6.09085L4.79102 5.90764H5.10558L5.20237 5.61035Z"
          fill="white"
        />
        <path
          d="M4.55959 1.07843L4.56996 1.10262L4.53885 1.07843L4.32107 0.919415L4.09638 1.07843L4.06527 1.10262L4.07564 1.07843L4.16552 0.808797L3.99268 0.684353C4.03761 0.667069 4.08255 0.642871 4.12749 0.622131H4.22428L4.24156 0.570279C4.28996 0.546081 4.3349 0.528797 4.38329 0.508057L4.4144 0.618674H4.72552L4.47317 0.805341L4.56305 1.07497L4.55959 1.07843Z"
          fill="white"
        />
        <path
          d="M6.08759 0.324951L6.18438 0.622235H6.49549L6.24314 0.805445L6.33993 1.10273L6.08759 0.919519L5.83524 1.10273L5.93203 0.805445L5.67969 0.622235H5.9908L6.08759 0.324951Z"
          fill="white"
        />
        <path
          d="M2.54071 1.83545L2.6375 2.13273H2.94861L2.69627 2.31594L2.79306 2.61323L2.54071 2.43002L2.28837 2.61323L2.38516 2.31594L2.13281 2.13273H2.44392L2.54071 1.83545Z"
          fill="white"
        />
        <path
          d="M4.31415 1.83545L4.41094 2.13273H4.72205L4.46971 2.31594L4.5665 2.61323L4.31415 2.43002L4.06181 2.61323L4.1586 2.31594L3.90625 2.13273H4.21736L4.31415 1.83545Z"
          fill="white"
        />
        <path
          d="M6.08759 1.83545L6.18438 2.13273H6.49549L6.24314 2.31594L6.33993 2.61323L6.08759 2.43002L5.83524 2.61323L5.93203 2.31594L5.67969 2.13273H5.9908L6.08759 1.83545Z"
          fill="white"
        />
        <path
          d="M0.922834 3.82664L1.01962 4.12392L0.767278 3.94071L0.663574 4.00985C0.725796 3.8854 0.788019 3.7575 0.853698 3.63306V3.64343H1.17172L0.919377 3.82664H0.922834Z"
          fill="white"
        />
        <path
          d="M2.54071 3.34619L2.6375 3.64348H2.94861L2.69627 3.82668L2.79306 4.12397L2.54071 3.94076L2.28837 4.12397L2.38516 3.82668L2.13281 3.64348H2.44392L2.54071 3.34619Z"
          fill="white"
        />
        <path
          d="M4.31415 3.34619L4.41094 3.64348H4.72205L4.46971 3.82668L4.5665 4.12397L4.31415 3.94076L4.06181 4.12397L4.1586 3.82668L3.90625 3.64348H4.21736L4.31415 3.34619Z"
          fill="white"
        />
        <path
          d="M6.08759 3.34619L6.18438 3.64348H6.49549L6.24314 3.82668L6.33993 4.12397L6.08759 3.94076L5.83524 4.12397L5.93203 3.82668L5.67969 3.64348H5.9908L6.08759 3.34619Z"
          fill="white"
        />
        <path
          d="M0.767276 4.86035L0.864066 5.15764H1.17518L0.922832 5.34085L1.01962 5.63813L0.767276 5.45492L0.514931 5.63813L0.611721 5.34085L0.359375 5.15764H0.670486L0.767276 4.86035Z"
          fill="white"
        />
        <path
          d="M2.54071 4.86035L2.6375 5.15764H2.94861L2.69627 5.34085L2.79306 5.63813L2.54071 5.45492L2.28837 5.63813L2.38516 5.34085L2.13281 5.15764H2.44392L2.54071 4.86035Z"
          fill="white"
        />
        <path
          d="M4.31415 4.86035L4.41094 5.15764H4.72205L4.46971 5.34085L4.5665 5.63813L4.31415 5.45492L4.06181 5.63813L4.1586 5.34085L3.90625 5.15764H4.21736L4.31415 4.86035Z"
          fill="white"
        />
        <path
          d="M6.08759 4.86035L6.18438 5.15764H6.49549L6.24314 5.34085L6.33993 5.63813L6.08759 5.45492L5.83524 5.63813L5.93203 5.34085L5.67969 5.15764H5.9908L6.08759 4.86035Z"
          fill="white"
        />
        <path
          d="M0.767276 6.35352L0.864066 6.6508H1.17518L0.922832 6.83401L1.01962 7.13129L0.767276 6.94808L0.514931 7.13129L0.611721 6.83401L0.359375 6.6508H0.670486L0.767276 6.35352Z"
          fill="white"
        />
        <path
          d="M2.54071 6.35352L2.6375 6.6508H2.94861L2.69627 6.83401L2.79306 7.13129L2.54071 6.94808L2.28837 7.13129L2.38516 6.83401L2.13281 6.6508H2.44392L2.54071 6.35352Z"
          fill="white"
        />
        <path
          d="M4.31415 6.35352L4.41094 6.6508H4.72205L4.46971 6.83401L4.5665 7.13129L4.31415 6.94808L4.06181 7.13129L4.1586 6.83401L3.90625 6.6508H4.21736L4.31415 6.35352Z"
          fill="white"
        />
        <path
          d="M6.08759 6.35352L6.18438 6.6508H6.49549L6.24314 6.83401L6.33993 7.13129L6.08759 6.94808L5.83524 7.13129L5.93203 6.83401L5.67969 6.6508H5.9908L6.08759 6.35352Z"
          fill="white"
        />
      </g>
      <circle cx="7" cy="7" r="6.75" stroke="#D3D0CC" stroke-width="0.5" />
      <defs>
        <clipPath id="clip0_4155_2380">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
