import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import config from '../../common/config';
import { useHasActiveSubcription } from '../../common/permissions';
import { useWindowSize } from '../../common/windowsSize';
import Icon from '../common/components/Icon';
import { useGetProductByStripePriceId } from '../user/redux/hooks';
import CheckoutForm from './components/CheckoutForm';
import chat from './images/chat_image.png';
// import PropTypes from 'prop-types';
import logo from './images/logo.png';
import people from './images/people.png';

const stripePromise = loadStripe(config.publishableKey);

export default function SubscriptionInvitationCheckout() {
  const { isActive } = useHasActiveSubcription();

  let redirect = isActive;

  const { price_id } = useParams();

  const clientSecret =
    'pi_3Jz7R2GvgjKLpHZZ0N4AU8ZI_secret_9L5jftnEOimi0Xz2QTUoNHbfH';

  const appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      fontFamily: 'Poppins, sans-serif',
      colorText: '#6D786E',
      borderRadius: '10px',
      spacingUnit: '2.8px',
    },
  };
  const options = {
    appearance,
    clientSecret,
  };

  const { getProductByStripePriceId } = useGetProductByStripePriceId();

  const [productInfo, setProductInfo] = useState({});

  useEffect(() => {
    getProductByStripePriceId({ priceId: price_id })
      .then((res) => {
        setProductInfo(res.data);
      })
      .catch((err) => console.log(err));
  }, [price_id, getProductByStripePriceId, setProductInfo]);

  const history = useHistory();
  useEffect(() => {
    if (redirect) {
      history.push('/');
    }
  }, [redirect, history]);

  const size = useWindowSize();

  return (
    <div className="home-subscription-invitation-checkout">
      <div
        className="login_sidebar mrm-mx-10"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          minHeight: '100vh',
          backgroundImage: size.width < 768 ? `url(${people})` : '',
        }}
      >
        <Link to="/">
          <img
            src={logo}
            className="logo d-none d-lg-block"
            alt="company-logo"
          />
        </Link>
        <div className="login_form">
          <div className="subscription-noble-intro">
            <h3 className="welcome">Subscription</h3>
            <p>
              Join the thousands of users who are receiving more comprehensive
              and connected care.
            </p>
          </div>

          <div className="form_c">
            <div className="subscription">
              {' '}
              <p>{productInfo?.product?.name}</p>
              <p className="price"> ${productInfo?.unit_amount / 100}/mo </p>
            </div>
            <hr />
            <div className="credit-cards">
              <p>Credit Card</p>
              <div className="card-icons">
                <Icon name="visa" size={30} />
                <Icon name="amex" />
                <Icon name="mastercard" />
                <Icon name="stripe" />
                <Icon name="paypal" />
              </div>
            </div>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm productInfo={productInfo} />
            </Elements>
          </div>
        </div>
      </div>
      <div className="s-content">
        <div className="login_content">
          <img src={chat} className="illustration" alt="Two women chatting" />
          <h1>Using technology to enhance, not replace, human connection.</h1>
          <p>
            Your clients will gain access to automated Roadmaps, assessments,
            and in-app messaging – tools that will help you and your clients
            gain a better understanding of their progress between sessions, so
            you can tailor your one-on-one sessions to their needs more
            effectively.
          </p>
        </div>
      </div>
    </div>
  );
}

SubscriptionInvitationCheckout.propTypes = {};
SubscriptionInvitationCheckout.defaultProps = {};
