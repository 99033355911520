export const provider_dashboard = {
    add_staff: 'Add Staff',
    first_name: 'First Name',
    last_name: 'Last Name',
    suffix: 'Suffix',
    email: 'Email',
    phone_number: 'Phone Number',
    provider_npi: 'Provider NPI',
    access_level: 'Access Level',
    admin: 'Admin',
    coach: 'Coach',
    user: 'User',
    add_staff_member: 'Add Staff Member',
    cancel: 'Cancel',
    is_required: 'is required',
    clients: 'Clients',
    search: 'Search',
    eligible: 'Eligible',
    ineligible: 'Ineligible',
    alert_type: 'Alert Type',
    mood: 'Mood',
    assessment: 'Assessment',
    eligibility: 'Eligibility',
    refine: 'Refine',
    apply_filters: 'Apply Filters',
    reset_filters: 'Reset Filters',
    columns: {
        name: 'Name',
        clients: 'Clients',
        status: 'Status',
        diagnosis: 'Diagnosis',
        alerts: 'Alerts',
    },
    pending: 'Pending',
    resend_invitation: 'Resend Invitation',
    alerts: 'Alerts',
    view_all: 'View All',
    no_alerts: 'No Alerts',
    compliance_score: 'Compliance Score',
    calls: 'Calls',
    has_call_scheduled: 'Has Call Scheduled',
    mood: 'Mood',
    assessment: 'Assessment',
}
