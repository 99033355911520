import React, { useEffect, useState } from 'react';
import { DesktopHeader } from '../common';
import Icon from '../common/components/Icon';
import TabSelector from './components/TabSelector';
import Sidebar from './Sidebar';
import { useCheckStatusStripeAccountLink } from '../home/redux/checkStatusStripeAccountLink';

export default function Payouts() {
  const [stripeStatus, setStripeStatus] = useState(false);
  const { checkStatusStripeAccountLink } = useCheckStatusStripeAccountLink();

  useEffect(() => {
    // if coach has completed the process of connecting to Stripe
    // then do not show connect to stripe banner
    const checkingStatus = async () => {
      await checkStatusStripeAccountLink()
        .then((res) => {
          console.log('checkingStatus', res.data.data.Status);
          if (res.data.data.Status === true) {
            setStripeStatus(true);
          }
        })
        .catch((err) => {
          console.log('checkingStatus', err);
        });
    };
    checkingStatus();
  }, [checkStatusStripeAccountLink]);

  return (
    <div className="dashboard-payouts">
      <DesktopHeader />

      <Sidebar />
      <div className="tab">
        <TabSelector />
      </div>
      {stripeStatus === false && (
        <div
          className="banner"
          style={{
            backgroundImage: 'url("https://i.ibb.co/PFpt8w6/money-man.png")',
            backgroundPosition: 'right bottom',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <h1>Start Getting Paid</h1>
          <p>
            Using Stripe Connect we can safely and securely transfer your money
            automatically into your account. And don’t forget, we don’t paid
            until you do.
          </p>
          <button>
            <span className="button-icon">
              <svg
                width="14"
                height="20"
                viewBox="0 0 14 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9213 3.6595C12.9213 4.37872 12.9078 5.09885 12.9267 5.81784C12.9376 6.23423 12.8288 6.30994 12.4459 6.12923C10.7962 5.35065 9.07115 4.88816 7.23492 4.93676C6.95359 4.94433 6.6751 4.96273 6.40563 5.05456C5.58164 5.33468 5.39048 6.2496 6.03772 6.84603C6.32196 7.10805 6.65853 7.28793 7.01749 7.42049C7.9315 7.75815 8.85469 8.07158 9.76086 8.42945C10.8432 8.85712 11.85 9.41281 12.6704 10.28C14.7279 12.4552 14.7529 17.7661 10.1997 19.3306C6.9371 20.451 3.73302 20.0816 0.579183 18.8369C0.324201 18.7363 0.228357 18.5614 0.228954 18.2989C0.230895 16.8934 0.230348 15.4879 0.227312 14.0824C0.226043 13.6812 0.398098 13.6844 0.689282 13.8372C2.0466 14.5499 3.47811 15.0434 4.98875 15.2896C5.67397 15.4008 6.37094 15.4167 7.06041 15.337C7.72078 15.2613 8.1661 14.906 8.28456 14.4043C8.41384 13.8562 8.18379 13.3672 7.5897 12.981C6.78945 12.4612 5.87611 12.2062 4.99786 11.8709C4.3186 11.6114 3.64614 11.3372 2.99644 11.0063C-0.197708 9.3795 -0.520541 6.23741 0.559924 3.71749C1.40019 1.75781 3.02659 0.75476 4.99718 0.299002C7.54507 -0.290229 10.042 0.0383414 12.4944 0.874378C12.8096 0.981883 12.9355 1.1632 12.9273 1.50131C12.9087 2.22023 12.9211 2.94005 12.9213 3.6595Z"
                  fill="white"
                />
              </svg>{' '}
            </span>
            Start with Stripe
          </button>
          <a href="https://stripe.com/">Learn more about stripe</a>
        </div>
      )}

      <div className="main-content">
        <p>Earnings</p>
        <div className="card-grid">
          <div className="card">
            <div className="icon">
              <Icon name="wallet" size={33} />
            </div>
            <div className="title">
              <h2>$3,000.00</h2>
              <p>This month earnings</p>
            </div>
          </div>
          <div className="card">
            <div className="icon">
              <Icon name="wallet" size={33} />
            </div>
            <div className="title">
              <h2>$3,000.00</h2>
              <p>This month earnings</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Payouts.propTypes = {};
Payouts.defaultProps = {};
