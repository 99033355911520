import React from 'react';

function useEditGroup({
  editGroupChat,
  selectedConversation,
  setSelectedConversation,
  groupName,
  user,
}) {
  const editGroup = React.useCallback(
    async ({ participants }) => {
      editGroupChat({
        id: selectedConversation?.id,
        name: groupName,
        participants: participants,
        // participants: participants.concat(user.id),
      }).then((editChat) => {
        window.location.reload();
        setSelectedConversation((c) =>
          Object.assign({}, c, {
            name: editChat.groupName || groupName,
            participants: editChat.participants,
          }),
        );
      });
    },
    [groupName, selectedConversation, user],
  );

  return editGroup;
}

export default useEditGroup;
