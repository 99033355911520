import React, { useState, useCallback, Fragment } from 'react'
import Icon from '../../../../common/components/Icon'
import { useCreateGroupChat } from '../../../redux/createGroupChat'

import dayjs from 'dayjs'

export default function CreateConversation({
    setCreateNewConversation,
    selectedConversation,
    setSelectedConversation,
    namingGroup,
    setNamingGroup,
    setSelectedParticipants,
    selectedParticipants,
    search,
    setSearch,
    user,
    recipients,
}) {
    const { createGroupChat } = useCreateGroupChat()

    const [groupName, setGroupName] = useState('')

    const createNewGroup = useCallback(async () => {
        createGroupChat({
            name: groupName,
            created: dayjs().valueOf(),
            participants: selectedParticipants
                .map(participant => {
                    return participant.id
                })
                .concat(user.id),
        }).then(newChat => {
            let conversation = {
                id: newChat.id,
                type: 'group',
                name: newChat.groupName || groupName,
                participants: newChat.participants,
                created: newChat.created,
            }

            setCreateNewConversation(null)
            setSelectedConversation(conversation)
        })
    }, [createGroupChat, groupName, selectedParticipants, user])

    return (
        <div className="new-message">
            <div className="message-header">
                <button
                    onClick={() => {
                        setCreateNewConversation(false)
                        setSelectedConversation(null)
                    }}
                >
                    <Icon name="chevronLeft" size={12} />
                </button>
                {!namingGroup ? (
                    <p>
                        New{' '}
                        {selectedConversation.type === 'group'
                            ? 'Group'
                            : 'Message'}
                    </p>
                ) : (
                    <p>Name this group</p>
                )}

                {selectedConversation.type === 'group' && !namingGroup ? (
                    <button
                        style={{
                            transform: 'rotate(180deg)',
                        }}
                        onClick={() => {
                            if (selectedParticipants.length > 0) {
                                setNamingGroup(true)
                            }
                        }}
                    >
                        <Icon name="chevronLeft" size={12} />
                    </button>
                ) : (
                    <div>{/** empty */}</div>
                )}
            </div>
            {!namingGroup && (
                <Fragment>
                    <div className="input-container">
                        <p>
                            {selectedConversation.type === 'group'
                                ? 'Select group members'
                                : 'To'}{' '}
                        </p>
                        <input
                            type="text"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder="Search"
                        />
                    </div>
                    {selectedParticipants.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                overflowX: 'auto',
                                maxWidth: '35vw',
                                padding: '0 1rem .5rem 1rem',
                                cursor: 'pointer',
                            }}
                        >
                            {selectedParticipants.map((participant, idx) => {
                                return (
                                    <img
                                        key={idx}
                                        onClick={() => {
                                            if (
                                                participant.username !==
                                                user.username
                                            ) {
                                                setSelectedParticipants([
                                                    ...selectedParticipants.filter(
                                                        p =>
                                                            p.username !==
                                                            participant.username
                                                    ),
                                                ])
                                            }
                                        }}
                                        src={
                                            participant.photo
                                                ? participant.photo
                                                : `https://ui-avatars.com/api/?name=${participant
                                                      .first_name[0] +
                                                      participant
                                                          .last_name[0]}&background=718371&color=fff&size=128`
                                        }
                                        style={{
                                            width: '2rem',
                                            height: '2rem',
                                            objectFit: 'contain',
                                            borderRadius: '.3rem',
                                        }}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {selectedConversation.type === 'group' && (
                        <div className="recipients">
                            {recipients &&
                                recipients
                                    .filter(
                                        // filter out selectedParticipants
                                        recipient =>
                                            !selectedParticipants.find(
                                                participant =>
                                                    participant.username ===
                                                    recipient.username
                                            )
                                    )
                                    .map((recipient, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                className="recipient"
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '100%',
                                                }}
                                                onClick={() => {
                                                    setSelectedParticipants([
                                                        ...selectedParticipants,
                                                        recipient,
                                                    ])
                                                }}
                                            >
                                                <img
                                                    src={
                                                        recipient.photo
                                                            ? recipient.photo
                                                            : `https://ui-avatars.com/api/?name=${recipient
                                                                  .first_name[0] +
                                                                  recipient
                                                                      .last_name[0]}&background=718371&color=fff&size=128`
                                                    }
                                                />
                                                {recipient.username ===
                                                user.username ? (
                                                    <h3 className="name">
                                                        You
                                                    </h3>
                                                ) : (
                                                    <h3 className="name">
                                                        {recipient.first_name}{' '}
                                                        {recipient.last_name}
                                                    </h3>
                                                )}

                                                <p className="email">
                                                    {recipient.email}
                                                </p>
                                            </div>
                                        )
                                    })}
                        </div>
                    )}
                </Fragment>
            )}

            {namingGroup && (
                <div className="name-group-container">
                    <input
                        type="text"
                        value={groupName}
                        placeholder="Group name"
                        onChange={e => setGroupName(e.target.value)}
                    />
                    <button
                        onClick={() => {
                            createNewGroup()
                            setNamingGroup(false)
                        }}
                    >
                        Save
                    </button>
                </div>
            )}
            {selectedConversation.type === 'p2p' && (
                <div className="recipients">
                    {recipients &&
                        recipients.map((recipient, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="recipient"
                                    style={{ cursor: 'pointer', width: '100%' }}
                                    onClick={() => {
                                        setSelectedConversation({
                                            id: recipient.username,
                                            timestamp: Date.now(),
                                            user: {
                                                first_name:
                                                    recipient.first_name,
                                                last_name: recipient.last_name,
                                                photo: recipient.photo,
                                                username: recipient.username,
                                            },
                                            type: 'p2p',
                                        })
                                        setCreateNewConversation(false)
                                    }}
                                >
                                    <img
                                        src={
                                            recipient.photo
                                                ? recipient.photo
                                                : `https://ui-avatars.com/api/?name=${recipient
                                                      .first_name[0] +
                                                      recipient
                                                          .last_name[0]}&background=343434&color=fff&size=128`
                                        }
                                    />
                                    
                                    <h3 className="name">
                                        {recipient.first_name}{' '}
                                        {recipient?.last_name} {recipient.username === user.username && '(You)'}
                                    </h3>
                                    <p className="email">{recipient.email}</p>
                                </div>
                            )
                        })}
                </div>
            )}
        </div>
    )
}
