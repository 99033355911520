import { useSendPushNotifications } from '../redux/sendPushNotifications';

export default function useOnSendPeerMessage({
  selectedConversation,
  message,
  setSelectedMessages,
  unauthorizedErrorHandler,
  createPeerToPeerMessage,
  user,
}) {
  const { sendPushNotifications } = useSendPushNotifications();

  function onSendPeerMessage() {
    const peerId = selectedConversation.user.username;

    setSelectedMessages((prevSelectedMessages) => {
      return [
        ...prevSelectedMessages,
        {
          user: {
            id: user.id,
            username: user.username,
            photo: user.photo,
            first_name: user.first_name,
            last_name: user.last_name,
          },
          timestamp: Date.now(),
          text: message,
        },
      ];
    });

    createPeerToPeerMessage({
      receiver_username: peerId,
      text: message,
      timestamp: new Date().valueOf(),
    })
      .then((newMsg) => {
       /*  sendPushNotifications({
          user,
          message: newMsg.text,
          counterpartId: selectedConversation.user.username,
          type: 'chat',
          counterpart:
            selectedConversation.user.first_name +
            ' ' +
            selectedConversation.user.last_name,
        }).catch((err) => {
          console.log(err);
        }); */

        const jsonMessagePayload = {
          action: 'new-message',
          user: newMsg.sender,
          timestamp: newMsg.timestamp,
          text: newMsg.text,
          id: newMsg.message_id,
        };
        setSelectedMessages((prevSelectedMessages) => {
          if (prevSelectedMessages && prevSelectedMessages.length > 0) {
            return [...prevSelectedMessages?.slice(0, -1), jsonMessagePayload];
          }
          return [jsonMessagePayload];
        });
      })
      .catch(unauthorizedErrorHandler);
  }

  return { onSendPeerMessage };
}
