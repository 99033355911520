import React, { useEffect } from 'react';
import Icon from '../../common/components/Icon';
import { useCreateRefundForClient } from '../../user/redux/createRefundForClient';
import { useGetSubscriptionByCoachAndUser } from '../../user/redux/getSubscriptionByCoachAndUser';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../common';

export default function RefundsForm({ userId, cancel }) {
  const { getSubscriptionByCoachAndUser } = useGetSubscriptionByCoachAndUser();
  const { createRefundForClient } = useCreateRefundForClient();
  const history = useHistory();
  const [comments, setComments] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    const getSubscription = async () => {
      const data = await getSubscriptionByCoachAndUser({
        id: userId,
      }).then((res) => {
        setAmount(res.data.plan.unit_amount / 100);
      });
      console.log(data);
    };
    getSubscription();
  }, [getSubscriptionByCoachAndUser, userId]);

  const createRefund = async () => {
    createRefundForClient({
      user_pk: userId,
      reason: reason,
      comments: comments,
    })
      .then((res) => {
        console.log(res);
        window.location.reload(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    createRefund();
  };
  return (
    <form className="reasons-container" onSubmit={handleSubmit}>
      <div className="refund-item">
        <p>Refund</p>
        <input value={`Last Payment $${amount}`} disabled />
      </div>

      <div className="refund-item">
        <p>Reason</p>
        <select
          onChange={(e) => {
            setReason(e.target[e.target.selectedIndex].text);
          }}
        >
          <option value="3">Requested by customer</option>
          <option value="1">Not satisfied with service</option>
          <option value="2">Not satisfied with product</option>
        </select>
      </div>

      <Icon name="chevronLeft" color="#343434" size={11} className="arrow" />
      <div className="refund-item explanation">
        <p>Reason</p>
        <textarea
          placeholder="Add more details about refund"
          onChange={(e) => {
            setComments(e.target.value);
          }}
          required
        />
      </div>
      <hr />
      <div className="warning">
        <Icon name="info" />
        <p>Refunds take 5-10 days to appear on a customers statement </p>
      </div>

      <hr />
      {isLoading ? (
        <Loader position="static" />
      ) : (
        <button className="refund" type="submit">
          Refund
        </button>
      )}

      <button className="cancel d-none d-lg-block" onClick={cancel}>
        <p>Cancel</p>
      </button>
      <button className="cancel d-lg-none" onClick={() => history.push('/')}>
        <p>Cancel</p>
      </button>
    </form>
  );
}
