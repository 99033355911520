import { useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
    NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_BEGIN,
    NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR,
    NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_FAILURE,
    NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function fetchNotificationsSettings(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.get(
                `${config.notificationSettingsApiHost}/notificationsettings/${args.userId}`,
                {
                    ...createAxiosConfigWithAuth(getState()),
                    headers: {
                        origin: 'https://api.noble.health',
                    },
                }
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissFetchNotificationsSettingsError() {
    return {
        type: NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR,
    }
}

export function useFetchNotificationsSettings() {
    const dispatch = useDispatch()

    const {
        notificationsSettings,
        fetchNotificationsSettingsPending,
        fetchNotificationsSettingsError,
    } = useSelector(
        state => ({
            fetchNotificationsSettingsPending:
                state.notifications.fetchNotificationsSettingsPending,
            fetchNotificationsSettingsError:
                state.notifications.fetchNotificationsSettingsError,
            notificationsSettings: state.notifications.notificationsSettings,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(fetchNotificationsSettings(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissFetchNotificationsSettingsError())
    }, [dispatch])

    return {
        notificationsSettings,
        fetchNotificationsSettings: boundAction,
        fetchNotificationsSettingsPending,
        fetchNotificationsSettingsError,
        dismissFetchNotificationsSettingsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                fetchNotificationsSettingsPending: true,
                fetchNotificationsSettingsError: null,
            }

        case NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS:
            // The request is success
            return {
                ...state,
                fetchNotificationsSettingsPending: false,
                fetchNotificationsSettingsError: null,
                notificationsSettings: action.data.notificationSettings.optOuts,
            }

        case NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_FAILURE:
            // The request is failed
            return {
                ...state,
                fetchNotificationsSettingsPending: false,
                fetchNotificationsSettingsError: action.data.error,
            }

        case NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                fetchNotificationsSettingsError: null,
            }

        default:
            return state
    }
}
