import { useEffect, useState } from 'react'
import { useFetchAssessments } from '../../../../redux/fetchAssessments'

export default function useGetDropdownAssessments() {
    const { fetchAssessments } = useFetchAssessments()

    const [assessments, setAssessments] = useState([])

    useEffect(() => {
        fetchAssessments()
            .then(res => {
                console.log(res)
                setAssessments(
                    res.results
                )
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return { assessments }
}
