import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from '../../../../common/useQuery';
import AddResponseModal from '../../ActionItemInfo/AddResponseModal';
import AnswerModal from '../../AnswerModal';

export default function GlobalQuestions({
  questions,
  answers,
  roadmapId,
  competency,
  user,
}) {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const [showAnswerModal, setShowAnswerModal] = useState(false);

  useEffect(() => {
    if (history.location.state?.from === 'competency') {
      setShowAnswerModal(true);
    }
  }, [history]);

  const [showAddResponseModal, setShowAddResponseModal] = useState(false);

  useEffect(() => {
    console.log(location);
    if (
      location.state?.fromAddResponse === true ||
      location.pathname.endsWith('/note/')
    ) {
      setShowAddResponseModal(true);
      console.log('showAddResponseModal', showAddResponseModal);
    }
  }, []);

  const handleQuestionClick = useCallback(
    (id) => () => {
      const answer = answers.find((x) => x.parent === id);
      const url = `/roadmap/${roadmapId}/stage/${competency.stage}/competency/${
        competency.id
      }/question/${id}/answer/${answer && answer.id}`;

      // replace params with history.pushState to avoid page reload
      history.push(url, { from: 'competency' });
    },
    [roadmapId, competency, answers, history, location],
  );

  const canAnswerQuestion = useCallback(() => {
    if (user.groups.includes('Admin') || user.groups.includes('Coach')) {
      if (query) {
        const queryUser = query.get('user');
        if (queryUser) {
          return Number(queryUser) === user.id;
        }
      }
    }
    return user.groups.includes('User');
  }, [user, query]);

  const [questionId, setQuestionId] = useState(null);
  const [answer, setAnswer] = useState(null);

  return (
    <Row className="order-2">
      <AddResponseModal
        show={showAddResponseModal}
        onHide={() => {
          history.push(
            `/roadmap/${roadmapId}/stage/${competency.stage}/competency/${competency.id}`,
            { state: { fromActionItem: false } },
          );
          setShowAddResponseModal(false);
        }}
      />
      {questions && questions.length > 0 && answers && (
        <Col>
          <div className="border d-lg-none" />
          <h2 className="question-title">
            Questions{' '}
            <span>
              ({answers?.length || 0} of {questions.length} answered)
            </span>
          </h2>
          <div className="questions-section mb-3">
            {questions.map((item) => (
              <div
                key={item.id}
                className="question-item question theme-text-secondary"
                onClick={
                  canAnswerQuestion()
                    ? () => {
                        handleQuestionClick(item.id)();
                        if (answers.length > 0) {
                          console.log(
                            answers.find((x) => x.parent === item.id),
                          );
                          setAnswer(answers.find((x) => x.parent === item.id));
                        }

                        setQuestionId(item.id);
                        setShowAnswerModal(true);
                      }
                    : undefined
                }
              >
                <div>
                  <p className="question">{item.question}</p>
                  {answers.find((x) => x.parent === item.id) && (
                    <p className="answer">
                      {answers.find((x) => x.parent === item.id)?.answer}
                    </p>
                  )}
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            ))}
            <AnswerModal
              show={showAnswerModal}
              onHide={() => setShowAnswerModal(false)}
              stageId={competency.stage}
              competencyId={competency.id}
              questionId={questionId}
            />
          </div>
        </Col>
      )}
    </Row>
  );
}
