import React from 'react';

export default function BillingHistory({ desktop = true, invoices = [{}] }) {
  return (
    <React.Fragment>
      {invoices.length > 0 && (
        <div
          className="billing-container"
          style={{ padding: desktop === true ? '2rem 2.5rem' : '1rem 1.5rem' }}
        >
          <p className="title">Billing History</p>
          <div
            className="billing-items"
            style={{
              backgroundColor: desktop ? '#ffffff' : '',
              boxShadow: desktop ? '1px 1px 20px 1px rgba(0, 0, 0, 0.05)' : '',
              padding: desktop ? '2rem' : '',
            }}
          >
            {invoices &&
              invoices.map((invoice) => {
                let unixTime = invoice.created;
                let date = new Date(unixTime * 1000);
                const locale =
                  navigator.languages !== undefined
                    ? navigator.languages[0]
                    : navigator.language;
                let month = date.toLocaleDateString(locale, {
                  month: 'long',
                });
                date = date.toDateString().slice(4);

                const status =
                  invoice.paid && !invoice.refunded
                    ? 'paid'
                    : invoice.paid && invoice.refunded
                    ? 'refunded'
                    : 'due';

                return (
                  <div key={invoice.id}>
                    <div className="billing-item">
                      <div className="billing-item-date">
                        <p>{month}</p>
                        <p className="date">{date}</p>
                      </div>
                      <div className="billing-item-price">
                        <p className="price">
                          $
                          {(invoice.amount_captured.toFixed(2) / 100.0).toFixed(
                            2,
                          )}
                        </p>
                        {status === 'paid' ? (
                          <p className="status-tag">paid</p>
                        ) : (
                          <p className="status-tag due">{status}</p>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}

            {/* <div className="billing-item">
   <div className="billing-item-date">
     <p>September</p>
     <p className="date">Oct 6, 2021</p>
   </div>
   <div className="billing-item-price">
     <p className="price">$25.00</p>
     <p className="status-tag">paid</p>
   </div>
 </div>
 <hr /> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
