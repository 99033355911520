import pluralize from 'pluralize'
import React, { useCallback, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Masonry from 'react-masonry-css'
// import PropTypes from 'prop-types';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { DesktopBackButton, DesktopHeader, Loader, UserAvatar } from '../common'
import Icon from '../common/components/Icon'
import useEffectiveBackLink from '../common/useEffectiveBackLinkHook'
import AssignRoadmapModal from '../dashboard/components/AssignRoadmapModal'
import RefundsModal from '../dashboard/components/RefundsModal'
import Roadmap from '../dashboard/components/Roadmap'
import { useFetchAssignedUsers } from '../dashboard/redux/hooks'
import { useDeleteUserRoadmap } from '../manage/redux/deleteUserRoadmap'
import BasicInfo from '../user/components/profile/BasicInfo'
import MyClients from '../user/components/profile/MyClients'
import ProfilePictureContainer from '../user/components/profile/ProfilePictureContainer'
import { useGetChargesByCustomer } from '../user/redux/getChargesByCustomer'
import { useGetSubscriptionByCoachAndUser } from '../user/redux/getSubscriptionByCoachAndUser'
import { useGetSubscriptionStatusByEmail } from '../user/redux/getSubscriptionStatusByEmail'
import { useFetchUser } from '../user/redux/hooks'
import CancelClientSubscriptionModal from './components/CancelClientSubscriptionModal'
import { EditUserProfileModal } from './EditUserProfilePage'
import { useSendWelcomeEmail } from './redux/sendWelcomeEmail'

export default function UserPage() {
    const history = useHistory()

    const {
        user: currentUser,
        replaceStringWithSynonyms,
        fetchUser,
    } = useFetchUser()
    const { getChargesByCustomer } = useGetChargesByCustomer()
    const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail()
    const { getSubscriptionByCoachAndUser } = useGetSubscriptionByCoachAndUser()
    const { deleteUserRoadmap } = useDeleteUserRoadmap()
    const { fetchAssignedUsers } = useFetchAssignedUsers()

    const [canRefund, setCanRefund] = useState(true)
    const [isEditing, setIsEditing] = useState(false)
    const [tabSelected, setTabSelected] = React.useState('Profile')
    const [showRefundModal, setShowRefundModal] = useState(false)
    const [userSubscriptionStatus, setUserSubscriptionStatus] = useState(null)
    const [subscriptionDetails, setSubscriptionDetails] = useState(null)

    const [user, setUser] = useState(null)

    const toggleEditUser = () => setIsEditing(!isEditing)

    const handleArchiveRoadmapYes = roadmapId => {
        deleteUserRoadmap({ roadmapId, userId: user.id }).then(() => {
            window.location.reload(false)
        })
    }

    const params = useParams()
    const location = useLocation()
    const userId = Number(params.userId)
    const userGroups = currentUser ? currentUser.groups : []

    const canEditUserProfile =
        currentUser &&
        (currentUser.groups.includes('Admin') ||
            currentUser.groups.includes('Coach') ||
            currentUser.is_internal_coach)

    useEffect(() => {
        fetchUser()
    }, [fetchUser, userId])

    useEffect(() => {
        getSubscriptionByCoachAndUser({ id: userId })
            .then(res => {
                console.log('subscription', res)
                setSubscriptionDetails(res.data)
            })
            .catch(err => console.log(err.response))
    }, [getSubscriptionByCoachAndUser, userId])

    useEffect(() => {
        const getStatus = async () =>
            getSubscriptionStatusByEmail({ email: user.email })
                .then(res => {
                    if (res.data.current_subscription_state === 'active') {
                        setUserSubscriptionStatus('active')
                    } else {
                        setUserSubscriptionStatus(
                            res?.data?.current_subscription_state || 'inactive'
                        )
                    }
                })
                .catch(err => {
                    console.log('err', err)
                })

        if (user) {
            getStatus()
        }
    }, [
        currentUser,
        user,
        getSubscriptionStatusByEmail,
        setUserSubscriptionStatus,
        userSubscriptionStatus,
    ])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        fetchAssignedUsers({ userId })
            .then(assignedUsers => {
                setUser(assignedUsers.results.find(u => u.id === userId))
            })
            .catch(err => console.log(err.response))
    }, [fetchAssignedUsers, history])

    const { sendWelcomeEmail, sendWelcomeEmailPending } = useSendWelcomeEmail()
    const handleSendWelcomeEmailClick = useCallback(() => {
        sendWelcomeEmail({ userId }).then(() =>
            alert('Welcome email has been sent')
        )
    }, [sendWelcomeEmail, userId])

    const defaultBackLink = !!user
        ? userGroups.includes('Admin')
            ? '/manage/accounts'
            : '/dashboard/coach'
        : '/dashboard'

    const effectiveBackLink = useEffectiveBackLink(defaultBackLink)
    const [showAddRdmp, setShowAddRdmp] = useState(false)

    const [
        showCancelClientSubscriptionModal,
        setShowCancelClientSubscriptionModal,
    ] = useState(false)

    useEffect(() => {
        getChargesByCustomer({ customerId: userId }).then(res => {
            if (res.data.data?.[0].refunded === true) {
                setCanRefund(false)
            }
        })
    }, [getChargesByCustomer, userId])

    useEffect(() => {
        if (currentUser && !currentUser.company_is_rtm) return

        window.location.href = `/rpm/client-profile/${userId}`
    }, [history, currentUser, userId])

    if (!user || !currentUser) {
        console.log('no user', user, currentUser)
        return <Loader />
    }

    const groupNames = replaceStringWithSynonyms(user.groups.join(', '))
    const cohortNames =
        user.cohort.length > 0
            ? user.cohort.map(t => t.text).join(', ')
            : 'None'

    // subscriptionDetails.current_period_start to local date

    let subscriptionStartDate = new Date(
        subscriptionDetails?.current_period_start
    )

    subscriptionStartDate = subscriptionStartDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })

    return (
        <div className="manage-user-page">
            <DesktopHeader />
            <div className="d-lg-none profile-tab-selector">
                <div className="profile-header">
                    <Link to="/">
                        <Icon name="chevronLeft" size="12" />
                    </Link>
                    <h1>Profile</h1>
                </div>
                <ProfilePictureContainer
                    user={user}
                    onEdit={toggleEditUser}
                    styles={{ padding: '1rem 1rem' }}
                />
                <div className="profile-tabs">
                    <button
                        className="profile-tab"
                        onClick={() => setTabSelected('Profile')}
                        style={{ justifyItems: 'start' }}
                    >
                        <p>Profile</p>
                        {tabSelected === 'Profile' && (
                            <Icon name="line" size="4" />
                        )}
                    </button>
                    <button
                        className="profile-tab"
                        onClick={() => setTabSelected('Roadmaps')}
                    >
                        <p>Roadmaps</p>
                        {tabSelected === 'Roadmaps' && (
                            <Icon name="line" size="4" />
                        )}
                    </button>
                    {/* <button
            className="profile-tab"
            onClick={() => setTabSelected('Billing')}
          >
            <p>Billing History</p>
            {tabSelected === 'Billing' && <Icon name="line" size="4" />}
          </button> */}
                </div>
                <div className="profile-tab-content">
                    {tabSelected === 'Profile' && (
                        <>
                            <BasicInfo
                                user={user}
                                onEdit={toggleEditUser}
                                styles={{ padding: '1rem 1rem' }}
                                desktop={false}
                                groupNames={groupNames}
                                cohortNames={cohortNames}
                                subscription
                                onClick={handleSendWelcomeEmailClick}
                                disabled={sendWelcomeEmailPending}
                            />
                            {user.groups.includes('Coach') && (
                                <MyClients
                                    user={user}
                                    onEdit={toggleEditUser}
                                    styles={{ padding: '1rem 1rem' }}
                                    title={pluralize(
                                        currentUser?.synonyms?.student,
                                        2
                                    )}
                                    desktop={false}
                                />
                            )}
                            {user.groups.includes('User') && (
                                <MyClients
                                    user={user}
                                    onEdit={toggleEditUser}
                                    styles={{ padding: '1rem 1rem' }}
                                    title={pluralize(
                                        currentUser?.synonyms?.coach,
                                        1
                                    )}
                                    desktop={false}
                                    type="coach"
                                />
                            )}
                        </>
                    )}
                    {tabSelected === 'Roadmaps' && (
                        <div className="client-roadmaps">
                            <div className="client-roadmaps-header">
                                <p>My Roadmaps ({user.roadmaps_info.length})</p>
                                <div className="text-center mrm-mt-1">
                                    <Link
                                        to={{
                                            pathname: `/manage/user/${user.id}/edit-roadmap`,
                                            state: { backLink: location },
                                        }}
                                    >
                                        <div className="plus-btn">
                                            <Icon name="plus" color="#ffffff" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {user.roadmaps_info.length > 0 ? (
                                user.roadmaps_info.map(roadmap => (
                                    <Roadmap
                                        data={roadmap}
                                        key={roadmap.id}
                                        user={user}
                                        className="roadmap"
                                        onDeleteClick={() =>
                                            handleArchiveRoadmapYes(roadmap.id)
                                        }
                                    />
                                ))
                            ) : (
                                <div className="no-roadmap">No Roadmaps</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="desktop-view-container">
                <div className="user-page-header">
                    <DesktopBackButton defaultBackLink={effectiveBackLink} />
                    {canEditUserProfile && (
                        <Link
                            className=""
                            to={{
                                pathname: `/user/edit-profile/${user.id}`,
                                state: { backLink: location },
                            }}
                        >
                            <Button variant="gray">Edit Account</Button>
                        </Link>
                    )}
                </div>
                <div className="profile-card">
                    <div className="profile-picture-container">
                        <UserAvatar user={user} />
                        <h1>
                            {user.first_name} {user.last_name}
                        </h1>
                        {user.groups.includes('User') && (
                            <p
                                style={{
                                    backgroundColor:
                                        userSubscriptionStatus === 'active'
                                            ? '#71837140'
                                            : '#6E95A940',
                                    color:
                                        userSubscriptionStatus === 'active'
                                            ? '#718371'
                                            : '#6E95A9',
                                }}
                            >
                                {userSubscriptionStatus === null &&
                                user.groups.includes('User')
                                    ? 'inactive'
                                    : userSubscriptionStatus}
                            </p>
                        )}

                        <button
                            className="message-user"
                            type="button"
                            onClick={() => {
                                history.push({
                                    pathname: '/messages/default',
                                    state: {
                                        conversation: {
                                            id: userId,
                                            timestamp: Date.now(),
                                            type: 'p2p',
                                            user: {
                                                id: userId,
                                                first_name: user.first_name,
                                                last_name: user.last_name,
                                                photo: user.photo,
                                                username: user.username,
                                            },
                                        },
                                    },
                                })
                            }}
                        >
                            <Icon name="message" size="12" />
                            <p>Message</p>
                        </button>
                    </div>

                    <BasicInfo
                        user={user}
                        cohortNames={cohortNames}
                        groupNames={groupNames}
                        horizontal
                    />
                    <hr className={`${!subscriptionDetails && 'd-none'}`} />

                    {subscriptionDetails && (
                        <div className="bottom-price-container">
                            <div className="subscription-type">
                                <h2>{subscriptionDetails?.plan?.name}</h2>
                            </div>
                            <div className="subscription-date">
                                <p>Start Date: {`${subscriptionStartDate}`}</p>
                            </div>
                            <div className="price">
                                <p>
                                    $
                                    {subscriptionDetails?.plan?.unit_amount.toString()
                                        .length < 3
                                        ? subscriptionDetails?.plan
                                              ?.unit_amount + '.00'
                                        : subscriptionDetails?.plan
                                              ?.unit_amount /
                                              100 +
                                          '.00'}
                                </p>
                            </div>

                            <div className="payment-buttons">
                                {canRefund && (
                                    <button
                                        className="issue-refund"
                                        onClick={() => {
                                            setShowRefundModal(true)
                                        }}
                                    >
                                        Issue Refund
                                    </button>
                                )}

                                {userSubscriptionStatus === 'active' && (
                                    <button
                                        className="cancel-subscription"
                                        onClick={() =>
                                            setShowCancelClientSubscriptionModal(
                                                true
                                            )
                                        }
                                    >
                                        Cancel subscription
                                    </button>
                                )}

                                <RefundsModal
                                    userId={userId}
                                    show={showRefundModal}
                                    onHide={() => {
                                        setShowRefundModal(false)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <Masonry
                    breakpointCols={{ default: 2, 991: 1 }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    <div className="client-roadmaps">
                        <div className="client-roadmaps-header">
                            <p>Roadmaps ({user.roadmaps_info.length})</p>
                            <AssignRoadmapModal
                                show={showAddRdmp}
                                client={user.id}
                                handleClose={() => {
                                    setShowAddRdmp(false)
                                    window.location.reload(false)
                                }}
                            />
                            <div className="text-center mrm-mt-1">
                                <button onClick={() => setShowAddRdmp(true)}>
                                    <div className="plus-btn">
                                        <Icon name="plus" color="#ffffff" />
                                    </div>
                                </button>
                            </div>
                        </div>
                        {user.roadmaps_info.length > 0 ? (
                            user.roadmaps_info.map(roadmap => (
                                <Roadmap
                                    data={roadmap}
                                    key={roadmap.id}
                                    user={user}
                                    className="roadmap"
                                    onDeleteClick={() =>
                                        handleArchiveRoadmapYes(roadmap.id)
                                    }
                                />
                            ))
                        ) : (
                            <div className="no-roadmap">No Roadmaps</div>
                        )}
                    </div>
                    {user.groups.includes('User') && (
                        <MyClients
                            user={user}
                            type="coach"
                            title={pluralize(
                                currentUser?.synonyms?.coach,
                                currentUser?.coaches?.length
                            )}
                        />
                    )}
                    {user.groups.includes('Coach') && (
                        <MyClients
                            user={user}
                            title={pluralize(currentUser?.synonyms?.student, 2)}
                        />
                    )}
                </Masonry>

                <EditUserProfileModal
                    show={isEditing}
                    onHide={toggleEditUser}
                    userId={userId}
                />
            </div>
            <CancelClientSubscriptionModal
                show={showCancelClientSubscriptionModal}
                onHide={() => setShowCancelClientSubscriptionModal(false)}
                userPk={userId}
            />
        </div>
    )
}

UserPage.propTypes = {}
UserPage.defaultProps = {}
