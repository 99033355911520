import React from 'react';

export default function SyncIcon({ size = 11, color = '#908E87' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_528_3780)">
        <path
          d="M0.739258 5.47242C0.739258 3.05242 2.86829 1.04492 5.50001 1.04492C7.15592 1.04492 8.60485 1.81492 9.43281 2.99742"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2607 5.47266C10.2607 7.92016 8.13172 9.90016 5.5 9.90016C3.84408 9.90016 2.39516 9.13016 1.5672 7.94766"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.36023 10.3127V7.86523H3.96238"
          stroke="#908E87"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.43278 0.6875V3.135H6.83063"
          stroke="#908E87"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_528_3780">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
