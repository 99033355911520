import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { DesktopHeader } from '../common';
import { useFetchUser } from '../user/redux/fetchUser';
import { useGetListInternalProductSubscription } from './redux/getListInternalProductSubscription';
import Sidebar from './components/Sidebar';
import SubscriptionTable from './components/SubscriptionTable';
import InternalProductModal from './components/InternalProductModal';

export default function InternalSubscriptions() {
  const { getListInternalProductSubscription } =
    useGetListInternalProductSubscription();

  const { user } = useFetchUser();

  const [tableData, setTableData] = useState([
    {
      name: 'Loading...',
    },
  ]);

  useEffect(() => {
    const getProducts = () => {
      try {
        getListInternalProductSubscription().then((res) => {
          setTableData(res.data.results);
        });
      } catch {
        console.error('Error fetching products');
      }
    };
    getProducts();
  }, [getListInternalProductSubscription]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Created',
        accessor: 'created_at',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
    ],
    [],
  );

  const data = useMemo(
    () =>
      tableData
        .filter((product) => {
          if (user) {
            return product.company === user.company_id;
          }
        })
        .filter((product) => product.contract === null)
        .map((data) => {
          const parseDate = (date) => {
            const newDate = new Date(date);
            const parseDate = newDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            });
            return parseDate;
          };

          return {
            name: data.name,
            created_at: parseDate(data.created_at),
            price: '$' + data.price?.unit_amount / 100,
          };
        }),
    [tableData, user],
  );

  return (
    <div className="subscriptions-internal-subscriptions">
      <DesktopHeader />
      <Container>
        <div className="subscriptions-internal-subscriptions wrapper">
          <Sidebar title="Internal Subscriptions" />
          <SubscriptionTable
            columns={columns}
            data={data}
            style={{ height: '800px' }}
            title="Subscription"
            modal={InternalProductModal}
          />
        </div>
      </Container>
    </div>
  );
}

InternalSubscriptions.propTypes = {};
InternalSubscriptions.defaultProps = {};
