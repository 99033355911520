// images
import girlSmiling from '../../assets/carousel/images/girl-smiling.png';
import manTyping from '../../assets/carousel/images/man-typing.png';
import manWithLaptop from '../../assets/carousel/images/man-with-laptop.png';
// icons
import computer from '../../assets/carousel/icons/computer.svg';
import highFive from '../../assets/carousel/icons/high-five.svg';
import notes from '../../assets/carousel/icons/notes.svg';

export const carouselItems = [
  {
    title: 'Connect with a Specialized Care Team',
    description: `You're in great hands. Together your provider and a Noble Health Professional with monitor your health data and guide your healing. We use technology to enchance your care and monitor your progress over time.`,
    image: girlSmiling,
    icon: highFive,
  },
  {
    title: 'Take Control of Your Progress',
    description: `Our roadmaps have been clinically proven to reduce symptoms and excellerating healing. Daily use of roadmaps will allow your care team to best serve you, and give you the greatest benefit from the program.`,
    image: manTyping,
    icon: notes,
  },
  {
    title: 'Report your health data with ease',
    description: `We use a combination of health data and personal observation to to monitor your wellbeing and alert your provider to any concerns that arise.`,
    image: manWithLaptop,
    icon: computer,
  },
];
