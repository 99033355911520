import React from 'react';
import Icon from '../../../../common/components/Icon';

export default function HealthDataHeader() {
  return (
    <div className="health-data-header">
      <p className="title">Health Data</p>
      <Icon name="faq" />
    </div>
  );
}
