import { Formik } from 'formik'
import React, { Fragment, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useCreateCptLog } from '../../redux/createCptLog'
import { useGetBilling } from '../../redux/getBilling'
import { useGetCptList } from '../../redux/getCptList'
import { useUpdateCptLog } from '../../redux/updateCptLog'
import { isValidDate } from '../../utils/isValidDate'
import SuccessAdditionModal from './SuccessAdditionModal'

const validationSchema = Yup.object().shape({
    cpt_id: Yup.number().required('Please select a CPT code'),
    month: Yup.string().required('Please enter a month'),
    day: Yup.string().required('Please enter a day'),
    year: Yup.string().required('Please enter a year'),

    call_length: Yup.number()
        .integer()
        .min(0, 'Please enter a positive number'),

    notes: Yup.string(),
})

export default function AddCptCodeModal({
    show,
    onHide = () => {},
    userId,
    updateData = null,
    showDeleteModal = () => {},
}) {
    const { createCptLog } = useCreateCptLog()
    const { getBilling } = useGetBilling()

    const [showCptCodeDropdown, setShowCptCodeDropdown] = React.useState(false)

    const cptCodeDropdownRef = useRef()
    useOutsideAlerter(cptCodeDropdownRef, () => setShowCptCodeDropdown(false))

    const { cptList, getCptListPending } = useGetCptList()

    const { updateCptLog } = useUpdateCptLog()

    useEffect(() => {
        if (cptList) {
            console.log('cptList', cptList)
        }
    }, [cptList])

    const { t } = useTranslation()

    const validationSchema = Yup.object().shape({
        cpt_id: Yup.number().required(
            t('client_profile.please_select_a_cpt_code')
        ),
        month: Yup.string().required(t('client_profile.please_enter_a_month')),
        day: Yup.string().required(t('client_profile.please_enter_a_day')),
        year: Yup.string().required(t('client_profile.please_enter_a_year')),

        call_length: Yup.number()
            .integer()
            .min(0, t('client_profile.please_enter_a_positive_number')),

        notes: Yup.string(),
    })

    const [showSuccessModal, setShowSuccessModal] = React.useState(false)

    return (
        <Fragment>
            <Modal show={show} onHide={onHide} className="add-cpt-modal">
                <h1>
                    {updateData
                        ? t('client_profile.edit')
                        : t('client_profile.add')}{' '}
                    {t('client_profile.cpt_code')}
                </h1>

                <Formik
                    initialValues={
                        updateData
                            ? updateData
                            : {
                                  cpt_id: '',
                                  month: '',
                                  day: '',
                                  year: '',
                                  call_length: '',
                                  notes: '',
                              }
                    }
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        console.log(values)

                        if (
                            isValidDate(
                                values.year,
                                values.month,
                                values.day
                            ) === false
                        ) {
                            setFieldError(
                                'date',
                                t('client_profile.invalid_date')
                            )
                            return
                        }

                        setSubmitting(false)

                        console.log(values)

                        if (updateData) {
                            updateCptLog({
                                id: updateData.id,
                                cpt_id: values.cpt_id,
                                call_length: values.call_length
                                    ? Number(values.call_length)
                                    : null,
                                notes: values.notes ? values.notes : null,
                                date: `${values.year}-${values.month}-${values.day}`,
                                user_id: userId,
                            })
                                .then(res => {
                                    getBilling({ userId }).then(res => {
                                        console.log('getBilling', res)
                                        onHide()
                                        setShowSuccessModal(true)
                                    })
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                            return
                        }

                        createCptLog({
                            cpt_id: values.cpt_id,
                            call_length: values.call_length
                                ? values.call_length
                                : null,
                            notes: values.notes ? values.notes : null,
                            date: `${values.year}-${values.month}-${values.day}`,
                            user_id: userId,
                        })
                            .then(res => {
                                console.log(res)
                                getBilling({ userId }).then(res => {
                                    console.log('getBilling', res)
                                    setShowSuccessModal(true)
                                    onHide()
                                })
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="assessment_id">CPT Code</label>

                                <div
                                    className="select-cpt-dropdown"
                                    role="button"
                                    {...(!showCptCodeDropdown && {
                                        onClick: () =>
                                            setShowCptCodeDropdown(true),
                                    })}
                                    ref={cptCodeDropdownRef}
                                >
                                    {!values.cpt_id ? (
                                        <p>Select CPT code</p>
                                    ) : (
                                        <p>
                                            {cptList &&
                                                cptList.results.find(
                                                    cpt =>
                                                        cpt.id === values.cpt_id
                                                ).title}
                                        </p>
                                    )}
                                    <Icon name="chevron-down" />
                                    {showCptCodeDropdown && !getCptListPending && (
                                        <div className="select-cpt-dropdown__options">
                                            {cptList &&
                                                cptList.results.map(
                                                    (
                                                        {
                                                            id,
                                                            code,
                                                            description,
                                                            title,
                                                        },
                                                        i
                                                    ) => (
                                                        <div
                                                            className="select-cpt-dropdown__options__option"
                                                            key={i}
                                                            role="button"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'cpt_id',
                                                                    id
                                                                )

                                                                setShowCptCodeDropdown(
                                                                    false
                                                                )
                                                            }}
                                                        >
                                                            <p>
                                                                <strong>
                                                                    {code}
                                                                </strong>
                                                            </p>
                                                            <p className="description">
                                                                {title}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {errors.cpt_id && touched.cpt_id && (
                                <div className="error-message">
                                    {errors.cpt_id}
                                </div>
                            )}

                            <div className="form-group">
                                <label htmlFor="date">Date Completed</label>
                                <div className="date-wrapper">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="month"
                                        placeholder="MM"
                                        onChange={handleChange}
                                        value={values.month}
                                    />
                                    <p> / </p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="day"
                                        placeholder="DD"
                                        onChange={handleChange}
                                        value={values.day}
                                    />
                                    <p> / </p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="year"
                                        placeholder="YYYY"
                                        onChange={handleChange}
                                        value={values.year}
                                    />
                                </div>
                            </div>
                            {values.day &&
                                values.month &&
                                values.year &&
                                new Date( // if date is greater than today, disable delete button
                                    `${values.year}-${values.month}-${values.day}`
                                ) > new Date() && (
                                    <div className="error-message">
                                        Date cannot be in the future
                                    </div>
                                )}
                            {errors.month ||
                            errors.day ||
                            errors.year ||
                            errors.date ? (
                                <p className="error-message">
                                    {errors.month ||
                                        errors.day ||
                                        errors.year ||
                                        errors.date}
                                </p>
                            ) : null}
                            <div className="form-group">
                                <label htmlFor="call_length">
                                    Call Length in minutes (if applicable)
                                </label>
                                <input
                                    type="text"
                                    className="form-control simple-text-input"
                                    id="call_length"
                                    placeholder="call time"
                                    onChange={e => {
                                        // if value is not a number, don't update
                                        if (isNaN(e.target.value)) {
                                            return
                                        }
                                        handleChange(e)
                                    }}
                                    value={
                                        values.call_length === null
                                            ? ''
                                            : values.call_length
                                    }
                                />
                            </div>

                            {errors.call_length && touched.call_length && (
                                <p className="error-message">
                                    {errors.call_length}
                                </p>
                            )}

                            <div className="form-group">
                                <label htmlFor="notes">Notes (optional)</label>
                                <textarea
                                    className="form-control"
                                    id="notes"
                                    placeholder="notes"
                                    onChange={handleChange}
                                    value={values.notes}
                                />
                            </div>

                            {errors.notes && touched.notes && (
                                <p className="error-message">{errors.notes}</p>
                            )}

                            <button
                                type="submit"
                                className="submit-button"
                                onSubmit={handleSubmit}
                                disabled={
                                    new Date( // if date is greater than today, disable delete button
                                        `${values.year}-${values.month}-${values.day}`
                                    ) > new Date()
                                }
                            >
                                {updateData ? 'Save' : 'Add CPT Code'}
                            </button>
                            {updateData && (
                                <button
                                    type="button"
                                    className="submit-button"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #E8744F',
                                        color: '#E8744F',
                                        marginTop: '0.625rem',
                                    }}
                                    onClick={() => {
                                        showDeleteModal()
                                        onHide()
                                    }}
                                >
                                    {t('client_profile.delete')}{' '}
                                    {t('client_profile.cpt_code')}
                                </button>
                            )}
                            <button
                                type="button"
                                className="cancel-button"
                                onClick={onHide}
                            >
                                {t('client_profile.cancel')}
                            </button>
                        </form>
                    )}
                </Formik>
            </Modal>
            <SuccessAdditionModal
                show={showSuccessModal}
                description={`CPT code has been ${
                    updateData ? 'updated' : 'added'
                } to client profile.`}
                onHide={() => setShowSuccessModal(false)}
            />
        </Fragment>
    )
}
