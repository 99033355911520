import React from 'react';

export default function CloseIcon({ size = 20, color = '#343434' }) {
  return (
    <svg
      width={size}
      height={size / 1.03}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.245 19.7559C19.1274 19.8735 18.9679 19.9395 18.8016 19.9395C18.6353 19.9395 18.4758 19.8735 18.3582 19.7559L12.3868 13.7845C12.2692 13.6669 12.2031 13.5074 12.2031 13.3411C12.2031 13.1748 12.2692 13.0152 12.3868 12.8976L18.3582 6.92627C18.4758 6.80867 18.6353 6.7426 18.8016 6.7426C18.9679 6.7426 19.1274 6.80867 19.245 6.92627C19.3626 7.04388 19.4287 7.20338 19.4287 7.36969C19.4287 7.53601 19.3626 7.69551 19.245 7.81311L13.717 13.3411L19.245 18.869C19.3626 18.9866 19.4287 19.1461 19.4287 19.3124C19.4287 19.4788 19.3626 19.6383 19.245 19.7559Z"
        fill={color}
      />
      <path
        d="M13.273 13.7842L7.30163 19.7556C7.18403 19.8732 7.02453 19.9393 6.85821 19.9393C6.6919 19.9393 6.5324 19.8732 6.41479 19.7556C6.29719 19.638 6.23112 19.4785 6.23112 19.3122C6.23112 19.1459 6.29719 18.9864 6.41479 18.8688L11.9427 13.3408L6.41479 7.81287C6.29719 7.69526 6.23112 7.53576 6.23112 7.36945C6.23112 7.20313 6.29719 7.04363 6.41479 6.92603C6.53239 6.80843 6.6919 6.74236 6.85821 6.74236C7.02453 6.74236 7.18403 6.80843 7.30163 6.92603L13.273 12.8974C13.3906 13.015 13.4567 13.1745 13.4567 13.3408C13.4567 13.5071 13.3906 13.6666 13.273 13.7842Z"
        fill={color}
      />
    </svg>
  );
}
