import React, { createContext, useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

export const AudioPlayingContext = createContext();

export default function AudioPlayingProvider({ children }) {
  const [messagePlaying, setMessagePlaying] = useState(null); // this is going to be a message id
  const [recordingAudio, setRecordingAudio] = useState(false); // boolean
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    console.log('RECORDING AUDIO', recordingAudio);
  }, [recordingAudio]);

  // recording
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl: insideMediaBlobUrl,
    clearBlobUrl,
    status,
    previewStream,
  } = useReactMediaRecorder({
    askPermissionOnMount: false,
    mediaRecorderOptions: {
      mimeType: 'audio/wav', // this was the only option that worked with react-native
    },
    audio: true,
    stopStreamsOnStop: true,
  });

  // get minutes and seconds from previewStream

  useEffect(() => {
    if (previewStream) {
      console.log('previewStream', previewStream);
      const audio = new Audio(previewStream);
      console.log('audio', audio.currentTime);
    }
  }, [previewStream]);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const reset = () => {
    setMinutes(0);
    setSeconds(0);
  };

  useEffect(() => {
    // if 5 minutes have passed, stop recording
    if (minutes >= 5 && status === 'recording') {
      stopRecording();
      setRecordingAudio(false);
    }

    let interval = null;
    if (status === 'recording') {
      interval = setInterval(() => {
        setIsRunning(true);
        if (seconds === 59) {
          setMinutes(minutes + 1);
          setSeconds(0);
        } else {
          setSeconds(seconds + 1);
        }
      }, 1000);
    } else if (status === 'stopped') {
      clearInterval(interval);
      setIsRunning(false);
    }

    return () => clearInterval(interval);
  }, [status, seconds, minutes]);

  const [mediaBlobUrl, setMediaBlobUrl] = React.useState(undefined);
  const [usingBruteForce, setUsingBruteForce] = React.useState(false);

  const bruteForceStopRecording = async () => {
    setUsingBruteForce(true);
    setMediaBlobUrl(null);
    setRecordingAudio(false);
    stopRecording();
  };

  useEffect(() => {
    console.log('RECORDING AUDIO', recordingAudio);
  }, [recordingAudio]);

  return (
    <AudioPlayingContext.Provider
      value={{
        messagePlaying,
        setMessagePlaying,
        recordingAudio,
        setRecordingAudio,
        uploadedFile,
        setUploadedFile,
        recordingState: {
          startRecording,
          stopRecording,
          insideMediaBlobUrl,
          clearBlobUrl,
          status,
          mediaBlobUrl,
          setMediaBlobUrl,
          usingBruteForce,
          bruteForceStopRecording,
          setUsingBruteForce,
          minutes,
          seconds,
          reset,
          isRunning,
        },
      }}
    >
      {children}
    </AudioPlayingContext.Provider>
  );
}
