import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from 'react'
import AddCallModal from '../../../dashboard/UpcomingCalls/AddCallModal'
import EditCallModal from '../../../dashboard/UpcomingCalls/EditCallModal'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import Icon from '../../../common/components/Icon'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import { useFetchCoachUpcomingCalls } from '../../../dashboard/redux/fetchCoachUpcomingCalls'

export default function Schedule({ userId, user }) {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState(false)
  const [showEditCallModal, setShowEditCallModal] = useState(false)
  const [selectedCall, setSelectedCall] = useState(null)
  const [deleteCall, setDeleteCall] = useState(false)

  const { coachCalls, fetchCoachCalls } = useFetchCoachUpcomingCalls()
  const [dropdownShown, setDropdownShown] = React.useState(-1)
  const editDropdownRef = useRef(null)

  const [calls, setCalls] = useState([])

  const [startTimestamp, setStartTimestamp] = React.useState(
    new Date().valueOf()
  )

  console.log({ today: new Date().valueOf() })

  useEffect(() => {
    fetchCoachCalls({
      user_id: userId,
      start_timestamp: startTimestamp,
    })

    loadMore()
  }, [userId])

  useEffect(() => {
    if (coachCalls && coachCalls.results && coachCalls.results.length > 0) {
      setCalls(prev =>
        [
          ...prev.filter(
            call =>
              !coachCalls.results.find(
                newCall =>
                  newCall.id === call.id &&
                  newCall.meeting_timestamp === call.meeting_timestamp
              )
          ),
          ...coachCalls.results,
        ].filter(call => call.participants.includes(userId))
      )
      return
    }
  }, [coachCalls])

  const loadMore = useCallback(() => {
    // sum one week to the start timestamp
    setStartTimestamp(prev => prev + 604800000)
    fetchCoachCalls({
      user_id: userId,
      start_timestamp: startTimestamp + 604800000,
    })
  }, [coachCalls, startTimestamp])

  useOutsideAlerter(editDropdownRef, () => setDropdownShown(-1))

  if (!user) return null

  return (
    <div className="rpm-profile-info-container__info__calls">
      <AddCallModal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          fetchCoachCalls({ user_id: userId })
        }}
        userId={userId}
      />

      <EditCallModal
        callId={selectedCall ? selectedCall.id : null}
        show={showEditCallModal && selectedCall}
        userId={userId}
        onHide={() => {
          setDeleteCall(false)
          setSelectedCall(null)
          setShowEditCallModal(false)
          setCalls([])
          setStartTimestamp(new Date().valueOf())
          fetchCoachCalls({
            user_id: userId,
            start_timestamp: new Date().valueOf(),
          })
        }}
        coachCalls={calls}
        deleteCall={deleteCall}
      />

      <div className="rpm-profile-info-container__info__add_header">
        <p>
          {t('client_profile.next_call')}s (Now -{' '}
          {new Date(startTimestamp + 604800000).toLocaleString('default', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
          )
        </p>
        <button
          className="rpm-profile-info-container__info__add_header__button"
          onClick={() => {
            setShowModal(true)
          }}
        >
          <Icon name="plus" color={'#ffffff'} size={10} />
        </button>
      </div>

      {calls &&
        calls.length > 0 &&
        calls
          /*   .filter(call =>
            call.meeting_participants.invited.find(
              participant => participant.id === userId
            )
          ) */
          .map((call, idx) => (
            <div
              className="rpm-profile-info-container__info__calls__item"
              key={idx}
            >
              <a
                className="rpm-profile-info-container__info__calls__item__profile-container"
                href={call.meeting_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  opacity: 1,
                }}
              >
                <div className="rpm-profile-info-container__info__calls__item__profile-container__picture">
                  <div className="rpm-profile-info-container__info__calls__item__profile-container__picture__header">
                    {new Date(call.meeting_timestamp)
                      .toLocaleString('default', {
                        month: 'short',
                      })
                      .toLocaleUpperCase()}
                  </div>
                  <div className="rpm-profile-info-container__info__calls__item__profile-container__picture__body">
                    <p
                      className="rpm-profile-info-container__info__calls__item__profile-container__picture__body__text"
                      style={{
                        fontSize: '1rem',
                        fontWeight: '900',
                      }}
                    >
                      {new Date(call.meeting_timestamp).toLocaleString(
                        'default',
                        {
                          day: 'numeric',
                        }
                      )}
                    </p>
                  </div>{' '}
                </div>
                <div className="rpm-profile-info-container__info__calls__item__profile-container__name">
                  <p className="rpm-profile-info-container__info__calls__item__profile-container__name__text">
                    {call.call_name}
                  </p>
                  <p
                    className="rpm-profile-info-container__info__calls__item__profile-container__name__description"
                    style={{ textAlign: 'left' }}
                  >
                    {call?.meeting_participants?.invited &&
                      call?.meeting_participants?.invited.length > 0 &&
                      call?.meeting_participants?.invited.map(
                        (participant, idx) => (
                          <Fragment key={idx}>
                            {participant.first_name} {participant.last_name}
                            {idx <
                            call?.meeting_participants?.invited?.length - 1
                              ? ', '
                              : ''}
                          </Fragment>
                        )
                      )}
                  </p>
                </div>
              </a>
              <a
                href={call.meeting_link}
                target="_blank"
                rel="noopener noreferrer"
                className="rpm-profile-info-container__info__calls__item__time"
                style={{
                  opacity: 1,
                }}
              >
                <p className="rpm-profile-info-container__info__calls__item__time__text">
                  {call.is_recurrent && <Icon name="recurs" />}
                  {new Date(call.meeting_timestamp).toDateString()}
                  {' @ '}
                  {new Date(
                    call.meeting_timestamp
                    // hide seconds
                  )
                    .toLocaleTimeString({
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(/:\d\d /, ' ')}{' '}
                  {
                    new Date()
                      .toLocaleTimeString('en-us', {
                        timeZoneName: 'short',
                      })
                      .split(' ')[2]
                  }
                </p>
              </a>

              <div
                className="rpm-profile-info-container__info__calls__item__three-dots"
                type="button"
                onClick={() => setDropdownShown(idx)}
              >
                <Icon name={'threedots'} color={'#C4C4C4'} size={3} />
              </div>

              {dropdownShown === idx && (
                <div
                  className="rpm-profile-info-container__info__calls__item__dropdown"
                  ref={editDropdownRef}
                >
                  <div
                    className="rpm-profile-info-container__info__calls__item__dropdown__item"
                    style={{
                      borderBottom: '1px solid rgba(196, 196, 196, 0.3)',
                    }}
                    onClick={() => {
                      setDropdownShown(-1)
                      setShowEditCallModal(true)
                      setSelectedCall(call)
                    }}
                  >
                    {t('coach_dashboard.edit')}
                  </div>
                  <div
                    className="rpm-profile-info-container__info__calls__item__dropdown__item"
                    style={{ color: '#E8744F' }}
                    onClick={() => {
                      setDeleteCall(call.id)
                      setShowEditCallModal(true)
                      setSelectedCall(call)
                    }}
                  >
                    {t('coach_dashboard.delete')}
                  </div>
                </div>
              )}
            </div>
          ))}

      <button
        onClick={loadMore}
        className="rpm-profile-info-container__info__calls__load-more"
      >
        Load More
      </button>
    </div>
  )
}
