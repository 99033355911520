import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_GET_PROVIDERS_SUMMARY_BEGIN,
    RPM_GET_PROVIDERS_SUMMARY_SUCCESS,
    RPM_GET_PROVIDERS_SUMMARY_FAILURE,
    RPM_GET_PROVIDERS_SUMMARY_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function getProvidersSummary(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_GET_PROVIDERS_SUMMARY_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.get(
                `${config.apiRootUrl}/rpm/providers-summary`,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_GET_PROVIDERS_SUMMARY_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.date)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_GET_PROVIDERS_SUMMARY_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissGetProvidersSummaryError() {
    return {
        type: RPM_GET_PROVIDERS_SUMMARY_DISMISS_ERROR,
    }
}

export function useGetProvidersSummary() {
    const dispatch = useDispatch()

    const {
        providersSummary,
        getProvidersSummaryPending,
        getProvidersSummaryError,
    } = useSelector(
        state => ({
            providersSummary: state.rpm.providersSummary,
            getProvidersSummaryPending: state.rpm.getProvidersSummaryPending,
            getProvidersSummaryError: state.rpm.getProvidersSummaryError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(getProvidersSummary(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissGetProvidersSummaryError())
    }, [dispatch])

    useEffect(() => {
        if (!providersSummary) {
            boundAction()
        }
    }, [boundAction, providersSummary])

    return {
        providersSummary,
        getProvidersSummary: boundAction,
        getProvidersSummaryPending,
        getProvidersSummaryError,
        dismissGetProvidersSummaryError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_GET_PROVIDERS_SUMMARY_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                getProvidersSummaryPending: true,
                getProvidersSummaryError: null,
            }

        case RPM_GET_PROVIDERS_SUMMARY_SUCCESS:
            // The request is success
            return {
                ...state,
                providersSummary: action.data,
                getProvidersSummaryPending: false,
                getProvidersSummaryError: null,
            }

        case RPM_GET_PROVIDERS_SUMMARY_FAILURE:
            // The request is failed
            return {
                ...state,
                getProvidersSummaryPending: false,
                getProvidersSummaryError: action.data.error,
            }

        case RPM_GET_PROVIDERS_SUMMARY_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                getProvidersSummaryError: null,
            }

        default:
            return state
    }
}
