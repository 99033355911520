import { Formik } from 'formik'
import React, { Fragment, useEffect } from 'react'
import * as Yup from 'yup'
import Icon from '../../../../common/components/Icon'
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage'
import { useGetUserInsuranceInformation } from '../../../redux/getUserInsuranceInformation'
import { useUpdateUserInsuranceInformation } from '../../../redux/updateUserInsuranceInformation'
import DatePicker from 'react-datepicker'

const insuranceSchema = Yup.object().shape({
    insurance_provider: Yup.string().required('Insurance Provider is required'),
    member_id: Yup.string().required('Member ID is required'),
    policy_holder: Yup.string().required('Policy Holder is required'),
    group_number: Yup.string().required('Group Number is required'),
})

export default function InsuranceDetails({ user, fetchUser }) {
    const [editing, setEditing] = React.useState(false)

    const {
        updateUserInsuranceInformation,
    } = useUpdateUserInsuranceInformation()

    const {
        getUserInsuranceInformation,
        userInsuranceInformation,
        getUserInsuranceInformationPending,
    } = useGetUserInsuranceInformation()

    useEffect(() => {
        if (user) getUserInsuranceInformation({ userId: user.id })
    }, [user])

    const { t } = useTranslation()

    if (!user || getUserInsuranceInformationPending) return null

    return (
        <Formik
            initialValues={{
                insurance_provider:
                    userInsuranceInformation?.insurance_provider || '',
                member_id: userInsuranceInformation?.member_id || '',
                policy_holder: userInsuranceInformation?.policy_holder || '',
                group_number: userInsuranceInformation?.group_number || '',
                policy_holder_dob:
                    userInsuranceInformation?.policy_holder_dob || '',
            }}
            validationSchema={insuranceSchema}
            onSubmit={values => {
                updateUserInsuranceInformation({
                    userId: user.id,
                    method: userInsuranceInformation ? 'PUT' : 'POST',
                    ...values,
                })
                    .then(res => {
                        console.log(res)
                        fetchUser().then(() => {
                            setEditing(false)
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }}
        >
            {({ values, errors, handleChange, handleSubmit }) => (
                <Fragment>
                    <div className="profile-details-section__header mrm-mt-2">
                        <p className="profile-details-section__header__title">
                            Insurance Information
                        </p>
                        <p
                            className="profile-details-section__header__edit"
                            onClick={() =>
                                editing ? handleSubmit() : setEditing(true)
                            }
                            role="button"
                        >
                            {editing
                                ? t('client_profile.save')
                                : t('client_profile.edit')}
                        </p>
                    </div>

                    <div className="profile-details-section__body">
                        <div className="profile-details-section__body__row">
                            <div className="profile-details-section__body__row__label">
                                <div className="profile-details-section__body__row__label__icon">
                                    <Icon name="shield" />
                                </div>
                                <p className="profile-details-section__body__row__label__title">
                                    {t('client_profile.insurance_provider')}
                                </p>
                            </div>
                            {editing ? (
                                <div className="profile-details-section__body__row__input-container">
                                    <input
                                        type="text"
                                        name="insurance_provider"
                                        placeholder={t(
                                            'client_profile.insurance_provider'
                                        )}
                                        className="profile-details-section__body__row__input"
                                        onChange={handleChange}
                                        value={values.insurance_provider || ''}
                                    />
                                    {editing && Object.keys(errors).length > 0 && (
                                        <div
                                            className="profile-details-section-validation-alert"
                                            style={{
                                                top: '-3rem',
                                            }}
                                        >
                                            {/** show one error at a time here*/}
                                            <div className="alert-icon-container">
                                                <div
                                                    className="alert-icon-square"
                                                    style={{
                                                        backgroundColor:
                                                            '#FFFFFF',
                                                    }}
                                                />
                                                <p
                                                    className="alert-icon"
                                                    style={{
                                                        color: '#e8744f',
                                                    }}
                                                >
                                                    !
                                                </p>
                                            </div>
                                            <p className="profile-details-section-validation-alert__message">
                                                {Object.values(errors)[0]}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <p className="profile-details-section__body__row__value">
                                    {user?.insurance_information
                                        ?.insurance_provider || ''}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="profile-details-section__body">
                        <div className="profile-details-section__body__row">
                            <div className="profile-details-section__body__row__label">
                                <div className="profile-details-section__body__row__label__icon">
                                    <Icon name="health-id" />
                                </div>
                                <p className="profile-details-section__body__row__label__title">
                                    {t('client_profile.member_id')}
                                </p>
                            </div>
                            {editing ? (
                                <input
                                    type="text"
                                    name="member_id"
                                    placeholder={t('client_profile.member_id')}
                                    className="profile-details-section__body__row__input"
                                    onChange={handleChange}
                                    value={values.member_id}
                                />
                            ) : (
                                <p className="profile-details-section__body__row__value">
                                    {user?.insurance_information?.member_id ||
                                        ''}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="profile-details-section__body">
                        <div className="profile-details-section__body__row">
                            <div className="profile-details-section__body__row__label">
                                <div className="profile-details-section__body__row__label__icon">
                                    <Icon name="user" size={16} />
                                </div>
                                <p className="profile-details-section__body__row__label__title">
                                    {t('client_profile.policy_holder')}
                                </p>
                            </div>
                            {editing ? (
                                <input
                                    type="text"
                                    name="policy_holder"
                                    placeholder={t(
                                        'client_profile.policy_holder'
                                    )}
                                    className="profile-details-section__body__row__input"
                                    onChange={handleChange}
                                    value={values.policy_holder}
                                />
                            ) : (
                                <p className="profile-details-section__body__row__value">
                                    {user?.insurance_information
                                        ?.policy_holder || ''}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="profile-details-section__body">
                        <div className="profile-details-section__body__row">
                            <div className="profile-details-section__body__row__label">
                                <div className="profile-details-section__body__row__label__icon">
                                    <Icon name="health-id" />
                                </div>
                                <p className="profile-details-section__body__row__label__title">
                                    {t('client_profile.group_number')}
                                </p>
                            </div>
                            {editing ? (
                                <input
                                    type="text"
                                    name="group_number"
                                    placeholder={t(
                                        'client_profile.group_number'
                                    )}
                                    className="profile-details-section__body__row__input"
                                    onChange={handleChange}
                                    value={values.group_number}
                                />
                            ) : (
                                <p className="profile-details-section__body__row__value">
                                    {user?.insurance_information
                                        ?.group_number || ''}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="profile-details-section__body">
                        <div className="profile-details-section__body__row">
                            <div className="profile-details-section__body__row__label">
                                <div className="profile-details-section__body__row__label__icon">
                                    <Icon name="modern-calendar" />
                                </div>
                                <p className="profile-details-section__body__row__label__title">
                                    {t('client_profile.policy_holder_dob')}
                                </p>
                            </div>
                            {editing ? (
                                <input
                                    type="date"
                                    name="policy_holder_dob"
                                    placeholder={t(
                                        'client_profile.group_number'
                                    )}
                                    className="profile-details-section__body__row__input"
                                    onChange={handleChange}
                                    value={values.policy_holder_dob}
                                />
                            ) : (
                                <p className="profile-details-section__body__row__value">
                                    {user?.insurance_information
                                        ?.policy_holder_dob || ''}
                                </p>
                            )}
                        </div>
                    </div>
                </Fragment>
            )}
        </Formik>
    )
}
