import React, { Fragment } from 'react';
import { useListDevices } from '../../redux/listDevices';

export default function Step1({ currentStep, setCurrentStep }) {

  const { devices } = useListDevices();

  if (!devices) return null;


  const fitbitUri = devices.results.find(device => device.description === 'fitbit').uri_consent.web_url;

  return (
    <Fragment>
      <div
        className="step"
        style={{
          opacity: currentStep === 1 ? 1 : 0,
          zIndex: currentStep === 1 ? 1 : -1,
          transition: 'all ease-in-out 0.5s',
        }}
      >
        <h1 className="title">Connect device</h1>
        <p className="description">
          Noble will use your fitbit app to pull in your heart rate variablility
          and steps. We'll use all this information to inform your provider on
          the best way to manage your symptoms.
        </p>
        <a
          className="done-button"
          target={'_blank'}
          rel="noopener noreferrer"
          href={fitbitUri}
        >
          Connect to Fitbit
        </a>
      </div>

      <button
        className="bottom-question"
        onClick={() => setCurrentStep(0)}
        style={{
          zIndex: currentStep === 1 ? 1 : -1,
          opacity: currentStep === 1 ? 1 : 0,
          transition: 'all ease-in-out 0.5s',
        }}
      >
        Don't have the Fitbit App?
      </button>
    </Fragment>
  );
}
