import React from 'react';
import Icon from '../../../common/components/Icon';

export default function SearchBar({ searchText, setSearchText }) {
  return (
    <div className="content-search">
      <input
        type="text"
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Icon name="search" size={20} color="var(--primary-gray)" />
    </div>
  );
}
