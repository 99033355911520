import React from 'react'
import { Modal } from 'react-bootstrap'
import SuccessIcon from '../../assets/sent.svg'

export default function ResendInvitationConfirmation({ show, onHide }) {
    return (
        <Modal show={show} onHide={onHide} className="rpm-modal">
            <img src={SuccessIcon} alt="Success" />
            <h3>Success!</h3>

            <p>The invitation is on its way.</p>

            <p className="tip">
                Tip: If your clients are still not seeing it, have them check
                their spam folder.
            </p>

            <button onClick={onHide}>
                <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.69433 8.47014L6.5139 5.28971L9.70113 2.10249C9.81945 2.01665 9.91784 1.90623 9.98948 1.77884C10.0611 1.65144 10.1043 1.51011 10.1161 1.36459C10.1279 1.21908 10.1079 1.07285 10.0577 0.935981C10.0074 0.799115 9.92804 0.674867 9.825 0.571831C9.72197 0.468794 9.59772 0.389402 9.46085 0.339148C9.32399 0.288895 9.17776 0.268973 9.03224 0.280748C8.88673 0.292524 8.74539 0.33572 8.618 0.407358C8.4906 0.478996 8.38019 0.577383 8.29435 0.695704L5.10712 3.88293L1.93023 0.706043C1.74132 0.517129 1.48486 0.411252 1.21726 0.411688C0.949659 0.412123 0.692835 0.518835 0.503304 0.708365C0.313774 0.897896 0.207062 1.15472 0.206627 1.42232C0.206191 1.68992 0.312068 1.94638 0.500983 2.13529L3.67787 5.31218L0.512081 8.47797C0.39376 8.56381 0.295373 8.67422 0.223735 8.80162C0.152097 8.92902 0.1089 9.07035 0.0971252 9.21586C0.0853497 9.36138 0.105272 9.50761 0.155525 9.64448C0.205779 9.78134 0.285174 9.90559 0.388211 10.0086C0.491247 10.1117 0.615492 10.1911 0.752358 10.2413C0.889223 10.2916 1.03545 10.3115 1.18097 10.2997C1.32649 10.2879 1.46782 10.2447 1.59521 10.1731C1.72261 10.1015 1.83302 10.0031 1.91886 9.88475L5.09894 6.70467L8.27937 9.88511C8.46829 10.074 8.72477 10.1799 8.99237 10.1795C9.25997 10.179 9.51677 10.0723 9.7063 9.88278C9.89583 9.69325 10.0026 9.43645 10.003 9.16885C10.0034 8.90125 9.89754 8.64477 9.70862 8.45585L9.69433 8.47014Z"
                        fill="#343434"
                    />
                </svg>{' '}
            </button>
        </Modal>
    )
}
