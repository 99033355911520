import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_FITBIT_OAUTH_BEGIN,
  RPM_FITBIT_OAUTH_SUCCESS,
  RPM_FITBIT_OAUTH_FAILURE,
  RPM_FITBIT_OAUTH_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function fitbitOauth(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_FITBIT_OAUTH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      const doRequest = axios.post(config.apiRootUrl + '/rpm/integrations/fitbit/oauth/',
        args, createAxiosConfigWithAuth(getState()))

      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_FITBIT_OAUTH_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_FITBIT_OAUTH_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      ).catch((err) => {
        console.log(err)
      });
    });

    return promise;
  };
}

export function dismissFitbitOauthError() {
  return {
    type: RPM_FITBIT_OAUTH_DISMISS_ERROR,
  };
}

export function useFitbitOauth() {
  const dispatch = useDispatch();

  const { fitbitOauthPending, fitbitOauthError } = useSelector(
    state => ({
      fitbitOauthPending: state.rpm.fitbitOauthPending,
      fitbitOauthError: state.rpm.fitbitOauthError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fitbitOauth(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFitbitOauthError());
  }, [dispatch]);

  return {
    fitbitOauth: boundAction,
    fitbitOauthPending,
    fitbitOauthError,
    dismissFitbitOauthError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_FITBIT_OAUTH_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fitbitOauthPending: true,
        fitbitOauthError: null,
      };

    case RPM_FITBIT_OAUTH_SUCCESS:
      // The request is success
      return {
        ...state,
        fitbitOauthPending: false,
        fitbitOauthError: null,
      };

    case RPM_FITBIT_OAUTH_FAILURE:
      // The request is failed
      return {
        ...state,
        fitbitOauthPending: false,
        fitbitOauthError: action.data.error,
      };

    case RPM_FITBIT_OAUTH_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fitbitOauthError: null,
      };

    default:
      return state;
  }
}
