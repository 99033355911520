import pluralize from 'pluralize';
import React, { Suspense, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';
import {
  CurrentUserHasNoSubscription,
  HasDeclinedPayment,
  useHasActiveSubcription,
  useHasCanceledSubscription,
} from '../../../common/permissions';
import { DesktopHeader, Loader } from '../../common';
import Icon from '../../common/components/Icon';
import { useGetChargesByCustomer } from '../redux/getChargesByCustomer';
import { useGetPaymentMethod } from '../redux/getPaymentMethod';
import { useFetchUser } from '../redux/hooks';
import BasicInfo from './profile/BasicInfo';
import BillingHistory from './profile/BillingHistory';
import CreditCard from './profile/CreditCard';
import MyClients from './profile/MyClients';
import ProfilePictureContainer from './profile/ProfilePictureContainer';
import SubscriptionContainer from './profile/SubscriptionContainer';

export default function ProfileView({ user, onEdit }) {
  // permissions for the current user
  const { subscriptionState, currentPeriodEnd } = useHasActiveSubcription();

  const { isCanceled, cancelEnd } = useHasCanceledSubscription();
  const hasDeclinedPayment = HasDeclinedPayment();
  const hasNoSubscription = CurrentUserHasNoSubscription();

  // hooks
  const { getChargesByCustomer } = useGetChargesByCustomer();
  const { getPaymentMethod } = useGetPaymentMethod();
  const { replaceStringWithSynonyms } = useFetchUser();

  const groupNames = replaceStringWithSynonyms(user.groups.join(', '));
  const cohortNames =
    user.cohort.length > 0 ? user.cohort.map((c) => c.name).join(', ') : 'None';

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [showChangePaymentMethod, setShowChangePaymentMethod] = useState(false);

  useEffect(() => {
    getPaymentMethod()
      .then((res) => {
        if (res) {
          console.log('paymentMethod', res);
          setPaymentMethod(res.data.data.methods[0]);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [getPaymentMethod]);

  useEffect(() => {
    getChargesByCustomer({ customerId: user.id })
      .then((res) => {
        setInvoices(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [getChargesByCustomer, user.id]);

  const [tabSelected, setTabSelected] = React.useState('Profile');

  return (
    <Suspense fallback={<Loader position="static" />}>
      <div className="profile-view">
        <DesktopHeader />
        <div className="d-lg-none profile-tab-selector">
          <div className="profile-header">
            <Link to="/">
              <Icon name="chevronLeft" size="12" />
            </Link>
            <h1>Profile</h1>
          </div>
          <ProfilePictureContainer
            user={user}
            onEdit={onEdit}
            styles={{ padding: '1rem 1rem' }}
          />
          <div className="profile-tabs">
            <button
              className="profile-tab"
              onClick={() => setTabSelected('Profile')}
              style={{ justifyItems: 'start' }}
            >
              <p>Profile</p>
              {tabSelected === 'Profile' && <Icon name="line" size="4" />}
            </button>
            {user.groups.includes('User') && (
              <button
                className="profile-tab"
                onClick={() => setTabSelected('Subscription')}
              >
                <p>Subscription</p>
                {tabSelected === 'Subscription' && (
                  <Icon name="line" size="4" />
                )}
              </button>
            )}

            {user.groups.includes('User') && (
              <button
                className="profile-tab"
                onClick={() => setTabSelected('Billing')}
              >
                <p>Billing History</p>
                {tabSelected === 'Billing' && <Icon name="line" size="4" />}
              </button>
            )}
          </div>
          <div className="profile-tab-content">
            {tabSelected === 'Profile' && (
              <>
                <BasicInfo
                  user={user}
                  onEdit={onEdit}
                  styles={{ padding: '1rem 1rem' }}
                  desktop={false}
                  groupNames={groupNames}
                  cohortNames={groupNames}
                />

                {user.groups.includes('Coach') && (
                  <MyClients
                    user={user}
                    onEdit={onEdit}
                    styles={{ padding: '1rem 1rem' }}
                    title={`My ${pluralize(user.synonyms.user, 2)}`}
                    desktop={false}
                  />
                )}
                {user.groups.includes('User') && (
                  <MyClients
                    user={user}
                    onEdit={onEdit}
                    styles={{ padding: '1rem 1rem' }}
                    title={`My ${pluralize(user.synonyms.coach, 2)}`}
                    desktop={false}
                    type="coaches"
                  />
                )}
              </>
            )}
            {tabSelected === 'Subscription' &&
              !hasNoSubscription &&
              paymentMethod && (
                <>
                  <SubscriptionContainer desktop={false} />
                  <hr />
                  <CreditCard
                    desktop={false}
                    show={showChangePaymentMethod}
                    last4={paymentMethod?.card?.last4}
                    brand={paymentMethod?.card?.brand}
                  />
                </>
              )}
            {tabSelected === 'Billing' &&
              invoices &&
              user.groups.includes('User') && (
                <>
                  <BillingHistory desktop={false} invoices={invoices} />
                </>
              )}
          </div>
        </div>
        <div className="profile-container container-lg">
          {subscriptionState === 'canceled but active' && (
            <div className="active-but-canceled">
              {' '}
              <p>
                Your subscription has been canceled. Your account will still be
                active until the end of your current billing cycle (
                {currentPeriodEnd}).
              </p>{' '}
              <a
                href="mailto:hello@noble.health"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>contact us</button>
              </a>
            </div>
          )}
          {isCanceled && (
            <div className="active-but-canceled">
              {' '}
              <p>
                Your subscription ended on {cancelEnd}. To resume your plan,
                reactivate your subsctiption.
              </p>{' '}
              <a
                href="mailto:hello@noble.health"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>contact us</button>
              </a>
            </div>
          )}
          {hasDeclinedPayment && (
            <div className="active-but-canceled">
              {' '}
              <p style={{ margin: '0 auto' }}>
                There was a problem processing payment
                <span
                  onClick={() => {
                    setShowChangePaymentMethod(true);
                  }}
                >
                  {'view more'}
                </span>
              </p>{' '}
            </div>
          )}

          <Masonry
            breakpointCols={{ default: 2, 991: 1 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <BasicInfo
              onEdit={onEdit}
              user={user}
              groupNames={groupNames}
              cohortNames={cohortNames}
              desktop
            />

            {user.groups.includes('User') && !hasNoSubscription && (
              <div className="right-grid">
                <SubscriptionContainer />

                {paymentMethod !== null && !hasNoSubscription && (
                  <CreditCard
                    last4={paymentMethod?.card?.last4}
                    brand={paymentMethod?.card?.brand}
                    show={showChangePaymentMethod}
                  />
                )}
                {invoices && <BillingHistory invoices={invoices} />}
              </div>
            )}

            {user.groups.includes('Coach') && (
              <MyClients
                title={`My ${pluralize(user.synonyms.user, 2)}`}
                user={user}
              />
            )}

            {user.groups.includes('User') && (
              <MyClients
                title={`My ${pluralize(user.synonyms.coach, 2)}`}
                user={user}
                type="coaches"
              />
            )}
          </Masonry>
        </div>
      </div>
    </Suspense>
  );
}

ProfileView.propTypes = {};
ProfileView.defaultProps = {};
