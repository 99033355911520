import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_GET_USER_INSURANCE_INFORMATION_BEGIN,
  RPM_GET_USER_INSURANCE_INFORMATION_SUCCESS,
  RPM_GET_USER_INSURANCE_INFORMATION_FAILURE,
  RPM_GET_USER_INSURANCE_INFORMATION_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import axios from 'axios'

export function getUserInsuranceInformation(args = {}) {
  const {userId} = args;
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_GET_USER_INSURANCE_INFORMATION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(`${config.apiRootUrl}/users/${userId}/insurance-information`, {
        ...createAxiosConfigWithAuth(
          getState(),
        ),
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_GET_USER_INSURANCE_INFORMATION_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_GET_USER_INSURANCE_INFORMATION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetUserInsuranceInformationError() {
  return {
    type: RPM_GET_USER_INSURANCE_INFORMATION_DISMISS_ERROR,
  };
}

export function useGetUserInsuranceInformation() {
  const dispatch = useDispatch();

  const { getUserInsuranceInformationPending, getUserInsuranceInformationError, userInsuranceInformation } = useSelector(
    state => ({
      getUserInsuranceInformationPending: state.rpm.getUserInsuranceInformationPending,
      getUserInsuranceInformationError: state.rpm.getUserInsuranceInformationError,
      userInsuranceInformation: state.rpm.userInsuranceInformation,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getUserInsuranceInformation(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetUserInsuranceInformationError());
  }, [dispatch]);

  return {
    userInsuranceInformation,
    getUserInsuranceInformation: boundAction,
    getUserInsuranceInformationPending,
    getUserInsuranceInformationError,
    dismissGetUserInsuranceInformationError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_GET_USER_INSURANCE_INFORMATION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getUserInsuranceInformationPending: true,
        getUserInsuranceInformationError: null,
      };

    case RPM_GET_USER_INSURANCE_INFORMATION_SUCCESS:
      // The request is success
      return {
        ...state,
        userInsuranceInformation: action.data,
        getUserInsuranceInformationPending: false,
        getUserInsuranceInformationError: null,
      };

    case RPM_GET_USER_INSURANCE_INFORMATION_FAILURE:
      // The request is failed
      return {
        ...state,
        getUserInsuranceInformationPending: false,
        getUserInsuranceInformationError: action.data.error,
      };

    case RPM_GET_USER_INSURANCE_INFORMATION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getUserInsuranceInformationError: null,
      };

    default:
      return state;
  }
}
