const replaceBadLinks = (ref) => {
  const intervalId = setInterval(() => {
    if (!!ref.current) {
      clearInterval(intervalId);
      const badLinks = ref.current.querySelectorAll('a:not([href^="http"])');

      badLinks.forEach(function (link) {
        link.setAttribute('href', `http://${link.getAttribute('href')}`);
      });
    }
  }, 10);

  return intervalId;
};

export { replaceBadLinks };
