import React from 'react';

export default function BioIcon({ className, size = 77, style = {}, color }) {
  return (
    <svg
      width={size}
      height={size / 0.9}
      viewBox="0 0 76 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
      color={color}
    >
      <path
        d="M63.6567 64.9275C63.6449 66.5391 62.9995 68.0814 61.8598 69.221C60.7202 70.3606 59.1779 71.0061 57.5663 71.0179H11.9554C10.3595 70.9827 8.84009 70.327 7.71979 69.1898C6.59949 68.0527 5.96648 66.5237 5.95513 64.9275V19.3168C5.96648 17.7206 6.59949 16.1916 7.71979 15.0544C8.84009 13.9173 10.3595 13.2615 11.9554 13.2264H37.7609L43.6258 7.36149H12.0456C10.468 7.34958 8.90369 7.65002 7.44277 8.2455C5.98184 8.84098 4.65322 9.71971 3.53349 10.8311C2.41375 11.9424 1.52505 13.2644 0.918603 14.7208C0.312155 16.1772 -4.49263e-05 17.7392 4.84912e-09 19.3168V64.9275C4.84912e-09 68.0982 1.25958 71.1391 3.50165 73.3811C5.74372 75.6232 8.78461 76.8828 11.9554 76.8828H57.5663C60.737 76.8828 63.7779 75.6232 66.02 73.3811C68.2621 71.1391 69.5216 68.0982 69.5216 64.9275V34.0692L63.6567 39.9341V64.9275Z"
        fill={color}
      />
      <path
        d="M73.5939 9.21409L65.7632 1.66303C65.2734 1.14768 64.6846 0.734999 64.0316 0.449366C63.3785 0.163733 62.6744 0.0109362 61.9609 0H61.6893C59.986 0.0407523 58.3644 0.733164 57.1629 1.93271L22.0375 36.9912C21.5282 37.495 21.2223 38.1665 21.1775 38.879L20.4532 50.8797C20.4282 51.2821 20.4873 51.6852 20.6267 52.0638C20.7662 52.4423 20.9831 52.7881 21.2637 53.0794C21.5443 53.3707 21.8825 53.6012 22.2572 53.7564C22.6319 53.9115 23.0348 53.988 23.4407 53.9811H36.0243C36.7915 53.9404 37.5167 53.6204 38.0612 53.0821L73.1413 18.0237C73.763 17.4807 74.2689 16.8196 74.6292 16.0794C74.9895 15.3391 75.1968 14.5348 75.239 13.7137C75.2812 12.8926 75.1574 12.0715 74.8748 11.2987C74.5923 10.5259 74.1568 9.81707 73.5939 9.21409ZM26.5187 48.0481L27.0166 40.3622L53.1796 14.1582L60.8746 21.7542L34.7116 47.9133L26.5187 48.0481ZM69.0675 13.3492C69.0246 13.4738 68.9459 13.5831 68.8412 13.6638L64.9031 17.6191L57.3439 10.0231L61.2367 6.06781C61.2367 6.06781 61.6441 6.06781 61.5083 5.79813L69.0675 13.3492Z"
        fill={color}
      />
    </svg>
  );
}
