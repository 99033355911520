import React from 'react';

export default function SearchIcon({
  color = '#343434',
  size = 17,
  className = '',
}) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3875 12.1125L9.48884 9.21427C10.3008 8.33194 10.7347 7.16663 10.6976 5.96815C10.6606 4.76967 10.1554 3.63341 9.29051 2.80296C8.4256 1.97251 7.26976 1.51395 6.07076 1.52561C4.87176 1.53726 3.72505 2.01819 2.87644 2.8653C2.02783 3.71241 1.54488 4.85827 1.5311 6.05724C1.51733 7.25622 1.97384 8.41287 2.80276 9.27925C3.63168 10.1456 4.76705 10.6528 5.96546 10.692C7.16387 10.7312 8.32994 10.2993 9.21371 9.48892L12.1124 12.3876C12.1305 12.4057 12.1519 12.42 12.1755 12.4298C12.1991 12.4396 12.2244 12.4446 12.25 12.4446C12.2755 12.4446 12.3008 12.4396 12.3244 12.4298C12.348 12.42 12.3695 12.4057 12.3875 12.3876C12.4056 12.3695 12.4199 12.3481 12.4297 12.3245C12.4395 12.3009 12.4445 12.2756 12.4445 12.25C12.4445 12.2245 12.4395 12.1992 12.4297 12.1756C12.4199 12.152 12.4056 12.1305 12.3875 12.1125ZM6.12496 10.3056C5.29812 10.3056 4.48985 10.0604 3.80236 9.60104C3.11487 9.14167 2.57904 8.48876 2.26263 7.72486C1.94621 6.96096 1.86342 6.12039 2.02473 5.30944C2.18604 4.4985 2.58419 3.75359 3.16886 3.16893C3.75352 2.58427 4.49842 2.18611 5.30937 2.0248C6.12032 1.8635 6.96089 1.94628 7.72478 2.2627C8.48868 2.57912 9.14159 3.11495 9.60096 3.80244C10.0603 4.48993 10.3055 5.2982 10.3055 6.12503C10.3042 7.23339 9.86336 8.29598 9.07963 9.07971C8.29591 9.86344 7.23331 10.3043 6.12496 10.3056Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
      />
    </svg>
  );
}
