import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../../common/components/Icon';
import { Link } from 'react-router-dom';

export default function TabSelector() {
  const history = useHistory();
  return (
    <div className="payments-tab-selector">
      <div className="d-lg-none mobile-title">
        <Link to="/">
          <Icon name="chevronLeft" color="#343434" size={12} />
        </Link>
        <h2>Payments</h2>
      </div>
      <div className="payment-sections d-lg-none">
        <div className="payment-section">
          <button onClick={() => history.push('/dashboard/subscriptions')}>
            <p>Subscriptions</p>
          </button>
          {history.location.pathname === '/dashboard/subscriptions' && (
            <Icon name="line" color="#343434" size={4} />
          )}
        </div>
        <div className="payment-section">
          <button onClick={() => history.push('/dashboard/clients')}>
            <p>Clients</p>
          </button>
          {history.location.pathname === '/dashboard/clients' && (
            <Icon name="line" color="#343434" size={4} />
          )}{' '}
        </div>
        <div className="payment-section">
          <a
            href="https://dashboard.stripe.com/payments"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Payouts</p>
          </a>
        </div>
      </div>
    </div>
  );
}
