import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import Icon from '../../../../common/components/Icon';
import pauseIcon from '../../../images/pause.svg';
import playIcon from '../../../images/play.svg';
import { wave } from './wave';
import { AudioPlayingContext } from '../../contexts/AudioPlayingProvider';

const AudioPlayerSend = ({
  url,
  clearBlobUrl,
  setUploadedFile,
  handleSubmit,
}) => {
  const { messagePlaying, setMessagePlaying, recordingAudio } =
    useContext(AudioPlayingContext);

  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const { togglePlayPause, ready, loading, playing } = useAudioPlayer({
    src: url,
    format: 'mp3',
    autoplay: false,
    onend: () => console.log('sound has ended!'),
  });

  useEffect(() => {
    if (messagePlaying !== url && playing) {
      togglePlayPause();
    }
  }, [messagePlaying, url]);

  const { percentComplete, duration, seek } = useAudioPosition({
    highRefreshRate: true,
  });

  const goToPosition = useCallback(
    (percentage) => {
      seek(duration * percentage);
    },
    [duration, seek],
  );

  useEffect(() => {
    console.log('percentageComplete', percentComplete);
  }, [percentComplete]);

  const [audioContext, setAudioContext] = useState(null);
  const [barHeights, setBarHeights] = useState(wave);

  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    setAudioContext(new AudioContext());
  }, [setAudioContext]);

  useEffect(() => {
    if (audioContext) {
      const { log } = console;

      const filterData = (audioBuffer) => {
        const rawData = audioBuffer.getChannelData(0);
        const samples = 70;
        const blockSize = Math.floor(rawData.length / samples);
        return new Array(samples)
          .fill(0)
          .map((_, i) =>
            rawData
              .slice(i * blockSize, (i + 1) * blockSize)
              .reduce((sum, val) => sum + Math.abs(val), 0),
          );
      };

      const normalizeData = (filteredData) => {
        const multiplier = Math.max(...filteredData) ** -1;
        return filteredData.map((val) => val * multiplier);
      };

      const drawAudio = async (url) => {
        log(`Fetching data from ${url}...`);
        const response = await fetch(url);
        log('Getting array buffer...');
        const arrayBuffer = await response.arrayBuffer();
        log('Decoding audio data...');
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        log('Calculating...');
        const data = normalizeData(filterData(audioBuffer));
        setBarHeights(data);
        const averagedData = new Array(44).fill(0).map((_, i) => {
          const start = Math.floor((i * data.length) / 44);
          const end = Math.floor(((i + 1) * data.length) / 44);
          return (
            data.slice(start, end).reduce((sum, val) => sum + val, 0) /
            (end - start)
          );
        });
        setBarHeights(averagedData.map((val) => (val <= 0.3 ? 0.3 : val)));
        log('Done.');
      };

      drawAudio(url);
    }
  }, [audioContext, setBarHeights]);

  if (!ready && !loading) return <div>No audio to play</div>;
  if (loading) return <div>Loading audio</div>;

  // 3.36 to 3:36
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  const minutesPercentagePlayed = Math.floor(
    (duration * (percentComplete / 100)) / 60,
  );
  const secondsPercentagePlayed = Math.floor(
    (duration * (percentComplete / 100)) % 60,
  );

  return (
    <div className="flex flex-col items-center gap-1 w-full audio-player-send">
      <p className="state-label">
        {confirmingDelete
          ? 'Are you sure you want to delete this audio?'
          : 'Review Audio Message'}
      </p>

      <div className="player-container">
        <div className="bars-and-time-container">
          <p className="time">
            {
              // minutes and seconds percentage played
              `${
                minutesPercentagePlayed < 10
                  ? `0${minutesPercentagePlayed}`
                  : minutesPercentagePlayed
              }:${
                secondsPercentagePlayed < 10
                  ? `0${secondsPercentagePlayed}`
                  : secondsPercentagePlayed
              }`
            }
          </p>
          {barHeights.map((height, idx) => (
            <div
              key={idx}
              onClick={() => goToPosition((idx + 1) / 45)}
              className="bar"
              style={{
                height: `${height * 3}rem`,
                borderRadius: '1.4rem',
                marginRight: idx === 43 ? '0' : '3px',
                background:
                  percentComplete < idx * 2.27
                    ? '#e5e5e5'
                    : 'var(--primary-dark)',
              }}
            />
          ))}
          <p className="time right">
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
        </div>

        <div
          style={{
            display: confirmingDelete ? 'none' : 'flex',
            alignItems: 'center',
            gap: '1.5rem',
            width: '80%',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => {
              setConfirmingDelete(true);
            }}
          >
            <Icon name="delete" size={21} color="#7D7D7D" />
          </button>{' '}
          <button
            onClick={() => {
              togglePlayPause();
              setMessagePlaying(url);
            }}
            style={{ outline: 'none' }}
          >
            {playing ? (
              <img src={pauseIcon} alt="pause" style={{ width: '2.5rem' }} />
            ) : (
              <img src={playIcon} alt="play" style={{ width: '2.5rem' }} />
            )}
          </button>
          <button onClick={handleSubmit}>
            <Icon name="send" size={19} color="#7D7D7D" />
          </button>
        </div>
      </div>

      {confirmingDelete && (
        <div className="confirm-delete">
          <button
            className="delete"
            onClick={() => {
              clearBlobUrl();
              setUploadedFile(null);
              setConfirmingDelete(false);
            }}
          >
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmingDelete(false);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default AudioPlayerSend;
