import React from 'react'

export default function HrvIcon({ size = 14, color = '#000', ...props }) {
    return (
        <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{
                transform: `scale(${size / 14})`,
            }}
        >
            <path
                d="M2.62682 5.81948C2.62686 5.81952 2.62678 5.81944 2.62682 5.81948V5.81948Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.38241 1.68254C8.65218 0.360566 10.7057 0.339895 12 1.62053L12.0596 1.68254C13.3511 3.02715 13.3511 5.21739 12.0596 6.562L7.61119 11.1933C7.44513 11.3662 7.2231 11.4616 6.98865 11.4616C6.75394 11.4616 6.5319 11.3674 6.36611 11.1933L2.31052 6.97096L2.31358 6.96777L1.92078 6.55881C1.29631 5.90867 0.952148 5.04255 0.952148 4.11908C0.952148 3.19562 1.29636 2.32947 1.92231 1.67935C3.21225 0.336375 5.31109 0.336375 6.60102 1.67935L6.99325 2.0877L7.38241 1.68254ZM2.5757 2.37117C1.6872 3.29619 1.6507 4.77757 2.46619 5.74831H2.4571L3.62073 6.95979L3.61766 6.96298L6.98712 10.471L11.4031 5.87338C12.3297 4.90872 12.3297 3.33902 11.4031 2.37436C10.475 1.40807 8.96233 1.40813 8.0342 2.37442L7.40425 3.03187C7.17659 3.26889 6.80684 3.26889 6.57918 3.03187L5.94457 2.37117C5.01644 1.40487 3.50383 1.40487 2.5757 2.37117Z"
                fill={color}
            />
            <path
                d="M5.47109 6.94173H4.88593L4.88746 6.94013H4.71845C4.51873 6.94013 4.35205 6.77086 4.35205 6.55571C4.35205 6.34056 4.51873 6.17128 4.71845 6.17128H5.2429L6.19264 4.10084C6.25731 3.95945 6.40007 3.87363 6.54823 3.88417C6.69743 3.8947 6.82495 3.99876 6.87058 4.15206L7.57433 6.5216L8.05987 5.43619C8.12093 5.30102 8.25094 5.21533 8.39122 5.21533H9.41978C9.6195 5.21533 9.78618 5.38461 9.78618 5.59976C9.78618 5.81491 9.6195 5.98419 9.41978 5.98419H8.62353L7.83658 7.74383C7.77552 7.87901 7.64525 7.9647 7.50497 7.9647H7.47727C7.33207 7.95319 7.20106 7.8462 7.1557 7.69523L6.44819 5.31252L5.80106 6.72373C5.74027 6.85705 5.60891 6.94173 5.47109 6.94173Z"
                fill={color}
            />
            <path
                d="M4.71525 7.01212L4.71692 7.01213L4.71845 7.01213"
                fill={color}
            />
            <path
                d="M4.71525 7.01212C4.71443 7.01212 4.7136 7.01211 4.71278 7.0121L4.71525 7.01212Z"
                fill={color}
            />
        </svg>
    )
}
