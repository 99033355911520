import dayjs from 'dayjs'
import { Formik } from 'formik'
import React, { Fragment } from 'react'
import * as Yup from 'yup'
import Icon from '../../../../common/components/Icon'
import { useUpdateUser } from '../../../../manage/redux/updateUser'
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../../../../user/redux/fetchUser'

import Switch from 'react-switch'

const profileSchema = Yup.object().shape({
  email: Yup.string().required('Email Address is required'),
  phone_number: Yup.string(),
  groups: Yup.array()
    .min(1, 'At least one type is required')
    .required('At least one type is required'),
})
export default function UserDetails({ user, fetchUser }) {
  const [editing, setEditing] = React.useState(false)

  const { updateUser } = useUpdateUser()

  const { user: viewer } = useFetchUser()

  const { t } = useTranslation()

  if (!viewer) return null

  return (
    <Formik
      initialValues={{
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone_number: user.phone_number,
        groups: user.groups,
        user_is_internal_coach: user.user_is_internal_coach,
        username: user.username,
        is_approved: user.is_approved,
      }}
      onSubmit={values => {
        console.log('VALUES', values)
        updateUser({ userId: user.id, data: values })
          .then(() => {
            fetchUser().then(() => {
              setEditing(false)
            })
          })
          .catch(err => {
            console.log('ERR', err)
          })
      }}
      validationSchema={profileSchema}
    >
      {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
        <Fragment>
          <div className="profile-details-section__header">
            <p className="profile-details-section__header__title">
              {t('client_profile.personal_information')}
            </p>
            <p
              className="profile-details-section__header__edit"
              onClick={() => (editing ? handleSubmit() : setEditing(true))}
            >
              {editing ? t('client_profile.save') : t('client_profile.edit')}
            </p>
          </div>

          <div className="profile-details-section__body">
            <div className="profile-details-section__body__row">
              <div className="profile-details-section__body__row__label">
                <div className="profile-details-section__body__row__label__icon">
                  <Icon name="user" size={16} />
                </div>
                <p className="profile-details-section__body__row__label__title">
                  {t('client_profile.account_type')}
                </p>
              </div>
              {false ? (
                <div className="profile-details-section__body__row__checkboxes">
                  {Object.values(errors).length > 0 && (
                    <div className="profile-details-section-validation-alert">
                      {/** show one error at a time here*/}
                      <div className="alert-icon-container">
                        <div
                          className="alert-icon-square"
                          style={{
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        <p
                          className="alert-icon"
                          style={{
                            color: '#e8744f',
                          }}
                        >
                          !
                        </p>
                      </div>
                      <p className="profile-details-section-validation-alert__message">
                        {Object.values(errors)[0]}
                      </p>
                    </div>
                  )}
                  <button
                    className="profile-details-section__body__row__checkboxes__checkbox"
                    onClick={() => {
                      values.groups.includes('Admin')
                        ? setFieldValue(
                            'groups',
                            values.groups.filter(group => group !== 'Admin')
                          )
                        : setFieldValue('groups', [...values.groups, 'Admin'])
                    }}
                  >
                    <p
                      className={`profile-details-section__body__row__checkboxes__checkbox__label ${values.groups.includes(
                        'Admin'
                      ) && 'active'}`}
                    >
                      {t('client_profile.admin')}
                    </p>
                    <Icon
                      name="checkbox"
                      size={14}
                      active={values.groups.includes('Admin')}
                      color="#9DA16B"
                    />
                  </button>
                  <button
                    className="profile-details-section__body__row__checkboxes__checkbox"
                    onClick={() => {
                      // A provider is the one whose group contains 'Coach' and has user_is_internal_coach set to true
                      if (values.groups.includes('Coach')) {
                        setFieldValue(
                          'groups',
                          values.groups.filter(group => group !== 'Coach')
                        )
                      } else {
                        setFieldValue('groups', [...values.groups, 'Coach'])
                      }

                      if (values.user_is_internal_coach) {
                        setFieldValue('user_is_internal_coach', false)
                      } else {
                        setFieldValue('user_is_internal_coach', true)
                      }
                    }}
                  >
                    <p
                      className={`profile-details-section__body__row__checkboxes__checkbox__label ${values.groups.includes(
                        'Coach'
                      ) &&
                        values.user_is_internal_coach &&
                        'active'}`}
                    >
                      Provider
                    </p>
                    <Icon
                      name="checkbox"
                      size={14}
                      active={
                        values.groups.includes('Coach') &&
                        values.user_is_internal_coach
                      }
                      color="#9DA16B"
                    />
                  </button>
                  <button
                    className="profile-details-section__body__row__checkboxes__checkbox"
                    onClick={() => {
                      values.groups.includes('User')
                        ? setFieldValue(
                            'groups',
                            values.groups.filter(group => group !== 'User')
                          )
                        : setFieldValue('groups', [...values.groups, 'User'])
                    }}
                  >
                    <p
                      className={`profile-details-section__body__row__checkboxes__checkbox__label ${values.groups.includes(
                        'User'
                      ) && 'active'}`}
                    >
                      User
                    </p>
                    <Icon
                      name="checkbox"
                      size={14}
                      active={values.groups.includes('User')}
                      color="#9DA16B"
                    />
                  </button>
                </div>
              ) : (
                <p className="profile-details-section__body__row__value">
                  {user.groups.map(group => group).join(', ')}
                </p>
              )}
            </div>
            {(viewer.groups.includes('Admin') || viewer.id === user.id) && (
              <div className="profile-details-section__body__row">
                <div className="profile-details-section__body__row__label">
                  <div className="profile-details-section__body__row__label__icon">
                    <Icon name="email" size={14} />
                  </div>
                  <p className="profile-details-section__body__row__label__title">
                    {t('client_profile.email_address')}
                  </p>
                </div>
                {editing ? (
                  <input
                    className="profile-details-section__body__row__input"
                    value={values.email}
                    onChange={handleChange}
                    name="email"
                  />
                ) : (
                  <p className="profile-details-section__body__row__value">
                    {user.email}
                  </p>
                )}
              </div>
            )}
            {(viewer.groups.includes('Admin') || viewer.id === user.id) && (
              <div className="profile-details-section__body__row">
                <div className="profile-details-section__body__row__label">
                  <div className="profile-details-section__body__row__label__icon">
                    <Icon name="phone" size={14} />
                  </div>
                  <p className="profile-details-section__body__row__label__title">
                    {t('client_profile.phone')}
                  </p>
                </div>
                {editing ? (
                  <input
                    className="profile-details-section__body__row__input"
                    value={values.phone_number}
                    onChange={e => {
                      if (e.target.value.match(/^[0-9+]*$/)) {
                        handleChange(e)
                      }
                    }}
                    name="phone_number"
                  />
                ) : (
                  <p className="profile-details-section__body__row__value">
                    {user.phone_number}
                  </p>
                )}
              </div>
            )}
            <div className="profile-details-section__body__row">
              <div className="profile-details-section__body__row__label">
                <div className="profile-details-section__body__row__label__icon">
                  <Icon name="case" size={18} />
                </div>
                <p className="profile-details-section__body__row__label__title">
                  {t('client_profile.company')}
                </p>
              </div>
              <p className="profile-details-section__body__row__value">
                {user.company_name}
              </p>
            </div>
            <div className="profile-details-section__body__row">
              <div className="profile-details-section__body__row__label">
                <div className="profile-details-section__body__row__label__icon">
                  <Icon name="modern-calendar" size={18} />
                </div>
                <p className="profile-details-section__body__row__label__title">
                  {t('client_profile.date_joined')}
                </p>
              </div>
              <p className="profile-details-section__body__row__value">
                {dayjs(user.date_joined).format('MMM D, YYYY')}
              </p>
            </div>

            {viewer.groups.includes('Admin') && (
              <div className="profile-details-section__body__row">
                <div className="profile-details-section__body__row__label">
                  <div className="profile-details-section__body__row__label__icon">
                    <Icon name="user" size={14} />
                  </div>
                  <p className="profile-details-section__body__row__label__title">
                    {t('client_profile.approved')}
                  </p>
                </div>
                {editing ? (
                  <Switch
                    onChange={() => {
                      setFieldValue('is_approved', !values.is_approved)
                    }}
                    checked={values.is_approved}
                    onColor="#343434"
                  />
                ) : (
                  <p className="profile-details-section__body__row__value">
                    {user.is_approved ? 'Yes' : 'No'}
                  </p>
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </Formik>
  )
}
