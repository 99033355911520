import React, { useEffect, useRef } from 'react'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function Filters({ params, setParams }) {
    const [open, setOpen] = React.useState(false)

    const ref = useRef(null)
    useOutsideAlerter(ref, () => setOpen(false))
    const { t, defaultLanguage } = useTranslation()

    const initialState = {
        eligibility: {
            title: t('provider_dashboard.eligibility'),
            open: false,
            options: [
                {
                    name: t('provider_dashboard.eligible'),
                    value: 'Eligible',
                },
                {
                    name: t('provider_dashboard.ineligible'),
                    value: 'Ineligible',
                },
            ],
            selected: new Set(),
            type: 'exclusive',
        },
        protocol: {
            title: t('provider_dashboard.alert_type'),
            open: false,
            options: [
                {
                    name: t('provider_dashboard.mood'),
                    value: 'mood',
                },
                {
                    name: t('provider_dashboard.assessment'),
                    value: 'assessment',
                },
            ],
            selected: new Set(),
            type: 'exclusive',
        },
        calls: {
            title: t('provider_dashboard.calls'),
            open: false,
            options: [
                {
                    name: t('provider_dashboard.has_call_scheduled'),
                    value: 'has_call_scheduled',
                },
            ],
            selected: new Set(),
            type: 'exclusive',
        },
    }

    const [options, setOptions] = React.useState(initialState)

    useEffect(() => {
        setOptions(initialState)
    }, [defaultLanguage])

    const toggle = name => {
        setOptions({
            ...options,
            [name]: {
                ...options[name],
                open: !options[name].open,
            },
        })
    }

    const toggleOption = (name, value) => {
        if (options[name].type === 'exclusive') {
            if (options[name].selected.has(value)) {
                // remove the option from the selected set
                setOptions({
                    ...options,
                    [name]: {
                        ...options[name],
                        selected: new Set(),
                    },
                })
                return
            }

            setOptions({
                ...options,
                [name]: {
                    ...options[name],
                    selected: new Set([value]),
                },
            })
            return
        }

        const newSelected = new Set(options[name].selected)
        if (newSelected.has(value)) {
            newSelected.delete(value)
        } else {
            newSelected.add(value)
        }
        setOptions({
            ...options,
            [name]: {
                ...options[name],
                selected: newSelected,
            },
        })
    }

    const applyFilters = () => {
        const eligibility = Array.from(options.eligibility.selected).join(',')
        const alert_types = Array.from(options.protocol.selected).join(',')

        const has_call_scheduled = Array.from(options.calls.selected).includes(
            'has_call_scheduled'
        )

        // if there was a change in the filters, reset: true

        if (
            eligibility === params.eligibility_status &&
            alert_types === params.alert_type &&
            has_call_scheduled === params.has_call_scheduled
        ) {
            setOpen(false)
            return
        }

        setParams({
            ...params,
            eligibility_status: eligibility,
            alert_type: alert_types,
            page: 1,
            reset: true,
            has_call_scheduled,
        })
        setOpen(false)
    }

    return (
        <div className="search-container-wrapper">
            <div
                className="search-container-filter"
                onClick={() => setOpen(!open)}
                style={{ backgroundColor: open ? '#D3D0CC' : '#FFFFFF' }}
            >
                <Icon name={'filter'} color={open ? '#908E87' : '#343434'} />

                <p
                    className="search-container-filter-text"
                    style={{ color: open ? '#908E87' : '#343434' }}
                >
                    {t('provider_dashboard.refine')}
                </p>
            </div>
            {open && (
                <div className="search-container-filter-container" ref={ref}>
                    <div className="search-container-filter-container-header">
                        <p>{t('provider_dashboard.refine')} </p>
                    </div>

                    {/*  <div className="search-container-filter-container-body">
                        <button
                            className="search-container-filter-container-body-item"
                            type="button"
                            onClick={() => toggle('eligibility')}
                        >
                            <p>
                                Eligibility{' '}
                                <strong>
                                    ({options.eligibility.selected.size})
                                </strong>
                            </p>

                            <Icon
                                name="chevronRight"
                                size={6}
                                color="#555555"
                                style={{
                                    transform:
                                        options.eligibility.open &&
                                        'rotate(90deg)',
                                    transition: 'all 0.3s ease',
                                }}
                            />
                        </button>

                        <div className="search-container-filter-container-body-item-options">
                            {options.eligibility.open &&
                                options.eligibility.options.map((option) => (
                                    <div
                                        className="search-container-filter-container-body-item-options-item"
                                        key={option.value}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={options.eligibility.selected.has(
                                                option.value
                                            )}
                                            onChange={() =>
                                                toggleOption(
                                                    'eligibility',
                                                    option.value
                                                )
                                            }
                                        />
                                        <p>{option.name}</p>
                                    </div>
                                ))}
                        </div>
                    </div> */}
                    <div className="search-container-filter-container-body">
                        {Object.keys(options).map(key => (
                            <div key={key}>
                                <button
                                    className="search-container-filter-container-body-item"
                                    type="button"
                                    onClick={() => toggle(key)}
                                >
                                    <p>
                                        {options[key].title}{' '}
                                        <strong>
                                            ({options[key].selected.size})
                                        </strong>
                                    </p>

                                    <Icon
                                        name="chevronRight"
                                        size={6}
                                        color="#555555"
                                        style={{
                                            transform:
                                                options[key].open &&
                                                'rotate(90deg)',
                                            transition: 'all 0.3s ease',
                                        }}
                                    />
                                </button>

                                <div className="search-container-filter-container-body-item-options">
                                    {options[key].open &&
                                        options[key].options.map(option => (
                                            <div
                                                className="search-container-filter-container-body-item-options-item"
                                                key={option.value}
                                                role="button"
                                                onClick={() =>
                                                    toggleOption(
                                                        key,
                                                        option.value
                                                    )
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        toggleOption(
                                                            key,
                                                            option.value
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        name="checkbox"
                                                        active={options[
                                                            key
                                                        ].selected.has(
                                                            option.value
                                                        )}
                                                    />
                                                </button>
                                                <p>{option.name}</p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="search-container-filter-container-footer">
                        <button
                            className="search-container-filter-container-footer-button apply"
                            type="button"
                            onClick={applyFilters}
                        >
                            {t('provider_dashboard.apply_filters')}
                        </button>
                        <button
                            className="search-container-filter-container-footer-button reset"
                            type="button"
                            onClick={() => {
                                setOptions(initialState)
                                setParams({
                                    ...params,
                                    eligibility_status: '',
                                    alert_type: '',
                                    page: 1,
                                    reset: true,
                                    has_call_scheduled: false,
                                })

                                setOpen(false)
                            }}
                        >
                            {t('provider_dashboard.reset_filters')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
