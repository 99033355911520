import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  RPM_FETCH_COMPANY_LINKS_BEGIN,
  RPM_FETCH_COMPANY_LINKS_SUCCESS,
  RPM_FETCH_COMPANY_LINKS_FAILURE,
  RPM_FETCH_COMPANY_LINKS_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function fetchCompanyLinks(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: RPM_FETCH_COMPANY_LINKS_BEGIN,
    })

    const promise = new Promise((resolve, reject) => {
      let url = `${config.apiRootUrl}/company/links/`

      if (args.user_id) {
        url = `${config.apiRootUrl}/profile/links`
      }
      const doRequest = axios.get(url, {
        params: args,
        ...createAxiosConfigWithAuth(getState()),
      })
      doRequest.then(
        res => {
          dispatch({
            type: RPM_FETCH_COMPANY_LINKS_SUCCESS,
            data: res.data,
          })
          resolve(res.data)
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: RPM_FETCH_COMPANY_LINKS_FAILURE,
            data: { error: err },
          })
          reject(err)
        }
      )
    })

    return promise
  }
}

export function dismissFetchCompanyLinksError() {
  return {
    type: RPM_FETCH_COMPANY_LINKS_DISMISS_ERROR,
  }
}

export function useFetchCompanyLinks() {
  const dispatch = useDispatch()

  const {
    fetchCompanyLinksPending,
    fetchCompanyLinksError,
    companyLinks,
  } = useSelector(
    state => ({
      companyLinks: state.rpm.companyLinks,
      fetchCompanyLinksPending: state.rpm.fetchCompanyLinksPending,
      fetchCompanyLinksError: state.rpm.fetchCompanyLinksError,
    }),
    shallowEqual
  )

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchCompanyLinks(...args))
    },
    [dispatch]
  )

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchCompanyLinksError())
  }, [dispatch])

  return {
    companyLinks,
    fetchCompanyLinks: boundAction,
    fetchCompanyLinksPending,
    fetchCompanyLinksError,
    dismissFetchCompanyLinksError: boundDismissError,
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_FETCH_COMPANY_LINKS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        companyLinksPending: true,
        companyLinksError: null,
      }

    case RPM_FETCH_COMPANY_LINKS_SUCCESS:
      // The request is success
      return {
        ...state,
        companyLinks: action.data,
        companyLinksPending: false,
        companyLinksError: null,
      }

    case RPM_FETCH_COMPANY_LINKS_FAILURE:
      // The request is failed
      return {
        ...state,
        companyLinksPending: false,
        companyLinksError: action.data.error,
      }

    case RPM_FETCH_COMPANY_LINKS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        companyLinksError: null,
      }

    default:
      return state
  }
}
