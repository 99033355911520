import React from 'react';

export default function RadioInputIcon({
  size = 18,
  color = '#343434',
  style = {},
  active = false,
}) {
  if (active) {
    return (
      <svg
        style={style}
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="8.5" stroke="#E5E5E5" />
        <circle cx="9" cy="9" r="5" fill={color} />
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="8.5" stroke="#E5E5E5" />
    </svg>
  );
}
