import React from 'react';

export default function ChevronRight({
  size = 9,
  color = '#E1DDDA',
  style = {},
}) {
  return (
    <svg
      width={size}
      height={size * 1.88}
      style={style}
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.775641 17C0.572325 16.9965 0.377531 16.9149 0.229086 16.7711C0.085197 16.6178 0.00470974 16.4126 0.00470974 16.1989C0.00470974 15.9852 0.085197 15.7799 0.229086 15.6266L7.10007 8.50407L0.229086 1.38149C0.0824045 1.22972 -1.54554e-09 1.02388 0 0.809255C1.54554e-09 0.594627 0.0824045 0.38879 0.229086 0.237025C0.375767 0.0852605 0.574709 1.5991e-09 0.782148 0C0.989587 -1.5991e-09 1.18853 0.0852605 1.33521 0.237025L8.76576 7.93857C8.83978 8.01151 8.89871 8.09924 8.93897 8.19645C8.97924 8.29365 9 8.39831 9 8.50407C9 8.60983 8.97924 8.71448 8.93897 8.81169C8.89871 8.9089 8.83978 8.99663 8.76576 9.06957L1.33521 16.7711C1.18347 16.918 0.983462 16.9998 0.775641 17Z"
        fill={color}
      />
    </svg>
  );
}
