import React, { useContext } from 'react';
import { useWindowSize } from '../../../common/windowsSize';
import { InvitationContext } from './InvitationContext';

export default function ModalBodyContainer({ children }) {
  const { step } = useContext(InvitationContext);
  const size = useWindowSize();
  return (
    <div
      className={`${
        step === 5 && size.width < 768 && 'd-none'
      } modal-body-container`}
      style={{
        marginBottom:
          step === 1 && size.width < 768
            ? '-40%'
            : step === 2 && size.width < 768
            ? '-25%'
            : step === 3 && size.width < 768
            ? '-30%'
            : step === 4 && size.width < 768
            ? '-35%'
            : '',
      }}
    >
      {children}
    </div>
  );
}
