export const client_profile = {
  eligible: 'Elegible',
  ineligible: 'Inelegible',
  pending: 'Pendiente',
  days_tracked: 'días rastreados',
  days_left: 'días restantes',
  in_eligibility_period: 'en el período de elegibilidad',
  billing_cycle: 'Ciclo de facturación',
  not_available: 'No disponible',
  no_phone_number: 'No hay número de teléfono',
  message: 'Mensaje',
  care_team: 'Equipo de atención',
  you: 'Tú',
  current_roadmap: 'Roadmap actual',
  add_assessment: 'Agregar evaluación',
  add_cpt_code: 'Agregar código CPT',
  add_event: 'Agregar evento',
  add_call: 'Agregar nueva llamada',
  send_clinical_record: 'Enviar registro clínico',
  submit_medical_record: 'Enviar registro médico',
  confirm_submission: 'Confirmar envío',
  success: '¡Éxito!',
  submission_was_sent_successfully: 'El envío se realizó con éxito',
  process_failed: 'El proceso falló.',
  error_occurred:
    'Se produjo un error desconocido. Vuelva al formulario y envíelo nuevamente.',
  activity: 'Actividad',
  billing: 'Facturación',
  profile_details: 'Detalles del perfil',
  policy_holder_dob: 'Fecha de nacimiento del titular del seguro',
  edit: 'Editar',
  add: 'Agregar',
  save: 'Guardar',
  update: 'Actualizar',
  remove: 'Eliminar',
  assessment: 'Evaluación',
  select_an_assessment: 'Selecciona una evaluación',
  date_completed: 'Fecha de finalización',
  yyyy: 'AAAA',
  date_cannot_be_future: 'La fecha no puede ser futura',
  score: 'Puntuación',
  link_to_assessment_results: 'Enlace a los resultados de la evaluación',
  optional: 'Opcional',
  delete: 'Eliminar',
  cancel: 'Cancelar',
  please_select_an_assessment: 'Por favor seleccione una evaluación',
  please_enter_a_score: 'Por favor ingrese una puntuación',
  please_enter_a_month: 'Por favor ingrese un mes',
  please_enter_a_day: 'Por favor ingrese un día',
  please_enter_a_year: 'Por favor ingrese un año',
  invalid_date: 'Fecha inválida',
  cpt_code: 'Código CPT',
  call_length_in_minutes: 'Duración de la llamada en minutos',
  if_applicable: 'Si aplica',
  call_time: 'Hora de la llamada',
  notes: 'Notas',
  select_cpt_code: 'Seleccionar código CPT',
  please_select_a_cpt_code: 'Por favor seleccione un código CPT',
  please_enter_a_positive_number: 'Por favor ingrese un número positivo',
  no_activity_has_been_logged: 'No se ha registrado actividad',
  daily_steps: 'Pasos diarios',
  engagement: 'Compromiso',
  sleep: 'Sueño',
  emotional_check_in: 'Chequeo emocional',
  valid_until: 'Válido hasta',
  you_have_reached_the_end_of_this_health_history:
    'Has llegado al final de este historial de salud',
  delete_assessment_question: '¿Eliminar evaluación?',
  are_you_sure_you_want_to_delete_this_assessment_question:
    '¿Estás seguro de que quieres eliminar esta evaluación?',

  deleting_this_assessment:
    'Eliminar esta evaluación eliminará los resultados de la pestaña de datos de tu cliente. Y puede reducir la puntuación de cumplimiento.',
  personal_information: 'Información personal',
  account_type: 'Tipo de cuenta',
  admin: 'Administrador',
  email_address: 'Dirección de correo electrónico',
  phone: 'Teléfono',
  company: 'Empresa',
  date_joined: 'Fecha de ingreso',
  insurance_provider: 'Proveedor de seguros',
  member_id: 'ID de miembro',
  policy_holder: 'Titular de la póliza',
  group_number: 'Número de grupo',
  contract: 'Contrato',
  contract_type: 'Tipo de contrato',
  expires: 'Expira',
  created_by: 'Creado por',
  length_of_call: 'Duración de la llamada',
  delete_cpt_record_question: '¿Eliminar registro CPT?',
  are_you_sure_you_want_to_delete_this_record:
    '¿Estás seguro de que quieres eliminar este registro?',
  next_calls: 'Próximas Llamadas',
  schedule: 'Horario',
  events: 'Eventos',
  upheal: {
    title: 'Información de Upheal',
    upheal_user_id: 'ID de usuario de Upheal',
    coach_meeting_room: 'Sala de reuniones del Coach',
    client_session_url: 'URL de sesión del cliente',
  },
  employer_coverage: {
    title: 'Cobertura del empleador',
    employer_name: 'Nombre del empleador',
    subscriber_id_last_digits: 'Últimos dígitos del ID del suscriptor',
    is_covered: 'Está cubierto',
    yes: 'Sí',
  },
  assign_a_coach_to_your_client: 'Asigna un coach a tu cliente',
  edit_care_team: 'Editar equipo de atención',
  save: 'Guardar',
  approved: 'Aprobado',
}
