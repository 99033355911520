import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  HOME_GET_INVOICES_STRIPE_BEGIN,
  HOME_GET_INVOICES_STRIPE_SUCCESS,
  HOME_GET_INVOICES_STRIPE_FAILURE,
  HOME_GET_INVOICES_STRIPE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function getInvoicesStripe(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: HOME_GET_INVOICES_STRIPE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${config.apiRootUrl}/subscriptions/get-invoices-stripe`,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_GET_INVOICES_STRIPE_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_GET_INVOICES_STRIPE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetInvoicesStripeError() {
  return {
    type: HOME_GET_INVOICES_STRIPE_DISMISS_ERROR,
  };
}

export function useGetInvoicesStripe() {
  const dispatch = useDispatch();

  const { invoices, getInvoicesStripePending, getInvoicesStripeError } =
    useSelector(
      (state) => ({
        invoices: state.home.invoices,
        getInvoicesStripePending: state.home.getInvoicesStripePending,
        getInvoicesStripeError: state.home.getInvoicesStripeError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getInvoicesStripe(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetInvoicesStripeError());
  }, [dispatch]);

  return {
    invoices,
    getInvoicesStripe: boundAction,
    getInvoicesStripePending,
    getInvoicesStripeError,
    dismissGetInvoicesStripeError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_INVOICES_STRIPE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getInvoicesStripePending: true,
        getInvoicesStripeError: null,
      };

    case HOME_GET_INVOICES_STRIPE_SUCCESS:
      // The request is success
      return {
        ...state,
        getInvoicesStripePending: false,
        getInvoicesStripeError: null,
        invoices: action.data,
      };

    case HOME_GET_INVOICES_STRIPE_FAILURE:
      // The request is failed
      return {
        ...state,
        getInvoicesStripePending: false,
        getInvoicesStripeError: action.data.error,
      };

    case HOME_GET_INVOICES_STRIPE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getInvoicesStripeError: null,
      };

    default:
      return state;
  }
}
