import React, { Fragment } from 'react'
import { useFormikContext, Field } from 'formik'
import ReactPhoneInput from 'react-phone-input-2'
import { useFetchUser } from '../../redux/fetchUser'

export default function BasicInfoForm() {
  const { values, errors, touched, setFieldValue } = useFormikContext()

  const { user: viewer } = useFetchUser()

  return (
    <Fragment>
      <div className="flex w-full gap-1">
        <div className="form-group">
          <Field
            type="text"
            name="first_name"
            id="first_name"
            placeholder="First Name *"
          />
          {errors.first_name && touched.first_name && (
            <p className="error-message">First Name is required</p>
          )}
        </div>
        <div className="form-group">
          <Field
            type="text"
            name="last_name"
            id="last_name"
            placeholder="Last Name *"
          />
          {errors.last_name && touched.last_name && (
            <p className="error-message">Last Name is required</p>
          )}
        </div>
      </div>

      <div className="form-group">
        <Field type="email" name="email" id="email" placeholder="Email *" />
        {errors.email && touched.email && (
          <p className="error-message">
            Email is required and must be a valid email
          </p>
        )}
      </div>

      {(viewer.email === values.email || viewer.groups.includes('Admin')) && (
        <div className="form-group">
          <ReactPhoneInput
            defaultCountry="us"
            value={values.phone_number}
            name="phone_number"
            placeholder="Phone Number *"
            inputExtraProps={{
              name: 'phone_number',
              id: 'phone_number',
            }}
            onChange={phoneNumber => {
              let phoneNumberString = phoneNumber.replace(/[() -]/g, '')
              setFieldValue('phone_number', phoneNumberString)
              console.log('phoneNumber', values.phone_number)
            }}
          />
          {errors.phone_number && touched.phone_number && (
            <p className="error-message">
              Phone Number is required and must be a valid phone number
            </p>
          )}
        </div>
      )}
    </Fragment>
  )
}
