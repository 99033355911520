import React from 'react';

export default function LargeLineIcon({ size, color = '#343434' }) {
  return (
    <svg
      width={size / 7.8}
      height={size}
      viewBox="0 0 5 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2.90771"
        y1="2"
        x2="2.90772"
        y2="37"
        stroke={color}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}
