import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  USER_ASSIGN_COACH_TO_STUDENT_BEGIN,
  USER_ASSIGN_COACH_TO_STUDENT_SUCCESS,
  USER_ASSIGN_COACH_TO_STUDENT_FAILURE,
  USER_ASSIGN_COACH_TO_STUDENT_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
export function assignCoachToStudent(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: USER_ASSIGN_COACH_TO_STUDENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.apiRootUrl}/subscriptions/assign-coach-to-student`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: USER_ASSIGN_COACH_TO_STUDENT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: USER_ASSIGN_COACH_TO_STUDENT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissAssignCoachToStudentError() {
  return {
    type: USER_ASSIGN_COACH_TO_STUDENT_DISMISS_ERROR,
  };
}

export function useAssignCoachToStudent() {
  const dispatch = useDispatch();

  const { assignCoachToStudentPending, assignCoachToStudentError } =
    useSelector(
      (state) => ({
        assignCoachToStudentPending: state.user.assignCoachToStudentPending,
        assignCoachToStudentError: state.user.assignCoachToStudentError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(assignCoachToStudent(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissAssignCoachToStudentError());
  }, [dispatch]);

  return {
    assignCoachToStudent: boundAction,
    assignCoachToStudentPending,
    assignCoachToStudentError,
    dismissAssignCoachToStudentError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_ASSIGN_COACH_TO_STUDENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        assignCoachToStudentPending: true,
        assignCoachToStudentError: null,
      };

    case USER_ASSIGN_COACH_TO_STUDENT_SUCCESS:
      // The request is success
      return {
        ...state,
        assignCoachToStudentPending: false,
        assignCoachToStudentError: null,
      };

    case USER_ASSIGN_COACH_TO_STUDENT_FAILURE:
      // The request is failed
      return {
        ...state,
        assignCoachToStudentPending: false,
        assignCoachToStudentError: action.data.error,
      };

    case USER_ASSIGN_COACH_TO_STUDENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        assignCoachToStudentError: null,
      };

    default:
      return state;
  }
}
