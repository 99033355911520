import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '../../../common/components/Icon'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import { useDeleteUserRoadmap } from '../../../manage/redux/deleteUserRoadmap'
import { Modal } from 'react-bootstrap'

export default function SidebarRoadmaps({ user, fetchUser }) {
    const history = useHistory()

    const ref = useRef(null)
    const [showThreeDotsDropdown, setShowThreeDotsDropdown] = useState(-1)
    useOutsideAlerter(ref, () => {
        setShowThreeDotsDropdown(-1)
    })

    const [showModal, setShowModal] = useState(false) // false or { roadmapId: 1, userId: 'name'}

    const { deleteUserRoadmap } = useDeleteUserRoadmap()

    return (
        <div className="rpm-profile-info-container__info__roadmaps">
            {user.roadmaps_info &&
                user.roadmaps_info.length > 0 &&
                user.roadmaps_info.map((roadmap, index) => (
                    <div
                        className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper"
                        key={index}
                    >
                        {showThreeDotsDropdown === index && (
                            <div
                                className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__three-dots-dropdown"
                                ref={ref}
                            >
                                <button
                                    className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__three-dots-dropdown__button"
                                    onClick={() => {
                                        setShowModal({
                                            roadmapId: roadmap.id,
                                            userId: user.id,
                                        })
                                    }}
                                    type="button"
                                >
                                    Delete Roadmap{' '}
                                </button>
                            </div>
                        )}
                        {showThreeDotsDropdown !== index && (
                            <button
                                className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__three-dots"
                                onClick={() => setShowThreeDotsDropdown(index)}
                                type="button"
                            >
                                <Icon
                                    name={'threedots'}
                                    size={4}
                                    color={'#C4C4C4'}
                                />
                            </button>
                        )}

                        <div
                            className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap"
                            onClick={() => {
                                history.push(
                                    `/roadmap/${roadmap.id}/?user=${user.id}`
                                )
                            }}
                            role="button"
                        >
                            <div className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__header">
                                <img
                                    className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__header__image"
                                    src={roadmap.icon}
                                />
                                <p className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__header__title">
                                    {roadmap.title}
                                </p>
                            </div>
                            <div className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress">
                                <div className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress__bar">
                                    <div
                                        className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress__bar__fill"
                                        style={{
                                            width: `${roadmap.progress}%`,
                                        }}
                                    />
                                </div>
                                <p className="rpm-profile-info-container__info__roadmaps__roadmap-wrapper__roadmap__progress__text">
                                    {Math.floor(roadmap.progress)}%
                                </p>
                            </div>
                        </div>
                    </div>
                ))}

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                className="rpm-profile-info-container__info__roadmaps__modal"
            >
                {/* deleteUserRoadmap({ roadmapId: id, userId: client }); */}
                <Modal.Header>
                    <Modal.Title>
                        Are you sure you want to delete this roadmap?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Your client will no longer be able to see this roadmap.
                    </p>
                    <button
                        className="remove"
                        onClick={() => {
                            deleteUserRoadmap({
                                roadmapId: showModal.roadmapId,
                                userId: showModal.userId,
                            }).then(() => {
                                fetchUser().then(() => {
                                    setShowModal(false)
                                })
                            })
                        }}
                        type="button"
                    >
                        Remove Roadmap
                    </button>
                    <button
                        className="go-back"
                        onClick={() => {
                            setShowModal(false)
                        }}
                        type="button"
                    >
                        Go back
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    )
}
