import React, { Fragment } from 'react';
import Icon from '../../../common/components/Icon';
import { CurrentUserIsCoachOrAdmin } from '../../../../common/roles';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

export default function Header({
  setSelectedConversation,
  setCreateNewConversation,
  selectedTab,
}) {
  const { t } = useTranslation();
  let isCoachOrAdmin = CurrentUserIsCoachOrAdmin();

  return (
    <div className="content-header">
      <h1>{t('messages.messages')}</h1>
      <button
        onClick={() => {
          setCreateNewConversation(true);
          setSelectedConversation({
            id: 'creating',
            timestamp: Date.now(),
            user: {
              first_name: '',
              last_name: '',
              photo: '',
              username: '',
            },
            type: selectedTab === 'group' ? 'group' : 'p2p',
          });
        }}
      >
        {selectedTab === 'group' ? (
          <Fragment>
            {isCoachOrAdmin && (
              <Icon name="creategroup" size={22} color="var(--primary-dark)" />
            )}
          </Fragment>
        ) : (
          <Icon name="write" size={25} color="var(--primary-dark)" />
        )}
      </button>
    </div>
  );
}
