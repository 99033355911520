import React, { useContext } from 'react';
import { CompetencyContext } from '../../CompetencyPage';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';

export default function CompetencyPageFooter() {
  const {
    selectedCompetency,
    prevCompetency,
    roadmapId,
    stageId,
    studentId,
    defaultBackLink,
    defaultBackLinkParams,
    nextCompetency,
    showActionItemContainer,
  } = useContext(CompetencyContext);
  return (
    <React.Fragment>
      <div
        className="mrm-mb-6 justify-content-between prev-next-btn-container normal-width-container"
        style={{
          width: showActionItemContainer ? '100%' : '70%',
        }}
      >
        {(selectedCompetency.prev || prevCompetency) && (
          <Link
            to={{
              pathname: `/roadmap/${roadmapId}/stage/${stageId}/competency/${
                selectedCompetency.prev
                  ? selectedCompetency.prev
                  : prevCompetency.id
              }`,
              search: studentId ? `user=${studentId}` : null,
              state: {
                backLink: `${defaultBackLink}${defaultBackLinkParams}`,
              },
            }}
          >
            <Button variant="white" className="w-100">
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="sm"
                title="Detail"
                className="mrm-mr-0_5"
              />{' '}
              {selectedCompetency.prev
                ? 'Previous Competency'
                : 'Previous Stage'}
            </Button>
          </Link>
        )}{' '}
        {/*
         * Dummy button:
         * When there is no next or prev competency, we need to show a dummy button
         */}
        {!selectedCompetency.prev && !prevCompetency && (
          <Button variant="white" className="w-100">
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="sm"
              title="Detail"
              className="mrm-mr-0_5"
            />{' '}
            {selectedCompetency.prev
              ? 'Previous Competency'
              : 'Previous Competency'}
          </Button>
        )}
        {(selectedCompetency.next || nextCompetency) && (
          <Link
            to={{
              pathname: `/roadmap/${roadmapId}/stage/${stageId}/competency/${
                selectedCompetency.next
                  ? selectedCompetency.next
                  : nextCompetency.id
              }`,
              search: studentId ? `user=${studentId}` : null,
              state: {
                backLink: `${defaultBackLink}${defaultBackLinkParams}`,
              },
            }}
          >
            <Button variant="white" className="w-100">
              {selectedCompetency.next ? 'Next Competency' : 'Next Stage'}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                title="Detail"
                className="mrm-ml-0_5"
              />
            </Button>
          </Link>
        )}
        {/** Dummy button */}
        {!selectedCompetency.next && !nextCompetency && (
          <Button variant="white" className="w-100">
            {selectedCompetency.next ? 'Next Competency' : 'Next Competency'}
            <FontAwesomeIcon
              icon={faChevronRight}
              size="sm"
              title="Detail"
              className="mrm-ml-0_5"
            />
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}
