import React, { Fragment, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Icon from '../../../common/components/Icon'
import useWindowSize from '../../../common/useWindowResize'
import { useSendWelcomeEmail } from '../../../manage/redux/sendWelcomeEmail'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import AvatarBackground from '../../assets/provider-dashboard/avatar-background.png'
import { getEligibilityColor } from '../../utils/getEligibilityColor'
import Alerts from '../coach-dashboard/Alerts'
import ResendInvitationConfirmation from '../coach-dashboard/ResendInvitationConfirmation'
import RecordColumn from './RecordColumn'
export default function DesktopRecords({
    clients,
    clientsRaw,
    fetchMoreUsers,
    params,
    refetchClients,
}) {
    const { sendWelcomeEmail } = useSendWelcomeEmail()
    const { isDesktop } = useWindowSize()
    const { t } = useTranslation()

    const [
        showInvitationConfirmation,
        setShowInvitationConfirmation,
    ] = useState(false)

    if (!isDesktop) return null

    return (
        <Fragment>
            <ResendInvitationConfirmation
                show={showInvitationConfirmation}
                onHide={() => setShowInvitationConfirmation(false)}
            />
            <div className="rpm-provider-columns">
                <p className="rpm-provider-columns-name">
                    {t('provider_dashboard.columns.name')}
                </p>
                <div className="rpm-provider-columns-statuses">
                    <p>{t('provider_dashboard.columns.status')}</p>
                    <p>{t('provider_dashboard.columns.diagnosis')}</p>
                </div>
                <p className="rpm-provider-columns-alerts">
                    {t('provider_dashboard.columns.alerts')}
                </p>
            </div>
            {clients && clients.length > 0 && (
                <div className="rpm-provider-records">
                    <InfiniteScroll
                        className="w-full"
                        style={{ width: '100%', paddingBottom: '10rem' }}
                        pageStart={0}
                        loadMore={fetchMoreUsers}
                        initialLoad={false}
                        hasMore={
                            (clientsRaw?.next ? true : false) && !params.search
                        }
                        loader={
                            <div className="user-card-skeleton skeleton w-full" />
                        }
                        useWindow={false}
                    >
                        {clients.map(
                            (
                                {
                                    id,
                                    first_name,
                                    last_name,
                                    mood,
                                    compliance_score,
                                    assessments,
                                    eligibility,
                                    alerts,
                                },
                                idx
                            ) => {
                                let numberOfColumns = assessments.length + 2

                                numberOfColumns =
                                    numberOfColumns < 4 ? 4 : numberOfColumns

                                const icons = {
                                    'GAD-7': (
                                        <Icon
                                            name="notes"
                                            size={13}
                                            color="#595959"
                                        />
                                    ),
                                    'PHQ-9': (
                                        <Icon
                                            name="notes"
                                            size={13}
                                            color="#595959"
                                        />
                                    ),
                                    TIPSA: (
                                        <Icon
                                            name="notes"
                                            size={13}
                                            color="#595959"
                                        />
                                    ),
                                }

                                assessments = assessments.map(
                                    ({
                                        assessment_name,
                                        score,
                                        color,
                                        bg_color,
                                        assessment_type_id,
                                    }) => {
                                        return {
                                            title: assessment_name,
                                            score,
                                            color,
                                            bg_color,
                                            assessment_type_id,
                                            icon: icons[assessment_name],
                                            ranges:
                                                assessments.find(
                                                    ({ name }) =>
                                                        name === assessment_name
                                                )?.settings?.ranges || [],
                                        }
                                    }
                                )

                                return (
                                    <div
                                        className="rpm-provider-record"
                                        key={id}
                                    >
                                        <div className="rpm-provider-record-header">
                                            <div className="rpm-provider-record-header-status-container">
                                                <div
                                                    className="rpm-provider-record-header-status with-border"
                                                    style={{
                                                        borderColor: getEligibilityColor(
                                                            {
                                                                eligibility,
                                                            }
                                                        ),
                                                    }}
                                                >
                                                    <div
                                                        className="rpm-provider-record-header-status-dot"
                                                        style={{
                                                            backgroundColor: getEligibilityColor(
                                                                {
                                                                    eligibility,
                                                                }
                                                            ),
                                                        }}
                                                    />
                                                    <p
                                                        className={`rpm-provider-record-header-status-text`}
                                                        style={{
                                                            color: getEligibilityColor(
                                                                {
                                                                    eligibility,
                                                                }
                                                            ),
                                                        }}
                                                    >
                                                        {(eligibility &&
                                                            t(
                                                                `provider_dashboard.${eligibility.toLowerCase()}`
                                                            )) ||
                                                            t(
                                                                'provider_dashboard.pending'
                                                            )}
                                                    </p>
                                                </div>
                                                {/* <div className="rpm-provider-record-header-status">
                                        <div
                                            className={`rpm-provider-record-header-status-text ${
                                                record.depression
                                                    ? 'eligible'
                                                    : ''
                                            }`}
                                        >
                                            F99
                                        </div>
                                    </div> */}
                                            </div>
                                            <div
                                                className="rpm-provider-record-header-user"
                                                onClick={() => {
                                                    window.location.href = `/rpm/client-profile/${id}`
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <div className="rpm-provider-record-header-user-avatar">
                                                    <img
                                                        src={AvatarBackground}
                                                        alt="avatar"
                                                    />
                                                    <p className="rpm-provider-record-header-user-avatar-text">
                                                        {first_name[0]}
                                                        {last_name[0]}
                                                    </p>
                                                </div>
                                                <p className="rpm-provider-record-header-user-text">
                                                    {first_name} {last_name}
                                                </p>
                                            </div>
                                            <div className="rpm-alerts-wrapper">
                                                {eligibility ? (
                                                    <Alerts
                                                        id={id}
                                                        alerts={alerts}
                                                        onHide={() =>
                                                            refetchClients()
                                                        }
                                                        first={idx === 0}
                                                    />
                                                ) : (
                                                    <p
                                                        className="rpm-alerts-wrapper__resend-invitation"
                                                        role="button"
                                                        onClick={() => {
                                                            sendWelcomeEmail({
                                                                userId: id,
                                                            }).then(() =>
                                                                setShowInvitationConfirmation(
                                                                    true
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        {t(
                                                            'provider_dashboard.resend_invitation'
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="rpm-provider-record-grid"
                                            style={{
                                                backgroundColor: '#fff',
                                                gridTemplateColumns: `repeat(${
                                                    numberOfColumns > 6
                                                        ? 6
                                                        : numberOfColumns
                                                }, 1fr)`,
                                                // if there are more than 9 columns, we need to add more rows
                                                gridTemplateRows:
                                                    numberOfColumns > 6
                                                        ? `repeat(${Math.ceil(
                                                              numberOfColumns /
                                                                  6
                                                          )}, 1fr)`
                                                        : '1fr',

                                                // if there are more than 9 columns, we need to add more rows
                                                height:
                                                    numberOfColumns > 6
                                                        ? `${Math.ceil(
                                                              numberOfColumns /
                                                                  6
                                                          ) * 6.5}rem`
                                                        : '6.5rem',
                                            }}
                                        >
                                            <RecordColumn
                                                title={t(
                                                    'provider_dashboard.compliance_score'
                                                )}
                                                icon={
                                                    <Icon
                                                        name="hand-click"
                                                        size={15}
                                                        color="#595959"
                                                    />
                                                }
                                                score={
                                                    (compliance_score?.days_track ||
                                                        0) > 16
                                                        ? 16
                                                        : (compliance_score?.days_track ||
                                                              0) + '/16'
                                                }
                                                ranges={[]}
                                            />

                                            {assessments &&
                                                assessments.map(
                                                    ({
                                                        title,
                                                        score,
                                                        color,
                                                        bg_color,
                                                        icon,
                                                        ranges,
                                                    }) => (
                                                        <RecordColumn
                                                            title={title}
                                                            icon={icon}
                                                            score={score}
                                                            ranges={ranges}
                                                            color={color}
                                                            bg_color={bg_color}
                                                        />
                                                    )
                                                )}

                                            {assessments.length < 2 && (
                                                <>
                                                    {Array(
                                                        2 - assessments.length
                                                    )
                                                        .fill(0)
                                                        .map((_, index) => (
                                                            <RecordColumn
                                                                title={
                                                                    index === 0
                                                                        ? 'GAD-7'
                                                                        : 'PHQ-9'
                                                                }
                                                                icon={
                                                                    <Icon
                                                                        name="notes"
                                                                        size={
                                                                            13
                                                                        }
                                                                        color="#595959"
                                                                    />
                                                                }
                                                                score={0}
                                                                ranges={[]}
                                                            />
                                                        ))}
                                                </>
                                            )}
                                            <RecordColumn
                                                title={t(
                                                    'provider_dashboard.mood'
                                                )}
                                                icon={
                                                    <Icon
                                                        name="mood"
                                                        size={15}
                                                        color="#595959"
                                                    />
                                                }
                                                score={
                                                    Math.floor(mood?.value) || 0
                                                }
                                                ranges={[]}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </InfiniteScroll>
                </div>
            )}
        </Fragment>
    )
}
