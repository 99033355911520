import React, { useRef } from 'react'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'

export default function Sort({ params, setParams }) {
    const [open, setOpen] = React.useState(false)
    const ref = useRef(null)
    useOutsideAlerter(ref, () => setOpen(false))

    const options = {
        recent_activity: {
            name: 'Last Activity',
            value: '-last_seen',
            selected: false,
        },
        first_name: {
            name: 'First Name',
            value: 'first_name',
            selected: false,
        },
        last_name: {
            name: 'Last Name',
            value: 'last_name',
            selected: false,
        },
        email: {
            name: 'Email',
            value: 'email',
            selected: false,
        },
    }

    const [sortingBy, setSortingBy] = React.useState(null)

    const toggle = name => {
        if (sortingBy === name) {
            setSortingBy('-' + name)
            setParams({ ...params, ordering: '-' + options[name].value })
        } else if (sortingBy === '-' + name) {
            setSortingBy(null)
            const { ordering, ...rest } = params
            setParams(rest)
        } else {
            setSortingBy(name)
            setParams({ ...params, ordering: options[name].value })
        }
    }

    return (
        <div className="search-container-wrapper">
            <div
                className="search-container-filter"
                onClick={() => setOpen(true)}
                style={{ backgroundColor: open ? '#D3D0CC' : '#FFFFFF' }}
            >
                <Icon
                    name="sort"
                    size={12.5}
                    color={open ? '#908E87' : '#343434'}
                />
                <p
                    className="search-container-filter-text"
                    style={{ color: open ? '#908E87' : '#343434' }}
                >
                    Recent
                </p>
            </div>
            {open && (
                <div
                    className="search-container-filter-sort-container"
                    ref={ref}
                >
                    {Object.keys(options).map(key => (
                        <div
                            className="search-container-filter-sort-container-item"
                            key={key}
                            onClick={() => toggle(key)}
                            style={{
                                borderBottom:
                                    key ===
                                        Object.keys(options)[
                                            Object.keys(options).length - 1
                                        ] && 'none',
                            }}
                        >
                            <Icon
                                name="radio-input"
                                active={
                                    sortingBy === key || sortingBy === '-' + key
                                }
                            />
                            <p>{options[key].name}</p>

                            {
                                <div
                                    // rotate the arrow if the sorting by is descending or ascending
                                    style={{
                                        display:
                                            sortingBy === key ||
                                            sortingBy === '-' + key
                                                ? 'flex'
                                                : 'none',
                                        transform:
                                            sortingBy === key
                                                ? 'rotate(90deg)'
                                                : 'rotate(-90deg)',
                                    }}
                                >
                                    <Icon name="arrow-right" color="#343434" />
                                </div>
                            }
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
