import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  USER_CHANGE_PAYMENT_METHOD_CARD_BEGIN,
  USER_CHANGE_PAYMENT_METHOD_CARD_SUCCESS,
  USER_CHANGE_PAYMENT_METHOD_CARD_FAILURE,
  USER_CHANGE_PAYMENT_METHOD_CARD_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function changePaymentMethodCard(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: USER_CHANGE_PAYMENT_METHOD_CARD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.apiRootUrl}/subscriptions/change-payment-method-card`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: USER_CHANGE_PAYMENT_METHOD_CARD_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: USER_CHANGE_PAYMENT_METHOD_CARD_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissChangePaymentMethodCardError() {
  return {
    type: USER_CHANGE_PAYMENT_METHOD_CARD_DISMISS_ERROR,
  };
}

export function useChangePaymentMethodCard() {
  const dispatch = useDispatch();

  const { changePaymentMethodCardPending, changePaymentMethodCardError } =
    useSelector(
      (state) => ({
        changePaymentMethodCardPending:
          state.user.changePaymentMethodCardPending,
        changePaymentMethodCardError: state.user.changePaymentMethodCardError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(changePaymentMethodCard(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissChangePaymentMethodCardError());
  }, [dispatch]);

  return {
    changePaymentMethodCard: boundAction,
    changePaymentMethodCardPending,
    changePaymentMethodCardError,
    dismissChangePaymentMethodCardError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_CHANGE_PAYMENT_METHOD_CARD_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        changePaymentMethodCardPending: true,
        changePaymentMethodCardError: null,
      };

    case USER_CHANGE_PAYMENT_METHOD_CARD_SUCCESS:
      // The request is success
      return {
        ...state,
        changePaymentMethodCardPending: false,
        changePaymentMethodCardError: null,
      };

    case USER_CHANGE_PAYMENT_METHOD_CARD_FAILURE:
      // The request is failed
      return {
        ...state,
        changePaymentMethodCardPending: false,
        changePaymentMethodCardError: action.data.error,
      };

    case USER_CHANGE_PAYMENT_METHOD_CARD_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        changePaymentMethodCardError: null,
      };

    default:
      return state;
  }
}
