export const messages = {
  messages: 'Mensajes',
  all: 'Todos',
  group: 'Grupo',
  type_a_message: 'Escribe un mensaje',
  delete_group: 'Eliminar grupo',
  members: 'Miembros',
  leave_group: 'Dejar grupo',
  group_info: 'Información del grupo',
};
