import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetSettingsByName } from './redux/getSettingsByName'

import { useSetDefaultLanguage } from './redux/setDefaultLanguage'
import SelfSignupProgress from './components/SelfSignupProgress'
export default function Download() {
  const { getSettingsByName, settings: data } = useGetSettingsByName()
  const { defaultLanguage } = useSetDefaultLanguage()

  const { company: name, type } = useParams()

  useEffect(() => {
    getSettingsByName({ name, type })
  }, [name])

  useEffect(() => {
    console.log('data', data)
  }, [data])

  if (!data) return null

  return (
    <div>
      <div className="auth-download-navbar">
        <div className="auth-download-navbar__logo-container">
          <Link to={`/auth/${name}/${type}` + '?lang=' + defaultLanguage}>
            <img src={data.globals.logo} alt="logo" />
          </Link>
        </div>

        <SelfSignupProgress settings={data} />
      </div>

      <div className="auth-download">
        <div className="auth-download__content">
          <img
            src={data?.pages[defaultLanguage]?.download_app?.media.company_icon}
            alt="logo"
            className="auth-download__content__logo"
          />
          <h1>{data?.pages[defaultLanguage]?.download_app?.content.title}</h1>
          <p className="auth-download__content__subtitle">
            {data?.pages[defaultLanguage]?.download_app?.content.subtitle}
          </p>
          <div className="auth-download__content__qrs-wrapper">
            <div className="auth-download__content__qrs-wrapper__qr-container">
              <p className="auth-download__content__qrs-wrapper__qr-container__title">
                {
                  data?.pages[defaultLanguage]?.download_app?.content
                    .app_store_label
                }
              </p>

              <img
                src={
                  data?.pages[defaultLanguage]?.download_app?.media.app_store_qr
                }
                className="auth-download__content__qrs-wrapper__qr-container__qr"
                alt="qr"
              />
              <a
                href={
                  data?.pages[defaultLanguage]?.download_app?.content
                    .app_store_link
                }
              >
                <img
                  src={
                    data?.pages[defaultLanguage]?.download_app?.media
                      .app_store_button
                  }
                  alt="button"
                />
              </a>
            </div>
            <div className="auth-download__content__qrs-wrapper__qr-container">
              <p className="auth-download__content__qrs-wrapper__qr-container__title">
                {
                  data?.pages[defaultLanguage]?.download_app?.content
                    .google_play_label
                }
              </p>

              <img
                src={
                  data?.pages[defaultLanguage]?.download_app?.media
                    .google_play_qr
                }
                className="auth-download__content__qrs-wrapper__qr-container__qr"
                alt="qr"
              />
              <a
                href={
                  data?.pages[defaultLanguage]?.download_app?.content
                    .google_play_link
                }
              >
                <img
                  src={
                    data?.pages[defaultLanguage]?.download_app?.media
                      .google_play_button
                  }
                  alt="button"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          className="auth-download__background-image"
          style={{
            backgroundImage: `url(${data?.pages[defaultLanguage]?.download_app?.media.primary_image})`,
          }}
        >
          <div
            className="auth-download__background-image__secondary-image"
            style={{
              backgroundImage: `url(${data?.pages[defaultLanguage]?.download_app?.media.secondary_image})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

Download.propTypes = {}
Download.defaultProps = {}
