import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const CircleIcon = ({ size = 12, color = '#686868', style = {} }) => {
  return (
    <svg
      style={{ ...style }}
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill={color} />
    </svg>
  );
};

export default CircleIcon;
