// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState'
import { reducer as listHrvReducer } from './listHrv'
import { reducer as listDevicesReducer } from './listDevices'
import { reducer as fitbitOauthReducer } from './fitbitOauth'
import { reducer as fetchUnifiedSummaryReducer } from './fetchUnifiedSummary'
import { reducer as createInsuranceInformationReducer } from './createInsuranceInformation'
import { reducer as createProfileAddressReducer } from './createProfileAddress'
import { reducer as fetchAssessmentsReducer } from './fetchAssessments'
import { reducer as createAssessmentScoreReducer } from './createAssessmentScore'
import { reducer as getCptListReducer } from './getCptList'
import { reducer as createCptLogReducer } from './createCptLog'
import { reducer as getClientsReducer } from './getClients'
import { reducer as getProfileSummaryReducer } from './getProfileSummary'
import { reducer as getUserInsuranceInformationReducer } from './getUserInsuranceInformation'
import { reducer as updateUserInsuranceInformationReducer } from './updateUserInsuranceInformation'
import { reducer as getLatestUnifiedSummaryReducer } from './getLatestUnifiedSummary'
import { reducer as getAlertsReducer } from './getAlerts'
import { reducer as clearAllAlertsReducer } from './clearAllAlerts'
import { reducer as getBillingReducer } from './getBilling'
import { reducer as deleteCptLogReducer } from './deleteCptLog'
import { reducer as updateCptLogReducer } from './updateCptLog'
import { reducer as updateAssessmentReducer } from './updateAssessment'
import { reducer as deleteAssessmentReducer } from './deleteAssessment'
import { reducer as getProvidersSummaryReducer } from './getProvidersSummary'
import { reducer as trackCallEventReducer } from './trackCallEvent'
import { reducer as fetchCompanyLinksReducer } from './fetchCompanyLinks'

const reducers = [
    listHrvReducer,
    listDevicesReducer,
    fitbitOauthReducer,
    fetchUnifiedSummaryReducer,
    createInsuranceInformationReducer,
    createProfileAddressReducer,
    fetchAssessmentsReducer,
    createAssessmentScoreReducer,
    getCptListReducer,
    createCptLogReducer,
    getClientsReducer,
    getProfileSummaryReducer,
    getUserInsuranceInformationReducer,
    updateUserInsuranceInformationReducer,
    getLatestUnifiedSummaryReducer,
    getAlertsReducer,
    clearAllAlertsReducer,
    getBillingReducer,
  deleteCptLogReducer,
  updateCptLogReducer,
  updateAssessmentReducer,
  deleteAssessmentReducer,
  getProvidersSummaryReducer,
  trackCallEventReducer,
  fetchCompanyLinksReducer,
]

export default function reducer(state = initialState, action) {
    let newState
    switch (action.type) {
        // Handle cross-topic actions here
        default:
            newState = state
            break
    }
    return reducers.reduce((s, r) => r(s, action), newState)
}
