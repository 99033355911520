import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers'
import { useFetchUpdates, useSetUnreadMessages } from '../common/redux/hooks'
import useInterval from '../common/useIntervalHook'
import usePrivateLabelledSettings from '../common/usePrivateLabelledSettingsHook'
import { useSetDefaultLanguage } from '../translations/redux/setDefaultLanguage'
import { useFetchUser, useProfileSettings } from '../user/redux/hooks'
import TermsAndConditionsModal from '../user/TermsAndConditionsModal'
import PendoEffect from './hooks/PendoEffect'
import { useFetchAuthToken } from './redux/hooks'
import { useLocation, useHistory } from 'react-router-dom'

//Disable logs in production
if (
  process.env.REACT_APP_ENV === 'staging' ||
  process.env.REACT_APP_ENV === 'production'
) {
  console.log = function() {}
  console.warn = () => {}
}

console.error = error => {
  if (typeof error === 'string') {
    // this error is thrown when using the audio recorder.
    if (error?.split(' ')?.includes('MIME')) {
      return null
    }
    return error
  }
}

export default function App({ children }) {
  useSetDefaultLanguage()

  const [unreadMessagesCountFetched, setUnreadMessagesCountFetched] = useState(
    false
  )

  const unauthorizedErrorHandler = useUnauthorizedErrorHandler()
  const { updates, fetchUpdates } = useFetchUpdates()
  const { authToken, userApproved } = useFetchAuthToken()
  const { user, fetchUser } = useFetchUser()
  const { setUnreadMessages } = useSetUnreadMessages()
  const { defaultTheme } = usePrivateLabelledSettings()

  const location = useLocation()
  const history = useHistory()

  const { profileSettings: getProfileSettings } = useProfileSettings()

  useEffect(() => {
    getProfileSettings()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const osano = document.getElementsByClassName('osano-cm-window__dialog')
      if (osano && osano[0]) {
        osano[0].remove()
      }
    }, 2000)
  }, [])

  /*   useEffect(() => {
        if (!user) return
        console.log('user', user)

        if (user.company_is_rtm) {
            // if path doesn't not start with /rpm or /messages then redirect to an rpm page
            if (location.pathname.startsWith('/dashboard')) {
                console.log(location.pathname, 'i am here')
                if (user.groups.includes('Admin')) {
                    // TODO: establish a logic to redirect to the correct page for admins
                    if (user.user_is_internal_coach) {
                        history.push('/rpm/coach-dashboard')
                        return
                    }
                    history.push('/rpm/provider-dashboard')
                }

                if (user.groups.length === 1 && user.groups[0] === 'User') {
                    history.push('/rpm/download-app')
                    return
                }

                if (
                    // if is provider
                    user.groups.includes('Coach') &&
                    !user.user_is_internal_coach
                ) {
                    history.push('/rpm/provider-dashboard')
                    return
                }

                if (
                    // if is coach
                    user.groups.includes('Coach') &&
                    user.user_is_internal_coach
                ) {
                    history.push('/rpm/coach-dashboard')
                    return
                }
            }
        }
    }, [user, location.pathname, history]) */

  // make html font size 16px
  useEffect(() => {
    document.documentElement.style.fontSize = '16px'
  }, [])

  useEffect(() => {
    if (authToken) {
      fetchUser().catch(unauthorizedErrorHandler)
    }
  }, [authToken, fetchUser, unauthorizedErrorHandler])

  useEffect(() => {
    if (authToken) {
      document.cookie = `authtoken=${authToken};path=/;secure`
    } else {
      document.cookie =
        'authtoken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
  }, [authToken])

  const fetchUpdatesCallback = useCallback(async () => {
    if (authToken && userApproved) {
      fetchUpdates().catch(unauthorizedErrorHandler)
    }
  }, [authToken, userApproved, fetchUpdates, unauthorizedErrorHandler])

  // TODO: Ideally this should be a websocket-based real-time updates fetching
  // However currently we give up low latency for ease of implementation & deployment
  useInterval(fetchUpdatesCallback, 15 * 1000, true) // Every 15 seconds

  // This effect is only needed for one time unread messages count initialization
  useEffect(() => {
    if (unreadMessagesCountFetched || !updates.unread_messages_count) return
    setUnreadMessages(updates.unread_messages_count)
    setUnreadMessagesCountFetched(true)
  }, [
    unreadMessagesCountFetched,
    updates.unread_messages_count,
    setUnreadMessages,
    setUnreadMessagesCountFetched,
  ])

  const theme = useMemo(() => {
    if (user && user.theme) {
      return user.theme
    } else if (user && user.default_theme) {
      return user.default_theme
    } else if (defaultTheme) {
      return defaultTheme
    } else {
      return 'theme-light'
    }
  }, [user, defaultTheme])

  useEffect(() => {
    if (history.location.pathname.startsWith('/rpm/signup')) return

    if (!user) return

    if (
      !user.privacy_policy ||
      !user.terms_and_conditions ||
      (!user.dac && user.groups.includes('Coach'))
    ) {
      history.push('/auth/terms-and-conditions')
    }
  }, [user])

  // This line is essential, it adds the theme to the whole app (Changed this to the body tag so it doesn't affect Cookie Consent)
  document.body.className = theme

  return (
    <div className={clsx('home-app')}>
      <div className={clsx('page-container theme-bg')}>{children}</div>
      <PendoEffect />
      {/*       <PaySubscriptionEffect />
       */}{' '}
    </div>
  )
}
