import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const SortIcon = ({ size = 10, color = '#343434', style = {} }) => {
  return (
    <svg
      width={size * 1.3}
      height={size}
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9_9747)">
        <path
          d="M5.08865 6.61864L3.68757 7.92892V0.576369C3.68757 0.257445 3.41229 0 3.07127 0C2.73024 0 2.45496 0.257445 2.45496 0.576369V7.93084L1.05388 6.62056C0.813523 6.39577 0.423194 6.39577 0.182834 6.62056C-0.0575266 6.84534 -0.0575266 7.21038 0.182834 7.43516L2.63574 9.72911C2.75695 9.84246 2.91308 9.89818 3.07127 9.89818C3.22945 9.89818 3.38764 9.84246 3.50679 9.72911L5.9597 7.43516C6.20006 7.21038 6.20006 6.84534 5.9597 6.62056C5.71934 6.39577 5.32901 6.39577 5.08865 6.62056V6.61864Z"
          fill={color}
        />
        <path
          d="M12.8192 2.56494L10.3642 0.270991C10.1342 0.0558136 9.72328 0.0538923 9.49319 0.270991L7.04029 2.56494C6.79993 2.78972 6.79993 3.15476 7.04029 3.37954C7.28065 3.60432 7.67097 3.60432 7.91133 3.37954L9.31241 2.06926V9.42373C9.31241 9.74265 9.58769 10.0001 9.92872 10.0001C10.2697 10.0001 10.545 9.74265 10.545 9.42373V2.06926L11.9461 3.37954C12.0673 3.49289 12.2234 3.54861 12.3816 3.54861C12.5398 3.54861 12.698 3.49289 12.8172 3.37954C13.0575 3.15476 13.0575 2.78972 12.8172 2.56494H12.8192Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9_9747">
          <rect width={size * 1.3} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SortIcon;
