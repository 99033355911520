import React from 'react';

export default function HeadsetIcon({
  className,
  size = 13,
  color = '#6D786E',
  style,
}) {
  return (
    <svg
      width={size}
      height={size * 1.083}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
    >
      <path
        d="M11.3534 3.62742C11.2076 3.56932 11.0328 3.56932 10.887 3.59847C10.4498 1.53798 8.61335 0 6.39812 0C4.29938 0 2.52129 1.53804 2.05485 3.59847C1.90906 3.56953 1.76328 3.56953 1.6177 3.62742C1.12219 3.80151 0.684822 4.12073 0.393435 4.55615C-0.131145 5.33973 -0.131145 6.4134 0.393435 7.19698C0.685009 7.63219 1.12216 7.95144 1.6177 8.12571C1.70513 8.15466 1.79256 8.1838 1.87999 8.1838C1.93835 8.1838 1.96742 8.18381 2.02578 8.15486C2.259 10.7086 3.10424 11.5501 5.93171 11.6372C5.96078 11.6952 5.99006 11.7242 6.04822 11.7823C6.33979 12.0726 6.80604 12.0726 7.09758 11.7823C7.38916 11.492 7.38916 11.0278 7.09758 10.7376C6.80601 10.4473 6.33976 10.4473 6.04822 10.7376C6.01914 10.7665 5.98986 10.8246 5.96078 10.8536C3.45396 10.7955 2.90007 10.3022 2.78358 6.84883V4.4983V4.41126C2.92937 2.43803 4.50333 0.870871 6.42722 0.870871C8.49691 0.870871 10.1874 2.554 10.1874 4.61429C10.1874 4.67238 10.1874 4.70133 10.2164 4.73028V7.31302C10.2164 7.6033 10.3622 7.86444 10.5954 8.03851C10.7412 8.1545 10.9161 8.21259 11.12 8.21259C11.2074 8.21259 11.2949 8.18365 11.3823 8.1545C11.8778 7.98041 12.3152 7.66119 12.6066 7.22577C13.1311 6.44219 13.1311 5.36852 12.6066 4.58494C12.2863 4.09183 11.8489 3.77257 11.3534 3.62742ZM1.9674 4.43997V7.25486C1.9674 7.28381 1.93832 7.31296 1.93832 7.31296C1.90925 7.31296 1.90925 7.34191 1.87997 7.31296C1.55932 7.19697 1.26773 6.99373 1.06379 6.70345C0.714065 6.1812 0.714065 5.48463 1.06379 4.99135C1.26793 4.70107 1.5593 4.49804 1.87997 4.38183H1.90904H1.93812C1.93832 4.41098 1.9674 4.41102 1.9674 4.43997ZM11.0911 7.34192H11.0327C11.0327 7.34192 11.0036 7.31298 11.0036 7.28383V4.46893C11.0036 4.43998 11.0327 4.41083 11.0327 4.41083H11.0618H11.0909C11.4115 4.52682 11.7031 4.73006 11.907 5.02035C12.2568 5.5426 12.2568 6.23917 11.907 6.73245C11.7031 7.02273 11.4408 7.22571 11.0911 7.34192Z"
        fill={color}
      />
    </svg>
  );
}
