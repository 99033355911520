import React from 'react'
import Icon from '../../../common/components/Icon'
import useWindowSize from '../../../common/useWindowResize'
import { useGetProvidersSummary } from '../../redux/getProvidersSummary'

export default function CardsContainer() {
    const { isMobile } = useWindowSize()
    const [activeStep, setActiveStep] = React.useState(0)

    // create a set time out function that will change the active step every 5 seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            setActiveStep(prev => (prev + 1) % 2)
        }, 5000)

        return () => clearInterval(interval)
    }, [])

    const { providersSummary } = useGetProvidersSummary()

    if (!providersSummary) return null

    const {
        clients,
        plus_clients,
        eligible_clients,
        eligible_clients_percent,
    } = providersSummary

    if (isMobile)
        return (
            <div className="rpm-provider-mobile-summary-card">
                <h1>Monthly Overview</h1>

                <div className="rpm-provider-mobile-summary-card__label-and-value">
                    <div className="rpm-provider-mobile-summary-card__label-and-value__label">
                        <div className="rpm-provider-mobile-summary-card__label-and-value__label__icon-container">
                            <Icon name="user" color="#fff" size={10.5} />
                        </div>
                        <p>Total Clients</p>
                    </div>
                    <div className="rpm-provider-mobile-summary-card__label-and-value__value">
                        <h2>{clients}</h2>
                        <div className="rpm-provider-mobile-summary-card__label-and-value__value__description">
                            <div
                                className="rpm-provider-mobile-summary-card__label-and-value__value__description__icon-container"
                                style={{
                                    transform: 'rotate(-45deg)',
                                }}
                            >
                                <Icon
                                    name="arrow-right"
                                    color="#9DA16B"
                                    size={14.5}
                                />
                            </div>
                            <p>+{plus_clients} this month</p>
                        </div>
                    </div>
                </div>

                <div className="rpm-provider-mobile-summary-card__steps">
                    {Array(2)
                        .fill(0)
                        .map((_, idx) => (
                            <div
                                className={`rpm-provider-mobile-summary-card__steps__step ${
                                    activeStep === idx ? 'active' : ''
                                }`}
                                key={idx}
                                role="button"
                                onClick={() => setActiveStep(idx)}
                            />
                        ))}
                </div>
            </div>
        )

    return (
        <div className="rpm-provider-cards-container">
            <div className="rpm-provider-card">
                <div className="rpm-provider-card-header">
                    <div className="rpm-provider-card-header-icon">
                        <Icon name={'user'} color="#fff" size={10.5} />
                    </div>
                    <p className="rpm-provider-card-header-text">
                        Total Clients
                    </p>
                </div>
                <div className="rpm-provider-card-number-container">
                    <h2 className="rpm-provider-card-number">{clients}</h2>
                    <div className="rpm-provider-card-percentage">
                        <p className="rpm-provider-card-percentage-text">
                            +{plus_clients}
                        </p>
                    </div>
                </div>{' '}
               {/*  <div className="rpm-provider-card-footer">
                    <div
                        className="rpm-provider-card-footer-icon"
                        style={{
                            transform: 'rotate(-45deg)',
                        }}
                    >
                        <Icon name="arrow-right" color="#9DA16B" size={14.5} />
                    </div>
                    <p className="rpm-provider-card-footer-text">
                        {plus_clients} this month
                    </p>
                </div> */}
            </div>
            <div
                className="rpm-provider-card"
                style={{
                    borderRight: 'none',
                }}
            >
                <div className="rpm-provider-card-header">
                    <div className="rpm-provider-card-header-icon">
                        <Icon name={'user'} color="#fff" size={10.5} />
                    </div>
                    <p className="rpm-provider-card-header-text">
                        Eligible Clients
                    </p>
                </div>
                <div className="rpm-provider-card-number-container">
                    <h2 className="rpm-provider-card-number">
                        {' '}
                        {eligible_clients}
                    </h2>
                    <div className="rpm-provider-card-percentage">
                        <p className="rpm-provider-card-percentage-text">
                            {Number(eligible_clients_percent).toFixed(1)}%
                        </p>
                    </div>
                </div>
            {/*     <div className="rpm-provider-card-footer">
                    <div
                        className="rpm-provider-card-footer-icon"
                        style={{
                            transform: 'rotate(45deg)',
                        }}
                    >
                        <Icon name="arrow-right" color="#E8744F" size={14.5} />
                    </div>
                    <p className="rpm-provider-card-footer-text">
                        {plus_clients > 0 ? '+' : '-'}
                        {plus_clients}
                        this month
                    </p>
                </div> */}
            </div>
        </div>
    )
}
