import React, { createContext, useState } from 'react';
// import PropTypes from 'prop-types';
import Sidebar from './Sidebar';
import DesktopHeader from '../common/DesktopHeader';
import FormContainer from './FormContainer';

export const SupportContext = createContext();
export default function Dashboard() {
  const [stepValues, setStepValues] = React.useState({});
  const [userExists, setUserExists] = useState(false);

  const [steps, setSteps] = useState({
    1: {
      state: 'active',
      edit: null,
    },
    2: {
      state: 'inactive',
      edit: null,
    },
    3: {
      state: 'inactive',
      edit: null,
    },
    4: {
      state: 'inactive',
      edit: null,
    },
  });

  const [isCompleted, setIsCompleted] = useState(false);

  const [selectedSub, setSelectedSub] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [companyName, setCompanyName] = useState('');

  return (
    <SupportContext.Provider
      value={{
        stepValues,
        setStepValues,
        steps,
        setSteps,
        selectedSub,
        setSelectedSub,
        selectedCoach,
        setSelectedCoach,
        isCompleted,
        setIsCompleted,
        userExists,
        setUserExists,
        companyName,
        setCompanyName,
      }}
    >
      <div className="support-dashboard">
        <DesktopHeader />
        <div className="support-dashboard-body">
          <Sidebar />
          <FormContainer />
        </div>
      </div>
    </SupportContext.Provider>
  );
}

Dashboard.propTypes = {};
Dashboard.defaultProps = {};
