import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_CREATE_PROFILE_ADDRESS_BEGIN,
    RPM_CREATE_PROFILE_ADDRESS_SUCCESS,
    RPM_CREATE_PROFILE_ADDRESS_FAILURE,
    RPM_CREATE_PROFILE_ADDRESS_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function createProfileAddress(args = {}, method = 'POST') {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_CREATE_PROFILE_ADDRESS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const fetchMethod = method === 'POST' ? axios.post : axios.put

            let url = ''

            if (args.userId) {
                url =
                    config.apiRootUrl +
                    '/users/' +
                    args.userId +
                    '/full-address'
            } else {
                url = config.apiRootUrl + '/profile/full-address'
            }

            const doRequest = fetchMethod(
                url,
                {
                    address_line_1: args.address_1,
                    address_line_2: args.address_2,
                    state: args.state,
                    city: args.city,
                    zip_code: args.zip,
                },
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_CREATE_PROFILE_ADDRESS_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_CREATE_PROFILE_ADDRESS_FAILURE,
                        data: {
                            error: err,
                        },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissCreateProfileAddressError() {
    return {
        type: RPM_CREATE_PROFILE_ADDRESS_DISMISS_ERROR,
    }
}

export function useCreateProfileAddress() {
    const dispatch = useDispatch()

    const {
        createProfileAddressPending,
        createProfileAddressError,
    } = useSelector(
        state => ({
            createProfileAddressPending: state.rpm.createProfileAddressPending,
            createProfileAddressError: state.rpm.createProfileAddressError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(createProfileAddress(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissCreateProfileAddressError())
    }, [dispatch])

    return {
        createProfileAddress: boundAction,
        createProfileAddressPending,
        createProfileAddressError,
        dismissCreateProfileAddressError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_CREATE_PROFILE_ADDRESS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                createProfileAddressPending: true,
                createProfileAddressError: null,
            }

        case RPM_CREATE_PROFILE_ADDRESS_SUCCESS:
            // The request is success
            return {
                ...state,
                createProfileAddressPending: false,
                createProfileAddressError: null,
            }

        case RPM_CREATE_PROFILE_ADDRESS_FAILURE:
            // The request is failed
            return {
                ...state,
                createProfileAddressPending: false,
                createProfileAddressError: action.data.error,
            }

        case RPM_CREATE_PROFILE_ADDRESS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                createProfileAddressError: null,
            }

        default:
            return state
    }
}
