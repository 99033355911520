import React from 'react';

export default function DeleteIcon({ className, color, size = 20, style }) {
  return (
    <svg
      width={size * 1.08}
      height={size}
      viewBox="0 0 57 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M49.644 17.9941H6.79137C6.40458 17.9888 6.02109 18.066 5.66634 18.2204C5.31159 18.3748 4.99369 18.6029 4.73373 18.8897C4.49278 19.1889 4.31338 19.5329 4.2059 19.9018C4.09842 20.2707 4.06497 20.6573 4.10749 21.0392L9.74364 59.0588C9.83957 59.6918 10.1582 60.2698 10.642 60.6885C11.1259 61.1072 11.7432 61.3392 12.3828 61.3426H44.0526C44.6897 61.3303 45.3023 61.0952 45.7844 60.6781C46.2664 60.2609 46.5874 59.688 46.6918 59.0588L52.3279 21.0392C52.3704 20.6573 52.337 20.2707 52.2295 19.9018C52.122 19.5329 51.9426 19.1889 51.7017 18.8897C51.4417 18.6029 51.1238 18.3748 50.7691 18.2204C50.4143 18.066 50.0308 17.9888 49.644 17.9941ZM41.6371 55.9688H14.7983L9.87784 23.3679H46.5576L41.6371 55.9688Z"
        fill={color}
      />
      <path
        d="M53.769 9.4954H40.0823V2.61341C40.0823 1.92029 39.8014 1.25556 39.3014 0.76545C38.8014 0.27534 38.1232 0 37.4161 0H19.0191C18.312 0 17.6338 0.27534 17.1338 0.76545C16.6338 1.25556 16.3529 1.92029 16.3529 2.61341V9.4954H2.66623C1.9591 9.4954 1.28094 9.77075 0.780921 10.2609C0.280906 10.751 0 11.4157 0 12.1088C0 12.8019 0.280906 13.4667 0.780921 13.9568C1.28094 14.4469 1.9591 14.7222 2.66623 14.7222H53.769C54.4761 14.7222 55.1543 14.4469 55.6543 13.9568C56.1543 13.4667 56.4352 12.8019 56.4352 12.1088C56.4352 11.4157 56.1543 10.751 55.6543 10.2609C55.1543 9.77075 54.4761 9.4954 53.769 9.4954ZM21.6853 5.13971H34.7499V9.4954H21.6853V5.13971Z"
        fill={color}
      />
    </svg>
  );
}
