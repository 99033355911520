import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Icon from '../../../../common/components/Icon';
import { useDeleteGroupChat } from '../../../redux/deleteGroupChat';

export default function EditingGroup({
  setAddMoreParticipants,
  setSelectedParticipants,
  selectedConversation,
  editGroup,
  user,
}) {
  const { deleteGroupChat } = useDeleteGroupChat();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteGroup = useCallback(async () => {
    deleteGroupChat({
      chatId: selectedConversation.id,
    }).then(() => {
      window.location.reload(false);
    });
  }, [deleteGroupChat, selectedConversation]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="menu-item-container-participant"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <Icon name="threedots" size={4} color="#C4C4C4" />
    </div>
  ));

  return (
    <Fragment>
      <div
        style={{
          padding: '1rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '95%',
          margin: '0 auto',
        }}
      >
        <p>Members</p>
        <div
          style={{
            backgroundColor: 'var(--primary-dark)',
            borderRadius: '0.4rem',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '1.625rem',
            height: '1.625rem',
          }}
        >
          <button
            onClick={() => {
              setAddMoreParticipants(true);
              setSelectedParticipants(selectedConversation.participants);
            }}
          >
            <Icon name="plus" color="var(--primary-white)" />
          </button>
        </div>
      </div>
      <div className="new-message">
        <Fragment>
          <div className="recipients">
            {selectedConversation?.participants?.map((recipient, idx) => {
              return (
                <div
                  key={idx}
                  className="recipient"
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="flex items-center gap-1">
                    <img
                      src={
                        recipient.photo
                          ? recipient.photo
                          : `https://ui-avatars.com/api/?name=${
                              recipient.first_name[0] + recipient.last_name[0]
                            }&background=718371&color=fff&size=128`
                      }
                    />
                    <h3 className="name">
                      {recipient.first_name} {recipient?.last_name}
                    </h3>
                    <p className="email">{recipient.email}</p>
                  </div>
                  {((recipient.username === user.username &&
                    selectedConversation.participants.filter(
                      (participant) => participant.is_admin_or_coach === true,
                    ).length > 1) ||
                    recipient.username !== user.username) && (
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{
                            '&:active': {
                              background: 'none',
                            },
                          }}
                        >
                          <button
                            style={{
                              outline: 'none',
                            }}
                            onClick={() => {
                              editGroup({
                                participants: [
                                  ...selectedConversation.participants
                                    .filter(
                                      (participant) =>
                                        participant.username !==
                                        recipient.username,
                                    )
                                    .map((participant) => {
                                      return participant.id;
                                    }),
                                ],
                              });
                            }}
                          >
                            {recipient.username === user.username
                              ? 'Leave Group'
                              : 'Remove from group'}
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              );
            })}
          </div>
          {/* <button
            onClick={() => {
              setShowDeleteModal(true);
            }}
            style={{
              backgroundColor: 'var(--primary-red)',
              color: 'var(--primary-white)',
              width: 'max-content',
              padding: '0.5rem 3rem',
              borderRadius: '5px',
              margin: '0 auto',
              marginTop: '1rem',
            }}
          >
            Delete Group
          </button> */}
        </Fragment>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="delete-group-modal"
      >
        <Modal.Header className="mrm-py-5">
          <Modal.Title>
            Are you sure you want to delete {selectedConversation.name}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This action cannot be undone.</p>
          <div className="buttons-container">
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            <button className="delete-btn" onClick={deleteGroup}>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
