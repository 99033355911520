import React from 'react';
import { useFetchUser } from '../../../../user/redux/fetchUser';
import SignupBackground from '../../../assets/signup-background.png';
import FitbitLogo from '../../../assets/connect-your-device/fitbit-logo.png';
import { useHistory } from 'react-router-dom';

export default function MobileConnect() {
  const { user } = useFetchUser();
  if (!user) return null;

  const history = useHistory();

  return (
    <div className="rpm-connect-your-device-mobile">
      <img src={SignupBackground} alt="background" className="background" />
      <div className="overlay" />
      <div className="rpm-connect-progress-bar">
        <div className="rpm-connect-progress-bar-fill" />
      </div>
      <div className="header">
        {/** TODO: this should be the provider profile pic */}
        <img
          src={
            user.photo ||
            `https://ui-avatars.com/api/?name=${
              user.first_name[0] + user.last_name[0]
            }&background=343434&color=ffffff&size=128`
          }
          alt="user"
          className="user-photo"
        />

        <h1>Connect Your Device to Add Your Health Data</h1>
        <button>View eligible devices</button>
      </div>

      <div className="watches-options">
        <button
          className="watch-option"
          onClick={() => history.push('/rpm/fitbit-connection')}
        >
          <img src={FitbitLogo} alt="Fitbit Logo" />
        </button>
        <p className="footer-text">More devices coming soon!</p>
        <button className="no-device">I don't have a device</button>
      </div>
    </div>
  );
}
