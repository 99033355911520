import React from 'react';

export default function CircleCheckIcon({ active, size = 22 }) {
  if (active) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="11" fill="#343434" />
        <path
          d="M16.3002 8.04129C16.1741 7.92322 16.0053 7.85718 15.8295 7.85718C15.6537 7.85718 15.4848 7.92322 15.3587 8.04129L9.82072 13.3803L7.37292 11.0204C7.24489 10.9189 7.08211 10.8671 6.91653 10.8754C6.75095 10.8836 6.59455 10.9512 6.47801 11.0649C6.36147 11.1785 6.29322 11.3301 6.28665 11.4898C6.28009 11.6495 6.33569 11.8058 6.44253 11.9281L9.35553 14.7471C9.41684 14.8054 9.4895 14.8514 9.56933 14.8826C9.64917 14.9137 9.7346 14.9294 9.82072 14.9286C9.99761 14.9285 10.1678 14.8636 10.297 14.7471L16.3002 8.94892C16.3635 8.89014 16.4139 8.81966 16.4483 8.74168C16.4827 8.6637 16.5004 8.57984 16.5004 8.4951C16.5004 8.41037 16.4827 8.3265 16.4483 8.24852C16.4139 8.17055 16.3635 8.10006 16.3002 8.04129Z"
          fill="white"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="10.5" stroke="#C4C4C4" />
      </svg>
    );
  }
}
