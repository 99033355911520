import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { Loader } from '../../common';
import { useFetchAssignedUsers } from '../../dashboard/redux/fetchAssignedUsers';
import { useGetListInternalProductSubscription } from '../../subscriptions/redux/getListInternalProductSubscription';
import { useFetchUser } from '../../user/redux/fetchUser';
import { useGetSubscriptionStatusByEmail } from '../../user/redux/getSubscriptionStatusByEmail';
import { useCreateSubscriptionOnboardingCallCenter } from '../redux/createSubscriptionOnboardingCallCenter';
import RadioInput from './formContainer/customInputs/RadioInput';

export default function ResendSubscriptionModal({ show, setShow }) {
  const { createSubscriptionOnboardingCallCenter } =
    useCreateSubscriptionOnboardingCallCenter();

  const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail();

  const [formData, updateFormData] = useState({
    selectedUser: null,
    selectedSubscription: null,
    is_trial: false,
    trial_end: null,
    by_sms_or_default: false,
  });

  const checkSubscriptionStatus = async (email) => {
    getSubscriptionStatusByEmail({ email: formData.selectedUser?.email })
      .then((res) => {
        if (res.data.current_subscription_state === 'active') {
          setError('User already has an active subscription');
          setShowError(true);
        } else {
          setError(null);
          setShowError(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    checkSubscriptionStatus(formData.selectedUser?.email);
  }, [formData.selectedUser]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const isValidated = () => {
    if (!formData.selectedUser) {
      setError('Please select a user');
      return false;
    }
    if (!formData.selectedSubscription) {
      setError('Please select a subscription');
      return false;
    }

    if (formData.is_trial === true && !formData.trial_end) {
      setError('Please select a trial end date');
      return false;
    }
    return true;
  };

  const { user } = useFetchUser();

  React.useEffect(() => {
    console.log(formData, 'formData');
    if (showError && error) {
      setShowError(false);
      setError(null);
    }
  }, [formData]);

  const { getListInternalProductSubscription } =
    useGetListInternalProductSubscription();

  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();

  const loadNames = (inputValue) => {
    return fetchAssignedUsers({
      search: inputValue,
    })
      .then((res) => {
        return res.results.map((user) => ({
          value: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
          ...user,
        }));
      })
      .catch((e) => handleError(e.response.data));
  };

  const loadEmails = (inputValue) => {
    return fetchAssignedUsers({
      search: inputValue,
    })
      .then((res) => {
        return res.results.map((user) => ({
          value: user.email,
          label: user.email,
          id: user.id,
          ...user,
        }));
      })
      .catch((e) => handleError(e.response.data));
  };

  const loadProducts = (inputValue) => {
    return getListInternalProductSubscription({
      company: user?.company_id,
    })
      .then((res) => {
        console.log(res, 'res');
        return res.data.results.map((product) => ({
          value: product.name,
          label: product.price
            ? `${product.name} - $${product.price?.unit_amount / 100}`
            : product.name,
          id: product.id,
        }));
      })
      .catch((e) => handleError(e.response.data));
  };

  const desktopMultiSelectStyles = {
    control: (styles, state) => {
      const custom = {
        ...styles,
        borderRadius: '10px',
        cursor: 'text',
        borderColor: '#E3E5E5',
        minHeight: '40px',
        fontWeight: '300',
        fontSize: '14px',
        '&:hover': {
          borderColor: '#E3E5E5',
        },
      };
      return custom;
    },
  };

  const handleUser = (inputValue) => {
    if (multiselectList.assignedUsers) {
      updateFormData({
        ...formData,
        selectedUser: inputValue,
      });
    }
  };

  const handleSubscription = (inputValue) => {
    if (multiselectList.assignedUsers) {
      updateFormData({
        ...formData,
        selectedSubscription: inputValue,
      });
    }
  };

  const multiselectList = {
    assignedUsers: assignedUsers
      ? assignedUsers.results.map((item) => ({
          id: item.id,
          full_name: `${item.first_name} ${item.last_name}`,
          ...item,
        }))
      : null,
  };

  const createSubscription = async (data) => {
    createSubscriptionOnboardingCallCenter(data)
      .then(() => {
        setIsLoading(false);
        setShow(false);
        setError(null);
        setShowError(false);
        updateFormData({
          selectedUser: null,
          selectedSubscription: null,
          is_trial: false,
          trial_end: null,
          by_sms_or_default: false,
        });
      })
      .catch(() => {
        console.log('error');
        setIsLoading(false);
        setShowError(true);
        setError('Something went wrong, please try again later');
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidated()) {
      setShowError(true);
      return;
    }

    setIsLoading(true);

    let data = {
      user_pk: formData.selectedUser.id,
      product: formData.selectedSubscription.id,
      by_sms_or_default: formData.by_sms_or_default,
    };
    if (formData.is_trial)
      data = {
        ...data,
        is_trial: true,
        trial_end: formData.trial_end,
      };

    createSubscription(data);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="resend-subscription-modal"
    >
      <Modal.Header closeButton>Resend invitation</Modal.Header>
      <Modal.Body>
        {showError && <p className="error-message">{error}</p>}

        <div
          className="flex flex-col"
          style={{
            gap: '1rem',
          }}
        >
          <AsyncSelect
            name="selectedUser"
            placeholder="Select user"
            value={
              formData.selectedUser
                ? {
                    value: `${formData.selectedUser.first_name} ${formData.selectedUser.last_name}`,
                    label: `${formData.selectedUser.first_name} ${formData.selectedUser.last_name}`,
                  }
                : null
            }
            loadOptions={loadNames}
            onChange={handleUser}
            styles={desktopMultiSelectStyles}
            defaultOptions
          />

          <AsyncSelect
            name="selectedEmail"
            placeholder="Select email"
            value={
              formData.selectedUser
                ? {
                    value: formData.selectedUser.email,
                    label: formData.selectedUser.email,
                  }
                : null
            }
            loadOptions={loadEmails}
            onChange={handleUser}
            styles={desktopMultiSelectStyles}
            defaultOptions
          />

          <AsyncSelect
            name="selectedProduct"
            placeholder="Select Product"
            value={formData.selectedSubscription}
            loadOptions={loadProducts}
            onChange={handleSubscription}
            styles={desktopMultiSelectStyles}
            defaultOptions
          />
          <div className="flex items-center gap-2">
            <RadioInput
              label="By email"
              active
              size={20}
              style={{ marginTop: '0' }}
            />
            <RadioInput
              label="Is Trial"
              active={formData.is_trial}
              onClick={() => {
                updateFormData({
                  ...formData,
                  is_trial: !formData.is_trial,
                });
              }}
              size={20}
              style={{ marginTop: '0' }}
            />
          </div>
          {formData.is_trial && (
            <input
              type="date"
              id="trial_end"
              name="trial_end"
              className="calendar-input"
              onChange={(e) => {
                updateFormData({
                  ...formData,
                  trial_end: e.target.value,
                });
              }}
              value={formData.trial_end}
              min={
                new Date(new Date().setDate(new Date().getDate() + 3))
                  .toISOString()
                  .split('T')[0]
              }
            />
          )}
        </div>
        <button
          className="resend-sub-btn flex items-center gap-1"
          onClick={handleSubmit}
          disabled={isLoading || error}
        >
          {isLoading && (
            <Loader
              position="static"
              className="text-light"
              animation="border"
              marginAuto={false}
            />
          )}

          <p>Resend</p>
        </button>
      </Modal.Body>
    </Modal>
  );
}
