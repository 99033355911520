import React, { useState, useEffect, memo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSelfSignUp } from '../../redux/hooks';
import { useFetchAuthToken } from '../../../home/redux/fetchAuthToken';
import { useHistory } from 'react-router-dom';
import { SignupSchema } from '../../schemas/SignupSchema';
import * as Yup from 'yup';

function ClientForm({ data, caregiver }) {
  const { fetchAuthToken } = useFetchAuthToken();
  const { selfSignUp } = useSelfSignUp();

  const [error, setError] = useState(''); // error from the backend

  const schema = SignupSchema.concat(
    Yup.object().shape({
      is_adult: Yup.boolean()
        .oneOf([true], 'You must be 18 years or older')
        .required('You must be 18 years or older'),
    }),
  );

  const history = useHistory();

  const handleSubmit = async (values) => {
    let fields = {
      ...values,
      company: data.companyId,
      is_signed_terms_and_conditions: values.terms,
    };

    delete fields.terms;
    delete fields.is_adult; // this is not a field in the backend

    selfSignUp(fields)
      .then(() => {
        fetchAuthToken({
          email: values.email,
          password: values.password,
        }).then(() => {
          if (!caregiver) {
            localStorage.setItem('companyAlias', data.name);
            localStorage.setItem('lastCampaign', 'default');
            history.push(`/subscriptions/default/${data.name}`);
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response, 'error');

          const errorKey = Object.keys(error.response.data)[0];
          setError(error.response.data[errorKey]);
        }
      });
  };

  useEffect(() => {
    const checkbox = document.getElementById('terms');
    checkbox.className = 'checkbox-styles';
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        //  alert(JSON.stringify(values, null, 2));
        handleSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={schema}
    >
      {(props) => (
        <Form className="client-form">
          <div className="form-group">
            <Field
              type="text"
              name="first_name"
              id="first_name"
              placeholder="First Name *"
            />
            {props.errors.first_name && props.touched.first_name && (
              <p className="error-message">
                <ErrorMessage name="first_name" />
              </p>
            )}
          </div>
          <div className="form-group">
            <Field
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last Name *"
            />{' '}
            {props.errors.last_name && props.touched.last_name && (
              <p className="error-message">
                <ErrorMessage name="last_name" />
              </p>
            )}
          </div>
          <div className="form-group">
            <Field
              type="text"
              name="phone_number"
              id="phone_number"
              placeholder="Phone Number *"
            />
            {props.errors.phone_number && props.touched.phone_number && (
              <p className="error-message">
                <ErrorMessage name="phone_number" />
              </p>
            )}
          </div>
          <div className="form-group">
            <Field type="email" name="email" id="email" placeholder="Email *" />{' '}
            {props.errors.email && props.touched.email && (
              <p className="error-message">
                <ErrorMessage name="email" />{' '}
              </p>
            )}
          </div>
          <div className="form-group">
            <Field
              type="password"
              name="password"
              id="password"
              placeholder="Password *"
            />
            {props.errors.password && props.touched.password && (
              <p className="error-message">
                <ErrorMessage name="password" />
              </p>
            )}
          </div>
          <div className="form-group">
            <Field
              type="password"
              name="password2"
              id="password2"
              placeholder="Confirm Password *"
            />{' '}
            {props.errors.password2 && props.touched.password2 && (
              <p className="error-message">
                <ErrorMessage name="password2" />
              </p>
            )}
          </div>
          <div className="terms-check">
            <Field type="checkbox" name="is_adult" id="is_adult" />
            <div className="terms-container">
              <p
                className="agree"
                style={{ color: data.colors.primary }}
                onClick={() =>
                  props.setFieldValue('is_adult', !props.values.is_adult)
                }
              >
                I am 18 years or older
              </p>

              {props.errors.is_adult && props.touched.is_adult && (
                <p className="error-message">
                  <ErrorMessage name="is_adult" />
                </p>
              )}
            </div>
          </div>
          <div className="terms-check" style={{ marginTop: '-.5rem' }}>
            <Field type="checkbox" name="terms" id="terms" />
            <div className="terms-container">
              <p
                className="agree"
                style={{ color: data.colors.primary }}
                onClick={() =>
                  props.setFieldValue('terms', !props.values.terms)
                }
              >
                I have read and agree to the following:
              </p>
              <p>
                <a
                  href="https://www.noble.health/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                {'&'}{' '}
                <a
                  href="https://noble.health/terms-of-service/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </p>
              {props.errors.terms && props.touched.terms && (
                <p className="error-message">
                  <ErrorMessage name="terms" />
                </p>
              )}
            </div>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button
            type="submit"
            className="signup-btn"
            disabled={props.isSubmitting}
            style={{
              backgroundColor: data.colors.primary,
            }}
          >
            Create Account{' '}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default memo(ClientForm);

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password2: '',
  phone_number: '',
  terms: false,
  is_adult: false,
};
