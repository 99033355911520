import React from 'react'
import { useContext } from 'react'
import { CompetencyContext } from '../../CompetencyPage'
import useSetLocalStorage from './hooks/useSetLocalStorage'

export default function ResponseTextarea({ ...props }) {
  const {
    responseMessage,
    setResponseMessage,
    fetchActionItemResponses,
    actionItemResponses,
    setActionItemResponses,
  } = props

  const { selectedActionItem } = useContext(CompetencyContext)

  let actionItemToUpdate = actionItemResponses.find(
    response => response.edit === true
  )

  const handleUpdate = async () => {
    console.log(actionItemToUpdate, 'actionItemToUpdate')
    fetchActionItemResponses(
      'PUT',
      responseMessage.message,
      actionItemToUpdate.id
    )

    setActionItemResponses(
      actionItemResponses.map(response => {
        if (response.id === actionItemToUpdate.id) {
          return {
            ...response,
            message: responseMessage.message,
            edit: false,
          }
        }
        return response
      })
    )
  }

  const {
    addResponseToLocalStorage,
    removeResponseFromLocalStorage,
  } = useSetLocalStorage({
    actionItemId: selectedActionItem.id,
    responseMessage,
    setResponseMessage,
  })

  return (
    <form className="discuss-container">
      <textarea
        className="first-textarea-message"
        placeholder="Enter your response here."
        value={responseMessage.message}
        onChange={e => {
          setResponseMessage({
            ...responseMessage,
            message: e.target.value,
          })
          addResponseToLocalStorage(e)
        }}
      />
      <button
        className="btn-send-message"
        onClick={e => {
          e.preventDefault()
          if (props.isEditing) {
            handleUpdate()
          } else {
            fetchActionItemResponses('POST').then(() => {
              setResponseMessage({ message: '' })
              removeResponseFromLocalStorage()
            })
          }
        }}
        disabled={responseMessage.message === ''}
      >
        {props.isEditing ? 'Done' : 'Save Response'}
      </button>
    </form>
  )
}
