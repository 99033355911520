import React from 'react';
import { CurrentUserIsCoachOrAdmin } from '../../../../common/roles';
import Icon from '../../../common/components/Icon';

export default function MessageHeader({
  selectedConversation,
  setSelectedConversation,
  createNewConversation,
  editingGroup,
  setEditingGroup,
  editedName,
  setEditedName,
  editGroup,
  user
}) {
  const isCoachOrAdmin = CurrentUserIsCoachOrAdmin();
  return (
    <div
      className="header"
      style={{
        opacity: createNewConversation ? 0 : 1,
        ...(createNewConversation && { height: 0, padding: 0 }),
        alignItems: editingGroup ? 'flex-start' : 'center',
      }}
    >
      <button
        onClick={() => {
          setSelectedConversation(null);
        }}
      >
        <Icon name="chevronLeft" size={10} color="var(--primary-dark)" />
      </button>
      <div className="name-and-status">
        {editingGroup ? (
          <p className="name">Group info</p>
        ) : (
          <>
            {selectedConversation.type === 'p2p' && (
              <p className="name">
                {selectedConversation.user.first_name}{' '}
                {selectedConversation.user.last_name} {
                  selectedConversation.user.username === user.username ? '(You)' : ''
                }
              </p>
            )}
            {selectedConversation.type === 'group' && (
              <p className="name">{selectedConversation.name}</p>
            )}
          </>
        )}

        {editingGroup && (
          <div className="edit-group-info">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns:
                  selectedConversation?.participants &&
                  selectedConversation.participants.length === 1
                    ? '1fr'
                    : '1fr 1fr',
                gridTemplateRows:
                  selectedConversation?.participants &&
                  selectedConversation.participants.length < 3
                    ? '1fr'
                    : '1fr 1fr',
                width: '3.7rem',
                gap: '0.1rem',
                margin: 0,
              }}
            >
              {selectedConversation.participants &&
                selectedConversation.participants
                  .slice(0, 4)
                  .map(({ photo, first_name, last_name }, idx) => (
                    <img
                      key={idx}
                      src={
                        photo
                          ? photo
                          : `https://ui-avatars.com/api/?name=${
                              first_name[0] + last_name[0]
                            }&background=718371&color=fff&size=128`
                      }
                      style={{
                        aspectRatio: 1,
                        width: '100%',
                        height: '100%',
                        borderRadius: '0.3rem',
                      }}
                      alt="profile"
                    />
                  ))}
            </div>

            <div className="group-name-container">
              {editedName ? (
                <input
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                />
              ) : (
                <h1>{selectedConversation.name}</h1>
              )}
              <button
                onClick={() => {
                  if (editedName) {
                    editGroup({
                      participants: [
                        ...selectedConversation.participants.map(
                          (participant) => participant.id,
                        ),
                      ],
                    });
                    setEditedName(null);
                  } else {
                    setEditedName(selectedConversation.name);
                  }
                }}
              >
                <p>{editedName ? 'save' : 'edit name'}</p>
              </button>
            </div>
          </div>
        )}
      </div>

      <button
        onClick={() => {
          if (selectedConversation.type === 'group') {
            setEditingGroup(!editingGroup);
          }
        }}
      >
        {isCoachOrAdmin && (
          <div
            style={{
              opacity: selectedConversation.type === 'p2p' ? 0 : 1,
            }}
          >
            <Icon name="threedots" size={5} color="var(--primary-dark)" />
          </div>
        )}
      </button>
    </div>
  );
}
