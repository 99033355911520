import React from 'react';

export default function StepLineIcon({
  size = 19,
  color = '#343434',
  ...props
}) {
  return (
    <svg
      width={size}
      height={size / 3.8}
      viewBox="0 0 19 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="2.5"
        y1="2.5"
        x2="16.5"
        y2="2.5"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
}
