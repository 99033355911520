import { faCheckCircle, faCommentAlt } from '@fortawesome/pro-light-svg-icons';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { iconColor } from './iconColor';
import clsx from 'clsx';

const Stage = ({
  comp,
  showNonStudentAssessment,
  unseenCompetencies,
  studentId,
  recent = false,
  replaceStringWithSynonyms,
}) => (
  <Card className={recent ? 'recent' : null}>
    <Row className="align-items-center mrm-p-0_5 mrm-pl-1" noGutters>
      <Col className="col-auto d-flex">
        {unseenCompetencies?.indexOf(comp.id) >= 0 && (
          <span className="dot d-block" />
        )}
        <div
          className={clsx(
            'default-stage-thumbnail',
            { 'right-edge': showNonStudentAssessment },
            iconColor(comp),
          )}
        ></div>
      </Col>
      <Col>
        <Card.Body className="mrm-pl-0_5 py-0 pr-0">
          <Card.Title>
            <p>{comp.title}</p>
          </Card.Title>
          <Card.Text as="div">
            {comp.total_action_item_assessments_count > 0 && (
              <span className="action-items">
                <FontAwesomeIcon icon={faCheckCircle} size="xs" />
                <span>
                  {`${comp.done_action_item_assessments_count}/${comp.total_action_item_assessments_count}`}
                </span>
              </span>
            )}

            {comp.attachments.length > 0 && (
              <span className="attachments">
                <FontAwesomeIcon icon={faPaperclip} size="xs" />
                <span>{comp.attachments.length}</span>
              </span>
            )}
            {comp.hidden_for && comp.hidden_for.includes(studentId) && (
              <span className="hidden-student">
                {replaceStringWithSynonyms('Hidden for student')}
              </span>
            )}
            {comp.hidden_for_all_users && (
              <span className="hidden-student">
                {replaceStringWithSynonyms('Hidden for all students')}
              </span>
            )}
            {comp.user_defined && (
              <Badge variant="light">
                {replaceStringWithSynonyms('Student specific')}
              </Badge>
            )}
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>
);

export default Stage;
