import React from 'react';

export default function PaypalIcon({ size = 35 }) {
  return (
    <svg
      width={size}
      height={size / 0.68}
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="34"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90001 15.241H8.34439C8.23794 15.241 8.1474 15.3183 8.1308 15.4234L7.50163 19.4125C7.48913 19.4912 7.55009 19.5621 7.62993 19.5621H8.37259C8.47905 19.5621 8.56958 19.4848 8.58618 19.3795L8.75588 18.3036C8.77225 18.1982 8.863 18.121 8.96924 18.121H9.46169C10.4864 18.121 11.0778 17.6251 11.2323 16.6424C11.3019 16.2125 11.2353 15.8748 11.0339 15.6381C10.8129 15.3784 10.4206 15.241 9.90001 15.241ZM10.0795 16.6979C9.99443 17.2561 9.56792 17.2561 9.15553 17.2561H8.92078L9.08547 16.2136C9.09525 16.1507 9.14984 16.1042 9.21353 16.1042H9.32112C9.60204 16.1042 9.86706 16.1042 10.004 16.2643C10.0856 16.3599 10.1106 16.5018 10.0795 16.6979ZM14.5501 16.68H13.8052C13.7417 16.68 13.6869 16.7264 13.6771 16.7895L13.6441 16.9978L13.592 16.9223C13.4308 16.6882 13.0712 16.61 12.7122 16.61C11.889 16.61 11.1859 17.2335 11.049 18.1081C10.9778 18.5443 11.079 18.9615 11.3264 19.2524C11.5535 19.5199 11.8783 19.6314 12.2647 19.6314C12.928 19.6314 13.2959 19.2049 13.2959 19.2049L13.2626 19.4119C13.2501 19.4911 13.3111 19.562 13.3904 19.562H14.0615C14.1682 19.562 14.2582 19.4847 14.2751 19.3794L14.6777 16.8297C14.6904 16.7513 14.6297 16.68 14.5501 16.68ZM13.5117 18.1299C13.4398 18.5555 13.102 18.8412 12.6713 18.8412C12.455 18.8412 12.282 18.7718 12.1711 18.6403C12.061 18.5098 12.0191 18.3239 12.0541 18.117C12.1213 17.695 12.4647 17.4 12.889 17.4C13.1004 17.4 13.2724 17.4702 13.3857 17.6029C13.4992 17.7369 13.5442 17.9238 13.5117 18.1299ZM17.769 16.6799H18.5176C18.6224 16.6799 18.6836 16.7975 18.624 16.8835L16.1342 20.4774C16.0939 20.5356 16.0275 20.5702 15.9565 20.5702H15.2089C15.1036 20.5702 15.0421 20.4517 15.1031 20.3655L15.8783 19.2712L15.0538 16.8514C15.0253 16.7673 15.0874 16.6799 15.1769 16.6799H15.9124C16.008 16.6799 16.0923 16.7427 16.1199 16.8341L16.5575 18.2956L17.59 16.7748C17.6304 16.7154 17.6976 16.6799 17.769 16.6799Z"
        fill="#253B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8856 19.4124L26.5241 15.3504C26.5338 15.2874 26.5885 15.2409 26.652 15.2407H27.3707C27.4501 15.2407 27.511 15.3119 27.4985 15.3906L26.8689 19.3795C26.8525 19.4847 26.762 19.5621 26.6553 19.5621H26.0134C25.9341 19.5621 25.8731 19.4912 25.8856 19.4124ZM20.9963 15.2409H19.4404C19.3341 15.2409 19.2436 15.3183 19.227 15.4234L18.5978 19.4124C18.5853 19.4912 18.6463 19.5621 18.7257 19.5621H19.524C19.5983 19.5621 19.6617 19.508 19.6733 19.4343L19.8518 18.3036C19.8683 18.1982 19.959 18.1209 20.0652 18.1209H20.5574C21.5824 18.1209 22.1736 17.625 22.3283 16.6424C22.398 16.2125 22.331 15.8747 22.1297 15.6381C21.9088 15.3784 21.5169 15.2409 20.9963 15.2409ZM21.1757 16.6979C21.0909 17.2561 20.6644 17.2561 20.2517 17.2561H20.0172L20.1821 16.2136C20.1919 16.1506 20.246 16.1042 20.3099 16.1042H20.4175C20.6983 16.1042 20.9635 16.1042 21.1004 16.2643C21.182 16.3599 21.2069 16.5018 21.1757 16.6979ZM25.646 16.68H24.9015C24.8375 16.68 24.7832 16.7264 24.7736 16.7895L24.7407 16.9978L24.6884 16.9223C24.5271 16.6882 24.1677 16.61 23.8088 16.61C22.9855 16.61 22.2827 17.2335 22.1457 18.1081C22.0748 18.5443 22.1755 18.9615 22.423 19.2524C22.6505 19.5199 22.9749 19.6314 23.3613 19.6314C24.0246 19.6314 24.3924 19.2048 24.3924 19.2048L24.3592 19.4119C24.3467 19.491 24.4076 19.562 24.4875 19.562H25.1583C25.2645 19.562 25.355 19.4846 25.3716 19.3794L25.7745 16.8297C25.7868 16.7513 25.7258 16.68 25.646 16.68ZM24.6076 18.1299C24.5362 18.5555 24.1979 18.8412 23.7671 18.8412C23.5512 18.8412 23.3779 18.7718 23.2669 18.6403C23.1568 18.5098 23.1154 18.3239 23.15 18.1169C23.2175 17.6949 23.5606 17.4 23.9848 17.4C24.1964 17.4 24.3683 17.4702 24.4816 17.6028C24.5955 17.7368 24.6406 17.9238 24.6076 18.1299Z"
        fill="#179BD7"
      />
      <path
        d="M15.6571 13.8139L15.8484 12.5991L15.4224 12.5892H13.3882L14.8019 3.62541C14.8062 3.59834 14.8205 3.57311 14.8413 3.55519C14.8622 3.53728 14.8889 3.5274 14.9167 3.5274H18.3467C19.4855 3.5274 20.2713 3.76436 20.6816 4.23206C20.8739 4.45147 20.9964 4.68074 21.0557 4.93306C21.1178 5.19782 21.1189 5.51413 21.0582 5.89992L21.0538 5.92807V6.17526L21.2462 6.28424C21.4082 6.37017 21.5368 6.46854 21.6356 6.58117C21.8002 6.76876 21.9066 7.00718 21.9516 7.28985C21.998 7.58056 21.9826 7.92649 21.9066 8.31813C21.8188 8.76865 21.6769 9.16102 21.4854 9.48208C21.309 9.77791 21.0845 10.0232 20.818 10.2134C20.5635 10.3941 20.261 10.5312 19.9191 10.619C19.5878 10.7052 19.2101 10.7488 18.7958 10.7488H18.5288C18.338 10.7488 18.1525 10.8175 18.007 10.9408C17.8611 11.0666 17.7645 11.2384 17.7349 11.4264L17.7148 11.5358L17.3769 13.6767L17.3616 13.7553C17.3576 13.7803 17.3506 13.7927 17.3404 13.8011C17.3312 13.8087 17.3181 13.8139 17.3052 13.8139H15.6571Z"
        fill="#253B80"
      />
      <path
        d="M21.4284 5.95673C21.4182 6.02218 21.4065 6.08909 21.3933 6.15784C20.9409 8.48026 19.3934 9.28255 17.4169 9.28255H16.4106C16.1689 9.28255 15.9652 9.45807 15.9275 9.69649L15.4123 12.9642L15.2664 13.8904C15.2418 14.047 15.3626 14.1881 15.5205 14.1881H17.3054C17.5168 14.1881 17.6963 14.0345 17.7295 13.8261L17.7471 13.7354L18.0832 11.6027L18.1048 11.4858C18.1376 11.2766 18.3175 11.1231 18.5289 11.1231H18.7958C20.5251 11.1231 21.8789 10.4209 22.2746 8.3892C22.4398 7.54047 22.3543 6.83179 21.9169 6.33337C21.7846 6.18307 21.6204 6.05838 21.4284 5.95673Z"
        fill="#179BD7"
      />
      <path
        d="M20.9549 5.76819C20.8859 5.74808 20.8145 5.72979 20.7414 5.71334C20.6679 5.69726 20.5925 5.68299 20.515 5.67056C20.2437 5.62668 19.9464 5.60583 19.6279 5.60583H16.9395C16.8732 5.60583 16.8104 5.62082 16.7541 5.64789C16.6301 5.70749 16.538 5.82488 16.5157 5.96858L15.9437 9.59098L15.9272 9.69665C15.9649 9.45823 16.1686 9.28271 16.4103 9.28271H17.4166C19.3931 9.28271 20.9407 8.48005 21.393 6.158C21.4065 6.08926 21.4179 6.02234 21.4281 5.95689C21.3137 5.89618 21.1897 5.84426 21.0562 5.8C21.0234 5.78904 20.9894 5.77844 20.9549 5.76819Z"
        fill="#222D65"
      />
      <path
        d="M16.5156 5.96848C16.5379 5.82477 16.6301 5.70739 16.754 5.64814C16.8107 5.62108 16.8733 5.60609 16.9394 5.60609H19.6279C19.9464 5.60609 20.2437 5.62693 20.5149 5.67082C20.5925 5.68325 20.6679 5.69751 20.7414 5.7136C20.8145 5.73006 20.8858 5.74834 20.9549 5.76845C20.9893 5.77869 21.0233 5.78929 21.0565 5.7999C21.19 5.84414 21.314 5.89644 21.4284 5.95678C21.5631 5.09853 21.4274 4.51418 20.9634 3.98504C20.4518 3.40253 19.5284 3.15314 18.3469 3.15314H14.9168C14.6755 3.15314 14.4697 3.32865 14.4323 3.56744L13.0037 12.6234C12.9755 12.8026 13.1137 12.9643 13.2943 12.9643H15.412L15.9437 9.59087L16.5156 5.96848Z"
        fill="#253B80"
      />
    </svg>
  );
}
