import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_GET_ALERTS_BEGIN,
  RPM_GET_ALERTS_SUCCESS,
  RPM_GET_ALERTS_FAILURE,
  RPM_GET_ALERTS_DISMISS_ERROR,
} from './constants';
import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function getAlerts(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_GET_ALERTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(`${config.apiRootUrl}/rpm/alerts/`, {
        params: args,
        ...createAxiosConfigWithAuth(getState()),
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_GET_ALERTS_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_GET_ALERTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAlertsError() {
  return {
    type: RPM_GET_ALERTS_DISMISS_ERROR,
  };
}

export function useGetAlerts() {
  const dispatch = useDispatch();

  const { getAlertsPending, getAlertsError, alerts } = useSelector(
    state => ({
      getAlertsPending: state.rpm.getAlertsPending,
      getAlertsError: state.rpm.getAlertsError,
      alerts: state.rpm.alerts,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getAlerts(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetAlertsError());
  }, [dispatch]);

  return {
    alerts,
    getAlerts: boundAction,
    getAlertsPending,
    getAlertsError,
    dismissGetAlertsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_GET_ALERTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getAlertsPending: true,
        getAlertsError: null,
      };

    case RPM_GET_ALERTS_SUCCESS:
      // The request is success
      return {
        ...state,
        alerts: action.data,
        getAlertsPending: false,
        getAlertsError: null,
      };

    case RPM_GET_ALERTS_FAILURE:
      // The request is failed
      return {
        ...state,
        getAlertsPending: false,
        getAlertsError: action.data.error,
      };

    case RPM_GET_ALERTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getAlertsError: null,
      };

    default:
      return state;
  }
}
