// import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Icon from '../common/components/Icon'
import { useFetchAuthToken } from '../home/redux/fetchAuthToken'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'

export default function Login() {
    const { getSettingsByName, settings: data } = useGetSettingsByName()
    const { defaultLanguage } = useSetDefaultLanguage()

    const history = useHistory()
    const location = useLocation()

    const { company: name, type } = useParams()

    useEffect(() => {
        getSettingsByName({ name, type })
    }, [name])

    const { fetchAuthToken, authToken } = useFetchAuthToken()

    React.useEffect(() => {
        if (authToken) {
            history.push('/')
        }
    }, [authToken])

    const [error, setError] = React.useState('')

    const handleSubmit = async values => {
        fetchAuthToken(values).catch(error => {
            if (error.response) {
                const errorKey = Object.keys(error.response.data)[0]
                setError(error.response.data[errorKey])
            }
        })
    }

    if (!data) return null

    const LoginSchema = () =>
        Yup.object().shape({
            email: Yup.string()
                .email(
                    data.pages[defaultLanguage].login.input_alerts.invalid_email
                )
                .required(
                    data.pages[defaultLanguage].login.input_alerts
                        .email_required
                ),
            password: Yup.string()
                .min(
                    8,
                    data.pages[defaultLanguage].login.input_alerts
                        .password_length
                )
                .required(
                    data.pages[defaultLanguage].login.input_alerts
                        .password_required
                ),
        })

    const loginSchema = LoginSchema()

    if (!loginSchema) return null

    return (
        <div className="auth-login">
            <div className="auth-login-navbar">
                <Link
                    to={
                        (location.pathname.split('/').includes('v2')
                            ? `/auth/v2/${name}/${type}`
                            : `/auth/${name}/${type}`) +
                        '?lang=' +
                        defaultLanguage
                    }
                >
                    <img src={data.globals.logo} alt="logo" />
                </Link>
            </div>
            <div className="auth-login-content">
                {data.pages[defaultLanguage].login.media.background_image && (
                    <img
                        src={
                            data.pages[defaultLanguage].login.media
                                .background_image
                        }
                        alt="background"
                        className="background-image"
                    />
                )}

                {data.pages[defaultLanguage].login.media.background_video && (
                    <video autoPlay muted loop className="video-background">
                        <source
                            src={
                                data.pages[defaultLanguage].login.media
                                    .background_video
                            }
                            type="video/mp4"
                        />
                    </video>
                )}

                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values)
                        setSubmitting(false)
                    }}
                >
                    {props => (
                        <Form>
                            <div className="auth-login-form">
                                <div className="auth-login-form-header">
                                    <h1>
                                        {
                                            data.pages[defaultLanguage].login
                                                .content.welcome_message
                                        }
                                    </h1>
                                </div>
                                <div className="auth-login-form-body">
                                    <div className="auth-login-form-body-input">
                                        <div className="auth-login-input-wrapper">
                                            <Icon
                                                name="email"
                                                size={18}
                                                color="#6D786E"
                                            />
                                            <Field
                                                name="email"
                                                type="email"
                                                placeholder={
                                                    data.pages[defaultLanguage]
                                                        .login
                                                        .input_placeholders
                                                        .email + ' *'
                                                }
                                            />
                                        </div>
                                        {props.errors.email && (
                                            <p className="error-message">
                                                <ErrorMessage name="email" />
                                            </p>
                                        )}
                                    </div>
                                    <div className="auth-login-form-body-input">
                                        <div className="auth-login-input-wrapper">
                                            <Icon
                                                name="lock"
                                                size={25}
                                                color="#6D786E"
                                                className="lock"
                                            />
                                            <Field
                                                name="password"
                                                type="password"
                                                placeholder={
                                                    data.pages[defaultLanguage]
                                                        .login
                                                        .input_placeholders
                                                        .password + ' *'
                                                }
                                            />
                                        </div>
                                        {props.errors.password && (
                                            <p className="error-message">
                                                <ErrorMessage name="password" />
                                            </p>
                                        )}
                                    </div>{' '}
                                    {error && (
                                        <p className="error-message">{error}</p>
                                    )}
                                    <button
                                        type="submit"
                                        style={{
                                            backgroundColor:
                                                data.globals.colors.primary,
                                        }}
                                    >
                                        {
                                            data.pages[defaultLanguage].login
                                                .content.login_button
                                        }
                                    </button>
                                    <Link
                                        to={
                                            (location.pathname
                                                .split('/')
                                                .includes('v2')
                                                ? `/auth/v2/${name}/forgot-password/${type}`
                                                : `/auth/${name}/forgot-password/${type}`) +
                                            '?lang=' +
                                            defaultLanguage
                                        }
                                    >
                                        <button className="forgot-password">
                                            {
                                                data.pages[defaultLanguage]
                                                    .login.content
                                                    .forgot_password
                                            }
                                        </button>
                                    </Link>
                                </div>
                            </div>{' '}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

Login.propTypes = {}
Login.defaultProps = {}
