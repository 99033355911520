import React from 'react'
import Icon from '../../../common/components/Icon'
import { useOpenPeerConversation } from '../../../common/hooks/useOpenConversation'
import AssignRoadmapModal from '../../../dashboard/components/AssignRoadmapModal'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../../../user/redux/fetchUser'
import ProfileBackground from '../../assets/client-profile/profile-background.png'
import { useGetProfileSummary } from '../../redux/getProfileSummary'
import Schedule from './Schedule'
import SidebarRoadmaps from './SidebarRoadmaps'
import AddCoachModal from './AddCoachModal'

export default function Sidebar({ user, fetchUser }) {
  const [showAddRoadmapModal, setShowAddRoadmapModal] = React.useState(false)
  const [showAddCoachModal, setShowAddCoachModal] = React.useState(false)

  const { profileSummary } = useGetProfileSummary()
  const { openPeerConversation } = useOpenPeerConversation()

  const { user: loggedCoach } = useFetchUser()

  const { t } = useTranslation()

  const isOnlyUser = user.groups.length === 1 && user.groups[0] === 'User'

  return (
    <div className="rpm-client-profile__container__left">
      <AssignRoadmapModal
        show={showAddRoadmapModal}
        handleClose={() => {
          fetchUser().then(() => {
            setShowAddRoadmapModal(false)
          })
        }}
        client={user.id}
      />
      <AddCoachModal
        show={showAddCoachModal}
        onHide={() => setShowAddCoachModal(false)}
        user={user}
        fetchUser={fetchUser}
      />
      <div className="rpm-profile-info-container">
        <div className="rpm-profile-info-container__picture-header">
          <img src={ProfileBackground} alt="profile" className="background" />
          <div className="rpm-profile-info-container__picture-header__picture">
            <img
              src={
                user.photo
                  ? user.photo
                  : `https://ui-avatars.com/api/?name=${user.first_name[0] +
                      user.last_name[0]}&background=718371&color=fff&size=128`
              }
              alt="profile"
              className="rpm-profile-info-container__picture-header__picture__image"
            />
            {profileSummary && profileSummary.status && (
              <div
                className={`rpm-profile-info-container__picture-header__picture__status ${profileSummary.status.toLowerCase()}`}
              >
                <div className="rpm-profile-info-container__picture-header__picture__status__dot dot" />
                <p className="rpm-profile-info-container__picture-header__picture__status__text text">
                  {t(`client_profile.${profileSummary.status.toLowerCase()}`)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="rpm-profile-info-container__info">
          <h1>{`${user.first_name} ${user.last_name}`}</h1>
          {user.assigned_gender && <p>{user.assigned_gender}</p>}
          {user.date_of_birth && (
            <p>
              {/** calculate how many years old from this format 2000-06-20T21:34:52.518464-06:00 */}
              {`${new Date().getFullYear() -
                new Date(user.date_of_birth).getFullYear()} years old`}
            </p>
          )}
          <p>
            <span>
              {user.phone_number
                ? user.phone_number
                : t('client_profile.no_phone_number')}
            </span>
          </p>
          <p>
            <span>Diagnosis: </span> {user.icd_code || '-----'}
          </p>
          <div className="rpm-profile-info-container__info__buttons">
            <button
              className="rpm-profile-info-container__info__buttons__button dark"
              onClick={() => {
                openPeerConversation({
                  first_name: user.first_name,
                  last_name: user.last_name,
                  id: user.id,
                  photo: user.photo,
                  username: user.username,
                })
              }}
            >
              {t('client_profile.message')}
            </button>
            {/* <button className="rpm-profile-info-container__info__buttons__button">
                            Health Report
                        </button> */}
          </div>
          <hr
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: '#BEBDBD',
              marginTop: '2rem',
              marginBottom: '.5rem',
            }}
          />
          {user && isOnlyUser && <Schedule userId={user.id} user={user} />}
          <div className="rpm-profile-info-container__info__add_header">
            <p>{t('client_profile.care_team')} </p>

            {loggedCoach &&
              (loggedCoach.groups.includes('Coach') ||
                loggedCoach.groups.includes('Admin')) && (
                <button
                  className="rpm-profile-info-container__info__add_header__button"
                  onClick={() => {
                    setShowAddCoachModal(true)
                  }}
                >
                  <Icon name="plus" color={'#ffffff'} size={10} />
                </button>
              )}
          </div>
          <div className="rpm-profile-info-container__info__care-team">
            {user.coach &&
              user.coach.length > 0 &&
              user.coach.map((coach, index) => (
                <div
                  className="rpm-profile-info-container__info__care-team__member"
                  key={index}
                >
                  <div className="rpm-profile-info-container__info__care-team__member__name-container">
                    <div className="rpm-profile-info-container__info__care-team__member__name-container__picture">
                      <img
                        src={
                          coach.photo
                            ? coach.photo
                            : `https://ui-avatars.com/api/?name=${coach
                                .first_name[0] +
                                coach
                                  .last_name[0]}&background=343434&color=fff&size=128`
                        }
                        alt="profile"
                      />
                    </div>
                    <div className="rpm-profile-info-container__info__care-team__member__name-container__name">
                      <p>
                        {`${coach.first_name} ${coach.last_name}`}{' '}
                        {coach.id === user.id && `(${t('client_profile.you')})`}
                      </p>
                      <p className="subtitle">{`${coach.alias}`}</p>
                    </div>
                  </div>
                  {loggedCoach && loggedCoach.id !== coach.id && (
                    <button
                      className="chat-btn"
                      onClick={() => {
                        // open conversation with user in a new tab
                        const conversation = JSON.stringify({
                          id: coach.id,
                          timestamp: Date.now(),
                          type: 'p2p',
                          user: {
                            id: coach.id,
                            first_name: coach.first_name,
                            last_name: coach.last_name,
                            photo: coach.photo,
                            username: coach.username,
                          },
                        })

                        // encode the conversation object
                        const encodedConversation = encodeURIComponent(
                          conversation
                        )

                        window.open(
                          `/messages/default/?conversation=${encodedConversation}`,
                          '_blank'
                        )
                      }}
                    >
                      <Icon name="message" color={'#6D786E'} size={15} />
                    </button>
                  )}
                </div>
              ))}
          </div>
          <div className="rpm-profile-info-container__info__add_header">
            <p>{t('client_profile.current_roadmap')} </p>
            <button
              className="rpm-profile-info-container__info__add_header__button"
              onClick={() => {
                setShowAddRoadmapModal(true)
              }}
            >
              <Icon name="plus" color={'#ffffff'} size={10} />
            </button>
          </div>
          <SidebarRoadmaps user={user} fetchUser={fetchUser} />
        </div>
      </div>
    </div>
  )
}
