import React from 'react';
import Icon from '../../../../common/components/Icon';
import useWindowSize from '../../../../common/useWindowResize';
export default function Footer() {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  return (
    <div className="dashboard-records-footer">
      <p className="last-synced">Last synced: 09/21/22</p>
      <div className="sync-now">
        <Icon name="sync" color={isMobile ? '#ffffff' : '#908E87'} />
        <p>Sync Now</p>
      </div>
    </div>
  );
}
