import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import xor from 'lodash/xor';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Switch from 'react-switch';
import { CustomDialog, DesktopHeader, Loader, UserAvatar } from '../common';
import Icon from '../common/components/Icon';
import useEffectiveBackLink from '../common/useEffectiveBackLinkHook';
import {
  useFetchAssignedUsers,
  useFetchRoadmaps,
} from '../dashboard/redux/hooks';
import UserAvatarEditor from '../user/components/UserAvatarEditor';
import { useFetchUser } from '../user/redux/hooks';
import { Fields } from './components/editUserProfilePage/fields';
import { multiSelectStyles } from './components/editUserProfilePage/multiSelectStyles';
import { schema } from './components/editUserProfilePage/schema';
import {
  useAddUser,
  useBulkAssignUserRoadmaps,
  useDeleteUser,
  useFetchCohorts,
  useSendWelcomeEmail,
  useUpdateUser,
  useUpdateUserAvatar,
} from './redux/hooks';

const IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

function EditUserProfileComponent({
  userId,
  setSaveButtonProps,
  setUser,
  onSuccessfulSave,
  saveButton,
}) {
  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const [photo, setPhoto] = useState(null);

  const [photoFileName, setPhotoFileName] = useState(null);

  const { user, replaceStringWithSynonyms } = useFetchUser();

  const {
    assignedUsers,
    assignedCoaches,
    fetchAssignedUsersPending,
    fetchAssignedUsers,
  } = useFetchAssignedUsers();

  const { fetchCohorts, cohorts } = useFetchCohorts();

  const { addUser, addUserPending } = useAddUser();

  const { roadmaps, fetchRoadmaps } = useFetchRoadmaps();

  const { deleteUser, deleteUserPending } = useDeleteUser();

  const { updateUserAvatar, updateUserAvatarPending } = useUpdateUserAvatar();

  const { updateUser, updateUserPending } = useUpdateUser();

  const { bulkAssignUserRoadmaps } = useBulkAssignUserRoadmaps();

  const { sendWelcomeEmail, sendWelcomeEmailPending } = useSendWelcomeEmail();

  const { register, handleSubmit, control, errors, setError, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return actualUser;
    }, [actualUser]),
  });

  const [actualUser, setActualUser] = useState(null);

  useEffect(() => {
    if (userId) {
      fetchAssignedUsers({ userId });
    }
    fetchAssignedUsers({ type: 'Coach' });
  }, [fetchAssignedUsers, userId, history]);

  useEffect(() => {
    if (assignedUsers) {
      setActualUser(assignedUsers.results.find((u) => u.id === userId));
    }
  }, [assignedUsers, userId]);

  useEffect(() => {
    reset(actualUser);
  }, [actualUser]);

  const [photoFile, setPhotoFile] = useState(null);

  const multiselectList = {
    cohort: cohorts
      ? cohorts.results.map((item) => ({ id: item.id, text: item.name }))
      : null,
    coach: assignedCoaches
      ? assignedCoaches.results.map((item) => ({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
        }))
      : null,
    roadmaps_info: roadmaps
      ? roadmaps.results.filter((r) => r.is_published === true)
      : null,
  };

  useEffect(() => {
    fetchCohorts();
  }, [fetchCohorts]);

  useEffect(() => {
    fetchRoadmaps();
  }, [fetchRoadmaps]);

  const effectiveBackLink = useEffectiveBackLink(
    userId ? `/manage/user/${userId}` : '/manage/accounts',
  );
  const redirectBack = useCallback(() => {
    history.push(effectiveBackLink);
  }, [history, effectiveBackLink]);

  const handleError = useCallback(
    (err) =>
      Object.keys(err).forEach((key) => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
        }
      }),
    [setError],
  );

  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const stateRef = useRef(selectedCoaches);

  useEffect(() => {
    stateRef.current = selectedCoaches;
  }, [selectedCoaches]);

  const handleSaveClick = useCallback(
    (values) => {
      if (userId) {
        const data = {
          ...values,
          coach_write_only: stateRef.current
            ? stateRef.current.map((i) => i.id)
            : [],
          cohort: values.cohort ? values.cohort.map((i) => i.id) : [],
        };
        Promise.all([
          updateUser({ userId, data }),
          bulkAssignUserRoadmaps({
            userId,
            ids: values.roadmaps_info
              ? values.roadmaps_info.map((i) => i.id)
              : [],
          }),
        ])
          /*     .then(() =>
            (typeof onSuccessfulSave === 'function'
              ? onSuccessfulSave
              : redirectBack)(),
          ) */
          .catch((e) => handleError(e.response.data));
      } else {
        const data = new FormData();

        Object.keys(values).forEach((key) => {
          if (['cohort', 'roadmaps_info'].includes(key)) {
            if (values[key].length > 0) {
              values[key].map((i) => data.append(key, i.id));
            }
          } else if (key === 'photo') {
            !!photoFile && data.append('photo', photoFile);
          } else if (key === 'coach') {
            if (values.coach.length > 0) {
              values[key].map((i) => data.append('coach_write_only', i.id));
            }
          } else if (key === 'groups') {
            if (values.groups.length > 0) {
              values[key].map((i) => data.append('groups', i));
            }
          } else {
            data.append(key, values[key]);
          }
          if (stateRef.current.length > 0) {
            data.append(
              'coach_write_only',
              stateRef.current.map((i) => i.id),
            );
          }
        });
        let newUserId;
        addUser(data)
          .then((res) => {
            newUserId = res.id;
            if (values.roadmaps_info) {
              return bulkAssignUserRoadmaps({
                userId: res.id,
                ids: values.roadmaps_info.map((i) => i.id),
              });
            } else {
              return true;
            }
          })
          .then(() => {
            if (typeof onSuccessfulSave === 'function') {
              onSuccessfulSave();
            } else {
              // history.push(`/manage/user/${newUserId}`);
            }
          })
          .catch((e) => handleError(e.response.data));
      }
    },
    [
      userId,
      updateUser,
      bulkAssignUserRoadmaps,
      addUser,
      onSuccessfulSave,
      handleError,
      redirectBack,
      history,
      photoFile,
    ],
  );

  useEffect(
    () =>
      setSaveButtonProps({
        disabled: addUserPending || updateUserPending,
        onClick: handleSubmit(handleSaveClick),
      }),
    [
      setSaveButtonProps,
      addUserPending,
      updateUserPending,
      handleSubmit,
      handleSaveClick,
    ],
  );

  useEffect(() => {
    if (typeof setUser === 'function') setUser(actualUser);
  }, [setUser, actualUser]);

  const handleSendWelcomeEmailClick = useCallback(() => {
    sendWelcomeEmail({ userId }).then(() =>
      alert('Welcome email has been sent'),
    );
  }, [sendWelcomeEmail, userId]);

  const handleDeleteAccountClick = useCallback(() => setDeleteModal(true), []);

  const handleDeleteDialogHide = useCallback(() => setDeleteModal(false), []);

  const handleDeleteAccount = useCallback(() => {
    deleteUser({ userId })
      .then(() => {
        history.push(effectiveBackLink);
      })
      .catch((e) => handleError(e.response.data));
    setDeleteModal(false);
  }, [deleteUser, userId, history, handleError, effectiveBackLink]);

  const handleAvatarUpdate = useCallback(
    (data) => updateUserAvatar({ userId, data }).then(redirectBack),
    [userId, updateUserAvatar, redirectBack],
  );

  const handleAvatarRemove = useCallback(
    () => updateUserAvatar({ userId, data: null }).then(redirectBack),
    [userId, updateUserAvatar, redirectBack],
  );

  const handleNewUserAvatarChange = useCallback((e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    e.preventDefault();
    setPhotoFileName(file.name);

    if (IMAGE_FORMATS.includes(file.type)) {
      setPhotoFile(file);
      reader.onloadend = () => setPhoto(reader.result);
      reader.readAsDataURL(file);
    } else {
      setPhotoFile(null);
      setPhoto(false);
    }

    e.target.value = null;
  }, []);

  const coachAndNotAdmin =
    user &&
    user.groups &&
    user.groups.includes('Coach') &&
    !user.groups.includes('Admin');
  const userLastlogin = actualUser?.last_seen;

  let fields = Fields({
    coachAndNotAdmin,
    replaceStringWithSynonyms,
    userLastlogin,
  });

  useEffect(() => {
    if (actualUser) {
      setSelectedCoaches(
        actualUser.coach.map((user) => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
        })),
      );
    }
  }, [actualUser]);

  const handleChange = (inputValue) => {
    setSelectedCoaches(inputValue);
  };

  const loadOptions = (inputValue) => {
    return fetchAssignedUsers({
      type: 'Coach',
      search: inputValue,
    })
      .then((res) => {
        console.log(res);
        return res.results.map((user) => ({
          value: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
        }));
      })
      .catch((e) => handleError(e.response.data));
  };

  if (userId && !user) {
    return <Loader delay />;
  }

  return (
    <div className="desktop-page-container">
      <div className="edit-profile-container">
        <h1 className="d-none d-lg-block">{userId ? 'Edit' : 'Add'} account</h1>
        {userId && !coachAndNotAdmin && (
          <UserAvatarEditor
            user={actualUser}
            onUpdate={handleAvatarUpdate}
            onRemove={handleAvatarRemove}
            requesting={updateUserAvatarPending}
          />
        )}
        {userId && coachAndNotAdmin && (
          <UserAvatar
            user={actualUser}
            size="lg"
            className="mrm-mt-1 mx-auto"
          />
        )}
        <div className="mrm-px-1 mrm-mt-1 mrm-mb-1">
          {((actualUser && userId) || !userId) &&
            fields.map(
              ({ type, name, label, labelKey, display, disabled, icon }) => (
                <Form.Group
                  controlId={name}
                  key={name}
                  className={clsx(
                    { 'd-none': display === 'none' },
                    { 'mrm-mb-1 position-relative': type === 'file' },
                  )}
                >
                  {icon && (
                    <Icon
                      className={clsx(
                        'icon',
                        { user: icon === 'user' },
                        { email: icon === 'email' },
                        { phone: icon === 'phone' },
                      )}
                      size={16}
                      color={'#6D786E'}
                      name={icon}
                    />
                  )}
                  {type === 'string' ? (
                    <Form.Control
                      name={name}
                      defaultValue={
                        userId && actualUser ? actualUser[name] : ''
                      }
                      isInvalid={errors[name]}
                      ref={register}
                      disabled={disabled}
                      placeholder={label}
                    />
                  ) : type === 'text' ? (
                    <Form.Control
                      name={name}
                      as="textarea"
                      defaultValue={
                        userId && actualUser ? actualUser[name] : ''
                      }
                      isInvalid={errors[name]}
                      ref={register}
                      placeholder={label}
                      className="text-area-edit-profile"
                    />
                  ) : type === 'file' ? (
                    !userId && (
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Button variant="gray">
                            Choose Photo
                            <Form.Control
                              name={name}
                              type="file"
                              accept="image/*"
                              className="d-none"
                              onChange={handleNewUserAvatarChange}
                              ref={register}
                            />
                          </Button>
                          {photoFileName && (
                            <div className="mrm-ml-0_75">
                              <small>{photoFileName}</small>
                            </div>
                          )}
                        </div>
                        {photo ? (
                          <img
                            src={photo}
                            alt="user-avatar"
                            className="account-photo"
                          />
                        ) : (
                          photo === false && (
                            <div
                              className={clsx('invalid-avatar', {
                                'is-invalid': errors[name],
                              })}
                            >
                              Invalid file format
                            </div>
                          )
                        )}
                      </div>
                    )
                  ) : (
                    <Controller
                      name={name}
                      control={control}
                      defaultValue={actualUser ? actualUser[name] : []}
                      render={({ onChange, value }) =>
                        type === 'checkbox' ? (
                          <div className="checkbox-grid">
                            {['User', 'Coach', 'Admin'].map((group) => (
                              <Form.Check
                                type="checkbox"
                                key={group}
                                id={group}
                                label={replaceStringWithSynonyms(group)}
                                isInvalid={errors[name]}
                                checked={value.includes(group)}
                                onChange={() => onChange(xor(value, [group]))}
                              />
                            ))}
                          </div>
                        ) : type === 'multiselect' ? (
                          <Select
                            isMulti
                            name={name}
                            value={value}
                            options={multiselectList[name]}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                              typeof labelKey === 'function'
                                ? labelKey(option)
                                : option[labelKey]
                            }
                            components={{
                              IndicatorSeparator: null,
                              ClearIndicator: null,
                            }}
                            onChange={onChange}
                            styles={multiSelectStyles}
                          />
                        ) : type === 'async-multiselect' ? (
                          <AsyncSelect
                            isMulti
                            name={name}
                            value={selectedCoaches}
                            loadOptions={loadOptions}
                            getOptionValue={(option) => option.id}
                            defaultOptions
                            styles={multiSelectStyles}
                            onChange={handleChange}
                          />
                        ) : (
                          type === 'switch' && (
                            <div
                              style={{
                                padding: '0 1.5rem',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.8rem',
                              }}
                            >
                              <Switch
                                onChange={onChange}
                                checked={!!value}
                                onColor="#343434"
                              />
                              <p>{label}</p>
                            </div>
                          )
                        )
                      }
                    />
                  )}

                  {errors[name] && errors[name].type === 'remote' && (
                    <small className="error-message">
                      {errors[name].message}
                    </small>
                  )}
                </Form.Group>
              ),
            )}
        </div>
        <div className="save-cancel-grid">
          <Link to={effectiveBackLink}>
            <button className="btn-cancel-edit">Cancel</button>
          </Link>
          {saveButton}
        </div>
        <div className="resend-delete-grid">
          {userId && (
            <button
              className="action-button"
              onClick={handleSendWelcomeEmailClick}
              disabled={sendWelcomeEmailPending}
            >
              {sendWelcomeEmailPending && (
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  className="mrm-mr-0_25"
                  size="xs"
                  spin
                />
              )}
              Resend Email
            </button>
          )}
          {userId && (
            <button
              className="action-button"
              onClick={handleDeleteAccountClick}
              disabled={deleteUserPending}
            >
              Delete Account
            </button>
          )}
          <CustomDialog
            text={{
              caption:
                'Deleting an account is permanent. There is no way to undo this.',
              yes: 'Delete Account',
            }}
            show={deleteModal}
            onHide={handleDeleteDialogHide}
            onYes={handleDeleteAccount}
          />
        </div>
      </div>
    </div>
  );
}

export function EditUserProfileModal({ show, onHide, userId }) {
  const [saveButtonProps, setSaveButtonProps] = useState({});

  const onSuccessfulSave = useCallback(() => onHide(true), [onHide]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="manage-edit-user-profile-modal"
    >
      <Modal.Header>
        <button onClick={onHide} className="chevron">
          <Icon name="chevronLeft" size={12} />
        </button>
        <h2>Edit Account</h2>
        <button {...saveButtonProps} className="save-btn">
          Save
        </button>
      </Modal.Header>
      <Modal.Body>
        <EditUserProfileComponent
          userId={userId}
          setSaveButtonProps={setSaveButtonProps}
          onSuccessfulSave={onSuccessfulSave}
        />
      </Modal.Body>
    </Modal>
  );
}

export default function EditUserProfilePage() {
  const location = useLocation();

  const params = useParams();

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (params.userId && location.pathname.includes('edit')) {
      setUserId(Number(params.userId));
    }
  }, [params.userId, location.pathname]);

  const [saveButtonProps, setSaveButtonProps] = useState({});

  const renderSaveButton = useCallback(
    () => (
      <button className="btn-save-edit" {...saveButtonProps}>
        Save
      </button>
    ),
    [saveButtonProps],
  );

  return (
    <div className="manage-edit-user-profile-page">
      <DesktopHeader />
      <EditUserProfileComponent
        userId={userId}
        setSaveButtonProps={setSaveButtonProps}
        saveButton={renderSaveButton()}
      />
    </div>
  );
}

EditUserProfilePage.propTypes = {};
EditUserProfilePage.defaultProps = {};
