import React, { useEffect } from 'react'
// import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik'
import ReactPhoneInput from 'react-phone-input-2'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'
import { ConfirmCoverageSchema } from './schemas/ConfirmCoverageSchema'

export default function ConfirmCoverage() {
    const history = useHistory()

    const {
        getSettingsByName,
        settings: data,
        getSettingsByNamePending,
    } = useGetSettingsByName()

    const { defaultLanguage } = useSetDefaultLanguage()

    const { company: name, type } = useParams()

    useEffect(() => {
        getSettingsByName({ name, type })
        console.log('data campaign', data)
    }, [name])

    const [isLoading, setIsLoading] = React.useState(false)

    const handleSubmit = async values => {
        setIsLoading(true)
        await new Promise(resolve => setTimeout(resolve, 2000))

        setIsLoading(false)

        const encoded = btoa(JSON.stringify(values))

        history.push(
            `/auth/${name}/signup/${type}` +
                (defaultLanguage ? '?lang=' + defaultLanguage : '') +
                (defaultLanguage && encoded ? '&' : '?') +
                (encoded ? 'confirm_coverage=' + encoded : '')
        )
    }

    useEffect(() => {
        if (!data) return
        if (data.test) return

        const checkbox = document.getElementById('terms')

        if (!checkbox) return

        checkbox.className = 'checkbox-styles'

        const checkboxStyles = document.createElement('style')
        checkboxStyles.innerHTML = `
      input[type='checkbox'] {
        height: max-content;
        margin-top: 0.15rem;
      }
      input[type='checkbox']::after {
        width: 11px;
        height: 11px;
        border: 1px solid ${data.globals.colors.secondary};
        border-radius: 2px;
        background-color: #fff;
      }
      input[type='checkbox']:checked::after {
        background-color: ${data.globals.colors.secondary};
      }
    `

        document.head.appendChild(checkboxStyles)
    }, [data])

    if (getSettingsByNamePending || !data) return null

    const confirmCoverageSchema = ConfirmCoverageSchema(
        data.pages[defaultLanguage].confirm_coverage.input_alerts
    )

    if (!confirmCoverageSchema) return null

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values)
                setSubmitting(false)
            }}
            validationSchema={confirmCoverageSchema}
        >
            {props => (
                <div className="confirm-coverage">
                    <div className="confirm-coverage-navbar">
                        <Link
                            to={
                                `/auth/${name}/${type}` +
                                '?lang=' +
                                defaultLanguage
                            }
                        >
                            <img src={data.globals.logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="content-container">
                        {data.pages[defaultLanguage].confirm_coverage.media
                            .background_image && (
                            <img
                                src={
                                    data.pages[defaultLanguage].confirm_coverage
                                        .media.background_image
                                }
                                alt="background"
                                className="background-image"
                            />
                        )}

                        {data.pages[defaultLanguage].confirm_coverage.media
                            ?.background_video && (
                            <video
                                autoPlay
                                muted
                                loop
                                className="video-background"
                            >
                                <source
                                    src={
                                        data.pages[defaultLanguage]
                                            .confirm_coverage.media
                                            .background_video
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        )}

                        <div className="signup-container">
                            <h1>
                                {
                                    data.pages[defaultLanguage].confirm_coverage
                                        .content.welcome_title
                                }
                            </h1>
                            <p>
                                {
                                    data.pages[defaultLanguage].confirm_coverage
                                        .content.welcome_message
                                }
                            </p>
                            <Form>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="employer"
                                        id="employer"
                                        placeholder={
                                            data.pages[defaultLanguage]
                                                .confirm_coverage
                                                .input_placeholders.employer +
                                            ' *'
                                        }
                                    />
                                    {props.errors.employer &&
                                        props.touched.employer && (
                                            <p className="error-message">
                                                <ErrorMessage name="employer" />
                                            </p>
                                        )}
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="text"
                                        name="last_4_digits"
                                        id="last_4_digits"
                                        placeholder={
                                            data.pages[defaultLanguage]
                                                .confirm_coverage
                                                .input_placeholders
                                                .last_4_digits + ' *'
                                        }
                                        onChange={e => {
                                            const { value } = e.target
                                            if (value.length > 4) return

                                            props.setFieldValue(
                                                'last_4_digits',
                                                value
                                            )
                                        }}
                                        value={props.values.last_4_digits}
                                    />
                                    {props.errors.last_4_digits &&
                                        props.touched.last_4_digits && (
                                            <p className="error-message">
                                                <ErrorMessage name="last_4_digits" />
                                            </p>
                                        )}
                                </div>

                                <div className="terms-check">
                                    <Field
                                        type="checkbox"
                                        name="insured"
                                        id="insured"
                                    />
                                    <div className="terms-container">
                                        <p
                                            className="agree"
                                            style={{
                                                color:
                                                    data.globals.colors.primary,
                                            }}
                                            onClick={() =>
                                                props.setFieldValue(
                                                    'insured',
                                                    !props.values.insured
                                                )
                                            }
                                        >
                                            {
                                                data.pages[defaultLanguage]
                                                    .confirm_coverage.content
                                                    .insured_message
                                            }
                                        </p>

                                        {props.errors.insured &&
                                            props.touched.insured && (
                                                <p className="error-message">
                                                    <ErrorMessage name="insured" />
                                                </p>
                                            )}
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="signup-btn"
                                    disabled={props.isSubmitting}
                                    style={{
                                        backgroundColor:
                                            data.globals.colors.primary,
                                    }}
                                >
                                    {isLoading
                                        ? 'Loading...'
                                        : data.pages[defaultLanguage]
                                              .confirm_coverage.content
                                              .submit_button}
                                </button>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

const initialValues = {
    employer: '',
    last_4_digits: '',
    insured: false,
}
