import React, { useRef, useState } from 'react'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import { useClearAllAlerts } from '../../redux/clearAllAlerts'
import Icon from '../../../common/components/Icon'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function Alerts({ id, alerts, onHide, first = false }) {
    const { clearAllAlerts } = useClearAllAlerts()

    const history = useHistory()

    const [alertShowing, setAlertShowing] = useState(-1)

    const ref = useRef(null)
    useOutsideAlerter(ref, () => setAlertShowing(-1))

    const { t } = useTranslation()

    const icons = {
        assessment: 'notes',
        mood: 'hrv',
    }

    if (!alerts || alerts.length === 0)
        return (
            <p className="rpm-alerts-wrapper__no-alerts">
                {t('coach_dashboard.no_alerts')}
            </p>
        )

    return (
        <div
            className="rpm-alerts-wrapper-alert"
            onClick={() => setAlertShowing(id)}
        >
            <div className="rpm-alerts-wrapper-alert-icon-container">
                <div className="rpm-alerts-wrapper-alert-icon-square" />
                <p className="rpm-alerts-wrapper-alert-icon">!</p>
            </div>
            <div className="rpm-alerts-wrapper-alert-text-container">
                <p className="rpm-alerts-wrapper-alert-text">
                    {alerts ? alerts.length : 0}{' '}
                    {alerts && alerts.length > 1
                        ? t('coach_dashboard.alerts')
                        : t('coach_dashboard.alert')}
                </p>
            </div>
            {alertShowing === id && (
                <div
                    className="rpm-alerts-wrapper-alert-popup"
                    ref={ref}
                    style={{
                        ...(first && {
                            top: 0,
                        }),
                    }}
                >
                    <div className="rpm-alerts-wrapper-alert-popup-header">
                        <div className="rpm-alerts-wrapper-alert-popup-header-title-container">
                            <div className="rpm-alerts-wrapper-alert-icon-container">
                                <div
                                    className="rpm-alerts-wrapper-alert-icon-square"
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                    }}
                                />
                                <p
                                    className="rpm-alerts-wrapper-alert-icon"
                                    style={{
                                        color: '#e8744f',
                                    }}
                                >
                                    !
                                </p>
                            </div>
                            <p className="rpm-alerts-wrapper-alert-popup-header-title">
                                {alerts ? alerts.count : 0} Critical Scores{' '}
                            </p>
                        </div>
                        <p
                            className="rpm-alerts-wrapper-alert-popup-header-clear"
                            onClick={() => {
                                clearAllAlerts({
                                    userId: id,
                                }).then(() => {
                                    onHide()
                                    setAlertShowing(-1)
                                })
                            }}
                        >
                            {t('coach_dashboard.clear_all')}
                        </p>
                    </div>
                    <div className="rpm-alerts-wrapper-alert-popup-body">
                        <div
                            className="right-triangle"
                            style={{
                                ...(first && {
                                    top: '-2.25rem',
                                }),
                            }}
                        />
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingBottom: '1.5rem',
                                marginBottom: '3rem',
                                overflowY: 'auto',
                                height: '100%',
                            }}
                        >
                            {alerts &&
                                alerts.map(
                                    (
                                        { title, score, timestamp, type },
                                        index
                                    ) => (
                                        <div
                                            className="rpm-alerts-wrapper-alert-popup-body-item"
                                            key={index}
                                        >
                                            <div className="rpm-alerts-wrapper-alert-popup-body-item-left">
                                                <Icon
                                                    name={icons[type] || 'hrv'}
                                                />
                                                <p>{title}</p>
                                            </div>
                                            <div className="rpm-alerts-wrapper-alert-popup-body-item-right">
                                                <div className="rpm-alerts-wrapper-alert-popup-body-item-right-text">
                                                    <p>{score}</p>
                                                </div>
                                                <div className="rpm-alerts-wrapper-alert-popup-body-item-right-date">
                                                    <p>
                                                        {new Date(
                                                            timestamp
                                                        ).toLocaleDateString(
                                                            'en-US',
                                                            {
                                                                month: 'short',
                                                                day: 'numeric',
                                                            }
                                                        )}
                                                    </p>
                                                    <p>
                                                        {new Date(
                                                            timestamp
                                                        ).toLocaleTimeString(
                                                            'en-US',
                                                            {
                                                                hour: 'numeric',
                                                                minute:
                                                                    'numeric',
                                                            }
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                        <p
                            className="rpm-alerts-wrapper-alert-popup-body-item-view-profile"
                            onClick={() =>
                                history.push(`/rpm/client-profile/${id}`)
                            }
                            role="button"
                            style={{
                                position: 'absolute',
                                bottom: '1.5rem',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            {t('coach_dashboard.view_profile')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}
