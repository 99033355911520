import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    AUTH_CREATE_EMPLOYER_COVERAGE_BEGIN,
    AUTH_CREATE_EMPLOYER_COVERAGE_SUCCESS,
    AUTH_CREATE_EMPLOYER_COVERAGE_FAILURE,
    AUTH_CREATE_EMPLOYER_COVERAGE_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'
import axios from 'axios'
export function createEmployerCoverage(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: AUTH_CREATE_EMPLOYER_COVERAGE_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.post(
                `${config.apiRootUrl}/profile/employer-coverage`,
                args,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: AUTH_CREATE_EMPLOYER_COVERAGE_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: AUTH_CREATE_EMPLOYER_COVERAGE_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissCreateEmployerCoverageError() {
    return {
        type: AUTH_CREATE_EMPLOYER_COVERAGE_DISMISS_ERROR,
    }
}

export function useCreateEmployerCoverage() {
    const dispatch = useDispatch()

    const {
        createEmployerCoveragePending,
        createEmployerCoverageError,
    } = useSelector(
        state => ({
            createEmployerCoveragePending:
                state.auth.createEmployerCoveragePending,
            createEmployerCoverageError: state.auth.createEmployerCoverageError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(createEmployerCoverage(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissCreateEmployerCoverageError())
    }, [dispatch])

    return {
        createEmployerCoverage: boundAction,
        createEmployerCoveragePending,
        createEmployerCoverageError,
        dismissCreateEmployerCoverageError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case AUTH_CREATE_EMPLOYER_COVERAGE_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                createEmployerCoveragePending: true,
                createEmployerCoverageError: null,
            }

        case AUTH_CREATE_EMPLOYER_COVERAGE_SUCCESS:
            // The request is success
            return {
                ...state,
                createEmployerCoveragePending: false,
                createEmployerCoverageError: null,
            }

        case AUTH_CREATE_EMPLOYER_COVERAGE_FAILURE:
            // The request is failed
            return {
                ...state,
                createEmployerCoveragePending: false,
                createEmployerCoverageError: action.data.error,
            }

        case AUTH_CREATE_EMPLOYER_COVERAGE_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                createEmployerCoverageError: null,
            }

        default:
            return state
    }
}
