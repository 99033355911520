import React, { useContext } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Icon from '../../../common/components/Icon'
import getRelativeDay from '../../../../common/getRelativeDay'
import { AudioPlayingContext } from '../contexts/AudioPlayingProvider'

export default function Conversation({ ...props }) {
  const { timestamp, user, type, style, read, itsMe, isSelected } = props

  const { messagePlaying, recordingAudio } = useContext(AudioPlayingContext)

  const colorMap = {
    0: '718371',
    1: 'C47258',
    2: '517284',
    3: 'B68C31',
  }

  const Wrapper =
    messagePlaying || recordingAudio ? OverlayTrigger : React.Fragment

  return (
    <div
      className="item"
      onClick={props.onClick}
      style={{ ...style, position: 'relative' }}
    >
      <div className="photo-and-name">
        {read === false && (
          <div
            className="number-of-messages"
            style={{
              position: 'absolute',
              left: '12px',
              top: '50%',
            }}
          />
        )}

        {type === 'p2p' && (
          <img
            src={
              user.photo
                ? user.photo
                : `https://ui-avatars.com/api/?name=${user.first_name[0] +
                    user.last_name[0]}&background=718371&color=fff&size=128`
            }
          />
        )}

        {type === 'group' && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns:
                props?.participants && props.participants.length === 1
                  ? '1fr'
                  : '1fr 1fr',
              gridTemplateRows:
                props?.participants && props.participants.length < 2
                  ? '1fr'
                  : '1fr 1fr',
              width: '3.7rem',
              height: '3.7rem',
              gap: '0.1rem',
              position: 'relative',
            }}
          >
            {props?.participants &&
              props.participants
                .slice(0, 4)
                .map(({ photo, first_name, last_name }, idx) => (
                  <img
                    key={idx}
                    src={
                      photo
                        ? photo
                        : `https://ui-avatars.com/api/?name=${first_name[0] +
                            last_name[0]}&background=${
                            colorMap[idx]
                          }&color=fff&size=128`
                    }
                    style={{
                      aspectRatio: 1,
                      width: '100%',
                      height: '100%',
                      maxHeight: '1.85rem',
                      objectFit: 'cover',
                      borderRadius: '0.3rem',
                      borderEndEndRadius: '0rem',
                      ...(props?.participants.length >= 4 && {
                        borderEndEndRadius: '0.3rem',
                      }),
                      ...(idx === 1 &&
                        props.participants.length === 2 && {
                          marginTop: '1.3rem',
                          marginLeft: '-0.5rem',
                          border: '1px solid #F8F5F5',
                        }),
                      ...(props.participants.length === 2 &&
                        idx === 0 && {
                          marginTop: '.3rem',
                          marginRight: '-0.5rem',
                        }),
                    }}
                  />
                ))}

            {props?.participants && props.participants.length > 4 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '1.5rem',
                  height: '1.5rem',
                  backgroundColor: '#343434',
                  borderRadius: '0.3rem',
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                  marginBottom: '-0.5rem',
                  marginRight: '-0.5rem',
                }}
              >
                <p
                  style={{
                    color: '#fff',
                    fontSize: '0.7rem',
                    fontWeight: '600',
                    lineHeight: '0',
                    marginTop: '0.1rem',
                  }}
                >
                  +5
                </p>
              </div>
            )}
          </div>
        )}

        <div className="name-and-time">
          {type === 'p2p' ? (
            <h3>
              {user.first_name} {user.last_name} {itsMe && '(You)'}
            </h3>
          ) : (
            <h3>{props.name}</h3>
          )}

          <p>{timestamp ? getRelativeDay(timestamp) : 'No messages yet'}</p>
        </div>
      </div>
    </div>
  )
}
