import { Form, Formik, Field, ErrorMessage } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useCreateUserChildInvitation } from '../../redux/createUserChildInvitation';

export default function CaregiverForm({ data, setShowSuccess }) {
  const { createUserChildInvitation } = useCreateUserChildInvitation();

  const schema = Yup.object().shape({
    child_email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    child_first_name: Yup.string().required('Child First Name is required'),
    child_last_name: Yup.string().required('Child Last Name is required'),
    guardian_first_name: Yup.string().required(
      'Guardian First Name is required',
    ),
    guardian_last_name: Yup.string().required('Guardian Last Name is required'),
    signature: Yup.string().required('Signature is required'),
  });

  const initialValues = {
    child_email: '',
    child_first_name: '',
    child_last_name: '',
    guardian_first_name: '',
    guardian_last_name: '',
    signature: '',
  };

  const handleSubmit = async (values) => {
    createUserChildInvitation(values);
    setShowSuccess(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        //  alert(JSON.stringify(values, null, 2));
        handleSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={schema}
    >
      {(props) => (
        <Form className="caregiver-form">
          <h2>Invite your Child</h2>
          <div className="form-group">
            <Field
              type="email"
              name="child_email"
              id="child_email"
              placeholder="Child's Email Address *"
            />
            {props.errors.child_email && props.touched.child_email && (
              <p className="error-message">
                <ErrorMessage name="child_email" />
              </p>
            )}
          </div>
          <h2>Consent to work with Your Child</h2>
          <p className="section-title">Child's Information</p>
          <div className="form-group">
            <Field
              type="text"
              name="child_first_name"
              id="child_first_name"
              placeholder="Child's First Name *"
            />
            {props.errors.child_first_name && props.touched.child_first_name && (
              <p className="error-message">
                <ErrorMessage name="child_first_name" />
              </p>
            )}
          </div>
          <div className="form-group">
            <Field
              type="text"
              name="child_last_name"
              id="child_last_name"
              placeholder="Child's Last Name *"
            />
            {props.errors.child_last_name && props.touched.child_last_name && (
              <p className="error-message">
                <ErrorMessage name="child_last_name" />
              </p>
            )}
          </div>
          <p className="section-title">Guardian's Information</p>
          <div className="form-group">
            <Field
              type="text"
              name="guardian_first_name"
              id="guardian_first_name"
              placeholder="Guardian's First Name *"
            />
            {props.errors.guardian_first_name &&
              props.touched.guardian_first_name && (
                <p className="error-message">
                  <ErrorMessage name="guardian_first_name" />
                </p>
              )}
          </div>
          <div className="form-group">
            <Field
              type="text"
              name="guardian_last_name"
              id="guardian_last_name"
              placeholder="Guardian's Last Name *"
            />
            {props.errors.guardian_last_name &&
              props.touched.guardian_last_name && (
                <p className="error-message">
                  <ErrorMessage name="guardian_last_name" />
                </p>
              )}
          </div>
          <p className="section-title">Consent Information</p>
          <p className="consent-information">
            I, the parent, hereby consent to the use of my child's information
            for the purposes of the following: - To provide my child with the
            opportunity to participate in the program and to be involved in the
            development of the program.
          </p>
          <p className="section-title">
            By writing your name you agree that we can with work your child
          </p>

          <div className="form-group">
            <Field
              type="text"
              name="signature"
              id="signature"
              placeholder="Signature *"
            />
            {props.errors.signature && props.touched.signature && (
              <p className="error-message">
                <ErrorMessage name="signature" />
              </p>
            )}
          </div>

          <button
            type="submit"
            className="done-btn"
            disabled={props.isSubmitting}
            style={{
              backgroundColor: data.colors.primary,
            }}
          >
            Done
          </button>
        </Form>
      )}
    </Formik>
  );
}
