import React, { useEffect } from 'react';
import { useListHrv } from '../../redux/listHrv';
import Device from './records/Device';
import Footer from './records/Footer';
import HealthDataHeader from './records/HealthDataHeader';
import Records from './records/Records';
import StatsHeader from './records/StatsHeader';

export default function Sidebar() {
  const { listHrv } = useListHrv();

  useEffect(() => {
    listHrv();
  }, [listHrv]);


  return (
    <div className="stats-sidebar">
      <StatsHeader />
      <div className="devices-container">
        <Device />
        <Footer />
      </div>
      <HealthDataHeader />
      <div className="health-data-records-container">
        <Records />
      </div>
    </div>
  );
}
