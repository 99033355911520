import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { useFormikContext } from 'formik';
import { useFetchAssignedUsers } from '../../../dashboard/redux/fetchAssignedUsers';
import ValueContainer from '../../../support/components/formContainer/ValueContainer';
import { ReactSelectStyles } from '../../../support/components/formContainer/utils/ReactSelectStyles';

export default function AssignCoachForm() {
  const { setFieldValue, errors, touched, values } = useFormikContext();

  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const stateRef = useRef(selectedCoaches);

  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();

  useEffect(() => {
    stateRef.current = selectedCoaches;
  }, [selectedCoaches]);

  useEffect(() => {
    if (values?.id && values.coach?.length > 0) {
      setSelectedCoaches(
        values.coach.map((coach) => ({
          id: coach.id,
          value: `${coach.first_name} ${coach.last_name}`,
          label: `${coach.first_name} ${coach.last_name}`,
        })),
      );
    }
  }, [values?.id]);

  useEffect(() => {
    console.log('values', values);
  }, [values]);

  const loadCoaches = (inputValue) => {
    return fetchAssignedUsers({
      search: inputValue,
    })
      .then((res) => {
        console.log('internal coaches', res);
        return res.results.map((user) => ({
          value: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (inputValue) => {
    if (assignedUsers?.results.length > 0) {
      setSelectedCoaches(inputValue);
      setFieldValue(
        'coach_write_only',
        inputValue?.reduce((acc, item) => {
          acc.push(item.id);
          return acc;
        }, []) || [],
      );
    }
  };

  return (
    <div className="form-group">
      <AsyncSelect
        id="coach_write_only"
        isMulti
        placeholder="Assign a coach to your client"
        value={selectedCoaches}
        getOptionValue={(option) => option.id}
        defaultOptions
        loadOptions={loadCoaches}
        onChange={handleChange}
        styles={{
          ...ReactSelectStyles,
          valueContainer: (base) => ({
            ...ReactSelectStyles.valueContainer(base),
            paddingLeft: '1rem',
            height: 'auto',
          }),
          control: (base) => ({
            ...ReactSelectStyles.control(base),
            borderRadius: '0.8rem',
          }),
          placeholder: (base) => ({
            ...ReactSelectStyles.placeholder(base),
          }),
        }}
      />
      {errors.coach_write_only && touched.coach_write_only && (
        <div className="error-message">{errors.coach_write_only}</div>
      )}
    </div>
  );
}
