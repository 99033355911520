import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN,
  SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE,
  SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function createInternalProductSubscription(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.apiRootUrl}/subscriptions/create-internal-product-subscription`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateInternalProductSubscriptionError() {
  return {
    type: SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR,
  };
}

export function useCreateInternalProductSubscription() {
  const dispatch = useDispatch();

  const {
    createInternalProductSubscriptionPending,
    createInternalProductSubscriptionError,
  } = useSelector(
    (state) => ({
      createInternalProductSubscriptionPending:
        state.subscriptions.createInternalProductSubscriptionPending,
      createInternalProductSubscriptionError:
        state.subscriptions.createInternalProductSubscriptionError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(createInternalProductSubscription(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateInternalProductSubscriptionError());
  }, [dispatch]);

  return {
    createInternalProductSubscription: boundAction,
    createInternalProductSubscriptionPending,
    createInternalProductSubscriptionError,
    dismissCreateInternalProductSubscriptionError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createInternalProductSubscriptionPending: true,
        createInternalProductSubscriptionError: null,
      };

    case SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_SUCCESS:
      // The request is success
      return {
        ...state,
        createInternalProductSubscriptionPending: false,
        createInternalProductSubscriptionError: null,
      };

    case SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_FAILURE:
      // The request is failed
      return {
        ...state,
        createInternalProductSubscriptionPending: false,
        createInternalProductSubscriptionError: action.data.error,
      };

    case SUBSCRIPTIONS_CREATE_INTERNAL_PRODUCT_SUBSCRIPTION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createInternalProductSubscriptionError: null,
      };

    default:
      return state;
  }
}
