import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const CheckIcon = ({ size = 24, color = '#343434', style = {} }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      style={{ height: size, width: size, ...style }}
      fill={color}
    >
      <path d="M10.3,2A7.94,7.94,0,1,0,18.24,10,7.94,7.94,0,0,0,10.3,2Zm0,14.48A6.55,6.55,0,1,1,16.85,10,6.54,6.54,0,0,1,10.3,16.49Z" />
      <path d="M15.06,7.32a.69.69,0,0,0-1,0L9.15,12.26,7,10.11a.69.69,0,0,0-1,1l2.64,2.64a.68.68,0,0,0,.49.2.7.7,0,0,0,.49-.2L15.06,8.3A.69.69,0,0,0,15.06,7.32Z" />
    </svg>
  );
};

export default CheckIcon;
