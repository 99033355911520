import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Loader } from '../../common';
import { useFetchUser } from '../../user/redux/fetchUser';
import {
  useCreateContract,
  useCreateInternalProductContractSubscription,
  useUpdateContract,
} from '../redux/hooks';

export default function ContractModal({
  showModal,
  setShowModal,
  update = false,
  contract = {},
  setContract,
}) {
  const { user } = useFetchUser();
  const { createContract } = useCreateContract();
  const { createInternalProductContractSubscription } =
    useCreateInternalProductContractSubscription();

  const { updateContract } = useUpdateContract();

  const [formData, updatedFormData] = useState({
    name: update ? contract.name : '',
    description: update ? contract.description : '',
    start_date: update ? contract.start_date : '',
    end_date: update ? contract.end_date : '',
    company: '',
    ...(update && { id: contract.id }),
  });

  console.log(formData);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const isValidated = () => {
    if (formData.name === '') {
      setError('Please fill in the Name field');
      return false;
    }
    if (formData.description === '') {
      setError('Please fill in the Description field');
      return false;
    }
    if (formData.start_date === '') {
      setError('Please fill in the Start Date field');
      return false;
    }
    if (formData.end_date === '' || formData.end_date === null) {
      setError('Please fill in the End Date field');
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (user) {
      updatedFormData({
        ...formData,
        company: user.company_id,
      });
    }
  }, [user]);

  const handleClose = () => {
    setError([]);
    setShowError(false);
    setShowModal(false);
    setLoading(false);

    if (update) {
      setContract(false);
    }
  };

  const handleChange = (e) => {
    updatedFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setShowError(false);
    if (!isValidated()) {
      setLoading(false);
      setShowError(true);
      console.log('Error response', error);
      return;
    }

    async function createProductAndContract() {
      createContract(formData).then(async (res) => {
        const contractId = res.data.id;
        createInternalProductContractSubscription({
          name: formData.name,
          contract: contractId,
        })
          .then((res) => {
            console.log('res', res);
            setLoading(false);
            setShowModal(false);
            window.location.reload(false);
          })
          .catch((err) => {
            console.log('err', err);
            setLoading(false);
            setShowError(true);
            setError('Error creating subscription');
          });
      });
    }

    async function updateContractFunc() {
      updateContract(formData)
        .then((res) => {
          console.log('res', res);
          setLoading(false);
          setShowModal(false);
          window.location.reload(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoading(false);
          setShowError(true);
          setError('Error updating contract');
        });
    }

    if (update) {
      updateContractFunc();
    } else {
      createProductAndContract();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      className="contracts-modal"
    >
      <Modal.Header>
        <Button variant="secondary" onClick={() => handleClose()}>
          Cancel
        </Button>
        <h2>{update ? 'Update Contract' : 'Create a Contract'} </h2>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader position="static" />}
        {showError && <p className="error-message">{error}</p>}
        <form id="create-product-form" onSubmit={handleSubmit}>
          <label>Contract Name</label>
          <input
            type="text"
            name="name"
            onChange={handleChange}
            value={formData.name}
          />
          <label>Contract Description</label>
          <input
            type="text"
            name="description"
            onChange={handleChange}
            value={formData.description}
          />
          <label>Contract Start Date</label>
          <input
            type="date"
            name="start_date"
            onChange={handleChange}
            value={formData.start_date}
          />
          <label>Contract End Date</label>
          <input
            type="date"
            name="end_date"
            onChange={handleChange}
            min={formData.start_date}
            value={formData.end_date}
          />
        </form>
      </Modal.Body>
    </Modal>
  );
}
