import React, { useState, useEffect, useCallback } from 'react';
import Icon from '../../common/components/Icon';
import { useWindowSize } from '../../../common/windowsSize';

export default function RoadmapsCarousel(props) {
  const size = useWindowSize();
  const { children, show, infiniteLoop } = props;

  const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? show : 0);
  const [length, setLength] = useState(children.length);

  const [isRepeating, setIsRepeating] = useState(
    infiniteLoop && children.length > show,
  );
  const [transitionEnabled, setTransitionEnabled] = useState(true);

  const [touchPosition, setTouchPosition] = useState(null);

  // Set the length to match current children from props
  useEffect(() => {
    setLength(children.length);
    setIsRepeating(infiniteLoop && children.length > show);
  }, [children, infiniteLoop, show]);

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true);
      }
    }
  }, [currentIndex, isRepeating, show, length]);

  const next = useCallback(() => {
    if (isRepeating || currentIndex < length - show) {
      if (currentIndex === length + show) {
        setCurrentIndex(show);
      }
      setCurrentIndex((prevState) => prevState + 1);
    }
  }, [currentIndex, isRepeating, length, show]);

  // every 1.5 seconds if infinite loop is enabled run next()
  useEffect(() => {
    if (currentIndex === length + show) {
      setCurrentIndex(show);
    }
    if (currentIndex < show) {
      setCurrentIndex(length + show - 1);
    }
    if (infiniteLoop) {
      const interval = setInterval(() => {
        next();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [infiniteLoop, next, currentIndex, length, show]);

  const prev = () => {
    if (isRepeating || currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  const handleTransitionEnd = () => {
    if (isRepeating) {
      if (currentIndex === 0) {
        setTransitionEnabled(false);
        setCurrentIndex(length);
      } else if (currentIndex === length + show) {
        setTransitionEnabled(false);
        setCurrentIndex(show);
      }
    }
  };

  const renderExtraPrev = () => {
    let output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[length - 1 - index]);
    }
    output.reverse();
    return output;
  };

  const renderExtraNext = () => {
    let output = [];
    for (let index = 0; index < show; index++) {
      output.push(children[index]);
    }
    return output;
  };

  return (
    <div className="carousel-container" style={{ maxWidth: '100%' }}>
      <div className={`buttons-container ${size.width < 768 && 'd-none'}`}>
        {(isRepeating || currentIndex > 0) && (
          <button onClick={prev}>
            <Icon name="chevronLeft" size="8" color="#343434" />
          </button>
        )}
        {(isRepeating || currentIndex < length - show) && (
          <button onClick={next}>
            <Icon
              name="chevronLeft"
              size="8"
              color="#343434"
              className="right"
            />
          </button>
        )}
      </div>

      <div
        className="carousel-content-wrapper"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div
          className={`carousel-content show-${show}`}
          style={{
            transform: `translateX(-${currentIndex * (100 / show)}%)`,
            transition: !transitionEnabled ? 'none' : undefined,
          }}
          onTransitionEnd={() => handleTransitionEnd()}
        >
          {length > show && isRepeating && renderExtraPrev()}
          {children}
          {length > show && isRepeating && renderExtraNext()}
        </div>
      </div>
    </div>
  );
}
