import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_GET_CLIENTS_BEGIN,
    RPM_GET_CLIENTS_SUCCESS,
    RPM_GET_CLIENTS_FAILURE,
    RPM_GET_CLIENTS_DISMISS_ERROR,
} from './constants'
import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function getClients(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_GET_CLIENTS_BEGIN,
        })

        const {
          has_call_scheduled,
          does_not_have_call,
          search,
          page,
          eligibility_status,
          alert_type,
          reset,
          ordering,
        } = args

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.get(`${config.apiRootUrl}/rpm/clients/`, {
                params: {
                    ...(has_call_scheduled && { has_call_scheduled }),
                    ...(does_not_have_call && { does_not_have_call }),
                    search,
                    page,
                    ...(eligibility_status && { eligibility_status }),
                    ...(alert_type && { alert_type }),
                    ...(ordering && { ordering }),
                },
                ...createAxiosConfigWithAuth(getState()),
            })
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_GET_CLIENTS_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_GET_CLIENTS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissGetClientsError() {
    return {
        type: RPM_GET_CLIENTS_DISMISS_ERROR,
    }
}

export function useGetClients() {
    const dispatch = useDispatch()

    const { getClientsPending, getClientsError, clients } = useSelector(
        state => ({
            getClientsPending: state.rpm.getClientsPending,
            getClientsError: state.rpm.getClientsError,
            clients: state.rpm.clients,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(getClients(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissGetClientsError())
    }, [dispatch])

    return {
        clients,
        getClients: boundAction,
        getClientsPending,
        getClientsError,
        dismissGetClientsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_GET_CLIENTS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                getClientsPending: true,
                getClientsError: null,
            }

        case RPM_GET_CLIENTS_SUCCESS:
            // The request is success
            return {
                ...state,
                getClientsPending: false,
                getClientsError: null,
                clients: action.data,
            }

        case RPM_GET_CLIENTS_FAILURE:
            // The request is failed
            return {
                ...state,
                getClientsPending: false,
                getClientsError: action.data.error,
            }

        case RPM_GET_CLIENTS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                getClientsError: null,
            }

        default:
            return state
    }
}
