import * as Yup from 'yup'

export const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
        termsAndConditions: Yup.boolean().oneOf([true], 'Terms and Conditions must be accepted'),
})

export const aboutYourselfSchema = Yup.object().shape({
    gender: Yup.string().required('Gender is required'),
    month: Yup.string().required('Month is required'),
    day: Yup.string().required('Day is required'),
    year: Yup.string().required('Year is required'),
    phone_number: Yup.string()
        .required('Phone is required')
        .min(10, 'Phone must be 10 digits minimum')
        .max(15, 'Phone must be 15 digits maximum'),
})

export const contactInformationSchema = Yup.object().shape({
    address_1: Yup.string().required('Address is required'),
    address_2: Yup.string(),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string()
        .required('Zip is required')
        .min(5, 'Zip must be 5 digits')
        .max(5, 'Zip must be 5 digits'),
})

export const insuranceSchema = Yup.object().shape({
    insurance_provider: Yup.string().required('Insurance Provider is required'),
    member_id: Yup.string().required('Member ID is required'),
    policy_holder: Yup.string().required('Policy Holder is required'),
    group_number: Yup.string().required('Group Number is required'),
})
