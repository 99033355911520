const config = {
    apiRootUrl: 'https://api-dev.noble.health/api/v1',
    //apiRootUrl: 'http://0.0.0.0:8000/api/v1',
    displayStageEnvBanner: false,
    publishableKey:
        'pk_test_51H5C5BGvgjKLpHZZe8hVX7PAqvVMHlePiQaJQEyJ6yu2O9dBkSgkyJAQYYZbKDHwIc7SXzA73aa8BcxzcafBJu7I00OnqrYx5D',
    messagesMediaUrl:
        'https://0s6bjq2ydl.execute-api.us-east-1.amazonaws.com/v1',
    pushNotificationsUrl:
        'https://0bnkdyzsoi.execute-api.us-east-1.amazonaws.com/v1',
    nobleExpoUrl: 'noblehealth://',
    parentGuidanceExpoUrl: 'parentguidance-coaching://',
    notificationSettingsApiHost:
        'https://nqv4yr7sqg.execute-api.us-east-1.amazonaws.com/v1',
}

if (process.env.REACT_APP_ENV === 'development') {
    config.apiRootUrl = 'https://api-dev.noble.health/api/v1'
    config.displayStageEnvBanner = true
    config.publishableKey =
        'pk_test_51H5C5BGvgjKLpHZZe8hVX7PAqvVMHlePiQaJQEyJ6yu2O9dBkSgkyJAQYYZbKDHwIc7SXzA73aa8BcxzcafBJu7I00OnqrYx5D'
} else if (process.env.REACT_APP_ENV === 'staging') {
    config.apiRootUrl = 'https://api-staging.noble.health/api/v1'
    config.displayStageEnvBanner = true
    config.publishableKey =
        'pk_test_51H5C5BGvgjKLpHZZe8hVX7PAqvVMHlePiQaJQEyJ6yu2O9dBkSgkyJAQYYZbKDHwIc7SXzA73aa8BcxzcafBJu7I00OnqrYx5D'
} else if (process.env.REACT_APP_ENV === 'production') {
    config.apiRootUrl = 'https://api.noble.health/api/v1'
    config.publishableKey =
        'pk_live_51H5C5BGvgjKLpHZZPCLUAapxrrLwB2lBxoFCK7g2Q9LABZKb721UtAS9XtBEyrt5Lo6lZj12iryEtS4ttcPFiVK900YMfi2U0Z'
    config.messagesMediaUrl =
        'https://goj04tj2e0.execute-api.us-east-1.amazonaws.com/v1'
    config.pushNotificationsUrl =
        'https://0bnkdyzsoi.execute-api.us-east-1.amazonaws.com/v1'
    config.notificationSettingsApiHost =
        'https://jn91iw3mhe.execute-api.us-east-1.amazonaws.com/v1'
}

export default config
