import { useContext, useEffect } from 'react'
import { SupportContext } from '../../../Dashboard'
import { AssignCoachSchema } from '../yup/AssignCoachSchema'
import { ClientInfoSchema } from '../yup/ClientInfoSchema'
import { SubscriptionSchema } from '../yup/SubscriptionSchema'

export default function StepListener() {
    const { stepValues, steps, setSteps, userExists } = useContext(
        SupportContext
    )

    useEffect(() => {
        console.log('steps', steps)
    }, [steps])

    useEffect(() => {
        if (!userExists) {
            ClientInfoSchema.validate(stepValues)
                .then(() => {
                    console.log('step one is valid')
                    console.log('steps', steps)
                    if (
                        steps[1].edit !== true &&
                        steps[2].edit !== true &&
                        steps[3].edit !== true &&
                        steps[4].edit !== true
                    ) {
                        setSteps({
                            ...steps,
                            1: {
                                state: 'done',
                                edit: false,
                            },
                            2: {
                                state: 'active',
                                edit: null,
                            },
                        })

                        AssignCoachSchema.concat(ClientInfoSchema)
                            .validate(stepValues)
                            .then(() => {
                                console.log('step two is valid')
                                if (
                                    steps[2].edit !== true &&
                                    steps[3].edit !== true &&
                                    steps[4].edit !== true
                                ) {
                                    setSteps({
                                        ...steps,
                                        2: {
                                            state: 'done',
                                            edit: false,
                                        },
                                        3: {
                                            state: 'active',
                                            edit: null,
                                        },
                                    })

                                    AssignCoachSchema.concat(ClientInfoSchema)
                                        .concat(SubscriptionSchema)
                                        .validate(stepValues)
                                        .then(() => {
                                            console.log('step three is valid')
                                            if (
                                                steps[3].edit !== true &&
                                                steps[4].edit !== true
                                            ) {
                                                setSteps({
                                                    ...steps,
                                                    3: {
                                                        state: 'done',
                                                        edit: false,
                                                    },
                                                    4: {
                                                        state: 'active',
                                                        edit: false,
                                                    },
                                                })
                                            }
                                        })
                                        .catch(() => null)
                                }
                            })
                            .catch(() => null)
                    }
                })
                .catch(err => {})
        }
    }, [stepValues])
    return null
}
