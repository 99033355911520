import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import Icon from '../../../common/components/Icon';
import clsx from 'clsx';

const DatePickerButton = forwardRef(({ value, onClick, className }, ref) => (
  <Button
    ref={ref}
    onClick={onClick}
    variant="white"
    className={clsx('btn-due-date btn-center', className)}
  >
    <Icon name="calendar" />
    {value || 'Due Date'}
  </Button>
));

export default DatePickerButton;
