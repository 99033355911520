import React from 'react';
import Linkify from 'react-linkify';

export default function ResponseCard({ collapsed = false, ...props }) {
  const {
    item,
    relativeTime,
    setActionItemResponses,
    actionItemResponses,
    user,
    setResponseMessage,
    isMobile,
  } = props;

  let customAvatar = `https://ui-avatars.com/api/?name=${
    item.user.first_name[0] + item.user.last_name[0]
  }&background=343434&color=fff&size=128`;

  const handleEdit = () => {
    setActionItemResponses(
      actionItemResponses.map((response) => {
        if (response.id === item.id) {
          return {
            ...response,
            edit: !response.edit,
          };
        } else {
          return {
            ...response,
            edit: false,
          };
        }
      }),
    );
    setResponseMessage((prevState) => ({
      ...prevState,
      message: item.message,
    }));

    props.editCallback && props.editCallback();
  };

  return (
    <div
      className={'action-item-response ' + props.className}
      style={{
        ...props.style,
        ...(collapsed && {
          height: '120px',
        }),
      }}
    >
      <div className="action-item-header">
        <div className="image-and-name">
          <img
            src={item.user.photo || customAvatar}
            alt="user image"
            className="action-item-image"
          />
          <div className="name-and-time">
            <p className="name-text">
              {item.user.first_name + ' ' + item.user.last_name}
            </p>
            <p className="date-text">{relativeTime}</p>
          </div>
        </div>
        {item.user.id === user.id && (
          <p className="edit" onClick={handleEdit}>
            {item.edit ? 'Save' : 'Edit'}
          </p>
        )}
      </div>
      <div className="action-item-content">
        <p
          style={{
            // add 2 lines of ellipsis when collapsed is true
            display: '-webkit-box',
            WebkitLineClamp: collapsed ? 2 : 'unset',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transition: 'all 1s ease-in-out',
          }}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target="_blank"
                href={decoratedHref}
                key={key}
                style={{
                  color: '#343434',
                  textDecoration: 'underline',
                  fontWeight: '500',
                }}
              >
                {decoratedText}{' '}
              </a>
            )}
          >
            {item.message}
          </Linkify>
        </p>
      </div>
    </div>
  );
}
