import React from 'react';

export default function ProgressBar({ percentage, color = '#88a088' }) {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="progress-wrapper">
          <div
            style={{
              width: `${percentage}%`,
              backgroundColor: color,
              color: color,
            }}
            className="progress"
          />
        </div>
      </div>
      <p className="text">{Math.floor(percentage)}%</p>
    </div>
  );
}
