import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useCancelSubscription } from '../redux/cancelSubscription';
import { Loader } from '../../common';
import { useGetSubscriptionStatus } from '../redux/getSubscriptionStatus';

export default function CancelSubscriptionModal({ show, onHide }) {
  const [isLoading, setIsLoading] = useState(false);
  const { cancelSubscription } = useCancelSubscription();

  const handleCancelSubscription = useCallback(() => {
    setIsLoading(true);
    cancelSubscription()
      .then((res) => {
        console.log('Cancel Subscription: ', res.data);
        setIsLoading(false);
        onHide();
      })
      .catch((err) => {
        console.log('Cancel Subscription Error: ', err);
      });
  }, [cancelSubscription, onHide]);

  const { getSubscriptionStatus } = useGetSubscriptionStatus();
  const [lastDate, setLastDate] = useState(null);

  useEffect(() => {
    const getStatus = async () => getSubscriptionStatus();
    getStatus()
      .then((res) => {
        const date = new Date(res.data.current_period_end);
        // format Date to Month Day, Year
        const formattedDate = `${date.toLocaleString('default', {
          month: 'long',
        })} ${date.getDate()}, ${date.getFullYear()}`;
        setLastDate(formattedDate);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} className="cancel-subscription-modal">
      <Modal.Header>
        <Modal.Title>We’re sorry to see you go.</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!isLoading && (
          <>
            {' '}
            <p>
              Your monthly subscription is paid until {lastDate}. If you would
              like to proceed with canceling your subscription, please select
              “Cancel Subcription” below.
            </p>
            <div className="are-you-sure">
              <p>Are you sure?</p>
              <p>
                By cancelling yoru subscription you will lose access to one on
                one messaging with your coach.
              </p>

              <p>You will still be able to access your roadmap</p>
            </div>
          </>
        )}

        {isLoading && <Loader />}
        {!isLoading && (
          <button className="cancel" onClick={handleCancelSubscription}>
            Cancel Subscription
          </button>
        )}

        {!isLoading && (
          <button className="go-back" onClick={onHide}>
            Go Back
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
}
