import React, { useContext } from 'react';
import StepHeader from '../StepHeader';
import { SupportContext } from '../../../Dashboard';
import { useFormikContext } from 'formik';
import SubscriptionSelector from '../customInputs/SubscriptionSelector';
import RadioInput from '../customInputs/RadioInput';
import StepsController from '../../StepsController';

export default function Review() {
  const { steps, setSteps, selectedCoach, selectedSub, companyName } =
    useContext(SupportContext);

  const { values } = useFormikContext();

  return (
    <React.Fragment>
      <StepHeader
        step={4}
        title="Let's Review"
        onClick={() => {
          StepsController(4, 0, setSteps, steps, values);
        }}
      />

      {steps[4].state !== 'active' ? (
        <></>
      ) : (
        <>
          <div className="support-dashboard-review">
            <p className="title">Client Information</p>

            <div className="info-grid">
              <p>{values.first_name}</p> <p>{values.last_name}</p>
              <p>{values.phone_number}</p> <p>{values.city}</p>
              <p>{values.email}</p>
            </div>
          </div>
          <hr className="support-divider" />
          <div className="support-dashboard-review">
            <div className="info-grid">
              <div className="inner-col">
                <p className="title">Coach</p>
                {selectedCoach &&
                  selectedCoach.map((coach, idx) => (
                    <p className="body-info" key={idx}>
                      {coach.value}
                    </p>
                  ))}
              </div>
              {values.coaching.length > 0 && (
                <div className="inner-col">
                  <p className="title">Coaching Group</p>
                  <p className="body-info">{values.coaching}</p>
                </div>
              )}
            </div>
          </div>
          <hr className="support-divider" />
          <div className="support-dashboard-review">
            <div className="info-grid">
              <div className="inner-col">
                <p className="title">Subscription</p>
                <p className="body-info">{companyName}</p>
              </div>
              {values.is_trial && (
                <div className="checkbox-wrapper">
                  <RadioInput
                    label="Trial Subscription"
                    active={values.is_trial}
                  />
                  {values.by_sms_or_default ? (
                    <RadioInput label="Send Text Message Alert" />
                  ) : (
                    <RadioInput label="Send Email Alert" />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="support-dashboard-review span-2 pl-0">
            <div className="subscription">
              <SubscriptionSelector
                title={selectedSub?.name}
                price={selectedSub?.price?.unit_amount}
                endDate={selectedSub?.trial_end}
              />
            </div>
          </div>
          <hr className="support-divider" />
          <button type="submit" className="support-submit-form-btn">
            Save and Send
          </button>
        </>
      )}
    </React.Fragment>
  );
}
