const StepsController = (step, schema, setSteps, steps, stepValues) => {
  const activeStep = Object.keys(steps).find(
    (key) => steps[key].state === 'active',
  );

  if (activeStep < step) return;

  if (step !== 4) {
    schema.validate(stepValues).then(() => {
      setSteps({
        ...steps,
        [step]: {
          state: steps[step].state === 'active' ? 'done' : 'active',
          edit: !steps[step].edit,
        },
        ...Array.from({ length: 4 }, (_, i) => step + i + 1).reduce(
          (acc, curr) => {
            acc[curr] = {
              state: 'inactive',
              edit: null,
            };
            return acc;
          },
          {},
        ),
      });

      if (steps[step].edit === true) {
        setSteps({
          ...steps,
          [step]: {
            state: steps[step].state === 'active' ? 'done' : 'active',
            edit: !steps[step].edit,
          },
          [step + 1]: {
            state: steps[step + 1].state === 'active' ? 'done' : 'active',
            edit: !steps[step + 1].edit,
          },
        });
      }
    });
  } else {
    step = 1;
    setSteps({
      ...steps,
      [step]: {
        state: steps[step].state === 'active' ? 'done' : 'active',
        edit: !steps[step].edit,
      },
      ...Array.from({ length: 4 }, (_, i) => step + i + 1).reduce(
        (acc, curr) => {
          acc[curr] = {
            state: 'inactive',
            edit: null,
          };
          return acc;
        },
        {},
      ),
    });
  }
};

export default StepsController;
