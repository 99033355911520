import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useGetListInternalProductSubscription } from '../../../../subscriptions/redux/getListInternalProductSubscription';
import { useFetchUser } from '../../../../user/redux/fetchUser';
import { SupportContext } from '../../../Dashboard';
import StepsController from '../../StepsController';
import RadioInput from '../customInputs/RadioInput';
import SubscriptionSelector from '../customInputs/SubscriptionSelector';
import StepHeader from '../StepHeader';
import { SubscriptionSchema } from '../yup/SubscriptionSchema';

export default function SubscriptionForm() {
  const { getListInternalProductSubscription } =
    useGetListInternalProductSubscription();

  const { setFieldValue, errors, touched, values } = useFormikContext();

  const { user } = useFetchUser();

  const [subscriptions, setSubscriptions] = useState([]);

  const [showContract, setShowContract] = useState(
    user?.company_payment_method_allowed === 0 ? false : true,
  );

  const { steps, setSteps, stepValues, setSelectedSub } =
    useContext(SupportContext);

  const getProducts = () => {
    try {
      getListInternalProductSubscription({
        company: values.company,
      }).then((res) => {
        setSubscriptions(
          res.data.results.filter((sub) =>
            showContract ? sub.contract : !sub.contract,
          ),
        );
        console.log('products', res.data);
      });
    } catch {
      console.log('error in getting products');
    }
  };

  useEffect(() => {
    if (values.company) {
      getProducts();
    }
  }, [getListInternalProductSubscription, user, values.company, showContract]);

  useEffect(() => {
    setFieldValue('product', '');
  }, [values.is_trial]);

  useEffect(() => {
    if (hasContract) {
      setFieldValue('by_sms_or_default', true);
    }
  }, [hasContract, values.product]);

  let hasContract =
    subscriptions?.filter((product) => product.contract).length > 0;

  return (
    <React.Fragment>
      <StepHeader
        step={3}
        title="Subscription"
        className={`${steps[4].state === 'active' && 'd-none'}`}
        onClick={() => {
          StepsController(3, SubscriptionSchema, setSteps, steps, stepValues);
        }}
      >
        <button
          className={`${
            steps[3].state === 'active' ? '' : 'd-none'
          } switch-contract-btn`}
          onClick={() => {
            setShowContract(!showContract);
          }}
        >
          {showContract ? 'Switch to stripe' : 'Switch to contract'}
        </button>
      </StepHeader>

      {subscriptions?.filter((product) => product.company === values.company)
        .length > 0 &&
        !hasContract && (
          <div
            className={`support-subscription-radios ${
              steps[4].state === 'active' && 'd-none'
            }`}
          >
            <RadioInput
              label="Trial Subscription"
              onClick={() => {
                setFieldValue('is_trial', !values.is_trial);
              }}
              active={values.is_trial}
              className={`${steps[3].state === 'active' ? '' : 'd-none'}`}
            />

            <div
              className={`support-subscription-trial-date ${
                values.is_trial && steps[3].state === 'active' ? '' : 'd-none'
              }`}
            >
              <label htmlFor="trial_end">Trial End Date</label>
              <input
                type="date"
                id="trial_end"
                name="trial_end"
                onChange={(e) => {
                  setFieldValue('trial_end', e.target.value);
                }}
                value={values.trial_end}
                className={`${steps[3].state === 'active' ? '' : 'd-none'}`}
                // min date is three days from today
                min={
                  new Date(new Date().setDate(new Date().getDate() + 3))
                    .toISOString()
                    .split('T')[0]
                }
              />
              {!!errors.trial_end && touched && (
                <div
                  className={`error ${
                    steps[3].state === 'active' ? '' : 'd-none'
                  }`}
                >
                  {errors.trial_end}
                </div>
              )}
            </div>
          </div>
        )}

      {subscriptions?.filter((product) => product.contract).length > 0 && (
        <p
          className={`contracts-header  ${
            steps[3].state === 'active' ? '' : 'd-none'
          }`}
        >
          <strong>Note:</strong> These subscriptions are part of a Third Party
          Payment Agreement. Contact your school district for more information
          related.
        </p>
      )}

      {subscriptions?.filter((product) => product.company === values.company)
        .length > 0 && (
        <div
          className={`subscriptions-grid ${
            steps[3].state === 'active' ? '' : 'd-none'
          }`}
        >
          {subscriptions
            ?.filter((product) => product.company === values.company)
            .filter((product) => (values.is_trial ? !product.contract : true))
            .map((product, idx) => {
              let price = product.price?.unit_amount;

              let endDate = product.contract
                ? new Date(product.contract.end_date)
                : null;

              if (endDate) {
                endDate = endDate.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                });
              }

              return (
                <div
                  onClick={() => {
                    setFieldValue('product', product.id);
                    setSelectedSub(product);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setFieldValue('product', product.id);
                      setSelectedSub(product);
                    }
                  }}
                  tabIndex="0"
                  key={idx}
                >
                  <SubscriptionSelector
                    title={product.name}
                    price={price}
                    active={values.product === product.id}
                    endDate={endDate}
                  />
                </div>
              );
            })}
        </div>
      )}
      {!!errors.product && touched && (
        <div className={`error ${steps[3].state === 'active' ? '' : 'd-none'}`}>
          {errors.product}
        </div>
      )}

      {!hasContract && (
        <div
          className={`text-message-or-email-wrapper ${
            steps[3].state === 'active' ? '' : 'd-none'
          }`}
        >
          <RadioInput
            label="Send Text Message Alert"
            onClick={() => {
              console.log(values, 'values');

              if (values.by_sms_or_default === null) {
                setFieldValue('by_sms_or_default', true);
              } else {
                setFieldValue('by_sms_or_default', !values.by_sms_or_default);
              }
            }}
            active={values.by_sms_or_default === true}
          />
          <RadioInput
            label="Send Email Alert"
            onClick={() => {
              if (values.by_sms_or_default === null) {
                setFieldValue('by_sms_or_default', false);
              } else {
                setFieldValue('by_sms_or_default', !values.by_sms_or_default);
              }
            }}
            active={values.by_sms_or_default === false}
          />
        </div>
      )}
    </React.Fragment>
  );
}
