import qs from 'qs';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Icon from '../common/components/Icon';
// import PropTypes from 'prop-types';
import logo from './images/logo.png';
import woman from './images/woman.png';
import { useConfirmResetPassword, useFetchAuthToken } from './redux/hooks';

export default function SubscriptionInvitation() {
  const [validated, setValidated] = useState(false);

  const { fetchAuthToken } = useFetchAuthToken();

  const { confirmResetPassword } = useConfirmResetPassword();
  const history = useHistory();
  const { uid, token } = useParams();
  const location = useLocation();
  console.log(location);

  const { p } = qs.parse(location.search.replace('?', ''));
  let params = {};
  try {
    params = JSON.parse(atob(p));
  } catch {}
  const email = params.email;
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      confirmResetPassword({
        new_password1: form.elements.formPassword.value,
        new_password2: form.elements.formConfirmPassword.value,
        uid,
        token,
      })
        .then(() => {
          fetchAuthToken({
            email: email,
            password: form.elements.formPassword.value,
          });
        })
        .then((res) => {
          history.push('/signup_stripe');
        })
        .catch(() => {
          console.log('Error resetting password');
        });
    }
    setValidated(true);
  };
  return (
    <div className="home-subscription-invitation">
      <div className="login_sidebar mrm-mx-10">
        <img src={logo} className="logo" alt="company-logo" />
        <div className="login_form">
          <div className="form_c">
            <h3 className="welcome">Welcome, ____</h3>
            <p>You’ve been invited by _______ to join Noble</p>
            <p className="ca">Setup Account</p>
            <Form
              className="mrm-mb-2 "
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Form.Group>
                <svg
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="email"
                >
                  <path
                    d="M15.3516 0H2.83303C2.08166 0 1.36107 0.302187 0.829774 0.840083C0.298479 1.37798 0 2.10752 0 2.86822V11.5464C0 12.3071 0.298479 13.0367 0.829774 13.5746C1.36107 14.1125 2.08166 14.4147 2.83303 14.4147H15.3516C16.103 14.4147 16.8236 14.1125 17.3549 13.5746C17.8862 13.0367 18.1846 12.3071 18.1846 11.5464V2.86822C18.1846 2.10752 17.8862 1.37798 17.3549 0.840083C16.8236 0.302187 16.103 0 15.3516 0ZM2.83303 1.47088H15.3516C15.7188 1.47087 16.0711 1.6177 16.3319 1.8794C16.5926 2.14111 16.7407 2.4965 16.7439 2.86822V3.27272L9.9156 8.43306C9.67757 8.61216 9.38889 8.70887 9.09232 8.70887C8.79576 8.70887 8.50708 8.61216 8.26905 8.43306L1.44073 3.27272V2.86822C1.44392 2.4965 1.59202 2.14111 1.85278 1.8794C2.11354 1.6177 2.46585 1.47087 2.83303 1.47088ZM15.3516 12.9438H2.83303C2.46585 12.9438 2.11354 12.797 1.85278 12.5353C1.59202 12.2736 1.44392 11.9182 1.44073 11.5464V5.11132L7.39735 9.6588C7.88527 10.0326 8.48043 10.2349 9.09232 10.2349C9.70421 10.2349 10.2994 10.0326 10.7873 9.6588L16.7439 5.11132V11.5464C16.7407 11.9182 16.5926 12.2736 16.3319 12.5353C16.0711 12.797 15.7188 12.9438 15.3516 12.9438Z"
                    fill="#FFFFFF"
                  />
                </svg>
                <Form.Control
                  type="email"
                  value={email}
                  disabled={true}
                  id={`disabled-custom-disabled`}
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <svg
                  width="17"
                  height="21"
                  viewBox="0 0 17 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.1123 8.52223C16.1194 8.46309 16.1194 8.40331 16.1123 8.34418V8.02623C16.114 6.97419 15.9079 5.93232 15.5062 4.96121C15.0939 4.00797 14.5022 3.1442 13.7637 2.41763C13.0203 1.62237 12.1219 0.990237 11.1247 0.560821C10.1473 0.176985 9.10541 -0.0130258 8.0564 0.00123366C7.00295 -0.0172849 5.95614 0.172845 4.97547 0.560821C3.99092 0.970158 3.09673 1.57208 2.34466 2.33177C1.59258 3.09146 0.997543 3.99382 0.593974 4.98665C0.190275 5.9701 -0.011599 7.02569 0.000514575 8.08982V19.2689C0.000514575 19.4712 0.0803337 19.6653 0.222413 19.8084C0.364491 19.9515 0.557192 20.0319 0.758121 20.0319H15.3168C15.5177 20.0319 15.7104 19.9515 15.8525 19.8084C15.9946 19.6653 16.0744 19.4712 16.0744 19.2689V8.70028C16.093 8.64239 16.1057 8.58272 16.1123 8.52223ZM3.40975 3.43507C4.01689 2.82745 4.73799 2.34758 5.53105 2.02338C6.3301 1.6852 7.18974 1.51636 8.0564 1.52738C8.92062 1.54158 9.77555 1.7095 10.5818 2.02338C11.3688 2.34801 12.0843 2.82646 12.6868 3.43112C13.2893 4.03577 13.7669 4.75465 14.092 5.54624C14.3778 6.24982 14.536 6.99932 14.5592 7.75915H1.54098C1.57153 7.00971 1.72513 6.27055 1.99555 5.57167C2.31934 4.77085 2.80035 4.04412 3.40975 3.43507ZM1.51573 18.5058V9.2853H14.5592V18.5058H1.51573Z"
                    fill="#6D786E"
                  />
                  <path
                    d="M8.0588 11.2822C7.8756 11.2822 7.6999 11.3631 7.57036 11.507C7.44082 11.651 7.36804 11.8462 7.36804 12.0497V15.1198C7.36804 15.3233 7.44082 15.5185 7.57036 15.6625C7.6999 15.8064 7.8756 15.8873 8.0588 15.8873C8.242 15.8873 8.41769 15.8064 8.54724 15.6625C8.67678 15.5185 8.74955 15.3233 8.74955 15.1198V12.0497C8.74955 11.8462 8.67678 11.651 8.54724 11.507C8.41769 11.3631 8.242 11.2822 8.0588 11.2822Z"
                    fill="#6D786E"
                  />
                </svg>
                <Form.Control required type="password" placeholder="Password" />
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <svg
                  width="17"
                  height="21"
                  viewBox="0 0 17 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.1123 8.52223C16.1194 8.46309 16.1194 8.40331 16.1123 8.34418V8.02623C16.114 6.97419 15.9079 5.93232 15.5062 4.96121C15.0939 4.00797 14.5022 3.1442 13.7637 2.41763C13.0203 1.62237 12.1219 0.990237 11.1247 0.560821C10.1473 0.176985 9.10541 -0.0130258 8.0564 0.00123366C7.00295 -0.0172849 5.95614 0.172845 4.97547 0.560821C3.99092 0.970158 3.09673 1.57208 2.34466 2.33177C1.59258 3.09146 0.997543 3.99382 0.593974 4.98665C0.190275 5.9701 -0.011599 7.02569 0.000514575 8.08982V19.2689C0.000514575 19.4712 0.0803337 19.6653 0.222413 19.8084C0.364491 19.9515 0.557192 20.0319 0.758121 20.0319H15.3168C15.5177 20.0319 15.7104 19.9515 15.8525 19.8084C15.9946 19.6653 16.0744 19.4712 16.0744 19.2689V8.70028C16.093 8.64239 16.1057 8.58272 16.1123 8.52223ZM3.40975 3.43507C4.01689 2.82745 4.73799 2.34758 5.53105 2.02338C6.3301 1.6852 7.18974 1.51636 8.0564 1.52738C8.92062 1.54158 9.77555 1.7095 10.5818 2.02338C11.3688 2.34801 12.0843 2.82646 12.6868 3.43112C13.2893 4.03577 13.7669 4.75465 14.092 5.54624C14.3778 6.24982 14.536 6.99932 14.5592 7.75915H1.54098C1.57153 7.00971 1.72513 6.27055 1.99555 5.57167C2.31934 4.77085 2.80035 4.04412 3.40975 3.43507ZM1.51573 18.5058V9.2853H14.5592V18.5058H1.51573Z"
                    fill="#6D786E"
                  />
                  <path
                    d="M8.0588 11.2822C7.8756 11.2822 7.6999 11.3631 7.57036 11.507C7.44082 11.651 7.36804 11.8462 7.36804 12.0497V15.1198C7.36804 15.3233 7.44082 15.5185 7.57036 15.6625C7.6999 15.8064 7.8756 15.8873 8.0588 15.8873C8.242 15.8873 8.41769 15.8064 8.54724 15.6625C8.67678 15.5185 8.74955 15.3233 8.74955 15.1198V12.0497C8.74955 11.8462 8.67678 11.651 8.54724 11.507C8.41769 11.3631 8.242 11.2822 8.0588 11.2822Z"
                    fill="#6D786E"
                  />
                </svg>
                <Form.Control
                  required
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Group>

              <div className="subscription">
                {' '}
                <p> Subscription </p>
                <div className="subscription-plan">
                  <div>
                    <p className="tag">Standard</p>
                    <h3>Noble Guided</h3>
                  </div>
                  <p className="price"> $175/mo </p>
                </div>
              </div>
              <Button className="btn-100" variant="primary" type="submit">
                Next
              </Button>
            </Form>

            <a href="https://google.com">
              {' '}
              <p className="terms">
                By clicking next you agree to terms and conditions{' '}
              </p>
            </a>

            <div className="fake_carousel">
              <Icon name="circle" />
              <Icon name="circle" color="#C4C4C4" />
              <Icon name="circle" color="#C4C4C4" />
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="login_content">
          <img src={woman} className="woman" alt="woman-illustration" />
          <h1>Sign up messaging</h1>
          <p>
            This is a chance to showcase what the app can do. Because what if
            the MHP didn’t give a full overview of all the benefits?
          </p>
        </div>
      </div>
    </div>
  );
}

SubscriptionInvitation.propTypes = {};
SubscriptionInvitation.defaultProps = {};
