import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  USER_GET_CHARGES_BY_CUSTOMER_BEGIN,
  USER_GET_CHARGES_BY_CUSTOMER_SUCCESS,
  USER_GET_CHARGES_BY_CUSTOMER_FAILURE,
  USER_GET_CHARGES_BY_CUSTOMER_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import config from '../../../common/config';

export function getChargesByCustomer(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: USER_GET_CHARGES_BY_CUSTOMER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${config.apiRootUrl}/subscriptions/get-charges-by-customer/${args.customerId}`,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: USER_GET_CHARGES_BY_CUSTOMER_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: USER_GET_CHARGES_BY_CUSTOMER_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetChargesByCustomerError() {
  return {
    type: USER_GET_CHARGES_BY_CUSTOMER_DISMISS_ERROR,
  };
}

export function useGetChargesByCustomer() {
  const dispatch = useDispatch();

  const { getChargesByCustomerPending, getChargesByCustomerError } =
    useSelector(
      (state) => ({
        getChargesByCustomerPending: state.user.getChargesByCustomerPending,
        getChargesByCustomerError: state.user.getChargesByCustomerError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getChargesByCustomer(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetChargesByCustomerError());
  }, [dispatch]);

  return {
    getChargesByCustomer: boundAction,
    getChargesByCustomerPending,
    getChargesByCustomerError,
    dismissGetChargesByCustomerError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_GET_CHARGES_BY_CUSTOMER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getChargesByCustomerPending: true,
        getChargesByCustomerError: null,
      };

    case USER_GET_CHARGES_BY_CUSTOMER_SUCCESS:
      // The request is success
      return {
        ...state,
        getChargesByCustomerPending: false,
        getChargesByCustomerError: null,
      };

    case USER_GET_CHARGES_BY_CUSTOMER_FAILURE:
      // The request is failed
      return {
        ...state,
        getChargesByCustomerPending: false,
        getChargesByCustomerError: action.data.error,
      };

    case USER_GET_CHARGES_BY_CUSTOMER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getChargesByCustomerError: null,
      };

    default:
      return state;
  }
}
