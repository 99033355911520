import React, { useEffect, useRef } from 'react'
import AddCptCodeModal from './AddCptCodeModal'
import NewAssessmentModal from './NewAssessmentModal'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import useWindowSize from '../../../common/useWindowResize'
import { useFetchUser } from '../../../user/redux/fetchUser'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import AddCallModal from '../../../dashboard/UpcomingCalls/AddCallModal'
import { useFetchCoachUpcomingCalls } from '../../../dashboard/redux/fetchCoachUpcomingCalls'
import SendClinicalRecordModal from './SendClinicalRecordModal'

export default function TabSelector({
  options,
  selectedOption,
  setSelectedOption,
  user,
  params,
  setParams,
}) {
  const [showAddDropdown, setShowAddDropdown] = React.useState(false)

  const [showAddAssessment, setShowAddAssessment] = React.useState(false)
  const [showAddCPTCode, setShowAddCPTCode] = React.useState(false)
  const [showSendClinicalRecord, setShowSendClinicalRecord] = React.useState(
    false
  )

  const { fetchCoachCalls } = useFetchCoachUpcomingCalls()
  const [showCallModal, setShowCallModal] = React.useState(false)

  const { user: coach } = useFetchUser()

  const { isMobile } = useWindowSize()

  const addDropdownRef = useRef(null)
  useOutsideAlerter(addDropdownRef, () => setShowAddDropdown(false))

  const { t } = useTranslation()

  if (!coach) return null

  const isOnlyUser = user.groups.length === 1 && user.groups[0] === 'User'

  return (
    <div className="rpm-client-profile__container__right-body__header">
      <AddCallModal
        show={showCallModal}
        onHide={() => {
          setShowCallModal(false)
          fetchCoachCalls({ user_id: user.id })
        }}
        userId={user.id}
      />

      <SendClinicalRecordModal
        show={showSendClinicalRecord}
        onHide={() => setShowSendClinicalRecord(false)}
        user={user}
      />

      <div className="rpm-client-profile__container__right-body__header__options-wrapper">
        <div className="rpm-client-profile__container__right-body__header__options-wrapper__options">
          {options.map((option, idx) => {
            const isSelected = options[idx].name === selectedOption.name
            return (
              <div
                id={option.name}
                className="rpm-client-profile__container__right-body__header__options-wrapper__options__option"
                key={idx}
                style={{
                  color: isSelected ? '#343434' : '#686868',
                  fontWeight: isSelected ? '600' : '400',
                  opacity: isSelected ? '1' : '0.4',
                  ...(isOnlyUser &&
                    option.value === 'schedule' && {
                      ...{
                        opacity: 0,
                        pointerEvents: 'none',
                        cursor: 'default',
                        userSelect: 'none',
                      },
                    }),
                }}
                role="button"
                onClick={() => setSelectedOption(option)}
              >
                <p className="rpm-client-profile__container__right-body__header__options-wrapper__options__option__text">
                  {t(`client_profile.${option.value.toLowerCase()}`)}
                </p>
              </div>
            )
          })}
        </div>
        {/** selected bar */}
        <div className="rpm-client-profile__container__right-body__header__options-wrapper__selected-bar">
          <div
            id="selected-bar"
            className="rpm-client-profile__container__right-body__header__options-wrapper__selected-bar__bar"
            style={{
              transform: `translateX(${options.findIndex(
                option => option.name === selectedOption.name
              ) *
                (isMobile ? 19 : 9.2) +
                (isMobile ? 'vw' : 'rem')}    )`,
            }}
          />
        </div>
      </div>
      {/**  if it is not a provider show the add button (or if it's admin) */}
      {((coach.groups.includes('Coach') && coach.user_is_internal_coach) ||
        coach.groups.includes('Admin')) && (
        <div
          className="rpm-client-profile__container__right-body__header__add"
          role="button"
          onClick={() => setShowAddDropdown(!showAddDropdown)}
          style={{
            ...(showAddDropdown && {
              backgroundColor: '#D3CFCC',
            }),
          }}
          ref={addDropdownRef}
        >
          <Icon
            name={'plus'}
            color={!showAddDropdown ? '#ffffff' : '#908E87'}
          />
          {showAddDropdown && (
            <div
              className="rpm-client-profile__container__right-body__header__add__options"
              role="button"
            >
              <div
                className="rpm-client-profile__container__right-body__header__add__options__option"
                onClick={() => {
                  setShowAddDropdown(false)
                  setShowAddAssessment(true)
                }}
              >
                <p className="rpm-client-profile__container__right-body__header__add__options__option__text">
                  {t('client_profile.add_assessment')}
                </p>
              </div>
              <div
                className="rpm-client-profile__container__right-body__header__add__options__option"
                onClick={() => {
                  setShowAddDropdown(false)
                  setShowAddCPTCode(true)
                }}
              >
                <p className="rpm-client-profile__container__right-body__header__add__options__option__text">
                  {t('client_profile.add_cpt_code')}
                </p>
              </div>

              <div
                className="rpm-client-profile__container__right-body__header__add__options__option"
                onClick={() => {
                  setShowAddDropdown(false)
                  setShowCallModal(true)
                }}
              >
                <p className="rpm-client-profile__container__right-body__header__add__options__option__text">
                  {t('client_profile.add_call')}
                </p>
              </div>
              {user.company_id == 122 && ( // prime meridian health care
                <div
                  className="rpm-client-profile__container__right-body__header__add__options__option"
                  onClick={() => {
                    setShowAddDropdown(false)
                    setShowSendClinicalRecord(true)
                  }}
                >
                  <p className="rpm-client-profile__container__right-body__header__add__options__option__text">
                    {t('client_profile.send_clinical_record')}
                  </p>
                </div>
              )}
              {coach.groups.includes('Admin') && (
                <div
                  className="rpm-client-profile__container__right-body__header__add__options__option"
                  onClick={() => {
                    window.location.href = `/rpm/events/${user.id}`
                  }}
                >
                  <p className="rpm-client-profile__container__right-body__header__add__options__option__text">
                    {t('client_profile.add_event')}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <NewAssessmentModal
        userId={user.id}
        show={showAddAssessment}
        onHide={() => setShowAddAssessment(false)}
        params={params}
        setParams={setParams}
      />
      <AddCptCodeModal
        show={showAddCPTCode}
        onHide={() => setShowAddCPTCode(false)}
        userId={user.id}
      />
    </div>
  )
}
