import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import dayjs from 'dayjs'
import React, { forwardRef, useCallback, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import Switch from 'react-switch'
import { Header } from '../common'
import Icon from '../common/components/Icon'
import useEffectiveBackLink from '../common/useEffectiveBackLinkHook'
import { useAddCoachCall, useFetchAssignedUsers } from './redux/hooks'
import { upcomingCallsSchema } from './UpcomingCalls/schema'

export default function CoachAddCallsPage() {
  const history = useHistory()
  const { register, handleSubmit, control, errors, setError } = useForm({
    resolver: yupResolver(upcomingCallsSchema),
  })
  const defaultBackLink = `/dashboard/coach`
  const effectiveBackLink = useEffectiveBackLink(defaultBackLink)

  const { addCoachCall, addCoachCallPending } = useAddCoachCall()

  const handleError = useCallback(
    err =>
      Object.keys(err).forEach(key => {
        const errors = err[key]
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' })
        }
      }),
    [setError]
  )

  const handleSaveClick = useCallback(
    ({
      call_name,
      timestamp,
      time_zone,
      meeting_link,
      selectedParticipants,
      interval,
      is_recurrent,
    }) => {
      timestamp = new Date(timestamp).valueOf()
      const participants = selectedParticipants.map(e => e.id)

      if (!meeting_link?.match(/^https?:\/\//))
        meeting_link = 'http://' + meeting_link
      addCoachCall({
        call_name,
        timestamp,
        time_zone,
        meeting_link,
        participants,
        interval,
        is_recurrent,
      })
        .then(() => history.push(effectiveBackLink))
        .catch(e => handleError(e.response.data))
    },
    [addCoachCall, history, handleError, effectiveBackLink]
  )

  const renderBackLink = useCallback(
    effectiveBackLink => (
      <Link to={effectiveBackLink}>
        <p className="add-btn cancel">Cancel</p>
      </Link>
    ),
    []
  )

  const mobileMultiSelectStyles = {
    control: (styles, state) => {
      const custom = {
        ...styles,
        borderRadius: '10px',
        cursor: 'text',
        borderColor: '#eeeeee',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          borderColor: '#eeeeee',
        },
      }
      return custom
    },
  }

  const renderSaveButton = useCallback(
    () => (
      <button
        className="add-btn"
        disabled={addCoachCallPending}
        onClick={handleSubmit(handleSaveClick)}
      >
        Add
      </button>
    ),
    [handleSaveClick, handleSubmit, addCoachCallPending]
  )

  const DatePickerButton = forwardRef(({ value, onClick, className }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      variant="white"
      className={clsx('form-control hover date-picker-cal', {
        'text-muted': !value,
      })}
    >
      <Icon name="calendar" size={23} />
      {value || 'Select Date & Time'}
    </div>
  ))

  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers()
  const multiselectList = {
    assignedUsers: assignedUsers
      ? assignedUsers.results.map(item => ({
          id: item.id,
          full_name: `${item.first_name} ${item.last_name}`,
        }))
      : null,
  }

  useEffect(() => {
    fetchAssignedUsers()
  }, [fetchAssignedUsers])

  return (
    <div className="dashboard-coach-add-calls-page">
      <Header
        icon="back"
        title="Add Call"
        renderThirdColumn={renderSaveButton}
        thirdColumnClass="text-right"
        colSizes={['auto', undefined, 'auto']}
        border
        renderBackLink={renderBackLink}
        defaultBackLink={defaultBackLink}
      ></Header>
      <Form className="mrm-p-1 mt-n5">
        <Form.Group controlId="call_name">
          <Form.Label>Call Name</Form.Label>
          <Form.Control
            name="call_name"
            type="text"
            isInvalid={errors.call_name}
            ref={register}
            placeholder="Call Name"
          />
        </Form.Group>

        <Form.Group
          controlId="timestamp"
          className="mb-0 d-flex flex-column mb-4"
        >
          <Form.Label>Date/Time</Form.Label>
          <Controller
            name="timestamp"
            control={control}
            isInvalid={errors.timestamp}
            render={({ onChange, value }) => (
              <DatePicker
                selected={value}
                showTimeSelect
                minDate={new Date()}
                timeIntervals={15}
                dateFormat="MMMM d @ h:mm aa"
                customInput={
                  <DatePickerButton
                    className={clsx({ 'is-invalid': errors.timestamp })}
                  />
                }
                onChange={onChange}
                className="text-primary ml-0"
              />
            )}
          />
        </Form.Group>

        <Form.Group controlId="time_zone" className="d-none">
          <Form.Control
            name="time_zone"
            type="text"
            isInvalid={errors.time_zone}
            ref={register}
            value={dayjs.tz?.guess()}
          />
        </Form.Group>

        <Form.Group controlId="selectedParticipants">
          <Form.Label>Participants</Form.Label>
          <Controller
            name="selectedParticipants"
            control={control}
            isInvalid={errors.participants}
            render={({ onChange, value }) => (
              <Select
                isMulti
                defaultValue={[]}
                value={value}
                options={multiselectList.assignedUsers}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.full_name}
                onChange={onChange}
                styles={mobileMultiSelectStyles}
              />
            )}
            placeholder="Select participants"
          />
        </Form.Group>

        <Form.Group controlId="meeting_link">
          <Form.Label>Meeting Link (zoom, google meet)</Form.Label>
          <Form.Control
            name="meeting_link"
            type="text"
            isInvalid={errors.meeting_link}
            ref={register}
            placeholder="Meeting Link"
          />
        </Form.Group>

        <Form.Group
          controlId="is_recurrent"
          className="mb-0 d-flex flex-column mb-4"
        >
          <Form.Label>Recurs Weekly</Form.Label>
          <Controller
            name="is_recurrent"
            control={control}
            defaultValue={false}
            isInvalid={errors.is_recurrent}
            render={({ onChange, value }) => (
              <Switch onChange={onChange} checked={!!value} onColor="#2f80ed" />
            )}
          />
        </Form.Group>
      </Form>
    </div>
  )
}
