import React from 'react'
import { DesktopHeader } from '../common'
import ClientProfileBody from './components/client-profile/ClientProfileBody'
export default function ClientProfile() {
    return (
        <div className="rpm-client-profile">
            <DesktopHeader />
            <ClientProfileBody />
        </div>
    )
}

ClientProfile.propTypes = {}
ClientProfile.defaultProps = {}
