import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import useWindowSize from '../../../common/useWindowResize'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../../../user/redux/fetchUser'
import { useDeleteAssessment } from '../../redux/deleteAssessment'
import NewAssessmentModal from './NewAssessmentModal'

export default function ActivityCard({
  title = '',
  score = 0,
  subtitle = ' How sad do you feel?',
  icon = <></>,
  link = '',
  backgroundColor = '',
  color = '#a5a877',
  hideScore = false,
  ranges = [],
  recordData = {},
  params = {},
  setParams = () => {},
  isMood = false,
  notes = '',
  createdAt = '',
}) {
  const [showFaqTooltip, setShowFaqTooltip] = React.useState(false)

  const { isDesktop } = useWindowSize()

  const [tooltipShown, setTooltipShown] = React.useState(-1)
  const ref = useRef(null)
  useOutsideAlerter(ref, () => setTooltipShown(-1))

  const { deleteAssessment } = useDeleteAssessment()

  const [showEditModal, setShowEditModal] = React.useState(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  let MAX_NOTES_CHARACTERS = 200
  const [maxNotesCharacters, setMaxNotesCharacters] = React.useState(
    MAX_NOTES_CHARACTERS
  )

  const { user } = useFetchUser()

  const { t } = useTranslation('')

  return (
    <div
      className="measurement-wrapper"
      style={{
        ...(isMood &&
          score == '--' && {
            paddingTop: '5px',
          }),
        ...(notes && {
          alignItems: 'flex-start',
          paddingTop: '15px',
          height: 'auto',
        }),
      }}
    >
      <div className="rpm-client-profile__container__right-body__records__record__measurements__measurement">
        <div className="rpm-client-profile__container__right-body__records__record__measurements__measurement__left">
          <div
            style={{
              width: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </div>
          <div className="rpm-client-profile__container__right-body__records__record__measurements__measurement__left__text">
            <p className="rpm-client-profile__container__right-body__records__record__measurements__measurement__left__text__title">
              {title}{' '}
              {link && (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mrm-ml-0_5"
                >
                  <Icon name="redirect" />
                </a>
              )}
              {ranges && ranges.length > 0 && (
                <button
                  className="tooltip-wrapper"
                  type="button"
                  {...(isDesktop && {
                    onMouseEnter: () => setShowFaqTooltip(true),
                  })}
                  {...(isDesktop && {
                    onMouseLeave: () => setShowFaqTooltip(false),
                  })}
                  {...(!isDesktop && {
                    onClick: () => setShowFaqTooltip(!showFaqTooltip),
                  })}
                >
                  <Icon
                    name="faq"
                    size={12}
                    color={'#A4A2A1'}
                    style={{ zIndex: 10 }}
                  />
                  {/* (showFaqTooltip && isDesktop) || */}
                  {showFaqTooltip && isDesktop && (
                    <div className="tooltip-wrapper__tooltip">
                      <div className="tooltip-wrapper__tooltip__triangle" />
                      <h3>{title}</h3>
                      <div className="tooltip-wrapper__tooltip__border" />
                      <div className="tooltip-wrapper__tooltip__content">
                        {ranges.map(
                          ({ min, max, tag, color, bg_color }, index) => (
                            <div
                              className="tooltip-wrapper__tooltip__content__item"
                              key={index}
                            >
                              <p className="tooltip-wrapper__tooltip__content__item__tag">
                                {tag}
                              </p>

                              <div
                                className="tooltip-wrapper__tooltip__content__item__score"
                                style={{
                                  backgroundColor: bg_color,
                                }}
                              >
                                <p
                                  className="tooltip-wrapper__tooltip__content__item__score__value"
                                  style={{
                                    color,
                                  }}
                                >
                                  {min} - {max}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </button>
              )}
            </p>
            {isMood && (
              <p className="rpm-client-profile__container__right-body__records__record__measurements__measurement__left__text__subtitle">
                {subtitle}
              </p>
            )}
            {isMood && score == '--' && (
              <p
                className="rpm-client-profile__container__right-body__records__record__measurements__measurement__left__text__subtitle"
                style={{
                  color: '#A4A2A1',
                  fontStyle: 'italic',
                  fontSize: '10px',
                  paddingBottom: '5px',
                }}
              >
                Chose not to answer
              </p>
            )}
          </div>
        </div>
        {!hideScore && (
          <div className="flex items-center" style={{ gap: '1rem' }}>
            <div
              className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right"
              style={{
                backgroundColor: backgroundColor
                  ? backgroundColor
                  : hextToRgba(color, 0.25),
              }}
            >
              <p
                className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__value"
                style={{ color }}
              >
                {score}
              </p>
            </div>

            {user &&
              user.user_is_internal_coach &&
              (user.groups.includes('Coach') ||
                user.groups.includes('Admin')) && (
                <button
                  type="button"
                  className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button"
                  style={{
                    outline: 'none',
                    border: 'none',
                    backgroundColor: 'transparent',
                    position: 'relative',
                    ...(!recordData.id && {
                      opacity: 0,
                      cursor: 'default',
                    }),
                  }}
                  onClick={() => {
                    setTooltipShown(recordData.id)
                  }}
                >
                  {' '}
                  <Icon name="threedots" size={3} color={'#C4C4C4'} />
                  {tooltipShown !== -1 && (
                    <div
                      className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip"
                      ref={ref}
                      style={{
                        zIndex: 1,
                      }}
                    >
                      <div
                        className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip__item"
                        role="button"
                        onClick={() => {
                          setTooltipShown(-1)
                          setShowEditModal(true)
                        }}
                        style={{
                          borderBottom: '1px solid rgba(196, 196, 196, 0.3)',
                        }}
                      >
                        <p className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip__item__text">
                          {t('client_profile.edit')}
                        </p>
                      </div>
                      <div
                        className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip__item"
                        role="button"
                        onClick={() => {
                          setTooltipShown(-1)
                          setShowDeleteModal(true)
                        }}
                      >
                        <p
                          className="rpm-client-profile__container__right-body__records__record__measurements__measurement__right__three-dots-button__tooltip__item__text"
                          style={{
                            color: '#E8744F',
                          }}
                        >
                          {t('client_profile.remove')}
                        </p>
                      </div>
                    </div>
                  )}
                </button>
              )}
          </div>
        )}

        {!isDesktop && showFaqTooltip && (
          <Modal
            className="data-prompt-modal"
            show={showFaqTooltip}
            onHide={() => setShowFaqTooltip(false)}
          >
            {icon}
            <h3>{title}</h3>

            <div className="data-prompt-modal__content">
              {ranges.map(({ min, max, tag, color, bg_color }, index) => (
                <div className="data-prompt-modal__content__item" key={index}>
                  <p className="data-prompt-modal__content__item__tag">{tag}</p>

                  <div
                    className="data-prompt-modal__content__item__score"
                    style={{
                      backgroundColor: bg_color,
                    }}
                  >
                    <p
                      className="data-prompt-modal__content__item__score__value"
                      style={{
                        color,
                      }}
                    >
                      {min} - {max}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <button
              type="button"
              className="data-prompt-modal__close"
              onClick={() => setShowFaqTooltip(false)}
            >
              <Icon name="close" size={25} />
            </button>
          </Modal>
        )}

        <Modal
          className="delete-assessment-modal"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <h3>{t('client_profile.delete_assessment_question')}</h3>

          <p>
            {t(
              'client_profile.are_you_sure_you_want_to_delete_this_assessment_question'
            )}
          </p>
          <div className="delete-assessment-modal__warning">
            <p className="red-text">
              {t('client_profile.deleting_this_assessment')}
            </p>
          </div>

          <div className="delete-assessment-modal__buttons">
            <button
              className="delete-assessment-modal__buttons__button"
              onClick={() => {
                setShowDeleteModal(false)
              }}
            >
              {t('client_profile.cancel')}
            </button>
            <button
              className="delete-assessment-modal__buttons__button"
              onClick={() => {
                deleteAssessment({
                  id: recordData.id,
                }).then(() => {
                  setShowDeleteModal(false)
                  setParams(params => ({
                    ...params,
                    page: 1,
                    resetResults: true,
                  }))
                })
              }}
              style={{
                backgroundColor: '#E8744F',
                color: '#fff',
                border: 'none',
              }}
            >
              {t('client_profile.delete')}
            </button>
          </div>

          <button
            className="delete-assessment-modal__close"
            style={{ outline: 'none' }}
            onClick={() => setShowDeleteModal(false)}
          >
            <Icon name="close" color="#343434" size={20} />
          </button>
        </Modal>
        {showEditModal && (
          <NewAssessmentModal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            setParams={setParams}
            updateData={{
              assessment_id: recordData.assessment_type_id,
              score: recordData.score,
              assessment_result_link: recordData.assessment_result_link,
              month: recordData.date.split('-')[1],
              day: recordData.date.split('-')[2],
              year: recordData.date.split('-')[0],
              date: recordData.date,
              min_score: recordData.min_score,
              max_score: recordData.max_score,
              id: recordData.id,
            }}
            showDeleteModal={() => setShowDeleteModal(true)}
            userId={recordData.userId}
          />
        )}
      </div>
      {isMood && notes && (
        <div className="measurement-wrapper__mood-text">
          <p className="hour">{createdAt}</p>
          <p className="description">
            <span className="text">
              {notes && notes.slice(0, maxNotesCharacters)}
              {notes && notes.length > maxNotesCharacters && '...'}
            </span>
            {notes && notes.length > MAX_NOTES_CHARACTERS && (
              <strong
                role="button"
                onClick={() => {
                  if (maxNotesCharacters === MAX_NOTES_CHARACTERS)
                    setMaxNotesCharacters(Infinity)
                  else setMaxNotesCharacters(MAX_NOTES_CHARACTERS)
                }}
              >
                [Read{' '}
                {maxNotesCharacters === MAX_NOTES_CHARACTERS ? 'more' : 'less'}]
              </strong>
            )}
          </p>
        </div>
      )}
    </div>
  )
}

const hextToRgba = (hex, opacity) => {

  // if not hex return a grey
  if (!hex) return `rgba(0,0,0,0.25)`

  const values = hex
    .slice(1)
    .split(/(..)/)
    .filter(c => c)
    .map(c => parseInt(c, 16))
  return `rgba(${values.join(',')} , ${opacity})`
}
