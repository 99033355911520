import * as Yup from 'yup'

export const SignupSchema = input_alerts =>
  Yup.object().shape({
    first_name: Yup.string().required(input_alerts.first_name_required),
    last_name: Yup.string().required(input_alerts.last_name_required),
    email: Yup.string()
      .email(input_alerts.invalid_email)
      .required(input_alerts.email_required),
    confirm_email: Yup.string()
      .email(input_alerts.invalid_email)
      .oneOf([Yup.ref('email'), null], input_alerts.email_not_match)
      .required(input_alerts.confirm_email_required),
    password: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
        input_alerts.password_format
      )
      .required(input_alerts.password_format),
    password2: Yup.string()
      .oneOf([Yup.ref('password'), null], input_alerts.password_not_match)
      .required(input_alerts.confirm_password_required),
    phone_number: Yup.string()
      .required(input_alerts.phone_required)
      .matches(
        /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{8,14}$/,
        input_alerts.phone_format
      ),
    terms: Yup.boolean()
      .oneOf([true], input_alerts.accept_terms)
      .required(input_alerts.accept_terms),
  })
