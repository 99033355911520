import React, { useContext } from 'react';
import { CompetencyContext } from '../../CompetencyPage';

export default function SupplementalInfo() {
  const { selectedCompetency, editorRef } = useContext(CompetencyContext);
  return (
    <React.Fragment>
      {selectedCompetency.content && (
        <>
          <h2>Supplemental Info</h2>
          <div
            className="embed theme-text-secondary"
            ref={editorRef}
            dangerouslySetInnerHTML={{
              __html: selectedCompetency.content.replace(
                /href/g,
                `target="_blank" href`,
              ),
            }}
          />
        </>
      )}
    </React.Fragment>
  );
}
