import React from 'react'

export default function AgreementsCheckbox({ isCoach = false, ...props }) {
    const { agreement, onChange } = props

    return (
        <div className="agreements-checkbox-wrapper">
            <div
                className="agreements-checkbox"
                style={{
                    ...props.style,
                }}
            >
                <div>
                    <input
                        className="checkbox"
                        type="checkbox"
                        id="agreement"
                        checked={agreement}
                        onChange={onChange}
                    />
                </div>

                <p className="agreement-text">
                    I have read and agree to:{' '}
                    <a
                        href="https://noble.health/consent"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Consent Documents{' '}
                    </a>{' '}
                </p>
            </div>
        </div>
    )
}
