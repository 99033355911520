import React, { Fragment, useEffect } from 'react'
import Icon from '../../../../common/components/Icon'
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-light-svg-icons'
export default function UphealInformation({ user }) {
    const { t } = useTranslation()

    return (
        <Fragment>
            <div className="profile-details-section__header mrm-mt-2">
                <p className="profile-details-section__header__title">
                    {t('client_profile.upheal.title')}
                </p>
                <p
                    className="profile-details-section__header__edit"
                    role="button"
                ></p>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="user" size={16} color="#595959" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            {t('client_profile.upheal.upheal_user_id')}
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {user.upheal_information?.user_id || '-'}
                    </p>
                </div>
            </div>
            {user.groups && user.groups.includes('Coach') ? (
                <div className="profile-details-section__body">
                    <div className="profile-details-section__body__row">
                        <div className="profile-details-section__body__row__label">
                            <div className="profile-details-section__body__row__label__icon">
                                <FontAwesomeIcon icon={faLink} size="lg" />
                            </div>
                            <p className="profile-details-section__body__row__label__title">
                                {t('client_profile.upheal.coach_meeting_room')}
                            </p>
                        </div>

                        <p className="profile-details-section__body__row__value">
                            {user.upheal_information?.private_room_link || '-'}
                        </p>
                    </div>
                </div>
            ) : (
                <div className="profile-details-section__body">
                    <div className="profile-details-section__body__row">
                        <div className="profile-details-section__body__row__label">
                            <div className="profile-details-section__body__row__label__icon">
                                <FontAwesomeIcon icon={faLink} size="lg" />
                            </div>
                            <p className="profile-details-section__body__row__label__title">
                                {t('client_profile.upheal.client_session_url')}
                            </p>
                        </div>

                        <p className="profile-details-section__body__row__value">
                            {user.upheal_information?.client_session_url || '-'}
                        </p>
                    </div>
                </div>
            )}
        </Fragment>
    )
}
