import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { useFetchAssignedUsers } from '../../../../dashboard/redux/fetchAssignedUsers';
import RadioInput from '../customInputs/RadioInput';
import StepHeader from '../StepHeader';
import { ReactSelectStyles } from '../utils/ReactSelectStyles';
import ValueContainer from '../ValueContainer';
import { SupportContext } from '../../../Dashboard';
import { AssignCoachSchema } from '../yup/AssignCoachSchema';
import StepsController from '../../StepsController';

export default function AssignCoachForm() {
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const stateRef = useRef(selectedCoaches);
  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers();
  const { setFieldValue, errors, touched, values } = useFormikContext();
  const { steps, setSteps, stepValues, setSelectedCoach } =
    useContext(SupportContext);

  useEffect(() => {
    stateRef.current = selectedCoaches;
  }, [selectedCoaches]);

  const multiselectList = {
    assignedUsers: assignedUsers
      ? assignedUsers.results.map((item) => ({
          id: item.id,
          full_name: `${item.first_name} ${item.last_name}`,
        }))
      : null,
  };

  const loadOptions = (inputValue) => {
    return fetchAssignedUsers({
      search: inputValue,
      is_internal_coach: true,
    })
      .then((res) => {
        console.log('internal coaches', res);
        return res.results.map((user) => ({
          value: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (inputValue) => {
    if (multiselectList.assignedUsers) {
      setSelectedCoaches(inputValue);
      setSelectedCoach(inputValue);
      setFieldValue(
        'coach_write_only',
        inputValue?.reduce((acc, item) => {
          acc.push(item.id);
          return acc;
        }, []),
      );
    }
  };

  return (
    <React.Fragment>
      <StepHeader
        step={2}
        title="Assign a coach to your client"
        onClick={() => {
          StepsController(2, AssignCoachSchema, setSteps, steps, stepValues);
        }}
        className={`${steps[4].state === 'active' && 'd-none'}`}
      />
      <div
        className={`support-assign-coach-form ${
          steps[2].state === 'active' ? '' : 'd-none'
        }`}
      >
        <div className="support-input-container">
          <AsyncSelect
            id="coach_write_only"
            isMulti
            placeholder="Assign a coach to your client"
            value={selectedCoaches}
            getOptionValue={(option) => option.id}
            defaultOptions
            loadOptions={loadOptions}
            onChange={handleChange}
            styles={{
              ...ReactSelectStyles,
              valueContainer: (base) => ({
                ...ReactSelectStyles.valueContainer(base),
                height: '2.7rem',
              }),
              control: (base) => ({
                ...ReactSelectStyles.control(base),
                borderRadius: '0.8rem',
              }),
            }}
            icon="user"
            components={{
              ValueContainer,
            }}
          />
        </div>
        {errors.coach_write_only && touched.coach_write_only && (
          <div className="error">{errors.coach_write_only}</div>
        )}
        <RadioInput
          onClick={() => {
            setFieldValue('coach_message_alert', !values.coach_message_alert);
          }}
          active={values.coach_message_alert}
          label="Send Text message Alert to Coach"
        />
      </div>
    </React.Fragment>
  );
}
