import React from 'react';
import InactiveIllustration from './assets/inactive-illustration.svg';

export default function Inactive() {
  return (
    <div className="inactive-messaging-banner">
      <h1>Messages</h1>

      <div className="content">
        <img src={InactiveIllustration} alt="Inactive Illustration" />
        <h2>This feature is not included in your current plan</h2>
        <p>
          Contact us to upgrade your plan and get reconnect with one of our
          mental health professionals.
        </p>

        <a
          href="mailto:hello@noble.health"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
}
