import React from 'react';

const Pill = ({
  image,
  color,
  textColor = '#fff',
  descriptionColor = '#fff',
  text1,
  text2,
}) => (
  <div
    className="auth-home-illustration-container"
    style={{
      backgroundColor: color,
    }}
  >
    <div
      className="roadmap-illustration"
      style={{
        backgroundImage: `url(${image})`,
      }}
    />
    <div className="text-container">
      <p className="main" style={{ color: textColor }}>
        {text1}
      </p>
      <p style={{ color: descriptionColor }}>
        {text2}
      </p>
    </div>
  </div>
);

export default Pill;
