import React, { useEffect, useLayoutEffect } from 'react'

export default function ProgressWheel({
    progress = 0,
    className = 'rpm-dashboard',
    days = 0,

}) {
    useLayoutEffect(() => {
        // --rotation-rpm css variable should be set to progress
        // .rpm-dashboard is the parent of --rotation-rpm css variable
        document
            .querySelector(`.${className}`)
            .style.setProperty('--rotation-rpm', (progress * 3.6) / 2 + 'deg')
    }, [progress, className])

    return (
        <div className="circle-wrap">
            <div className="circle">
                <div className="mask full">
                    <div className="fill"></div>
                </div>
                <div className="mask half">
                    <div className="fill"></div>
                </div>
                <div className="inside-circle">{days} / 16</div>
            </div>
        </div>
    )
}
