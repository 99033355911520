// This page is a nightmare to read.

import { faCheck, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import Icon from '../../../common/components/Icon';
import { useFetchUpdates } from '../../../common/redux/fetchUpdates';
import useQuery from '../../../common/useQuery';
import useWindowResize from '../../../common/useWindowResize';
import { useFetchAssignedUsers } from '../../../dashboard/redux/fetchAssignedUsers';
import { useFetchUser } from '../../../user/redux/fetchUser';
import { CompetencyContext } from '../../CompetencyPage';
import { useAddActionItemAttachment } from '../../redux/addActionItemAttachment';
import { useDeleteActionItemAttachment } from '../../redux/deleteActionItemAttachment';
import { useFetchCompetencyActionItemAssessments } from '../../redux/fetchCompetencyActionItemAssessments';
import { useSetActionItemAssessment } from '../../redux/setActionItemAssessment';
import { useSetActionItemDetails } from '../../redux/setActionItemDetails';
import AttachmentModal, {
  AttachmentList,
  UploadProgressBar,
  UPLOAD_STATUS,
  useUploadStatus,
} from '../ActionItemEditing/Attachment';
import ScreenCapture from '../ScreenCapture';
import ActionButton from './ActionButton';
import { useReplaceBadLinks } from './hooks/useReplaceBadLinks';

export default function ActionItemInfo({ show, onHide }) {
  const [showScreenCapture, setShowScreenCapture] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [actionItemId, setActionItemId] = useState(null);

  const history = useHistory();
  const fileEl = useRef({});

  const { showActionItemContainer, actionItemId: id } =
    useContext(CompetencyContext);

  const { roadmapId, stageId, competencyId } = showActionItemContainer;

  useEffect(() => {
    if (id) {
      setActionItemId(id);
    } else {
      setActionItemId(showActionItemContainer.actionItemId);
      console.log('showActionItemContainer', showActionItemContainer);
    }
  }, [id, showActionItemContainer]);

  const { user } = useFetchUser();
  const [student, setStudent] = useState(null);

  const {
    addActionItemAttachment,
    addActionItemAttachmentPending,
    addActionItemAttachmentReset,
    addActionItemAttachmentProgress,
    addActionItemAttachmentError,
  } = useAddActionItemAttachment();

  const { setActionItemAssessment, setActionItemAssessmentPending } =
    useSetActionItemAssessment();
  const { actionItems, fetchCompetencyActionItemAssessments } =
    useFetchCompetencyActionItemAssessments();

  const { deleteActionItemAttachment } = useDeleteActionItemAttachment();
  const { setActionItemDetails } = useSetActionItemDetails();
  const { fetchAssignedUsers } = useFetchAssignedUsers();
  const { fetchUpdates } = useFetchUpdates();

  const query = useQuery();
  const studentId = query && Number(query.get('user'));

  useEffect(() => {
    if (!!studentId) {
      fetchAssignedUsers({ userId: studentId }).then((res) =>
        setStudent(res.results[0]),
      );
    }
  }, [fetchAssignedUsers, studentId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const editorRef = useRef(null);

  useReplaceBadLinks({ editorRef });

  const updateActionItem = useCallback(
    (data) => {
      return setActionItemDetails({
        roadmapId,
        stageId,
        competencyId,
        actionItemId,
        data,
      }).catch(() => {});
    },
    [roadmapId, stageId, competencyId, actionItemId, setActionItemDetails],
  );

  useEffect(() => {
    if (!actionItemId) {
      return;
    }

    fetchCompetencyActionItemAssessments({
      roadmapId,
      stageId,
      competencyId,
      actionItemId,
      attachment: true,
      student: studentId,
    }).catch(() => {});
  }, [actionItemId, history, studentId]);

  const windowSize = useWindowResize();

  const selectedActionItem = actionItems[actionItemId];

  const sortedResolutions = useMemo(() => {
    if (!selectedActionItem) return [];

    return resolutions?.filter(
      ([res]) => selectedActionItem.resolutions.indexOf(res) !== -1,
    );
  }, [selectedActionItem]);

  const handleAddFileClick = useCallback(
    (type) => () => {
      fileEl.current.attachType = type;
      fileEl.current.click();
    },
    [],
  );

  const handleFileChange = useCallback(() => {
    addActionItemAttachmentReset();
    setAttachment({
      type: fileEl.current.attachType,
      path: fileEl.current.files[0].name,
    });
  }, [addActionItemAttachmentReset]);

  const handleAttachConfirmHide = useCallback(() => {
    fileEl.current.value = null;
    setAttachment(null);
  }, []);

  const handleAttachmentDelete = useCallback(
    (attachmentId) =>
      deleteActionItemAttachment({
        roadmapId,
        stageId,
        competencyId,
        actionItemId,
        attachmentId,
      }),
    [
      actionItemId,
      competencyId,
      roadmapId,
      stageId,
      deleteActionItemAttachment,
    ],
  );

  const addActionItemAttachmentFromData = useCallback(
    (data) => {
      return addActionItemAttachment({
        roadmapId,
        stageId,
        competencyId,
        actionItemId,
        data,
      });
    },
    [roadmapId, stageId, competencyId, actionItemId, addActionItemAttachment],
  );

  const _location = useLocation();
  const handleConfirmUpload = useCallback(() => {
    const category = {
      attach_screen_recording: 'SCREEN',
      attach_audio_recording: 'AUDIO',
      attach_file: 'ATTACHMENT',
    };
    const data = new FormData();
    data.append('attachment', fileEl.current.files[0]);
    data.append('file_category', category[attachment.type]);
    let userId = user.id;
    // If Coach or Admin user, get student user from url params
    if (user.groups.includes('Coach') || user.groups.includes('Admin')) {
      const params = new URLSearchParams(_location.search);
      const _userId = params.get('user') && Number(params.get('user'));
      if (_userId) userId = _userId;
      data.append('user', userId);
    }
    addActionItemAttachmentFromData(data);
  }, [addActionItemAttachmentFromData, attachment, user, _location]);

  const [approvedButtonText, setApprovedButtonText] = useState(
    selectedActionItem?.approved_done,
  );

  const handleApproveActionItem = (approve) => () => {
    const args = { roadmapId, stageId, competencyId, actionItemId, approve };
    setActionItemAssessment(args).then(() => {
      fetchUpdates();
      setApprovedButtonText(!approvedButtonText);
      console.log(approvedButtonText);
    });
  };

  const attachmentUploadStatus = useUploadStatus(
    addActionItemAttachmentPending,
    addActionItemAttachmentProgress,
    addActionItemAttachmentError,
  );

  return (
    <>
      {show && (
        <div
          className="action-item-modal"
          dialogClassName="action-item-modal-width"
        >
          <div className="action-item-body">
            <div className="title-header-wrapper">
              <div className="title-header">
                <div className="go-back" onClick={onHide}>
                  <Icon name="chevronLeft" size={13} />
                </div>
                <h1>
                  {selectedActionItem?.title
                    ? selectedActionItem?.title
                    : selectedActionItem?.description}
                </h1>
              </div>
            {/*   {user &&
                user.groups.includes('Coach') &&
                selectedActionItem &&
                selectedActionItem?.marked_done &&
                selectedActionItem?.resolutions.includes(
                  'requires_approval',
                ) && (
                  <div className="text-right">
                    <Button
                      className="approve-unapprove-btn"
                      variant={windowSize.width > 991 ? 'gray' : 'white'}
                      onClick={handleApproveActionItem(!approvedButtonText)}
                      disabled={setActionItemAssessmentPending}
                    >
                      {setActionItemAssessmentPending ? (
                        <FontAwesomeIcon
                          icon={faSpinnerThird}
                          className="mrm-mr-0_25"
                          size="xs"
                          spin
                        />
                      ) : (
                        !approvedButtonText && (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="mrm-mr-0_25"
                            size="xs"
                          />
                        )
                      )}
                      {approvedButtonText ? 'Unapprove' : 'Approve'}
                    </Button>
                  </div>
                )} */}
            </div>

            <Container className="primary-content normal-width-container">
              <Row>
                <Col className="ai-description theme-text">
                  <p
                    ref={editorRef}
                    dangerouslySetInnerHTML={{
                      __html: selectedActionItem?.effective_description.replace(
                        /href/g,
                        `target="_blank" href`,
                      ),
                    }}
                  />
                </Col>
              </Row>
              {selectedActionItem?.effective_description && (
                <div className="border mrm-mt-0_25 mrm-my-1" />
              )}

              {showScreenCapture ? (
                <ScreenCapture
                  addActionItemAttachmentFromData={
                    addActionItemAttachmentFromData
                  }
                  setShowScreenCapture={setShowScreenCapture}
                >
                  {(attachmentUploadStatus === UPLOAD_STATUS.uploading ||
                    attachmentUploadStatus === UPLOAD_STATUS.saving) && (
                    <UploadProgressBar
                      progress={addActionItemAttachmentProgress}
                      error={addActionItemAttachmentError}
                    />
                  )}
                </ScreenCapture>
              ) : (
                <>
                  <AttachmentList
                    data={selectedActionItem?.attachments}
                    onDelete={handleAttachmentDelete}
                    showBorder
                    user={user}
                  />
                  <Row className="mrm-pb-0_5 mrm-mt-1 justify-content-center buttons">
                    {sortedResolutions.map(([r, text]) => (
                      <Col
                        key={r}
                        className={clsx('col-auto action-button mrm-mb-1', {
                          'd-none':
                            student &&
                            studentId !== user.id &&
                            r === 'input_text',
                        })}
                      >
                        {actionButtonResolutions.indexOf(r) !== -1 ? (
                          <ActionButton
                            resolution={r}
                            markedDone={selectedActionItem.marked_done}
                            updateActionItem={updateActionItem}
                            setShowScreenCapture={setShowScreenCapture}
                          />
                        ) : (
                          <Button
                            variant={windowSize.width > 991 ? 'gray' : 'white'}
                            onClick={handleAddFileClick(r)}
                            className="w-100"
                          >
                            {text}
                          </Button>
                        )}
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              <input
                type="file"
                ref={fileEl}
                onChange={handleFileChange}
                hidden
              />

              <AttachmentModal
                show={!!attachment}
                data={attachment}
                onConfirm={handleConfirmUpload}
                onAnotherFile={handleAddFileClick(
                  attachment && attachment.type,
                )}
                pending={addActionItemAttachmentPending}
                progress={addActionItemAttachmentProgress}
                error={addActionItemAttachmentError}
                onHide={handleAttachConfirmHide}
              />
            </Container>
          </div>
        </div>
      )}
    </>
  );
}

const resolutions = [
  ['attach_screen_recording', 'Add Screen Recording'],
  ['attach_audio_recording', 'Add Audio Recording'],
  ['attach_file', 'Add File'],
  ['input_text', '_'],
  ['mark_complete', '_'],
];

const actionButtonResolutions = [
  'mark_complete',
  'input_text',
  'attach_screen_recording',
];
