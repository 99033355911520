import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_LIST_DEVICES_BEGIN,
  RPM_LIST_DEVICES_SUCCESS,
  RPM_LIST_DEVICES_FAILURE,
  RPM_LIST_DEVICES_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import axios from 'axios'

export function listDevices(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_LIST_DEVICES_BEGIN,
    });


    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(config.apiRootUrl + '/rpm/list-devices', {
        params: args,
        ...createAxiosConfigWithAuth(getState()),
      })
      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_LIST_DEVICES_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_LIST_DEVICES_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissListDevicesError() {
  return {
    type: RPM_LIST_DEVICES_DISMISS_ERROR,
  };
}

export function useListDevices() {
  const dispatch = useDispatch();

  const { listDevicesPending, listDevicesError, devices } = useSelector(
    state => ({
      devices: state.rpm.devices,
      listDevicesPending: state.rpm.listDevicesPending,
      listDevicesError: state.rpm.listDevicesError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(listDevices(...args));
  }, [dispatch]);

  useEffect(() => {
    boundAction()
  }, [boundAction])

  const boundDismissError = useCallback(() => {
    return dispatch(dismissListDevicesError());
  }, [dispatch]);

  return {
    devices,
    listDevices: boundAction,
    listDevicesPending,
    listDevicesError,
    dismissListDevicesError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_LIST_DEVICES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        listDevicesPending: true,
        listDevicesError: null,
      };

    case RPM_LIST_DEVICES_SUCCESS:
      // The request is success
      return {
        ...state,
        listDevicesPending: false,
        listDevicesError: null,
        devices: action.data,
      };

    case RPM_LIST_DEVICES_FAILURE:
      // The request is failed
      return {
        ...state,
        listDevicesPending: false,
        listDevicesError: action.data.error,
      };

    case RPM_LIST_DEVICES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        listDevicesError: null,
      };

    default:
      return state;
  }
}
