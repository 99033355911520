import React, { Fragment, useEffect, useState } from 'react'
import { useFetchRecipients } from '../../redux/fetchRecipients'
import { useFetchUser } from '../../../user/redux/fetchUser'
import AddMoreParticipants from './sender/AddMoreParticipants'
import CreateConversation from './sender/CreateConversation'
import EditingGroup from './sender/EditingGroup'
import SendMessage from './sender/SendMessage'
import debounce from 'lodash/debounce'

export default function Sender({ ...props }) {
  const {
    // send message
    selectedMessages,
    setSelectedMessages,
    setSelectedConversation,
    selectedConversation,

    // create new conversation
    createNewConversation,
    setCreateNewConversation,

    // editing group
    editingGroup,
    editGroup,
  } = props

  const [search, setSearch] = useState('')

  const { recipients, fetchRecipients } = useFetchRecipients()
  const { user } = useFetchUser()

  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [namingGroup, setNamingGroup] = useState(false)

  const [addMoreParticipants, setAddMoreParticipants] = useState(false)

  useEffect(() => {
    debounce(() => {
      fetchRecipients({ search: search })
    }, 500)()
  }, [fetchRecipients, search])

  return (
    <div
      className="sender"
      style={{
        ...(createNewConversation && { height: '100%', borderRadius: 0 }),
      }}
    >
      {!createNewConversation && !editingGroup && (
        <SendMessage
          selectedMessages={selectedMessages}
          setSelectedMessages={setSelectedMessages}
          selectedConversation={selectedConversation}
        />
      )}

      {createNewConversation && user && (
        <CreateConversation
          setCreateNewConversation={setCreateNewConversation}
          selectedConversation={selectedConversation}
          setSelectedConversation={setSelectedConversation}
          namingGroup={namingGroup}
          setNamingGroup={setNamingGroup}
          setSelectedParticipants={setSelectedParticipants}
          selectedParticipants={selectedParticipants}
          search={search}
          setSearch={setSearch}
          user={user}
          recipients={recipients}
        />
      )}

      {editingGroup && (
        <Fragment>
          {!addMoreParticipants && user && (
            <EditingGroup
              setAddMoreParticipants={setAddMoreParticipants}
              setSelectedParticipants={setSelectedParticipants}
              selectedConversation={selectedConversation}
              editGroup={editGroup}
              user={user}
            />
          )}

          {addMoreParticipants && user && (
            <AddMoreParticipants
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
              setAddMoreParticipants={setAddMoreParticipants}
              editGroup={editGroup}
              addMoreParticipants={addMoreParticipants}
              search={search}
              setSearch={setSearch}
              recipients={recipients}
              user={user}
            />
          )}
        </Fragment>
      )}
    </div>
  )
}
