import React from 'react';

export default function FilterIcon({
  className,
  size = 7,
  color = '#343434',
  style = {},
}) {
  return (
    <svg
      width={size * 1.85}
      height={size}
      className={className}
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9_9765)">
        <path
          d="M12.3411 1.27698H0.658895C0.294306 1.27698 0 0.991791 0 0.638492C0 0.285193 0.294306 0 0.658895 0H12.3411C12.7057 0 13 0.285193 13 0.638492C13 0.991791 12.7057 1.27698 12.3411 1.27698Z"
          fill={color}
        />
        <path
          d="M10.3864 4.13734H2.61361C2.24902 4.13734 1.95471 3.85214 1.95471 3.49884C1.95471 3.14554 2.24902 2.86035 2.61361 2.86035H10.3864C10.751 2.86035 11.0453 3.14554 11.0453 3.49884C11.0453 3.85214 10.751 4.13734 10.3864 4.13734Z"
          fill={color}
        />
        <path
          d="M8.74134 7.00013H4.25866C3.89407 7.00013 3.59976 6.71494 3.59976 6.36164C3.59976 6.00834 3.89407 5.72314 4.25866 5.72314H8.74134C9.10593 5.72314 9.40024 6.00834 9.40024 6.36164C9.40024 6.71494 9.10593 7.00013 8.74134 7.00013Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9_9765">
          <rect width={size * 1.85} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
