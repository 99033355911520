// This function is used to get the relative time from the created_at field from the backend (DJANGO)

const getRelativeDay = (created_at) => {
  const date = new Date(created_at);
  const now = new Date();
  const diff = Math.floor((now - date) / 1000);
  const day = Math.floor(diff / 86400);
  const hour = Math.floor(diff / 3600);
  const minute = Math.floor(diff / 60);
  const second = diff;

  if (day > 30) {
    return date.toLocaleDateString({
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  if (day > 0) {
    return `${day}d ago`;
  } else if (hour > 0) {
    return `${hour}h ago`;
  } else if (minute > 0) {
    return `${minute}m ago`;
  } else {
    return `${second}s ago`;
  }
};

export default getRelativeDay;
