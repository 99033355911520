import debounce from 'lodash/debounce'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useHistory } from 'react-router-dom'
import { Loader, UserAvatar } from '../../../common'
import Icon from '../../../common/components/Icon'
import LastSeen from '../../../common/components/LastSeen'
import { useFetchUser } from '../../../user/redux/fetchUser'
import InvitationModal from '../InvitationModal'
import FilterToast from './filter/FilterToast'
import { useGetAssignedUsers } from './hooks/useGetAssignedUsers'
import ProgressBar from './ProgressBar'
import SortToast from './sort/SortToast'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useOpenPeerConversation } from '../../../common/hooks/useOpenConversation'

export const ClientsCardContext = createContext()

export default function ClientsCard() {
    const { user: coach, fetchUser } = useFetchUser() // coach is the one seeing his clients
    const { openPeerConversation } = useOpenPeerConversation()
    useEffect(() => {
        if (coach) return
        fetchUser()
    }, [fetchUser])

    const history = useHistory()
    const [showModal, setShowModal] = useState(false)

    const {
        assignedUsersState,
        setAssignedUsersState,
        fetchMoreUsers,
    } = useGetAssignedUsers()

    const [showFilterToast, setShowFilterToast] = useState(false)
    const [showSortToast, setShowSortToast] = useState(false)

    useEffect(() => {
        if (showFilterToast) setShowSortToast(false)
    }, [showFilterToast])

    useEffect(() => {
        if (showSortToast) setShowFilterToast(false)
    }, [showSortToast])

    const searchHandler = useCallback(
        debounce(search => {
            setAssignedUsersState(prevState => ({
                ...prevState,
                search,
            }))
        }, 500),
        [setAssignedUsersState]
    )

    const { t } = useTranslation()

    if (assignedUsersState.loading || !coach)
        return <div className="clients-card-container container-skeleton" />

    if (assignedUsersState.error) return null

    return (
        <ClientsCardContext.Provider
            value={{
                assignedUsersState,
                setAssignedUsersState,
                showSortToast,
                setShowSortToast,
                showFilterToast,
                setShowFilterToast,
            }}
        >
            <div className="clients-card-container">
                <div className="w-full space-between mrm-pb-1 flex clients-card-header">
                    <h3>
                        {coach && coach.company_is_rtm ? (
                            <>{t('dashboard.clients.my_patients')}</>
                        ) : (
                            <>{t('dashboard.clients.title')} </>
                        )}
                        {assignedUsersState.count && (
                            <strong>({assignedUsersState.count})</strong>
                        )}
                    </h3>
                    <button
                        className="add-client"
                        onClick={() => setShowModal(true)}
                        type="button"
                    >
                        <Icon name="plus" size={13} color="#fff" />
                    </button>
                </div>
                <div className="filters-wrapper">
                    <div className="search-wrapper">
                        <input
                            type="text"
                            onChange={e => searchHandler(e.target.value)}
                            placeholder={t('dashboard.clients.search')}
                            className="search-input"
                        />
                        <Icon name="search" className="search-icon" />
                    </div>
                    <FilterToast />
                    <SortToast />
                </div>

                <div className="user-card-column">
                    {assignedUsersState.assignedUsers &&
                        assignedUsersState.assignedUsers.length > 0 && (
                            <InfiniteScroll
                                className="w-full"
                                pageStart={0}
                                loadMore={fetchMoreUsers}
                                hasMore={assignedUsersState.hasMore}
                                loader={
                                    <div className="user-card-skeleton skeleton" />
                                }
                                useWindow={false}
                            >
                                {assignedUsersState.assignedUsers.map(
                                    (user, idx) => (
                                        <div
                                            key={user.id}
                                            className="user-card"
                                            style={{
                                                marginTop:
                                                    idx === 0 ? 0 : '1rem',
                                            }}
                                        >
                                            <div className="clients-card-subscription-and-last-login">
                                                <div className="clients-card-subscription">
                                                    <p className="subscription-tag">
                                                        <div className="circle" />
                                                        {user.subscription
                                                            ? user.subscription
                                                                  .status
                                                            : 'No subscription'}
                                                    </p>
                                                </div>

                                                <div className="clients-card-last-login">
                                                    <p className="title">
                                                        {t(
                                                            'dashboard.clients.filters.last_ai'
                                                        )}
                                                    </p>
                                                    <p className="last-ai">
                                                        <LastSeen
                                                            lastSeen={
                                                                user.recent_action_item_done
                                                            }
                                                        />
                                                    </p>
                                                    <p className="title">
                                                        {t(
                                                            'dashboard.clients.filters.last_login'
                                                        )}
                                                    </p>
                                                    <p className="last-seen">
                                                        <LastSeen
                                                            lastSeen={
                                                                user.last_seen
                                                            }
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <hr className="w-full mrm-mb-1" />

                                            <div className="user-card-header">
                                                <div
                                                    className="avatar-and-name"
                                                    onClick={() => {
                                                        history.push(
                                                            `/manage/user/${user.id}`
                                                        )
                                                    }}
                                                >
                                                    <UserAvatar user={user} />
                                                    <div className="flex flex-col">
                                                        <p>
                                                            {user.first_name}{' '}
                                                            {user.last_name}
                                                        </p>
                                                        <p className="email">
                                                            {user.email}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="last-seen-and-message">
                                                    <button
                                                        onClick={() => {
                                                            openPeerConversation(
                                                                {
                                                                    first_name:
                                                                        user.first_name,
                                                                    last_name:
                                                                        user.last_name,
                                                                    id: user.id,
                                                                    username:
                                                                        user.username,
                                                                    photo:
                                                                        user.photo,
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        <Icon
                                                            name="message"
                                                            size={18}
                                                            color="#6D786E"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                            {user.roadmaps_info &&
                                                user.roadmaps_info.length >
                                                    0 && (
                                                    <hr
                                                        className="w-full mrm-m-1"
                                                        style={{
                                                            width:
                                                                'calc(100% - 1.75rem)',
                                                        }}
                                                    />
                                                )}
                                            {user.roadmaps_info &&
                                                user.roadmaps_info.length >
                                                    0 && (
                                                    <div className="user-card-roadmaps-container">
                                                        {user.roadmaps_info.map(
                                                            roadmap => (
                                                                <div
                                                                    className="user-card-roadmaps"
                                                                    key={
                                                                        roadmap.id
                                                                    }
                                                                    onClick={() =>
                                                                        history.push(
                                                                            `/roadmap/${roadmap.id}/?user=${user.id}`
                                                                        )
                                                                    }
                                                                >
                                                                    <p className="roadmap-title">
                                                                        {
                                                                            roadmap.title
                                                                        }
                                                                    </p>
                                                                    <p className="roadmap-description">
                                                                        {roadmap.competency_title ||
                                                                            ' '}
                                                                    </p>
                                                                    <ProgressBar
                                                                        percentage={
                                                                            roadmap.progress
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    )
                                )}
                            </InfiniteScroll>
                        )}

                    {!assignedUsersState.assignedUsers &&
                        !assignedUsersState.assignedUsers?.length > 0 && (
                            <Loader position="static" animation="border" />
                        )}
                </div>
                <InvitationModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                />
            </div>
        </ClientsCardContext.Provider>
    )
}
