export const NOTIFICATIONS_FETCH_NOTIFICATIONS_BEGIN =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_BEGIN'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_FAILURE =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_FAILURE'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_DISMISS_ERROR =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_DISMISS_ERROR'
export const NOTIFICATIONS_MARK_NOTIFICATION_READ_BEGIN =
    'NOTIFICATIONS_MARK_NOTIFICATION_READ_BEGIN'
export const NOTIFICATIONS_MARK_NOTIFICATION_READ_SUCCESS =
    'NOTIFICATIONS_MARK_NOTIFICATION_READ_SUCCESS'
export const NOTIFICATIONS_MARK_NOTIFICATION_READ_FAILURE =
    'NOTIFICATIONS_MARK_NOTIFICATION_READ_FAILURE'
export const NOTIFICATIONS_MARK_NOTIFICATION_READ_DISMISS_ERROR =
    'NOTIFICATIONS_MARK_NOTIFICATION_READ_DISMISS_ERROR'
export const NOTIFICATIONS_MARK_ALL_READ_BEGIN =
    'NOTIFICATIONS_MARK_ALL_READ_BEGIN'
export const NOTIFICATIONS_MARK_ALL_READ_SUCCESS =
    'NOTIFICATIONS_MARK_ALL_READ_SUCCESS'
export const NOTIFICATIONS_MARK_ALL_READ_FAILURE =
    'NOTIFICATIONS_MARK_ALL_READ_FAILURE'
export const NOTIFICATIONS_MARK_ALL_READ_DISMISS_ERROR =
    'NOTIFICATIONS_MARK_ALL_READ_DISMISS_ERROR'
export const NOTIFICATIONS_MARK_COMMENTS_READ_BEGIN =
    'NOTIFICATIONS_MARK_COMMENTS_READ_BEGIN'
export const NOTIFICATIONS_MARK_COMMENTS_READ_SUCCESS =
    'NOTIFICATIONS_MARK_COMMENTS_READ_SUCCESS'
export const NOTIFICATIONS_MARK_COMMENTS_READ_FAILURE =
    'NOTIFICATIONS_MARK_COMMENTS_READ_FAILURE'
export const NOTIFICATIONS_MARK_COMMENTS_READ_DISMISS_ERROR =
    'NOTIFICATIONS_MARK_COMMENTS_READ_DISMISS_ERROR'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_BEGIN =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_BEGIN'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_FAILURE =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_FAILURE'
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR =
    'NOTIFICATIONS_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR'
export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN =
    'NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN'
export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS =
    'NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS'
export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE =
    'NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE'
export const NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR =
    'NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR'
