import React from 'react'

export default function ProviderIcon({
    size = 14,
    color = '#595959',
    ...props
}) {
    return (
        <svg
            width={size}
            height={size * 1.2857142857142858}
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.64075 17.3365H0.640837C0.470876 17.3365 0.307877 17.2692 0.187697 17.1495C0.0675166 17.0297 0 16.8673 0 16.698V0.638454C0 0.469125 0.0675166 0.306732 0.187697 0.186999C0.307877 0.0672655 0.470876 0 0.640837 0H13.2998C13.4698 0 13.6328 0.0672655 13.753 0.186999C13.8732 0.306732 13.9407 0.469125 13.9407 0.638454V11.06H12.659V1.23762H1.28167V16.0694H7.64075V17.3365Z"
                fill={color}
            />
            <path
                d="M7.61371 17.3364C7.52897 17.3343 7.44523 17.3176 7.36621 17.2869C7.2497 17.237 7.15023 17.1542 7.07992 17.0487C7.00961 16.9432 6.97149 16.8196 6.97021 16.6928V11.0097C6.97021 10.839 7.03801 10.6753 7.15869 10.5546C7.27937 10.4339 7.44305 10.3661 7.61371 10.3661H13.2963C13.4237 10.3659 13.5482 10.4036 13.6542 10.4743C13.7602 10.545 13.8428 10.6456 13.8915 10.7633C13.9403 10.881 13.953 11.0105 13.9281 11.1354C13.9033 11.2604 13.8418 11.3751 13.7517 11.4651L8.06911 17.1582C7.9461 17.2743 7.78286 17.3382 7.61371 17.3364ZM8.25721 11.6433V15.1384L11.742 11.6433H8.25721Z"
                fill={color}
            />
            <path
                d="M5.43202 8.47798L5.43202 3.63707C5.43202 3.46811 5.49792 3.30607 5.61524 3.18659C5.73255 3.06712 5.89166 3 6.05756 3C6.22347 3 6.38258 3.06712 6.49989 3.18659C6.6172 3.30607 6.68311 3.46811 6.68311 3.63707L6.68311 8.47798C6.68311 8.64694 6.6172 8.80899 6.49989 8.92846C6.38258 9.04793 6.22347 9.11505 6.05756 9.11505C5.89166 9.11505 5.73255 9.04793 5.61524 8.92846C5.49792 8.80899 5.43202 8.64694 5.43202 8.47798Z"
                fill={color}
            />
            <path
                d="M3.63725 5.43202L8.47816 5.43202C8.64712 5.43202 8.80917 5.49792 8.92864 5.61524C9.04811 5.73255 9.11523 5.89166 9.11523 6.05756C9.11523 6.22347 9.04811 6.38258 8.92864 6.49989C8.80916 6.6172 8.64712 6.68311 8.47816 6.68311L3.63725 6.6831C3.46829 6.6831 3.30625 6.6172 3.18677 6.49989C3.0673 6.38258 3.00018 6.22347 3.00018 6.05756C3.00018 5.89166 3.0673 5.73255 3.18677 5.61524C3.30625 5.49792 3.46829 5.43202 3.63725 5.43202Z"
                fill={color}
            />
        </svg>
    )
}
