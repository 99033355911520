import React, { Fragment, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Icon from '../../../common/components/Icon'
import { useOpenPeerConversation } from '../../../common/hooks/useOpenConversation'
import useWindowSize from '../../../common/useWindowResize'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import AvatarBackground from '../../assets/provider-dashboard/avatar-background.png'
import { getEligibilityColor } from '../../utils/getEligibilityColor'
import AlertsModal from '../coach-dashboard/AlertsModal'

export default function MobileRecords({
    clients,
    clientsRaw,
    params,
    fetchMoreUsers,
    refetchClients,
}) {
    const { isDesktop } = useWindowSize()

    const [showAlertsModal, setShowAlertsModal] = useState(false)
    const { openPeerConversation } = useOpenPeerConversation()

    const { t } = useTranslation()

    if (isDesktop) return null

    return (
        <Fragment>
            {clients && clients.length > 0 && (
                <InfiniteScroll
                    className="rpm-provider-mobile-records"
                    pageStart={0}
                    loadMore={fetchMoreUsers}
                    hasMore={
                        (clientsRaw?.next ? true : false) && !params.search
                    }
                    initialLoad={false}
                    loader={
                        <div className="user-card-skeleton skeleton w-full" />
                    }
                    useWindow={false}
                >
                    {clients.map(
                        ({
                            id,
                            first_name,
                            last_name,
                            mood,
                            photo,
                            username,
                            assessments,
                            eligibility,
                            alerts,
                            compliance_score,
                        }) => {
                            const icons = {
                                'GAD-7': (
                                    <Icon
                                        name="notes"
                                        size={13}
                                        color="#595959"
                                    />
                                ),
                                'PHQ-9': (
                                    <Icon
                                        name="notes"
                                        size={13}
                                        color="#595959"
                                    />
                                ),
                                TIPSA: (
                                    <Icon
                                        name="notes"
                                        size={13}
                                        color="#595959"
                                    />
                                ),
                            }

                            assessments = assessments.map(
                                ({
                                    assessment_name,
                                    score,
                                    color,
                                    bg_color,
                                    assessment_type_id,
                                }) => {
                                    return {
                                        title: assessment_name,
                                        score,
                                        color,
                                        bg_color,
                                        assessment_type_id,
                                        icon: icons[assessment_name] || (
                                            <Icon
                                                name="notes"
                                                size={13}
                                                color="#595959"
                                            />
                                        ),
                                        ranges:
                                            assessments.find(
                                                ({ name }) =>
                                                    name === assessment_name
                                            )?.settings?.ranges || [],
                                    }
                                }
                            )

                            return (
                                <div className="rpm-provider-mobile-record">
                                    <AlertsModal
                                        show={showAlertsModal == id}
                                        onHide={() => {
                                            refetchClients().then(() => {
                                                setShowAlertsModal(false)
                                            })
                                        }}
                                        alerts={alerts}
                                        user={
                                            clients.find(
                                                client => client.id === id
                                            ) || {}
                                        }
                                    />
                                    <div
                                        className="rpm-provider-mobile-record__alert-header"
                                        style={{
                                            ...((!alerts ||
                                                alerts.length === 0) && {
                                                backgroundColor: '#F7F5F3',
                                            }),
                                        }}
                                    >
                                        {alerts && alerts.length > 0 ? (
                                            <Fragment>
                                                <div className="rpm-provider-mobile-record__alert-header__left">
                                                    <div className="rpm-provider-mobile-record__alert-header__left__alert-icon-container">
                                                        <div className="rpm-provider-mobile-record__alert-header__left__alert-icon-container__square" />
                                                        <p className="rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon">
                                                            !
                                                        </p>
                                                    </div>
                                                    <p className="rpm-provider-mobile-record__alert-header__left__alert-text">
                                                        {alerts.length}{' '}
                                                        {t(
                                                            'provider_dashboard.alerts'
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    className="rpm-provider-mobile-record__alert-header__right"
                                                    onClick={() => {
                                                        setShowAlertsModal(id)
                                                    }}
                                                >
                                                    {t(
                                                        'provider_dashboard.view_all'
                                                    )}
                                                </div>
                                            </Fragment>
                                        ) : (
                                            // No alerts
                                            <p className="rpm-provider-mobile-record__alert-header__no-alerts">
                                                {t(
                                                    'provider_dashboard.no_alerts'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    <div className="rpm-provider-mobile-record__alert-body">
                                        <div className="rpm-provider-mobile-record__alert-body__header">
                                            <div
                                                className="rpm-provider-mobile-record__alert-body__header__profile-container"
                                                onClick={() => {
                                                    window.location.href = `/rpm/client-profile/${id}`
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <div className="rpm-provider-mobile-record__alert-body__header__profile-container__profile-image">
                                                    <img
                                                        src={
                                                            photo ||
                                                            AvatarBackground
                                                        }
                                                        alt="profile"
                                                        style={{
                                                            border: photo
                                                                ? '2px solid #c4c4c4'
                                                                : 0,
                                                            borderRadius: photo
                                                                ? '0.35rem 0.35rem 0rem 0.35rem'
                                                                : 0,
                                                        }}
                                                    />
                                                    {!photo && (
                                                        <div className="rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials">
                                                            {first_name[0]}
                                                            {last_name[0]}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="rpm-provider-mobile-record__alert-body__header__profile-container__details">
                                                    {/* <span className="rpm-provider-mobile-record__alert-body__header__profile-container__details__tag">
                                                            NEW
                                                        </span> */}
                                                    <span className="rpm-provider-mobile-record__alert-body__header__profile-container__details__name">
                                                        {first_name} {last_name}
                                                    </span>
                                                    {/*    <span className="rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis">
                                                            Diagnosis: F99
                                                        </span> */}
                                                </div>
                                            </div>

                                            <div
                                                className="rpm-provider-mobile-record__alert-body__header__status-container"
                                                style={{
                                                    borderColor: getEligibilityColor(
                                                        {
                                                            eligibility,
                                                        }
                                                    ),
                                                }}
                                            >
                                                <div
                                                    className="rpm-provider-mobile-record__alert-body__header__status-container__dot"
                                                    style={{
                                                        backgroundColor: getEligibilityColor(
                                                            {
                                                                eligibility,
                                                            }
                                                        ),
                                                    }}
                                                />
                                                <p
                                                    className="rpm-provider-mobile-record__alert-body__header__status-container__text"
                                                    style={{
                                                        color: getEligibilityColor(
                                                            {
                                                                eligibility,
                                                            }
                                                        ),
                                                    }}
                                                >
                                                    {(eligibility &&
                                                        t(
                                                            `provider_dashboard.${eligibility.toLowerCase()}`
                                                        )) ||
                                                        t(
                                                            'provider_dashboard.pending'
                                                        )}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="rpm-provider-mobile-record__alert-body__coach-container">
                                            <p className="rpm-provider-mobile-record__alert-body__coach-container__title">
                                                -
                                            </p>

                                            <button
                                                className="rpm-provider-mobile-record__alert-body__coach-container__button"
                                                onClick={() => {
                                                    openPeerConversation({
                                                        id,
                                                        username,
                                                        first_name,
                                                        last_name,
                                                        photo,
                                                    })
                                                }}
                                            >
                                                <Icon
                                                    name="message"
                                                    size={16}
                                                />
                                            </button>
                                        </div>

                                        <div className="rpm-provider-mobile-record__alert-body__measurements-container">
                                            <div className="rpm-provider-mobile-record__alert-body__measurements-container__item">
                                                <div className="flex items-center gap-0-5">
                                                    <div className="rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper">
                                                        <Icon
                                                            name={'mood'}
                                                            size={15}
                                                            color="#595959"
                                                        />
                                                    </div>
                                                    <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__label">
                                                        {t(
                                                            'provider_dashboard.mood'
                                                        )}
                                                    </p>
                                                </div>

                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__value">
                                                    {Math.floor(mood?.value) ||
                                                        0}
                                                </p>
                                            </div>

                                            {assessments &&
                                                assessments.map(
                                                    (assessment, index) => {
                                                        return (
                                                            <div className="rpm-provider-mobile-record__alert-body__measurements-container__item">
                                                                <div className="flex items-center gap-0-5">
                                                                    <div className="rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper">
                                                                        {
                                                                            assessment.icon
                                                                        }
                                                                    </div>
                                                                    <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__label">
                                                                        {
                                                                            assessment.title
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__value">
                                                                    {assessment.score ||
                                                                        0}
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                )}

                                            <div className="rpm-provider-mobile-record__alert-body__measurements-container__item">
                                                <div className="flex items-center gap-0-5">
                                                    <div className="rpm-provider-mobile-record__alert-body__measurements-container__item__icon-wrapper">
                                                        <Icon
                                                            name={'hand-click'}
                                                            size={15}
                                                            color="#595959"
                                                            style={{
                                                                transform:
                                                                    'scale(0.8)',
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__label">
                                                        Compliance Score
                                                    </p>
                                                </div>
                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__value">
                                                    {16 -
                                                        (compliance_score?.days_left ||
                                                            0) +
                                                        '/16'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </InfiniteScroll>
            )}
        </Fragment>
    )
}
