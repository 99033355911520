import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useClientProfile } from './hooks/client-profile/useClientProfile'
import { useFetchCompanyLinks } from './redux/fetchCompanyLinks'
import { DesktopHeader } from '../common'
import Icon from '../common/components/Icon'
export default function Events() {
  const { user, fetchUser, userId } = useClientProfile()

  const scheduleButtonRef = useRef(null)

  const { companyLinks, fetchCompanyLinks } = useFetchCompanyLinks()

  useEffect(() => {
    console.log({ user, companyLinks })

    if (!user) return

    if (user && !companyLinks && user.coach) {
      fetchCompanyLinks({ user_id: user.id })
    } else if (
      user &&
      !companyLinks[0] &&
      !user.coach &&
      companyLinks.length === 0
    ) {
      fetchCompanyLinks()
    } else if (user && companyLinks && companyLinks.length === 0) {
      fetchCompanyLinks()
    }
  }, [user, companyLinks])

  const [selectedCompanyLink, setSelectedCompanyLink] = useState(null)

  useEffect(() => {
    if (companyLinks && companyLinks.length > 0 && user) {
      console.log({ companyLinks })
      setSelectedCompanyLink(companyLinks[0])
    }
  }, [companyLinks, user])

  useEffect(() => {
    console.log({ selectedCompanyLink })
  }, [selectedCompanyLink])

  // Callback to initialize the widget when the script is loaded
  const initializeBookingWidget = () => {
    if (!user || !selectedCompanyLink) return

    if (window.GReminders && window.GReminders.BookingWidget) {
      const options = {
        anchorEl: scheduleButtonRef.current,
        fields: {
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone_number || '',
          email: user.email,
          c_company_id: user.company_id,
          c_customer_user_id: user.id,
          c_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          c_company_name: user.company_name,
          auto_submit: true,
          remember_me: false,
        },

        styles: {
          zIndex: 100,
        },
      }

      const onSuccess = (event_id, form_data) => {
        window.GReminders.BookingWidget.close()
        window.location.href = `/rpm/client-profile/${userId}`

        /* alert(
          'Success! ' +
            event_id +
            ' Customer Form Data: ' +
            JSON.stringify(form_data)
        ) */
      }

      const onError = message => {
        window.GReminders.BookingWidget.close()
        alert('Error! ' + message)
      }

      const bookingWidget = window.GReminders.BookingWidget

      const schedulingUrl = selectedCompanyLink.scheduling_url

      bookingWidget.initialize(schedulingUrl, options)

      bookingWidget.open()
      bookingWidget.onSuccess(onSuccess)
      bookingWidget.onError(onError)
    }
  }

  // Load the external script with the callback
  useExternalScripts({
    url: 'https://app.greminders.com/widgets/booking.js',
    onLoad: initializeBookingWidget,
    user,
  })

  useEffect(() => {
    // wait 2 seconds for the script to load
    setTimeout(() => {
      // once the script is loaded, move the div elements with class "-widget" (there are many) to the inside of the #auth-google-calendar-content div
      if (document.querySelector('.greminders__booking-widget')) {
        console.log('moving elements')
        const elements = document.querySelectorAll(
          '.greminders__booking-widget'
        )
        const container = document.querySelector('#rpm-events')

        // only append the first element
        container.appendChild(elements[elements.length - 1])

        // remove all the other elements
        elements.forEach((element, index) => {
          if (index !== elements.length - 1) {
            element.remove()
          }
        })
      }
    }, 2000)
  }, [user, selectedCompanyLink])

  if (!user && companyLinks) return null

  return (
    <div className="rpm-events" id="rpm-events">
      <DesktopHeader />

      <div className="rpm-events__header">
        <button
          onClick={() => {
            window.location.href = `/rpm/client-profile/${userId}`
          }}
        >
          <Icon name="chevronLeft" size={6} color={'#FFFFFF'} />
          <p>Go back</p>
        </button>

        {companyLinks && selectedCompanyLink && (
          <select
            value={selectedCompanyLink.id}
            onChange={e => {
              const link = companyLinks.find(link => link.id == e.target.value)
              console.log({ link })
              setSelectedCompanyLink(link)
            }}
          >
            {companyLinks.map(link => (
              <option key={link.id} value={link.id}>
                {link.link_label}
              </option>
            ))}
          </select>
        )}
      </div>

      <input
        type="button"
        ref={scheduleButtonRef}
        value="Schedule With Me"
        style={{
          opacity: 0,
          position: 'absolute',
          zIndex: -1,
          pointerEvents: 'none',
          left: '-1000px',
        }}
      />
    </div>
  )
}

function useExternalScripts({ url, onLoad, user }) {
  useEffect(() => {
    if (!user) return

    const head = document.querySelector('head')
    const script = document.createElement('script')

    script.setAttribute('src', url)
    script.onload = onLoad // Call the provided onLoad callback once the script is loaded
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [url, onLoad, user])
}
