import React from 'react'

export default function LabIcon({ color = '#000', size = 6, ...props }) {
    return (
        <svg
            width="6"
            height="12"
            viewBox="0 0 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{ scale: size / 6 }}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.58293 2.3999C1.79444 2.3999 1.96591 2.5807 1.96591 2.80372V9.45965C1.96591 10.0956 2.43911 10.5923 2.99995 10.5923C3.56079 10.5923 4.03399 10.0956 4.03399 9.45965V2.80372C4.03399 2.5807 4.20546 2.3999 4.41697 2.3999C4.62849 2.3999 4.79995 2.5807 4.79995 2.80372V9.45965C4.79995 10.5203 4.00385 11.3999 2.99995 11.3999C1.99605 11.3999 1.19995 10.5203 1.19995 9.45965V2.80372C1.19995 2.5807 1.37142 2.3999 1.58293 2.3999Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.56006 4.92006C1.56006 4.72124 1.73319 4.56006 1.94676 4.56006H3.33336C3.54693 4.56006 3.72006 4.72124 3.72006 4.92006C3.72006 5.11888 3.54693 5.28006 3.33336 5.28006H1.94676C1.73319 5.28006 1.56006 5.11888 1.56006 4.92006Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.56006 5.99989C1.56006 5.80107 1.75391 5.63989 1.99305 5.63989H2.92707C3.1662 5.63989 3.36006 5.80107 3.36006 5.99989C3.36006 6.19871 3.1662 6.35989 2.92707 6.35989H1.99305C1.75391 6.35989 1.56006 6.19871 1.56006 5.99989Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.56006 7.07997C1.56006 6.88115 1.73319 6.71997 1.94676 6.71997H3.33336C3.54693 6.71997 3.72006 6.88115 3.72006 7.07997C3.72006 7.27879 3.54693 7.43997 3.33336 7.43997H1.94676C1.73319 7.43997 1.56006 7.27879 1.56006 7.07997Z"
                fill={color}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.31799 1.02661V1.97337H4.68198V1.02661H1.31799ZM0.47998 0.922198C0.47998 0.555855 0.790612 0.23999 1.19827 0.23999H4.80169C5.20935 0.23999 5.51998 0.555856 5.51998 0.922198V2.07778C5.51998 2.44413 5.20935 2.75999 4.80169 2.75999H1.19827C0.790612 2.75999 0.47998 2.44413 0.47998 2.07778V0.922198Z"
                fill={color}
            />
        </svg>
    )
}
