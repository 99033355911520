import queryString from 'query-string';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useFetchUpdates } from '../../../common/redux/fetchUpdates';
import useWindowSize from '../../../common/useWindowResize';
import { CompetencyContext } from '../../CompetencyPage';
import { useActionItemResponse } from '../../redux/actionItemResponse';
import FocusEdit from './hooks/FocusEdit';
import ScrollToBottom from './hooks/ScrollToBottom';
import NoResponse from './images/NoResponse.png';
import MobileResponses from './MobileResponses';
import ResponseTextarea from './ResponseTextarea';
import ThreadComments from './ThreadComments';
import ThreadStart from './ThreadStart.js';

export default function Responses() {
  const { actionItemResponse, actionItemResponsePending } =
    useActionItemResponse();

  const { updates } = useFetchUpdates();

  const [actionItemResponses, setActionItemResponses] = useState([]);

  const location = useLocation();
  const params = queryString.parse(location.search);

  const [responseMessage, setResponseMessage] = useState({
    message: '',
    count: 0, // the count helps to identify when the message was successfully sent,
    // so we fetch all messages again: see useEffect below
  });

  const editRef = useRef(null);

  const [centerEdit, setCenterEdit] = useState(false);

  const { showActionItemContainer, user, selectedActionItem } =
    useContext(CompetencyContext);
  const { stageId, competencyId, studentId, roadmapId, actionItemId } =
    showActionItemContainer;

  const size = useWindowSize();
  let isMobile = size.width < 767;

  const fetchActionItemResponses = async (
    method = 'GET',
    message = null,
    responseId = null,
  ) => {
    actionItemResponse({
      stageId,
      competencyId,
      studentId,
      roadmapId,
      actionItemId,
      method,
      message: message ? message : responseMessage.message,
      responseId,
    }).then((response) => {
      if (
        method === 'GET' &&
        actionItemResponses.length !== response.data.results.length
      ) {
        console.log(response, 'actionItemResponses');
        setActionItemResponses(
          response.data.results.map((response) => {
            return {
              ...response,
              edit: false,
            };
          }),
        );
      }
      if (method === 'POST') {
        setResponseMessage({
          message: '',
          count: responseMessage.count + 1,
        });
      }

      if (method === 'PUT') {
        setResponseMessage({
          message: '',
          count: responseMessage.count + 1,
        });
      }
    });
  };

  const actionItemParent = useRef(null); // automatic scroll to bottom of action-item container

  useEffect(() => {
    // as we are using the updates as a dependency, we have to make sure we do not interrupt the user when he is typing and edit
    if (actionItemResponses.some((response) => response.edit === true)) return;
    fetchActionItemResponses();
  }, [showActionItemContainer, responseMessage.count, updates]);

  let isEditing = actionItemResponses.some(
    (response) => response.edit === true,
  );

  const [hasNoResponses, setHasNoResponses] = useState(false);
  const [hasResponse, setHasResponse] = useState(false);

  useEffect(() => {
    // console.log(actionItemResponses, 'actionItemResponses');
    const filtered = actionItemResponses.filter(
      (response) => response.message !== '',
    );
    console.log('filtered', filtered);
    if (filtered.length < 1) {
      setHasNoResponses(true);
      setHasResponse(false);
    } else {
      setHasNoResponses(false);
      setHasResponse(true);
    }
  }, [actionItemResponses]);

  const responseCardProps = {
    setActionItemResponses,
    fetchActionItemResponses,
    actionItemResponses,
    actionItemParent,
    responseMessage,
    setResponseMessage,
    centerEdit,
    setCenterEdit,
    editRef,
    user,
    isMobile,
    isEditing,
    hasNoResponses,
    hasResponse,
  };

  let requiresResponse = selectedActionItem.resolutions?.includes('input_text');

  if (isMobile) {
    if ((hasNoResponses && !params.user) || hasResponse) {
      document.querySelector('.common-bottom-nav').style.display = 'none';
      document.querySelector('.osano-cm-window__widget').style.display = 'none';

      return (
        <MobileResponses
          {...responseCardProps}
          hasResponse={hasResponse}
          hasNoResponses={hasNoResponses}
        />
      );
    } else return null;
  }

  return (
    <div className="lateral-discuss">
      {(hasResponse || !params.user) && requiresResponse && (
        <Fragment>
          <p className="title">Add your response</p>
          <p className="subtitle">
            Once you have completed the exercise mark it as complete.
          </p>
        </Fragment>
      )}

      {(hasResponse || !params.user) && !requiresResponse && (
        <Fragment>
          <p className="title">No response required</p>
          <p className="subtitle">
            Once you have completed the exercise mark it as complete.
          </p>
        </Fragment>
      )}

      {hasNoResponses && !params.user && requiresResponse ? (
        <ResponseTextarea {...responseCardProps} />
      ) : hasNoResponses && requiresResponse ? (
        <div className="flex flex-col items-center gap-1 pt-5">
          <img src={NoResponse} alt="No Responses" width={87} />
          <p className="no-responses-yet">NO RESPONSES YET</p>
        </div>
      ) : null}

      {isEditing && <ResponseTextarea {...responseCardProps} />}

      {!isEditing && (
        <div className="discuss-container" style={{
          height: params.user ? '35rem' : '28.5rem'
        }}>
          <ThreadStart {...responseCardProps} />

          <ThreadComments {...responseCardProps} />
        </div>
      )}

      <ScrollToBottom // useEffect
        actionItemParent={actionItemParent}
        actionItemResponses={actionItemResponses}
        centerEdit={centerEdit}
      />
      <FocusEdit // useEffect
        editRef={editRef}
      />
    </div>
  );
}
