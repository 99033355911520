import React from 'react';
import { AttachmentList } from './ActionItemEditing/Attachment';

const CompetencyMoreTab = ({ competency }) => {
  return (
    <div className="roadmap-competency-page-competency-more-tab">
      <AttachmentList
        data={competency.attachments.filter((att) => att.user_id === null)}
        canDelete={false}
      />
    </div>
  );
};

export default CompetencyMoreTab;
