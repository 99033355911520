import React from 'react'
import Icon from '../../common/components/Icon'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from '../../translations/redux/setDefaultLanguage'

export default function Card({
  call,
  onDelete,
  onEdit,
  canEdit = false,
  icon = 'group',
}) {
  const { t, defaultLanguage } = useTranslation()
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
      className="drop-in-card-toggle-btn"
      style={{ transform: 'rotate(90deg)' }}
    >
      <Icon name="threedots" size={4} className="write-icon" color="#C4C4C4" />
    </button>
  ))

  let date = new Date(call.meeting_timestamp)

  const options = {
    // year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  let dateString

  // get timezone of user
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (call?.recours_weekly || call?.is_recurrent) {
    const day = date.getDay()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const ampm = hour >= 12 ? 'PM' : 'AM'
    const hour12 = hour % 12 || 12
    dateString = `
    
    ${
      day === 0
        ? t('dashboard.support_group.card.sunday')
        : day === 1
        ? t('dashboard.support_group.card.monday')
        : day === 2
        ? t('dashboard.support_group.card.tuesday')
        : day === 3
        ? t('dashboard.support_group.card.wednesday')
        : day === 4
        ? t('dashboard.support_group.card.thursday')
        : day === 5
        ? t('dashboard.support_group.card.friday')
        : day === 6
        ? t('dashboard.support_group.card.saturday')
        : ''
    } ${t('dashboard.support_group.card.at')} ${hour12}:${
      minute < 10 ? '0' : ''
    }${minute} ${ampm}`
  } else {
    dateString = date.toLocaleTimeString(defaultLanguage, options)
    dateString = dateString.replace(
      ',',
      ` ${t('dashboard.support_group.card.at')}`
    )
  }

  return (
    <div className="drop-in-card">
      <a
        className="icon-wrapper"
        href={
          // if call meeting link does not include protocol, add it
          call.meeting_link.indexOf('https') === -1 &&
          call.meeting_link.indexOf('http') === -1
            ? `https://${call.meeting_link}`
            : call.meeting_link
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="icon-container">
          <Icon name={icon} size={15} color="var(--primary-white)" />
        </div>
      </a>

      <a
        style={{ width: '95%' }}
        href={
          // if call meeting link does not include protocol, add it
          call.meeting_link.indexOf('https') === -1 &&
          call.meeting_link.indexOf('http') === -1
            ? `https://${call.meeting_link}`
            : call.meeting_link
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-container">
          <h3>{call.name}</h3>
          <p>
            {call?.recours_weekly || call?.is_recurrent ? (
              <Icon
                name={'recurs'}
                size={10}
                color="#bdc0c3"
                style={{
                  marginRight: '0.5rem',
                }}
              />
            ) : (
              ''
            )}
            {dateString} {''}
            {timezone}
          </p>
        </div>
      </a>

      {canEdit && (
        <Dropdown
          style={{
            position: 'absolute',
            right: '1.3rem',
            top: '1rem',
          }}
          className="drop-in-card-dropdown"
        >
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
            <hr />
            <Dropdown.Item onClick={onDelete} className="delete-btn">
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}
