import React, { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Loader, TextEditor } from '../common';
import { useUpdateCompetency } from './redux/hooks';
export default function AddCompetencySupplementalContent({
  className = '',
  roadmapId,
  stageId,
  competencyId,
  competency,
  fetchStageCompetenciesPending,
  updateSupplementalContent,
  hideSupplementalContentEditor,
}) {
  const { updateCompetency, updateCompetencyPending } = useUpdateCompetency();
  const [content, setContent] = useState(null);

  const handleSaveClick = useCallback(() => {
    updateCompetency({
      roadmap: roadmapId,
      stage: stageId,
      competency: competencyId,
      content,
    }).then(() => updateSupplementalContent());
  }, [
    roadmapId,
    stageId,
    competencyId,
    content,
    updateCompetency,
    updateSupplementalContent,
  ]);

  const renderSaveButton = useCallback(
    () => (
      <Button
        className="btn-save"
        variant="primary"
        disabled={updateCompetencyPending}
        onClick={handleSaveClick}
      >
        Save
      </Button>
    ),
    [handleSaveClick, updateCompetencyPending],
  );

  const renderCancelButton = useCallback(
    (buttonClasses) => (
      <Button
        className={`btn-cancel ${buttonClasses}`}
        variant="gray"
        disabled={updateCompetencyPending}
        onClick={hideSupplementalContentEditor}
      >
        Cancel
      </Button>
    ),
    [hideSupplementalContentEditor, updateCompetencyPending],
  );

  return (
    <div className={`manage-add-competency-supplemental-content ${className}`}>
      {(fetchStageCompetenciesPending || updateCompetencyPending) && <Loader />}
      {competency && (
        <>
          <div className="mrm-mt-1">
            <TextEditor data={competency.content} setData={setContent} />
          </div>

          <div className="d-flex align-items-center justify-content-start px-2 my-3">
            {renderSaveButton()}
            {renderCancelButton('ml-3')}
          </div>
        </>
      )}
    </div>
  );
}

AddCompetencySupplementalContent.propTypes = {};
AddCompetencySupplementalContent.defaultProps = {};
