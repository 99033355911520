import { useFetchUser } from '../features/user/redux/fetchUser';

const CurrentUserIsAdmin = () => {
  const { user } = useFetchUser();
  if (user) {
    return user.groups.includes('Admin');
  }
  return false;
};

const CurrentUserIsCoachOrAdmin = () => {
  const { user } = useFetchUser();
  if (!user) return;

  if (user.groups.includes('Coach') || user.groups.includes('Admin')) {
    return true;
  }
};

const CurrentUserIsOnlyUser = () => {
  const { user } = useFetchUser();
  if (user && user.groups.length === 1 && user.groups.includes('User')) {
    return true;
  }
};

const CurrentUserIsUser = () => {
  const { user } = useFetchUser();
  if (user && user.groups.includes('User')) {
    return true;
  }
};

const CurrentUserIsCoach = () => {
  const { user } = useFetchUser();
  if (user && user.groups.includes('Coach')) {
    return true;
  }
};

const CurrentUserIsInternalCoach = () => {
  const { user } = useFetchUser();
  if (user && user.is_internal_coach) {
    return true;
  }
  return false;
};

export {
  CurrentUserIsCoachOrAdmin,
  CurrentUserIsOnlyUser,
  CurrentUserIsCoach,
  CurrentUserIsUser, // returns true if user is type user
  CurrentUserIsAdmin, // returns true if user is type admin
  CurrentUserIsInternalCoach, // returns true if user is type internal coach
};
