import React from 'react';

export default function SimpleCheckIcon({
  color = 'white',
  size = 11,
  className = '',
  style = {},
}) {
  return (
    <svg
      width={size}
      height={size / 1.57}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        ...style,
      }}
    >
      <path
        d="M10.7844 0.182251C10.6486 0.0653765 10.4667 0 10.2774 0C10.0881 0 9.90629 0.0653765 9.7705 0.182251L3.80648 5.46731L1.17038 3.13131C1.0325 3.03079 0.857201 2.97956 0.678889 2.9877C0.500577 2.99583 0.332136 3.06274 0.206633 3.17528C0.08113 3.28782 0.00762993 3.43786 0.000561507 3.59596C-0.00650691 3.75406 0.0533668 3.90879 0.168429 4.02977L3.3055 6.82028C3.37153 6.87801 3.44978 6.9236 3.53575 6.95444C3.62173 6.98528 3.71373 7.00075 3.80648 6.99997C3.99697 6.99985 4.1803 6.93562 4.31939 6.82028L10.7844 1.08071C10.8526 1.02253 10.9068 0.952757 10.9439 0.875569C10.9809 0.79838 11 0.715361 11 0.631481C11 0.5476 10.9809 0.464583 10.9439 0.387394C10.9068 0.310205 10.8526 0.240433 10.7844 0.182251Z"
        fill={color}
      />
    </svg>
  );
}
