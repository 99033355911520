import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchUser } from '../user/redux/hooks'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'
import SelfSignupProgress from './components/SelfSignupProgress'

export function useExternalScripts({ url, onLoad, user }) {
  useEffect(() => {
    if (!user) return

    const head = document.querySelector('head')
    const script = document.createElement('script')

    script.setAttribute('src', url)
    script.onload = onLoad // Call the provided onLoad callback once the script is loaded
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [url, onLoad, user])
}
export default function GoogleCalendar() {
  const { getSettingsByName, settings: data } = useGetSettingsByName()

  const { company: name, type } = useParams()

  useEffect(() => {
    getSettingsByName({ name, type })
  }, [name])
  const { defaultLanguage } = useSetDefaultLanguage()

  const { user } = useFetchUser()

  const scheduleButtonRef = useRef(null)

  // Callback to initialize the widget when the script is loaded
  const initializeBookingWidget = () => {
    if (!user) return

    if (window.GReminders && window.GReminders.BookingWidget) {
      const options = {
        anchorEl: scheduleButtonRef.current,
        fields: {
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone_number || '',
          email: user.email,
          c_company_id: user.company_id,
          c_customer_user_id: user.id,
          c_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          c_company_name: user.company_name,
          auto_submit: true,
          remember_me: false,
        },

        styles: {
          zIndex: 100,
        },
      }

      const onSuccess = (event_id, form_data) => {
        window.GReminders.BookingWidget.close()

        if (Boolean(data?.pages[defaultLanguage]?.download_app)) {
          window.location.href = `/auth/${name}/${type}/download`
        } else window.location.href = '/rpm/download-app'

        /* alert(
          'Success! ' +
            event_id +
            ' Customer Form Data: ' +
            JSON.stringify(form_data)
        ) */
      }

      const onError = message => {
        window.GReminders.BookingWidget.close()
        alert('Error! ' + message)
      }

      const bookingWidget = window.GReminders.BookingWidget

      bookingWidget.initialize(
        // This is the one that can be changed
        data?.pages[defaultLanguage]?.calendar.config.src,
        options
      )

      bookingWidget.open()
      bookingWidget.onSuccess(onSuccess)
      bookingWidget.onError(onError)
    }
  }

  // Load the external script with the callback
  useExternalScripts({
    url: 'https://app.greminders.com/widgets/booking.js',
    onLoad: initializeBookingWidget,
    user,
  })

  useLayoutEffect(() => {
    // wait 2 seconds for the script to load
    setTimeout(() => {
      // once the script is loaded, move the div elements with class "-widget" (there are many) to the inside of the #auth-google-calendar-content div
      if (document.querySelector('.greminders__booking-widget')) {
        console.log('moving elements')
        const elements = document.querySelectorAll(
          '.greminders__booking-widget'
        )
        const container = document.querySelector(
          '#auth-google-calendar-content'
        )

        // only append the first element
        container.appendChild(elements[0])
      }
    }, 2000)
  }, [data])

  useEffect(() => {
    if (data && !data.pages[defaultLanguage]?.calendar) {
      if (data?.pages[defaultLanguage]?.download_app) {
        window.location.href = `/auth/${name}/${type}/download`
      } else window.location.href = '/rpm/download-app'
    }
  }, [data])

  if (!user) return null

  if (!data?.pages[defaultLanguage]?.calendar) return null

  return (
    <div className="auth-google-calendar">
      <div className="auth-google-calendar-navbar">
        <div className="auth-google-calendar-navbar__logo-container">
          <img src={data.globals.logo} alt="logo" />
        </div>
        <SelfSignupProgress settings={data} />
      </div>

      <div
        className="auth-google-calendar-content"
        id="auth-google-calendar-content"
      >
        {data.pages[defaultLanguage].sign_up.media.background_image && (
          <img
            src={data.pages[defaultLanguage].sign_up.media.background_image}
            alt="background"
            className="background-image"
          />
        )}
        <input
          type="button"
          ref={scheduleButtonRef}
          value="Schedule With Me"
          style={{
            opacity: 0,
            position: 'absolute',
            zIndex: -1,
            pointerEvents: 'none',
            left: '-1000px',
          }}
        />
      </div>
    </div>
  )
}

GoogleCalendar.propTypes = {}
GoogleCalendar.defaultProps = {}
