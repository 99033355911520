import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SUPPORT_GET_CITY_AND_STATE_BEGIN,
  SUPPORT_GET_CITY_AND_STATE_SUCCESS,
  SUPPORT_GET_CITY_AND_STATE_FAILURE,
  SUPPORT_GET_CITY_AND_STATE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';

export function getCityAndState(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: SUPPORT_GET_CITY_AND_STATE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      // http://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID="982NOBLE4805"><ZipCode ID="0"><Zip5>20024</Zip5></ZipCode></CityStateLookupRequest>
      const BASE_URI =
        'https://production.shippingapis.com/ShippingAPITest.dll?API=CityStateLookup&XML=';
      const xml = `<CityStateLookupRequest USERID="982NOBLE4805"><ZipCode ID="0"><Zip5>${args.zipcode}</Zip5></ZipCode></CityStateLookupRequest>`;

      const doRequest = axios.get(`${BASE_URI}${xml}`, {
        headers: {
          'Content-Type': 'text/xml',
        },
      });

      doRequest.then(
        (res) => {
          dispatch({
            type: SUPPORT_GET_CITY_AND_STATE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SUPPORT_GET_CITY_AND_STATE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetCityAndStateError() {
  return {
    type: SUPPORT_GET_CITY_AND_STATE_DISMISS_ERROR,
  };
}

export function useGetCityAndState() {
  const dispatch = useDispatch();

  const { getCityAndStatePending, getCityAndStateError } = useSelector(
    (state) => ({
      getCityAndStatePending: state.support.getCityAndStatePending,
      getCityAndStateError: state.support.getCityAndStateError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getCityAndState(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetCityAndStateError());
  }, [dispatch]);

  return {
    getCityAndState: boundAction,
    getCityAndStatePending,
    getCityAndStateError,
    dismissGetCityAndStateError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SUPPORT_GET_CITY_AND_STATE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getCityAndStatePending: true,
        getCityAndStateError: null,
      };

    case SUPPORT_GET_CITY_AND_STATE_SUCCESS:
      // The request is success
      return {
        ...state,
        getCityAndStatePending: false,
        getCityAndStateError: null,
      };

    case SUPPORT_GET_CITY_AND_STATE_FAILURE:
      // The request is failed
      return {
        ...state,
        getCityAndStatePending: false,
        getCityAndStateError: action.data.error,
      };

    case SUPPORT_GET_CITY_AND_STATE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getCityAndStateError: null,
      };

    default:
      return state;
  }
}
