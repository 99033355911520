import React from 'react';

export default function ClientsIcon({ size, color }) {
  return (
    <svg
      width={size}
      height={size / 1.09}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_962_573)">
        <path
          d="M14.1844 13.9703C13.7934 13.7431 13.3873 13.5437 12.969 13.3734C14.2884 12.591 15.3168 11.3902 15.8955 9.95654C16.4742 8.52287 16.5711 6.93603 16.171 5.44104C15.771 3.94605 14.8964 2.62608 13.6822 1.68496C12.4681 0.74383 10.9819 0.233887 9.45322 0.233887C7.92454 0.233887 6.43839 0.74383 5.22423 1.68496C4.01007 2.62608 3.13544 3.94605 2.73542 5.44104C2.33539 6.93603 2.43222 8.52287 3.01094 9.95654C3.58966 11.3902 4.61809 12.591 5.93741 13.3734C4.92296 13.7838 3.98069 14.3577 3.14707 15.073C1.40106 16.5969 0.277101 18.7245 -0.0060237 21.0418C-0.0186157 21.1615 -0.00593492 21.2825 0.0311793 21.3968C0.0675404 21.5123 0.126618 21.6192 0.204818 21.711C0.281935 21.803 0.376619 21.8783 0.483178 21.9323C0.589738 21.9862 0.705974 22.0178 0.824893 22.0251H18.0661C18.1022 22.0378 18.1415 22.0378 18.1777 22.0251C18.311 21.4996 18.311 20.9484 18.1777 20.4229C18.1777 20.3224 17.9545 20.3381 17.7188 20.3381H1.84179C2.23181 18.81 3.06826 17.4369 4.24151 16.3987C5.48023 15.3062 7.02235 14.6291 8.6561 14.4603C10.2899 14.2916 11.9351 14.6395 13.3659 15.4562C13.3659 15.4562 14.4262 14.2813 14.3425 14.0394L14.1844 13.9703ZM4.13918 7.19717C4.15644 5.85248 4.66654 4.56233 5.5703 3.57749C6.47406 2.59266 7.70694 1.98349 9.02927 1.86842C10.3516 1.75334 11.6689 2.14059 12.7252 2.95487C13.7814 3.76914 14.5011 4.95229 14.7444 6.27432C14.9877 7.59634 14.7372 8.96278 14.0415 10.1081C13.3459 11.2533 12.2548 12.0956 10.9804 12.4712C9.706 12.8467 8.33935 12.7287 7.14603 12.1401C5.95272 11.5515 5.018 10.5343 4.52362 9.28629C4.26296 8.62205 4.13544 7.91187 4.14849 7.19717H4.13918Z"
          fill={color}
        />
        <path
          d="M19.4548 19.5559C18.7986 19.5556 18.1503 19.411 17.5546 19.1322C16.9589 18.8534 16.43 18.4469 16.0044 17.9409C15.5788 17.4348 15.2667 16.8412 15.0894 16.201C14.9122 15.5609 14.8742 14.8894 14.978 14.2329C15.0818 13.5764 15.3249 12.9505 15.6906 12.3984C16.0562 11.8464 16.5358 11.3813 17.0961 11.0353C17.6564 10.6892 18.2841 10.4705 18.936 10.3942C19.5878 10.3178 20.2482 10.3857 20.8716 10.5931C20.949 10.6189 21.0206 10.6599 21.0823 10.7137C21.144 10.7675 21.1947 10.8331 21.2314 10.9068C21.2681 10.9804 21.2902 11.0607 21.2963 11.143C21.3023 11.2252 21.2924 11.308 21.2669 11.3864C21.2415 11.4647 21.201 11.5372 21.1479 11.5998C21.0948 11.6623 21.0301 11.7137 20.9574 11.7509C20.8847 11.7881 20.8055 11.8104 20.7243 11.8166C20.6431 11.8228 20.5614 11.8126 20.4841 11.7869C19.8225 11.5665 19.1089 11.5641 18.4459 11.7801C17.7829 11.9961 17.2045 12.4194 16.7936 12.9892C16.3828 13.5591 16.1606 14.2461 16.1591 14.9518C16.1575 15.6575 16.3766 16.3455 16.785 16.9172C17.1933 17.4889 17.7699 17.9148 18.4319 18.1338C19.094 18.3528 19.8075 18.3537 20.4701 18.1363C21.1326 17.9189 21.7102 17.4944 22.1199 16.9237C22.5296 16.353 22.7503 15.6655 22.7505 14.9598C22.7505 14.8454 22.7443 14.7311 22.7318 14.6174C22.7237 14.5349 22.7317 14.4516 22.7553 14.3722C22.779 14.2928 22.8178 14.2189 22.8696 14.1547C22.9215 14.0905 22.9852 14.0373 23.0574 13.9981C23.1295 13.9589 23.2085 13.9345 23.2899 13.9263C23.3714 13.918 23.4536 13.9261 23.532 13.95C23.6103 13.974 23.6832 14.0133 23.7465 14.0658C23.8099 14.1183 23.8624 14.183 23.9011 14.256C23.9398 14.3291 23.9638 14.4092 23.972 14.4917C24.0386 15.1317 23.9715 15.7787 23.775 16.3906C23.5784 17.0026 23.257 17.5658 22.8314 18.0435C22.4059 18.5212 21.8858 18.9027 21.3051 19.1633C20.7244 19.4239 20.0961 19.5576 19.461 19.5559H19.4548Z"
          fill={color}
        />
        <path
          d="M19.38 16.7755C19.2984 16.776 19.2175 16.7602 19.1419 16.7289C19.0664 16.6976 18.9976 16.6516 18.9397 16.5934L17.588 15.2237C17.4716 15.1058 17.4062 14.9458 17.4062 14.7791C17.4062 14.6124 17.4716 14.4525 17.588 14.3346C17.7043 14.2167 17.8621 14.1505 18.0267 14.1505C18.1912 14.1505 18.349 14.2167 18.4654 14.3346L19.3955 15.2582L22.4772 12.1355C22.5936 12.0176 22.7514 11.9514 22.916 11.9514C23.0805 11.9514 23.2383 12.0176 23.3546 12.1355C23.471 12.2534 23.5364 12.4133 23.5364 12.5801C23.5364 12.7468 23.471 12.9067 23.3546 13.0246L19.8326 16.5934C19.7732 16.6531 19.7025 16.7001 19.6247 16.7314C19.5469 16.7627 19.4637 16.7777 19.38 16.7755Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_962_573">
          <rect width={size} height={size / 1.09} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
