import React, { useContext, useState } from 'react';
import { AssignCoachSchema } from './components/formContainer/yup/AssignCoachSchema';
import { ClientInfoSchema } from './components/formContainer/yup/ClientInfoSchema';
import { SubscriptionSchema } from './components/formContainer/yup/SubscriptionSchema';
import ResendSubscriptionModal from './components/ResendSubscriptionModal';
import Step from './components/sidebar/Step';
import StepsController from './components/StepsController';
import { SupportContext } from './Dashboard';

export default function Sidebar() {
  const { steps, stepValues, setSteps } = useContext(SupportContext);
  const [showResendModal, setShowResendModal] = useState(false);
  return (
    <div className="support-sidebar">
      <div className="support-sidebar-header">
        <p>Onboarding</p>
        <h1>New Client</h1>
      </div>
      <div className="support-sidebar-body">
        <div className="support-sidebar-body-steps">
          <Step
            state={steps[1].state}
            step={1}
            description="Create a new client"
            onClick={() => {
              StepsController(1, ClientInfoSchema, setSteps, steps, stepValues);
            }}
          />
          <Step
            state={steps[2].state}
            step={2}
            description="Assign a coach"
            onClick={() => {
              StepsController(
                2,
                AssignCoachSchema,
                setSteps,
                steps,
                stepValues,
              );
            }}
          />
          <Step
            state={steps[3].state}
            step={3}
            description="Subscription"
            onClick={() => {
              StepsController(
                3,
                SubscriptionSchema,
                setSteps,
                steps,
                stepValues,
              );
            }}
          />
          <Step
            state={steps[4].state}
            step={4}
            description="Let's Review"
            onClick={() => {
              StepsController(4, null, setSteps, steps, stepValues);
            }}
          />
        </div>
      </div>

      <button
        className="resend-sub-btn"
        onClick={() => {
          setShowResendModal(true);
        }}
      >
        <p>Resend Subscription</p>
      </button>
      <ResendSubscriptionModal
        show={showResendModal}
        setShow={setShowResendModal}
      />
    </div>
  );
}

Sidebar.propTypes = {};
Sidebar.defaultProps = {};
