import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_UPDATE_CPT_LOG_BEGIN,
    RPM_UPDATE_CPT_LOG_SUCCESS,
    RPM_UPDATE_CPT_LOG_FAILURE,
    RPM_UPDATE_CPT_LOG_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function updateCptLog(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_UPDATE_CPT_LOG_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.put(
                `${config.apiRootUrl}/rpm/cpt-log/${args.id}/`,
                args,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_UPDATE_CPT_LOG_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_UPDATE_CPT_LOG_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissUpdateCptLogError() {
    return {
        type: RPM_UPDATE_CPT_LOG_DISMISS_ERROR,
    }
}

export function useUpdateCptLog() {
    const dispatch = useDispatch()

    const { updateCptLogPending, updateCptLogError } = useSelector(
        state => ({
            updateCptLogPending: state.rpm.updateCptLogPending,
            updateCptLogError: state.rpm.updateCptLogError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(updateCptLog(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissUpdateCptLogError())
    }, [dispatch])

    return {
        updateCptLog: boundAction,
        updateCptLogPending,
        updateCptLogError,
        dismissUpdateCptLogError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_UPDATE_CPT_LOG_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                updateCptLogPending: true,
                updateCptLogError: null,
            }

        case RPM_UPDATE_CPT_LOG_SUCCESS:
            // The request is success
            return {
                ...state,
                updateCptLogPending: false,
                updateCptLogError: null,
            }

        case RPM_UPDATE_CPT_LOG_FAILURE:
            // The request is failed
            return {
                ...state,
                updateCptLogPending: false,
                updateCptLogError: action.data.error,
            }

        case RPM_UPDATE_CPT_LOG_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                updateCptLogError: null,
            }

        default:
            return state
    }
}
