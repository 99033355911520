import React, { createContext, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import DesktopHeader from '../common/DesktopHeader';
import { useFetchUser } from '../user/redux/fetchUser';
import RankByComments from './components/charts/bar/RankByComments';
import UserBehavior from './components/charts/line/UserBehavior';
import UsersQuantity from './components/charts/line/UsersQuantity';
import FilterCard from './components/FilterCard';

export const AnalyticsContext = createContext({});

export default function Home() {
  const { fetchUser, user } = useFetchUser();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const [filters, setFilters] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    subscriptionStatus: '',
  });

  if (!user) return null;

  return (
    <AnalyticsContext.Provider
      value={{
        filters,
        setFilters,
      }}
    >
      <div className="analytics-home">
        <DesktopHeader />
        <h1>Welcome to {user.company_name} Analytics</h1>
        <p>
          Here you can view the result and trends of different KPIs, in general
          or filtered by key variables
        </p>
        <div className="analytics-container">
          <FilterCard />
          <Masonry
            breakpointCols={{ default: 2, 991: 1 }}
            className="my-masonry-grid mrm-mt-2"
            columnClassName="my-masonry-grid_column"
          >
            <RankByComments />
            <UsersQuantity />
            <UserBehavior />
          </Masonry>
        </div>
      </div>
    </AnalyticsContext.Provider>
  );
}

Home.propTypes = {};
Home.defaultProps = {};
