import * as yup from 'yup'

export const upcomingCallsSchema = yup.object().shape({
    call_name: yup.string().required('Call name is required'),
    user_provided_timestamp: yup
        .string()
        .min(1, 'Timestamp is required')
        .required('Timestamp is required'),
    time_zone: yup.string(),
    selected_participants: yup
        .array()
        .min(1, 'At least one participant is required'),

    user_provided_link: yup
        .string()
        .when('use_upheal_link', {
            is: true,
            then: yup.string().notRequired(),
        })
        .when('use_upheal_link', {
            is: false,
            then: yup
                .string()
                .required('Meeting link is required')
                .matches(
                    /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
                    'Invalid meeting link'
                ),
        }),
    is_recurrent: yup.boolean(),
    interval: yup.number(),
    use_upheal_link: yup.boolean(),
    include_creator: yup.boolean(),
})
