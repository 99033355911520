import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import logo from './images/logo.png';
import roadmapThumbnail from '../dashboard/images/woman-with-cat.svg';
import { Link, useHistory } from 'react-router-dom';
import { useGetInvoicesStripe } from './redux/getInvoicesStripe';
import loginMobileBackground from './images/people.png';
import { useWindowSize } from '../../common/windowsSize';
import { useFetchUser } from '../user/redux/fetchUser';
import { useFetchRoadmaps } from '../dashboard/redux/hooks';
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers';

export default function OnboardingConfirmation() {
  const { getInvoicesStripe } = useGetInvoicesStripe();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();

  const { roadmaps, fetchRoadmaps } = useFetchRoadmaps();

  useEffect(() => {
    fetchRoadmaps({ assignedCoaches: true }).catch(unauthorizedErrorHandler);
  }, [fetchRoadmaps, unauthorizedErrorHandler]);
  const history = useHistory();

  const [billingPeriod, setBillingPeriod] = useState('');
  const [billingDate, setBillingDate] = useState(1640107167);
  const [dueMonthly, setDueMonthly] = useState(0);
  const [date, setDate] = useState();

  useEffect(() => {
    getInvoicesStripe().then((res) => {
      const invoice = res.data.data.Invoices.data[0];
      setBillingPeriod(invoice.lines.data[0].plan.interval);
      setBillingDate(invoice.lines.data[0].period.start * 1000);
      setDueMonthly(invoice.lines.data[0].plan.amount);
    });
    const day = new Date(billingDate);
    setDate(day.getDate());
  }, [getInvoicesStripe, billingDate]);

  const size = useWindowSize();

  const { user } = useFetchUser();
  console.log(user);
  console.log(roadmaps);

  return (
    <div className="home-onboarding-confirmation">
      <div
        className="login_sidebar mrm-mx-10"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          minHeight: '100vh',
          backgroundImage:
            size.width < 768 ? `url(${loginMobileBackground})` : '',
        }}
      >
        <Link to="/">
          <img src={logo} className="logo" alt="company-logo" />
        </Link>

        <div className="login_form">
          <div className="success-block">
            <h3 className="welcome">Success!</h3>
            <p>
              We'll let {user?.coaches?.[0]?.first_name}{' '}
              {user?.coaches?.[0]?.last_name} know you're here.
            </p>
          </div>

          <div className="form_c">
            <div className="subscription-details">
              <p> Subscription Details </p>
              <hr />
              <div className="details-grid">
                <div className="billing-item">
                  <p className="desc">Billing Period</p>
                  <p>{billingPeriod === 'month' ? 'Monthly' : ''}</p>
                </div>
                <div className="billing-item">
                  <p className="desc">Billing Date</p>
                  <p>{date}</p>
                </div>
                <div className="billing-item">
                  <p className="desc">Due Monthly</p>
                  <p>${dueMonthly / 100}.00</p>
                </div>
              </div>
              <hr />
              <a
                href="https://www.noble.health/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="legals">Terms and Conditions</p>
              </a>
            </div>

            <button onClick={() => history.push('/')}> Go to Dashboard</button>
          </div>
        </div>
      </div>
      <div className="s-content">
        <div className="login_content">
          <h1>Welcome!</h1>
          <p className="welcome-desc">
            {user?.coaches?.[0]?.first_name} {user?.coaches?.[0]?.last_name} has
            assigned you a Roadmap. Let's get started...
          </p>
          <div
            className="parent-roadmap-image"
            style={{
              height:
                roadmaps?.results?.[0]?.description === '' ? '23rem' : '31rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                backgroundImage: `url(${
                  roadmaps?.results?.[0]?.icon || roadmapThumbnail
                })`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '23rem',
                width: '100%',
                borderRadius: '1rem',
              }}
              className="roadmap-image"
            >
              <div className="button-and-text">
                <h1>{roadmaps?.results?.[0]?.title}</h1>
                <button
                  onClick={() =>
                    history.push(`/roadmap/${roadmaps?.results?.[0]?.id}`)
                  }
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="20" cy="20" r="20" fill="#343434" />
                    <path
                      d="M25.545 18.6393C26.1995 19.0265 26.1995 19.9735 25.545 20.3607L21.5021 22.7522L17.5083 25.1096C16.8417 25.5031 16 25.0226 16 24.2485V19.5V14.7515C16 13.9774 16.8417 13.4969 17.5083 13.8904L21.5021 16.2478L25.545 18.6393Z"
                      fill="white"
                    />
                  </svg>
                  Start Roadmap
                </button>
              </div>
            </div>
            <p className="description">{roadmaps?.results?.[0]?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

OnboardingConfirmation.propTypes = {};
OnboardingConfirmation.defaultProps = {};
