export const provider_dashboard = {
    add_staff: 'Añadir personal',
    first_name: 'Nombre',
    last_name: 'Apellido',
    suffix: 'Sufijo',
    email: 'Correo electrónico',
    phone_number: 'Número de teléfono',
    provider_npi: 'NPI del proveedor',
    access_level: 'Nivel de acceso',
    admin: 'Administrador',
    coach: 'Coach',
    user: 'Usuario',
    add_staff_member: 'Añadir miembro del personal',
    cancel: 'Cancelar',
    is_required: 'es requerido',
    clients: 'Clientes',
    search: 'Buscar',
    eligible: 'Elegible',
    ineligible: 'No elegible',
    alert_type: 'Tipo de alerta',
    mood: 'Estado de ánimo',
    assessment: 'Evaluación',
    eligibility: 'Elegibilidad',
    refine: 'Refinar',
    apply_filters: 'Aplicar filtros',
    reset_filters: 'Restablecer filtros',
    columns: {
        name: 'Nombre',
        clients: 'Clientes',
        status: 'Estado',
        diagnosis: 'Diagnóstico',
        alerts: 'Alertas',
    },
    pending: 'Pendiente',
    resend_invitation: 'Reenviar invitación',
    alerts: 'Alertas',
    view_all: 'Ver todo',
    no_alerts: 'No hay alertas',
    compliance_score: 'Puntuación de cumplimiento',
    calls: 'Llamadas',
    has_call_scheduled: 'Tiene llamada programada',
    mood: 'Estado de ánimo',
    assessment: 'Evaluación',
}
