import DesktopDownloadApp from './components/download-app/DesktopDownloadApp'
import useWindowSize from '../common/useWindowResize'
import MobileDownloadApp from './components/download-app/MobileDownloadApp'
export default function DownloadApp() {
    const { width } = useWindowSize()

    const isMobile = width < 768

    if (isMobile) return <MobileDownloadApp />

    return <DesktopDownloadApp />
}

DownloadApp.propTypes = {}
DownloadApp.defaultProps = {}
