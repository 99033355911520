import * as yup from 'yup';

// This schema is used to validate the
// invitation data in InvitationModal.js

export const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().nullable(),
  email: yup.string().email(),
  phone_number: yup.string(),
  groups: yup.array(),
  cohort: yup.array().nullable(),
  roadmaps_info: yup.array().nullable(),
  coaches: yup.array().nullable(),
  bio: yup.string(),
  isApproved: yup.bool(),
  photo: yup.mixed().test('fileFormat', 'Image file only', (value) => {
    return value && value.length > 0
      ? IMAGE_FORMATS.includes(value[0].type)
      : true;
  }),
});

const IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
