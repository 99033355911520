import { Form, Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { CustomDialog, DesktopHeader } from '../common'
import SuccessModal from '../common/SuccessModal'
import useEffectiveBackLink from '../common/useEffectiveBackLinkHook'
import { useAddUser } from '../dashboard/redux/addUser'
import { useFetchAssignedUsers } from '../dashboard/redux/fetchAssignedUsers'
import { useBulkAssignUserRoadmaps } from '../manage/redux/bulkAssignUserRoadmaps'
import { useDeleteUser } from '../manage/redux/deleteUser'
import { useSendWelcomeEmail } from '../manage/redux/sendWelcomeEmail'
import { useUpdateUser } from '../manage/redux/updateUser'
import AssignCoachForm from './components/EditProfile/AssignCoachForm'
import AssignCohortsForm from './components/EditProfile/AssignCohortsForm'
import AssignGroupsForm from './components/EditProfile/AssignGroupsForm'
import AssignRoadmapsForm from './components/EditProfile/AssignRoadmapsForm'
import BasicInfoForm from './components/EditProfile/BasicInfoForm'
import Switches from './components/EditProfile/Switches'
import UploadProfilePictureForm from './components/EditProfile/UploadProfilePictureForm'
import {
    CurrentUserIsAdmin,
    CurrentUserIsCoachOrAdmin,
    CurrentUserIsInternalCoach,
} from '../../common/roles'

export default function EditProfile() {
    const isAdmin = CurrentUserIsAdmin()

    const isCoachOrAdmin =
        CurrentUserIsCoachOrAdmin() && !CurrentUserIsInternalCoach()

    const params = useParams()
    const history = useHistory()

    const backLink =
        useEffectiveBackLink(history.location.state?.backLink) ||
        '/manage/accounts'

    let userId = Number(params.userId)
    let isEditing = userId

    const { sendWelcomeEmail } = useSendWelcomeEmail()
    const { updateUser } = useUpdateUser()
    const { fetchAssignedUsers, assignedUsers } = useFetchAssignedUsers()
    const { addUser } = useAddUser()
    const { bulkAssignUserRoadmaps } = useBulkAssignUserRoadmaps()
    const { deleteUser } = useDeleteUser()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const handleDeleteHideModal = useCallback(
        () => setShowDeleteModal(false),
        []
    )

    const handleSave = async values => {
        delete values.photo
        if (isEditing) {
            Promise.all([
                updateUser({
                    userId,
                    data: {
                        ...values,
                    },
                }),
                bulkAssignUserRoadmaps({
                    userId,
                    ids:
                        values.roadmaps_info.length > 0
                            ? values.roadmaps_info.map(r => r.id)
                            : [],
                }),
            ])
                .then(() => {
                    history.push(backLink)
                })
                .catch(error => {
                    console.error(error)
                })
        } else {
            let newUserId
            addUser({
                ...values,
            })
                .then(res => {
                    newUserId = res.id
                    if (values.roadmaps_info?.length > 0) {
                        bulkAssignUserRoadmaps({
                            userId: newUserId,
                            ids: values.roadmaps_info,
                        })
                    } else return true
                })
                .then(() => history.push(backLink))
                .catch(error => {
                    console.error(error)
                })
        }
    }

    const handleSendWelcomeEmailClick = useCallback(() => {
        sendWelcomeEmail({ userId }).then(() => setShowSuccessModal(true))
    }, [sendWelcomeEmail, userId])

    const handleDeleteAccount = useCallback(() => {
        deleteUser({ userId })
            .then(() => {
                history.push(`/manage/accounts`)
            })
            .catch(e => console.error(e))
    }, [deleteUser, userId, history, backLink])

    useEffect(() => {
        if (userId) {
            fetchAssignedUsers({ userId })
        }
        fetchAssignedUsers({ type: 'Coach' })
    }, [fetchAssignedUsers, userId])

    const [formikInitialValues, setFormikInitialValues] = useState(
        initialValues
    )

    useEffect(() => {
        if (
            assignedUsers &&
            assignedUsers.results.length > 0 &&
            !formikInitialValues?.id // this line here is to prevent the form from re-rendering when the user is editing a user and the initial values were already set
        ) {
            setFormikInitialValues(
                assignedUsers.results.find(u => u.id === userId)
            )
        }
    }, [assignedUsers, userId])

    useEffect(() => {
        console.log('formikInitialValues', formikInitialValues)
    }, [formikInitialValues])

    if (
        (isEditing && !assignedUsers) ||
        (isEditing && !formikInitialValues?.id)
    ) {
        return null
    }

    return (
        <div className="user-edit-profile">
            <DesktopHeader />
            <Formik
                initialValues={formikInitialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    //alert(JSON.stringify(values, null, 2));
                    console.log('values', values)
                    handleSave(values)
                    setSubmitting(false)
                }}
            >
                {props => (
                    <Form>
                        <h1 className="title">
                            {isEditing ? 'Edit Profile' : 'Create Profile'}
                        </h1>
                        <UploadProfilePictureForm />
                        <BasicInfoForm />
                        {isAdmin && <AssignGroupsForm />}

                        <AssignCoachForm />
                        {isAdmin && <AssignCohortsForm />}

                        <AssignRoadmapsForm />
                        <Switches />
                        <button
                            className="cancel-button"
                            onClick={() => history.push(backLink)}
                            type="button"
                        >
                            Cancel
                        </button>
                        <button className="edit-profile-button" type="submit">
                            {isEditing ? 'Update Profile' : 'Create Profile'}
                        </button>

                        <div className="flex items-center w-full gap-1">
                            <button
                                className="cancel-button"
                                onClick={handleSendWelcomeEmailClick}
                                type="button"
                            >
                                Send Welcome Email
                            </button>
                            <button
                                className="cancel-button"
                                onClick={() => setShowDeleteModal(true)}
                                disabled={!isAdmin}
                                type="button"
                            >
                                Delete Account
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
            <CustomDialog
                text={{
                    caption:
                        'Deleting an account is permanent. There is no way to undo this.',
                    yes: 'Delete Account',
                }}
                show={showDeleteModal}
                onHide={handleDeleteHideModal}
                onYes={handleDeleteAccount}
            />
            <SuccessModal
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                title="Success!"
                message="A welcome email has been sent to the user."
            />
        </div>
    )
}

export const validationSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
    // if phone is '+' or '' then it's valid else it's invalid
    phone_number: yup
        .string()
        .test(
            'phone',
            'Invalid phone number, make sure it starts with a + to indicate the country code',
            value => {
                if (value === '+' || value === '') {
                    return true
                }
                return /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{8,14}$/.test(
                    value
                )
            }
        ),
    coach_write_only: yup.array(),
    groups: yup.array().required(),
    cohort: yup.array(),
    roadmaps_info: yup.array().nullable(),
    is_approved: yup.bool(),
    is_internal_coach: yup.bool(),
    photo: yup.string().nullable(),
    create_upheal_account: yup.bool(),
})

export const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    coach_write_only: [],
    groups: [],
    cohort: [],
    roadmaps_info: [],
    is_approved: false,
    is_internal_coach: false,
    photo: null,
}

EditProfile.propTypes = {}
EditProfile.defaultProps = {}
