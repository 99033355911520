import React from 'react';
import { useHasActiveSubcription } from '../../../../common/permissions';
import { UserAvatar } from '../../../common';

export default function ProfilePictureContainer({ user, onEdit, styles = {} }) {
  const { subscriptionState } = useHasActiveSubcription();

  return (
    <div className="profile-picture-container" style={{ ...styles }}>
      <div className="profile-picture-info">
        <UserAvatar user={user} size="md" className="avatar" />
        <div>
          {' '}
          <p className="name">
            {' '}
            {user.first_name} {user.last_name}{' '}
          </p>{' '}
          {user.groups.includes('User') && (
            <p
              className="tag"
              style={{
                backgroundColor:
                  subscriptionState === 'active' ? '#71837140' : '#6E95A940',
                color: subscriptionState === 'active' ? '#718371' : '#6E95A9',
              }}
            >
              {!subscriptionState && user.groups.includes('User')
                ? 'inactive'
                : subscriptionState}
            </p>
          )}
        </div>
      </div>
      <button onClick={onEdit} className="d-lg-none">
        <p>Edit Profile</p>
      </button>
    </div>
  );
}
