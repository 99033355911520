import { useFormikContext } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useGetSubscriptionStatusByEmail } from '../../../../user/redux/getSubscriptionStatusByEmail'
import { SupportContext } from '../../../Dashboard'
import StepsController from '../../StepsController'
import CustomSelect from '../customInputs/CustomSelect'
import TextInput from '../customInputs/TextInput'
import StepHeader from '../StepHeader'
import SetCompanies from '../utils/useEffects/SetCompanies'
import SetZip from '../utils/useEffects/SetZip'
import { ClientInfoSchema } from '../yup/ClientInfoSchema'
import debounce from 'lodash/debounce'

export default function ClientInfoForm() {
    const {
        values,
        setFieldValue,
        errors,
        touched,
        setFieldTouched,
    } = useFormikContext()
    const {
        steps,
        setSteps,
        stepValues,
        userExists,
        setUserExists,
        isCompleted,
        setCompanyName,
    } = useContext(SupportContext)

    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState({})

    // console.log('companies', companies)

    const [zipError, setZipError] = useState(false)

    const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail()

    useEffect(() => {
        if (companies.length === 1) {
            setSelectedCompany({
                value: companies[0].value,
                label: companies[0].label,
                coaching_group: companies[0].coaching_group,
            })

            setFieldValue('company', companies[0].value)
            setCompanyName(companies[0].label)
            if (companies[0].coaching_group !== true) {
                setFieldValue('coaching', '')
                setSelectedCompany({})
            }
        }
    }, [companies])

    useEffect(() => {
        if (!values.insurance_provider) {
            setFieldValue('insurance_provider', '')
            setFieldValue('member_id', '')
            setFieldValue('policy_holder', '')
            setFieldValue('policy_holder_dob', '')
            setFieldValue('group_number', '')
        }
    }, [values.insurance_provider])

    async function getSubscriptionStatus() {
        getSubscriptionStatusByEmail({
            email: values.email,
        })
            .then(res => {
                setUserExists(
                    res.data.current_subscription_state === 'active'
                        ? true
                        : false
                )
                console.log('res', res)
            })
            .catch(err => {
                console.log('err', err)
                setUserExists(false)
            })
    }

    const verify = useCallback(
        debounce(() => {
            getSubscriptionStatus()
        }, 500),
        []
    )

    useEffect(() => {
        if (!values.email) return

        verify()
    }, [values.email, isCompleted])

    return (
        <React.Fragment>
            <SetZip setZipError={setZipError} />
            <SetCompanies setCompanies={setCompanies} />
            <StepHeader
                onClick={() => {
                    StepsController(
                        1,
                        ClientInfoSchema,
                        setSteps,
                        steps,
                        stepValues
                    )
                }}
                className={`${steps[4].state === 'active' && 'd-none'}`}
            />

            <p
                className={`subform-header ${(steps[1].state !== 'active' ||
                    steps[4].state === 'active') &&
                    'd-none'}`}
            >
                Company
            </p>

            <CustomSelect
                id="company"
                placeholder={
                    companies.length === 1 ? companies[0].label : 'Company'
                }
                className={`${(steps[1].state !== 'active' ||
                    steps[4].state === 'active') &&
                    'd-none'}`}
                icon="company"
                options={companies}
                onChange={e => {
                    setFieldValue('company', e.value)
                    console.log(e)
                    setCompanyName(e.label)

                    setSelectedCompany({
                        value: e.value,
                        label: e.label,
                        coaching_group: e.coaching_group,
                    })
                    if (e.coaching_group !== true) {
                        setFieldValue('coaching', '')
                        setSelectedCompany({})
                    }
                }}
                value={values.company.value}
                error={errors.company}
                touched={touched.company}
            />

            <div
                className={`${(steps[1].state !== 'active' ||
                    steps[4].state === 'active') &&
                    'd-none'}`}
            />

            {steps[1].state === 'active' && steps[4].state !== 'active' && (
                <p className="subform-header">Personal</p>
            )}

            {steps[1].state === 'active' && steps[4].state !== 'active' && (
                <React.Fragment>
                    <TextInput
                        placeholder="First Name"
                        icon="user"
                        name="first_name"
                    />
                    <TextInput
                        placeholder="Last Name"
                        icon="user"
                        name="last_name"
                    />

                    <TextInput
                        placeholder="Phone Number"
                        icon="phone"
                        name="phone_number"
                        maxLength={15}
                    />

                    <TextInput
                        placeholder="Email"
                        icon="email"
                        name="email"
                        error={userExists ? 'User has an active account' : ''}
                    />
                </React.Fragment>
            )}

            {steps[1].state === 'active' && steps[4].state !== 'active' && (
                <p className="subform-header">
                    Insurance{' '}
                    <span
                        style={{
                            fontWeight: 300,
                            fontSize: '0.625rem',
                            marginLeft: '0.5rem',
                            lineHeight: 2,
                        }}
                    >
                        {!values.insurance_provider &&
                            '(Insurance Provider will enable the rest of the insurance fields)'}
                    </span>
                </p>
            )}
            {steps[1].state === 'active' && steps[4].state !== 'active' && (
                <React.Fragment>
                    <TextInput
                        placeholder="Insurance Provider"
                        icon="shield"
                        name="insurance_provider"
                    />

                    <TextInput
                        placeholder="Member ID"
                        name="member_id"
                        icon="health-id"
                        disabled={!values.insurance_provider}
                    />
                    <TextInput
                        placeholder="Policy Holder"
                        name="policy_holder"
                        icon="user"
                        disabled={
                            !values.insurance_provider || !values.member_id
                        }
                    />
                    <TextInput
                        placeholder="Policy Holder Date of Birth"
                        name="policy_holder_dob"
                        icon="user"
                        type="text"
                        onFocus={e => {
                            e.currentTarget.type = 'date'
                        }}
                        onBlur={e => {
                            e.currentTarget.type = 'text'
                        }}
                        disabled={
                            !values.insurance_provider || !values.member_id
                        }
                    />

                    <TextInput
                        placeholder="Group Number"
                        name="group_number"
                        icon="health-id"
                        disabled={
                            !values.insurance_provider || !values.member_id
                        }
                    />
                </React.Fragment>
            )}

            {steps[1].state === 'active' && steps[4].state !== 'active' && (
                <p className="subform-header">Address</p>
            )}
            {steps[1].state === 'active' && steps[4].state !== 'active' && (
                <React.Fragment>
                    <TextInput
                        placeholder="Address line 1"
                        icon="location"
                        name="address_1"
                    />
                    <TextInput
                        placeholder="Address line 2"
                        icon="location"
                        name="address_2"
                    />
                    <TextInput
                        placeholder="Zip"
                        icon="location"
                        name="zip"
                        error={zipError || ''}
                        maxLength={5}
                        message={
                            values.zip === '00000' && 'International zip code'
                        }
                    />

                    <TextInput
                        placeholder="City"
                        icon="location"
                        name="city_temp"
                        disabled={values.zip !== '00000' && !zipError} // 00000 means no zip or international
                        // do not update state until you leave the field
                        onBlur={e => {
                            setFieldValue('city', e.target.value)
                        }}
                        value={
                            zipError || values.zip === '00000'
                                ? values.city_temp
                                : values.city
                        }
                    />
                    <TextInput
                        placeholder="State"
                        icon="location"
                        disabled={values.zip !== '00000' && !zipError}
                        name="state_temp"
                        onBlur={e => {
                            setFieldValue('state', e.target.value)
                        }}
                        value={
                            zipError || values.zip === '00000'
                                ? values.state_temp
                                : values.state
                        }
                    />
                </React.Fragment>
            )}

            <div
                className={`support-client-info-extra-dropdown ${
                    selectedCompany.coaching_group === true ? '' : 'd-none'
                } ${(steps[1].state !== 'active' ||
                    steps[4].state === 'active') &&
                    'd-none'}`}
            >
                <div className="triangle-up" />
                <CustomSelect
                    className="coaching-group"
                    id="coaching"
                    placeholder="Coaching Group Time"
                    icon="group"
                    iconColor="var(--primary-dark-green)"
                    options={[
                        {
                            value: '1',
                            label: 'Option 1',
                        },
                        {
                            value: '2',
                            label: 'Option 2',
                        },
                    ]}
                    onChange={e => {
                        setFieldValue('coaching', e.value)
                    }}
                    value={values.coaching.value}
                    error={errors.coaching}
                    touched={touched.coaching}
                />
            </div>
        </React.Fragment>
    )
}
