import React, { Fragment } from 'react';
import Icon from '../../../common/components/Icon';

export default function TabSelector({ selectedTab, setSelectedTab }) {
  return (
    <Fragment>
      <div className="tab-selector">
        <div
          className="tab"
          onClick={() => setSelectedTab('left')}
          style={{
            ...(selectedTab === 'right' && { opacity: 0.5 }),
          }}
        >
          <Icon name="client" />
          <div className="title-container">
            <h3>I am a Client</h3>
            <p>I am seeking help for myself</p>
          </div>
        </div>
        <div
          className="tab"
          onClick={() => setSelectedTab('right')}
          style={{
            ...(selectedTab === 'left' && { opacity: 0.5 }),
          }}
        >
          <Icon name="client" />
          <div className="title-container">
            <h3>I am a Caregiver</h3>
            <p>I am supporting a client</p>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#ebece9',
          width: '85%',
          height: '5px',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        <div
          className="active"
          style={{
            backgroundColor: '#79987D',
            width: '50%',
            position: 'absolute',
            height: '5px',
            ...(selectedTab === 'left' ? { left: '0' } : { left: '50%' }),
            transition: 'all 0.3s ease-in-out',
          }}
        />
      </div>
    </Fragment>
  );
}
