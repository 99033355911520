import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSpinnerThird,
} from '@fortawesome/pro-duotone-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

export default function GreenAssessmentApprovePanel({
  assessmentApprovedStatus,
  handleApproveAssessmentClick,
  approveCompetencyAssessmentPending,
  className = '',
}) {
  return (
    <div className={clsx('assessment-approve', className)}>
      <button
        className={`${
          assessmentApprovedStatus === 'rejected' ? 'rejected' : ''
        }`}
        onClick={handleApproveAssessmentClick(false)}
        disabled={!!approveCompetencyAssessmentPending}
      >
        {approveCompetencyAssessmentPending === 'reject' ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="mrm-mr-0_5"
            spin
            size="xs"
          />
        ) : (
          assessmentApprovedStatus === 'rejected' && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mrm-mr-0_5"
              size="sm"
            />
          )
        )}
        Needs Work
      </button>
      <button
        className={`${
          assessmentApprovedStatus === 'approved' ? 'approved' : ''
        }`}
        onClick={handleApproveAssessmentClick(true)}
        disabled={!!approveCompetencyAssessmentPending}
      >
        {approveCompetencyAssessmentPending === 'approve' ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="mrm-mr-0_25"
            spin
            size="xs"
          />
        ) : (
          assessmentApprovedStatus === 'approved' && (
            <FontAwesomeIcon icon={faCheck} className="mrm-mr-0_25" size="sm" />
          )
        )}
        Approve
      </button>
    </div>
  );
}
