import React from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../../../common/components/Icon';

import { useTranslation } from '../../../translations/redux/setDefaultLanguage';
export default function Tabs({ setSelectedTab, selectedTab }) {
  const { t } = useTranslation();
  const tabs = [
    {
      name: 'all',
      label: t('messages.all'),
    },
    {
      name: 'messages',
      label: t('messages.messages'),
    },
    {
      name: 'group',
      label: t('messages.group'),
    } /*
  {
    name: 'Archived',
  }, */,
  ];
  return (
    <div className="content-tabs">
      {tabs &&
        tabs.map((tab, idx) => {
          return (
            <div
              key={idx}
              className="tab"
              onClick={() => {
                setSelectedTab(tab.name);
                window.history.replaceState({}, '', '/messages/default');
              }}
            >
              <p>{tab.label}</p>
              {tab.name === selectedTab && <Icon name="line" size={4} />}
            </div>
          );
        })}
    </div>
  );
}
