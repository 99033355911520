import clsx from 'clsx';
import dayjs from 'dayjs';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useUnauthorizedErrorHandler } from '../../../common/apiHelpers';
import { useApproveCompetencyAssessment } from '../redux/approveCompetencyAssessment';
import { useFetchCompetencyAssessments } from '../redux/fetchCompetencyAssessments';
import { useSetCompetencyAssessment } from '../redux/setCompetencyAssessment';
import GreenAssessmentApprovePanel from './GreenAssessmentApprovePanel';

export default function SelfReflection({
  className,
  roadmapId,
  competency,
  refetchCompetency,
  student,
  user,
}) {
  const { setCompetencyAssessment } = useSetCompetencyAssessment();
  const { approveCompetencyAssessment, approveCompetencyAssessmentPending } =
    useApproveCompetencyAssessment();
  const { competencyAssessments, fetchCompetencyAssessments } =
    useFetchCompetencyAssessments();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();
  const [
    assessmentApprovedStatusOverride,
    setAssessmentApprovedStatusOverride,
  ] = useState(undefined);
  const [refreshSlider, setRefreshSlider] = useState();

  useEffect(() => {
    if (!roadmapId || !competency.stage || !competency.id || !user) {
      return;
    } else {
      const args = {
        roadmapId,
        stageId: competency.stage,
        competencyId: competency.id,
        student,
      };
      fetchCompetencyAssessments(args);
    }
  }, [competency, student, user]);

  const lastCompetencyAssessmentBy = useCallback(
    (userId) => {
      if (competencyAssessments) {
        const assessments = competencyAssessments.filter(
          (i) => i.user === userId,
        );
        if (assessments.length > 0) {
          return assessments[0];
        }
      }
      return null;
    },
    [competencyAssessments],
  );

  let assessment = lastCompetencyAssessmentBy(user.id);
  let studentAssessment = lastCompetencyAssessmentBy(student || user.id);

  const [loading, setLoading] = useState(false);

  const setCompetencyAssessmentHandlingErrors = useCallback(
    async ({ status, sliderStatus }) => {
      setLoading(true);
      setCompetencyAssessment({
        roadmapId,
        stageId: competency.stage,
        competencyId: competency.id,
        studentId: student,
        status,
        sliderStatus,
      })
        .catch(unauthorizedErrorHandler)
        .then(() => {
          refetchCompetency();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      setCompetencyAssessment,
      roadmapId,
      competency,
      student,
      unauthorizedErrorHandler,
      refetchCompetency,
    ],
  );

  const canAssess = useCallback(() => {
    if (user.groups.includes('User')) {
      if (user.groups.includes('Admin') || user.groups.includes('Coach')) {
        if (student === user.id) {
          return true;
        } else {
          return user.features.coach_or_admin_can_assess_objectives;
        }
      } else {
        return true;
      }
    } else if (user.groups.includes('Admin') || user.groups.includes('Coach')) {
      return user.features.coach_or_admin_can_assess_objectives;
    } else {
      return true;
    }
  }, [student, user]);

  const handleApproveAssessmentClick = useCallback(
    (approved) => () => {
      if (!studentAssessment) {
        return;
      }
      const args = {
        roadmapId,
        approved,
        stageId: competency.stage,
        competencyId: competency.id,
        assessmentId: studentAssessment.id,
      };
      approveCompetencyAssessment(args)
        .then(() => {
          setAssessmentApprovedStatusOverride(
            approved ? 'approved' : 'rejected',
          );
        })

        .catch(unauthorizedErrorHandler);
    },
    [
      roadmapId,
      competency,
      studentAssessment,
      approveCompetencyAssessment,
      unauthorizedErrorHandler,
    ],
  );

  const handleChangeSlider = useCallback(
    (value) => {
      setCompetencyAssessmentHandlingErrors({ sliderStatus: value });
      if (
        value >= 7.5 &&
        user.features.coach_approves_green_assessments &&
        (user.groups.includes('Admin') || user.groups.includes('Coach'))
      ) {
        handleApproveAssessmentClick(true)();
      }
    },
    [setCompetencyAssessmentHandlingErrors, handleApproveAssessmentClick, user],
  );

  useEffect(() => {
    setRefreshSlider(Math.random().toString(36).substring(7));
  }, [assessment]);

  const lastSubmission = (date) => {
    const diff = dayjs(date).diff(dayjs(), 'day');
    if (diff === 0) {
      return 'Today';
    } else {
      return dayjs(date).fromNow();
    }
  };

  const handleCompetencyAssessmentClick = useCallback(
    (status) => () => {
      setCompetencyAssessmentHandlingErrors({ status });
      if (
        status === 3 &&
        user.features.coach_approves_green_assessments &&
        (user.groups.includes('Admin') || user.groups.includes('Coach'))
      ) {
        handleApproveAssessmentClick(true)();
      }
    },
    [
      setCompetencyAssessmentHandlingErrors,
      handleApproveAssessmentClick,
      user,
      assessment,
    ],
  );

  const showApproveButton = useMemo(
    () =>
      !!studentAssessment &&
      studentAssessment.status === '3' &&
      (user.groups.includes('Admin') || user.groups.includes('Coach')) &&
      user.features.coach_approves_green_assessments,
    [studentAssessment, user],
  );

  const [effectiveAssessment, setEffectiveAssessment] = useState(
    (user.groups.includes('Admin') || user.groups.includes('Coach')) &&
      user.features.coach_or_admin_can_assess_objectives
      ? assessment
      : studentAssessment,
  );

  useEffect(() => {
    setEffectiveAssessment(
      (user.groups.includes('Admin') || user.groups.includes('Coach')) &&
        user.features.coach_or_admin_can_assess_objectives
        ? assessment
        : studentAssessment,
    );
  }, [studentAssessment, assessment, assessmentApprovedStatusOverride]);

  const defaultAssessmentApprovedStatus = useMemo(() => {
    if (studentAssessment && studentAssessment.status === '3') {
      if (studentAssessment.approved) {
        return 'approved';
      } else if (studentAssessment.rejected) {
        return 'rejected';
      }
    }
    return null;
  }, [studentAssessment]);
  const assessmentApprovedStatus =
    assessmentApprovedStatusOverride || defaultAssessmentApprovedStatus;

  let marks = Array(11);

  if (!competencyAssessments) return null;

  return (
    <Row
      className={clsx('self-reflection', className, {
        'd-none':
          (competency[`red_description`].length === 0) &
          (competency[`yellow_description`].length === 0) &
          (competency[`green_description`].length === 0) &
          (user.features.slider_for_competency_assessment === false),
      })}
    >
      <Col>
        <div className="flex space-between items-center">
          <div className="flex items-center">
            <h2 className="assessment-title">How are you feeling?</h2>
            {effectiveAssessment && effectiveAssessment.date && (
              <span>
                (
                {`last submission: ${lastSubmission(effectiveAssessment.date)}`}
                )
              </span>
            )}
          </div>
          {loading && <Spinner animation="border" size="sm" />}
        </div>

        <div
          className={clsx('assessment-container', {
            'mrm-py-1': user.features.slider_for_competency_assessment,
            'assessment-slider-container':
              user.features.slider_for_competency_assessment,
            'd-none':
              (competency[`red_description`].length === 0) &
              (competency[`yellow_description`].length === 0) &
              (competency[`green_description`].length === 0) &
              (user.features.slider_for_competency_assessment === false),
          })}
        >
          {/*  {loading && <Loader position="local" />} */}
          {user && user.features.slider_for_competency_assessment ? (
            <>
              <p>How do you feel like you are doing in this area?</p>
              <div className="slider-container" key={refreshSlider}>
                <Slider
                  marks={marks}
                  min={1}
                  max={10}
                  step={0.1}
                  defaultValue={
                    (effectiveAssessment &&
                      effectiveAssessment.slider_status) ||
                    5.5
                  }
                  disabled={!canAssess()}
                  trackStyle={{ background: 'transparent' }}
                  onAfterChange={handleChangeSlider}
                />
              </div>
            </>
          ) : (
            ['red', 'yellow', 'green'].map((color, i) => (
              <Row
                className={clsx(
                  'assessment',
                  color,
                  {
                    active:
                      (!!effectiveAssessment
                        ? Number(effectiveAssessment?.status)
                        : null) ===
                      i + 1,
                  },
                  {
                    'd-none': competency[`${color}_description`].length === 0,
                  },
                )}
                noGutters
                onClick={
                  canAssess()
                    ? handleCompetencyAssessmentClick(i + 1)
                    : undefined
                }
                key={color}
              >
                <Col xs={12} className="d-none assessment-header" />
                <Col className="" xs={1}>
                  {(!!effectiveAssessment
                    ? Number(effectiveAssessment.status)
                    : null) ===
                  i + 1 ? (
                    <p className="circle selected " />
                  ) : (
                    <p className="circle" />
                  )}
                </Col>
                <Col xs={10} className="align-self-start">
                  <Card.Body>
                    <Card.Title>
                      <p className="card-title-options">
                        {competency[`${color}_description`]}
                      </p>
                    </Card.Title>
                  </Card.Body>
                </Col>
              </Row>
            ))
          )}
        </div>
        {showApproveButton && (
          <GreenAssessmentApprovePanel
            approveCompetencyAssessmentPending={
              approveCompetencyAssessmentPending
            }
            assessmentApprovedStatus={assessmentApprovedStatus}
            handleApproveAssessmentClick={handleApproveAssessmentClick}
          />
        )}
      </Col>
    </Row>
  );
}
