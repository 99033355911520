import React, { Fragment } from 'react';
import { useFetchRoadmaps } from '../../../redux/fetchRoadmaps';
import FilterToastItem from './FilterToastItem';
import Icon from '../../../../common/components/Icon';
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage';

export default function RoadmapsFilter({ ...props }) {
  const { setToastState, toastState } = props;

  const { roadmaps } = useFetchRoadmaps();

  const publishedRoadmaps =
    roadmaps.results.filter((roadmap) => roadmap.is_published) || [];

  const selectRoadmap = (roadmap) => {
    if (toastState.selectedEnrolledRoadmaps.has(roadmap.id)) {
      toastState.selectedEnrolledRoadmaps.delete(roadmap.id);
    } else {
      toastState.selectedEnrolledRoadmaps.add(roadmap.id);
    }
    setToastState({ ...toastState });
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      <FilterToastItem
        setToastState={setToastState}
        toastState={toastState}
        setName="selectedEnrolledRoadmaps"
        switchName="openEnrolledRoadmaps"
        title={t('dashboard.clients.filters.enrolled_roadmaps')}
      />
      {toastState.openEnrolledRoadmaps && (
        <div className="list-toast">
          {publishedRoadmaps.length &&
            publishedRoadmaps.map((roadmap, idx) => (
              <Fragment key={roadmap.id}>
                <div
                  key={roadmap.id}
                  className="list-toast-item"
                  onClick={() => selectRoadmap(roadmap)}
                >
                  <Icon
                    name="checkbox"
                    active={toastState.selectedEnrolledRoadmaps.has(roadmap.id)}
                  />
                  <p>{roadmap.title}</p>
                </div>
                {idx !== publishedRoadmaps.length - 1 && (
                  <hr className="divider" />
                )}
              </Fragment>
            ))}
        </div>
      )}
    </Fragment>
  );
}
