const initialState = {
  authToken: null,
  fetchAuthTokenPending: false,
  fetchAuthTokenError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  confirmResetPasswordPending: false,
  confirmResetPasswordError: null,
  registerAccountPending: false,
  registerAccountError: null,
  unsubscribeUserPending: false,
  unsubscribeUserError: null,
  acceptCoachInvitationPending: false,
  acceptCoachInvitationError: null,
  fetchCoachInvitationPending: false,
  fetchCoachInvitationError: null,
  paymentCheckoutPending: false,
  paymentCheckoutError: null,
  nobleCoachInvitationPending: false,
  nobleCoachInvitationError: null,
  createStripeAccountLinkPending: false,
  createStripeAccountLinkError: null,
  refreshStripeAccountLinkPending: false,
  refreshStripeAccountLinkError: null,
  checkStatusStripeAccountLinkPending: false,
  checkStatusStripeAccountLinkError: null,
  getInvoicesStripePending: false,
  getInvoicesStripeError: null,
  getActiveSubscriptionPending: false,
  getActiveSubscriptionError: null,
  createProductSubscriptionPending: false,
  createProductSubscriptionError: null,
  createPriceSubscriptionPending: false,
  createPriceSubscriptionError: null,
  getListProductSubscriptionPending: false,
  getListProductSubscriptionError: null,
};

export default initialState;
