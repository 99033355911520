import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { DesktopHeader, UserAvatar } from '../common';
import Sidebar from './Sidebar';
import { useListSubscriptionByCoach } from '../user/redux/listSubscriptionByCoach';
import TabSelector from './components/TabSelector';
import { useFetchUser } from '../user/redux/fetchUser';
import Icon from '../common/components/Icon';
import InvitationModal from './components/InvitationModal';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../common/windowsSize';

export default function Clients() {
  const history = useHistory();
  const { user } = useFetchUser();
  const size = useWindowSize();
  const { listSubscriptionByCoach } = useListSubscriptionByCoach();

  const [subscribersList, setSubscribersList] = React.useState([]);

  useEffect(() => {
    const getAllSubscribers = async () => {
      listSubscriptionByCoach()
        .then((res) => {
          setSubscribersList(res.data.results);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getAllSubscribers();
  }, [listSubscriptionByCoach, setSubscribersList]);

  const [showInvitationModal, setShowInvitationModal] = React.useState(false);

  return (
    <>
      {' '}
      <DesktopHeader />
      <div className="dashboard-clients">
        <InvitationModal
          show={showInvitationModal}
          handleClose={() => {
            setShowInvitationModal(false);
          }}
        />
        <Sidebar />
        <div className="main-content">
          <TabSelector />

          <div className="main-header">
            <p className={`${size.width < 768 && 'd-none'}`}>
              {user?.synonyms?.user}s (
              {subscribersList && subscribersList.length})
            </p>
            <button
              className={`add-sub ${size.width < 768 && 'd-none'}`}
              onClick={() => setShowInvitationModal(true)}
            >
              <Icon name="plus" size={12} color="#FFFFFF" />
              <span>Add New</span>
            </button>
          </div>

          <table className="subscriptions-table">
            <thead>
              <tr className="header">
                <th className="first"></th>
                <th>Name</th>
                <th>Plan</th>
                <th>Cost</th>
                <th>Start</th>
                <th>End</th>
                <th>Status</th>
              </tr>
            </thead>
            {subscribersList &&
              subscribersList.map((row, idx) => {
                let createdAt = new Date(row.created_at);
                createdAt = createdAt.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                });

                let endDate = new Date(row.current_period_end);
                endDate = endDate.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                });

                return (
                  <tbody key={idx}>
                    <tr>
                      <td className="first">
                        <UserAvatar user={row.user} />
                      </td>
                      <td>
                        <div className="user">
                          <div className="title">
                            {' '}
                            <h3>{row.user.first_name}</h3>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>{row.plan.name}</p>
                      </td>
                      <td>
                        <p>${row.plan.unit_amount / 100}/mo</p>
                      </td>
                      <td>
                        <p>{createdAt}</p>
                      </td>
                      <td>
                        <p>{endDate}</p>
                      </td>
                      <td>
                        <p className={`tag ${row.current_state.toLowerCase()}`}>
                          {row.current_state}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
          <div className="mobile-subscribers d-lg-none">
            {size.width < 768 && (
              <div className="main-header">
                <p className="title">
                  {user?.synonyms?.user}s (
                  {subscribersList && subscribersList.length})
                </p>
                <button
                  className="add-sub "
                  onClick={() =>
                    history.push('/dashboard/onboarding-invitation')
                  }
                >
                  <Icon name="plus" size={12} color="#FFFFFF" />
                  <span>Add New</span>
                </button>
              </div>
            )}

            {subscribersList &&
              subscribersList.map((row, idx) => {
                return (
                  <div className="row" key={idx}>
                    <div className="profile-info">
                      <UserAvatar user={row.user} size="md" />
                      <div className="name-container">
                        <p className="client-name">
                          {row.user.first_name} {row.user.last_name}
                        </p>
                        <p className="sub-name">{row.plan.name}</p>
                      </div>
                    </div>
                    <div className="price">
                      <p className="sub-status">{row.current_state}</p>
                      <p className="sub-price">
                        ${row.plan.unit_amount / 100}/mo
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

Clients.propTypes = {};
Clients.defaultProps = {};
