// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import {
  Signup,
  ConnectYourDevice,
  FitbitConnection,
  Dashboard,
  ProviderDashboard,
  DownloadApp,
  ClientProfile,
  CoachDashboard,
  Events,
} from './'

export default {
  path: 'rpm',
  childRoutes: [
    { path: 'signup/:uid/:token', component: Signup, noSignIn: true },
    { path: 'signup/:stepLabel', component: Signup },
    { path: 'connect-your-device', component: ConnectYourDevice },
    { path: 'fitbit-connection', component: FitbitConnection },
    { path: 'fitbit-connection/:step', component: FitbitConnection },
    { path: 'dashboard', component: Dashboard },
    { path: 'provider-dashboard', component: ProviderDashboard },
    { path: 'download-app', component: DownloadApp, noSignIn: true },
    { path: 'client-profile/:userId', component: ClientProfile },
    { path: 'coach-dashboard', component: CoachDashboard },
    { path: 'events/:userId', component: Events },
  ],
}
