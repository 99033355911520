import React from 'react';

export default function Sidebar({ title }) {
  return (
    <div className="subscriptions-internal-subscriptions-sidebar">
      <h1 className="sidebar-header">Manage</h1>
      <h3 className="sidebar-subheader">{title}</h3>
    </div>
  );
}

Sidebar.propTypes = {};
Sidebar.defaultProps = {};
