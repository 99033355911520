import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const LockIcon = ({ size = 24, color = '#343434', style = {}, className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      className={className}
      style={{ height: size, width: size, ...style }}
      fill={color}
    >
      <path
        className="cls-1"
        d="M16.38,8.25a.8.8,0,0,0,0-.15V7.86A6.32,6.32,0,0,0,12.43,2,6.26,6.26,0,0,0,10,1.55,6.4,6.4,0,0,0,7.56,2,6.3,6.3,0,0,0,5.48,3.4,6.25,6.25,0,0,0,3.62,7.9v8.8a.6.6,0,0,0,.6.6H15.75a.6.6,0,0,0,.6-.6V8.39A.7.7,0,0,0,16.38,8.25Zm-10.06-4A5.3,5.3,0,0,1,8,3.13a5.63,5.63,0,0,1,2-.38,6.06,6.06,0,0,1,2,.39,5.1,5.1,0,0,1,2.78,2.77,4.93,4.93,0,0,1,.37,1.74H4.84A5.34,5.34,0,0,1,5.2,5.93,5.05,5.05,0,0,1,6.32,4.25ZM4.82,16.1V8.85H15.15V16.1Z"
      />
      <path
        className="cls-1"
        d="M10,10.44a.6.6,0,0,0-.6.6v2.4a.6.6,0,0,0,1.2,0V11A.6.6,0,0,0,10,10.44Z"
      />
    </svg>
  );
};

export default LockIcon;
