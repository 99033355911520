import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SUPPORT_GROUP_OPENSUPPORTGROUP_BEGIN,
  SUPPORT_GROUP_OPENSUPPORTGROUP_SUCCESS,
  SUPPORT_GROUP_OPENSUPPORTGROUP_FAILURE,
  SUPPORT_GROUP_OPENSUPPORTGROUP_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import axios from 'axios';

export function opensupportgroup(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: SUPPORT_GROUP_OPENSUPPORTGROUP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let url = `${config.apiRootUrl}/supportgroup/opensupportgroup/`;
      args.method === 'get' &&
        !args.id &&
        (url = `${config.apiRootUrl}/supportgroup/opensupportgrouplist/`);

      if (args.id) url += `${args.id}/`;

      const doRequest =
        args.method === 'get'
          ? axios.get(url, createAxiosConfigWithAuth(getState()))
          : args.method === 'post'
          ? axios.post(url, args, createAxiosConfigWithAuth(getState()))
          : args.method === 'put'
          ? axios.put(url, args, createAxiosConfigWithAuth(getState()))
          : args.method === 'delete'
          ? axios.delete(url, createAxiosConfigWithAuth(getState()))
          : axios.get(url, createAxiosConfigWithAuth(getState()));

      doRequest.then(
        (res) => {
          dispatch({
            type: SUPPORT_GROUP_OPENSUPPORTGROUP_SUCCESS,
            data: res.data?.results,
          });
          resolve(res.data?.results);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SUPPORT_GROUP_OPENSUPPORTGROUP_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissOpensupportgroupError() {
  return {
    type: SUPPORT_GROUP_OPENSUPPORTGROUP_DISMISS_ERROR,
  };
}

export function useOpensupportgroup() {
  const dispatch = useDispatch();

  const { supportGroups, opensupportgroupPending, opensupportgroupError } =
    useSelector(
      (state) => ({
        supportGroups: state.supportGroup.supportGroups,
        opensupportgroupPending: state.supportGroup.opensupportgroupPending,
        opensupportgroupError: state.supportGroup.opensupportgroupError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(opensupportgroup(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissOpensupportgroupError());
  }, [dispatch]);

  return {
    supportGroups,
    opensupportgroup: boundAction,
    opensupportgroupPending,
    opensupportgroupError,
    dismissOpensupportgroupError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SUPPORT_GROUP_OPENSUPPORTGROUP_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        opensupportgroupPending: true,
        opensupportgroupError: null,
      };

    case SUPPORT_GROUP_OPENSUPPORTGROUP_SUCCESS:
      // The request is success
      return {
        ...state,
        opensupportgroupPending: false,
        opensupportgroupError: null,
        supportGroups: action.data,
      };

    case SUPPORT_GROUP_OPENSUPPORTGROUP_FAILURE:
      // The request is failed
      return {
        ...state,
        opensupportgroupPending: false,
        opensupportgroupError: action.data.error,
      };

    case SUPPORT_GROUP_OPENSUPPORTGROUP_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        opensupportgroupError: null,
      };

    default:
      return state;
  }
}
