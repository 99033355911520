import axios from 'axios';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import config from '../../../common/config';
import {
  MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_BEGIN,
  MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_DISMISS_ERROR,
  MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_FAILURE,
  MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_SUCCESS,
} from './constants';

export function getMediaMessagesByThread(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_BEGIN,
    });

    let host = `${config.messagesMediaUrl}/mediamessages/${args.threadId}`;

    if (args.isChatThread) host += `/${args.username}`;

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(host, createAxiosConfigWithAuth(getState()));
      doRequest.then(
        (res) => {
          dispatch({
            type: MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetMediaMessagesByThreadError() {
  return {
    type: MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_DISMISS_ERROR,
  };
}

export function useGetMediaMessagesByThread() {
  const dispatch = useDispatch();

  const { getMediaMessagesByThreadPending, getMediaMessagesByThreadError } =
    useSelector(
      (state) => ({
        getMediaMessagesByThreadPending:
          state.messages.getMediaMessagesByThreadPending,
        getMediaMessagesByThreadError:
          state.messages.getMediaMessagesByThreadError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getMediaMessagesByThread(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetMediaMessagesByThreadError());
  }, [dispatch]);

  return {
    getMediaMessagesByThread: boundAction,
    getMediaMessagesByThreadPending,
    getMediaMessagesByThreadError,
    dismissGetMediaMessagesByThreadError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getMediaMessagesByThreadPending: true,
        getMediaMessagesByThreadError: null,
      };

    case MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_SUCCESS:
      // The request is success
      return {
        ...state,
        getMediaMessagesByThreadPending: false,
        getMediaMessagesByThreadError: null,
      };

    case MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_FAILURE:
      // The request is failed
      return {
        ...state,
        getMediaMessagesByThreadPending: false,
        getMediaMessagesByThreadError: action.data.error,
      };

    case MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getMediaMessagesByThreadError: null,
      };

    default:
      return state;
  }
}
