import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router-dom';
import { useFetchUser } from '../../user/redux/fetchUser';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinnerThird,
  faRedo,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

const screenCaptureFileSchema = yup.object().shape({
  filename: yup.string().required(),
});

export default function ScreenCapture({
  addActionItemAttachmentFromData,
  children,
  setShowScreenCapture,
}) {
  const [captureStream, setCaptureStream] = useState(null);
  const [captureError, setCaptureError] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recording, setRecording] = useState(null);
  const [recordingCountdown, setRecordingCountdown] = useState(undefined);

  const { register, handleSubmit, errors, getValues } = useForm({
    resolver: yupResolver(screenCaptureFileSchema),
  });

  useEffect(() => {
    if (!!captureError) setShowScreenCapture(false);
  }, [captureError, setShowScreenCapture]);

  useEffect(() => {
    if (typeof recordingCountdown === 'undefined') return;
    const timeoutHandle = setTimeout(() => {
      const newCountdown = recordingCountdown - 1;
      if (newCountdown <= 0) {
        mediaRecorder.start();
        setRecordingCountdown(undefined);
      } else {
        setRecordingCountdown(newCountdown);
      }
    }, 1000);
    return () => clearTimeout(timeoutHandle);
  }, [recordingCountdown, mediaRecorder]);

  const stopRecording = () => {
    try {
      setIsRecording(false);
      mediaRecorder.stop();
      captureStream.getTracks().forEach((track) => track.stop());
    } catch (e) {
      setCaptureError(e);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          width: 1280,
          height: 720,
        },
      });
      const mic = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.addTrack(mic.getTracks()[0]);
      setIsRecording(true);
      stream.getTracks().forEach((track) => {
        track.onended = stopRecording;
      });
      setCaptureStream(stream);
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        setRecording(event.data);
      };
      setMediaRecorder(recorder);
      setRecordingCountdown(5);
    } catch (e) {
      setIsRecording(false);
      setCaptureError(e);
    }
  };

  const toggleRecording = () =>
    isRecording ? stopRecording() : startRecording();
  const _location = useLocation();
  const { user } = useFetchUser();

  const handleSaveClick = useCallback(
    ({ filename }) => {
      const data = new FormData();
      data.append(
        'attachment',
        new File([recording], filename + '.webm', { type: 'video/webm' }),
      );
      data.append('file_category', 'SCREEN');
      let userId = user.id;
      // If Coach or Admin user, get student user from url params
      if (user.groups.includes('Coach') || user.groups.includes('Admin')) {
        const params = new URLSearchParams(_location.search);
        const _userId = params.get('user') && Number(params.get('user'));
        if (_userId) userId = _userId;
        data.append('user', userId);
      }
      addActionItemAttachmentFromData(data).then(() =>
        setShowScreenCapture(false),
      );
    },
    [
      recording,
      addActionItemAttachmentFromData,
      setShowScreenCapture,
      user,
      _location,
    ],
  );

  const handleEnterKey = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit(handleSaveClick({ filename: getValues('filename') }));
      }
    },
    [handleSaveClick, handleSubmit, getValues],
  );

  return (
    <Row className="screen-capture">
      <Col className="text-center mrm-mb-1">
        {children && (
          <>
            <p>Uploading...</p>
            {children}
            <div className="mrm-mt-1">
              <Button
                variant="gray"
                onClick={() => setShowScreenCapture(false)}
              >
                <FontAwesomeIcon icon={faTimes} className="mr-2" size="xs" />
                Cancel
              </Button>
            </div>
          </>
        )}
        {!children && (
          <>
            <p>
              {recordingCountdown ? (
                `Recording starting in ${recordingCountdown}...`
              ) : isRecording ? (
                <>
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    className="mr-2"
                    size="xs"
                    spin
                  />
                  Recording in progress
                </>
              ) : !!recording ? (
                'Recording done'
              ) : (
                'Recording inactive'
              )}
            </p>
            {(!recording || isRecording) && (
              <div className="mrm-mt-1">
                <button
                  className="start-recording-btn mrm-mr-1"
                  onClick={toggleRecording}
                  disabled={!!recordingCountdown}
                >
                  {isRecording && !recordingCountdown
                    ? 'Stop Recording'
                    : 'Start Recording'}
                </button>
                <Button
                  className="cancel-recording"
                  onClick={() => setShowScreenCapture(false)}
                >
                  Cancel
                </Button>
              </div>
            )}
            {!!recording && !isRecording && (
              <div>
                <Video
                  controls={[
                    'PlayPause',
                    'Seek',
                    'Time',
                    'Volume',
                    'Fullscreen',
                  ]}
                  onCanPlayThrough={() => {
                    // Do stuff
                  }}
                >
                  <source
                    src={recording && URL.createObjectURL(recording)}
                    type="video/webm"
                  />
                </Video>
                <Form className="p-3">
                  <Form.Group controlId="filename">
                    <Form.Label>File Name</Form.Label>
                    <Form.Control
                      name="filename"
                      defaultValue={undefined}
                      isInvalid={errors.filename}
                      ref={register}
                      onKeyDown={handleEnterKey}
                    />
                  </Form.Group>
                  <Button
                    className="mrm-mr-1"
                    variant="primary"
                    onClick={handleSubmit(handleSaveClick)}
                  >
                    Submit Recording
                  </Button>
                  <Button variant="gray" onClick={() => setRecording(null)}>
                    <FontAwesomeIcon icon={faRedo} className="mr-2" size="xs" />
                    Redo Recording
                  </Button>
                </Form>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}
