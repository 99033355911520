import { faBell } from '@fortawesome/pro-light-svg-icons'
import {
    faCreditCard,
    faLifeRing,
    faUserPlus,
} from '@fortawesome/pro-regular-svg-icons'
import React, { useCallback, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link, useHistory } from 'react-router-dom'
import { Loader, SwitchActiveCompanyModal, UserAvatar } from '../common'
import Icon from '../common/components/Icon'
import { useLogout } from '../common/redux/hooks'
import { useFetchAuthToken } from '../home/redux/hooks'
import InviteCoachModal from './components/InviteCoachModal'
import { useFetchUser } from './redux/hooks'
import { useTranslation } from '../translations/redux/setDefaultLanguage'
export default function UserPage() {
    const {
        languages,
        defaultLanguage,
        setDefaultLanguage,
        languageNames,
        t,
    } = useTranslation()
    const { userApproved } = useFetchAuthToken()
    const { user, replaceStringWithSynonyms } = useFetchUser()
    const userCompany = user?.all_companies?.filter(
        c => c.id === user.company_id
    )[0]
    const { logout } = useLogout()
    const history = useHistory()

    const [
        showSwitchActiveCompanyModal,
        setShowSwitchActiveCompanyModal,
    ] = useState(false)

    const handleSwitchClose = () => setShowSwitchActiveCompanyModal(false)

    const [showInviteCoachModal, setShowInviteCoachModal] = useState(false)

    const handleInviteCoachClick = useCallback(
        () => setShowInviteCoachModal(true),
        []
    )

    const handleInviteCoachModalHide = useCallback(
        () => setShowInviteCoachModal(false),
        []
    )

    const handleGotoNotificationsSettings = useCallback(() => {
        history.push('/user/notifications-settings')
    }, [history])

    const handleLogOut = useCallback(() => {
        logout()
        history.push('/')
    }, [logout, history])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [showLanguages, setShowLanguages] = useState(false)

    if (!user) {
        return <Loader />
    }

    const fullName = `${user.first_name} ${user.last_name}`

    let mainMenuItems = [
        {
            icon: faBell,
            label: 'Notifications',
            onClick: handleGotoNotificationsSettings,
        },
        {
            icon: faLifeRing,
            label: 'Help',
            link: 'https://www.myroadmap.io/#/support',
        },
    ]

    if (userCompany.name === 'Noble') {
        mainMenuItems = [
            {
                icon: faBell,
                label: 'Notifications',
                onClick: handleGotoNotificationsSettings,
            },
            {
                icon: faLifeRing,
                label: 'Help',
                link: 'https://www.myroadmap.io/#/support',
            },
            {
                icon: faCreditCard,
                label: 'Payments',
                onClick: () => history.push('/dashboard/subscriptions'),
            },
            {
                icon: 'roadmap',
                label: 'Roadmaps',
                onClick: () => history.push('/manage/roadmaps'),
                customIcon: true,
            },
        ]
    }

    if (user.features.can_assign_coach && userApproved) {
        mainMenuItems.splice(0, 0, {
            icon: faUserPlus,
            label: replaceStringWithSynonyms('Invite a Coach'),
            onClick: handleInviteCoachClick,
        })
    }

    const showDropIn =
        user.company_name === 'Bloom' ||
        user.company_name === 'Path' ||
        user.company_name === 'Everything After' ||
        user.company_name === 'ParentGuidance.org'

    const dropInGroups = {
        icon: 'group',
        title: 'Drop-in-group',
        link:
            user?.company_name === 'Bloom'
                ? 'http://noble-old.addonetwork.com/bloom-groups'
                : user?.company_name === 'Path'
                ? 'http://noble-old.addonetwork.com/path-groups'
                : user?.company_name === 'Everything After'
                ? 'http://noble-old.addonetwork.com/ea-groups'
                : user?.company_name === 'ParentGuidance.org'
                ? 'http://noble-old.addonetwork.com/pg-groups'
                : '',
        url: true,
        iconSize: 20,
    }

    const YouBloomGroups = {
        icon: 'group',
        title: 'YouBloom Groups',
        link: 'http://noble-old.addonetwork.com/yb-groups',
        url: true,
        iconSize: 20,
    }

    let SettingItems = [
        {
            icon: 'user',
            title: t('dropdown.profile'),
            link: '/manage/accounts',
            iconSize: 20,
            show: user.groups.includes('Admin'),
        },
        {
            icon: 'notification',
            title: t('dropdown.notifications'),
            link: '/user/notifications-settings',
            iconSize: 18,
            show: user.groups.includes('Admin'),
        },
        {
            icon: 'settings',
            title: t('dropdown.switch_company'),
            onClick: () => setShowSwitchActiveCompanyModal(true),
            iconSize: 18,
            show: user?.all_companies?.length > 1,
        },
        {
            icon: 'payouts',
            title: t('dropdown.manage_subscriptions'),
            link: '/dashboard/subscriptions',
            iconSize: 22,
            show:
                user.groups.includes('Admin') || user.groups.includes('Coach'),
        },
        {
            icon: 'roadmap',
            title: t('dropdown.roadmaps'),
            link: '/manage/roadmaps',
            iconSize: 18,
            show: user.groups.includes('Admin'),
        },
        {
            icon: 'help',
            title: t('dropdown.help'),
            link: 'https://www.noble.health/contact',
            url: true,
        },

        {
            icon: `${defaultLanguage}-flag`,
            title: t('dropdown.language'),
            onClick: () => setShowLanguages(!showLanguages),
            iconSize: 18,
            show: true,
        },

        {
            icon: 'logout',
            title: t('dropdown.logout'),
            onClick: handleLogOut,
            iconSize: 20,
            color: '#B68C31',
        },
    ]

    if (showDropIn)
        SettingItems.splice(SettingItems.length - 2, 0, dropInGroups)
    if (user.company_name === 'Bloom')
        SettingItems.splice(SettingItems.length - 2, 0, YouBloomGroups)

    return (
        <div className="user-user-page">
            <div className="header">
                {user && (
                    <img
                        src={
                            user.all_companies.find(
                                company => company.id === user.company_id
                            )?.logo
                        }
                        alt="company-logo"
                    />
                )}
            </div>
            <div class="mrm-p-1 mrm-pb-4">
                <Row>
                    <Col>
                        <Row className="user-page-header mrm-px-1">
                            <Col xs={'auto'}>
                                <UserAvatar user={user} square />
                            </Col>
                            <Col className="user-name pl-0" xs={'auto'}>
                                <p className="theme-text-primary">{fullName}</p>
                                <p className="mb-0">
                                    <Link
                                        to="/user/profile"
                                        className="theme-text-secondary"
                                    >
                                        {t('profile.view_profile')}
                                    </Link>
                                </p>
                            </Col>
                        </Row>

                        <div className="menu-items-grid">
                            {SettingItems.slice(0, SettingItems.length - 2).map(
                                (item, idx) => (
                                    <div
                                        className={`item ${
                                            item.show === false ? 'd-none' : ''
                                        }`}
                                        key={idx}
                                        onClick={
                                            item.url
                                                ? () => window.open(item.link)
                                                : item.onClick
                                                ? () => item.onClick()
                                                : () => history.push(item.link)
                                        }
                                    >
                                        <div className="icon-and-title">
                                            <Icon
                                                name={item.icon}
                                                size={item.iconSize}
                                                color={item.color || '#343434'}
                                            />
                                            <p
                                                className="title"
                                                style={{
                                                    color:
                                                        item.title === 'Log Out'
                                                            ? '#B68C31'
                                                            : '#343434',
                                                }}
                                            >
                                                {item.title}
                                            </p>
                                        </div>
                                        <button
                                            onClick={
                                                item.url
                                                    ? () =>
                                                          window.open(item.link)
                                                    : () =>
                                                          history.push(
                                                              item.link
                                                          )
                                            }
                                        >
                                            <Icon
                                                name="chevronRight"
                                                color="#343434"
                                                size={10}
                                            />
                                        </button>
                                    </div>
                                )
                            )}

                            <div className="flex flex-col w-full">
                                <div
                                    className="item"
                                    onClick={() =>
                                        SettingItems[
                                            SettingItems.length - 2
                                        ].onClick()
                                    }
                                >
                                    <div className="icon-and-title">
                                        <Icon
                                            name={
                                                SettingItems[
                                                    SettingItems.length - 2
                                                ].icon
                                            }
                                            size={
                                                SettingItems[
                                                    SettingItems.length - 2
                                                ].iconSize
                                            }
                                            color={
                                                SettingItems[
                                                    SettingItems.length - 2
                                                ].color || '#343434'
                                            }
                                        />
                                        <p
                                            className="title"
                                            style={{
                                                color:
                                                    SettingItems[
                                                        SettingItems.length - 2
                                                    ].title === 'Log Out'
                                                        ? '#B68C31'
                                                        : '#343434',
                                            }}
                                        >
                                            {
                                                SettingItems[
                                                    SettingItems.length - 2
                                                ].title
                                            }
                                        </p>
                                    </div>
                                    <button
                                        style={{
                                            transform: showLanguages
                                                ? 'rotate(90deg)'
                                                : 'rotate(0deg)',
                                            transition: 'all 0.5s ease',
                                            outline: 'none',
                                        }}
                                        onClick={() =>
                                            SettingItems[
                                                SettingItems.length - 2
                                            ].onClick()
                                        }
                                    >
                                        <Icon
                                            name="chevronRight"
                                            color="#343434"
                                            size={10}
                                        />
                                    </button>
                                </div>

                                <div
                                    className={`flex flex-col`}
                                    style={{
                                        display: showLanguages
                                            ? 'flex'
                                            : 'none',
                                        transition: 'all 0.5s ease',
                                    }}
                                >
                                    <div
                                        className="item"
                                        onClick={() => {
                                            setDefaultLanguage('en-US')
                                        }}
                                        style={{
                                            ...(defaultLanguage === 'en-US'
                                                ? {
                                                      backgroundColor:
                                                          'var(--primary-bg-white)',
                                                  }
                                                : {}),
                                            paddingLeft: '3rem',
                                        }}
                                    >
                                        <div className="icon-and-title">
                                            <Icon
                                                name="en-US-flag"
                                                size={18}
                                                color="#343434"
                                            />
                                            <p className="title">English</p>
                                        </div>
                                    </div>
                                    <div
                                        className="item"
                                        onClick={() => {
                                            setDefaultLanguage('es-NI')
                                        }}
                                        style={{
                                            ...(defaultLanguage === 'es-NI'
                                                ? {
                                                      backgroundColor:
                                                          'var(--primary-bg-white)',
                                                  }
                                                : {}),
                                            paddingLeft: '3rem',
                                        }}
                                    >
                                        <div className="icon-and-title">
                                            <Icon
                                                name="es-NI-flag"
                                                size={18}
                                                color="#343434"
                                            />
                                            <p className="title">Spanish</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`item ${
                                    SettingItems[SettingItems.length - 1]
                                        .show === false
                                        ? 'd-none'
                                        : ''
                                }`}
                                onClick={() =>
                                    SettingItems[
                                        SettingItems.length - 1
                                    ].onClick()
                                }
                            >
                                <div className="icon-and-title">
                                    <Icon
                                        name={
                                            SettingItems[
                                                SettingItems.length - 1
                                            ].icon
                                        }
                                        size={
                                            SettingItems[
                                                SettingItems.length - 1
                                            ].iconSize
                                        }
                                        color={
                                            SettingItems[
                                                SettingItems.length - 1
                                            ].color || '#343434'
                                        }
                                    />
                                    <p
                                        className="title"
                                        style={{
                                            color:
                                                SettingItems[
                                                    SettingItems.length - 1
                                                ].title === 'Log Out'
                                                    ? '#B68C31'
                                                    : '#343434',
                                        }}
                                    >
                                        {
                                            SettingItems[
                                                SettingItems.length - 1
                                            ].title
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {showSwitchActiveCompanyModal && (
                <SwitchActiveCompanyModal
                    show={showSwitchActiveCompanyModal}
                    onHide={() => setShowSwitchActiveCompanyModal(false)}
                />
            )}
        </div>
    )
}

UserPage.propTypes = {}
UserPage.defaultProps = {}
