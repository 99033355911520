import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SUBSCRIPTIONS_UPDATE_CONTRACT_BEGIN,
  SUBSCRIPTIONS_UPDATE_CONTRACT_SUCCESS,
  SUBSCRIPTIONS_UPDATE_CONTRACT_FAILURE,
  SUBSCRIPTIONS_UPDATE_CONTRACT_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function updateContract(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: SUBSCRIPTIONS_UPDATE_CONTRACT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.put(
        `${config.apiRootUrl}/subscriptions/contract/${args.id}/`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: SUBSCRIPTIONS_UPDATE_CONTRACT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SUBSCRIPTIONS_UPDATE_CONTRACT_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateContractError() {
  return {
    type: SUBSCRIPTIONS_UPDATE_CONTRACT_DISMISS_ERROR,
  };
}

export function useUpdateContract() {
  const dispatch = useDispatch();

  const { updateContractPending, updateContractError } = useSelector(
    (state) => ({
      updateContractPending: state.subscriptions.updateContractPending,
      updateContractError: state.subscriptions.updateContractError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateContract(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateContractError());
  }, [dispatch]);

  return {
    updateContract: boundAction,
    updateContractPending,
    updateContractError,
    dismissUpdateContractError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SUBSCRIPTIONS_UPDATE_CONTRACT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateContractPending: true,
        updateContractError: null,
      };

    case SUBSCRIPTIONS_UPDATE_CONTRACT_SUCCESS:
      // The request is success
      return {
        ...state,
        updateContractPending: false,
        updateContractError: null,
      };

    case SUBSCRIPTIONS_UPDATE_CONTRACT_FAILURE:
      // The request is failed
      return {
        ...state,
        updateContractPending: false,
        updateContractError: action.data.error,
      };

    case SUBSCRIPTIONS_UPDATE_CONTRACT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateContractError: null,
      };

    default:
      return state;
  }
}
