import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_BEGIN,
  USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_SUCCESS,
  USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_FAILURE,
  USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import config from '../../../common/config';

export function getSubscriptionByCoachAndUser(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${config.apiRootUrl}/subscriptions/get-subscription-by-coach-and-user/${args.id}`,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      ).catch((error) => {
        console.log(error);
      });
    });

    return promise;
  };
}

export function dismissGetSubscriptionByCoachAndUserError() {
  return {
    type: USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_DISMISS_ERROR,
  };
}

export function useGetSubscriptionByCoachAndUser() {
  const dispatch = useDispatch();

  const {
    getSubscriptionByCoachAndUserPending,
    getSubscriptionByCoachAndUserError,
    subscription,
  } = useSelector(
    (state) => ({
      subscription: state.user.subscription,
      getSubscriptionByCoachAndUserPending:
        state.user.getSubscriptionByCoachAndUserPending,
      getSubscriptionByCoachAndUserError:
        state.user.getSubscriptionByCoachAndUserError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getSubscriptionByCoachAndUser(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetSubscriptionByCoachAndUserError());
  }, [dispatch]);

  return {
    subscription,
    getSubscriptionByCoachAndUser: boundAction,
    getSubscriptionByCoachAndUserPending,
    getSubscriptionByCoachAndUserError,
    dismissGetSubscriptionByCoachAndUserError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getSubscriptionByCoachAndUserPending: true,
        getSubscriptionByCoachAndUserError: null,
      };

    case USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_SUCCESS:
      // The request is success
      return {
        ...state,
        getSubscriptionByCoachAndUserPending: false,
        getSubscriptionByCoachAndUserError: null,
        subscription: action.data.data,
      };

    case USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_FAILURE:
      // The request is failed
      return {
        ...state,
        getSubscriptionByCoachAndUserPending: false,
        getSubscriptionByCoachAndUserError: action.data.error,
      };

    case USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getSubscriptionByCoachAndUserError: null,
      };

    default:
      return state;
  }
}
