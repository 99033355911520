import React from 'react';

export default function ClockIcon({ size = 20, color = '#6D786E' }) {
  return (
    <svg
      width={size}
      height={size / 1.03}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1503 0.0517578C12.1538 0.0517578 9.2247 0.929974 6.73324 2.57535C4.24179 4.22073 2.29994 6.55937 1.15325 9.29553C0.00656423 12.0317 -0.293462 15.0425 0.291115 17.9472C0.875692 20.8519 2.31862 23.52 4.43742 25.6142C6.55623 27.7083 9.25575 29.1345 12.1946 29.7123C15.1335 30.2901 18.1797 29.9935 20.9481 28.8602C23.7164 27.7268 26.0826 25.8075 27.7473 23.3451C29.412 20.8826 30.3006 17.9875 30.3006 15.0259C30.2955 11.056 28.6976 7.25026 25.8575 4.44316C23.0174 1.63606 19.1668 0.0568117 15.1503 0.0517578ZM15.1503 27.708C12.6125 27.708 10.1317 26.9642 8.02157 25.5707C5.91146 24.1772 4.26683 22.1965 3.29566 19.8791C2.32448 17.5618 2.07038 15.0118 2.56548 12.5517C3.06058 10.0916 4.28265 7.83187 6.07715 6.05823C7.87165 4.2846 10.158 3.07674 12.647 2.5874C15.1361 2.09805 17.716 2.3492 20.0606 3.30909C22.4053 4.26897 24.4093 5.89447 25.8192 7.98004C27.2291 10.0656 27.9817 12.5176 27.9817 15.0259C27.9765 18.3878 26.623 21.6107 24.2178 23.9879C21.8125 26.3652 18.5518 27.703 15.1503 27.708Z"
        fill={color}
      />
      <path
        d="M21.7477 15.2531H16.4012V6.84825C16.4012 6.54079 16.2796 6.24592 16.0632 6.02851C15.8468 5.8111 15.5533 5.68896 15.2473 5.68896C14.9412 5.68896 14.6477 5.8111 14.4313 6.02851C14.2149 6.24592 14.0934 6.54079 14.0934 6.84825V16.509C14.0934 16.8164 14.2149 17.1113 14.4313 17.3287C14.6477 17.5461 14.9412 17.6683 15.2473 17.6683H21.7477C22.0538 17.6683 22.3473 17.5461 22.5637 17.3287C22.7801 17.1113 22.9017 16.8164 22.9017 16.509C22.9017 16.2015 22.7801 15.9066 22.5637 15.6892C22.3473 15.4718 22.0538 15.3497 21.7477 15.3497V15.2531Z"
        fill={color}
      />
    </svg>
  );
}
