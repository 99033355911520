import clsx from 'clsx'
import React, { forwardRef, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import Switch from 'react-switch'
import { Loader } from '../../common'
import Icon from '../../common/components/Icon'
import { useFetchAssignedUsers } from '../../dashboard/redux/fetchAssignedUsers'
import { useOpensupportgroup } from '../redux/opensupportgroup'
import { useFetchRoadmaps } from '../../dashboard/redux/fetchRoadmaps'

export default function CallsModal({
    show,
    setShow,
    update = false,
    call,
    showDelete,
    setShowDelete,
}) {
    const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers()
    const { opensupportgroup } = useOpensupportgroup()
    const { roadmaps, fetchRoadmaps } = useFetchRoadmaps()

    useEffect(() => {
        fetchRoadmaps()
    }, [])

    useEffect(() => {
        console.log('call', call)
    }, [call])

    const [formData, updateFormData] = useState({
      name: update ? call.name : '',
      timestamp: update ? new Date(call.timestamp) : new Date(),
      meeting_link: update ? call.meeting_link : '',
      recours_weekly: update ? call.recours_weekly : false,
      moderator: update
        ? {
            id: call.moderator.id,
            value: `${call.moderator.first_name} ${call.moderator.last_name}`,
            label: `${call.moderator.first_name} ${call.moderator.last_name}`,
          }
        : '',
      roadmaps: update
        ? roadmaps.results?.length > 0 &&
          call.roadmaps?.length > 0 &&
          roadmaps.results
            .map(roadmap => ({
              value: roadmap.id,
              label: roadmap.title,
              id: roadmap.id,
            }))
            .filter(roadmap =>
              call.roadmaps.find(callRoadmap => callRoadmap.id === roadmap.id)
            )
        : [],
    })

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [showError, setShowError] = useState(false)

    const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
        <div
            ref={ref}
            onClick={onClick}
            className={clsx('form-control hover date-picker-cal', {
                'text-muted': !value,
            })}
        >
            <Icon name="calendar" size={24} />
            {value || 'Select Date & Time'}
        </div>
    ))

    const desktopMultiSelectStyles = {
        control: (styles, state) => {
            const custom = {
                ...styles,
                borderRadius: '10px',
                cursor: 'text',
                borderColor: '#E3E5E5',
                minHeight: '40px',
                fontWeight: '300',
                fontSize: '14px',
                '&:hover': {
                    borderColor: '#E3E5E5',
                },
            }
            return custom
        },
    }

    const multiselectList = {
        assignedUsers: assignedUsers
            ? assignedUsers.results.map(item => ({
                  id: item.id,
                  full_name: `${item.first_name} ${item.last_name}`,
              }))
            : null,
        roadmaps:
            roadmaps?.results.length > 0
                ? roadmaps.results.map(item => ({
                      id: item.id,
                      name: item.name,
                  }))
                : null,
    }

    useEffect(() => {
        fetchAssignedUsers()
    }, [fetchAssignedUsers])

    const handleModerator = inputValue => {
        if (multiselectList.assignedUsers) {
            updateFormData({
                ...formData,
                moderator: inputValue,
            })
        }
    }

    const handleRoadmaps = inputValue => {
        if (multiselectList.roadmaps) {
            updateFormData({
                ...formData,
                roadmaps: inputValue,
            })
            console.log(inputValue)
        }
    }

    const isValidated = () => {
        if (formData.name === '') {
            setError('Please fill in the Name field')
            return false
        }

        if (formData.timestamp === '') {
            setError('Please fill in the Date & Time field')
            return false
        }
        if (formData.meeting_link === '') {
            setError('Please fill in the Meeting Link field')
            return false
        }
        if (
            !formData.meeting_link.match(
                /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm
            )
        ) {
            setError('Please fill in a valid Meeting Link')
            return false
        }
        if (!formData.moderator) {
            setError('Please fill in the Moderator field')
            return false
        }

        return true
    }

    const loadOptions = async inputValue => {
        return fetchAssignedUsers({
            search: inputValue,
        })
            .then(res => {
                return res.results.map(user => ({
                    value: `${user.first_name} ${user.last_name}`,
                    label: `${user.first_name} ${user.last_name}`,
                    id: user.id,
                }))
            })
            .catch(e => handleError(e.response.data))
    }

    const loadRoadmaps = async inputValue => {
        return fetchRoadmaps({
            search: inputValue,
        })
            .then(res => {
                console.log(res)
                return res.data.results.map(roadmap => ({
                    value: roadmap.id,
                    label: roadmap.title,
                    id: roadmap.id,
                }))
            })
            .catch(e => handleError(e.response.data))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        console.log(formData)

        if (isValidated()) {
            setError('')
            setShowError(false)
            setLoading(true)
            setShow(false)

            const timestamp = new Date(
                formData.timestamp
            ).getTime()

            opensupportgroup({
              name: formData.name,
              participants: [],
              timestamp: timestamp,
              meeting_link: formData.meeting_link,
              recours_weekly: formData.recours_weekly,
              moderator: formData.moderator.id,
              roadmaps:
                formData.roadmaps?.length > 0
                  ? formData.roadmaps.map(roadmap => roadmap.id)
                  : [],
              method: update ? (showDelete ? 'delete' : 'put') : 'post',
              ...(update && { id: call.id }),
            })
              .then(res => {
                setLoading(false)
                // reload page
                window.location.reload()
              })
              .catch(e => {
                setLoading(false)
                setShowError(true)
                setError(e.response.data.message)
              })
        } else {
            setShowError(true)
            setLoading(false)
        }
    }

    if (!showDelete)
        return (
            <Modal
                show={show}
                onHide={() => setShow(false)}
                className="calls-modal"
            >
                <Modal.Header>
                    <button onClick={() => setShow(false)}>Cancel</button>
                    <Modal.Title>
                        {update ? 'Update Group' : 'Create Group'}
                    </Modal.Title>
                    <p onClick={handleSubmit}>Save</p>
                </Modal.Header>
                <Modal.Body>
                    {loading && <Loader position="static" />}
                    {showError && <p className="error-message">{error}</p>}

                    <div className="flex flex-col">
                        <label>Group Name</label>
                        <input
                            type="text"
                            value={formData.name}
                            onChange={e =>
                                updateFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <label>Meeting Date</label>
                        <DatePicker
                            selected={formData.timestamp}
                            showTimeSelect
                            minDate={new Date()}
                            timeIntervals={15}
                            dateFormat="MMMM d @ h:mm aa"
                            customInput={<DatePickerButton />}
                            onChange={date => {
                                let newDate = new Date(date).valueOf()
                                updateFormData({
                                    ...formData,
                                    timestamp: newDate,
                                })
                            }}
                            className="text-primary ml-0"
                        />
                    </div>

                    <div className="flex flex-col">
                        <label>Moderator</label>
                        <AsyncSelect
                            name="selectedModerator"
                            placeholder="Select Moderator"
                            value={formData.moderator}
                            getOptionValue={option => option.id}
                            defaultOptions
                            loadOptions={loadOptions}
                            onChange={handleModerator}
                            styles={desktopMultiSelectStyles}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label>Roadmaps</label>
                        <AsyncSelect
                            name="selectedRoadmaps"
                            placeholder="Select Roadmaps"
                            isMulti
                            value={formData.roadmaps}
                            getOptionValue={option => option.id}
                            defaultOptions
                            loadOptions={loadRoadmaps}
                            onChange={handleRoadmaps}
                            styles={desktopMultiSelectStyles}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label>Meeting link</label>
                        <input
                            type="text"
                            value={formData.meeting_link}
                            onChange={e =>
                                updateFormData({
                                    ...formData,
                                    meeting_link: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="flex flex-col">
                        <label>Recurs weekly</label>
                        <Switch
                            onChange={() => {
                                updateFormData({
                                    ...formData,
                                    recours_weekly: !formData.recours_weekly,
                                })
                            }}
                            checked={formData.recours_weekly}
                            onColor="#343434"
                        />
                    </div>

                    {update && (
                        <button
                            className="delete-call-btn"
                            onClick={() => {
                                setShowDelete(true)
                            }}
                        >
                            <Icon name="delete" color="#fff" size={15} />
                            Delete Group
                        </button>
                    )}
                </Modal.Body>
            </Modal>
        )
    else
        return (
            <Modal
                show={showDelete}
                onHide={() => {
                    setShowDelete(false)
                    setShow(false)
                }}
                className="delete-call-modal"
            >
                <Modal.Header>
                    <button
                        onClick={() => {
                            setShowDelete(false)
                            setShow(false)
                        }}
                    >
                        Cancel
                    </button>
                    <Modal.Title>Delete Group</Modal.Title>
                    <p onClick={handleSubmit}>Delete</p>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete {call.name}?</p>
                </Modal.Body>
            </Modal>
        )
}
