export const FormikInitialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    company: '',
    coaching: [],
    coach_write_only: [],
    coach_message_alert: true,
    product: '',
    text_message_alert: true,
    client_email_alert: true,
    is_trial: false,
    trial_end: Date(new Date().setDate(new Date().getDate() + 3)),
    by_sms_or_default: null,
    // insurance
    insurance_provider: '',
    member_id: '',
    policy_holder: '',
    policy_holder_dob: '',
    group_number: '',
    // address
    address_1: '',
    address_2: '',
    zip: '',
    state: '',
    city: '',
}
