import { Formik } from 'formik'
import React, { Fragment, useEffect } from 'react'
import { useFetchUser } from '../../../../user/redux/fetchUser'
import clipBoard from '../../../assets/signup/clipboard.svg'
import { useCreateProfileAddress } from '../../../redux/createProfileAddress'
import { contactInformationSchema } from '../validationSchemas'
import { useHistory } from 'react-router-dom'

export default function ContactInformation({ setStep }) {
    const [editing, setEditing] = React.useState(true)
    const [method, setMethod] = React.useState('POST')
    const history = useHistory()
    const { createProfileAddress } = useCreateProfileAddress()

    const [address, setAddress] = React.useState({
        address_1: '',
        address_2: '',
        state: '',
        city: '',
        zip: '',
    })

    const { user } = useFetchUser()

    useEffect(() => {
        if (user && user.full_address) {
            setAddress({
                address_1: user.full_address.address_line_1,
                address_2: user.full_address.address_line_2,
                city: user.full_address.city,
                state: user.full_address.state,
                zip: user.full_address.zip_code,
            })
            setEditing(false)
            setMethod('PUT')
        }
    }, [user])

    if (!user) return null

    return (
        <div className="sidebar-content">
            <img src={clipBoard} alt="RPM Logo" id="overhead-icon" />
            <h1>Verify your address.</h1>

            <Formik
                initialValues={address}
                validationSchema={contactInformationSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    console.log(values)

                    setSubmitting(false)

                    createProfileAddress(values, method)
                        .then(res => {
                            history.push('/rpm/download-app')
                        })
                        .catch(err => {
                            setFieldError('address_1', err.message)
                        })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    handleBlur,
                }) => (
                    <form onSubmit={handleSubmit} className="form">
                        {editing ? (
                            <Fragment>
                                <p className="description-bold">Address</p>

                                <input
                                    type="text"
                                    name="address_1"
                                    placeholder="Address 1"
                                    className="simple-input"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address_1}
                                />
                                {errors.address_1 && touched.address_1 && (
                                    <p className="error-message">
                                        {errors.address_1}
                                    </p>
                                )}
                                <input
                                    type="text"
                                    name="address_2"
                                    placeholder="Address 2"
                                    className="simple-input"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address_2}
                                />
                                {errors.address_2 && touched.address_2 && (
                                    <p className="error-message">
                                        {errors.address_2}
                                    </p>
                                )}
                                <input
                                    type="text"
                                    name="state"
                                    placeholder="State"
                                    className="simple-input"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.state}
                                />
                                {errors.state && touched.state && (
                                    <p className="error-message">
                                        {errors.state}
                                    </p>
                                )}

                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City"
                                    className="simple-input"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                />

                                {errors.city && touched.city && (
                                    <p className="error-message">
                                        {errors.city}
                                    </p>
                                )}

                                <input
                                    type="text"
                                    placeholder="Zip"
                                    className="simple-input"
                                    onChange={e => {
                                        if (
                                            e.target.value.match(/^[0-9]*$/) &&
                                            e.target.value.length < 6
                                        ) {
                                            setFieldValue('zip', e.target.value)
                                        }
                                    }}
                                    value={values.zip}
                                    onBlur={handleBlur}
                                />
                                {errors.zip && touched.zip && (
                                    <p className="error-message">
                                        {errors.zip}
                                    </p>
                                )}
                            </Fragment>
                        ) : (
                            <div className="address-container">
                                <p className="address-title">Address</p>
                                <p className="address">
                                    {user.full_address.address_line_1}
                                    <br />
                                    {user.full_address.address_line_2}
                                    <br />
                                    {user.full_address.city},{' '}
                                    {user.full_address.state}{' '}
                                    {user.full_address.zip_code}
                                </p>
                                <button
                                    onClick={() => {
                                        setFieldValue(
                                            'address_1',
                                            user.full_address.address_line_1 ||
                                                ''
                                        )
                                        setFieldValue(
                                            'address_2',
                                            user.full_address.address_line_2 ||
                                                ''
                                        )
                                        setFieldValue(
                                            'city',
                                            user.full_address.city || ''
                                        )
                                        setFieldValue(
                                            'state',
                                            user.full_address.state || ''
                                        )
                                        setFieldValue(
                                            'zip',
                                            user.full_address.zip_code || ''
                                        )
                                        setEditing(true)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        )}
                        <button
                            className="next-button"
                            id="next"
                            onClick={() => {
                                console.log('clicked')
                                if (editing) {
                                    handleSubmit()
                                } else {
                                    // /rpm/signup/insurance-information'
                                    history.push('/rpm/download-app')
                                }
                            }}
                        >
                            Next
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    )
}
