import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../common/windowsSize';
import usePrivateLabelledSettings from '../common/usePrivateLabelledSettingsHook';
import SignUpForm from './components/SignupForm';
import chat from './images/chat_image.png';
// import PropTypes from 'prop-types';
import logo from './images/logo.png';
import people from './images/people.png';
import { clearAuthToken } from './redux/fetchAuthToken';
import { useRegisterAccount } from './redux/hooks';
import { useSignupHandleSubmit } from './utils/useSignupHandleSubmit';
import { useUserAgreement } from '../user/redux/userAgreement';

// COACH SIGNUP PAGE

export default function Signup() {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const [emailExists, setEmailExists] = useState(null);

  const { authToken, userApproved, registerAccount, registerAccountPending } =
    useRegisterAccount();
  const { userAgreement } = useUserAgreement();

  const [userLoggedIn, setUserLoggedIn] = useState(authToken && userApproved);
  const [agreement, setAgreement] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const privateLabelledSettings = usePrivateLabelledSettings();
  const queryParams = qs.parse(location.search.slice(1));

  const coachSignUp =
    (queryParams['is-coach'] &&
      queryParams['is-coach'].toLowerCase() === 'true') ||
    privateLabelledSettings.signupCompanyNameField === 'Noble';

  let signupCompanyName = privateLabelledSettings.signupCompanyNameField;

  if (!signupCompanyName && queryParams['company-name'])
    signupCompanyName = queryParams['company-name'];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (authToken) {
      if (userApproved) {
        if (coachSignUp && userLoggedIn) {
          dispatch(clearAuthToken(() => setUserLoggedIn(false)));
        } else {
          history.push(queryParams.next || '/');
        }
      } else {
        history.push({
          pathname: '/user-not-approved',
          state: {
            pathname: history.location.pathname,
          },
        });
      }
    }
  }, [
    authToken,
    userApproved,
    coachSignUp,
    userLoggedIn,
    dispatch,
    history,
    queryParams,
  ]);

  const [passwordError, setPasswordError] = useState(' ');
  const [firstNameError, setFirstNameError] = useState(' ');
  const [emailError, setEmailError] = useState(' ');
  const [lastNameError, setLastNameError] = useState(' ');

  const handleSubmit = useSignupHandleSubmit({
    setEmailError,
    setPasswordError,
    setFirstNameError,
    setLastNameError,
    coachSignUp,
    registerAccount,
    privateLabelledSettings,
    setValidated,
    userAgreement,
  });

  const size = useWindowSize();

  return (
    <div className="home-signup">
      <div
        className="signup-sidebar mrm-mx-10"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          minHeight: '100vh',
          backgroundImage: size.width < 768 ? `url(${people})` : '',
        }}
      >
        <Link to="/">
          <img
            src={logo}
            className="logo d-none d-lg-block"
            alt="company-logo"
          />
        </Link>
        <div className="signup_form">
          <div className="form_c">
            <h3>Welcome to Noble</h3>
            <p>
              You are just a few clicks away from serving more people in less
              time, supporting a worthy cause, and earning passive income!
            </p>
            <SignUpForm
              isLoading={registerAccountPending || authToken}
              validated={validated}
              handleSubmit={handleSubmit}
              passwordError={passwordError}
              emailExists={emailExists}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              emailError={emailError}
              agreement={agreement}
              setAgreement={setAgreement}
            />
          </div>
        </div>
      </div>
      <div className="s-content">
        <div className="login_content">
          <img src={chat} className="illustration-s" alt="Two women chatting" />
          <h1>Using technology to enhance, not replace, human connection.</h1>
          <p>
            Your clients will gain access to automated Roadmaps, assessments,
            and in-app messaging – tools that will help you and your clients
            gain a better understanding of their progress between sessions, so
            you can tailor your one-on-one sessions to their needs more
            effectively.
          </p>
        </div>
      </div>
    </div>
  );
}

Signup.propTypes = {};
Signup.defaultProps = {};
