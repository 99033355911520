import React from 'react';
import { Modal } from 'react-bootstrap';
import Icon from '../../common/components/Icon';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useChangePaymentMethodCard } from '../redux/changePaymentMethodCard';
import likeHand from '../../dashboard/images/LikeHand.png';
import Loader from '../../../features/common/Loader';

export default function ChangePaymentMethodModal({ show, onHide }) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const { changePaymentMethodCard } = useChangePaymentMethodCard();

  const cardStyle = {
    style: {
      base: {
        backgroundColor: 'white',
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    setStep(2);
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });
    if (paymentMethod) {
      changePaymentMethodCard({ payment_method_id: paymentMethod.id })
        .then(() => {
          setLoading(false);
          setError(null);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (err.response.status === 402) {
            setStep(1);
            setLoading(false);
            console.log(err.response && err.response.data);
            setError(err.response && err.response.data);
          }
          setStep(1);
        });
    } else {
      setLoading(false);
      setStep(1);
      setError({ CardError: ['Please enter a valid card'] });
    }
  };

  const [step, setStep] = React.useState(1);

  return (
    <Modal show={show} className="change-payment-method-modal" onHide={onHide}>
      <Modal.Header>
        <Modal.Title className={`${step > 1 && 'd-none'}`}>
          Update Payment Method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader position="static" className="mrm-mb-1" />}
        <div className={`success-body ${(step !== 2 || loading) && 'd-none'}`}>
          <img src={likeHand} alt="an illustration of a hand giving a like" />
          <Modal.Title>Success!</Modal.Title>
          <p>Your payment method has successfully been added to your file. </p>
          <button onClick={() => window.location.reload(false)}>Done</button>
        </div>
        <div className={`credit-card ${step > 1 && 'd-none'}`}>
          <p>Credit Card</p>
          <div className="companies">
            <Icon name="visa" size={30} />
            <Icon name="amex" size={30} />
            <Icon name="mastercard" size={30} />
            <Icon name="stripe" size={30} />
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className={`${loading || step > 1 ? 'd-none' : ''}`}
        >
          <CardElement id="card-element" options={cardStyle} />
          <button>Update Payment Method</button>
          <p className={`error-message ${error ? '' : 'd-none'}`}>
            {error && error.CardError[0]}
          </p>
        </form>
      </Modal.Body>
    </Modal>
  );
}
