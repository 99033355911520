import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import DatePickerButton from './DatePickerButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import clsx from 'clsx';

const addActionItemSchema = yup.object().shape({
  description: yup.string().required(),
  due_date: yup.date().required(),
});

const ActionItemDialog = ({ show, onHide, onSave, data }) => {
  const { register, handleSubmit, control, errors } = useForm({
    resolver: yupResolver(addActionItemSchema),
  });

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="action-item-dialog modal-mobile-slide-from-bottom"
      centered
    >
      <Form onSubmit={handleSubmit(onSave)}>
        <Modal.Body>
          <div className="header">
            <button onClick={onHide}>Cancel</button>
            <h2>
              {!data.description ? 'Add Action Item' : 'Edit Action Item'}
            </h2>
            <button className="save" type="submit">
              {!data.description ? 'Save' : 'Save Changes'}
            </button>
          </div>

          <Form.Group controlId="action-item-name">
            <Form.Control
              placeholder="Action item name..."
              autoComplete="off"
              name="description"
              defaultValue={data.description}
              isInvalid={errors.description}
              ref={register}
            />
          </Form.Group>

          <Form.Group controlId="due-date" className="mb-0 due-date-control">
            <Controller
              name="due_date"
              control={control}
              defaultValue={
                data.due_date ? dayjs(data.due_date).toDate() : null
              }
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  customInput={
                    <DatePickerButton
                      className={clsx('due-date-control', {
                        'is-invalid': errors.due_date,
                      })}
                    />
                  }
                  onChange={onChange}
                />
              )}
            />
          </Form.Group>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default ActionItemDialog;
