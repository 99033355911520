import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Container, Row, Form, Button, Col } from 'react-bootstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useUnauthorizedErrorHandler } from '../../../../common/apiHelpers';
import { useFetchCompetencyActionItemAssessments } from '../../redux/fetchCompetencyActionItemAssessments';
import { useSetActionItemDetails } from '../../redux/setActionItemDetails';
import TextareaAutosize from 'react-textarea-autosize';

export default function AddResponseModal({ show, onHide }) {
  const history = useHistory();
  const { roadmapId, stageId, competencyId, actionItemId } = useParams();
  const [note, setNote] = useState('');
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();
  const { actionItems } = useFetchCompetencyActionItemAssessments();
  const selectedActionItem = actionItems[actionItemId];
  const { setActionItemDetails } = useSetActionItemDetails();

  useEffect(() => {
    setNote(selectedActionItem?.notes);
  }, [selectedActionItem]);

  const updateActionItem = useCallback(
    (data) => {
      const args = { roadmapId, stageId, competencyId, actionItemId, data };
      return setActionItemDetails(args).catch(unauthorizedErrorHandler);
    },
    [
      roadmapId,
      stageId,
      competencyId,
      actionItemId,
      setActionItemDetails,
      unauthorizedErrorHandler,
    ],
  );

  const handleSaveClick = useCallback(() => {
    const updatedNotes = note;
    updateActionItem({ notes: updatedNotes }).then(() => {
      history.push(
        `/roadmap/${roadmapId}/stage/${stageId}/competency/${competencyId}`,
      );
    });
  }, [updateActionItem, note, history]);

  const renderSaveButton = useCallback(
    () => <button onClick={handleSaveClick}>Save</button>,
    [handleSaveClick],
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="add-response-modal-width"
      className="add-response-modal"
    >
      <Modal.Header>
        {' '}
        <button onClick={onHide}>Cancel</button>
        {renderSaveButton()}
      </Modal.Header>
      <Modal.Title>Add Response</Modal.Title>
      <Container>
        <Row>
          <Col>
            <Form className="mrm-mt-1" noValidate onSubmit={handleSaveClick}>
              <Form.Group controlId="formNotes">
                <Form.Control
                  as={TextareaAutosize}
                  minRows={6}
                  maxRows={14}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Add your thoughts..."
                  className="theme-card"
                ></Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
