import React from 'react';
import useWindowSize from '../common/useWindowResize';
import DesktopSignup from './components/signup/desktop/DesktopSignup';
import MobileCarousel from './components/signup/mobile/MobileCarousel';
import MobileSignup from './components/signup/mobile/MobileSignup';

export default function Signup() {
  const { width } = useWindowSize();

  const isMobile = width < 768;

  if (isMobile) {
    return <MobileSignup />;
  }

  return <DesktopSignup />;
}

Signup.propTypes = {};
Signup.defaultProps = {};
