import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const HomeIcon = ({
  size = 25,
  color = '#343434',
  style = {},
  active = false,
}) => {
  if (active) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 76 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.7843 22.943C73.7011 20.7321 72.0233 18.8644 69.9379 17.5482L45.1812 2.00803C43.0295 0.694914 40.5557 0 38.0328 0C35.5099 0 33.0361 0.694914 30.8844 2.00803L6.1277 17.3872C4.00604 18.7315 2.32225 20.6618 1.28136 22.943C0.240645 25.08 -0.179058 27.4649 0.0697702 29.8274L3.46221 57.687C3.91423 60.8194 5.49311 63.681 7.90469 65.739C10.3495 67.821 13.4531 68.9759 16.6685 69H35.6096V59.1767V40.0533C35.6096 39.3059 35.9075 38.5891 36.4377 38.0606C36.9678 37.5321 37.6869 37.2352 38.4367 37.2352C39.1902 37.2351 39.9135 37.5307 40.4501 38.0581C40.9867 38.5855 41.2935 39.3022 41.3041 40.0533V59.1767V68.9597H59.3163C62.5292 68.922 65.6283 67.7688 68.0802 65.6987C70.4826 63.639 72.048 60.7758 72.4822 57.6468L75.9151 29.8274C76.189 27.4724 75.7973 25.088 74.7843 22.943V22.943Z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 76 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M59.3316 69.9185H16.6501C13.4383 69.8941 10.3381 68.7259 7.89597 66.6199C5.48705 64.5382 3.90991 61.6435 3.45839 58.475L0.0696932 30.2124C-0.178861 27.8226 0.240379 25.4102 1.27994 23.2485C2.31968 20.941 4.00162 18.9885 6.12094 17.6286L30.8504 2.03121C32.9997 0.702935 35.4708 0 37.9908 0C40.5109 0 42.982 0.702935 45.1313 2.03121L69.8607 17.5879C71.9724 18.9744 73.6511 20.9373 74.7017 23.2485C75.7793 25.4706 76.1995 27.9591 75.912 30.416L72.483 58.5565C72.0491 61.7216 70.4855 64.6179 68.0857 66.7013C65.6366 68.7953 62.5409 69.9618 59.3316 70V69.9185ZM37.9908 5.6964C36.5527 5.70926 35.1499 6.14824 33.9567 6.95885L9.10622 22.4748C7.9394 23.2157 7.00276 24.2732 6.40348 25.5264C5.8042 26.7795 5.56648 28.1777 5.71752 29.5608L9.10622 57.6605C9.3412 59.4561 10.2335 61.0977 11.6074 62.2624C13.0035 63.4954 14.7952 64.1754 16.6501 64.1764H59.2912C61.1581 64.1742 62.962 63.4949 64.3743 62.2624C65.7418 61.0753 66.6315 59.4238 66.8755 57.6198L70.2642 29.5201C70.413 28.1384 70.1697 26.7425 69.5626 25.495C68.9554 24.2476 68.0095 23.2002 66.8351 22.4748L42.025 6.95885C40.8268 6.15886 39.4272 5.72088 37.9908 5.6964V5.6964Z"
          fill={color}
        />
        <path
          d="M37.9789 67C37.5841 67 37.1933 66.9242 36.8291 66.7769C36.4649 66.6295 36.1347 66.4136 35.8575 66.1418C35.5804 65.8699 35.3619 65.5475 35.2147 65.1932C35.0675 64.8389 34.9947 64.4599 35.0003 64.0782V39.8807C35.0003 39.1167 35.3141 38.384 35.8727 37.8437C36.4313 37.3035 37.1889 37 37.9789 37C38.7728 36.9999 39.5349 37.3021 40.1002 37.8412C40.6656 38.3803 40.9888 39.1129 41 39.8807V64.0782C41 64.8531 40.6817 65.5963 40.1151 66.1442C39.5486 66.6922 38.7801 67 37.9789 67V67Z"
          fill={color}
        />
      </svg>
    );
  }
};

export default HomeIcon;
