export const dashboard = {
  title: 'Dashboard',
  subtitle: 'This is the dashboard page.',
  coach_message_prompt: {
    topHeader: "Let's get started!",
    header: 'Invite a client',
    description:
      "Add a new client, assign a Roadmap, and we'll handle the rest.",
    button: 'Invite a client',
  },
  my_roadmaps: {
    title: 'My Roadmaps',
    completed: 'completed',
  },
  roadmaps_carousel: {
    title: 'Discover our Roadmaps',
  },
  my_coaching: 'My Coaching',
  my_payouts: { title: 'My Payouts' },
  upcoming_calls: {
    title: 'Upcoming Calls',
    no_calls: 'No upcoming calls',
  },
  clients: {
    title: 'My Clients',
    my_patients: 'My Patients',
    search: 'Search',
    no_clients: 'No clients',
    powered_by: 'Powered by',
    filters: {
      filter: 'Filter',
      sort: 'Sort',
      sort_by: 'Sort by',
      apply_filters: 'Apply Filters',
      reset_filters: 'Reset Filters',
      enrolled_roadmaps: 'Enrolled Roadmaps',
      status: 'Status',
      active: 'Active',
      past_due: 'Past Due',
      canceled: 'Canceled',
      trialing: 'Trialing',
      first_name: 'First Name',
      last_name: 'Last Name',
      most_progress: 'Most Progress',
      most_red_assessments: 'Most Red Assessments',
      last_login: 'Last Login',
      last_ai: 'Last AI',
    },
  },
  onboarding: {
    cancel: 'Cancel',
    modal_header_1: 'Before you can add a client we need to',
    modal_header_2: 'make sure you can get paid!',
    modal_description:
      'Using Stripe Connect we can safely and securely transfer your money from your clients to you.',
    connect_bank: 'Connect to your bank account',
    connect_button: 'Connect with Stripe',
    learn: 'Learn more about Stripe',
    first_step: {
      title: 'Invite a Client',
      description:
        "Let us know who you want to invite and we'll take care of the rest. We'll get them setup and let you know when they're ready.",
      client_information: 'Client Information',
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      next: 'Next',
      validations: {
        first_name: 'First name is required',
        last_name: 'Last name is required',
        email: 'Email is required',
        invalid_email: 'Email is invalid',
        active_sub:
          ' You cannot send an invitation to people with active subscriptions.',
      },
    },
    second_step: {
      title: 'Assign your client a Roadmap',
      description:
        "Let's get your client working on one of our science backed roadmaps.",
      featured_roadmaps: 'Featured Roadmaps',
      next: 'Next',
    },
    third_step: {
      title: 'How much would you like to charge?',
      description:
        'Set your price. As high or as low as you want. You’re in complete control.',
      create_subscription: 'Create a custom subscription',
      choose_subscription: 'Choose a subscription',
      next: 'Next',
      no_price: 'no price',
      month_abbr: 'mo',
      add_new_subscriptions: 'Add new subscriptions',
      subscription_placeholder: 'Subscription name',
    },
    fourth_step: {
      title: "Let's Review",
      client_information: 'Client Information',
      cancel: 'Cancel',
      edit: 'Edit',
      assigned_roadmap: 'Assigned Roadmap',
      subscription: 'Subscription',
      month_abbr: 'mo',
      send_invitation: 'Send Invitation',
    },
    fifth_step: {
      title: "We'll take it from here.",
      description: `We have everything we need from you! {name}'s account
          should be ready soon. In the meantime, feel free to invite another
          client to Noble!`,
      invite_another: 'Invite another client',
      done: 'Done',
    },
  },
  support_group: {
    card: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      at: 'at',
    },
  },
};
