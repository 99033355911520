import React from 'react'

export default function HealthIdIcon({
    size = 20,
    color = '#595959',
    style,
    className,
    ...props
}) {
    return (
        <svg
            width={size}
            height={size / 1.333333333}
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
            {...props}
        >
            <path
                d="M17.6834 1H2.31655C1.58944 1 1 1.64749 1 2.44621V12.5538C1 13.3525 1.58944 14 2.31655 14H17.6834C18.4106 14 19 13.3525 19 12.5538V2.44621C19 1.64749 18.4106 1 17.6834 1Z"
                stroke={color}
                stroke-width="1.3"
                stroke-miterlimit="10"
            />
            <path
                d="M1 4.79175H19"
                stroke={color}
                stroke-width="1.3"
                stroke-miterlimit="10"
            />
            <path
                d="M5.5 7.5L5.5 10.75"
                stroke={color}
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 9.125H4"
                stroke={color}
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.5 8H16.5"
                stroke={color}
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.5 10.625H14.5"
                stroke={color}
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
