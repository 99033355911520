export const multiSelectStyles = {
  control: (styles, state) => {
    const custom = {
      ...styles,
      borderRadius: '10px',
      cursor: 'text',
      borderColor: '#cccccc',
      minHeight: '40px',
      maxWidth: '90%',
      margin: '0 auto',
      fontFamily: '"Poppins", sans-serif',
      '&:hover': {
        borderColor: '#cccccc',
      },
      '&:active': {
        borderColor: '#6E9FE0',
      },
      '&:focus': {
        borderColor: '#6E9FE0',
      },
      '@media only screen and (max-width: 991px)': {
        ...styles['@media only screen and (max-width: 991px)'],
        borderColor: '#eeeeee',
      },
    };
    if (state.isFocused) {
      custom.boxShadow = 'none';
    }
    return custom;
  },
  option: (styles, state) => {
    return {
      ...styles,
      fontFamily: '"Poppins", sans-serif',
    };
  },
};
