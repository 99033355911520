export default function useOnSendGroupMessage({
  createGroupChatMessage,
  setSelectedMessages,
  selectedMessages,
  user,
  selectedConversation,
  message,
}) {
  const onSendGroupMessage = () => {
    const chatId = selectedConversation.id;

    setSelectedMessages((prevSelectedMessages) => {
      return [
        ...prevSelectedMessages,
        {
          user: {
            id: user.id,
            username: user.username,
            photo: user.photo,
            first_name: user.first_name,
            last_name: user.last_name,
          },
          timestamp: Date.now(),
          text: message,
        },
      ];
    });

    createGroupChatMessage({
      chatId,
      timestamp: Date.now(),
      text: message,
    })
      .then((newMsg) => {
        const jsonMessagePayload = {
          user: newMsg.user,
          timestamp: newMsg.timestamp,
          text: newMsg.text,
          id: newMsg.id,
          user: user,
        };

        // set last message of setselectedmessages jsonmessagepayload
        setSelectedMessages((prevSelectedMessages) => {
          return [...prevSelectedMessages.slice(0, -1), jsonMessagePayload];
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return { onSendGroupMessage };
}
