import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from './components/Icon';
import useEffectiveBackLink from './useEffectiveBackLinkHook';

export default function DesktopBackButton({
  className,
  defaultBackLink,
  queryParams = '',
}) {
  const history = useHistory();
  const effectiveBackLink = useEffectiveBackLink(defaultBackLink);

  if (!!queryParams && typeof effectiveBackLink !== 'string') {
    effectiveBackLink.search = queryParams;
  }

  const buttonOnClick = useCallback(() => {
    history.push(effectiveBackLink);
    window.location.reload();
  }, [history, effectiveBackLink]);

  return (
    <div className={clsx('desktop-button', className)} onClick={buttonOnClick}>
      <Icon name="chevronLeft" size={8} />
      <p>Go back</p>
    </div>
  );
}

DesktopBackButton.propTypes = {};
DesktopBackButton.defaultProps = {};
