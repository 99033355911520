import React from 'react';
import { Modal } from 'react-bootstrap';

import RefundsForm from './RefundsForm';

export default function RefundsModal({ userId, show, onHide }) {
  return (
    <Modal show={show} className="refunds-modal" onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Refund Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RefundsForm userId={userId} cancel={onHide} />
      </Modal.Body>
    </Modal>
  );
}
