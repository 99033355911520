import React, { Fragment, useEffect } from 'react'
import Icon from '../../../common/components/Icon'
import { useFetchCoachUpcomingCalls } from '../../../dashboard/redux/fetchCoachUpcomingCalls'
import AddCallModal from '../../../dashboard/UpcomingCalls/AddCallModal'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import EditCallModal from '../../../dashboard/UpcomingCalls/EditCallModal'
import { useProfileSettings } from '../../../user/redux/profileSettings'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useTrackCallEvent } from '../../redux/trackCallEvent'

export default function Calls() {
  const {
    coachCalls,
    fetchCoachCalls,
    fetchCoachCallsPending,
  } = useFetchCoachUpcomingCalls()
  const { settings } = useProfileSettings()

  const { trackCallEvent } = useTrackCallEvent()

  const [showModal, setShowModal] = React.useState(false)
  const [showEditCallModal, setShowEditCallModal] = React.useState(false)
  const [deleteCall, setDeleteCall] = React.useState(false)
  const [selectedCall, setSelectedCall] = React.useState(null)

  const [dropdownShown, setDropdownShown] = React.useState(-1)
  const editDropdownRef = React.useRef(null)
  useOutsideAlerter(editDropdownRef, () => setDropdownShown(-1))

  const { t } = useTranslation()

  useEffect(() => {
    fetchCoachCalls()
  }, [])

  useEffect(() => {
    console.log('coachCalls', coachCalls)
  }, [coachCalls])

  if (!settings) return null

  return (
    <div className="rpm-coach-calls">
      <AddCallModal
        show={showModal}
        onHide={() => {
          setShowModal(false)
          fetchCoachCalls()
        }}
      />
      {coachCalls && coachCalls.results && (
        <EditCallModal
          callId={selectedCall ? selectedCall.id : null}
          show={showEditCallModal}
          onHide={() => {
            setShowEditCallModal(false)
            fetchCoachCalls()
            setDeleteCall(false)
            setSelectedCall(null)
          }}
          deleteCall={deleteCall}
          coachCalls={coachCalls.results}
        />
      )}
      <div className="rpm-coach-calls__header">
        <p className="rpm-coach-calls__header__title">
          {t('coach_dashboard.upcoming_calls')}
        </p>
        <button
          className="rpm-coach-calls__header__button"
          onClick={() => setShowModal(true)}
        >
          <Icon name="plus" color="#ffffff" />
        </button>
      </div>

      <div className="rpm-coach-calls__content">
        {/*                 <p className="rpm-coach-calls__content__title">TODAY</p>
         */}{' '}
        <div className="rpm-coach-calls__content__list">
          {fetchCoachCallsPending &&
            Array(10)
              .fill(0)
              .map((_, idx) => (
                <div
                  className="rpm-coach-calls__content__list__loading-card"
                  key={idx}
                >
                  <div className="rpm-coach-calls__content__list__loading-card__avatar" />
                  <div className="rpm-coach-calls__content__list__loading-card__info" />
                  <div className="rpm-coach-calls__content__list__loading-card__body" />
                </div>
              ))}

          {coachCalls &&
            coachCalls.results.length > 0 &&
            coachCalls.results.map((call, idx) => (
              <div className="rpm-coach-calls__content__list__item" key={idx}>
                <a
                  className="rpm-coach-calls__content__list__item__profile-container"
                  onClick={e => {
                    trackCallEvent({
                      callId: call.id,
                    })
                  }}
                  href={call.meeting_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    opacity: 1,
                  }}
                >
                  <div className="rpm-coach-calls__content__list__item__profile-container__picture">
                    <div className="rpm-coach-calls__content__list__item__profile-container__picture__header">
                      {new Date(call.meeting_timestamp)
                        .toLocaleString('default', {
                          month: 'short',
                        })
                        .toLocaleUpperCase()}
                    </div>
                    <div className="rpm-coach-calls__content__list__item__profile-container__picture__body">
                      <p>
                        {new Date(call.meeting_timestamp).toLocaleString(
                          'default',
                          {
                            day: 'numeric',
                          }
                        )}
                      </p>
                    </div>{' '}
                  </div>
                  <div className="rpm-coach-calls__content__list__item__profile-container__name">
                    <p className="rpm-coach-calls__content__list__item__profile-container__name__text">
                      {call.call_name}
                    </p>
                    <p className="rpm-coach-calls__content__list__item__profile-container__name__description">
                      {call?.meeting_participants?.invited &&
                        call?.meeting_participants?.invited.length > 0 &&
                        call?.meeting_participants?.invited.map(
                          (participant, idx) => (
                            <Fragment key={idx}>
                              {participant.first_name} {participant.last_name}
                              {idx <
                              call?.meeting_participants?.invited?.length - 1
                                ? ', '
                                : ''}
                            </Fragment>
                          )
                        )}
                    </p>
                  </div>
                </a>
                <a
                  href={call.meeting_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rpm-coach-calls__content__list__item__time"
                  style={{
                    opacity: 1,
                  }}
                  onClick={e => {
                    trackCallEvent({
                      callId: call.id,
                    })
                  }}
                >
                  {call.is_recurrent && (
                    <Icon name="recurs" color={'#ffffff'} size={15} />
                  )}
                  {call.is_recurrent === true ? (
                    <p>
                      {/*   {new Date(call.meeting_timestamp).toLocaleString(
                        'default',
                        {
                          weekday: 'long',
                        }
                      )}{' '}
                      at 
                      {new Date(call.meeting_timestamp)
                        .toLocaleTimeString({
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                        .replace(/:\d\d /, ' ')} */}

                      {/** FORMAT SHOULD BE LIKE THIS Thu Aug 17 2023 @ 12:20 PM CDT */}
                      {new Date(call.meeting_timestamp)
                        .toLocaleString('default', {
                          weekday: 'short',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          timeZoneName: 'short',
                        })
                        .replace(/,([^,]*)$/, ' @ $1')}
                    </p>
                  ) : (
                    <>
                      <p>{new Date(call.meeting_timestamp).toDateString()}</p>
                      <p>{' - '}</p>
                      <p>
                        {new Date(
                          call.meeting_timestamp
                          // hide seconds
                        )
                          .toLocaleTimeString({
                            hour: '2-digit',
                            minute: '2-digit',
                          })
                          .replace(/:\d\d /, ' ')}
                      </p>
                    </>
                  )}
                </a>
                {/* <div className="rpm-coach-calls__content__list__item__footer">
                                    <a
                                        href={
                                            'https://calendar.google.com/calendar/r/eventedit?text=' +
                                            call.call_name +
                                            '&dates=' +
                                            // from
                                            new Date(call.user_provided_timestamp)
                                                .toISOString()
                                                .replace(/-|:|\.\d\d\d/g, '') +
                                            '/' +
                                            // to
                                            new Date(call.user_provided_timestamp)
                                                .toISOString()
                                                .replace(/-|:|\.\d\d\d/g, '') +
                                            '&details=' +
                                            call.call_name +
                                            '&location=' +
                                            call.user_provided_link
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Icon
                                            name={'add-date'}
                                            color={'#A3A1A0'}
                                        />
                                    </a>
                                    <a
                                        className="rpm-coach-calls__content__list__item__footer__right"
                                        href={call.user_provided_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <p className="rpm-coach-calls__content__list__item__footer__right__text">
                                            Join Now
                                        </p>
                                        <Icon
                                            name={'arrow-right'}
                                            color={'#E8744F'}
                                        />
                                    </a>
                                </div> */}
                <div
                  className="rpm-coach-calls__content__list__item__three-dots"
                  type="button"
                  onClick={() => setDropdownShown(call.id)}
                >
                  <Icon name={'threedots'} color={'#C4C4C4'} size={3} />
                </div>

                {dropdownShown === call.id && (
                  <div
                    className="rpm-coach-calls__content__list__item__dropdown"
                    ref={editDropdownRef}
                  >
                    <div
                      className="rpm-coach-calls__content__list__item__dropdown__item"
                      style={{
                        borderBottom: '1px solid rgba(196, 196, 196, 0.3)',
                      }}
                      onClick={() => {
                        setDropdownShown(-1)
                        setShowEditCallModal(true)
                        setSelectedCall(call)
                      }}
                    >
                      {t('coach_dashboard.edit')}
                    </div>
                    <div
                      className="rpm-coach-calls__content__list__item__dropdown__item"
                      style={{ color: '#E8744F' }}
                      onClick={() => {
                        setDeleteCall(call.id)
                        setShowEditCallModal(true)
                        setSelectedCall(call)
                      }}
                    >
                      {t('coach_dashboard.delete')}
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
