import React, { Fragment, useCallback, useEffect } from 'react'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import EditCallModal from '../../../dashboard/UpcomingCalls/EditCallModal'
import { useFetchCoachUpcomingCalls } from '../../../dashboard/redux/fetchCoachUpcomingCalls'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function ScheduleSection({ userId }) {
  const { coachCalls, fetchCoachCalls } = useFetchCoachUpcomingCalls()
  const { t } = useTranslation()

  const [calls, setCalls] = React.useState([])
  const [startTimestamp, setStartTimestamp] = React.useState(
    new Date().valueOf()
  )

  useEffect(() => {
    fetchCoachCalls({
      user_id: userId,
      start_timestamp: startTimestamp,
    })

    loadMore()
  }, [])

  useEffect(() => {
    if (coachCalls && coachCalls.results && coachCalls.results.length > 0) {
      setCalls(prev => [
        ...prev.filter(
          call =>
            !coachCalls.results.find(
              newCall =>
                newCall.id === call.id &&
                newCall.meeting_timestamp === call.meeting_timestamp
            )
        ),
        ...coachCalls.results,
      ])
      return
    }
  }, [coachCalls])

  const loadMore = useCallback(async () => {
    // sum one week to the start timestamp
    setStartTimestamp(prev => prev + 604800000)
    fetchCoachCalls({
      user_id: userId,
      start_timestamp: startTimestamp + 604800000,
    })
  }, [coachCalls, startTimestamp, fetchCoachCalls])

  const [dropdownShown, setDropdownShown] = React.useState(-1)
  const editDropdownRef = React.useRef(null)
  useOutsideAlerter(editDropdownRef, () => setDropdownShown(-1))

  // const [showModal, setShowModal] = React.useState(false)
  const [showEditCallModal, setShowEditCallModal] = React.useState(false)
  const [deleteCall, setDeleteCall] = React.useState(false)
  const [selectedCall, setSelectedCall] = React.useState(null)

  return (
    <div className="rpm-client-profile__container__right-body__schedule-section">
      <h3>
        Upcoming Calls (Now -{' '}
        {new Date(startTimestamp + 604800000).toLocaleString('default', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
        )
      </h3>

      {calls && calls.length > 0 && <hr />}
      <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls">
        {calls && calls.length > 0 && (
          <EditCallModal
            callId={selectedCall ? selectedCall.id : null}
            show={showEditCallModal}
            onHide={() => {
              setShowEditCallModal(false)
              setDeleteCall(false)
              setSelectedCall(null)
              fetchCoachCalls({
                user_id: userId,
                start_timestamp: startTimestamp,
              })
            }}
            coachCalls={calls}
            deleteCall={deleteCall}
            userId={userId}
          />
        )}
        {calls &&
          calls.map((call, index) => {
            const {
              meeting_timestamp,
              name,
              call_name,
              is_recurrent,
              meeting_link,
              interval,
            } = call
            return (
              <div
                className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call"
                key={index}
              >
                <div className="flex items-center gap-0-5">
                  <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture">
                    <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture__header">
                      {new Date(meeting_timestamp)
                        .toLocaleString('default', {
                          month: 'short',
                        })
                        .toLocaleUpperCase()}
                    </div>
                    <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-call__upcoming-calls__picture__body">
                      <p
                        className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__picture__body__text"
                        style={{
                          fontSize: '1rem',
                          fontWeight: '900',
                        }}
                      >
                        {new Date(meeting_timestamp).toLocaleString('default', {
                          day: 'numeric',
                        })}
                      </p>
                    </div>{' '}
                  </div>
                  <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__name">
                    <p className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__name__text">
                      {call_name}
                    </p>
                    <p className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__name__description">
                      {call?.meeting_participants?.invited &&
                        call?.meeting_participants?.invited.length > 0 &&
                        call?.meeting_participants?.invited.map(
                          (participant, idx) => (
                            <Fragment key={idx}>
                              {participant.first_name} {participant.last_name}
                              {idx <
                              call?.meeting_participants?.invited?.length - 1
                                ? ', '
                                : ''}
                            </Fragment>
                          )
                        )}
                    </p>
                  </div>
                </div>

                <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time">
                  {is_recurrent && <Icon name="recurs" color={'#343434'} />}

                  <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time__text">
                    <p className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time__text__day">
                    {/*   {is_recurrent &&
                        (interval === 0 ? 'Every ' : 'Every other ')} */}
                      {new Date(meeting_timestamp).toLocaleString('default', {
                        weekday: 'long',
                      })}
                    </p>
                    <p className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__time__text__hour">
                      {new Date(meeting_timestamp).toLocaleString('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}{' '}
                      {
                        new Date()
                          .toLocaleTimeString('en-us', {
                            timeZoneName: 'short',
                          })
                          .split(' ')[2]
                      }
                    </p>
                  </div>
                </div>

                <div className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons">
                  <a
                    className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__join-now"
                    href={meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>Join Now</p>
                    <Icon name="arrow-right" />
                  </a>
                  <a
                    href={
                      'https://calendar.google.com/calendar/r/eventedit?text=' +
                      call_name +
                      '&dates=' +
                      // from
                      new Date(meeting_timestamp)
                        .toISOString()
                        .replace(/-|:|\.\d\d\d/g, '') +
                      '/' +
                      // to
                      new Date(meeting_timestamp)
                        .toISOString()
                        .replace(/-|:|\.\d\d\d/g, '') +
                      '&details=' +
                      call_name +
                      '&location=' +
                      meeting_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__schedule"
                    style={{ opacity: 1 }}
                  >
                    <Icon name="modern-calendar" color="#A3A1A0" />
                  </a>

                  <button
                    className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__buttons__edit"
                    onClick={() => setDropdownShown(index)}
                  >
                    <Icon name="threedots" size={3} color="#A3A1A0" />
                  </button>
                </div>
                {dropdownShown === index && (
                  <div
                    className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__dropdown"
                    ref={editDropdownRef}
                  >
                    <div
                      className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__dropdown__item"
                      style={{
                        borderBottom: '1px solid rgba(196, 196, 196, 0.3)',
                      }}
                      onClick={() => {
                        setDropdownShown(-1)
                        setShowEditCallModal(true)
                        setSelectedCall(call)
                      }}
                    >
                      {t('coach_dashboard.edit')}
                    </div>
                    <div
                      className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__upcoming-call__dropdown__item"
                      style={{ color: '#E8744F' }}
                      onClick={() => {
                        setDeleteCall(call.id)
                        setShowEditCallModal(true)
                        setSelectedCall(call)
                      }}
                    >
                      {t('coach_dashboard.delete')}
                    </div>
                  </div>
                )}
              </div>
            )
          })}

        {calls && calls.length > 0 && <hr />}

        {calls && calls.length === 0 && (
          <p className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__no-upcoming-calls">
            No upcoming calls
          </p>
        )}
        <button
          className="rpm-client-profile__container__right-body__schedule-section__upcoming-calls__load-more"
          onClick={loadMore}
        >
          Load More
        </button>
      </div>
    </div>
  )
}
