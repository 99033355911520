import React, { Fragment } from 'react';
import FilterToastItem from './FilterToastItem';
import Icon from '../../../../common/components/Icon';
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage';

export default function StatusFilter({ ...props }) {
  const { setToastState, toastState } = props;

  const { t } = useTranslation();

  const statuses = [
    {
      name: 'active',
      title: t('dashboard.clients.filters.active'),
    },
    {
      name: 'past_due',
      title: t('dashboard.clients.filters.past_due'),
    },
    {
      name: 'canceled',
      title: t('dashboard.clients.filters.canceled'),
    },
    {
      name: 'trialing',
      title: t('dashboard.clients.filters.trialing'),
    },
  ];

  // canceled = UNPAID SOME ISSUE PAYMENT”, “CANCELED UNTIL END PERIOD”, “SUBSCRIPTION INCOMPLETE, FIRST PAYMENT FAILED”, “INCOMPLETE EXPIRED"

  const selectStatus = (status) => {
    if (toastState.selectedStatuses.has(status.name)) {
      if (status.name === 'canceled') {
        toastState.selectedStatuses.delete('canceled');
        toastState.selectedStatuses.delete('incomplete');
        toastState.selectedStatuses.delete('incomplete_expired');
        toastState.selectedStatuses.delete('unpaid');
      } else {
        toastState.selectedStatuses.delete(status.name);
      }
    } else {
      if (status.name === 'canceled') {
        toastState.selectedStatuses.add('canceled');
        toastState.selectedStatuses.add('unpaid');
        toastState.selectedStatuses.add('incomplete_expired');
        toastState.selectedStatuses.add('incomplete');
      } else {
        toastState.selectedStatuses.add(status.name);
      }
    }
    setToastState({ ...toastState });
  };

  return (
    <Fragment>
      <FilterToastItem
        setToastState={setToastState}
        toastState={toastState}
        setName="selectedStatuses"
        switchName="openStatus"
        title={t('dashboard.clients.filters.status')}
      />
      {toastState.openStatus && (
        <div className="list-toast">
          {statuses.map((status, idx) => (
            <Fragment key={status.name}>
              <div
                key={status.name}
                className="list-toast-item"
                onClick={() => selectStatus(status)}
              >
                <Icon
                  name="checkbox"
                  active={toastState.selectedStatuses.has(status.name)}
                />
                <p>{status.title}</p>
              </div>
              {idx !== statuses.length - 1 && <hr className="divider" />}
            </Fragment>
          ))}
        </div>
      )}
    </Fragment>
  );
}
