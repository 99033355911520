import {
  AcceptCoachInvitePage,
  CoachSignup,
  ForgotPasswordPage,
  Invitation,
  LogInPage,
  OnboardingConfirmation,
  Signup,
  SubscriptionInvitation,
  SubscriptionInvitationCheckout,
  UnsubscribePage,
  WelcomePage,
  Typeform,
  Campaigns,
} from './'

export default {
  path: '',
  childRoutes: [
    { path: 'welcome', component: WelcomePage, isIndex: true, noSignIn: true },
    { path: 'log-in', component: LogInPage, noSignIn: true },
    /*     { path: 'sign-up', component: SignUpPage, noSignIn: true },
     */ {
      path: 'forgot-password',
      component: ForgotPasswordPage,
      noSignIn: true,
    },
    {
      path: 'reset-password/:uid/:token',
      component: Invitation,
      noSignIn: true,
    },
    {
      path: 'unsubscribe/:uid/:token',
      component: UnsubscribePage,
      noSignIn: true,
    },
    {
      path: 'accept-coach-invite/:uid/:token',
      component: AcceptCoachInvitePage,
    },
    { path: 'signup', component: Signup, noSignIn: true },
    /*     { path: 'signup_stripe', component: SignupStripe },
     */ {
      path: 'invitation/:uid/:token',
      component: Invitation,
      noSignIn: true,
    },
    { path: 'subscription-invitation', component: SubscriptionInvitation },
    {
      path: 'subscription-invitation-checkout/:price_id',
      component: SubscriptionInvitationCheckout,
    },
    { path: 'onboarding-confirmation', component: OnboardingConfirmation },
    { path: 'coach-signup', component: CoachSignup },
    { path: 'typeform/:id', component: Typeform },
    { path: 'campaigns', component: Campaigns },
  ],
}
