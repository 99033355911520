import React from 'react';
import Icon from '../../../common/components/Icon';
import GirlPic from '../../assets/check-in/girl.png';

export default function CheckIn() {
  return (
    <div className="check-in">
      <h3>Next Check-In</h3>
      <div className="check-in-card-container">
        <div className="check-in-card">
          <div className="check-in-card-header">
            <img src={GirlPic} alt="girl smiling" />
            <div className="check-in-card-header-text">
              <h4>Whitney T.</h4>
              <p>November Check-in</p>
            </div>
          </div>
          <div className="check-in-card-body">
            <p className="check-in-card-body-date">Mon Nov 23</p>
            <p className="check-in-card-body-time">1:00pm — 2:00pm</p>
          </div>
          <div className="check-in-card-footer">
            <Icon name="add-date" color={'#A3A1A0'} />

            <div className="check-in-card-footer-right">
              <p>Join Now</p>
              <Icon name="arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
