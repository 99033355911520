import React, { useEffect } from 'react'
import ProgressWheel from '../dashboard/records/ProgressWheel'
import useWindowSize from '../../../common/useWindowResize'
import { useGetProfileSummary } from '../../redux/getProfileSummary'
import { getEligibilityColor } from '../../utils/getEligibilityColor'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function Header({ user }) {
    const { isDesktop } = useWindowSize()
    const { profileSummary, getProfileSummary } = useGetProfileSummary()

    useEffect(() => {
        if (user) getProfileSummary({ user_id: user.id })
    }, [user])

    const {
        billing_cycle = {
            start: '',
            end: '',
        },
        eligibility_period = {
            days_left: 0,
            days_track: 0,
            date_end: '',
        },
        status = '',
    } = profileSummary || {}

    const { t } = useTranslation()

    return (
        <div className="rpm-client-profile-right-header">
            <div className="wheel-container">
                <ProgressWheel
                    progress={Math.abs(
                        (eligibility_period.days_track / 16) * 100 > 100
                            ? 100
                            : (eligibility_period.days_track / 16) * 100
                    )}
                    days={Math.abs(eligibility_period.days_track)}
                    className="rpm-client-profile"
                />
                <div className="rpm-client-profile-right-header__left">
                    <div
                        className={`rpm-client-profile-right-header__left__status`}
                        style={{
                            borderColor: getEligibilityColor({
                                eligibility: status,
                            }),
                        }}
                    >
                        <div
                            className="dot"
                            style={{
                                backgroundColor: getEligibilityColor({
                                    eligibility: status,
                                }),
                            }}
                        />
                        <p
                            className="text"
                            style={{
                                color: getEligibilityColor({
                                    eligibility: status,
                                }),
                            }}
                        >
                            {(status &&
                                t(`client_profile.${status.toLowerCase()}`)) ||
                                t('client_profile.pending')}
                        </p>
                    </div>
                    {/*  <h3>13/16 days tracked</h3> */}
                    <h3>
                        {Math.abs(eligibility_period.days_track)}/16{' '}
                        {t('client_profile.days_tracked')}
                    </h3>

                    <p className="rpm-client-profile-right-header__left__text">
                        <span
                            style={{
                                textDecoration: 'underline',
                            }}
                        >
                            {eligibility_period.days_left}{' '}
                            {t('client_profile.days_left')}
                        </span>{' '}
                        {t('client_profile.in_eligibility_period')}
                    </p>
                </div>
            </div>

            <div className="rpm-client-profile-right-header__right">
                <p className="rpm-client-profile-right-header__right__title">
                    {t('client_profile.billing_cycle')}
                </p>
                {billing_cycle.start ? (
                    <p className="rpm-client-profile-right-header__right__text">
                        {billing_cycle.start.split('-').join('/')}
                        {isDesktop && <br />}— {isDesktop && <br />}
                        {billing_cycle.end.split('-').join('/')}
                    </p>
                ) : (
                    <p className="rpm-client-profile-right-header__right__text">
                        {t('client_profile.not_available')}
                    </p>
                )}
            </div>
        </div>
    )
}
