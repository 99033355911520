import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import React from 'react';
import Icon from '../../../common/components/Icon';
import ChangePaymentMethodModal from '../ChangePaymentMethodModal';
import config from '../../../../common/config';
import { useMakePaymentToSubscriptionUnpaid } from '../../redux/makePaymentToSubscriptionUnpaid';
import { HasDeclinedPayment, IsPastDue } from '../../../../common/permissions';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

const stripePromise = loadStripe(config.publishableKey);
export default function CreditCard({ desktop = true, last4, brand, show }) {
  brand = brand?.[0].toUpperCase() + brand?.slice(1);
  const clientSecret =
    'pi_3Jz7R2GvgjKLpHZZ0N4AU8ZI_secret_9L5jftnEOimi0Xz2QTUoNHbfH';

  const appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      fontFamily: 'Poppins, sans-serif',
      colorText: '#6D786E',
      borderRadius: '10px',
      spacingUnit: '2.8px',
    },
  };

  const hasDeclinedPayment = HasDeclinedPayment();
  const isPastDue = IsPastDue();

  const canTryToPayAgain = hasDeclinedPayment || isPastDue;

  const options = {
    appearance,
    clientSecret,
  };

  const { t } = useTranslation();

  const [showUpdateModal, setShowUpdateModal] = React.useState(false);

  const { makePaymentToSubscriptionUnpaid } =
    useMakePaymentToSubscriptionUnpaid();

  const handleSubmit = async (event) => {
    event.preventDefault();
    makePaymentToSubscriptionUnpaid()
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="payment-method-container"
      style={{ padding: desktop === true ? '1.5rem 2.5rem' : '1rem 1.5rem' }}
    >
      <p className="title">{t('profile.payment_method')}</p>
      <div className="card-info">
        <div className="card-type">
          {' '}
          {brand === 'Visa' && <Icon name="visa" size={30} />}
          {brand === 'Mastercard' && <Icon name="mastercard" size={30} />}
          {brand === 'Amex' && <Icon name="amex" size={30} />}
          <p>{brand}</p>
        </div>
        <div className="card-number">
          {' '}
          <p>•••• •••• •••• {last4}</p>
          <div className="buttons-container">
            {canTryToPayAgain && (
              <button onClick={handleSubmit}>
                <p className="update">Try to pay again</p>
              </button>
            )}
            {canTryToPayAgain && <>|</>}

            <button onClick={() => setShowUpdateModal(true)}>
              {' '}
              <p className="update">update</p>
            </button>
          </div>
        </div>
      </div>

      <Elements stripe={stripePromise} options={options}>
        <ChangePaymentMethodModal
          show={showUpdateModal || show}
          onHide={() => {
            setShowUpdateModal(false);
          }}
        />
      </Elements>
    </div>
  );
}
