// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
    listHrvPending: false,
    listHrvError: null,
    listDevicesPending: false,
    listDevicesError: null,
    fitbitOauthPending: false,
    fitbitOauthError: null,
    fetchUnifiedSummaryPending: false,
    fetchUnifiedSummaryError: null,
    createInsuranceInformationPending: false,
    createInsuranceInformationError: null,
    createProfileAddressPending: false,
    createProfileAddressError: null,
    fetchAssessmentsPending: false,
    fetchAssessmentsError: null,
    createAssessmentScorePending: false,
    createAssessmentScoreError: null,
    getCptListPending: false,
    getCptListError: null,
    createCptLogPending: false,
    createCptLogError: null,
    getClientsPending: false,
    getClientsError: null,
    getProfileSummaryPending: false,
    getProfileSummaryError: null,
    getUserInsuranceInformationPending: false,
    getUserInsuranceInformationError: null,
    updateUserInsuranceInformationPending: false,
    updateUserInsuranceInformationError: null,
    getLatestUnifiedSummaryPending: false,
    getLatestUnifiedSummaryError: null,
    getAlertsPending: false,
    getAlertsError: null,
    clearAllAlertsPending: false,
    clearAllAlertsError: null,
    getBillingPending: false,
    getBillingError: null,
  deleteCptLogPending: false,
  deleteCptLogError: null,
  updateCptLogPending: false,
  updateCptLogError: null,
  updateAssessmentPending: false,
  updateAssessmentError: null,
  deleteAssessmentPending: false,
  deleteAssessmentError: null,
  getProvidersSummaryPending: false,
  getProvidersSummaryError: null,
  trackCallEventPending: false,
  trackCallEventError: null,
  fetchCompanyLinksPending: false,
  fetchCompanyLinksError: null,
}

export default initialState
