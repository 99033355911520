import React from 'react'
import Icon from '../../../common/components/Icon'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import Filters from './Filters'
import Sort from './Sort'

export default function SearchContainer({ params, setParams, clientsCount }) {
    const { t } = useTranslation()
    return (
        <div className="search-container">
            <div className="left-search-container">
                <h2 className="search-container-title">
                    {t('coach_dashboard.clients')}
                </h2>

                <div className="search-container-number">
                    <h2 className="search-container-number-text">
                        {clientsCount}
                    </h2>
                </div>
            </div>
            <div className="right-search-container">
                <div className="search-container-input">
                    <Icon name="search" color="#9DA16B" size={14.5} />
                    <input
                        type="text"
                        placeholder={t('coach_dashboard.search')}
                        value={params.search}
                        onChange={e =>
                            setParams({ ...params, search: e.target.value })
                        }
                    />
                </div>
                <Filters params={params} setParams={setParams} />
                <Sort params={params} setParams={setParams} />
            </div>
        </div>
    )
}
