import { useTranslation } from '../../../../translations/redux/setDefaultLanguage';

const sortByOptions = [
  { label: 'First Name', field: 'first_name', name: 'first_name' },
  { label: 'Last Name', field: 'last_name', name: 'last_name' },
  { label: 'Most Progress', field: '-max_progress', name: 'most_progress' },
  {
    label: 'Most red assessments',
    field: '-red_assessments_count',
    name: 'most_red_assessments',
  },
  { label: 'Last Login', field: '-last_seen', name: 'last_login' },
  {
    label: 'Last Action Item Completed',
    field: 'recent_action_item_done',
    name: 'last_ai',
  },
];

export const useSortByOptions = () => {
  const { t } = useTranslation();

  return {
    sortByOptions: sortByOptions.map((option) => ({
      ...option,
      label: t(`dashboard.clients.filters.${option.name}`),
    })),
  };
};
