// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as fetchUserReducer } from './fetchUser';
import { reducer as updateUserReducer } from './updateUser';
import { reducer as updateProfilePhotoReducer } from './updateProfilePhoto';
import { reducer as deleteUserReducer } from './deleteUser';
import { reducer as switchActiveCompanyReducer } from './switchActiveCompany';
import { reducer as deleteCoachReducer } from './deleteCoach';
import { reducer as inviteCoachReducer } from './inviteCoach';
import { reducer as fetchNotificationsSettingsReducer } from './fetchNotificationsSettings';
import { reducer as updateNotificationsSettingsReducer } from './updateNotificationsSettings';
import { reducer as bulkSwitchNotificationsSettingsReducer } from './bulkSwitchNotificationsSettings';
import { reducer as ChangePasswordReducer } from './changePassword';
import { reducer as getPaymentMethodReducer } from './getPaymentMethod';
import { reducer as getSubscriptionStatusReducer } from './getSubscriptionStatus';
import { reducer as checkUserExistsByEmailReducer } from './checkUserExistsByEmail';
import { reducer as getSubscriptionStatusByEmailReducer } from './getSubscriptionStatusByEmail';
import { reducer as assignCoachToStudentReducer } from './assignCoachToStudent';
import { reducer as cancelSubscriptionReducer } from './cancelSubscription';
import { reducer as updateProductSubscriptionReducer } from './updateProductSubscription';
import { reducer as listSubscriptionByCoachReducer } from './listSubscriptionByCoach';
import { reducer as cancelSubscriptionByCoachReducer } from './cancelSubscriptionByCoach';
import { reducer as getSubscriptionByCoachAndUserReducer } from './getSubscriptionByCoachAndUser';
import { reducer as getProductByStripePriceIdReducer } from './getProductByStripePriceId';
import { reducer as changePaymentMethodCardReducer } from './changePaymentMethodCard';
import { reducer as subscriptionInvitationCheckoutReducer } from './subscriptionInvitationCheckout';
import { reducer as getSubscriptionInvitationsReducer } from './getSubscriptionInvitations';
import { reducer as createRefundForClientReducer } from './createRefundForClient';
import { reducer as getChargesByCustomerReducer } from './getChargesByCustomer';
import { reducer as makePaymentToSubscriptionUnpaidReducer } from './makePaymentToSubscriptionUnpaid';
import { reducer as userAgreementReducer } from './userAgreement';
import { reducer as profileSettingsReducer } from './profileSettings';

const reducers = [
  fetchUserReducer,
  updateUserReducer,
  updateProfilePhotoReducer,
  deleteUserReducer,
  switchActiveCompanyReducer,
  deleteCoachReducer,
  inviteCoachReducer,
  fetchNotificationsSettingsReducer,
  updateNotificationsSettingsReducer,
  bulkSwitchNotificationsSettingsReducer,
  ChangePasswordReducer,
  getPaymentMethodReducer,
  getSubscriptionStatusReducer,
  checkUserExistsByEmailReducer,
  getSubscriptionStatusByEmailReducer,
  assignCoachToStudentReducer,
  cancelSubscriptionReducer,
  updateProductSubscriptionReducer,
  listSubscriptionByCoachReducer,
  cancelSubscriptionByCoachReducer,
  getSubscriptionByCoachAndUserReducer,
  getProductByStripePriceIdReducer,
  changePaymentMethodCardReducer,
  subscriptionInvitationCheckoutReducer,
  getSubscriptionInvitationsReducer,
  createRefundForClientReducer,
  getChargesByCustomerReducer,
  makePaymentToSubscriptionUnpaidReducer,
  userAgreementReducer,
  profileSettingsReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
