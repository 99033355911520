import React from 'react';

export default function MicrophoneIcon({ size = 27, color = 'white' }) {
  return (
    <svg
      width={size * 1.28571429}
      height={size}
      viewBox="0 0 21 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_451_116)">
        <path
          d="M16.3217 6.76947C16.3217 3.45957 13.6066 0.776367 10.2573 0.776367C6.908 0.776367 4.19287 3.45957 4.19287 6.76947V12.8247C4.19287 16.1346 6.908 18.8178 10.2573 18.8178C13.6066 18.8178 16.3217 16.1346 16.3217 12.8247V6.76947Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.4639 12.8091C19.4639 15.2221 18.4939 17.5363 16.7673 19.2426C15.0407 20.9488 12.699 21.9074 10.2573 21.9074V21.9074C7.81552 21.9074 5.47378 20.9488 3.74721 19.2426C2.02064 17.5363 1.05066 15.2221 1.05066 12.8091"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2573 21.9074V26.1616"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.11981 26.2237H15.3948"
          stroke={color}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.19287 8.88098H7.42932"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.19287 11.6291H7.42932"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.101 8.88098H16.3217"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.101 11.6291H16.3217"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_451_116">
          <rect
            width="20"
            height="27"
            fill={color}
            transform="translate(0.265137)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
