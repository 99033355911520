import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ClinicalRecordSubmitted from './ClinicalRecordSubmitted'
import axios from 'axios'

const validationSchema = Yup.object().shape({
  session_length: Yup.string().required('Session length is required'),
  notes: Yup.string().required('Notes are required'),
})

export default function SendClinicalRecordModal({ show, onHide, user }) {
  const { t } = useTranslation()

  const SUBMITTING = 'submitting'
  const REVIEWING = 'reviewing'

  const [currentStep, setCurrentStep] = React.useState(SUBMITTING)
  const [showClinicalSubmitted, setShowClinicalSubmitted] = React.useState({
    show: false,
    success: true,
  })

  return (
    <Fragment>
      <ClinicalRecordSubmitted
        success={showClinicalSubmitted.success}
        show={showClinicalSubmitted.show}
        onHide={() => setShowClinicalSubmitted({ show: false, success: true })}
        description={
          showClinicalSubmitted.success
            ? t('client_profile.submission_was_sent_successfully')
            : t('client_profile.error_occurred')
        }
        title={
          showClinicalSubmitted.success
            ? t('client_profile.success')
            : t('client_profile.process_failed')
        }
      />

      <Modal
        className="send-clinical-record-modal"
        show={show}
        onHide={onHide}
        centered
      >
        {currentStep === SUBMITTING && (
          <h3>{t('client_profile.submit_medical_record')}</h3>
        )}

        {currentStep === REVIEWING && (
          <h3>{t('client_profile.confirm_submission')}</h3>
        )}

        <Formik
          initialValues={{
            send_to: 'Prime Meridian Health',
            client: (user.first_name || '') + ' ' + (user.last_name || ''),
            session_length: '',
            notes: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            if (currentStep === SUBMITTING) {
              setCurrentStep(REVIEWING)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="send_to">Send to</label>
                <input
                  type="text"
                  className="form-control"
                  id="send_to"
                  name="send_to"
                  value={values.send_to}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                  style={{
                    display: currentStep === SUBMITTING ? 'block' : 'none',
                  }}
                />

                {currentStep === REVIEWING && (
                  <p className="reviewing-text">{values.send_to}</p>
                )}
                {errors.send_to && touched.send_to && (
                  <div className="error-message">{errors.send_to}</div>
                )}

                <label htmlFor="client">Client</label>
                <input
                  type="text"
                  className="form-control"
                  id="client"
                  name="client"
                  value={values.client}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                  style={{
                    display: currentStep === SUBMITTING ? 'block' : 'none',
                  }}
                />

                {currentStep === REVIEWING && (
                  <p className="reviewing-text">{values.client}</p>
                )}

                <label htmlFor="session_length">Session length</label>
                <input
                  type="text"
                  className="form-control"
                  id="session_length"
                  name="session_length"
                  value={values.session_length}
                  onChange={e => {
                    //if it's not a number, don't allow it
                    if (isNaN(e.target.value)) {
                      return
                    }
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  placeholder="Minutes"
                  style={{
                    display: currentStep === SUBMITTING ? 'block' : 'none',
                  }}
                />

                {currentStep === REVIEWING && (
                  <p className="reviewing-text">{values.session_length}</p>
                )}

                {errors.session_length && touched.session_length && (
                  <div className="error-message">{errors.session_length}</div>
                )}

                <label htmlFor="notes">Notes</label>
                <textarea
                  className="form-control"
                  id="notes"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows="10"
                  style={{
                    display: currentStep === SUBMITTING ? 'block' : 'none',
                  }}
                />

                {currentStep === REVIEWING && (
                  <p className="reviewing-text">{values.notes}</p>
                )}

                {errors.notes && touched.notes && (
                  <div className="error-message">{errors.notes}</div>
                )}
              </div>

              <div className="send-clinical-record-modal__buttons">
                {currentStep === REVIEWING && (
                  <button
                    type="button"
                    className="back-btn"
                    onClick={() => setCurrentStep(SUBMITTING)}
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="submit-btn"
                  onClick={async () => {
                    if (currentStep === SUBMITTING) {
                      handleSubmit()
                    } else {
                      onHide()

                      const token =
                        'vIEm~SY.gkqdWpbrSXZ77dGksuy7iTexdvgj2S.W_Yr-t+-HVZDJZuOlB/tJabwtdTzgBFsw7XvbgXy.XGTjXzMeqM2sM1ILpD_S5.6IoWg9o5_ZAnjCx6XK'

                      const payload = {
                        data: {
                          email: user.email,
                          payload: values.notes,
                        },
                      }

                      axios('https://thriverhealth.com/api3/v1/notes', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                        data: JSON.stringify(payload),
                      })
                        .then(data => {
                          setFieldValue('session_length', '')
                          setFieldValue('notes', '')
                          setCurrentStep(SUBMITTING)
                          setShowClinicalSubmitted({
                            show: true,
                            success: true,
                          })
                          console.log(data)
                        })
                        .catch(error => {
                          setCurrentStep(SUBMITTING)
                          setShowClinicalSubmitted({
                            show: true,
                            success: false,
                          })
                          console.log('clinical error', error)
                        })
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  )
}
