import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_UPDATE_ASSESSMENT_BEGIN,
    RPM_UPDATE_ASSESSMENT_SUCCESS,
    RPM_UPDATE_ASSESSMENT_FAILURE,
    RPM_UPDATE_ASSESSMENT_DISMISS_ERROR,
} from './constants'
import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function updateAssessment(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_UPDATE_ASSESSMENT_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.put(
                `${config.apiRootUrl}/rpm/assessment-score/${args.id}/`,
                args,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_UPDATE_ASSESSMENT_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_UPDATE_ASSESSMENT_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissUpdateAssessmentError() {
    return {
        type: RPM_UPDATE_ASSESSMENT_DISMISS_ERROR,
    }
}

export function useUpdateAssessment() {
    const dispatch = useDispatch()

    const { updateAssessmentPending, updateAssessmentError } = useSelector(
        state => ({
            updateAssessmentPending: state.rpm.updateAssessmentPending,
            updateAssessmentError: state.rpm.updateAssessmentError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(updateAssessment(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissUpdateAssessmentError())
    }, [dispatch])

    return {
        updateAssessment: boundAction,
        updateAssessmentPending,
        updateAssessmentError,
        dismissUpdateAssessmentError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_UPDATE_ASSESSMENT_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                updateAssessmentPending: true,
                updateAssessmentError: null,
            }

        case RPM_UPDATE_ASSESSMENT_SUCCESS:
            // The request is success
            return {
                ...state,
                updateAssessmentPending: false,
                updateAssessmentError: null,
            }

        case RPM_UPDATE_ASSESSMENT_FAILURE:
            // The request is failed
            return {
                ...state,
                updateAssessmentPending: false,
                updateAssessmentError: action.data.error,
            }

        case RPM_UPDATE_ASSESSMENT_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                updateAssessmentError: null,
            }

        default:
            return state
    }
}
