import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import xor from 'lodash/xor';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Switch from 'react-switch';
import { useWindowSize } from '../../../../common/windowsSize';
import { useAddUser } from '../../../manage/redux/addUser';
import { useBulkAssignUserRoadmaps } from '../../../manage/redux/bulkAssignUserRoadmaps';
import { useUpdateUser } from '../../../manage/redux/updateUser';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';
import { useAssignCoachToStudent } from '../../../user/redux/assignCoachToStudent';
import { useCheckUserExistsByEmail } from '../../../user/redux/checkUserExistsByEmail';
import { useGetSubscriptionStatusByEmail } from '../../../user/redux/getSubscriptionStatusByEmail';
import { useSubscriptionInvitationCheckout } from '../../../user/redux/subscriptionInvitationCheckout';
import { InvitationContext } from '../InvitationContext';
import { customComponentDefaultValues } from '../utils/customComponentDefaultValues';
import { useFields } from '../utils/useFields';
import { schema } from '../utils/invitationSchema';
import { useHandleError } from '../utils/OnboardingInvitation/hooks/useHandleError';
import { useHandleSaveClick } from '../utils/OnboardingInvitation/hooks/useHandleSaveClick';

export default function Step1({ body = false }) {
  const {
    step,
    setStep,
    setFirstVals,
    firstVals,
    user,
    setIsLoading,
    subSelected,
    selectedRoadmaps,
    onSuccessfulSave,
    replaceStringWithSynonyms,
    setSaveButtonProps,
  } = useContext(InvitationContext);

  const { fields } = useFields();

  const { t } = useTranslation();

  const photoFile = null;
  const [errorByConsole, setErrorByConsole] = useState({});

  const [validations, setValidations] = useState({
    first_name: false,
    last_name: false,
    email: false,
  });

  const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail();

  // yup schema
  const { register, handleSubmit, control, errors, setError } = useForm({
    resolver: yupResolver(schema),
  });

  const [invalidEmail, setInvalidEmail] = useState(false);

  const [userExists, setUserExists] = useState(false);

  const { checkUserExistsByEmail } = useCheckUserExistsByEmail();

  const { updateUserPending } = useUpdateUser();
  const { bulkAssignUserRoadmaps } = useBulkAssignUserRoadmaps();
  const { subscriptionInvitationCheckout } =
    useSubscriptionInvitationCheckout();
  const { addUser, addUserPending } = useAddUser();
  const { assignCoachToStudent } = useAssignCoachToStudent();
  const handleError = useHandleError({
    setError,
    setErrorByConsole,
  });

  const [hasActiveSubscription, setHasActiveSubscription] = useState(null);

  useEffect(() => {
    const checkEmail = async () => {
      getSubscriptionStatusByEmail({ email: firstVals.email })
        .then((res) => {
          console.log(res);

          if (res.data.current_subscription_state === 'active') {
            setHasActiveSubscription('active');
          } else {
            setHasActiveSubscription(false);
          }
        })

        .catch((err) => {
          // if error is 400, set hasActiveSubscription to false

          if (err.response.status === 400) {
            setHasActiveSubscription(false);
          }
        });
    };

    if (firstVals?.email) {
      const timerId = setTimeout(() => {
        checkEmail();
      }, 500);

      return () => clearTimeout(timerId);
    }
  }, [getSubscriptionStatusByEmail, firstVals, setHasActiveSubscription]);

  const size = useWindowSize();

  const handleNextStep = async () => {
    if (!firstVals.first_name) {
      setValidations({ ...validations, first_name: true });
      return;
    }

    if (!firstVals.email) {
      setValidations({
        first_name: false,
        email: true,
      });
      return;
    }

    if (firstVals.first_name && firstVals.email) {
      setStep(2);
      setValidations({
        first_name: false,
        email: false,
      });
    }
  };

  useEffect(() => {
    if (firstVals.first_name) {
      setValidations({ ...validations, first_name: false });
    }

    if (firstVals.email) {
      setValidations({ ...validations, email: false });
    }
  }, [firstVals]);

  // email validation
  useEffect(() => {
    if (firstVals?.email) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(firstVals.email) === false) {
        setInvalidEmail(true);
      } else setInvalidEmail(false);
    }
  }, [firstVals, setInvalidEmail]);

  useEffect(() => {
    const checkUserExists = async () => {
      checkUserExistsByEmail({ email: firstVals.email }).then((res) => {
        console.log(res);
        setUserExists(res.data.user_exists);
      });
    };
    if (firstVals?.email) {
      const timerId = setTimeout(() => {
        checkUserExists();
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [checkUserExistsByEmail, user, firstVals]);

  const handleSaveClick = useHandleSaveClick({
    photoFile,
    user,
    setIsLoading,
    userExists,
    assignCoachToStudent,
    firstVals,
    selectedRoadmaps,
    bulkAssignUserRoadmaps,
    subSelected,
    subscriptionInvitationCheckout,
    setStep,
    handleError,
    addUser,
    onSuccessfulSave,
  });

  useEffect(() => {
    if (body) {
      setSaveButtonProps({
        disabled: addUserPending || updateUserPending,
        onClick: handleSubmit(handleSaveClick),
      });
    }
  }, [
    setSaveButtonProps,
    addUserPending,
    updateUserPending,
    handleSubmit,
    handleSaveClick,
    body,
  ]);
  if (body) {
    return (
      <>
        {size.width < 768 && step === 1 && <p className="mrm-py-1">Add User</p>}
        {fields.map(({ type, name, label, display, disabled }) => (
          <Form.Group
            controlId={name}
            key={name}
            className={
              clsx(
                { 'd-none': display === 'none' },
                { 'mrm-mb-1 position-relative': type === 'file' },
              ) + (step === 1 ? '' : ' d-none')
            }
            onChange={(e) => {
              setFirstVals({ ...firstVals, [name]: e.target.value });
            }}
          >
            {type === 'string' ? (
              <Form.Control
                name={name}
                isInvalid={errors[name]}
                ref={register}
                disabled={disabled}
                placeholder={label}
              />
            ) : type === 'text' ? (
              <Form.Control
                name={name}
                as="textarea"
                isInvalid={errors[name]}
                ref={register}
                placeholder={label}
                className="text-area-edit-profile"
              />
            ) : (
              <Controller
                name={name}
                control={control}
                defaultValue={customComponentDefaultValues[name]}
                render={({ onChange, value }) =>
                  type === 'checkbox' ? (
                    <div className="checkbox-grid">
                      {['User', 'Coach', 'Admin'].map((group) => (
                        <Form.Check
                          type="checkbox"
                          key={group}
                          id={group}
                          label={replaceStringWithSynonyms(group)}
                          isInvalid={errors[name]}
                          checked={value.includes(group)}
                          onChange={() => onChange(xor(value, [group]))}
                        />
                      ))}
                    </div>
                  ) : (
                    type === 'switch' && (
                      <div
                        style={{
                          padding: '0 1.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.8rem',
                        }}
                      >
                        <Switch
                          onChange={onChange}
                          checked={true}
                          onColor="#343434"
                        />
                        <p>Approved</p>
                      </div>
                    )
                  )
                }
              />
            )}

            {errors[name] && errors[name].type === 'remote' && (
              <small className="error-message">{errors[name].message}</small>
            )}
          </Form.Group>
        ))}

        <div className="invitation-button-container">
          {invalidEmail && (
            <p className="error-active-sub">
              {t('dashboard.onboarding.first_step.validations.invalid_email')}
            </p>
          )}
          {errorByConsole.key && (
            <p className="error-from-console">
              {errorByConsole.key}: {errorByConsole.message}
            </p>
          )}
          {validations.first_name && step === 1 && (
            <p className="error-from-console mrm-mb-1">
              {t('dashboard.onboarding.first_step.validations.first_name')}
            </p>
          )}
          {validations.email && step === 1 && (
            <p className="error-from-console mrm-mb-1">
              {t('dashboard.onboarding.first_step.validations.email')}
            </p>
          )}

          {hasActiveSubscription && firstVals.email && (
            <p className="error-active-sub">
              {t('dashboard.onboarding.first_step.validations.active_sub')}
            </p>
          )}
          {step === 1 && (
            <button
              onClick={() => {
                handleNextStep();
              }}
              className="invitation-submit"
              disabled={hasActiveSubscription || invalidEmail ? true : false}
            >
              {t('dashboard.onboarding.first_step.next')}
            </button>
          )}
        </div>
      </>
    );
  } else if (step === 1 && !body) {
    return (
      <div
        style={{ padding: size.width < 768 ? '0 1rem' : '0' }}
        className="step-1"
      >
        <h1>{t('dashboard.onboarding.first_step.title')}</h1>{' '}
        <p className="description">
          {t('dashboard.onboarding.first_step.description')}
        </p>
        <p className="mrm-mt-1 d-none d-lg-block">
          {t('dashboard.onboarding.first_step.client_information')}
        </p>
      </div>
    );
  } else {
    return <></>;
  }
}
