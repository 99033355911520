import React from 'react';
import Icon from '../../../../common/components/Icon';
export default function RadioInput({
  onClick = () => {},
  label = '',
  active = true,
  size = 21,
  className = '',
  style = {},
}) {
  return (
    <div className={`support-radio-container ${className}`} style={style}>
      <div className="radio-icon" onClick={onClick}>
        <Icon name="checkradio" active={active} size={size} />
      </div>
      <p>{label}</p>
    </div>
  );
}
