export const dashboard = {
  title: 'Tablero',
  subtitle: 'Esta es la página del tablero.',
  coach_message_prompt: {
    topHeader: '¡Empecemos!',
    header: 'Invitar a un cliente',
    description:
      'Agregue un nuevo cliente, asigne un Roadmap y nosotros nos encargamos del resto.',
    button: 'Invitar a un cliente',
  },
  my_roadmaps: {
    title: 'Mis Roadmaps',
    completed: 'completado',
  },
  roadmaps_carousel: {
    title: 'Descubre nuestros Roadmaps',
  },
  my_coaching: 'Mi entrenamiento',
  my_payouts: { title: 'Mis pagos' },
  upcoming_calls: {
    title: 'Próximas llamadas',
    no_calls: 'No hay llamadas próximas',
  },
  clients: {
    title: 'Mis clientes',
    my_patients: 'Mis pacientes',
    search: 'Buscar',
    no_clients: 'No hay clientes',
    powered_by: 'Desarrollado por',
    filters: {
      filter: 'Filtrar',
      sort: 'Ordenar',
      sort_by: 'Ordenar por',
      apply_filters: 'Aplicar filtros',
      reset_filters: 'Restablecer',
      enrolled_roadmaps: 'Roadmaps inscritos',
      status: 'Estado',
      active: 'Activo',
      past_due: 'Vencido',
      canceled: 'Cancelado',
      trialing: 'Prueba',
      first_name: 'Nombre',
      last_name: 'Apellido',
      most_progress: 'Más progreso',
      most_red_assessments: 'Más evaluaciones rojas',
      last_login: 'Último inicio de sesión',
      last_ai: 'Último AI',
    },
  },
  onboarding: {
    cancel: 'Cancelar',
    modal_header_1: '¡Antes de poder agregar un cliente, tenemos',
    modal_header_2: 'que asegurarnos de que puedas cobrar!',
    modal_description:
      'Usando Stripe Connect, podemos transferir de forma segura y segura el dinero de tus clientes a ti.',
    connect_bank: 'Conecta a tu cuenta bancaria',
    connect_button: 'Conectar con Stripe',
    learn: 'Aprende más sobre Stripe',
    first_step: {
      title: 'Invitar a un cliente',
      description:
        'Dinos a quién quieres invitar y nos encargaremos del resto. Los prepararemos y te avisaremos cuando estén listos.',
      client_information: 'Información del cliente',
      first_name: 'Nombre',
      last_name: 'Apellido',
      email: 'Email',
      next: 'Siguiente',
      validations: {
        first_name: 'El nombre es requerido',
        last_name: 'El apellido es requerido',
        email: 'El email es requerido',
        invalid_email: 'El email no es válido',
        active_sub:
          'No puedes enviar una invitación a personas con suscripciones activas.',
      },
    },
    second_step: {
      title: 'Asignale un Roadmap a tu cliente',
      description:
        'Vamos a que tu cliente trabaje en uno de nuestros Roadmaps basados en la ciencia.',
      featured_roadmaps: 'Roadmaps destacados',
      next: 'Siguiente',
    },
    third_step: {
      title: '¿Cuánto te gustaría cobrar?',
      description:
        'Establece tu precio. Tanto alto como bajo como quieras. Tienes el control completo.',
      create_subscription: 'Crear una suscripción personalizada',
      choose_subscription: 'Elegir una suscripción',
      next: 'Siguiente',
      no_price: 'sin precio',
      month_abbr: 'mes',
      add_new_subscriptions: 'Agregar nuevas suscripciones',
      subscription_placeholder: 'Nombre de la suscripción',
    },
    fourth_step: {
      title: 'Revisemos',
      client_information: 'Información del cliente',
      cancel: 'Cancelar',
      edit: 'Editar',
      assigned_roadmap: 'Roadmap asignado',
      subscription: 'Suscripción',
      month_abbr: 'mes',
      send_invitation: 'Enviar invitación',
    },
    fifth_step: {
      title: 'Nosotros nos encargamos del resto.',
      description: `¡Tenemos todo lo que necesitamos de ti! La cuenta de {name} debería estar lista pronto. Mientras tanto, siéntete libre de invitar a otro cliente a Noble!`,
      invite_another: 'Invitar a otro cliente',
      done: 'Hecho',
    },
  },
  support_group: {
    card: {
      sunday: 'Domingo',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      at: 'a las',
    },
  },
};
