export const Fields = ({
  coachAndNotAdmin = false,
  replaceStringWithSynonyms,
  userLastlogin,
}) => {
  if (!coachAndNotAdmin) {
    return [
      { type: 'string', label: 'First Name', name: 'first_name' },
      { type: 'string', label: 'Last Name', name: 'last_name' },
      { type: 'string', label: 'Email', name: 'email' },
      { type: 'string', label: 'Phone Number', name: 'phone_number' },
      { type: 'checkbox', label: 'Roles', name: 'groups' },
      {
        type: 'multiselect',
        label: 'Groups',
        name: 'cohort',
        labelKey: 'text',
      },
      {
        type: 'multiselect',
        label: 'Roadmaps',
        name: 'roadmaps_info',
        labelKey: 'title',
      },
      {
        type: 'async-multiselect',
        label: replaceStringWithSynonyms('Coaches'),
        name: 'coach',
        labelKey: (c) => `${c.first_name} ${c.last_name}`,
      },
      { type: 'file', label: 'Profile Photo', name: 'photo' },
      { type: 'text', label: 'Bio', name: 'bio' },
      { type: 'switch', label: 'Approved', name: 'is_approved' },
      { type: 'switch', label: 'Internal coach', name: 'is_internal_coach' },
    ];
  } else {
    return [
      { type: 'string', label: 'First Name', name: 'first_name', icon: 'user' },
      { type: 'string', label: 'Last Name', name: 'last_name', icon: 'user' },
      {
        type: 'string',
        label: 'Email',
        name: 'email',
        icon: 'email',
        disabled: userLastlogin,
      },
      {
        type: 'string',
        label: 'Phone Number',
        name: 'phone_number',
        icon: 'phone',
      },
      { type: 'checkbox', label: 'Roles', name: 'groups', display: 'none' },
      {
        type: 'multiselect',
        label: 'Groups',
        name: 'cohort',
        labelKey: 'text',
        display: 'none',
      },
      {
        type: 'multiselect',
        label: 'Roadmaps',
        name: 'roadmaps_info',
        labelKey: 'title',
        display: 'none',
      },
      {
        type: 'multiselect',
        label: replaceStringWithSynonyms('Coaches'),
        name: 'coach',
        labelKey: (c) => `${c.first_name} ${c.last_name}`,
        display: 'none',
      },
      { type: 'file', label: 'Profile Photo', name: 'photo', display: 'none' },
      { type: 'text', label: 'Bio', name: 'bio', display: 'none' },
      { type: 'switch', label: 'Approved', name: 'is_approved' },
      { type: 'switch', label: 'Internal coach', name: 'is_internal_coach' },
    ];
  }
};
