export const relative = {
  years: '{years}A',
  year: '1A',
  months: '{months}M',
  month: '1M',
  weeks: '{weeks}S',
  week: '1S',
  days: '{days}D',
  day: '1D',
  hours: '{hours}H',
  hour: '1H',
  minutes: '{minutes}MIN',
  minute: '1MIN',
  seconds: '{seconds}S',
  now: 'Ahora',
  never: 'Nunca',
};
