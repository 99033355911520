// this component represents the container that holds
// the competency page tabs.

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../../../common/components/Icon';
import { CompetencyContext } from '../../CompetencyPage';
import CompetencyLearnTab from './CompetencyLearnTab';
import SupplementalInfo from './SupplementalInfo';
import CompetencyMoreTab from '../CompetencyMoreTab';

export default function MainContainer() {
  const history = useHistory();
  const {
    defaultBackLink,
    selectedCompetency,
    user,
    refetchCompetency,
    roadmapId,
    showRightColumn,
    studentId,
  } = useContext(CompetencyContext);

  return (
    <React.Fragment>
      <div className="title-header">
        <button
          onClick={() =>
            studentId
              ? (window.location.href = defaultBackLink)
              : history.push({
                  pathname: defaultBackLink,
                  state: {
                    stageId: selectedCompetency.stage,
                  },
                })
          }
        >
          <Icon name="chevronLeft" size={13} />
        </button>
        {selectedCompetency && <h1>{selectedCompetency.title}</h1>}
      </div>

      <div className="mrm-mb-1">
        <CompetencyLearnTab
          competency={selectedCompetency}
          refetchCompetency={refetchCompetency}
          roadmapId={roadmapId}
          user={user}
        />
        {selectedCompetency.content && (
          <CompetencyMoreTab
            competency={selectedCompetency}
            roadmapId={roadmapId}
          />
        )}
      </div>

      {showRightColumn && (
        <React.Fragment>
          <SupplementalInfo />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
