import React, { useContext } from 'react';
import { SupportContext } from '../../Dashboard';

export default function StepHeader({
  step = 1,
  title = 'Add Client Info',
  onClick,
  className,
  ...props
}) {
  const { steps } = useContext(SupportContext);

  return (
    <React.Fragment>
      <div className={`support-step-header-container ${className}`}>
        <div className="step-and-title">
          <div className="step-number">
            <p>{step}</p>
          </div>
          <h3>{title}</h3>
          {props.children}
        </div>
        <button className="edit" onClick={onClick} type="button">
          {step === 4 && steps[4].edit !== null
            ? 'Edit'
            : steps[step].edit === true
            ? 'Save'
            : steps[step].edit === false
            ? 'Edit'
            : ''}
        </button>
      </div>
      <hr className={`support-step-divider ${className}`} />
    </React.Fragment>
  );
}
