import React from 'react';

export default function PenIcon({
  className = '',
  onClick = () => {},
  style = {},
  size = 55,
  color = '#6D786E',
}) {
  return (
    <svg
      width={size}
      height={size * 0.9818}
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <path
        d="M51.7066 18.4453C56.0979 14.1211 56.0979 7.3646 51.7066 3.04043C47.5897 -1.01348 40.4537 -1.01348 36.0623 3.04043C34.9644 1.95939 33.3177 1.95939 32.2198 3.04043C31.122 4.12147 31.122 5.74304 32.2198 6.82408L6.42052 32.2286C6.14606 32.4988 5.8716 33.0394 5.8716 33.3096L0.107916 50.336C-0.166544 51.4171 0.107916 52.4981 0.656838 53.0386C1.75468 54.1197 2.85253 54.1197 3.67591 53.8494L20.9669 48.174C21.2414 48.174 21.7903 47.6334 22.0648 47.6334L47.8641 22.2289C48.962 23.31 50.6087 23.31 51.7066 22.2289C52.8044 21.1479 52.8054 19.5263 51.7066 18.4453ZM47.8641 7.09434C50.0598 9.25643 50.0598 12.4996 47.8641 14.6616L40.1792 7.09434C42.1004 4.93226 45.9429 4.93226 47.8641 7.09434ZM7.2439 47.0929L9.98851 39.2554L15.2033 44.3903L7.2439 47.0929ZM20.1436 41.958L12.4587 34.3907L36.3368 10.878L44.0217 18.4453L20.1436 41.958Z"
        fill={color}
      />
    </svg>
  );
}
