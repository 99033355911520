import qs from 'qs'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Banner from '../../common/Banner.js'
import Icon from '../common/components/Icon/index.js'
import Header from '../common/Header.js'
import { prepareErrorMessage } from '../common/uiHelpers'
import usePrivateLabelledSettings from '../common/usePrivateLabelledSettingsHook'
// import PropTypes from 'prop-types';
import { useFetchAuthToken } from './redux/hooks'

function LogInForm({
    isLoading,
    validated,
    handleSubmit,
    errorMessage = undefined,
}) {
    const [formState, setFormState] = useState({})

    const handleFormFieldChange = event => {
        const updatedFormState = Object.assign({}, formState)
        updatedFormState[event.target.id] = event.target.value
        setFormState(updatedFormState)
    }

    return (
        <>
            {isLoading ? (
                <Col className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
            ) : (
                <Col>
                    <div
                        style={{
                            marginTop: '-1rem',
                        }}
                    >
                        <Banner />
                    </div>

                    <Form
                        className="mrm-mb-1_5"
                        validated={validated}
                        onSubmit={event => handleSubmit(event, formState)}
                        noValidate
                    >
                        <Form.Group controlId="formEmail">
                            <Icon
                                name="user"
                                size={18}
                                style={{
                                    position: 'absolute',
                                    marginTop: '12px',
                                    marginLeft: '10px',
                                }}
                            />
                            <Form.Control
                                required
                                type="email"
                                placeholder="Email"
                                value={formState.formEmail || ''}
                                onChange={handleFormFieldChange}
                                isInvalid={errorMessage !== undefined}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPassword">
                            <Icon
                                name="lock"
                                size={20}
                                style={{
                                    position: 'absolute',
                                    marginTop: '12px',
                                    marginLeft: '10px',
                                }}
                            />
                            <Form.Control
                                required
                                type="password"
                                placeholder="Password"
                                value={formState.formPassword || ''}
                                onChange={handleFormFieldChange}
                                isInvalid={errorMessage !== undefined}
                            />
                        </Form.Group>
                        <p>
                            {errorMessage !== null && (
                                <span className="validation">
                                    {errorMessage}
                                </span>
                            )}
                        </p>
                        <Button
                            className="btn-100 mrm-mt-1"
                            variant="primary"
                            type="submit"
                        >
                            Log in
                        </Button>
                    </Form>
                    <p className="legal-info mrm-mt-4">
                        <Link to="/forgot-password" className="text-underline">
                            <u>Forgot password?</u>
                        </Link>
                    </p>
                </Col>
            )}
        </>
    )
}

export default function LogInPage() {
    const { loginCompanyNameField } = usePrivateLabelledSettings()

    const {
        authToken,
        userApproved,
        fetchAuthToken,
        fetchAuthTokenPending,
        fetchAuthTokenError,
        dismissFetchAuthTokenError,
    } = useFetchAuthToken()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            dismissFetchAuthTokenError()
        }, 500)
    }, [dismissFetchAuthTokenError])

    useEffect(() => {
        if (authToken) {
            if (userApproved) {
                const queryParams = qs.parse(location.search.slice(1))
                history.push(queryParams.next || '/dashboard')
            } else {
                history.push({
                    pathname: '/user-not-approved',
                    state: {
                        pathname: history.location.pathname,
                    },
                })
            }
        }
    }, [authToken, userApproved, location, history])

    const handleSubmit = (event, formState) => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        if (form.checkValidity()) {
            const fetchArgs = {
                email: formState.formEmail,
                password: formState.formPassword,
                // company_name: loginCompanyNameField ? loginCompanyNameField : 'Noble',
            }

            fetchAuthToken(fetchArgs).catch(() => {
                console.warn('Error fetching auth token')
            })
        } else {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    const errorMessage = fetchAuthTokenError
        ? prepareErrorMessage(fetchAuthTokenError)
        : undefined

    return (
        <div className="home-log-in-page">
            <Header
                icon="back"
                colSizes={[2, 8, 2]}
                title="Log In"
                defaultBackLink="/welcome"
            />
            <Container className="entry">
                <Row className="justify-content-center">
                    <Col xs={12} className="d-lg-none">
                        <Row className="mt-5">
                            <LogInForm
                                handleSubmit={handleSubmit}
                                isLoading={fetchAuthTokenPending || authToken}
                                errorMessage={
                                    errorMessage !== undefined
                                        ? errorMessage
                                        : undefined
                                }
                            />
                        </Row>
                    </Col>
                    <Col
                        lg={4}
                        className="d-none d-lg-block desktop-form-container"
                    >
                        <h2 className="mrm-my-2 mrm-mb-3 text-center">
                            Log in
                        </h2>
                        <Row className="mt-3">
                            <LogInForm
                                handleSubmit={handleSubmit}
                                isLoading={fetchAuthTokenPending || authToken}
                                errorMessage={
                                    errorMessage !== undefined
                                        ? errorMessage
                                        : undefined
                                }
                            />
                        </Row>
                    </Col>
                    {/*  <Col xs={12}>
            <Toast
              onClose={() => dismissFetchAuthTokenError()}
              show={!!errorMessage}
              delay={3000}
              autohide
              className="mx-auto"
            >
              <Toast.Header>
                <strong className="mr-auto text-danger">Error</strong>
              </Toast.Header>
              <Toast.Body>{errorMessage}</Toast.Body>
            </Toast>
          </Col> */}
                </Row>
            </Container>
        </div>
    )
}

LogInPage.propTypes = {}
LogInPage.defaultProps = {}
