import {
  faChartBar,
  faCopyright,
  faFileContract,
  faLifeRing,
  faSyncAlt,
  faUser,
  faUserCircle,
  faUsers,
  faWindow,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
// import PropTypes from 'prop-types';
import { DropdownButton } from 'react-bootstrap'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers'
import useOutsideAlerter from '../../common/useOutsideAlerter'
import {
  useSetDefaultLanguage,
  useTranslation,
} from '../../features/translations/redux/setDefaultLanguage'
import dashboardIcon from '../../images/icons/dashboard.svg'
import defaultLogo from '../../images/noble-logo.png'
import {
  useCheckStatusStripeAccountLink,
  useFetchAuthToken,
} from '../home/redux/hooks'
import { NotificationsList } from '../notifications'
import {
  useFetchNotifications,
  useMarkAllRead,
} from '../notifications/redux/hooks'
import { useFetchUser } from '../user/redux/hooks'
import Icon from './components/Icon'
import {
  InfoModal,
  Loader,
  SwitchActiveCompanyModal,
  UserAvatar,
} from './index'
import {
  useFetchUpdates,
  useLogout,
  useUpdateUnreadMessages,
} from './redux/hooks'
import usePagination from './usePagination'

const NotificationsMenuItem = React.forwardRef(
  ({ children, onClick, activeSwitch }, ref) => {
    const { updates } = useFetchUpdates()
    return (
      <div
        className="menu-item-container"
        ref={ref}
        onClick={e => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {children}
        {updates && updates.unread_notifications_count > 0 && (
          <Badge pill variant="danger">
            {updates.unread_notifications_count > 9
              ? '9+'
              : updates.unread_notifications_count}
          </Badge>
        )}
      </div>
    )
  }
)

const NotificationsDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const { user } = useFetchUser()
    const {
      notifications,
      fetchNotifications,
      fetchNotificationsPending,
    } = useFetchNotifications()
    const { updates, fetchUpdates } = useFetchUpdates()
    const unauthorizedErrorHandler = useUnauthorizedErrorHandler()
    const { markAllRead } = useMarkAllRead()
    const scrollRef = useRef(null)

    const { resetPage } = usePagination({
      fetchAction: fetchNotifications,
      requestNextPage: () =>
        notifications && notifications.next && !fetchNotificationsPending,
      scrollRef: scrollRef.current,
    })

    const onMarkAllRead = useCallback(
      () =>
        markAllRead()
          .catch(unauthorizedErrorHandler)
          .then(() => {
            resetPage()
            fetchNotifications().catch(unauthorizedErrorHandler)
            fetchUpdates()
          }),
      [
        markAllRead,
        resetPage,
        fetchNotifications,
        unauthorizedErrorHandler,
        fetchUpdates,
      ]
    )

    useEffect(() => {
      if (!user) return
      fetchNotifications().catch(unauthorizedErrorHandler)
    }, [user, fetchNotifications, unauthorizedErrorHandler, updates])

    return (
      <div
        ref={ref}
        style={{
          ...style,
          position: 'absolute',
          top: '44px',
          left: 'auto',
          transform: 'none !important',
        }}
        className={clsx('notifications-dropdown-menu', className)}
        aria-labelledby={labeledBy}
      >
        <div className="notifications-list-wrapper" ref={scrollRef}>
          {!notifications && <Loader />}
          {!!notifications && (
            <>
              <div className="notifications-list-header mrm-mt-0_5">
                {notifications.results.length > 0 &&
                  updates.unread_notifications_count > 0 && (
                    <Button
                      variant="gray"
                      size="sm"
                      className="float-right mark-all-read-btn"
                      onClick={onMarkAllRead}
                    >
                      Mark all read
                    </Button>
                  )}
                <h2 className="notification-title">Notifications</h2>
              </div>
              <NotificationsList
                notifications={notifications}
                showSeparators={true}
              />
            </>
          )}
        </div>
      </div>
    )
  }
)

export default function DesktopHeader({
  children,
  showPrimaryContent = true,
  replacePrimaryContent = false,
}) {
  const { checkStatusStripeAccountLink } = useCheckStatusStripeAccountLink()

  const { authToken } = useFetchAuthToken()
  const { user } = useFetchUser()
  const { logout } = useLogout()

  const history = useHistory()
  const { userApproved } = useFetchAuthToken()

  const handleLogOut = useCallback(() => {
    logout()
    history.push('/log-in')
  }, [logout, history])

  const [hasStripeConnected, setHasStripeConnected] = useState(false)

  useEffect(() => {
    checkStatusStripeAccountLink()
      .then(() => {
        setHasStripeConnected(true)
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          setHasStripeConnected(false)
        }
      })
  }, [checkStatusStripeAccountLink])

  const [
    showSwitchActiveCompanyModal,
    setShowSwitchActiveCompanyModal,
  ] = useState(false)

  const handleSwitchOpen = () => setShowSwitchActiveCompanyModal(true)

  const handleSwitchClose = () => setShowSwitchActiveCompanyModal(false)

  const [showInfoModal, setShowInfoModal] = useState(false)
  const handleInfoModalOpen = () => setShowInfoModal(true)
  const handleInfoModalClose = () => setShowInfoModal(false)

  const { unreadMessagesCount } = useUpdateUnreadMessages()

  function renderHeader() {
    const userCompany = user?.all_companies?.filter(
      c => c.id === user.company_id
    )[0]
    const userCompanyLogo = userCompany?.logo
    const homeLink = () => {
      if (
        user &&
        userApproved &&
        user.groups.includes('Coach') &&
        !user.groups.includes('Admin')
      ) {
        return '/dashboard/coach'
      } else if (user && userApproved && user.groups.includes('Admin')) {
        return '/dashboard/admin'
      } else if (user && userApproved) {
        return 'dashboard/roadmaps'
      } else {
        return ''
      }
    }

    const pathname = window.location.pathname
    const [activeSwitch, setActiveSwitch] = useState(false)

    useEffect(() => {
      console.log('activeSwitch', activeSwitch)
    }, [activeSwitch])

    const {
      setDefaultLanguage,
      defaultLanguage,
      languageNames,
      t,
    } = useTranslation()

    const { setPreferredLanguage } = useSetDefaultLanguage()

    const LanguageSwitcher = () => {
      const handleLanguageChange = useCallback(
        language => {
          setPreferredLanguage(language).then(() => {
            window.location.reload()
          })
        },
        [setDefaultLanguage, setPreferredLanguage, window.location.reload]
      )

      return (
        <DropdownButton
          style={{ border: 'none' }}
          id="dropdown-basic-button"
          title={
            <div className="language-switcher-title">
              <div className="language-switcher-title-text">
                <Icon name={`${defaultLanguage}-flag`} size={16} />
                {languageNames[defaultLanguage]}
              </div>
              <Icon name="chevronRight" style={{ marginRight: 0 }} />
            </div>
          }
          variant="outline-primary"
          className="language-switcher dropdown-item"
          drop="left"
        >
          <Dropdown.Item onClick={() => handleLanguageChange('en-US')}>
            <Icon name="en-US-flag" size={16} />
            <span> English</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLanguageChange('es-NI')}>
            <Icon name="es-NI-flag" size={16} />
            <span> Español</span>
          </Dropdown.Item>
        </DropdownButton>
      )
    }

    const [showProfileDropdown, setShowProfileDropdown] = useState(false)
    const profileDropdownRef = useRef(null)

    useOutsideAlerter(profileDropdownRef, () => {
      setShowProfileDropdown(false)
    })

    return (
      <div className="common-desktop-header">
        {showPrimaryContent && (
          <div className="top-menu-container">
            {replacePrimaryContent && children}
            {!replacePrimaryContent && (
              <Container
                id="desktop-header-container"
                style={
                  {
                    // maxWidth: '1350px',
                  }
                }
              >
                <Row>
                  <Col className="left-menu d-flex align-items-center pl-0">
                    {userCompany?.name === 'Noble' ? (
                      <Link
                        style={{
                          opacity: 1,
                        }}
                        className="noble-company-logo"
                        to={homeLink}
                        tabIndex="false"
                      >
                        <img
                          src={userCompany?.logo || defaultLogo}
                          alt="logo"
                        />
                      </Link>
                    ) : (
                      <Link className="company-logo" to={homeLink}>
                        <img src={userCompanyLogo} alt="logo" />
                      </Link>
                    )}
                  </Col>
                  <Col className="right-menu d-flex align-items-center justify-content-end pr-0">
                    {user &&
                      userApproved &&
                      user.groups &&
                      user.groups.includes('Admin') && (
                        <Dropdown
                          className="menu-item"
                          onToggle={() => {
                            setActiveSwitch(!activeSwitch)
                          }}
                          show={activeSwitch}
                        >
                          <Dropdown.Menu
                            className="admin-dropdown-menu"
                            align="right"
                          >
                            <Dropdown.Item as={Link} to="/dashboard/admin">
                              <img src={dashboardIcon} alt="" />
                              Dashboard
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            {/* <Dropdown.Item as={Link} to="/manage/roadmaps">
                            <FontAwesomeIcon icon={faMap} />
                            Roadmaps
                          </Dropdown.Item> */}
                            <Dropdown.Item as={Link} to="/manage/accounts">
                              <FontAwesomeIcon icon={faUser} />
                              Accounts
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to="/manage/groups">
                              <FontAwesomeIcon icon={faUsers} />
                              Groups
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    {userApproved && (
                      <>
                        {unreadMessagesCount > 0 && (
                          <Link to={'/messages/default'}>
                            <Badge
                              pill
                              variant="danger"
                              className="transparent-badge unread-messages-desktop"
                              style={{
                                position: 'absolute',
                                top: '4px',
                                right: '100px',
                                left: 'auto',
                                zIndex: 1,
                              }}
                            >
                              {unreadMessagesCount > 9
                                ? '9+'
                                : unreadMessagesCount}
                            </Badge>
                          </Link>
                        )}

                        {userApproved && user && user.groups && (
                          <NavLink
                            activeClassName="active"
                            to={'/dashboard/coach'}
                            className="menu-item home-icon"
                          >
                            <Icon
                              name="home"
                              active={
                                (pathname.match('/dashboard') &&
                                pathname !== '/dashboard/clients' &&
                                pathname !== '/dashboard/subscriptions'
                                  ? true
                                  : false) ||
                                pathname.match('/rpm/coach-dashboard')
                              }
                              size={22}
                            />
                          </NavLink>
                        )}

                        <NavLink
                          activeClassName="active"
                          to={'/messages/default'}
                          className="menu-item"
                          style={{
                            marginRight: '-0.5rem',
                          }}
                        >
                          <Icon
                            name="message"
                            active={pathname.match('/messages') ? true : false}
                            size={22}
                          />
                        </NavLink>

                        <Dropdown
                          className="menu-item"
                          show={activeSwitch}
                          onToggle={() => {
                            setActiveSwitch(!activeSwitch)
                          }}
                        >
                          <Dropdown.Toggle as={NotificationsMenuItem}>
                            <Icon
                              name="notification"
                              size={20}
                              active={activeSwitch}
                            />{' '}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            as={NotificationsDropdownMenu}
                            align="right"
                          />
                        </Dropdown>
                      </>
                    )}
                    <div className="profile-header" ref={profileDropdownRef}>
                      <div className="menu-item">
                        {user && (
                          <div
                            className="menu-item-container"
                            onClick={() =>
                              setShowProfileDropdown(!showProfileDropdown)
                            }
                          >
                            <UserAvatar user={user} size="sm" />
                          </div>
                        )}

                        {showProfileDropdown && (
                          <div className="profile-dropdown-menu">
                            <Dropdown.Item as={Link} to="/user/profile">
                              <FontAwesomeIcon icon={faUserCircle} />
                              {t('dropdown.profile')}
                            </Dropdown.Item>
                            {user.user_is_internal_coach &&
                              user.company_is_rtm &&
                              (user.groups.includes('Coach') ||
                                user.groups.includes('Admin')) && (
                                <Dropdown.Item as={Link} to="/dashboard/legacy">
                                  <FontAwesomeIcon icon={faChartBar} />
                                  Legacy Dashboard
                                </Dropdown.Item>
                              )}

                            {user?.all_companies?.length > 1 && (
                              <Dropdown.Item onClick={handleSwitchOpen}>
                                <FontAwesomeIcon icon={faSyncAlt} />
                                {t('dropdown.switch_company')}
                              </Dropdown.Item>
                            )}
                            {user?.groups.includes('Coach') &&
                              hasStripeConnected && (
                                <Dropdown.Item
                                  as={Link}
                                  to="/dashboard/clients"
                                >
                                  <Icon
                                    name="payouts"
                                    size={28}
                                    color="#343434"
                                  />
                                  {t('dropdown.manage_subscriptions')}
                                </Dropdown.Item>
                              )}
                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item
                                as={Link}
                                to="/subscriptions/internal-subscriptions"
                              >
                                <Icon
                                  name="subscriptions"
                                  size={28}
                                  color="#343434"
                                />
                                {t('dropdown.manage_subscriptions')}
                              </Dropdown.Item>
                            )}
                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item
                                as={Link}
                                to="/subscriptions/contracts"
                              >
                                <FontAwesomeIcon icon={faFileContract} />
                                {t('dropdown.contracts')}
                              </Dropdown.Item>
                            )}
                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item as={Link} to="/campaigns">
                                <FontAwesomeIcon icon={faWindow} />
                                {t('dropdown.campaigns')}
                              </Dropdown.Item>
                            )}
                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item as={Link} to="/manage/accounts">
                                <FontAwesomeIcon icon={faUser} />
                                {t('dropdown.accounts')}
                              </Dropdown.Item>
                            )}
                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item as={Link} to="/support/dashboard">
                                <Icon name="headset" color="#343434" />
                                {t('dropdown.onboarding')}
                              </Dropdown.Item>
                            )}
                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item as={Link} to="/manage/roadmaps">
                                <Icon name="roadmap" color="#343434" />
                                {t('dropdown.roadmaps')}
                              </Dropdown.Item>
                            )}

                            {user?.groups.includes('Admin') && (
                              <Dropdown.Item
                                href={`http://analytics.noble.health/?access_token=${authToken}`}
                              >
                                <FontAwesomeIcon
                                  icon={faChartBar}
                                  color="#343434"
                                />
                                {t('dropdown.analytics')}
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              as={Link}
                              to="/notifications/settings"
                            >
                              <Icon name="notification" size={13} />
                              Notifications Settings
                            </Dropdown.Item>

                            <Dropdown.Divider />

                            {user?.company_id === 109 && (
                              <Dropdown.Item
                                href="https://assessments-dev.noble.health/"
                                target="_blank"
                              >
                                <Icon name="assessments" size={20} />
                                {t('dropdown.assessments')}
                              </Dropdown.Item>
                            )}

                            <Dropdown.Item
                              href="https://addorecovery.typeform.com/to/WARdgSSp?typeform-source=admin.typeform.com"
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faLifeRing} />
                              {t('dropdown.help')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleInfoModalOpen}>
                              <FontAwesomeIcon icon={faCopyright} />
                              {t('dropdown.app_information')}
                            </Dropdown.Item>
                            {/** language switcher is a horizontal dropdown */}
                            <Dropdown.Item
                              as={LanguageSwitcher}
                              className="language-switcher"
                            />

                            <Dropdown.Divider />
                            <Dropdown.Item onClick={handleLogOut}>
                              {t('dropdown.logout')}
                            </Dropdown.Item>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        )}
        {!replacePrimaryContent && children}
      </div>
    )
  }

  return (
    <div className="common-desktop-header-container d-none d-lg-block">
      <div className="static-placeholder">{renderHeader()}</div>
      <div className="fixed-actual-header">{renderHeader()}</div>
      <InfoModal show={showInfoModal} onHide={handleInfoModalClose} />
      <SwitchActiveCompanyModal
        show={showSwitchActiveCompanyModal}
        onHide={handleSwitchClose}
      />
    </div>
  )
}

DesktopHeader.propTypes = {}
DesktopHeader.defaultProps = {}
