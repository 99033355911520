import React, { useContext } from 'react';
import { useWindowSize } from '../../../common/windowsSize';
import { InvitationContext } from './InvitationContext';
import Loader from '../../common/Loader';

export default function ModalProfileContainer({ children }) {
  const size = useWindowSize();
  const { step, isLoading } = useContext(InvitationContext);
  return (
    <div
      className="modal-profile-container"
      style={{
        backgroundColor: size.width < 768 ? '#F0ECE8' : 'var(--primary-white)',
        position: size.width < 768 ? 'relative' : '',
        minHeight: size.width < 768 ? '90vh' : '',
        padding: step === 5 && size.width < 768 ? '0 1rem' : '',
      }}
    >
      {children}
      {isLoading && <Loader position="static" />}
    </div>
  );
}
