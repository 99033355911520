import React, { useCallback, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from '../../translations/redux/setDefaultLanguage';
import OnboardingInvitation from './OnboardingInvitation';

export default function InvitationModal({ show, handleClose }) {
  const [saveButtonProps, setSaveButtonProps] = useState({});

  const { t } = useTranslation();

  const renderSaveButton = useCallback(
    () => (
      <button className="invitation-submit" {...saveButtonProps}>
        {t('dashboard.onboarding.fourth_step.send_invitation')}
      </button>
    ),
    [saveButtonProps],
  );

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header>
        <div className="invitation-cancel-header">
          <button onClick={handleClose}>
            <p>{t('dashboard.onboarding.fourth_step.cancel')}</p>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="invitation-modal-body">
          <OnboardingInvitation
            setSaveButtonProps={setSaveButtonProps}
            saveButton={renderSaveButton()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
