import React from 'react';
import { carouselItems } from '../carouselItems';
import Icon from '../../../../common/components/Icon';

export default function Slider() {
  const [currentStep, setCurrentStep] = React.useState(0);

  const handleNext = () => {
    if (currentStep < carouselItems.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(0);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(carouselItems.length - 1);
    }
  };

  return (
    <div className="content">
      <div className="carousel">
        {carouselItems.map((item, index) => (
          <div
            className="carousel-item"
            key={index}
            style={{
              opacity: currentStep === index ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            <div className="photo-wrapper">
              <img src={item.image} alt={item.title} className="photo" />
              <div className="arrow-wrapper">
                <button className="left-arrow" onClick={handlePrev}>
                  <Icon name="chevronLeft" size={10} color="#fff" />
                </button>
                <button className="right-arrow" onClick={handleNext}>
                  <Icon name="chevronLeft" size={10} color="#fff" />
                </button>
              </div>
            </div>
            <div className="carousel-item-content">
              <img src={item.icon} alt={item.title} className="icon" />
              <h1 className="title">{item.title}</h1>
              <p className="description">{item.description}</p>

              <div className="line-container">
                {carouselItems.map((_, idx) => (
                  <div
                    className="line"
                    key={idx}
                    style={{
                      backgroundColor:
                        currentStep === idx ? '#343434' : '#D3D0CC',
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
