import React from 'react';
import OldPeople from '../../assets/growth-sessions/old-people.png';

export default function GrowthSessions() {
  return (
    <div className="upcoming-growth-sessions">
      <h3> Upcoming Growth Sessions</h3>
      <p className="description">
        Take a deep dive into specific topics in an interactive seminar
        environment.
      </p>
      <div className="sessions-container">
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <div className="session" key={index}>
              <div className="session-left">
                <div className="date">
                  <p className="day">23</p>
                  <p className="month">NOV</p>
                </div>

                <img src={OldPeople} alt="old people" />

                <div className="session-info">
                  <p className="session-time">6:00pm MST</p>
                  <p className="session-title">
                    Managing anxiety with breath work
                  </p>
                </div>
              </div>
              <div className="session-right">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_606_1095)">
                    <path
                      d="M11.0849 7.79116C11.2313 7.64475 11.3045 7.45652 11.3045 7.25782C11.3045 7.05913 11.2209 6.86044 11.0849 6.72449C10.9385 6.57809 10.7503 6.50488 10.5516 6.50488H5.15553C4.95684 6.50488 4.75814 6.58854 4.6222 6.72449C4.47579 6.8709 4.40259 7.05913 4.40259 7.25782C4.40259 7.45652 4.48625 7.65521 4.6222 7.79116C4.7686 7.93756 4.95684 8.01077 5.15553 8.01077H10.5516C10.7503 8.01077 10.949 7.9271 11.0849 7.79116Z"
                      fill="#343434"
                    />
                    <path
                      d="M5.15553 9.97656C4.95684 9.97656 4.75814 10.0602 4.6222 10.1962C4.47579 10.3426 4.40259 10.5308 4.40259 10.74C4.40259 10.9491 4.48625 11.1373 4.6222 11.2733C4.7686 11.4197 4.95684 11.4929 5.15553 11.4929H7.85357C8.05226 11.4929 8.25095 11.4092 8.3869 11.2733C8.53331 11.1269 8.60651 10.9387 8.60651 10.74C8.60651 10.5413 8.52285 10.3426 8.3869 10.1962C8.2405 10.0498 8.05226 9.97656 7.85357 9.97656H5.15553Z"
                      fill="#343434"
                    />
                    <path
                      d="M19.1791 12.0048C19.0327 11.8584 18.8445 11.7852 18.6458 11.7852H16.7111V9.8506C16.7111 9.6519 16.6275 9.45321 16.4915 9.31726C16.3451 9.17086 16.1569 9.09766 15.9582 9.09766C15.7595 9.09766 15.5608 9.18132 15.4248 9.31726C15.3726 9.36955 15.3412 9.4323 15.2994 9.49504C15.2366 9.61007 15.1948 9.72511 15.1948 9.8506V11.7852H13.2601C13.0615 11.7852 12.8628 11.8689 12.7268 12.0048C12.5804 12.1513 12.5072 12.3395 12.5072 12.5382C12.5072 12.7369 12.5909 12.9356 12.7268 13.0715C12.8314 13.1761 12.9778 13.2388 13.1242 13.2702C13.1242 13.2702 13.2183 13.3016 13.2601 13.3016H15.1948V15.2362C15.1948 15.4349 15.2784 15.6336 15.4144 15.7696C15.5608 15.916 15.749 15.9892 15.9477 15.9892C16.1464 15.9892 16.3451 15.9055 16.4811 15.7696C16.617 15.6336 16.7007 15.4349 16.7007 15.2362V13.3016H18.6353C18.834 13.3016 19.0327 13.2179 19.1686 13.082C19.315 12.9356 19.3882 12.7473 19.3882 12.5486C19.3882 12.3499 19.3046 12.1513 19.1686 12.0153L19.1791 12.0048Z"
                      fill="#343434"
                    />
                    <path
                      d="M11.0118 14.2957C10.5725 14.4003 10.1229 14.4839 9.65229 14.4839H3.13725C2.7085 14.4839 2.30065 14.3166 1.99739 14.0134C1.69412 13.7101 1.5268 13.3022 1.5268 12.8735V4.04734C1.5268 3.61859 1.69412 3.21074 1.99739 2.90747C2.30065 2.60421 2.7085 2.43689 3.13725 2.43689H4.39216V2.58329C4.39216 2.78198 4.47582 2.98068 4.61176 3.11662C4.75817 3.26303 4.9464 3.33623 5.1451 3.33623C5.34379 3.33623 5.54248 3.25257 5.67843 3.11662C5.82484 2.97022 5.89804 2.78198 5.89804 2.58329V2.43689H10.949V2.58329C10.949 2.78198 11.0327 2.98068 11.1686 3.11662C11.3046 3.25257 11.5033 3.33623 11.702 3.33623C11.9007 3.33623 12.0993 3.25257 12.2353 3.11662C12.3712 2.98068 12.4549 2.78198 12.4549 2.58329V2.43689H13.7098C13.919 2.43689 14.1281 2.47872 14.3268 2.56238C14.5255 2.64604 14.7033 2.76107 14.8497 2.90747C14.9961 3.05388 15.1216 3.23166 15.1948 3.43035C15.2784 3.62904 15.3203 3.83819 15.3203 4.04734V7.05911C15.5085 7.01728 15.7072 6.99636 15.9059 6.99636C16.2301 6.99636 16.5438 7.05911 16.8366 7.16368V4.04734C16.8366 3.2212 16.502 2.42643 15.9163 1.83035C15.3307 1.24473 14.5359 0.910089 13.6993 0.910089H12.4444V0.763683C12.4444 0.564991 12.3608 0.366298 12.2248 0.23035C12.0784 0.0839448 11.8902 0.0107422 11.6915 0.0107422C11.4928 0.0107422 11.2941 0.0944023 11.1582 0.23035C11.0118 0.376755 10.9386 0.564991 10.9386 0.763683V0.910089H5.91895V0.763683C5.91895 0.564991 5.83529 0.366298 5.69935 0.23035C5.55294 0.0839448 5.36471 0.0107422 5.16601 0.0107422C4.96732 0.0107422 4.76863 0.0944023 4.63268 0.23035C4.48627 0.376755 4.41307 0.564991 4.41307 0.763683V0.910089H3.15817C2.33203 0.910089 1.53725 1.24473 0.941176 1.83035C0.334641 2.41597 0 3.21074 0 4.04734V12.8735C0 13.6996 0.334641 14.4944 0.920261 15.0905C1.50588 15.6761 2.30065 16.0107 3.13725 16.0107H9.65229C10.7085 16.0107 11.7229 15.7702 12.6641 15.3415C12.1203 15.2264 11.6288 14.9754 11.2314 14.5676C11.1477 14.4839 11.085 14.3898 11.0118 14.2957Z"
                      fill="#343434"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_606_1095">
                      <rect width="19.3987" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
