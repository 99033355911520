import React from 'react';

export default function ArrowRightIcon({
  size = 13,
  color = '#E8744F',
  style = {},
}) {
  return (
    <svg
      width={size}
      height={size * 1.083}
      style={style}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83426 3.60126L10.3301 6.09714C10.4292 6.18684 10.2596 5.98884 10.3278 6.09477C10.3835 6.1841 10.2868 6.06316 10.313 6.0516C10.306 6.0539 10.3497 6.13585 10.3352 6.13573C10.3206 6.1356 10.329 5.9678 10.3205 6.09256C10.3205 6.09256 10.3459 5.95616 10.3295 6.02562C10.3272 6.03266 10.3247 6.03992 10.325 6.04485C10.314 6.08145 10.3156 6.08146 10.3297 6.04489C10.3345 6.03058 10.3413 6.01865 10.3461 6.00434C10.3366 6.02332 10.3273 6.04251 10.3153 6.06404C10.2485 6.09239 10.3771 6.00696 10.3604 6.00939C10.3365 6.0139 10.3414 6.03321 10.3271 6.04273L7.91782 8.45201C7.6681 8.70173 7.6527 9.16198 7.92588 9.41138C8.19906 9.66078 8.61897 9.68572 8.88525 9.41944L11.1714 7.13332C11.3996 6.90504 11.6037 6.65259 11.6512 6.31487C11.7191 5.83108 11.5527 5.41705 11.2117 5.07608L8.78579 2.65017C8.53184 2.39622 8.07105 2.37329 7.82641 2.64211C7.58157 2.91114 7.56368 3.33069 7.83447 3.60148L7.83426 3.60126Z"
        fill={color}
      />
      <path
        d="M10.9313 5.41029C9.55945 5.39877 8.19234 5.38729 6.82053 5.37577C5.68385 5.36622 4.5467 5.35196 3.40772 5.34946C2.58737 5.34749 1.76961 5.34769 0.94947 5.34552C0.592081 5.34487 0.258881 5.64913 0.276331 6.01865C0.293742 6.38346 0.5795 6.70235 0.960873 6.7032C2.06407 6.70304 3.16722 6.69774 4.2705 6.70701C5.9494 6.72111 7.63301 6.73525 9.31191 6.74935L10.9428 6.76305C11.2979 6.76603 11.6358 6.4571 11.6158 6.09012C11.5962 5.72787 11.3105 5.41369 10.9315 5.41051L10.9313 5.41029Z"
        fill={color}
      />
    </svg>
  );
}
