export const MANAGE_FETCH_USER_ROADMAPS_BEGIN =
  'MANAGE_FETCH_USER_ROADMAPS_BEGIN';
export const MANAGE_FETCH_USER_ROADMAPS_SUCCESS =
  'MANAGE_FETCH_USER_ROADMAPS_SUCCESS';
export const MANAGE_FETCH_USER_ROADMAPS_FAILURE =
  'MANAGE_FETCH_USER_ROADMAPS_FAILURE';
export const MANAGE_FETCH_USER_ROADMAPS_DISMISS_ERROR =
  'MANAGE_FETCH_USER_ROADMAPS_DISMISS_ERROR';
export const MANAGE_ASSIGN_USER_ROADMAP_BEGIN =
  'MANAGE_ASSIGN_USER_ROADMAP_BEGIN';
export const MANAGE_ASSIGN_USER_ROADMAP_SUCCESS =
  'MANAGE_ASSIGN_USER_ROADMAP_SUCCESS';
export const MANAGE_ASSIGN_USER_ROADMAP_FAILURE =
  'MANAGE_ASSIGN_USER_ROADMAP_FAILURE';
export const MANAGE_ASSIGN_USER_ROADMAP_DISMISS_ERROR =
  'MANAGE_ASSIGN_USER_ROADMAP_DISMISS_ERROR';
export const MANAGE_DELETE_USER_ROADMAP_BEGIN =
  'MANAGE_DELETE_USER_ROADMAP_BEGIN';
export const MANAGE_DELETE_USER_ROADMAP_SUCCESS =
  'MANAGE_DELETE_USER_ROADMAP_SUCCESS';
export const MANAGE_DELETE_USER_ROADMAP_FAILURE =
  'MANAGE_DELETE_USER_ROADMAP_FAILURE';
export const MANAGE_DELETE_USER_ROADMAP_DISMISS_ERROR =
  'MANAGE_DELETE_USER_ROADMAP_DISMISS_ERROR';
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_BEGIN =
  'MANAGE_BULK_ASSIGN_USER_ROADMAPS_BEGIN';
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_SUCCESS =
  'MANAGE_BULK_ASSIGN_USER_ROADMAPS_SUCCESS';
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_FAILURE =
  'MANAGE_BULK_ASSIGN_USER_ROADMAPS_FAILURE';
export const MANAGE_BULK_ASSIGN_USER_ROADMAPS_DISMISS_ERROR =
  'MANAGE_BULK_ASSIGN_USER_ROADMAPS_DISMISS_ERROR';
export const MANAGE_FETCH_COHORTS_BEGIN = 'MANAGE_FETCH_COHORTS_BEGIN';
export const MANAGE_FETCH_COHORTS_SUCCESS = 'MANAGE_FETCH_COHORTS_SUCCESS';
export const MANAGE_FETCH_COHORTS_FAILURE = 'MANAGE_FETCH_COHORTS_FAILURE';
export const MANAGE_FETCH_COHORTS_DISMISS_ERROR =
  'MANAGE_FETCH_COHORTS_DISMISS_ERROR';
export const MANAGE_SEND_WELCOME_EMAIL_BEGIN =
  'MANAGE_SEND_WELCOME_EMAIL_BEGIN';
export const MANAGE_SEND_WELCOME_EMAIL_SUCCESS =
  'MANAGE_SEND_WELCOME_EMAIL_SUCCESS';
export const MANAGE_SEND_WELCOME_EMAIL_FAILURE =
  'MANAGE_SEND_WELCOME_EMAIL_FAILURE';
export const MANAGE_SEND_WELCOME_EMAIL_DISMISS_ERROR =
  'MANAGE_SEND_WELCOME_EMAIL_DISMISS_ERROR';
export const MANAGE_DELETE_USER_BEGIN = 'MANAGE_DELETE_USER_BEGIN';
export const MANAGE_DELETE_USER_SUCCESS = 'MANAGE_DELETE_USER_SUCCESS';
export const MANAGE_DELETE_USER_FAILURE = 'MANAGE_DELETE_USER_FAILURE';
export const MANAGE_DELETE_USER_DISMISS_ERROR =
  'MANAGE_DELETE_USER_DISMISS_ERROR';
export const MANAGE_UPDATE_USER_BEGIN = 'MANAGE_UPDATE_USER_BEGIN';
export const MANAGE_UPDATE_USER_SUCCESS = 'MANAGE_UPDATE_USER_SUCCESS';
export const MANAGE_UPDATE_USER_FAILURE = 'MANAGE_UPDATE_USER_FAILURE';
export const MANAGE_UPDATE_USER_DISMISS_ERROR =
  'MANAGE_UPDATE_USER_DISMISS_ERROR';
export const MANAGE_UPDATE_USER_AVATAR_BEGIN =
  'MANAGE_UPDATE_USER_AVATAR_BEGIN';
export const MANAGE_UPDATE_USER_AVATAR_SUCCESS =
  'MANAGE_UPDATE_USER_AVATAR_SUCCESS';
export const MANAGE_UPDATE_USER_AVATAR_FAILURE =
  'MANAGE_UPDATE_USER_AVATAR_FAILURE';
export const MANAGE_UPDATE_USER_AVATAR_DISMISS_ERROR =
  'MANAGE_UPDATE_USER_AVATAR_DISMISS_ERROR';
export const MANAGE_ADD_USER_BEGIN = 'MANAGE_ADD_USER_BEGIN';
export const MANAGE_ADD_USER_SUCCESS = 'MANAGE_ADD_USER_SUCCESS';
export const MANAGE_ADD_USER_FAILURE = 'MANAGE_ADD_USER_FAILURE';
export const MANAGE_ADD_USER_DISMISS_ERROR = 'MANAGE_ADD_USER_DISMISS_ERROR';
export const MANAGE_DELETE_COHORT_BEGIN = 'MANAGE_DELETE_COHORT_BEGIN';
export const MANAGE_DELETE_COHORT_SUCCESS = 'MANAGE_DELETE_COHORT_SUCCESS';
export const MANAGE_DELETE_COHORT_FAILURE = 'MANAGE_DELETE_COHORT_FAILURE';
export const MANAGE_DELETE_COHORT_DISMISS_ERROR =
  'MANAGE_DELETE_COHORT_DISMISS_ERROR';
export const MANAGE_FETCH_COHORT_USERS_BEGIN =
  'MANAGE_FETCH_COHORT_USERS_BEGIN';
export const MANAGE_FETCH_COHORT_USERS_SUCCESS =
  'MANAGE_FETCH_COHORT_USERS_SUCCESS';
export const MANAGE_FETCH_COHORT_USERS_FAILURE =
  'MANAGE_FETCH_COHORT_USERS_FAILURE';
export const MANAGE_FETCH_COHORT_USERS_DISMISS_ERROR =
  'MANAGE_FETCH_COHORT_USERS_DISMISS_ERROR';
export const MANAGE_FETCH_USER_ACCOUNTS_BEGIN =
  'MANAGE_FETCH_USER_ACCOUNTS_BEGIN';
export const MANAGE_FETCH_USER_ACCOUNTS_SUCCESS =
  'MANAGE_FETCH_USER_ACCOUNTS_SUCCESS';
export const MANAGE_FETCH_USER_ACCOUNTS_FAILURE =
  'MANAGE_FETCH_USER_ACCOUNTS_FAILURE';
export const MANAGE_FETCH_USER_ACCOUNTS_DISMISS_ERROR =
  'MANAGE_FETCH_USER_ACCOUNTS_DISMISS_ERROR';
export const MANAGE_ADD_ROADMAP_BEGIN = 'MANAGE_ADD_ROADMAP_BEGIN';
export const MANAGE_ADD_ROADMAP_SUCCESS = 'MANAGE_ADD_ROADMAP_SUCCESS';
export const MANAGE_ADD_ROADMAP_FAILURE = 'MANAGE_ADD_ROADMAP_FAILURE';
export const MANAGE_ADD_ROADMAP_DISMISS_ERROR =
  'MANAGE_ADD_ROADMAP_DISMISS_ERROR';
export const MANAGE_ADD_COHORT_BEGIN = 'MANAGE_ADD_COHORT_BEGIN';
export const MANAGE_ADD_COHORT_SUCCESS = 'MANAGE_ADD_COHORT_SUCCESS';
export const MANAGE_ADD_COHORT_FAILURE = 'MANAGE_ADD_COHORT_FAILURE';
export const MANAGE_ADD_COHORT_DISMISS_ERROR =
  'MANAGE_ADD_COHORT_DISMISS_ERROR';
export const MANAGE_UPDATE_COHORT_BEGIN = 'MANAGE_UPDATE_COHORT_BEGIN';
export const MANAGE_UPDATE_COHORT_SUCCESS = 'MANAGE_UPDATE_COHORT_SUCCESS';
export const MANAGE_UPDATE_COHORT_FAILURE = 'MANAGE_UPDATE_COHORT_FAILURE';
export const MANAGE_UPDATE_COHORT_DISMISS_ERROR =
  'MANAGE_UPDATE_COHORT_DISMISS_ERROR';
export const MANAGE_HIDE_COMPETENCY_BEGIN = 'MANAGE_HIDE_COMPETENCY_BEGIN';
export const MANAGE_HIDE_COMPETENCY_SUCCESS = 'MANAGE_HIDE_COMPETENCY_SUCCESS';
export const MANAGE_HIDE_COMPETENCY_FAILURE = 'MANAGE_HIDE_COMPETENCY_FAILURE';
export const MANAGE_HIDE_COMPETENCY_DISMISS_ERROR =
  'MANAGE_HIDE_COMPETENCY_DISMISS_ERROR';
export const MANAGE_ADD_COMPETENCY_BEGIN = 'MANAGE_ADD_COMPETENCY_BEGIN';
export const MANAGE_ADD_COMPETENCY_SUCCESS = 'MANAGE_ADD_COMPETENCY_SUCCESS';
export const MANAGE_ADD_COMPETENCY_FAILURE = 'MANAGE_ADD_COMPETENCY_FAILURE';
export const MANAGE_ADD_COMPETENCY_DISMISS_ERROR =
  'MANAGE_ADD_COMPETENCY_DISMISS_ERROR';
export const MANAGE_ADD_STAGE_BEGIN = 'MANAGE_ADD_STAGE_BEGIN';
export const MANAGE_ADD_STAGE_SUCCESS = 'MANAGE_ADD_STAGE_SUCCESS';
export const MANAGE_ADD_STAGE_FAILURE = 'MANAGE_ADD_STAGE_FAILURE';
export const MANAGE_ADD_STAGE_DISMISS_ERROR = 'MANAGE_ADD_STAGE_DISMISS_ERROR';
export const MANAGE_UPDATE_STAGE_BEGIN = 'MANAGE_UPDATE_STAGE_BEGIN';
export const MANAGE_UPDATE_STAGE_SUCCESS = 'MANAGE_UPDATE_STAGE_SUCCESS';
export const MANAGE_UPDATE_STAGE_FAILURE = 'MANAGE_UPDATE_STAGE_FAILURE';
export const MANAGE_UPDATE_STAGE_DISMISS_ERROR =
  'MANAGE_UPDATE_STAGE_DISMISS_ERROR';
export const MANAGE_DELETE_ROADMAP_BEGIN = 'MANAGE_DELETE_ROADMAP_BEGIN';
export const MANAGE_DELETE_ROADMAP_SUCCESS = 'MANAGE_DELETE_ROADMAP_SUCCESS';
export const MANAGE_DELETE_ROADMAP_FAILURE = 'MANAGE_DELETE_ROADMAP_FAILURE';
export const MANAGE_DELETE_ROADMAP_DISMISS_ERROR =
  'MANAGE_DELETE_ROADMAP_DISMISS_ERROR';
export const MANAGE_UPDATE_ROADMAP_BEGIN = 'MANAGE_UPDATE_ROADMAP_BEGIN';
export const MANAGE_UPDATE_ROADMAP_SUCCESS = 'MANAGE_UPDATE_ROADMAP_SUCCESS';
export const MANAGE_UPDATE_ROADMAP_FAILURE = 'MANAGE_UPDATE_ROADMAP_FAILURE';
export const MANAGE_UPDATE_ROADMAP_DISMISS_ERROR =
  'MANAGE_UPDATE_ROADMAP_DISMISS_ERROR';
export const MANAGE_DELETE_STAGE_BEGIN = 'MANAGE_DELETE_STAGE_BEGIN';
export const MANAGE_DELETE_STAGE_SUCCESS = 'MANAGE_DELETE_STAGE_SUCCESS';
export const MANAGE_DELETE_STAGE_FAILURE = 'MANAGE_DELETE_STAGE_FAILURE';
export const MANAGE_DELETE_STAGE_DISMISS_ERROR =
  'MANAGE_DELETE_STAGE_DISMISS_ERROR';
export const MANAGE_COPY_ROADMAP_BEGIN = 'MANAGE_COPY_ROADMAP_BEGIN';
export const MANAGE_COPY_ROADMAP_SUCCESS = 'MANAGE_COPY_ROADMAP_SUCCESS';
export const MANAGE_COPY_ROADMAP_FAILURE = 'MANAGE_COPY_ROADMAP_FAILURE';
export const MANAGE_COPY_ROADMAP_DISMISS_ERROR =
  'MANAGE_COPY_ROADMAP_DISMISS_ERROR';
export const MANAGE_COPY_STAGE_BEGIN = 'MANAGE_COPY_STAGE_BEGIN';
export const MANAGE_COPY_STAGE_SUCCESS = 'MANAGE_COPY_STAGE_SUCCESS';
export const MANAGE_COPY_STAGE_FAILURE = 'MANAGE_COPY_STAGE_FAILURE';
export const MANAGE_COPY_STAGE_DISMISS_ERROR =
  'MANAGE_COPY_STAGE_DISMISS_ERROR';
export const MANAGE_CLEAR_ROADMAP_ASSESSMENT_BEGIN =
  'MANAGE_CLEAR_ROADMAP_ASSESSMENT_BEGIN';
export const MANAGE_CLEAR_ROADMAP_ASSESSMENT_SUCCESS =
  'MANAGE_CLEAR_ROADMAP_ASSESSMENT_SUCCESS';
export const MANAGE_CLEAR_ROADMAP_ASSESSMENT_FAILURE =
  'MANAGE_CLEAR_ROADMAP_ASSESSMENT_FAILURE';
export const MANAGE_CLEAR_ROADMAP_ASSESSMENT_DISMISS_ERROR =
  'MANAGE_CLEAR_ROADMAP_ASSESSMENT_DISMISS_ERROR';
export const MANAGE_REORDER_STAGE_BEGIN = 'MANAGE_REORDER_STAGE_BEGIN';
export const MANAGE_REORDER_STAGE_SUCCESS = 'MANAGE_REORDER_STAGE_SUCCESS';
export const MANAGE_REORDER_STAGE_FAILURE = 'MANAGE_REORDER_STAGE_FAILURE';
export const MANAGE_REORDER_STAGE_DISMISS_ERROR =
  'MANAGE_REORDER_STAGE_DISMISS_ERROR';
export const MANAGE_REORDER_COMPETENCY_BEGIN =
  'MANAGE_REORDER_COMPETENCY_BEGIN';
export const MANAGE_REORDER_COMPETENCY_SUCCESS =
  'MANAGE_REORDER_COMPETENCY_SUCCESS';
export const MANAGE_REORDER_COMPETENCY_FAILURE =
  'MANAGE_REORDER_COMPETENCY_FAILURE';
export const MANAGE_REORDER_COMPETENCY_DISMISS_ERROR =
  'MANAGE_REORDER_COMPETENCY_DISMISS_ERROR';
export const MANAGE_BULK_ASSIGN_COHORTS_BEGIN =
  'MANAGE_BULK_ASSIGN_COHORTS_BEGIN';
export const MANAGE_BULK_ASSIGN_COHORTS_SUCCESS =
  'MANAGE_BULK_ASSIGN_COHORTS_SUCCESS';
export const MANAGE_BULK_ASSIGN_COHORTS_FAILURE =
  'MANAGE_BULK_ASSIGN_COHORTS_FAILURE';
export const MANAGE_BULK_ASSIGN_COHORTS_DISMISS_ERROR =
  'MANAGE_BULK_ASSIGN_COHORTS_DISMISS_ERROR';
export const MANAGE_UPDATE_COMPETENCY_BEGIN = 'MANAGE_UPDATE_COMPETENCY_BEGIN';
export const MANAGE_UPDATE_COMPETENCY_SUCCESS =
  'MANAGE_UPDATE_COMPETENCY_SUCCESS';
export const MANAGE_UPDATE_COMPETENCY_FAILURE =
  'MANAGE_UPDATE_COMPETENCY_FAILURE';
export const MANAGE_UPDATE_COMPETENCY_DISMISS_ERROR =
  'MANAGE_UPDATE_COMPETENCY_DISMISS_ERROR';
export const MANAGE_DELETE_COMPETENCY_BEGIN = 'MANAGE_DELETE_COMPETENCY_BEGIN';
export const MANAGE_DELETE_COMPETENCY_SUCCESS =
  'MANAGE_DELETE_COMPETENCY_SUCCESS';
export const MANAGE_DELETE_COMPETENCY_FAILURE =
  'MANAGE_DELETE_COMPETENCY_FAILURE';
export const MANAGE_DELETE_COMPETENCY_DISMISS_ERROR =
  'MANAGE_DELETE_COMPETENCY_DISMISS_ERROR';
export const MANAGE_COPY_COMPETENCY_BEGIN = 'MANAGE_COPY_COMPETENCY_BEGIN';
export const MANAGE_COPY_COMPETENCY_SUCCESS = 'MANAGE_COPY_COMPETENCY_SUCCESS';
export const MANAGE_COPY_COMPETENCY_FAILURE = 'MANAGE_COPY_COMPETENCY_FAILURE';
export const MANAGE_COPY_COMPETENCY_DISMISS_ERROR =
  'MANAGE_COPY_COMPETENCY_DISMISS_ERROR';
export const MANAGE_ADD_GLOBAL_ACTION_ITEM_BEGIN =
  'MANAGE_ADD_GLOBAL_ACTION_ITEM_BEGIN';
export const MANAGE_ADD_GLOBAL_ACTION_ITEM_SUCCESS =
  'MANAGE_ADD_GLOBAL_ACTION_ITEM_SUCCESS';
export const MANAGE_ADD_GLOBAL_ACTION_ITEM_FAILURE =
  'MANAGE_ADD_GLOBAL_ACTION_ITEM_FAILURE';
export const MANAGE_ADD_GLOBAL_ACTION_ITEM_DISMISS_ERROR =
  'MANAGE_ADD_GLOBAL_ACTION_ITEM_DISMISS_ERROR';
export const MANAGE_UPDATE_GLOBAL_ACTION_ITEM_BEGIN =
  'MANAGE_UPDATE_GLOBAL_ACTION_ITEM_BEGIN';
export const MANAGE_UPDATE_GLOBAL_ACTION_ITEM_SUCCESS =
  'MANAGE_UPDATE_GLOBAL_ACTION_ITEM_SUCCESS';
export const MANAGE_UPDATE_GLOBAL_ACTION_ITEM_FAILURE =
  'MANAGE_UPDATE_GLOBAL_ACTION_ITEM_FAILURE';
export const MANAGE_UPDATE_GLOBAL_ACTION_ITEM_DISMISS_ERROR =
  'MANAGE_UPDATE_GLOBAL_ACTION_ITEM_DISMISS_ERROR';
export const MANAGE_DELETE_GLOBAL_ACTION_ITEM_BEGIN =
  'MANAGE_DELETE_GLOBAL_ACTION_ITEM_BEGIN';
export const MANAGE_DELETE_GLOBAL_ACTION_ITEM_SUCCESS =
  'MANAGE_DELETE_GLOBAL_ACTION_ITEM_SUCCESS';
export const MANAGE_DELETE_GLOBAL_ACTION_ITEM_FAILURE =
  'MANAGE_DELETE_GLOBAL_ACTION_ITEM_FAILURE';
export const MANAGE_DELETE_GLOBAL_ACTION_ITEM_DISMISS_ERROR =
  'MANAGE_DELETE_GLOBAL_ACTION_ITEM_DISMISS_ERROR';
export const MANAGE_FETCH_GLOBAL_QUESTIONS_BEGIN =
  'MANAGE_FETCH_GLOBAL_QUESTIONS_BEGIN';
export const MANAGE_FETCH_GLOBAL_QUESTIONS_SUCCESS =
  'MANAGE_FETCH_GLOBAL_QUESTIONS_SUCCESS';
export const MANAGE_FETCH_GLOBAL_QUESTIONS_FAILURE =
  'MANAGE_FETCH_GLOBAL_QUESTIONS_FAILURE';
export const MANAGE_FETCH_GLOBAL_QUESTIONS_DISMISS_ERROR =
  'MANAGE_FETCH_GLOBAL_QUESTIONS_DISMISS_ERROR';
export const MANAGE_ADD_GLOBAL_QUESTION_BEGIN =
  'MANAGE_ADD_GLOBAL_QUESTION_BEGIN';
export const MANAGE_ADD_GLOBAL_QUESTION_SUCCESS =
  'MANAGE_ADD_GLOBAL_QUESTION_SUCCESS';
export const MANAGE_ADD_GLOBAL_QUESTION_FAILURE =
  'MANAGE_ADD_GLOBAL_QUESTION_FAILURE';
export const MANAGE_ADD_GLOBAL_QUESTION_DISMISS_ERROR =
  'MANAGE_ADD_GLOBAL_QUESTION_DISMISS_ERROR';
export const MANAGE_UPDATE_GLOBAL_QUESTION_BEGIN =
  'MANAGE_UPDATE_GLOBAL_QUESTION_BEGIN';
export const MANAGE_UPDATE_GLOBAL_QUESTION_SUCCESS =
  'MANAGE_UPDATE_GLOBAL_QUESTION_SUCCESS';
export const MANAGE_UPDATE_GLOBAL_QUESTION_FAILURE =
  'MANAGE_UPDATE_GLOBAL_QUESTION_FAILURE';
export const MANAGE_UPDATE_GLOBAL_QUESTION_DISMISS_ERROR =
  'MANAGE_UPDATE_GLOBAL_QUESTION_DISMISS_ERROR';
export const MANAGE_DELETE_GLOBAL_QUESTION_BEGIN =
  'MANAGE_DELETE_GLOBAL_QUESTION_BEGIN';
export const MANAGE_DELETE_GLOBAL_QUESTION_SUCCESS =
  'MANAGE_DELETE_GLOBAL_QUESTION_SUCCESS';
export const MANAGE_DELETE_GLOBAL_QUESTION_FAILURE =
  'MANAGE_DELETE_GLOBAL_QUESTION_FAILURE';
export const MANAGE_DELETE_GLOBAL_QUESTION_DISMISS_ERROR =
  'MANAGE_DELETE_GLOBAL_QUESTION_DISMISS_ERROR';
export const MANAGE_REORDER_ACTION_ITEMS_BEGIN =
  'MANAGE_REORDER_ACTION_ITEMS_BEGIN';
export const MANAGE_REORDER_ACTION_ITEMS_SUCCESS =
  'MANAGE_REORDER_ACTION_ITEMS_SUCCESS';
export const MANAGE_REORDER_ACTION_ITEMS_FAILURE =
  'MANAGE_REORDER_ACTION_ITEMS_FAILURE';
export const MANAGE_REORDER_ACTION_ITEMS_DISMISS_ERROR =
  'MANAGE_REORDER_ACTION_ITEMS_DISMISS_ERROR';
export const MANAGE_REORDER_GLOBAL_QUESTIONS_BEGIN =
  'MANAGE_REORDER_GLOBAL_QUESTIONS_BEGIN';
export const MANAGE_REORDER_GLOBAL_QUESTIONS_SUCCESS =
  'MANAGE_REORDER_GLOBAL_QUESTIONS_SUCCESS';
export const MANAGE_REORDER_GLOBAL_QUESTIONS_FAILURE =
  'MANAGE_REORDER_GLOBAL_QUESTIONS_FAILURE';
export const MANAGE_REORDER_GLOBAL_QUESTIONS_DISMISS_ERROR =
  'MANAGE_REORDER_GLOBAL_QUESTIONS_DISMISS_ERROR';
export const EXPORT_ROADMAP_ASSESSMENT_DATA_BEGIN =
  'EXPORT_ROADMAP_ASSESSMENT_DATA_BEGIN';
export const EXPORT_ROADMAP_ASSESSMENT_DATA_SUCCESS =
  'EXPORT_ROADMAP_ASSESSMENT_DATA_SUCCESS';
export const EXPORT_ROADMAP_ASSESSMENT_DATA_FAILURE =
  'EXPORT_ROADMAP_ASSESSMENT_DATA_FAILURE';
export const EXPORT_ROADMAP_ASSESSMENT_DATA_DISMISS_ERROR =
  'EXPORT_ROADMAP_ASSESSMENT_DATA_DISMISS_ERROR';
