import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Btn from '../common/components/Btn';

import usePrivateLabelledSettings from '../common/usePrivateLabelledSettingsHook';

import { useFetchAuthToken } from './redux/hooks';

export default function WelcomePage() {
  const { authToken } = useFetchAuthToken();
  const history = useHistory();
  const privateLabelledSettings = usePrivateLabelledSettings();

  useEffect(() => {
    if (authToken) history.push('/dashboard');
  }, [authToken, history]);

  if (authToken) {
    return null;
  }

  return (
    <div className="welcome entry welcome-page">
      <Row className="justify-content-center h-100">
        <Col className="text-center d-flex flex-column justify-content-around align-items-center h-100">
          <img
            className="welcome-page-logo mb-3"
            src={privateLabelledSettings.logoUrl}
            alt={privateLabelledSettings.welcomePageCompanyName}
          />
          <div
            className="welcome-page-msg mrm-mb-1_5 font-weight-light"
            dangerouslySetInnerHTML={{
              __html: privateLabelledSettings.appWelcomeMessage,
            }}
          />
          <div className="w-100">
            <Link to="/log-in">
              <Btn className="mb-3 py-0 login" size="lg">
                Log In
              </Btn>
            </Link>
            {privateLabelledSettings.signupButtonVisible ? (
              <Link to="/signup">
                <Btn variant="secondary" size="lg">
                  Sign Up
                </Btn>
              </Link>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}
