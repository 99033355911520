import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogout } from '../../../common/redux/logout'
import nobleLogo from '../../../home/images/logo.png'
import { useFetchAuthToken } from '../../../home/redux/fetchAuthToken'
import { useFetchUser } from '../../../user/redux/fetchUser'
import appStoreQr from '../../assets/download-app/app-store-qr.svg'
import getItOnAppStore from '../../assets/download-app/get-it-on-app-store.svg'
import getItOnGooglePlay from '../../assets/download-app/get-it-on-google-play.svg'
import googlePlayQr from '../../assets/download-app/google-play-qr.svg'
import wiresImage from '../../assets/download-app/wires.png'

export default function DesktopDownloadApp() {
    const { logout } = useLogout()
    const history = useHistory()

    const handleLogOut = useCallback(() => {
        logout()
        history.push('/log-in')
    }, [logout, history])

    const { authToken } = useFetchAuthToken()

    const { user } = useFetchUser()

    if (authToken && !user) return null

    return (
        <div className="rpm-download-app-desktop">
            <div className="rpm-download-app-desktop__content">
                {authToken && (
                    <button
                        type="button"
                        onClick={handleLogOut}
                        className="rpm-download-app-desktop__content__logout"
                    >
                        Log out
                    </button>
                )}

                <img src={nobleLogo} alt="Noble Logo" id="logo" />

                <div className="flex flex-col gap-1">
                    {authToken ? (
                        <h1>
                            {user.company_name} proudly partners with Noble
                            to provide Coaching.
                        </h1>
                    ) : (
                        <h1>This feature is only available on the app.</h1>
                    )}
                    <p className="rpm-download-app-desktop__content__description">
                        Scan the code with your phones camera to download the
                        app.
                    </p>
                </div>
                <div className="rpm-download-app-desktop__content__qrs">
                    <div className="rpm-download-app-desktop__content__qrs__qr-container">
                        <p className="rpm-download-app-desktop__content__qrs__qr-container__store">
                            App store
                        </p>
                        <img
                            src={appStoreQr}
                            alt="App store QR"
                            className="rpm-download-app-desktop__content__qrs__qr-container__qr"
                        />
                        <a
                            href="https://apps.apple.com/ni/app/noble/id1606431994?l=en"
                            target="_blank"
                            rel="noopener noreferrer"
                            role="button"
                        >
                            <img
                                src={getItOnAppStore}
                                alt="Get it on App store"
                                className="rpm-download-app-desktop__content__qrs__qr-container__button"
                                onClick={() => {}}
                                role="button"
                            />
                        </a>
                    </div>
                    <div className="rpm-download-app-desktop__content__qrs__qr-container">
                        <p className="rpm-download-app-desktop__content__qrs__qr-container__store">
                            Google play
                        </p>
                        <img
                            src={googlePlayQr}
                            alt="Google play QR"
                            className="rpm-download-app-desktop__content__qrs__qr-container__qr"
                        />
                        <a
                            href="https://vqr.vc/WB9OG6bzW"
                            target="_blank"
                            rel="noopener noreferrer"
                            role="button"
                        >
                            <img
                                src={getItOnGooglePlay}
                                role="button"
                                alt="Get it on Google play"
                                className="rpm-download-app-desktop__content__qrs__qr-container__button"
                                onClick={() => {}}
                            />
                        </a>
                    </div>
                </div>
            </div>
            <img
                src={wiresImage}
                alt="Wires"
                className="rpm-download-app-desktop__wires"
            />
        </div>
    )
}
