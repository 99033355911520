import { Formik } from 'formik'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Icon from '../../../../common/components/Icon'
import rpmLogo from '../../../assets/rpm.svg'
import useConfirmPassword from '../hooks/useConfirmPassword'
import { passwordValidationSchema } from '../validationSchemas'

export default function ConfirmPassword() {
    const location = useLocation()

    const { p } = qs.parse(location.search.replace('?', ''))
    let params = {}
    try {
        params = JSON.parse(atob(p))
    } catch {}

    let name = params.user?.first_name?.toLowerCase()
    name = name && name.charAt(0).toUpperCase() + name.slice(1)

    const email = params.email

    const { handleSubmit } = useConfirmPassword({
        email,
        isCoach: params.user.groups.includes('Coach'),
    })

    return (
        <div className="sidebar-content">
            <img src={params.company?.logo} alt="Company Logo" id="rpm-logo" />
            <h1>Welcome {name}</h1>
            <p className="description">
                {!params.user.groups.includes('Coach')
                    ? `One click away from access to the best anxiety monitoring system
                technology has to offer.`
                    : `We're happy to partner with you in providing enhanced mental healthcare for your patients.`}
            </p>
            <p className="label">Setup Account</p>

            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                    termsAndConditions: false,
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    setSubmitting(false)
                    handleSubmit(values, setFieldError)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit} className="form">
                        <div className="input-wrapper">
                            <input
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Password"
                            />
                            <Icon
                                name="lock"
                                size={25}
                                color="var(--primary-dark-green)"
                                className="lock"
                            />
                        </div>

                        {errors.password && touched.password && (
                            <p className="error-message">{errors.password}</p>
                        )}

                        <div className="input-wrapper">
                            <input
                                type="password"
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                placeholder="Confirm Password"
                            />
                            <Icon
                                name="lock"
                                size={25}
                                color="var(--primary-dark-green)"
                                className="lock"
                            />
                        </div>

                        {errors.confirmPassword && touched.confirmPassword && (
                            <p className="error-message">
                                {errors.confirmPassword}
                            </p>
                        )}

                        <ul className="password-requirements">
                            <p className="title">Your password must contain</p>
                            <li className="password-requirements__item">
                                <span className="password-requirements__item__icon">
                                    +
                                </span>
                                <p className="password-requirements__item__text">
                                    8 or more characters
                                </p>
                            </li>

                            <li className="password-requirements__item">
                                <span className="password-requirements__item__icon">
                                    +
                                </span>
                                <p className="password-requirements__item__text">
                                    uppercase letters
                                </p>
                            </li>
                            <li className="password-requirements__item">
                                <span className="password-requirements__item__icon">
                                    +
                                </span>
                                <p className="password-requirements__item__text">
                                   1 or more numbers/symbols
                                </p>
                            </li>
                            <li className="password-requirements__item">
                                <span className="password-requirements__item__icon">
                                    +
                                </span>
                                <p className="password-requirements__item__text">
                                    lowercase letters
                                </p>
                            </li>
                        </ul>

                        <div className="terms-and-conditions">
                            <div
                                role="button"
                                className="accept-terms"
                                onClick={() =>
                                    setFieldValue(
                                        'termsAndConditions',
                                        !values.termsAndConditions
                                    )
                                }
                            >
                                <Icon
                                    name="minimalist-check"
                                    active={values.termsAndConditions}
                                />
                            </div>

                            <div className="terms-and-conditions__text-wrapper">
                                <p
                                    className="terms-and-conditions__text-wrapper__text"
                                    onClick={() =>
                                        setFieldValue(
                                            'termsAndConditions',
                                            !values.termsAndConditions
                                        )
                                    }
                                    role="button"
                                >
                                    I have read and agree to the following:
                                </p>
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.noble.health/privacy-policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>{' '}
                                        {'&'}{' '}
                                        <a
                                            href="https://noble.health/terms-of-service/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms of Service
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {errors.termsAndConditions &&
                            touched.termsAndConditions && (
                                <p className="error-message">
                                    {errors.termsAndConditions}
                                </p>
                            )}

                        <button type="submit" disabled={isSubmitting}>
                            {params.user.groups.includes('Coach')
                                ? 'Create Account'
                                : 'Next'}
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    )
}
