import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { DesktopHeader, Loader } from '../common'
import useWindowSize from '../common/useWindowResize'
import { useEditGroupChat } from '../messages/redux/editGroupChat'
import { useFetchUser } from '../user/redux/fetchUser'
import Conversation from './components/conversations/Conversation'
import Header from './components/conversations/Header'
import SearchBar from './components/conversations/SearchBar'
import Tabs from './components/conversations/Tabs'
import MessageHeader from './components/messages/MessageHeader'
import Messages from './components/messages/Messages'
import Sender from './components/messages/Sender'
import Conversations from './hooks/Conversations'
import useEditGroup from './hooks/useEditGroup'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useUpdateUnreadMessages } from '../common/redux/updateUnreadMessages'
import queryString from 'query-string'
import { useHasActiveSubcription } from '../../common/permissions'

import { useCreateUpdateGroupLastReadMessageTimestamp } from './redux/createUpdateGroupLastReadMessageTimestamp'
import { useCreateUpdatePeerLastReadMessageTimestamp } from './redux/createUpdatePeerLastReadMessageTimestamp'
import AudioPlayingProvider from './components/contexts/AudioPlayingProvider'
import Inactive from './components/inactive/Inactive'
export default function ChatPage() {
    const { user } = useFetchUser()
    const { isActive, isLoading } = useHasActiveSubcription()
    const { editGroupChat } = useEditGroupChat()

    const { updateUnreadMessages } = useUpdateUnreadMessages()

    const { chatId } = useParams()
    // query params
    const location = useLocation()

    // there is a json object in the query string called "conversation"
    const { conversation: conversationParam } = queryString.parse(
        location.search
    )

    const { width } = useWindowSize()

    let isDesktop = width > 992
    let isTablet = width < 992

    const [selectedTab, setSelectedTab] = React.useState('all') // all, messages, group

    const [conversationsList, setConversationsList] = React.useState([]) // all conversations
    const [selectedConversation, setSelectedConversation] = React.useState(null)

    const [createNewConversation, setCreateNewConversation] = React.useState(
        false
    )

    const [searchText, setSearchText] = React.useState('')

    const [selectedMessages, setSelectedMessages] = React.useState([])

    const [editingGroup, setEditingGroup] = React.useState(false)

    const [editedName, setEditedName] = useState(null)

    const editGroup = useEditGroup({
        editGroupChat: editGroupChat,
        selectedConversation: selectedConversation,
        setSelectedConversation: setSelectedConversation,
        groupName: editedName || selectedConversation?.name,
        user: user,
    })

    const {
        createUpdateGroupLastReadMessageTimestamp,
    } = useCreateUpdateGroupLastReadMessageTimestamp()
    const {
        createUpdatePeerLastReadMessageTimestamp,
    } = useCreateUpdatePeerLastReadMessageTimestamp()

    useEffect(() => {
        // THIS OPENS A CONVERSATION IF A COACH CLICKED ON "MESSAGE" FROM ANOTHER PAGE to message a client
        if (location.state && location.state.conversation) {
            const conversation = conversationsList.find(
                conversation =>
                    conversation.user.id == location.state.conversation.id
            )

            if (conversation) {
                setSelectedConversation(conversation)
                window.history.replaceState(
                    null,
                    window.title,
                    '/messages/default/' + conversation.id
                )
            } else {
                setSelectedConversation({
                    id: location.state.conversation.id,
                    timestamp: location.state.conversation.timestamp,
                    type: location.state.conversation.type,
                    user: location.state.conversation.user,
                })
            }
        }
    }, [location])

    // do the same thing as above but with query params

    useEffect(() => {
        if (conversationParam && !selectedConversation) {
            console.log('conversationParam', conversationParam)
            const conversationParsed = JSON.parse(
                decodeURIComponent(conversationParam)
            )
            const conversation = conversationsList.find(
                conversation => conversation.user?.id == conversationParsed.id
            )

            if (conversation) {
                setSelectedConversation(conversation)
                window.history.replaceState(
                    null,
                    window.title,
                    '/messages/default/' + conversation.id
                )
            } else {
                setSelectedConversation({
                    id: conversationParsed.id,
                    timestamp: conversationParsed.timestamp,
                    type: conversationParsed.type,
                    user: conversationParsed.user,
                })
            }
        }
    }, [conversationParam, conversationsList])

    useEffect(() => {
        // OPEN CONVERSATION IF ID IN URL
        if (chatId && conversationsList.length > 0 && !selectedConversation) {
            const conversation = conversationsList.find(
                conversation => conversation.id == chatId
            )

            if (conversation) {
                setSelectedConversation(conversation)
                if (conversation.type === 'group') {
                    setSelectedTab('Group')
                }
            } else {
                setSelectedConversation(null)
            }
        }
    }, [chatId, conversationsList])

    useLayoutEffect(() => {
        if (!selectedConversation && isTablet) {
            const element = document.querySelector('.common-bottom-nav')
            if (element) {
                element.style.position = 'absolute'
                element.style['z-index'] = '99999999'
                element.style.display = 'block'
            }
        } else {
            const element = document.querySelector('.common-bottom-nav')
            if (element) {
                element.style.display = 'none'
            }
        }
    }, [selectedConversation, isTablet])

    const readMessage = async conversation => {
        console.log('readMessage', conversation)

        if (conversation.type === 'group') {
            createUpdateGroupLastReadMessageTimestamp({
                group_chat: conversation.id,
                timestamp: conversation?.timestamp,
            }).then(() => {
                const newConversationsList = conversationsList.map(conv => {
                    if (conv.id === conversation.id) {
                        conv.read = true
                    }
                    return conv
                })

                updateUnreadMessages(unreadMessages => {
                    return unreadMessages - 1
                })

                setConversationsList(newConversationsList)
            })
        } else {
            console.log('peerconversationsclick', conversation)
            createUpdatePeerLastReadMessageTimestamp({
                peer_id: conversation.user.username,
                timestamp: conversation?.timestamp,
            }).then(() => {
                // mark read true for conversation
                const newConversationsList = conversationsList.map(conv => {
                    if (conv.id === conversation.id) {
                        conv.read = true
                    }
                    return conv
                })
                updateUnreadMessages(unreadMessages => {
                    return unreadMessages - 1
                })
                setConversationsList(newConversationsList)
            })
        }
    }

    useEffect(() => {
        if (
            selectedConversation &&
            selectedMessages &&
            selectedMessages.length > 0
        ) {
            readMessage(selectedConversation)
        }
    }, [selectedConversation, selectedMessages])

    if (!user) return <Loader />

    if (isLoading) return <Loader />

    return (
        <AudioPlayingProvider>
            <div className="chat-page">
                <DesktopHeader />
                {!isActive ? (
                    <Inactive />
                ) : (
                    <Fragment>
                        <div
                            className="chat-page-wrapper"
                            style={{
                                gridTemplateColumns:
                                    selectedConversation && isDesktop
                                        ? '1fr 1fr'
                                        : '1fr',
                                width: isDesktop
                                    ? selectedConversation
                                        ? '78%'
                                        : '40%'
                                    : '100%',
                            }}
                        >
                            {(isDesktop ||
                                (isTablet && !selectedConversation)) && (
                                <div className="content">
                                    <Header
                                        setSelectedConversation={
                                            setSelectedConversation
                                        }
                                        setCreateNewConversation={
                                            setCreateNewConversation
                                        }
                                        selectedTab={selectedTab}
                                    />

                                    <Tabs
                                        setSelectedTab={setSelectedTab}
                                        selectedTab={selectedTab}
                                    />
                                    <SearchBar
                                        searchText={searchText}
                                        setSearchText={setSearchText}
                                    />

                                    <div className="conversations">
                                        {conversationsList &&
                                            conversationsList.map(
                                                (conversation, idx) => (
                                                    <Fragment key={idx}>
                                                      
                                                        <Conversation
                                                            itsMe={
                                                                conversation
                                                                    .user
                                                                    ?.first_name ===
                                                                    user.first_name &&
                                                                conversation
                                                                    .user
                                                                    ?.last_name ===
                                                                    user.last_name
                                                            }
                                                            key={idx}
                                                            selectedConversation={
                                                                selectedConversation
                                                            }
                                                            editGroup={
                                                                editGroup
                                                            }
                                                            {...conversation}
                                                            style={{
                                                                backgroundColor:
                                                                    selectedConversation &&
                                                                    selectedConversation.id ==
                                                                        conversation.id
                                                                        ? 'var(--primary-gray-lighter)'
                                                                        : 'var(--primary-white)',
                                                            }}
                                                            onClick={() => {
                                                                setSelectedConversation(
                                                                    conversation
                                                                )
                                                                setCreateNewConversation(
                                                                    false
                                                                )
                                                                setEditingGroup(
                                                                    false
                                                                )
                                                                window.history.replaceState(
                                                                    null,
                                                                    window.title,
                                                                    '/messages/default/' +
                                                                        conversation.id
                                                                )
                                                            }}
                                                        />
                                                        <hr />
                                                    </Fragment>
                                                )
                                            )}
                                    </div>
                                </div>
                            )}

                            {selectedConversation && (
                                <div className="messages-container">
                                    <MessageHeader
                                        editGroup={editGroup}
                                        editedName={editedName}
                                        setEditedName={setEditedName}
                                        selectedConversation={
                                            selectedConversation
                                        }
                                        setSelectedConversation={
                                            setSelectedConversation
                                        }
                                        createNewConversation={
                                            createNewConversation
                                        }
                                        editingGroup={editingGroup}
                                        setEditingGroup={setEditingGroup}
                                        user={user}
                                    />

                                    {!createNewConversation &&
                                        !editingGroup && (
                                            <Messages
                                                selectedMessages={
                                                    selectedMessages
                                                }
                                                user={user}
                                                selectedConversation={
                                                    selectedConversation
                                                }
                                            />
                                        )}

                                    <Sender
                                        editGroup={editGroup}
                                        createNewConversation={
                                            createNewConversation
                                        }
                                        setCreateNewConversation={
                                            setCreateNewConversation
                                        }
                                        selectedMessages={selectedMessages}
                                        setSelectedConversation={
                                            setSelectedConversation
                                        }
                                        setSelectedMessages={
                                            setSelectedMessages
                                        }
                                        selectedConversation={
                                            selectedConversation
                                        }
                                        editingGroup={editingGroup}
                                    />
                                </div>
                            )}
                        </div>
                        <Conversations // useEffects
                            setConversationsList={setConversationsList}
                            setSelectedMessages={setSelectedMessages}
                            selectedTab={selectedTab}
                            selectedConversation={selectedConversation}
                            searchText={searchText}
                            selectedMessages={selectedMessages}
                        />
                    </Fragment>
                )}
            </div>
        </AudioPlayingProvider>
    )
}

ChatPage.propTypes = {}
ChatPage.defaultProps = {}
