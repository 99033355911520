// This file defines the styles for the react-select component

export const ReactSelectStyles = {
  container: (base) => ({
    ...base,
    width: '100%',
    alignItems: 'center',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '2.2rem',
    borderRadius: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    position: 'block',
    paddingLeft: '2.5rem',
  }),
  control: (base) => ({
    ...base,
    borderRadius: '0.4rem',
    border: '1px solid var(--primary-light-gray)',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '0.875rem',
    fontWeight: '300',
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: '0.875rem',
    fontWeight: '300',
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isFocused
      ? 'var(--primary-white)'
      : isSelected
      ? 'var(--primary-white)'
      : 'var(--pure-white)',
    color: isFocused ? 'var(--primary-dark)' : 'var(--primary-dark)',

    fontWeight: '300',
    fontSize: '0.875rem',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled
        ? isSelected
          ? undefined
          : 'var(--primary-light-gray)'
        : undefined,
    },
  }),
};
