import { useTranslation } from '../../translations/redux/setDefaultLanguage';

export default function LastSeen({ lastSeen = '' }) {
  // create a react functional component to getLastSeen
  const { t } = useTranslation('datestrings');

  const date = new Date(lastSeen);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor(diff / 1000);

  if (!lastSeen) return <>{t('relative.never')}</>;

  if (days > 730) {
    return <>{t('relative.years', { years: Math.floor(days / 365) })}</>;
  }

  // years
  if (days > 365) {
    return <>{t('relative.year')}</>;
  }

  if (days > 60) {
    return <>{t('relative.months', { months: Math.floor(days / 30) })}</>;
  }

  // months
  if (days > 30) {
    return <>{t('relative.month')}</>;
  }

  if (days === 7) {
    return <>{t('relative.week')}</>;
  }

  // weeks
  if (days > 7) {
    return <>{t('relative.weeks', { weeks: Math.floor(days / 7) })}</>;
  }

  if (days === 1) {
    return <>{t('relative.day')}</>;
  }

  if (days > 0) return <>{t('relative.days', { days })}</>;
  if (hours > 0) return <>{t('relative.hours', { hours })}</>;
  if (minutes === 1) return <>{t('relative.minute')}</>;
  if (minutes > 0) return <>{t('relative.minutes', { minutes })}</>;
  if (seconds > 0) return <>{t('relative.seconds', { seconds })}</>;

  return <>{t('relative.now')}</>;
}
