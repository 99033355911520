export const useOpenPeerConversation = () => {
    const openPeerConversation = ({
        id,
        first_name,
        last_name,
        photo,
        username,
    }) => {
        // open conversation with user in a new tab
        const conversation = JSON.stringify({
            id,
            timestamp: Date.now(),
            type: 'p2p',
            user: {
                id,
                first_name,
                last_name,
                photo,
                username,
            },
        })

        // encode the conversation object
        const encodedConversation = encodeURIComponent(conversation)

        window.open(
            `/messages/default/?conversation=${encodedConversation}`,
            '_blank'
        )
    }

    return { openPeerConversation }
}
