import React from 'react';
import Icon from '../../../../common/components/Icon';

export default function SubscriptionSelector({
  title = 'Subscription Name',
  price = null,
  active = true,
  endDate = null,
}) {
  return (
    <div className="support-subscription-container">
      <div className="title-container">
        <Icon name="circlecheck" active={active} />
        <div>
          <p>{title}</p>
          {endDate && <p className="end-date">Available until: {endDate}</p>}
        </div>
      </div>
      {price && !endDate && (
        <p className="price">{price > 0 ? `$${price / 100} / mo` : 'free'}</p>
      )}
    </div>
  );
}
