import React, { useState } from 'react'
import Icon from '../../../common/components/Icon'
import useWindowSize from '../../../common/useWindowResize'

export default function RecordColumn({
    title = '',
    icon = <Icon name="hand-click" size={15} color="#595959" />,
    score = 0,
    ranges = [],
}) {
    const [showFaqTooltip, setShowFaqTooltip] = useState(false)
    const { isDesktop } = useWindowSize()
    return (
        <div
            className="rpm-provider-record-grid__column"
            style={
                {
                    //borderBottomLeftRadius: '1rem',
                }
            }
        >
            <div className="rpm-provider-record-grid__column__header">
                <div
                    className="rpm-provider-record-grid__column__header__icon-container"
                    style={{
                        transform: 'scale(0.8)',
                    }}
                >
                    {icon}
                </div>
                <p className="rpm-provider-record-grid__column__header__title">
                    {title}
                </p>
                <div className="rpm-provider-record-grid__column__header__icon-container">
                    {ranges && ranges.length > 0 && (
                        <button
                            className="tooltip-wrapper"
                            type="button"
                            {...(isDesktop && {
                                onMouseEnter: () => setShowFaqTooltip(true),
                            })}
                            {...(isDesktop && {
                                onMouseLeave: () => setShowFaqTooltip(false),
                            })}
                            {...(!isDesktop && {
                                onClick: () =>
                                    setShowFaqTooltip(!showFaqTooltip),
                            })}
                        >
                            <Icon
                                name="faq"
                                size={12}
                                color={'#A4A2A1'}
                                style={{ zIndex: 10 }}
                            />
                            {showFaqTooltip && isDesktop && (
                                <div className="tooltip-wrapper__tooltip">
                                    <div className="tooltip-wrapper__tooltip__triangle" />
                                    <h3>{title}</h3>
                                    <div className="tooltip-wrapper__tooltip__border" />
                                    <div className="tooltip-wrapper__tooltip__content">
                                        {ranges.map(
                                            (
                                                {
                                                    min,
                                                    max,
                                                    tag,
                                                    color,
                                                    bg_color,
                                                },
                                                index
                                            ) => (
                                                <div
                                                    className="tooltip-wrapper__tooltip__content__item"
                                                    key={index}
                                                >
                                                    <p className="tooltip-wrapper__tooltip__content__item__tag">
                                                        {tag}
                                                    </p>

                                                    <div
                                                        className="tooltip-wrapper__tooltip__content__item__score"
                                                        style={{
                                                            backgroundColor: bg_color,
                                                        }}
                                                    >
                                                        <p
                                                            className="tooltip-wrapper__tooltip__content__item__score__value"
                                                            style={{
                                                                color,
                                                            }}
                                                        >
                                                            {min} - {max}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </button>
                    )}
                </div>
            </div>

            <div className="rpm-provider-record-grid__column__body">
                <p className="rpm-provider-record-grid__column__body__title">
                    {score}
                </p>
            </div>
        </div>
    )
}
