import React, { useMemo, useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import dayjs from 'dayjs';
import {
  Header,
  SearchBar,
  CustomTable,
  Loader,
  ActionMenu,
  DesktopHeader,
} from '../common';
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers';
import { useFetchUserAccounts } from './redux/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
// import { useFetchUser } from '../user/redux/hooks';

import ReactPaginate from 'react-paginate';

export default function AccountsPage() {
  // const { user, fetchUser } = useFetchUser();
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const { fetchUserAccounts, fetchUserAccountsPending } =
    useFetchUserAccounts();
  const [actionMenu, setActionMenu] = useState(null);
  const location = useLocation();
  const [accounts, setAccounts] = useState(null);

  // useEffect(() => {
  //   fetchUser()
  // }, [fetchUser])

  const columns = [
    {
      title: 'Name',
      id: 'name',
      sortable: true,
    },
    {
      title: 'Email',
      id: 'email',
      sortable: true,
    },
    {
      title: '',
      id: 'action',
      className: 'text-center',
      cell: (row) => (
        <Button
          className="btn-action btn-edit"
          onClick={handleActionMenuClick(row.id)}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
      ),
    },
  ];

  const desktopColumns = [
    {
      title: 'Name',
      id: 'name',
      sortable: true,
      cell: (row) => (
        <>
          <b>{row.name}</b>
          <p>{row.email}</p>
        </>
      ),
    },
    {
      title: 'Role',
      id: 'groups',
      sortable: true,
      cell: (row) => <p>{row.groups}</p>,
    },
    {
      title: 'Group',
      id: 'cohort',
      sortable: true,
      cell: (row) => <p>{row.cohort.map((c) => c.text).join(', ')}</p>,
    },
    {
      title: 'Active',
      id: 'is_approved',
      sortable: true,
      cell: (row) => <p>{row.is_approved}</p>,
    },
    {
      title: 'Internal Coach',
      id: 'is_internal_coach',
      sortable: true,
      cell: (row) => <p>{row.is_internal_coach}</p>,
    },
    {
      title: 'Coach',
      id: 'coach',
      sortable: true,
      cell: (row) => <p>{row.coach}</p>,
    },
    {
      title: 'Roadmaps',
      id: 'roadmaps',
      sortable: true,
      cell: (row) => <p>{row.roadmaps}</p>,
    },
    {
      title: '',
      id: 'action',
      className: 'text-center',
      cell: (row) => (
        <Link
          className="more-btn-desktop float-right"
          onClick={handleActionMenuClick(row.id)}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Link>
      ),
    },
  ];

  const handleActionMenuClick = useCallback(
    (userId) => () => {
      setActionMenu(userId);
    },
    [],
  );

  const handleHideActionMenu = useCallback(() => setActionMenu(null), []);

  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();

  const tableData = useMemo(() => {
    if (!accounts) {
      return [];
    }

    const filteredAccounts = accounts.results.map((acc) => ({
      id: acc.id,
      name: `${acc.first_name} ${acc.last_name}`,
      email: acc.email,
      last_login: acc.last_login ? dayjs(acc.last_seen).fromNow() : 'Never',
      groups: acc.groups.join(', '),
      is_approved: acc.is_approved === true ? 'Active' : 'Inactive',
      is_internal_coach: acc.is_internal_coach === true ? 'Yes' : 'No',
      cohort: acc.cohort,
      coach: acc.coach.map((c) => `${c.first_name} ${c.last_name}`).join(', '),
      roadmaps: acc.roadmaps_info.map((r) => `${r.title}`).join(', '),
    }));

    // if (!!user && user.cohort.length > 0 && !!filteredAccounts) {
    //   return filteredAccounts.filter(acc =>
    //     user.cohort.some(c => acc.cohort.some(aC => aC.id === c.id)),
    //   );
    // }

    return filteredAccounts;
  }, [accounts]);

  // Pagination

  const pageSize = 15;

  const paginationPerPage = pageSize;
  const [paginationData, setPaginationData] = useState([]);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(0);
  const [paginationPageCount, setPaginationPageCount] = useState(0);

  useEffect(() => {
    if (!!accounts)
      setPaginationPageCount(Math.ceil(accounts.count / paginationPerPage));
  }, [accounts, paginationPerPage]);

  useEffect(() => {
    const loadUserAccounts = async () => {
      const accs = await fetchUserAccounts({ page: 0, pageSize }).catch(
        unauthorizedErrorHandler,
      );
      setAccounts(accs);
    };

    loadUserAccounts();
  }, [fetchUserAccounts, unauthorizedErrorHandler, paginationPerPage]);

  const paginateData = useCallback(() => {
    const data = tableData;
    setPaginationData(data);
  }, [tableData]);

  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    setAccounts(
      await fetchUserAccounts({
        page: selectedPage,
        pageSize,
        search: searchText,
        sortBy: sortBy,
      }),
    );

    setPaginationCurrentPage(selectedPage);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    paginateData();
  }, [paginateData, paginationCurrentPage]);

  const debouncedSearch = useCallback(
    debounce(async (q) => {
      setAccounts(
        await fetchUserAccounts({ search: q, page: 0, sortBy, pageSize }),
      );
    }, 500),
    [fetchUserAccounts],
  );

  const handleAccountSearch = useCallback(
    (e) => {
      setSearchText(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const getSortByKey = useCallback((field) => {
    if (field === 'name') {
      return 'first_name,last_name';
    } else if (field === '-name') {
      return '-first_name,-last_name';
    } else {
      return field;
    }
  }, []);

  const handleSortChange = useCallback(
    async (field) => {
      const newSortBy = getSortByKey(field);
      setSortBy(newSortBy);
      setAccounts(
        await fetchUserAccounts({
          search: searchText,
          sortBy: newSortBy,
          pageSize,
        }),
      );
    },
    [searchText, getSortByKey, setSortBy, fetchUserAccounts],
  );

  const actionMenuItems = useCallback(() => {
    const items = [];

    if (accounts) {
      const user = accounts.results.find((u) => u.id === actionMenu);
      items.push({ user });
    }

    items.push(
      {
        to: {
          pathname: `/rpm/client-profile/${actionMenu}`,
          state: { backLink: location },
        },
        label: 'View Account',
      },
      {
        to: {
          pathname: `/user/edit-profile/${actionMenu}`,
          state: { backLink: location },
        },
        label: 'Edit Account',
      }
    )

    return items;
  }, [actionMenu, accounts, location]);

  return (
    <div className="manage-accounts-page">
      <Header border icon="back" title="Accounts" defaultBackLink="/user" />
      <DesktopHeader>
        <Container>
          <div className="desktop-page-secondary-header-wrapper card mrm-mb-1 mrm-p-1">
            <h1>Accounts ({accounts && accounts.count})</h1>
            <div className="d-flex justify-content-between">
              <SearchBar value={searchText} onSearch={handleAccountSearch} />
              <Link to="/user/edit-profile">
                <button className="add-account">Add Account</button>
              </Link>
            </div>
          </div>
        </Container>
      </DesktopHeader>
      <div className="d-lg-none mobile-page-container">
        <div className="mrm-mt-1 mrm-px-0_5 d-flex justify-content-between d-lg-none">
          <SearchBar value={searchText} onSearch={handleAccountSearch} />
          <Link to="/user/edit-profile/">
            <button className="add-account">Add Account</button>
          </Link>
        </div>
        <div className="mrm-mt-1 mrm-mb-3">
          <CustomTable
            columns={columns}
            data={paginationData}
            onSortChange={handleSortChange}
          />
        </div>
      </div>
      <div className="d-none d-lg-block desktop-page-container">
        <Container>
          <div className="mrm-mt-1 mrm-mb-3">
            <CustomTable
              columns={desktopColumns}
              data={paginationData}
              onSortChange={handleSortChange}
            />
          </div>
        </Container>
      </div>

      <div className="pagination-container">
        <Container>
          <div className="mrm-mt-1 mrm-mb-3">
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={paginationPageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          </div>
        </Container>
      </div>

      {fetchUserAccountsPending && <Loader />}
      <ActionMenu
        show={!!actionMenu}
        onHide={handleHideActionMenu}
        items={actionMenuItems()}
      />
    </div>
  );
}

AccountsPage.propTypes = {};
AccountsPage.defaultProps = {};
