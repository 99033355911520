import React, { useCallback, useEffect, useState, createContext } from 'react';
import { useHandleCreateProductSubmit } from './utils/OnboardingInvitation/hooks/useHandleCreateProductSubmit';
import {
  useCreatePriceSubscription,
  useCreateProductSubscription,
  useGetListProductSubscription,
} from '../../home/redux/hooks';
import { useFetchUser } from '../../user/redux/fetchUser';
import { useFetchRoadmaps } from '../redux/fetchRoadmaps';

export const InvitationContext = createContext();

export default function InvitationContextProvider({ children, ...props }) {
  const { user, replaceStringWithSynonyms } = useFetchUser();
  const { getListProductSubscription } = useGetListProductSubscription();

  // Roadmaps related hooks
  const { roadmaps, fetchRoadmaps } = useFetchRoadmaps();
  const [allRoadmaps, setAllRoadmaps] = useState(
    roadmaps && roadmaps.results.filter((roadmap) => roadmap.is_published),
  );
  const [selectedRoadmaps, setSelectedRoadmaps] = useState([]);

  useEffect(() => {
    fetchRoadmaps({ assignedCoaches: false }).then((res) => {
      setAllRoadmaps(
        res.data.results.filter((roadmap) => roadmap.is_published),
      );
    });
  }, [fetchRoadmaps]);

  // Subscription related hooks

  const [subSelected, setSubSelected] = useState(null);

  // User related hooks
  const [firstVals, setFirstVals] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState(1);

  const [addSubscriptionForm, setAddSubscriptionForm] = useState(false);
  const [subscriptionsList, setSubscriptionsList] = useState(null);

  useEffect(() => {
    getListProductSubscription({ coach_id: user?.id }).then((res) => {
      setSubscriptionsList(res.data.results);
    });
  }, [getListProductSubscription, user]);

  // add roadmap to user
  const handleAssignRoadmap = useCallback(
    (roadmap) => () => {
      setSelectedRoadmaps([...selectedRoadmaps, roadmap]);
      let newRoadmaps = allRoadmaps.filter((r) => r.id !== roadmap.id);
      setAllRoadmaps(newRoadmaps);
    },
    [selectedRoadmaps, allRoadmaps],
  );

  // remove roadmap from user
  const handleDeleteRoadmap = useCallback(
    (roadmap) => () => {
      let tempSelectedRoadmaps = selectedRoadmaps.filter(
        (r) => r.id !== roadmap.id,
      );
      setSelectedRoadmaps(tempSelectedRoadmaps);
      setAllRoadmaps([...allRoadmaps, roadmap]);
    },
    [selectedRoadmaps, allRoadmaps],
  );

  // Step 3 - Create a Subscription
  const { createPriceSubscription } = useCreatePriceSubscription();
  const { createProductSubscription } = useCreateProductSubscription();
  const [subscription, setSubscription] = React.useState(
    `Noble guided w/ ${user?.first_name} ${user?.last_name}`,
  );
  const [price, setPrice] = React.useState('');

  const { handleCreateProductSubmit } = useHandleCreateProductSubmit({
    setAddSubscriptionForm,
    setSubscriptionsList,
    createPriceSubscription,
    createProductSubscription,
    user,
    price,
    subscription,
    getListProductSubscription,
  });

  // After user stops typing for 0.5 seconds,
  // check if the email is already taken
  return (
    <InvitationContext.Provider
      value={{
        step,
        setStep,
        setIsLoading,
        setFirstVals,
        firstVals,
        setSubSelected,
        setSubscription,
        setPrice,
        price,
        handleCreateProductSubmit,
        addSubscriptionForm,
        setAddSubscriptionForm,
        subscriptionsList,
        setSubscriptionsList,
        handleAssignRoadmap,
        handleDeleteRoadmap,
        selectedRoadmaps,
        allRoadmaps,
        roadmaps,
        setSelectedRoadmaps,
        setAllRoadmaps,
        replaceStringWithSynonyms,
        user,
        isLoading,
        subSelected,
        ...props,
      }}
    >
      {children}
    </InvitationContext.Provider>
  );
}
