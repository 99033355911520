export const RPM_LIST_HRV_BEGIN = 'RPM_LIST_HRV_BEGIN';
export const RPM_LIST_HRV_SUCCESS = 'RPM_LIST_HRV_SUCCESS';
export const RPM_LIST_HRV_FAILURE = 'RPM_LIST_HRV_FAILURE';
export const RPM_LIST_HRV_DISMISS_ERROR = 'RPM_LIST_HRV_DISMISS_ERROR';
export const RPM_LIST_DEVICES_BEGIN = 'RPM_LIST_DEVICES_BEGIN';
export const RPM_LIST_DEVICES_SUCCESS = 'RPM_LIST_DEVICES_SUCCESS';
export const RPM_LIST_DEVICES_FAILURE = 'RPM_LIST_DEVICES_FAILURE';
export const RPM_LIST_DEVICES_DISMISS_ERROR = 'RPM_LIST_DEVICES_DISMISS_ERROR';
export const RPM_FITBIT_OAUTH_BEGIN = 'RPM_FITBIT_OAUTH_BEGIN';
export const RPM_FITBIT_OAUTH_SUCCESS = 'RPM_FITBIT_OAUTH_SUCCESS';
export const RPM_FITBIT_OAUTH_FAILURE = 'RPM_FITBIT_OAUTH_FAILURE';
export const RPM_FITBIT_OAUTH_DISMISS_ERROR = 'RPM_FITBIT_OAUTH_DISMISS_ERROR';
export const RPM_FETCH_UNIFIED_SUMMARY_BEGIN = 'RPM_FETCH_UNIFIED_SUMMARY_BEGIN';
export const RPM_FETCH_UNIFIED_SUMMARY_SUCCESS = 'RPM_FETCH_UNIFIED_SUMMARY_SUCCESS';
export const RPM_FETCH_UNIFIED_SUMMARY_FAILURE = 'RPM_FETCH_UNIFIED_SUMMARY_FAILURE';
export const RPM_FETCH_UNIFIED_SUMMARY_DISMISS_ERROR = 'RPM_FETCH_UNIFIED_SUMMARY_DISMISS_ERROR';
export const RPM_CREATE_INSURANCE_INFORMATION_BEGIN = 'RPM_CREATE_INSURANCE_INFORMATION_BEGIN';
export const RPM_CREATE_INSURANCE_INFORMATION_SUCCESS = 'RPM_CREATE_INSURANCE_INFORMATION_SUCCESS';
export const RPM_CREATE_INSURANCE_INFORMATION_FAILURE = 'RPM_CREATE_INSURANCE_INFORMATION_FAILURE';
export const RPM_CREATE_INSURANCE_INFORMATION_DISMISS_ERROR = 'RPM_CREATE_INSURANCE_INFORMATION_DISMISS_ERROR';
export const RPM_CREATE_PROFILE_ADDRESS_BEGIN = 'RPM_CREATE_PROFILE_ADDRESS_BEGIN';
export const RPM_CREATE_PROFILE_ADDRESS_SUCCESS = 'RPM_CREATE_PROFILE_ADDRESS_SUCCESS';
export const RPM_CREATE_PROFILE_ADDRESS_FAILURE = 'RPM_CREATE_PROFILE_ADDRESS_FAILURE';
export const RPM_CREATE_PROFILE_ADDRESS_DISMISS_ERROR = 'RPM_CREATE_PROFILE_ADDRESS_DISMISS_ERROR';
export const RPM_FETCH_ASSESSMENTS_BEGIN = 'RPM_FETCH_ASSESSMENTS_BEGIN';
export const RPM_FETCH_ASSESSMENTS_SUCCESS = 'RPM_FETCH_ASSESSMENTS_SUCCESS';
export const RPM_FETCH_ASSESSMENTS_FAILURE = 'RPM_FETCH_ASSESSMENTS_FAILURE';
export const RPM_FETCH_ASSESSMENTS_DISMISS_ERROR = 'RPM_FETCH_ASSESSMENTS_DISMISS_ERROR';
export const RPM_CREATE_ASSESSMENT_SCORE_BEGIN = 'RPM_CREATE_ASSESSMENT_SCORE_BEGIN';
export const RPM_CREATE_ASSESSMENT_SCORE_SUCCESS = 'RPM_CREATE_ASSESSMENT_SCORE_SUCCESS';
export const RPM_CREATE_ASSESSMENT_SCORE_FAILURE = 'RPM_CREATE_ASSESSMENT_SCORE_FAILURE';
export const RPM_CREATE_ASSESSMENT_SCORE_DISMISS_ERROR = 'RPM_CREATE_ASSESSMENT_SCORE_DISMISS_ERROR';
export const RPM_GET_CPT_LIST_BEGIN = 'RPM_GET_CPT_LIST_BEGIN';
export const RPM_GET_CPT_LIST_SUCCESS = 'RPM_GET_CPT_LIST_SUCCESS';
export const RPM_GET_CPT_LIST_FAILURE = 'RPM_GET_CPT_LIST_FAILURE';
export const RPM_GET_CPT_LIST_DISMISS_ERROR = 'RPM_GET_CPT_LIST_DISMISS_ERROR';
export const RPM_CREATE_CPT_LOG_BEGIN = 'RPM_CREATE_CPT_LOG_BEGIN';
export const RPM_CREATE_CPT_LOG_SUCCESS = 'RPM_CREATE_CPT_LOG_SUCCESS';
export const RPM_CREATE_CPT_LOG_FAILURE = 'RPM_CREATE_CPT_LOG_FAILURE';
export const RPM_CREATE_CPT_LOG_DISMISS_ERROR = 'RPM_CREATE_CPT_LOG_DISMISS_ERROR';
export const RPM_GET_CLIENTS_BEGIN = 'RPM_GET_CLIENTS_BEGIN';
export const RPM_GET_CLIENTS_SUCCESS = 'RPM_GET_CLIENTS_SUCCESS';
export const RPM_GET_CLIENTS_FAILURE = 'RPM_GET_CLIENTS_FAILURE';
export const RPM_GET_CLIENTS_DISMISS_ERROR = 'RPM_GET_CLIENTS_DISMISS_ERROR';
export const RPM_GET_PROFILE_SUMMARY_BEGIN = 'RPM_GET_PROFILE_SUMMARY_BEGIN';
export const RPM_GET_PROFILE_SUMMARY_SUCCESS = 'RPM_GET_PROFILE_SUMMARY_SUCCESS';
export const RPM_GET_PROFILE_SUMMARY_FAILURE = 'RPM_GET_PROFILE_SUMMARY_FAILURE';
export const RPM_GET_PROFILE_SUMMARY_DISMISS_ERROR = 'RPM_GET_PROFILE_SUMMARY_DISMISS_ERROR';
export const RPM_GET_USER_INSURANCE_INFORMATION_BEGIN = 'RPM_GET_USER_INSURANCE_INFORMATION_BEGIN';
export const RPM_GET_USER_INSURANCE_INFORMATION_SUCCESS = 'RPM_GET_USER_INSURANCE_INFORMATION_SUCCESS';
export const RPM_GET_USER_INSURANCE_INFORMATION_FAILURE = 'RPM_GET_USER_INSURANCE_INFORMATION_FAILURE';
export const RPM_GET_USER_INSURANCE_INFORMATION_DISMISS_ERROR = 'RPM_GET_USER_INSURANCE_INFORMATION_DISMISS_ERROR';
export const RPM_UPDATE_USER_INSURANCE_INFORMATION_BEGIN = 'RPM_UPDATE_USER_INSURANCE_INFORMATION_BEGIN';
export const RPM_UPDATE_USER_INSURANCE_INFORMATION_SUCCESS = 'RPM_UPDATE_USER_INSURANCE_INFORMATION_SUCCESS';
export const RPM_UPDATE_USER_INSURANCE_INFORMATION_FAILURE = 'RPM_UPDATE_USER_INSURANCE_INFORMATION_FAILURE';
export const RPM_UPDATE_USER_INSURANCE_INFORMATION_DISMISS_ERROR = 'RPM_UPDATE_USER_INSURANCE_INFORMATION_DISMISS_ERROR';
export const RPM_GET_LATEST_UNIFIED_SUMMARY_BEGIN = 'RPM_GET_LATEST_UNIFIED_SUMMARY_BEGIN';
export const RPM_GET_LATEST_UNIFIED_SUMMARY_SUCCESS = 'RPM_GET_LATEST_UNIFIED_SUMMARY_SUCCESS';
export const RPM_GET_LATEST_UNIFIED_SUMMARY_FAILURE = 'RPM_GET_LATEST_UNIFIED_SUMMARY_FAILURE';
export const RPM_GET_LATEST_UNIFIED_SUMMARY_DISMISS_ERROR = 'RPM_GET_LATEST_UNIFIED_SUMMARY_DISMISS_ERROR';
export const RPM_GET_ALERTS_BEGIN = 'RPM_GET_ALERTS_BEGIN';
export const RPM_GET_ALERTS_SUCCESS = 'RPM_GET_ALERTS_SUCCESS';
export const RPM_GET_ALERTS_FAILURE = 'RPM_GET_ALERTS_FAILURE';
export const RPM_GET_ALERTS_DISMISS_ERROR = 'RPM_GET_ALERTS_DISMISS_ERROR';
export const RPM_CLEAR_ALL_ALERTS_BEGIN = 'RPM_CLEAR_ALL_ALERTS_BEGIN';
export const RPM_CLEAR_ALL_ALERTS_SUCCESS = 'RPM_CLEAR_ALL_ALERTS_SUCCESS';
export const RPM_CLEAR_ALL_ALERTS_FAILURE = 'RPM_CLEAR_ALL_ALERTS_FAILURE';
export const RPM_CLEAR_ALL_ALERTS_DISMISS_ERROR = 'RPM_CLEAR_ALL_ALERTS_DISMISS_ERROR';
export const RPM_GET_BILLING_BEGIN = 'RPM_GET_BILLING_BEGIN';
export const RPM_GET_BILLING_SUCCESS = 'RPM_GET_BILLING_SUCCESS';
export const RPM_GET_BILLING_FAILURE = 'RPM_GET_BILLING_FAILURE';
export const RPM_GET_BILLING_DISMISS_ERROR = 'RPM_GET_BILLING_DISMISS_ERROR';
export const RPM_UPDATE_CPT_CODE_BEGIN = 'RPM_UPDATE_CPT_CODE_BEGIN';
export const RPM_UPDATE_CPT_CODE_SUCCESS = 'RPM_UPDATE_CPT_CODE_SUCCESS';
export const RPM_UPDATE_CPT_CODE_FAILURE = 'RPM_UPDATE_CPT_CODE_FAILURE';
export const RPM_UPDATE_CPT_CODE_DISMISS_ERROR = 'RPM_UPDATE_CPT_CODE_DISMISS_ERROR';
export const RPM_DELETE_CPT_CODE_BEGIN = 'RPM_DELETE_CPT_CODE_BEGIN';
export const RPM_DELETE_CPT_CODE_SUCCESS = 'RPM_DELETE_CPT_CODE_SUCCESS';
export const RPM_DELETE_CPT_CODE_FAILURE = 'RPM_DELETE_CPT_CODE_FAILURE';
export const RPM_DELETE_CPT_CODE_DISMISS_ERROR = 'RPM_DELETE_CPT_CODE_DISMISS_ERROR';
export const RPM_DELETE_CPT_LOG_BEGIN = 'RPM_DELETE_CPT_LOG_BEGIN';
export const RPM_DELETE_CPT_LOG_SUCCESS = 'RPM_DELETE_CPT_LOG_SUCCESS';
export const RPM_DELETE_CPT_LOG_FAILURE = 'RPM_DELETE_CPT_LOG_FAILURE';
export const RPM_DELETE_CPT_LOG_DISMISS_ERROR = 'RPM_DELETE_CPT_LOG_DISMISS_ERROR';
export const RPM_UPDATE_CPT_LOG_BEGIN = 'RPM_UPDATE_CPT_LOG_BEGIN';
export const RPM_UPDATE_CPT_LOG_SUCCESS = 'RPM_UPDATE_CPT_LOG_SUCCESS';
export const RPM_UPDATE_CPT_LOG_FAILURE = 'RPM_UPDATE_CPT_LOG_FAILURE';
export const RPM_UPDATE_CPT_LOG_DISMISS_ERROR = 'RPM_UPDATE_CPT_LOG_DISMISS_ERROR';
export const RPM_UPDATE_ASSESSMENT_BEGIN = 'RPM_UPDATE_ASSESSMENT_BEGIN';
export const RPM_UPDATE_ASSESSMENT_SUCCESS = 'RPM_UPDATE_ASSESSMENT_SUCCESS';
export const RPM_UPDATE_ASSESSMENT_FAILURE = 'RPM_UPDATE_ASSESSMENT_FAILURE';
export const RPM_UPDATE_ASSESSMENT_DISMISS_ERROR = 'RPM_UPDATE_ASSESSMENT_DISMISS_ERROR';
export const RPM_DELETE_ASSESSMENT_BEGIN = 'RPM_DELETE_ASSESSMENT_BEGIN';
export const RPM_DELETE_ASSESSMENT_SUCCESS = 'RPM_DELETE_ASSESSMENT_SUCCESS';
export const RPM_DELETE_ASSESSMENT_FAILURE = 'RPM_DELETE_ASSESSMENT_FAILURE';
export const RPM_DELETE_ASSESSMENT_DISMISS_ERROR = 'RPM_DELETE_ASSESSMENT_DISMISS_ERROR';
export const RPM_GET_PROVIDERS_SUMMARY_BEGIN = 'RPM_GET_PROVIDERS_SUMMARY_BEGIN';
export const RPM_GET_PROVIDERS_SUMMARY_SUCCESS = 'RPM_GET_PROVIDERS_SUMMARY_SUCCESS';
export const RPM_GET_PROVIDERS_SUMMARY_FAILURE = 'RPM_GET_PROVIDERS_SUMMARY_FAILURE';
export const RPM_GET_PROVIDERS_SUMMARY_DISMISS_ERROR = 'RPM_GET_PROVIDERS_SUMMARY_DISMISS_ERROR';
export const RPM_TRACK_CALL_EVENT_BEGIN = 'RPM_TRACK_CALL_EVENT_BEGIN';
export const RPM_TRACK_CALL_EVENT_SUCCESS = 'RPM_TRACK_CALL_EVENT_SUCCESS';
export const RPM_TRACK_CALL_EVENT_FAILURE = 'RPM_TRACK_CALL_EVENT_FAILURE';
export const RPM_TRACK_CALL_EVENT_DISMISS_ERROR = 'RPM_TRACK_CALL_EVENT_DISMISS_ERROR';
export const RPM_FETCH_COMPANY_LINKS_BEGIN = 'RPM_FETCH_COMPANY_LINKS_BEGIN';
export const RPM_FETCH_COMPANY_LINKS_SUCCESS = 'RPM_FETCH_COMPANY_LINKS_SUCCESS';
export const RPM_FETCH_COMPANY_LINKS_FAILURE = 'RPM_FETCH_COMPANY_LINKS_FAILURE';
export const RPM_FETCH_COMPANY_LINKS_DISMISS_ERROR = 'RPM_FETCH_COMPANY_LINKS_DISMISS_ERROR';
