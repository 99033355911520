export const messages = {
  messages: 'Messages',
  all: 'All',
  group: 'Group',
  type_a_message: 'Type a message',
  delete_group: 'Delete group',
  members: 'Members',
  leave_group: 'Leave group',
  group_info: 'Group info',
};

export const spanishMessages = {
  messages: 'Mensajes',
  all: 'Todos',
  group: 'Grupo',
  type_a_message: 'Escribe un mensaje',
  delete_group: 'Eliminar grupo',
  members: 'Miembros',
  leave_group: 'Dejar grupo',
  group_info: 'Información del grupo',
};
