import React from 'react'
import { DesktopHeader } from '../common'
import Icon from '../common/components/Icon'

import CardsContainer from './components/provider-dashboard/CardsContainer'
import NewStaffModal from './components/provider-dashboard/NewStaffModal'
import RecordsContainer from './components/provider-dashboard/RecordsContainer'
import SearchContainer from './components/provider-dashboard/SearchContainer'

import { useTranslation } from '../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../user/redux/fetchUser'

export default function ProviderDashboard() {
  const [params, setParams] = React.useState({
    search: '',
    page: 1,
    eligibility_status: '',
    alert_type: '',
  })
  const [clientsCount, setClientsCount] = React.useState(0)

  const [showStaffModal, setShowStaffModal] = React.useState(false)

  const { user } = useFetchUser()

  const { t } = useTranslation()

  if (!user) return null

  return (
    <div className="rpm-provider-dashboard">
      <NewStaffModal
        show={showStaffModal}
        onHide={() => setShowStaffModal(false)}
      />
      <DesktopHeader />
      <div className="rpm-provider-dashboard__add-staff-header">
        <div className="rpm-provider-dashboard__add-staff-header__left">
          <p className="rpm-provider-dashboard__add-staff-header__left__title">
            {user.company_name}
          </p>
        </div>

        <div className="rpm-provider-dashboard__add-staff-header__right">
          <button
            className="rpm-provider-dashboard__add-staff-header__right__button"
            onClick={() => setShowStaffModal(true)}
            type="button"
          >
            <Icon name="plus" size={15} color="#fff" />
            <p className="rpm-provider-dashboard__add-staff-header__right__button__text">
              {t('provider_dashboard.add_staff')}
            </p>
          </button>
        </div>
      </div>
      <div className="rpm-provider-container">
        <CardsContainer />
        <SearchContainer
          params={params}
          setParams={setParams}
          clientsCount={clientsCount}
        />

        <RecordsContainer
          params={params}
          setParams={setParams}
          setClientsCount={setClientsCount}
        />
      </div>
    </div>
  )
}

ProviderDashboard.propTypes = {}
ProviderDashboard.defaultProps = {}
