import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const MessageIcon = ({
  size = 26,
  color = '#343434',
  style = {},
  active = false,
}) => {
  if (active) {
    return (
      <svg
        width={size * 1.77}
        height={size}
        viewBox="0 0 62 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M61.1979 6.57461C60.7255 5.27214 60.0029 4.07854 59.0724 3.06352C58.1417 2.08001 57.0224 1.3018 55.7839 0.777226C54.5135 0.241414 53.1484 -0.0226272 51.7736 0.0015185H10.1863C8.8369 -0.00731826 7.49939 0.259069 6.25271 0.784965C5.00603 1.31086 3.87544 2.08561 2.92762 3.06352C1.04137 5.02648 -0.0102643 7.66631 7.55305e-05 10.4123V67.0798C0.0104335 68.23 0.349717 69.3522 0.976457 70.3092C1.6032 71.2663 2.49024 72.0168 3.52917 72.4689C4.2959 72.8282 5.13149 73.0094 5.97548 72.9996H6.93796C8.05838 72.8403 9.10611 72.3426 9.94572 71.5707L18.7685 63.4054H51.8939C53.2263 63.4 54.5446 63.1275 55.7736 62.6035C57.0026 62.0794 58.1181 61.3141 59.0566 60.3512C59.995 59.3882 60.7379 58.2465 61.243 56.9913C61.748 55.736 62.0052 54.3918 61.9999 53.0354V10.5348C61.9957 9.17338 61.723 7.82668 61.1979 6.57461ZM33.6468 44.3393H16.3623C15.609 44.2515 14.9139 43.8846 14.4092 43.3087C13.9045 42.7327 13.6256 41.9879 13.6256 41.2161C13.6256 40.4442 13.9045 39.6994 14.4092 39.1234C14.9139 38.5475 15.609 38.1806 16.3623 38.0928H33.6468C34.4 38.1806 35.0952 38.5475 35.5999 39.1234C36.1045 39.6994 36.3834 40.4442 36.3834 41.2161C36.3834 41.9879 36.1045 42.7327 35.5999 43.3087C35.0952 43.8846 34.4 44.2515 33.6468 44.3393V44.3393ZM45.6778 30.0908H16.3623C15.5539 30.0908 14.7787 29.7639 14.2071 29.182C13.6355 28.6001 13.3144 27.8109 13.3144 26.988C13.3143 26.1612 13.6342 25.3678 14.2046 24.7794C14.7751 24.191 15.5502 23.855 16.3623 23.8443H45.6377C46.4497 23.855 47.2249 24.191 47.7954 24.7794C48.3658 25.3678 48.6857 26.1612 48.6856 26.988C48.6856 27.8109 48.3645 28.6001 47.7929 29.182C47.2213 29.7639 46.4461 30.0908 45.6377 30.0908H45.6778Z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size * 1.77}
        height={size}
        viewBox="0 0 62 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.90427 72.8371C5.0562 72.8341 4.21947 72.6393 3.45501 72.2668C2.42361 71.8007 1.54805 71.0403 0.934959 70.0784C0.321871 69.1165 -0.0022869 67.9944 0.001963 66.8489V10.4299C-0.0511104 7.67341 0.973867 5.00779 2.85274 3.01596C3.79553 2.05739 4.91595 1.29755 6.14945 0.780192C7.38295 0.262837 8.70515 -0.00180383 10.0399 0.00151511H51.7977C53.1743 -0.0225768 54.541 0.240876 55.8129 0.775495C57.0351 1.29171 58.1414 2.05388 59.0652 3.01596C60.0123 3.98549 60.7626 5.13423 61.2735 6.39703C61.7878 7.69006 62.0339 9.07678 61.9963 10.4706V53.0802C62.0015 54.4336 61.744 55.7749 61.2384 57.0273C60.7327 58.2798 59.9889 59.4189 59.0493 60.3797C58.1098 61.3405 56.9929 62.1041 55.7624 62.627C54.532 63.1499 53.212 63.4218 51.878 63.4271H18.6324L9.79899 71.5742C8.95837 72.3444 7.90938 72.8411 6.78761 73L5.90427 72.8371ZM9.91945 6.27482C8.86037 6.29013 7.85014 6.72937 7.10882 7.49689C6.7246 7.87896 6.41998 8.33548 6.21306 8.83932C6.00614 9.34316 5.90113 9.88405 5.90427 10.4299V66.8489L14.5369 58.7017C15.6575 57.7334 17.0801 57.1994 18.5521 57.1945H51.7576C52.8109 57.19 53.8203 56.7658 54.5682 56.0132C54.9524 55.6311 55.257 55.1746 55.4639 54.6708C55.6709 54.1669 55.7759 53.626 55.7727 53.0802V10.4706C55.7872 9.90992 55.6774 9.35309 55.4515 8.84118C55.2604 8.34114 54.9739 7.88431 54.6083 7.49689C54.2365 7.11199 53.793 6.80578 53.3037 6.596C52.8143 6.38623 52.2888 6.27706 51.7576 6.27482H9.91945Z"
          fill={color}
        />
        <path
          d="M45.898 30H16.102C15.2793 30 14.4903 29.686 13.9086 29.1271C13.3268 28.5681 13 27.8101 13 27.0196C12.9999 26.2255 13.3254 25.4633 13.906 24.8982C14.4866 24.333 15.2756 24.0103 16.102 24H45.898C46.7244 24.0103 47.5134 24.333 48.094 24.8982C48.6746 25.4633 49.0001 26.2255 49 27.0196C49 27.8101 48.6732 28.5681 48.0914 29.1271C47.5097 29.686 46.7207 30 45.898 30Z"
          fill={color}
        />
        <path
          d="M34.114 45H15.886C15.0917 44.9016 14.3586 44.4905 13.8263 43.8451C13.2941 43.1996 13 42.3649 13 41.5C13 40.6351 13.2941 39.8004 13.8263 39.1549C14.3586 38.5095 15.0917 38.0984 15.886 38H34.114C34.9083 38.0984 35.6414 38.5095 36.1737 39.1549C36.7059 39.8004 37 40.6351 37 41.5C37 42.3649 36.7059 43.1996 36.1737 43.8451C35.6414 44.4905 34.9083 44.9016 34.114 45V45Z"
          fill={color}
        />
      </svg>
    );
  }
};

export default MessageIcon;
