import React from 'react';

export default function CompanyIcon({
  className = '',
  size = 14,
  color = 'var(--primary-dark-green)',
  style = {},
}) {
  return (
    <svg
      width={size * 1.3}
      height={size * 0.85 * 1.3} // 1.3 is to make the icon bigger than the original size
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
    >
      <path
        d="M0.718541 4.44469C0.603614 4.44469 0.493393 4.49151 0.412128 4.57486C0.330862 4.65821 0.285208 4.77126 0.285208 4.88913C0.285208 5.00701 0.330862 5.12005 0.412128 5.2034C0.493393 5.28675 0.603614 5.33358 0.718541 5.33358H12.7049C12.8198 5.33358 12.93 5.28675 13.0113 5.2034C13.0925 5.12005 13.1382 5.00701 13.1382 4.88913V3.91136C13.1377 3.4636 12.964 3.03432 12.6553 2.71771C12.3466 2.4011 11.9281 2.22299 11.4915 2.22247H9.67307V1.13596C9.67276 0.83457 9.55578 0.54564 9.34785 0.332675C9.13992 0.11971 8.85805 0.000138848 8.5642 0.00024421H4.71178C4.41827 0.000664353 4.13689 0.120467 3.92938 0.333375C3.72187 0.546283 3.60516 0.834914 3.60485 1.13596V2.22247H1.7864C1.34956 2.22257 0.930628 2.40049 0.621594 2.71715C0.312561 3.03381 0.138696 3.46332 0.138184 3.91136V10.3114C0.1389 10.7591 0.312617 11.1882 0.621272 11.5048C0.929927 11.8213 1.34835 11.9995 1.78485 12.0002H11.4915C11.928 11.9994 12.3463 11.8212 12.655 11.5047C12.9636 11.1881 13.1374 10.759 13.1382 10.3114V5.07961C13.1382 4.96174 13.0925 4.84869 13.0113 4.76534C12.93 4.68199 12.8198 4.63517 12.7049 4.63517C12.5899 4.63517 12.4797 4.68199 12.3984 4.76534C12.3172 4.84869 12.2715 4.96174 12.2715 5.07961V10.3114C12.2713 10.5235 12.1891 10.7268 12.0428 10.8768C11.8966 11.0268 11.6983 11.1111 11.4915 11.1114H1.78485C1.57798 11.1114 1.37959 11.0271 1.23331 10.877C1.08703 10.727 1.00485 10.5235 1.00485 10.3114V3.91136C1.00475 3.80627 1.02485 3.70219 1.06402 3.60509C1.10318 3.50798 1.16063 3.41974 1.23308 3.34544C1.30553 3.27113 1.39156 3.21221 1.48624 3.17204C1.58092 3.13187 1.68239 3.11125 1.78485 3.11136H11.4915C11.6984 3.11136 11.8968 3.19564 12.0431 3.34567C12.1893 3.4957 12.2715 3.69918 12.2715 3.91136V4.44469H0.718541ZM8.80485 2.22247H4.47152V1.13596C4.47172 1.07063 4.49709 1.00803 4.54209 0.961797C4.58709 0.915565 4.64809 0.889447 4.71178 0.889133H8.5642C8.62796 0.889342 8.68905 0.915414 8.73414 0.961658C8.77923 1.0079 8.80465 1.07056 8.80485 1.13596V2.22247Z"
        fill={color}
      />
    </svg>
  );
}
