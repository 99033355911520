import * as Yup from 'yup'

export const ConfirmCoverageSchema = input_alerts =>
    Yup.object().shape({
        employer: Yup.string().required(input_alerts.employer_required),
        last_4_digits: Yup.string()
            .min(4, input_alerts.last_4_digits_required)
            .max(4, input_alerts.last_4_digits_required)
            .required(input_alerts.last_4_digits_required),
        insured: Yup.boolean().required(input_alerts.insured_required),
    })
