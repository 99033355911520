import React, { useContext, useEffect } from 'react'
import Icon from '../../../../common/components/Icon'
import { useCreateProfileAddress } from '../../../../rpm/redux/createProfileAddress'
import { useUpdateUserInsuranceInformation } from '../../../../rpm/redux/updateUserInsuranceInformation'
import { SupportContext } from '../../../Dashboard'
import { useCreateSubscriptionOnboardingCallCenter } from '../../../redux/createSubscriptionOnboardingCallCenter'
import { useCreateUserAndSubscriptionCallcenterOnboarding } from '../../../redux/createUserAndSubscriptionCallcenterOnboarding'

export default function Completed() {
    const red = 'var(--primary-red)'
    const green = 'var(--primary-light-green)'

    const { isCompleted, userExists } = useContext(SupportContext)

    const Item = ({ color = green, label, retry }) => (
        <div className="completed-item">
            <Icon name="circle" color={color} />
            <p>{label}</p>
            {retry && <button type="submit">Retry</button>}
        </div>
    )

    const [showRetry, setShowRetry] = React.useState(false)

    const {
        createUserAndSubscriptionCallcenterOnboardingPending,
        createUserAndSubscriptionCallcenterOnboardingError,
    } = useCreateUserAndSubscriptionCallcenterOnboarding()

    const {
        createSubscriptionOnboardingCallCenterPending,
        createSubscriptionOnboardingCallCenterError,
    } = useCreateSubscriptionOnboardingCallCenter()

    const {
        updateUserInsuranceInformationPending,
        updateUserInsuranceInformationError,
    } = useUpdateUserInsuranceInformation()

    const {
        createProfileAddressPending,
        createProfileAddressError,
    } = useCreateProfileAddress()

    /*   useEffect(() => {
        // if isCompleted is true, wait 3 seconds to assign showRetry to true
        if (isCompleted) {
            setTimeout(() => {
                setShowRetry(!userExists)
            }, 2000)
        }
    }) */

    const [error, setError] = React.useState([])

    useEffect(() => {
        // if there is an error, show retry button
        if (
            createUserAndSubscriptionCallcenterOnboardingError ||
            createSubscriptionOnboardingCallCenterError ||
            updateUserInsuranceInformationError ||
            createProfileAddressError
        ) {
            setError([])
            setShowRetry(true)

            // find the one that has an error and set the error
            if (createUserAndSubscriptionCallcenterOnboardingError) {
                setError(error => [
                    ...error,
                    'There was an error creating the user.',
                ])
            }

            if (createSubscriptionOnboardingCallCenterError) {
                setError(error => [
                    ...error,
                    'There was an error creating the subscription.',
                ])
            }

            if (updateUserInsuranceInformationError) {
                setError(error => [
                    ...error,
                    'There was an error updating the insurance information.',
                ])
            }

            if (createProfileAddressError) {
                setError(error => [
                    ...error,
                    'There was an error creating the address.',
                ])
            }

            return
        }
        setShowRetry(false)
    }, [
        // all pending states
        createUserAndSubscriptionCallcenterOnboardingPending,
        createSubscriptionOnboardingCallCenterPending,
        updateUserInsuranceInformationPending,
        createProfileAddressPending,
    ])

    if (!isCompleted) return null

    return (
        <div className="completed-form-page">
            <h1>Request Status</h1>
            <div className="completed-status-grid">
                <Item
                    label="User Creation"
                    color={userExists ? green : red}
                    retry={showRetry}
                />
                <Item label="Subscription" color={userExists ? green : red} />
            </div>

            <button
                className="create-new-client"
                onClick={() => {
                    window.location.reload(false)
                }}
            >
                Create a new client
            </button>

            {error.length > 0 && (
                <div className="error">
                    {error.join('\n')}
                    <br />
                    Please contact support.
                </div>
            )}
        </div>
    )
}
