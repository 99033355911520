import React from 'react';
import useWindowSize from '../common/useWindowResize';
import DesktopConnect from './components/connect-your-device/desktop/DesktopConnect';
import MobileConnect from './components/connect-your-device/mobile/MobileConnect';

export default function ConnectYourDevice() {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  if (isMobile) {
    return <MobileConnect />;
  }

  return <DesktopConnect />;
}

ConnectYourDevice.propTypes = {};
ConnectYourDevice.defaultProps = {};
