import React, { useEffect, useState } from 'react'
import Pill from './components/Pill'
// import PropTypes from 'prop-types';
import useWindowSize from '../common/useWindowResize'
import CaregiverModal from './components/CaregiverModal'
import { useLocation, Link } from 'react-router-dom'
import { useGetSettingsByName } from './redux/getSettingsByName'
import { useSetDefaultLanguage } from './redux/setDefaultLanguage'

export default function CaregiverDefault() {
    const size = useWindowSize()
    const isMobile = size.width < 768

    const [showModal, setShowModal] = useState(false)
    const toggleModal = () => setShowModal(!showModal)

    const { getSettingsByName, settings: data } = useGetSettingsByName()

    const location = useLocation()
    let name = location.pathname.split('/').filter(p => p !== '')[2]

    useEffect(() => {
        getSettingsByName({ name })
    }, [name])

    const { defaultLanguage } = useSetDefaultLanguage()

    if (!data) return null

    return (
        <div className="auth-caregiver-default">
            <div className="auth-caregiver-default-navbar">
                <img src={data.logo} alt="logo" />
                <div className="login-and-cta">
                    {data.CTA && data.CTA[defaultLanguage] && (
                        <div className="cta">
                            <p style={{ color: data.colors.tertiary }}>
                                {data.CTA[defaultLanguage].split(',')[0]}
                            </p>
                            <p
                                style={{ color: data.colors.tertiary }}
                                className="schedule"
                            >
                                {data.CTA[defaultLanguage].split(',')[1]}
                            </p>
                        </div>
                    )}
                    <Link to={`/auth/v2/${name}/login`}>
                        <button
                            className="login-btn"
                            style={{
                                backgroundColor: data.colors.primary,
                                color: '#fff',
                            }}
                        >
                            Login
                        </button>
                    </Link>
                </div>
            </div>
            <div className="auth-caregiver-default-content">
                <div className="left-side">
                    <div className="title-content">
                        <h1>
                            Instant & Consistent Support for Those Battling
                            Addiction
                        </h1>
                        <p style={{ color: data.colors.primary }}>
                            Santa Clara County has partnered with Noble to offer
                            addiction coaching, therapy roadmaps, and group
                            support.
                        </p>
                    </div>
                    <div className="cta-content">
                        <button
                            className="login-btn"
                            style={{
                                backgroundColor: data.colors.primary,
                                color: '#fff',
                            }}
                            onClick={toggleModal}
                        >
                            Sign Up
                        </button>
                        {!isMobile && data.CTA2 && data.CTA2[defaultLanguage] && (
                            <Pill
                                image={data.images.pill_illustration}
                                color="transparent"
                                textColor={data.colors.primary}
                                descriptionColor={data.colors.tertiary}
                                text1={data.CTA2[defaultLanguage].split(',')[0]}
                                text2={data.CTA2[defaultLanguage].split(',')[1]}
                            />
                        )}
                    </div>
                </div>
                <div className="right-side">
                    <img src={data.images.home} alt="caregiver-default" />
                    {isMobile && data.CTA2 && data.CTA2[defaultLanguage] && (
                        <Pill
                            image={data.images.pill_illustration}
                            color="transparent"
                            textColor={data.colors.primary}
                            descriptionColor={data.colors.tertiary}
                            text1={data.CTA2[defaultLanguage].split(',')[0]}
                            text2={data.CTA2[defaultLanguage].split(',')[1]}
                        />
                    )}
                </div>
            </div>
            <CaregiverModal
                data={data}
                show={showModal}
                toggleModal={toggleModal}
            />
        </div>
    )
}

CaregiverDefault.propTypes = {}
CaregiverDefault.defaultProps = {}
