import { useCallback } from 'react';
import * as ZapierApi from '../../../common/zapier';

export function useSignupHandleSubmit({
  setEmailError,
  setPasswordError,
  setFirstNameError,
  setLastNameError,
  coachSignUp,
  registerAccount,
  privateLabelledSettings,
  setValidated,
  userAgreement,
}) {
  const handleSubmit = useCallback(
    (event, formState) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();

      setEmailError(' ');
      setPasswordError(' ');
      setFirstNameError(' ');
      setLastNameError(' ');

      if (formState.formFirstName?.length === 0 || !formState.formFirstName) {
        setFirstNameError('First name is required');
      } else if (
        formState.formLastName?.length === 0 ||
        !formState.formLastName
      ) {
        setLastNameError('Last name is required');
      } else if (formState.formEmail?.length === 0 || !formState.formEmail) {
        setEmailError('Email is required');
      } else if (formState.formEmail) {
        const regex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (regex.test(formState.formEmail) === false) {
          setEmailError('Please enter a valid email address');
        } else if (
          formState.formPassword?.length === 0 ||
          !formState.formPassword
        ) {
          setPasswordError('Password is required');
        } else if (formState.formPassword.length < 8) {
          setPasswordError('Password must be at least 8 characters long');
        } else if (formState.formConfirmPassword !== formState.formPassword) {
          setPasswordError('Passwords do not match');
        } else if (formState.formConfirmPassword === formState.formPassword) {
          if (form.checkValidity()) {
            const data = {
              first_name: formState.formFirstName,
              last_name: formState.formLastName,
              email: formState.formEmail,
              password: formState.formPassword,
              company_name: 'Noble',
              is_coach: true,
            };
            if (
              privateLabelledSettings.signupSchoolFieldAvailable &&
              formState.formSchool
            ) {
              data.school = formState.formSchool;
            }

            if (coachSignUp) {
              data.is_coach = true;
            }

            registerAccount(data)
              .then(() => {
                userAgreement({
                  privacy_policy: true,
                  terms_and_conditions: true,
                  dac: true,
                }).then(() => {
                  ZapierApi.sendContactForm(
                    formState.formFirstName,
                    formState.formLastName,
                    formState.formEmail,
                    'new-coach',
                  );
                });
              })
              .catch((err) => {
                if (err.response?.status === 400) {
                  setEmailExists(err.response.data.non_field_errors);
                }
              });
          }
          setValidated(true);
        }
      }
    },
    [registerAccount, privateLabelledSettings, coachSignUp],
  );

  return handleSubmit;
}
