import { useCallback } from 'react';

export function useHandleError({ setError, setErrorByConsole }) {
  const handleError = useCallback(
    (err) =>
      Object.keys(err).forEach((key) => {
        const errors = err[key];
        if (errors.length) {
          setError(key, { message: errors[0], type: 'remote' });
          console.log(key, { message: errors[0], type: 'remote' });
          setErrorByConsole({ key, message: errors[0] });
        }
      }),
    [setError, setErrorByConsole],
  );
  return handleError;
}
