import React, { useCallback, useEffect, useState } from 'react';
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import { Loader } from '../../../../common';
import useWindowSize from '../../../../common/useWindowResize';
import { wave } from './wave';

export default function AudioMessagePlayer({
  url,
  mine,
  messagePlaying,
  setMessagePlaying,
  recordingAudio,
}) {
  const windowSize = useWindowSize();
  let isMobile = windowSize.width < 768;

  const { togglePlayPause, playing, ready, loading } = useAudioPlayer({
    src: url,
    format: 'mp3',
    autoplay: false,
    onend: () => console.log('sound has ended!'),
  });

  const { percentComplete, duration, seek } = useAudioPosition({
    highRefreshRate: true,
  });

  const goToPosition = useCallback(
    (percentage) => {
      seek(duration * percentage);
    },
    [duration, seek],
  );

  const [audioContext, setAudioContext] = useState(null);
  const [barHeights, setBarHeights] = useState(wave);

  useEffect(() => {
    if (!window.AudioContext) {
      if (!window.webkitAudioContext) {
        return;
      }
      window.AudioContext = window.webkitAudioContext;
    }
    setAudioContext(new AudioContext());
  }, [setAudioContext]);

  useEffect(() => {
    // if message playing is changed, stop it if it's not the message playing
    if (playing && messagePlaying !== url) {
      togglePlayPause();
    }
  }, [messagePlaying, playing, togglePlayPause, url]);

  useEffect(() => {
    if (recordingAudio && playing) {
      togglePlayPause();
    }
  }, [recordingAudio, messagePlaying, setMessagePlaying]);

  useEffect(() => {
    if (audioContext) {
      const { log } = console;

      const filterData = (audioBuffer) => {
        const rawData = audioBuffer.getChannelData(0);
        const samples = 70;
        const blockSize = Math.floor(rawData.length / samples);
        return new Array(samples)
          .fill(0)
          .map((_, i) =>
            rawData
              .slice(i * blockSize, (i + 1) * blockSize)
              .reduce((sum, val) => sum + Math.abs(val), 0),
          );
      };

      const normalizeData = (filteredData) => {
        const multiplier = Math.max(...filteredData) ** -1;
        return filteredData.map((val) => val * multiplier);
      };

      const drawAudio = async (url) => {
        log(`Fetching data from ${url}...`);
        const response = await fetch(url);

        log('Getting array buffer...');
        const arrayBuffer = await response.arrayBuffer();
        log('Decoding audio data...');
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        log('Calculating...');
        const data = normalizeData(filterData(audioBuffer));
        setBarHeights(data);

        const averagedData = new Array(44).fill(0).map((_, i) => {
          const start = Math.floor((i * data.length) / 44);
          const end = Math.floor(((i + 1) * data.length) / 44);
          return (
            data.slice(start, end).reduce((sum, val) => sum + val, 0) /
            (end - start)
          );
        });
        setBarHeights(averagedData.map((val) => (val <= 0.3 ? 0.3 : val)));
        log('Done.');
      };

      if (url) {
        drawAudio(url).catch((err) => console.error(err));
      }
    }
  }, [audioContext, setBarHeights]);

  return (
    <div
      className="audio-message-player-container"
      style={{
        backgroundColor: !mine
          ? 'var(--primary-white)'
          : 'var(--primary-green)',
        ...(mine
          ? { borderTopRightRadius: '0' }
          : { borderTopLeftRadius: '0' }),
      }}
    >
      <div
        className="play-button"
        style={{
          gap: isMobile ? '0.5rem' : '1rem',
        }}
      >
        <button
          disabled={recordingAudio}
          onClick={
            !loading && ready
              ? () => {
                  togglePlayPause();
                  setMessagePlaying(url);
                }
              : () => {}
          }
          style={{
            ...(isMobile && { transform: 'scale(0.8)' }),
            backgroundColor: mine
              ? 'var(--primary-white)'
              : 'var(--primary-dark)',
            ...(mine
              ? { borderTopRightRadius: '0' }
              : { borderTopLeftRadius: '0' }),
          }}
        >
          {loading || (loading && !ready) ? (
            <Loader
              position="static"
              animation="border"
              className={`${mine ? 'dark-loader' : 'light-loader'}`}
            />
          ) : !playing ? (
            <svg
              width="17"
              height="19"
              viewBox="0 0 17 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.0385 7.77243C16.3615 8.54421 16.3615 10.4558 15.0384 11.2275L9 14.7498L3.00777 18.2454C1.67446 19.0232 -8.33202e-07 18.0615 -7.6573e-07 16.5179L-4.5897e-07 9.50004L-1.52203e-07 2.48205C-8.47317e-08 0.93848 1.6744 -0.0232639 3.00771 0.75447L9 4.24983L15.0385 7.77243Z"
                fill={mine ? 'var(--primary-dark)' : 'var(--primary-white)'}
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="20"
                height="20"
                rx="2"
                fill={mine ? 'var(--primary-dark)' : 'var(--primary-white)'}
              />
            </svg>
          )}
        </button>
      </div>

      <div className="bars-container">
        {barHeights.map((height, idx) => (
          <div
            key={idx}
            className="bar-unit"
            onClick={() => goToPosition((idx + 1) / 45)}
            style={{
              height: `${height * 2.5}rem`,
              marginRight: idx === 43 ? '0' : '1.85px',
              background:
                percentComplete < idx * 2.27
                  ? 'rgba(52, 52, 52, 0.31)'
                  : mine
                  ? 'var(--primary-white)'
                  : 'var(--primary-dark)',
            }}
          />
        ))}
      </div>
    </div>
  );
}
