import React, { useCallback } from 'react'
// import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Badge from 'react-bootstrap/Badge'

import { useFetchUpdates, useUpdateUnreadMessages } from './redux/hooks'
import { useFetchAuthToken } from '../home/redux/hooks'

import Icon from './components/Icon'

export const appSections = {
  home: 'home',
  messages: 'messages',
  notifications: 'notifications',
  menu: 'menu',
}

export default function BottomNav({ activeSection }) {
  const history = useHistory()
  const { updates } = useFetchUpdates()
  const { unreadMessagesCount } = useUpdateUnreadMessages()
  const { userApproved } = useFetchAuthToken()
  const menuItems = [
    {
      to: userApproved ? '/dashboard' : '/user-not-approved',
      section: appSections.home,
      icon: <Icon name="home" />,
      activeIcon: <Icon name="home" active={true} />,
    },
    {
      to: '/messages/default',
      section: appSections.messages,
      icon: <Icon name="message" />,
      activeIcon: <Icon name="message" active={true} />,
    },
    {
      to: '/notifications/default',
      section: appSections.notifications,
      icon: <Icon name="notification" />,
      activeIcon: <Icon name="notification" active={true} />,
    },
    {
      to: '/user',
      section: appSections.menu,
      icon: <Icon name="settings" />,
      activeIcon: <Icon name="settings" active={true} />,
    },
  ]

  const redirectTo = useCallback(
    mi => () => {
      if (
        mi.section === appSections.messages &&
        window.location.pathname === '/messages/default'
      ) {
        history.go(0)
      } else {
        history.push(mi.to)
      }
    },
    [history]
  )

  // in this pages we don't want to show the bottom nav
  switch (window.location.pathname) {
    case '/rpm/connect-your-device':
      return null
    case '/rpm/fitbit-connection':
      return null
    case '/rpm/fitbit-connection/3':
      return null
    case '/rpm/download-app':
      return null
    case '/rpm/signup/insurance-information':
      return null
    case '/rpm/signup/verify-your-address':
      return null
    case '/rpm/signup/about-yourself':
      return null
    case '/rpm/signup/confirm-password':
      return null
    case '/typeform':
      return null
    
  }

  if (window.location.pathname.includes('download')) return null
  if (window.location.pathname.includes('calendar')) return null

  return (
    <div className="common-bottom-nav d-lg-none">
      <Navbar fixed="bottom ">
        <Nav fill className="w-100" activeKey={activeSection}>
          {menuItems.map((mi, i) => (
            <Nav.Item key={i}>
              <Nav.Link eventKey={mi.section} onClick={redirectTo(mi)}>
                {activeSection === mi.section ? mi.activeIcon : mi.icon}
              </Nav.Link>
              {i === 1 && unreadMessagesCount > 0 && (
                <Badge pill variant="danger" className="transparent-badge">
                  {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                </Badge>
              )}
              {i === 2 && updates && updates.unread_notifications_count > 0 && (
                <Badge pill variant="danger">
                  {updates.unread_notifications_count > 9
                    ? '9+'
                    : updates.unread_notifications_count}
                </Badge>
              )}
            </Nav.Item>
          ))}
        </Nav>
      </Navbar>
    </div>
  )
}

BottomNav.propTypes = {}
BottomNav.defaultProps = {}
