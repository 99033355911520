import React, { Fragment } from 'react'
import { useFormikContext } from 'formik'

export default function AssignGroupsForm() {
    const { setFieldValue, values } = useFormikContext()

    const handleChange = group => {
        setFieldValue(
            'groups',
            values.groups.includes(group)
                ? [...values.groups.filter(g => g !== group)]
                : [...values.groups, group] || []
        )
    }

    return (
        <Fragment>
            <div className="form-group">
                <div className="checkbox-group">
                    <input
                        type="checkbox"
                        placeholder="User *"
                        onChange={() => handleChange('User')}
                        checked={values.groups.includes('User')}
                    />
                    <label htmlFor="user" onClick={() => handleChange('User')}>
                        User
                    </label>
                </div>
            </div>
            <div className="form-group">
                <div className="checkbox-group">
                    <input
                        type="checkbox"
                        placeholder="Coach *"
                        checked={values.groups.includes('Coach')}
                        onChange={() => {
                            if (values.groups.includes('Coach')) {
                                setFieldValue('create_upheal_account', false)
                            }
                            handleChange('Coach') 
                        }}
                    />
                    <label
                        htmlFor="coach"
                        onClick={() => handleChange('Coach')}
                    >
                        Coach
                    </label>
                </div>
            </div>
            <div className="form-group">
                <div className="checkbox-group">
                    <input
                        type="checkbox"
                        placeholder="Admin *"
                        checked={values.groups.includes('Admin')}
                        onChange={() => handleChange('Admin')}
                    />
                    <label
                        htmlFor="admin"
                        onClick={() => handleChange('Admin')}
                    >
                        Admin
                    </label>
                </div>
            </div>
        </Fragment>
    )
}
