import React, { useEffect } from 'react'
import useWindowSize from '../../../common/useWindowResize'
import { useClientProfile } from '../../hooks/client-profile/useClientProfile'
import ActivitySection from './ActivitySection'
import BillingSection from './BillingSection'
import ProfileDetailsSection from './ProfileDetailsSection'
import Sidebar from './Sidebar'
import TabSelector from './TabSelector'
import Header from './Header'
import ScheduleSection from './ScheduleSection'
import EventsSection from './EventsSection'

export default function ClientProfileBody() {
  const { user, fetchUser, userId } = useClientProfile()

  const tabOptions = [
    { name: 'Activity', value: 'activity' },
    { name: 'Billing', value: 'billing' },
    { name: 'Profile Details', value: 'profile_details' },
    { name: 'Schedule', value: 'schedule' },
  ]
  const [selectedOption, setSelectedOption] = React.useState(tabOptions[0])

  const initialValues = { page: 1, user_id: userId, resetResults: false }

  const [params, setParams] = React.useState(initialValues)
  const { isDesktop, isMobile } = useWindowSize()

  useEffect(() => {
    setParams(initialValues)
  }, [])

  if (!user) return null

  const isOnlyUser = user.groups.length === 1 && user.groups[0] === 'User'

  return (
    <div className="rpm-client-profile__container">
      {isMobile && isOnlyUser && <Header user={user} />}
      <Sidebar user={user} fetchUser={fetchUser} />
      <div className="rpm-client-profile__container__right">
        {isDesktop && isOnlyUser && <Header user={user} />}
        <div className="rpm-client-profile__container__right-body">
          <TabSelector
            options={tabOptions}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            user={user}
            params={params}
            setParams={setParams}
          />
          {selectedOption.value === 'activity' && (
            <ActivitySection
              params={params}
              userId={userId}
              setParams={setParams}
            />
          )}
          {selectedOption.value === 'billing' && (
            <BillingSection userId={userId} />
          )}

          {selectedOption.value === 'profile_details' && (
            <ProfileDetailsSection user={user} fetchUser={fetchUser} />
          )}

          {selectedOption.value === 'schedule' && userId && (
            <ScheduleSection userId={userId} />
          )}

          {selectedOption.value === 'events' && user && <EventsSection user={user} />}
        </div>
      </div>
    </div>
  )
}
