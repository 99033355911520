import { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    DASHBOARD_ADD_COACH_CALL_BEGIN,
    DASHBOARD_ADD_COACH_CALL_SUCCESS,
    DASHBOARD_ADD_COACH_CALL_FAILURE,
    DASHBOARD_ADD_COACH_CALL_DISMISS_ERROR,
} from './constants'

import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function addCoachCall(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: DASHBOARD_ADD_COACH_CALL_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.post(
                `${config.apiRootUrl}/upcoming-calls/` +
                    (args.user_id ? `?user_id=${args.user_id}` : ''),
                args,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: DASHBOARD_ADD_COACH_CALL_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: DASHBOARD_ADD_COACH_CALL_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissAddCoachCallError() {
    return {
        type: DASHBOARD_ADD_COACH_CALL_DISMISS_ERROR,
    }
}

export function useAddCoachCall() {
    const dispatch = useDispatch()

    const { addCoachCallPending, addCoachCallError } = useSelector(
        state => ({
            addCoachCallPending: state.dashboard.addCoachCallPending,
            addCoachCallError: state.dashboard.addCoachCallError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(addCoachCall(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissAddCoachCallError())
    }, [dispatch])

    return {
        addCoachCall: boundAction,
        addCoachCallPending,
        addCoachCallError,
        dismissAddCoachCallError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case DASHBOARD_ADD_COACH_CALL_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                addCoachCallPending: true,
                addCoachCallError: null,
            }

        case DASHBOARD_ADD_COACH_CALL_SUCCESS:
            // The request is success
            return {
                ...state,
                addCoachCallPending: false,
                addCoachCallError: null,
            }

        case DASHBOARD_ADD_COACH_CALL_FAILURE:
            // The request is failed
            return {
                ...state,
                addCoachCallPending: false,
                addCoachCallError: action.data.error,
            }

        case DASHBOARD_ADD_COACH_CALL_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                addCoachCallError: null,
            }

        default:
            return state
    }
}
