import React from 'react'
import { DesktopHeader } from '../common'
import Conversations from './components/Conversations'
import Messages from './components/Messages'
import ChatProvider from './ChatProvider'

export default function Default() {
    return (
        <ChatProvider>
            <div className="chat-default">
                <DesktopHeader />
                <main className="chat-container">
                    <Conversations />
                    <Messages />
                </main>
            </div>
        </ChatProvider>
    )
}

Default.propTypes = {}
Default.defaultProps = {}
