import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  HOME_REFRESH_STRIPE_ACCOUNT_LINK_BEGIN,
  HOME_REFRESH_STRIPE_ACCOUNT_LINK_SUCCESS,
  HOME_REFRESH_STRIPE_ACCOUNT_LINK_FAILURE,
  HOME_REFRESH_STRIPE_ACCOUNT_LINK_DISMISS_ERROR,
} from './constants';

import axios from 'axios';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import config from '../../../common/config';

export function refreshStripeAccountLink(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: HOME_REFRESH_STRIPE_ACCOUNT_LINK_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.apiRootUrl}/subscriptions/refresh-stripe-account-link`,
        args,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_REFRESH_STRIPE_ACCOUNT_LINK_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_REFRESH_STRIPE_ACCOUNT_LINK_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissRefreshStripeAccountLinkError() {
  return {
    type: HOME_REFRESH_STRIPE_ACCOUNT_LINK_DISMISS_ERROR,
  };
}

export function useRefreshStripeAccountLink() {
  const dispatch = useDispatch();

  const { refreshStripeAccountLinkPending, refreshStripeAccountLinkError } =
    useSelector(
      (state) => ({
        refreshStripeAccountLinkPending:
          state.home.refreshStripeAccountLinkPending,
        refreshStripeAccountLinkError: state.home.refreshStripeAccountLinkError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(refreshStripeAccountLink(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissRefreshStripeAccountLinkError());
  }, [dispatch]);

  return {
    refreshStripeAccountLink: boundAction,
    refreshStripeAccountLinkPending,
    refreshStripeAccountLinkError,
    dismissRefreshStripeAccountLinkError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_REFRESH_STRIPE_ACCOUNT_LINK_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        refreshStripeAccountLinkPending: true,
        refreshStripeAccountLinkError: null,
      };

    case HOME_REFRESH_STRIPE_ACCOUNT_LINK_SUCCESS:
      // The request is success
      return {
        ...state,
        refreshStripeAccountLinkPending: false,
        refreshStripeAccountLinkError: null,
      };

    case HOME_REFRESH_STRIPE_ACCOUNT_LINK_FAILURE:
      // The request is failed
      return {
        ...state,
        refreshStripeAccountLinkPending: false,
        refreshStripeAccountLinkError: action.data.error,
      };

    case HOME_REFRESH_STRIPE_ACCOUNT_LINK_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        refreshStripeAccountLinkError: null,
      };

    default:
      return state;
  }
}
