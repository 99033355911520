import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_GET_PROFILE_SUMMARY_BEGIN,
  RPM_GET_PROFILE_SUMMARY_SUCCESS,
  RPM_GET_PROFILE_SUMMARY_FAILURE,
  RPM_GET_PROFILE_SUMMARY_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'


export function getProfileSummary(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_GET_PROFILE_SUMMARY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      const doRequest = axios.get(`${config.apiRootUrl}/rpm/clients/profile-summary`, {
          params: args,
          ...createAxiosConfigWithAuth(getState()),
      })
      
      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_GET_PROFILE_SUMMARY_SUCCESS,
            data: res.data,
          });
          resolve(res.data);  
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_GET_PROFILE_SUMMARY_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetProfileSummaryError() {
  return {
    type: RPM_GET_PROFILE_SUMMARY_DISMISS_ERROR,
  };
}

export function useGetProfileSummary() {
  const dispatch = useDispatch();

  const { getProfileSummaryPending, getProfileSummaryError, profileSummary } = useSelector(
    state => ({
      getProfileSummaryPending: state.rpm.getProfileSummaryPending,
      getProfileSummaryError: state.rpm.getProfileSummaryError,
      profileSummary: state.rpm.profileSummary,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getProfileSummary(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetProfileSummaryError());
  }, [dispatch]);

  return {
    profileSummary,
    getProfileSummary: boundAction,
    getProfileSummaryPending,
    getProfileSummaryError,
    dismissGetProfileSummaryError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_GET_PROFILE_SUMMARY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getProfileSummaryPending: true,
        getProfileSummaryError: null,
      };

    case RPM_GET_PROFILE_SUMMARY_SUCCESS:
      // The request is success
      return {
        ...state,
        getProfileSummaryPending: false,
        getProfileSummaryError: null,
        profileSummary: action.data,
      };

    case RPM_GET_PROFILE_SUMMARY_FAILURE:
      // The request is failed
      return {
        ...state,
        getProfileSummaryPending: false,
        getProfileSummaryError: action.data.error,
      };

    case RPM_GET_PROFILE_SUMMARY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getProfileSummaryError: null,
      };

    default:
      return state;
  }
}
