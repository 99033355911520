import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_GET_CPT_LIST_BEGIN,
    RPM_GET_CPT_LIST_SUCCESS,
    RPM_GET_CPT_LIST_FAILURE,
    RPM_GET_CPT_LIST_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function getCptList(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_GET_CPT_LIST_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
        const doRequest = axios.get(`${config.apiRootUrl}/rpm/cpt/`, {
                params: args,
                ...createAxiosConfigWithAuth(getState()),
            })

            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_GET_CPT_LIST_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_GET_CPT_LIST_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissGetCptListError() {
    return {
        type: RPM_GET_CPT_LIST_DISMISS_ERROR,
    }
}

export function useGetCptList() {
    const dispatch = useDispatch()

    const { getCptListPending, getCptListError, cptList } = useSelector(
        state => ({
            getCptListPending: state.rpm.getCptListPending,
            getCptListError: state.rpm.getCptListError,
            cptList: state.rpm.cptList,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(getCptList(...args))
        },
        [dispatch]
    )

    useEffect(() => {
        if (!cptList) {
            boundAction()
        }
    }, [boundAction, cptList])

    const boundDismissError = useCallback(() => {
        return dispatch(dismissGetCptListError())
    }, [dispatch])

    return {
        cptList,
        getCptList: boundAction,
        getCptListPending,
        getCptListError,
        dismissGetCptListError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_GET_CPT_LIST_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                getCptListPending: true,
                getCptListError: null,
            }

        case RPM_GET_CPT_LIST_SUCCESS:
            // The request is success
            return {
                ...state,
                getCptListPending: false,
                getCptListError: null,
                cptList: action.data,
            }

        case RPM_GET_CPT_LIST_FAILURE:
            // The request is failed
            return {
                ...state,
                getCptListPending: false,
                getCptListError: action.data.error,
            }

        case RPM_GET_CPT_LIST_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                getCptListError: null,
            }

        default:
            return state
    }
}
