import React from 'react';
import Button from 'react-bootstrap/Button';

const Btn = ({ variant, className, size, children }) => {
  return (
    <Button variant={variant} className={className} size={size}>
      {children}
    </Button>
  );
};

export default Btn;

Btn.propTypes = {};
Btn.defaultProps = {};
