import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AudioPlayerProvider } from 'react-use-audio-player';
import { useUnauthorizedErrorHandler } from '../../../../../common/apiHelpers';
import Icon from '../../../../common/components/Icon';
import { useFetchUser } from '../../../../user/redux/fetchUser';
import useOnSendGroupMessage from '../../../hooks/useOnSendGroupMessage';
import record from '../../../images/record.svg';
import stopRecordingImage from '../../../images/stopRecording.svg';

import useOnSendPeerMessage from '../../../hooks/useOnSendPeerMessage';
import useUploadMediaFile from '../../../hooks/useUploadMediaFile';
import { useCreateMediaMessage } from '../../../redux/createMediaMessage';
import {
  useCreateGroupChatMessage,
  useCreatePeerToPeerMessage,
} from '../../../redux/hooks';
import { useUploadMedia } from '../../../redux/uploadMedia';
import { AudioPlayingContext } from '../../contexts/AudioPlayingProvider';
import AudioPlayerSend from '../audio/AudioPlayerSend';

export default function SendMessage({
  selectedConversation,
  selectedMessages,
  setSelectedMessages,
}) {
  const {
    setRecordingAudio,
    recordingAudio,
    uploadedFile,
    setUploadedFile,
    recordingState,
  } = useContext(AudioPlayingContext);

  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();

  const { createGroupChatMessage } = useCreateGroupChatMessage();

  const { createPeerToPeerMessage } = useCreatePeerToPeerMessage();

  const { user } = useFetchUser();

  const { uploadMedia } = useUploadMedia();
  const { createMediaMessage } = useCreateMediaMessage();

  // message input
  const [message, setMessage] = useState('');

  // file upload
  const fileRef = useRef();

  const handleChange = (e) => {
    const [file] = e.target.files;
    // file must be image type
    console.log('file', file);
    if (file.type.startsWith('image')) {
      setUploadedFile(file);
    } else {
      alert('Please upload an image');
    }
  };

  const {
    startRecording,
    stopRecording,
    insideMediaBlobUrl,
    clearBlobUrl,
    status,
    mediaBlobUrl,
    usingBruteForce,
    bruteForceStopRecording,
    setUsingBruteForce,
    setMediaBlobUrl,
    minutes,
    seconds,
    reset,
    isRunning,
  } = recordingState;

  // do not ask for permission

  React.useEffect(() => {
    if (usingBruteForce) {
      setUsingBruteForce(false);
      return;
    }

    if (insideMediaBlobUrl) {
      setMediaBlobUrl(insideMediaBlobUrl);
      return;
    }

    if (mediaBlobUrl) {
      setMediaBlobUrl(null);
      return;
    }
  }, [insideMediaBlobUrl]);

  useEffect(() => {
    if (recordingAudio === false && status === 'recording') {
      bruteForceStopRecording();
    }
  }, [recordingAudio]);

  useEffect(() => {
    if (mediaBlobUrl) {
      console.log('mediaBlobUrl', mediaBlobUrl);
      let date = new Date();

      const getFile = async (blobUrl) => {
        const file = await fetch(blobUrl)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File(
                [blobFile],
                `${date.getFullYear()}-${
                  date.getMonth() + 1
                }-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.wav`,
                { type: 'audio/wav' },
              ),
          )
          .catch((err) => {
            console.log('err', err);
          });
        setUploadedFile(file);
      };

      getFile(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  const { uploadMediaFile } = useUploadMediaFile({
    selectedConversation,
    selectedMessages,
    setSelectedMessages,
    setUploadedFile,
    createMediaMessage,
    uploadMedia,
    clearBlobUrl,
    mediaBlobUrl,
    uploadedFile,
    user,
  });

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (message || uploadedFile) {
        if (selectedConversation.type === 'group') {
          if (uploadedFile) {
            uploadMediaFile();
          } else {
            onSendGroupMessage(message);
          }
        } else {
          if (uploadedFile) {
            uploadMediaFile();
          } else {
            onSendPeerMessage(message);
          }
        }
        setMessage('');
      }
    },
    [
      onSendPeerMessage,
      onSendGroupMessage,
      message,
      uploadedFile,
      uploadMediaFile,
    ],
  );

  const { onSendPeerMessage } = useOnSendPeerMessage({
    selectedConversation,
    message,
    setSelectedMessages,
    unauthorizedErrorHandler,
    createPeerToPeerMessage,
    user,
  });

  const { onSendGroupMessage } = useOnSendGroupMessage({
    createGroupChatMessage,
    setSelectedMessages,
    selectedMessages,
    user,
    selectedConversation,
    message,
  });

  const inputRef = useRef(null);

  useEffect(() => {
    // AUTO RESIZE TEXTAREA
    const autoResize = () => {
      const textarea = inputRef.current;

      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight - 10}px`;
    };

    autoResize();
  }, [message]);

  return (
    <Fragment>
      {uploadedFile && uploadedFile?.type?.startsWith('image') && (
        <Fragment>
          <img
            src={URL.createObjectURL(uploadedFile)}
            alt="recording"
            style={{ width: '100%', height: '7rem', objectFit: 'contain' }}
          />

          <div className="flex items-center gap-1_5">
            <button
              onClick={() => {
                setUploadedFile(null);
              }}
            >
              <Icon name="delete" size={21} color="#7D7D7D" />
            </button>

            <button onClick={handleSubmit}>
              <Icon name="send" size={19} color="#7D7D7D" />
            </button>
          </div>
        </Fragment>
      )}

      {/*     {!isRunning && !mediaBlobUrl && !uploadedFile && (
        <div
          className="flex flex-col items-center justify-center cursor-pointer"
          onClick={() => {
            setRecordingAudio(true);
            reset();
            startRecording();
          }}
        >
          <img src={record} alt="record" />
          <p
            style={{
              fontSize: '0.8rem',
              fontWeight: '300',
              paddingTop: '0.5rem',
            }}
          >
            Tap to record audio
          </p>
        </div>
      )} */}

      {!isRunning && !uploadedFile && !mediaBlobUrl && (
        <div className="input-container">
          <input type="file" onChange={handleChange} ref={fileRef} hidden />{' '}
          <div className="input-wrapper">
            <textarea
              style={{
                resize: 'none',
                borderRadius:
                  inputRef.current?.scrollHeight > 50 && message.length > 0
                    ? '1rem'
                    : '5rem',
              }}
              ref={inputRef}
              type="text"
              placeholder="Type a message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              
            />
            <button onClick={handleSubmit}>
              <Icon name="send" size={20} color="var(--primary-dark)" />
            </button>
          </div>
          {/* <button
            onClick={() => {
              fileRef.current.click();
            }}
          >
            <Icon name="attachment" size={19} />
          </button> */}
        </div>
      )}

      {mediaBlobUrl && isRunning === false && (
        <AudioPlayerProvider>
          <AudioPlayerSend
            url={mediaBlobUrl}
            clearBlobUrl={clearBlobUrl}
            setUploadedFile={setUploadedFile}
            handleSubmit={handleSubmit}
          />
        </AudioPlayerProvider>
      )}

      {isRunning && status === 'recording' && (
        <div className="flex flex-col items-center gap-1 w-full">
          <p
            style={{
              fontSize: '0.8rem',
              fontWeight: '300',
            }}
          >
            Listening
          </p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              placeContent: 'center',
              placeItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '14px',
                color: 'var(--primary-dark)',
              }}
            >
              {minutes < 1 ? '00' : minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
            <button
              onClick={() => {
                setRecordingAudio(false);
                stopRecording();
              }}
            >
              <img src={stopRecordingImage} alt="stop" />
            </button>

            <button
              onClick={() => {
                setRecordingAudio(false);
                stopRecording();
              }}
            >
              <Icon name="close" size={25} color="var(--primary-dark)" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
}
