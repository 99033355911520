import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import config from '../../../common/config';
import {
  RPM_LIST_HRV_BEGIN, RPM_LIST_HRV_DISMISS_ERROR, RPM_LIST_HRV_FAILURE, RPM_LIST_HRV_SUCCESS
} from './constants';


import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
export function listHrv(args = {
  page: 1,
}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_LIST_HRV_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      const doRequest = axios.get(
        `${config.apiRootUrl}/rpm/list-hrv/`,
        {
          params: args,
          ...createAxiosConfigWithAuth(getState()),
        },
      );

      doRequest.then(
        (res) => {
          console.log('list hrv res', res.data);
          dispatch({
            type: RPM_LIST_HRV_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_LIST_HRV_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissListHrvError() {
  return {
    type: RPM_LIST_HRV_DISMISS_ERROR,
  };
}

export function useListHrv() {
  const dispatch = useDispatch();

  const { listHrvPending, listHrvError } = useSelector(
    state => ({
      listHrvPending: state.rpm.listHrvPending,
      listHrvError: state.rpm.listHrvError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(listHrv(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissListHrvError());
  }, [dispatch]);

  useEffect(() => {
    boundAction();
  }, [boundAction]);


  return {
    listHrv: boundAction,
    listHrvPending,
    listHrvError,
    dismissListHrvError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_LIST_HRV_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        listHrvPending: true,
        listHrvError: null,
      };

    case RPM_LIST_HRV_SUCCESS:
      // The request is success
      return {
        ...state,
        listHrvPending: false,
        listHrvError: null,
      };

    case RPM_LIST_HRV_FAILURE:
      // The request is failed
      return {
        ...state,
        listHrvPending: false,
        listHrvError: action.data.error,
      };

    case RPM_LIST_HRV_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        listHrvError: null,
      };

    default:
      return state;
  }
}
