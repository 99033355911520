import React, { useEffect } from 'react'
// import PropTypes from 'prop-types';
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { DesktopHeader, Loader } from '../common'
import Icon from '../common/components/Icon'
import { useFetchUser } from '../user/redux/fetchUser'
import { useUserAgreement } from '../user/redux/userAgreement'

export default function TermsAndConditions() {
  const { user } = useFetchUser()
  const [loading, setLoading] = React.useState(false)
  const { userAgreement } = useUserAgreement()

  const [policies, setPolicies] = React.useState({
    consent_documents: {
      // it is like accepting policy and terms
      title: 'Consent Documents',
      link: 'https://www.noble.health/consent',
      active: (user?.privacy_policy && user?.terms_and_conditions) || false,
      key: 'consent_documents',
    },
  })

  const Item = ({ item }) => {
    const onItemClick = () => {
      if (item.key === 'consent_documents') {
        setPolicies({
          ...policies,
          consent_documents: {
            ...policies.consent_documents,
            active: !policies.consent_documents.active,
          },
        })
      }

      if (item.key === 'business') {
        setPolicies({
          ...policies,
          business: {
            ...policies.business,
            active: !policies.business.active,
          },
        })
      }
    }
    return (
      <div className="item">
        <div onClick={onItemClick} style={{ paddingRight: '1rem' }}>
          <Icon
            name="checkradio"
            color="#343434"
            active={item.active}
            size={20}
          />
        </div>
        <div onClick={onItemClick}>
          <p className="item">{item.title}</p>
        </div>
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLink} className="link-icon" />
        </a>
      </div>
    )
  }

  const handleClick = async () => {
    setLoading(true)
    userAgreement({
      privacy_policy: policies.consent_documents.active,
      terms_and_conditions: policies.consent_documents.active,
      dac: policies.consent_documents.active,
    })
      .then(res => {
        window.location.href = '/'
      })
      .catch(() => {
        setLoading(false)
        console.log('error in userAgreement')
      })
  }

  const [clientUser, setClientUser] = React.useState(null)

  useEffect(() => {
    if (clientUser) return

    if (user) {
      setClientUser(user)
      setPolicies({
        ...policies,
        consent_documents: {
          ...policies.consent_documents,
          active: (user.privacy_policy && user.terms_and_conditions) || false,
        },
      })
    }
  }, [user])

  if (!clientUser) return null

  if (
    !clientUser.privacy_policy ||
    !clientUser.terms_and_conditions ||
    (!clientUser.dac && clientUser.groups.includes('Coach'))
  )
    return (
      <div className="auth-terms-and-conditions-page">
        <DesktopHeader />
        <div className="auth-terms-and-conditions-container">
          <div className="header">
            <div className="title">Terms and Conditions</div>
            <p> We've updated our Terms and Conditions. Review them below. </p>
          </div>
          <div className="body">
            {(!clientUser.privacy_policy ||
              !clientUser.terms_and_conditions ||
              (!clientUser.dac && clientUser.groups.includes('Coach'))) && (
              <Item item={policies.consent_documents} />
            )}

            <button
              className="terms-button"
              disabled={
                (!clientUser.privacy_policy &&
                  !policies.consent_documents.active) ||
                (!clientUser.terms_and_conditions &&
                  !policies.consent_documents.active) ||
                (!clientUser.dac &&
                  !policies.consent_documents.active &&
                  clientUser.groups.includes('Coach'))
              }
              onClick={handleClick}
            >
              {loading ? <Loader /> : 'Accept Terms and Conditions'}
            </button>
          </div>
        </div>
      </div>
    )

  return null
}

TermsAndConditions.propTypes = {}
TermsAndConditions.defaultProps = {}
