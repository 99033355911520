export const dropdown = {
  profile: 'Perfil',
  switch_company: 'Cambiar de compañía',
  logout: 'Cerrar sesión',
  assessments: 'Evaluaciones',
  manage_subscriptions: 'Administrar suscripciones',
  internal_subscriptions: 'Suscripciones internas',
  contracts: 'Contratos',
  accounts: 'Cuentas',
  onboarding: 'Onboarding',
  roadmaps: 'Roadmaps',
  analytics: 'Analytics',
  help: 'Ayuda',
  app_information: 'Información de la aplicación',
  language: 'Idioma',
  notifications: 'Notificaciones',
  campaigns: 'Campañas',
};
