import React from 'react';

export default function HelpIcon({ size = 19, color = '#343434' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9306 18.5278H1.59722C1.17361 18.5278 0.767356 18.3595 0.467819 18.06C0.168282 17.7604 0 17.3542 0 16.9306V1.59722C0 1.17361 0.168282 0.767356 0.467819 0.467819C0.767356 0.168282 1.17361 0 1.59722 0H16.9306C17.3542 0 17.7604 0.168282 18.06 0.467819C18.3595 0.767356 18.5278 1.17361 18.5278 1.59722V16.9306C18.5278 17.3542 18.3595 17.7604 18.06 18.06C17.7604 18.3595 17.3542 18.5278 16.9306 18.5278ZM1.59722 1.27778C1.55454 1.27495 1.51174 1.28128 1.4717 1.29634C1.43166 1.31139 1.39533 1.33482 1.36509 1.36507C1.33484 1.39531 1.31139 1.43168 1.29634 1.47172C1.28128 1.51175 1.27495 1.55454 1.27778 1.59722V16.9306C1.27495 16.9732 1.28128 17.016 1.29634 17.0561C1.31139 17.0961 1.33484 17.1325 1.36509 17.1627C1.39533 17.193 1.43166 17.2164 1.4717 17.2314C1.51174 17.2465 1.55454 17.2528 1.59722 17.25H16.9306C16.9732 17.2528 17.016 17.2465 17.0561 17.2314C17.0961 17.2164 17.1324 17.193 17.1627 17.1627C17.1929 17.1325 17.2164 17.0961 17.2314 17.0561C17.2465 17.016 17.2528 16.9732 17.25 16.9306V1.59722C17.2528 1.55454 17.2465 1.51175 17.2314 1.47172C17.2164 1.43168 17.1929 1.39531 17.1627 1.36507C17.1324 1.33482 17.0961 1.31139 17.0561 1.29634C17.016 1.28128 16.9732 1.27495 16.9306 1.27778H1.59722Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.10414 6.7105C8.94619 6.7105 8.79179 6.66367 8.66046 6.57591C8.52913 6.48816 8.42677 6.36343 8.36632 6.2175C8.30588 6.07158 8.29008 5.91101 8.32089 5.75609C8.35171 5.60117 8.42773 5.45888 8.53942 5.34719C8.65111 5.2355 8.79344 5.15944 8.94836 5.12863C9.10327 5.09781 9.26381 5.11363 9.40974 5.17407C9.55567 5.23452 9.68039 5.33688 9.76814 5.46821C9.85589 5.59954 9.90275 5.75394 9.90275 5.91189C9.9036 6.01701 9.88355 6.12123 9.84371 6.21851C9.80388 6.31578 9.74506 6.40417 9.67073 6.4785C9.5964 6.55282 9.50803 6.61161 9.41076 6.65145C9.31348 6.69128 9.20925 6.71136 9.10414 6.7105V6.7105Z"
        fill={color}
      />
      <path
        d="M10.5417 13.1001H9.90278V8.30848C9.90278 7.95709 9.61528 7.98904 9.2639 7.98904H8.62501C8.27362 7.98904 7.98612 8.11682 7.98612 8.4682C7.98612 8.81959 8.30556 8.94737 8.62501 8.94737V13.1001H7.98612C7.81667 13.1001 7.65418 13.1675 7.53436 13.2873C7.41455 13.4071 7.34723 13.5696 7.34723 13.739C7.34723 13.9085 7.41455 14.071 7.53436 14.1908C7.65418 14.3106 7.81667 14.3779 7.98612 14.3779H10.5417C10.7111 14.3779 10.8736 14.3106 10.9934 14.1908C11.1132 14.071 11.1806 13.9085 11.1806 13.739C11.1806 13.5696 11.1132 13.4071 10.9934 13.2873C10.8736 13.1675 10.7111 13.1001 10.5417 13.1001Z"
        fill={color}
      />
    </svg>
  );
}
