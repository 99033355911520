import initialState from './initialState';
import { reducer as fetchAuthTokenReducer } from './fetchAuthToken';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as confirmResetPasswordReducer } from './confirmResetPassword';
import { reducer as registerAccountReducer } from './registerAccount';
import { reducer as unsubscribeUserReducer } from './unsubscribeUser';
import { reducer as acceptCoachInvitationReducer } from './acceptCoachInvitation';
import { reducer as fetchCoachInvitationReducer } from './fetchCoachInvitation';
import { reducer as paymentCheckoutReducer } from './paymentCheckout';
import { reducer as nobleCoachInvitationReducer } from './nobleCoachInvitation';
import { reducer as createStripeAccountLinkReducer } from './createStripeAccountLink';
import { reducer as refreshStripeAccountLinkReducer } from './refreshStripeAccountLink';
import { reducer as checkStatusStripeAccountLinkReducer } from './checkStatusStripeAccountLink';
import { reducer as getInvoicesStripeReducer } from './getInvoicesStripe';
import { reducer as getActiveSubscriptionReducer } from './getActiveSubscription';
import { reducer as createProductSubscriptionReducer } from './createProductSubscription';
import { reducer as createPriceSubscriptionReducer } from './createPriceSubscription';
import { reducer as getListProductSubscriptionReducer } from './getListProductSubscription';

const reducers = [
  fetchAuthTokenReducer,
  resetPasswordReducer,
  confirmResetPasswordReducer,
  registerAccountReducer,
  unsubscribeUserReducer,
  acceptCoachInvitationReducer,
  fetchCoachInvitationReducer,
  paymentCheckoutReducer,
  nobleCoachInvitationReducer,
  createStripeAccountLinkReducer,
  refreshStripeAccountLinkReducer,
  checkStatusStripeAccountLinkReducer,
  getInvoicesStripeReducer,
  getActiveSubscriptionReducer,
  createProductSubscriptionReducer,
  createPriceSubscriptionReducer,
  getListProductSubscriptionReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
