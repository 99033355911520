import React, { Fragment, useCallback, useContext, useRef } from 'react';
import useOutsideAlerter from '../../../../../common/useOutsideAlerter';
import BottomSheet from '../../../../common/BottomSheet';
import Icon from '../../../../common/components/Icon';
import useWindowSize from '../../../../common/useWindowResize';
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage';
import { ClientsCardContext } from '../ClientsCard';
import { useSortByOptions } from './useSortByOptions';

export default function SortToast() {
  const {
    assignedUsersState,
    setAssignedUsersState,
    showSortToast,
    setShowSortToast,
  } = useContext(ClientsCardContext);

  const { sortByOptions } = useSortByOptions();

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const sortBy = useCallback(
    (field) => {
      if (assignedUsersState.sortBy === field) {
        field = '';
      }
      setAssignedUsersState({
        ...assignedUsersState,
        sortBy: field,
      });

      setShowSortToast(false);
    },
    [
      assignedUsersState,
      setAssignedUsersState,
      setShowSortToast,
      showSortToast,
    ],
  );

  const closeSort = useCallback(() => {
    setShowSortToast(false);
  });

  const sortToastRef = useRef(null);
  useOutsideAlerter(sortToastRef, closeSort); // when clicked outside of the sortToast, close it

  return (
    <Fragment>
      <div className="sort-wrapper" onClick={() => setShowSortToast(true)}>
        <Icon name="sort" />
        <span className="sort-text">{t('dashboard.clients.filters.sort')}</span>
        {showSortToast && width > 768 && (
          <div className="sort-toast" ref={sortToastRef}>
            <p className="title">
              {t('dashboard.clients.filters.sort_by').toUpperCase()}
            </p>
            {sortByOptions.map((option) => (
              <Fragment key={option.field}>
                <div
                  className={`sort-toast-item`}
                  onClick={() => sortBy(option.field)}
                >
                  <Icon
                    name="radio-input"
                    active={option.field === assignedUsersState.sortBy}
                  />
                  <span>{option.label}</span>
                </div>
                {
                  // if not last item
                  option.field !==
                    sortByOptions[sortByOptions.length - 1].field && <hr />
                }
              </Fragment>
            ))}
          </div>
        )}
      </div>

      {showSortToast && width <= 768 && (
        <BottomSheet
          className="sort-toast"
          onClose={closeSort}
          style={{
            bottom: 0,
            top: 'auto',
            position: 'fixed',
            zIndex: 9999999,
            width: '100%',
            overflow: 'hidden',
            overscrollBehavior: 'contain',
          }}
        >
          <div
            style={{
              overflow: 'auto',
              height: '100%',
              width: '100%',
              padding: '1rem 0',
              overscrollBehavior: 'contain',
            }}
          >
            <p className="title">
              {t('dashboard.clients.filters.sort_by').toUpperCase()}
            </p>
            {sortByOptions.map((option) => (
              <Fragment key={option.field}>
                <div
                  className={`sort-toast-item`}
                  onClick={() => sortBy(option.field)}
                >
                  <Icon
                    name="radio-input"
                    active={option.field === assignedUsersState.sortBy}
                  />
                  <span>{option.label}</span>
                </div>
                {
                  // if not last item
                  option.field !==
                    sortByOptions[sortByOptions.length - 1].field && <hr />
                }
              </Fragment>
            ))}
          </div>
        </BottomSheet>
      )}
    </Fragment>
  );
}
