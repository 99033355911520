import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useWindowSize from '../../../common/useWindowResize'
import AvatarBackground from '../../assets/provider-dashboard/avatar-background.png'
import { getEligibilityColor } from '../../utils/getEligibilityColor'
import AlertsModal from './AlertsModal'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function MobileRecords({
    clients,
    refetchClients,
    hasMore,
    fetchMoreUsers,
}) {
    const { isDesktop } = useWindowSize()

    const [showAlertsModal, setShowAlertsModal] = useState(false)
    const history = useHistory()

    const { t } = useTranslation()

    if (isDesktop) return null

    return (
        <div className="rpm-provider-records">
            <InfiniteScroll
                className="w-full"
                pageStart={0}
                loadMore={fetchMoreUsers}
                hasMore={hasMore}
                loader={<div className="user-card-skeleton skeleton w-full" />}
                useWindow={false}
                initialLoad={false}
            >
                {clients &&
                    clients.map(
                        ({
                            id,
                            first_name,
                            last_name,
                            photo,
                            eligibility,
                            engagement,
                            eligibility_period,
                            alerts,
                            current_roadmap,
                            touch_points,
                            icd_code,
                        }) => (
                            <div
                                className="rpm-provider-mobile-records"
                                key={id}
                            >
                                <AlertsModal
                                    show={showAlertsModal == id}
                                    onHide={() => {
                                        refetchClients().then(() => {
                                            setShowAlertsModal(false)
                                        })
                                    }}
                                    alerts={alerts}
                                    user={
                                        clients.find(
                                            client => client.id === id
                                        ) || {}
                                    }
                                />
                                <div className="rpm-provider-mobile-record">
                                    <div
                                        className="rpm-provider-mobile-record__alert-header"
                                        style={{
                                            ...((!alerts ||
                                                alerts.length === 0) && {
                                                backgroundColor: '#F7F5F3',
                                            }),
                                        }}
                                    >
                                        {alerts && alerts.length > 0 ? (
                                            <Fragment>
                                                <div className="rpm-provider-mobile-record__alert-header__left">
                                                    <div className="rpm-provider-mobile-record__alert-header__left__alert-icon-container">
                                                        <div className="rpm-provider-mobile-record__alert-header__left__alert-icon-container__square" />
                                                        <p className="rpm-provider-mobile-record__alert-header__left__alert-icon-container__icon">
                                                            !
                                                        </p>
                                                    </div>
                                                    <p className="rpm-provider-mobile-record__alert-header__left__alert-text">
                                                        {alerts.length}{' '}
                                                        {t(
                                                            'coach_dashboard.alerts'
                                                        )}
                                                    </p>
                                                </div>
                                                <div
                                                    className="rpm-provider-mobile-record__alert-header__right"
                                                    onClick={() => {
                                                        setShowAlertsModal(id)
                                                    }}
                                                >
                                                    {t(
                                                        'coach_dashboard.view_all'
                                                    )}
                                                </div>
                                            </Fragment>
                                        ) : (
                                            // No alerts
                                            <p className="rpm-provider-mobile-record__alert-header__no-alerts">
                                                {t('coach_dashboard.no_alerts')}
                                            </p>
                                        )}
                                    </div>
                                    <div className="rpm-provider-mobile-record__alert-body">
                                        <div className="rpm-provider-mobile-record__alert-body__header">
                                            <div
                                                className="rpm-provider-mobile-record__alert-body__header__profile-container"
                                                onClick={() =>
                                                    (window.location.href = `/rpm/client-profile/${id}`)
                                                }
                                                role="button"
                                            >
                                                <div className="rpm-provider-mobile-record__alert-body__header__profile-container__profile-image">
                                                    <img
                                                        src={
                                                            photo ||
                                                            AvatarBackground
                                                        }
                                                        alt="profile"
                                                        style={{
                                                            border: photo
                                                                ? '2px solid #c4c4c4'
                                                                : 0,
                                                            borderRadius: photo
                                                                ? '0.35rem 0.35rem 0rem 0.35rem'
                                                                : 0,
                                                        }}
                                                    />
                                                    {!photo && (
                                                        <div className="rpm-provider-mobile-record__alert-body__header__profile-container__profile-image__initials">
                                                            {first_name[0]}
                                                            {last_name[0]}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="rpm-provider-mobile-record__alert-body__header__profile-container__details">
                                                    {/*  <span className="rpm-provider-mobile-record__alert-body__header__profile-container__details__tag">
                                                    NEW
                                                </span> */}
                                                    <span className="rpm-provider-mobile-record__alert-body__header__profile-container__details__name">
                                                        {first_name} {last_name}
                                                    </span>
                                                    <span className="rpm-provider-mobile-record__alert-body__header__profile-container__details__diagnosis">
                                                        {t(
                                                            'coach_dashboard.diagnosis'
                                                        )}
                                                        :{' '}
                                                        {icd_code
                                                            ? icd_code
                                                            : '-'}
                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                className="rpm-provider-mobile-record__alert-body__header__status-container"
                                                style={{
                                                    borderColor: getEligibilityColor(
                                                        {
                                                            eligibility,
                                                        }
                                                    ),
                                                }}
                                            >
                                                <div
                                                    className="rpm-provider-mobile-record__alert-body__header__status-container__dot"
                                                    style={{
                                                        backgroundColor: getEligibilityColor(
                                                            {
                                                                eligibility,
                                                            }
                                                        ),
                                                    }}
                                                />
                                                <p
                                                    className="rpm-provider-mobile-record__alert-body__header__status-container__text"
                                                    style={{
                                                        color: getEligibilityColor(
                                                            {
                                                                eligibility,
                                                            }
                                                        ),
                                                    }}
                                                >
                                                    {(eligibility &&
                                                        t(
                                                            `coach_dashboard.${eligibility.toLowerCase()}`
                                                        )) ||
                                                        t(
                                                            'coach_dashboard.pending'
                                                        )}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="rpm-provider-mobile-record__alert-body__measurements-container">
                                            <div className="rpm-provider-mobile-record__alert-body__measurements-container__item">
                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__label">
                                                    {t(
                                                        'coach_dashboard.engagement'
                                                    )}
                                                </p>

                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__value">
                                                    {engagement || 0} / 16
                                                </p>
                                            </div>

                                            <div className="rpm-provider-mobile-record__alert-body__measurements-container__item">
                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__label">
                                                    {t(
                                                        'coach_dashboard.eligibility_period'
                                                    )}
                                                </p>

                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__value">
                                                    {eligibility_period ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                            >
                                                                {eligibility_period
                                                                    ? eligibility_period.days_left
                                                                    : 0}{' '}
                                                                {t(
                                                                    'coach_dashboard.days_left'
                                                                )}
                                                            </span>{' '}
                                                            {t(
                                                                'coach_dashboard.ends'
                                                            )}{' '}
                                                            {eligibility_period &&
                                                                eligibility_period.date_end}
                                                        </>
                                                    ) : (
                                                        t(
                                                            'coach_dashboard.not_available'
                                                        )
                                                    )}
                                                </p>
                                            </div>
                                            <div className="rpm-provider-mobile-record__alert-body__measurements-container__item">
                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__label">
                                                    {t(
                                                        'coach_dashboard.touch_points'
                                                    )}
                                                </p>

                                                <p className="rpm-provider-mobile-record__alert-body__measurements-container__item__value">
                                                    {touch_points.point || 0} of{' '}
                                                    {touch_points.of || 0}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {current_roadmap && (
                                        <div
                                            className="rpm-provider-mobile-record-footer"
                                            role="button"
                                            onClick={() => {
                                                history.push(
                                                    `/roadmap/${current_roadmap.id}/?user=${id}`
                                                )
                                            }}
                                        >
                                            <p>
                                                <strong>
                                                    {current_roadmap.title}{' '}
                                                    {' > '}
                                                </strong>{' '}
                                                <br />
                                                {
                                                    current_roadmap.competency_title
                                                }
                                            </p>

                                            <div className="rpm-provider-record-footer-progress">
                                                <div className="rpm-provider-record-footer-progress-bar">
                                                    <div
                                                        className="rpm-provider-record-footer-progress-bar-fill"
                                                        style={{
                                                            width: `${current_roadmap.progress}%`,
                                                        }}
                                                    />
                                                </div>
                                                <p>
                                                    {current_roadmap.progress}%
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    )}
            </InfiniteScroll>
        </div>
    )
}
