import React from 'react'
import { DesktopHeader } from '../common'
import Calls from './components/coach-dashboard/Calls'
import RecordsContainer from './components/coach-dashboard/RecordsContainer'
import SearchContainer from './components/coach-dashboard/SearchContainer'

export default function CoachDashboard() {
    const [params, setParams] = React.useState({
        search: '',
        page: 1,
        eligibility: [],
    })

    const [clientsCount, setClientsCount] = React.useState(0)

    return (
        <div className="rpm-coach-dashboard">
            <DesktopHeader />
            <div className="rpm-coach-container-layout">
                <div className="rpm-coach-container">
                    <SearchContainer
                        params={params}
                        setParams={setParams}
                        clientsCount={clientsCount}
                    />
                    <RecordsContainer
                        params={params}
                        setParams={setParams}
                        setClientsCount={setClientsCount}
                    />
                </div>
                <Calls />
            </div>
        </div>
    )
}
