import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    RPM_CREATE_INSURANCE_INFORMATION_BEGIN,
    RPM_CREATE_INSURANCE_INFORMATION_SUCCESS,
    RPM_CREATE_INSURANCE_INFORMATION_FAILURE,
    RPM_CREATE_INSURANCE_INFORMATION_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function createInsuranceInformation(args = {}, method = 'POST') {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: RPM_CREATE_INSURANCE_INFORMATION_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {

            const fetchMethod = method === 'POST' ? axios.post : axios.put

            const doRequest = fetchMethod(
                `${config.apiRootUrl}/profile/insurance-information`,
                args,
                createAxiosConfigWithAuth(getState())
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: RPM_CREATE_INSURANCE_INFORMATION_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: RPM_CREATE_INSURANCE_INFORMATION_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissCreateInsuranceInformationError() {
    return {
        type: RPM_CREATE_INSURANCE_INFORMATION_DISMISS_ERROR,
    }
}

export function useCreateInsuranceInformation() {
    const dispatch = useDispatch()

    const {
        createInsuranceInformationPending,
        createInsuranceInformationError,
    } = useSelector(
        state => ({
            createInsuranceInformationPending:
                state.rpm.createInsuranceInformationPending,
            createInsuranceInformationError:
                state.rpm.createInsuranceInformationError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(createInsuranceInformation(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissCreateInsuranceInformationError())
    }, [dispatch])

    return {
        createInsuranceInformation: boundAction,
        createInsuranceInformationPending,
        createInsuranceInformationError,
        dismissCreateInsuranceInformationError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case RPM_CREATE_INSURANCE_INFORMATION_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                createInsuranceInformationPending: true,
                createInsuranceInformationError: null,
            }

        case RPM_CREATE_INSURANCE_INFORMATION_SUCCESS:
            // The request is success
            return {
                ...state,
                createInsuranceInformationPending: false,
                createInsuranceInformationError: null,
            }

        case RPM_CREATE_INSURANCE_INFORMATION_FAILURE:
            // The request is failed
            return {
                ...state,
                createInsuranceInformationPending: false,
                createInsuranceInformationError: action.data.error,
            }

        case RPM_CREATE_INSURANCE_INFORMATION_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                createInsuranceInformationError: null,
            }

        default:
            return state
    }
}
