import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useProfileSettings } from '../../user/redux/profileSettings'
import { COMMON_SET_DEFAULT_LANGUAGE } from './constants'

export function setDefaultLanguage(args) {
    return {
        type: 'COMMON_SET_DEFAULT_LANGUAGE',
        data: args,
    }
}

export function useTranslation() {
    const dispatch = useDispatch()

    const {
        defaultLanguage,
        languages,
        translations,
        languageNames,
    } = useSelector(state => state.translations, shallowEqual)

    const t = (key, params = {}) => {
        const keys = key.split('.')
        let value = translations[defaultLanguage]

        /*   console.log('defaultLanguage', defaultLanguage);
    console.log('translations', translations);
    console.log('value', value);
 */
        for (let i = 0; i < keys.length; i++) {
            value = value[keys[i]]
        }

        if (params) {
            Object.keys(params).forEach(param => {
                value = value.replace(`{${param}}`, params[param])
            })
        }

        return value
    }

    const boundAction = useCallback(
        (...params) => dispatch(setDefaultLanguage(...params)),
        [dispatch]
    )

    return {
        defaultLanguage,
        languages,
        setDefaultLanguage: boundAction,
        translations,
        t,
        languageNames,
    }
}

export function useSetDefaultLanguage() {
    const dispatch = useDispatch()

    const { settings, profileSettings } = useProfileSettings()

    const boundAction = useCallback(
        (...params) => dispatch(setDefaultLanguage(...params)),
        [dispatch]
    )

    const setPreferredLanguage = async (language = 'en-US') => {
        profileSettings({
            method: 'patch',
            data: {
                preferred_language: language,
            },
        })
    }

    useEffect(() => {
        console.log('settings', settings)
        if (settings && settings.preferred_language) {
            boundAction(settings.preferred_language)
            return
        }
    }, [settings])

    useEffect(() => {
        if (!settings) return

        if (!settings.preferred_language) {
            setPreferredLanguage()
            boundAction('en-US')
        }
    }, [])

    useEffect(() => {
        if (settings && settings.preferred_language) {
            boundAction(settings.preferred_language)
        }
    }, [settings])

    return { setPreferredLanguage }
}

export function reducer(state, action) {
    switch (action.type) {
        case COMMON_SET_DEFAULT_LANGUAGE:
            return {
                ...state,
                defaultLanguage: action.data,
            }

        default:
            return state
    }
}
