import React from 'react'
import { useFetchUser } from '../features/user/redux/fetchUser'
import { useGetSubscriptionStatusByEmail } from '../features/user/redux/getSubscriptionStatusByEmail'

function useHasActiveSubcription(props) {
  const {
    getSubscriptionStatusByEmail,
    getSubscriptionStatusByEmailPending,
  } = useGetSubscriptionStatusByEmail()
  const { user } = useFetchUser()

  const [isActive, setIsActive] = React.useState(false)
  const [subscriptionState, setSubscriptionState] = React.useState('')
  const [currentPeriodEnd, setCurrentPeriodEnd] = React.useState('')

  let userEmail = props?.email
  let email = userEmail ? userEmail : user?.email

  React.useEffect(() => {
    if (user) {
      if (user.groups.includes('Admin') || user.groups.includes('Coach')) {
        setIsActive(true)
      } else {
        getSubscriptionStatusByEmail({ email }).then(res => {
          let state = res.data.current_subscription_state
          let canceled = res.data.is_subscription_canceled

          switch (state) {
            case 'active':
              if (canceled === true) {
                setIsActive(true)
                setSubscriptionState('canceled but active')
                let endDate = new Date(res.data.current_period_end)
                endDate = endDate.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
                setCurrentPeriodEnd(endDate)
              } else if (canceled === false) {
                setIsActive(true)
                setSubscriptionState(state)
              }
              break
            case 'trialing':
              setIsActive(true)
              setSubscriptionState(state)
              break
            case 'canceled':
              setIsActive(false)
              setSubscriptionState(state)

              break
            case 'unpaid':
              setIsActive(false)
              setSubscriptionState(state)
              break
            case 'past_due':
              setIsActive(false)
              setSubscriptionState(state)
              break
            case 'incomplete':
              setIsActive(false)
              setSubscriptionState(state)
              break
            case 'incomplete_expired':
              setIsActive(false)
              setSubscriptionState('incomplete')
              break
            default:
              break
          }
        })
      }
    }
  }, [getSubscriptionStatusByEmail, user, isActive])
  return {
    isActive,
    subscriptionState,
    currentPeriodEnd,
    isLoading: getSubscriptionStatusByEmailPending,
  }
}

function HasDeclinedPayment() {
  const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail()
  const [isDeclined, setIsDeclined] = React.useState(false)
  const { user } = useFetchUser()

  React.useEffect(() => {
    if (user) {
      getSubscriptionStatusByEmail({ email: user.email }).then(res => {
        console.log(res)
        if (res.data.current_subscription_state === 'unpaid') {
          setIsDeclined(true)
        }
      })
    }
  }, [getSubscriptionStatusByEmail, user, isDeclined])
  return isDeclined
}

function useHasCanceledSubscription() {
  const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail()
  const { user } = useFetchUser()

  React.useEffect(() => {
    if (user && !!!user.groups.includes('Admin')) {
      getSubscriptionStatusByEmail({ email: user.email }).then(res => {
        console.log(res)
        if (res.data.current_subscription_state === 'canceled') {
          let endDate = new Date(res.data.current_period_end)
          endDate = endDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
          return {
            isCanceled: true,
            cancelEnd: endDate,
          }
        }
      })
    }
  }, [user])

  return { cancelEnd: false, end: '' }
}

function CurrentUserHasNoSubscription() {
  const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail()
  const [hasNoSubscription, setHasNoSubscription] = React.useState(false)
  const { user } = useFetchUser()

  React.useEffect(() => {
    if (user) {
      getSubscriptionStatusByEmail({ email: user.email }).then(res => {
        console.log(res)
        if (res.data.the_current_user_has_a_subscription === false) {
          setHasNoSubscription(true)
        }
      })
    }
  }, [getSubscriptionStatusByEmail, user, hasNoSubscription])
  return hasNoSubscription
}

function IsPastDue() {
  const { getSubscriptionStatusByEmail } = useGetSubscriptionStatusByEmail()
  const [isPastDue, setIsPastDue] = React.useState(false)
  const { user } = useFetchUser()

  React.useEffect(() => {
    if (user) {
      getSubscriptionStatusByEmail({ email: user.email }).then(res => {
        console.log(res)
        if (res.data.current_subscription_state === 'past_due') {
          setIsPastDue(true)
        }
      })
    }
  }, [getSubscriptionStatusByEmail, user, isPastDue])
  return isPastDue
}

export {
  CurrentUserHasNoSubscription,
  HasDeclinedPayment,
  IsPastDue,
  useHasActiveSubcription,
  useHasCanceledSubscription,
}
