import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

// Settings here contains only diffs from default settings
const privateLabelledSettings = {
    'react-app.myroadmap.io': {
        welcomePageCompanyName: 'ParentGuidance',
        signupCompanyNameField: 'ParentGuidance.org',
        signupSchoolFieldAvailable: true,
        defaultTheme: 'blue-ocean',
        loginCompanyNameField: 'ParentGuidance.org',
    },
    'brightermornings.myroadmap.io': {
        welcomePageCompanyName: 'Brighter Mornings',
        signupCompanyNameField: 'Brighter Mornings',
        defaultTheme: 'light',
        loginCompanyNameField: 'Brighter Mornings',
    },
    'app.noble.health': {
        welcomePageCompanyName: 'Noble.',
        signupCompanyNameField: 'Noble',
        defaultTheme: 'theme-dark',
        logoUrl:
            'https://s3.us-west-2.amazonaws.com/local.myroadmap.io/images/company-logos/Noble_YfkpZjB.png',
        appWelcomeMessage:
            "<div style='display: flex; flex-direction: column; align-items: center' > <h1>Therapist-created, research-backed </h1> <h1>Therapy Roadmaps.</h1><p className='welcome-page-msg mrm-mb-1_5 font-weight-light'>Find healing through connection and habit-building. </p></div>",
        loginCompanyNameField: 'Noble',
        coachSynonym: 'Mental Health Professional',
        userSynonym: 'Client',
        signupButtonVisible: false,
        signupSchoolFieldAvailable: false,
    },
    'app-dev.noble.health': {
        welcomePageCompanyName: 'Noble.',
        signupCompanyNameField: 'Noble',
        defaultTheme: 'theme-dark',
        logoUrl:
            'https://s3.us-west-2.amazonaws.com/local.myroadmap.io/images/company-logos/Noble_YfkpZjB.png',
        appWelcomeMessage:
            "<div style='display: flex; flex-direction: column; align-items: center' > <h1>Therapist-created, research-backed </h1> <h1>Therapy Roadmaps.</h1><p className='welcome-page-msg mrm-mb-1_5 font-weight-light'>Find healing through connection and habit-building. </p></div>",
        loginCompanyNameField: 'Noble',
        coachSynonym: 'Mental Health Professional',
        userSynonym: 'Client',
        signupButtonVisible: false,
        signupSchoolFieldAvailable: false,
    },
    'app-staging.noble.health': {
        welcomePageCompanyName: 'Noble.',
        signupCompanyNameField: 'Noble',
        defaultTheme: 'theme-dark',
        logoUrl:
            'https://s3.us-west-2.amazonaws.com/local.myroadmap.io/images/company-logos/Noble_YfkpZjB.png',
        appWelcomeMessage:
            "<div style='display: flex; flex-direction: column; align-items: center' > <h1>Therapist-created, research-backed </h1> <h1>Therapy Roadmaps.</h1><p className='welcome-page-msg mrm-mb-1_5 font-weight-light'>Find healing through connection and habit-building. </p></div>",
        loginCompanyNameField: 'Noble',
        coachSynonym: 'Mental Health Professional',
        userSynonym: 'Client',
        signupButtonVisible: false,
        signupSchoolFieldAvailable: false,
    },
}

const defaultSettings = {
    welcomePageCompanyName: 'Noble.',
    signupCompanyNameField: 'Noble',
    defaultTheme: 'theme-dark',
    logoUrl:
        'https://s3.us-west-2.amazonaws.com/local.myroadmap.io/images/company-logos/Noble_YfkpZjB.png',
    appWelcomeMessage:
        "<div style='display: flex; flex-direction: column; align-items: center' > <h1>Therapist-created, research-backed </h1> <h1>Therapy Roadmaps.</h1><p className='welcome-page-msg mrm-mb-1_5 font-weight-light'>Find healing through connection and habit-building. </p></div>",
    loginCompanyNameField: 'Noble',
    coachSynonym: 'Mental Health Professional',
    userSynonym: 'Client',
    signupButtonVisible: false,
    signupSchoolFieldAvailable: false,
}

function usePrivateLabelledSettings() {
    const location = useLocation()
    const queryParams = queryString.parse(location.search)
    const appDomain = queryParams.domainOverride || window.location.hostname
    // const appDomain = 'app.noble.health';
    return Object.assign(
        { appDomain },
        defaultSettings,
        appDomain in privateLabelledSettings
            ? privateLabelledSettings[appDomain]
            : {}
    )
}

export default usePrivateLabelledSettings
