// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  user: undefined,
  accounts: undefined,
  notificationsSettings: undefined,
  fetchUserPending: false,
  fetchUserError: null,
  updateUserPending: false,
  updateUserError: null,
  updateProfilePhotoPending: false,
  updateProfilePhotoError: null,
  deleteUserPending: false,
  deleteUserError: null,
  switchActiveCompanyPending: false,
  switchActiveCompanyError: null,
  deleteCoachPending: false,
  deleteCoachError: null,
  inviteCoachPending: false,
  inviteCoachError: null,
  fetchNotificationsSettingsPending: false,
  fetchNotificationsSettingsError: null,
  updateNotificationsSettingsPending: false,
  updateNotificationsSettingsError: null,
  bulkSwitchNotificationsSettingsPending: false,
  bulkSwitchNotificationsSettingsError: null,
  getPaymentMethodPending: false,
  getPaymentMethodError: null,
  getSubscriptionStatusPending: false,
  getSubscriptionStatusError: null,
  checkUserExistsByEmailPending: false,
  checkUserExistsByEmailError: null,
  getSubscriptionStatusByEmailPending: false,
  getSubscriptionStatusByEmailError: null,
  assignCoachToStudentPending: false,
  assignCoachToStudentError: null,
  cancelSubscriptionPending: false,
  cancelSubscriptionError: null,
  updateProductSubscriptionPending: false,
  updateProductSubscriptionError: null,
  listSubscriptionByCoachPending: false,
  listSubscriptionByCoachError: null,
  cancelSubscriptionByCoachPending: false,
  cancelSubscriptionByCoachError: null,
  getSubscriptionByCoachAndUserPending: false,
  getSubscriptionByCoachAndUserError: null,
  getProductByStripePriceIdPending: false,
  getProductByStripePriceIdError: null,
  changePaymentMethodCardPending: false,
  changePaymentMethodCardError: null,
  subscriptionInvitationCheckoutPending: false,
  subscriptionInvitationCheckoutError: null,
  getSubscriptionInvitationsPending: false,
  getSubscriptionInvitationsError: null,
  createRefundForClientPending: false,
  createRefundForClientError: null,
  getChargesByCustomerPending: false,
  getChargesByCustomerError: null,
  makePaymentToSubscriptionUnpaidPending: false,
  makePaymentToSubscriptionUnpaidError: null,
  userAgreementPending: false,
  userAgreementError: null,
  profileSettingsPending: false,
  profileSettingsError: null,
};

export default initialState;
