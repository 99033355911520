import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '../common/components/Icon/index';
import useEntranceAnimation from '../common/hooks/useEntranceAnimation';
// assets
import FitbitCardHeader from './assets/fitbit-connection/fitbit-background.png';
import FitbitLogo from './assets/fitbit-connection/fitbit-logo.png';
import Step0 from './components/fitbit-connection/Step0';
import Step1 from './components/fitbit-connection/Step1';
import Step2 from './components/fitbit-connection/Step2';

export default function FitbitConnection() {
  const { step } = useParams();
  const [currentStep, setCurrentStep] = React.useState(0);

  useEffect(() => {
    if (step === '3') {
      setCurrentStep(2);
    }
  }, [step]);

  const { initialOpacity } = useEntranceAnimation();

  return (
    <div
      className="rpm-fitbit-connection"
      style={{
        opacity: initialOpacity,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      <div className="rpm-wrapper">
        <div className="rpm-fitbit-connection-card">
          <div className="rpm-fitbit-connection-card-header">
            <button
              className="go-back-button"
              onClick={() => {
                if (currentStep === 0) {
                  window.history.back();
                } else {
                  setCurrentStep((step) => step - 1);
                }
              }}
            >
              <Icon name="chevronLeft" size={10} color="#fff" />
            </button>
            <img src={FitbitCardHeader} alt="Fitbit" className="background" />
            <img src={FitbitLogo} alt="Fitbit Logo" className="logo" />
            <div className="rpm-fitbit-progress-bar">
              <div
                className="rpm-fitbit-progress-bar-fill"
                style={{
                  width: `${(currentStep + 1) * 33.3333333}%`,
                  transition: 'all ease-in-out 0.5s',
                }}
              />
            </div>
          </div>
          <div className="rpm-fitbit-connection-card-body">
            <Step0 currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <Step1 currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <Step2 currentStep={currentStep} setCurrentStep={setCurrentStep} />
          </div>
        </div>
      </div>
    </div>
  );
}

FitbitConnection.propTypes = {};
FitbitConnection.defaultProps = {};
