export const desktopMultiSelectStyles = {
  control: (styles) => {
    const custom = {
      ...styles,
      borderRadius: '10px',
      cursor: 'text',
      borderColor: '#E3E5E5',
      minHeight: '40px',
      fontWeight: '300',
      '&:hover': {
        borderColor: '#E3E5E5',
      },
    };
    return custom;
  },
};
