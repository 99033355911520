import React from 'react';
import InvitationContextProvider from './InvitationContext';
import ModalBodyContainer from './ModalBodyContainer';
import ModalProfileContainer from './ModalProfileContainer';
import Header from './Steps/Header';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import StepsCounter from './Steps/StepsCounter';

export default function OnboardingInvitation(props) {
  return (
    <InvitationContextProvider {...props}>
      <div className="onboarding-invitation-container">
        <Header />
        <ModalProfileContainer>
          <StepsCounter />
          <Step1 />
          <Step2 />
          <Step3 />
          <Step4 />
          <Step5 />

          <ModalBodyContainer>
            <Step1 body />
            <Step2 body />
            <Step3 body />
            <Step4 body />
          </ModalBodyContainer>
        </ModalProfileContainer>
      </div>
    </InvitationContextProvider>
  );
}
