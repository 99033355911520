import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import AssignCoachForm from './components/formContainer/forms/AssignCoachForm'
import ClientInfoForm from './components/formContainer/forms/ClientInfoForm'
import Completed from './components/formContainer/forms/Completed'
import Review from './components/formContainer/forms/Review'
import SubscriptionForm from './components/formContainer/forms/SubscriptionForm'
import { FormikInitialValues } from './components/formContainer/utils/FormikInitialValues'
import StepListener from './components/formContainer/utils/StepListener'
import UpdateValues from './components/formContainer/utils/UpdateValues'
import { AssignCoachSchema } from './components/formContainer/yup/AssignCoachSchema'
// import PropTypes from 'prop-types';
import { ClientInfoSchema } from './components/formContainer/yup/ClientInfoSchema'
import { SubscriptionSchema } from './components/formContainer/yup/SubscriptionSchema'
import { SupportContext } from './Dashboard'
import { useCreateSubscriptionOnboardingCallCenter } from './redux/createSubscriptionOnboardingCallCenter'
import { useCreateUserAndSubscriptionCallcenterOnboarding } from './redux/createUserAndSubscriptionCallcenterOnboarding'
import { useUpdateUserInsuranceInformation } from '../rpm/redux/updateUserInsuranceInformation'
import { useCreateProfileAddress } from '../rpm/redux/createProfileAddress'
export default function FormContainer() {
    const {
        isCompleted,
        setIsCompleted,
        selectedSub,
        setUserExists,
        userExists,
    } = useContext(SupportContext)

    const valSchema = ClientInfoSchema.concat(AssignCoachSchema).concat(
        SubscriptionSchema
    )

    const {
        createUserAndSubscriptionCallcenterOnboarding,
    } = useCreateUserAndSubscriptionCallcenterOnboarding()

    const {
        createSubscriptionOnboardingCallCenter,
    } = useCreateSubscriptionOnboardingCallCenter()

    const {
        updateUserInsuranceInformation,
    } = useUpdateUserInsuranceInformation()
    const { createProfileAddress } = useCreateProfileAddress()

    const createSubscription = async (res, values) => {
        let data = {
            user_pk: res.data.id,
            by_sms_or_default: values.by_sms_or_default,
        }

        if (selectedSub?.id) data['product'] = selectedSub.id
        if (values.is_trial) data['trial_end'] = values.trial_end
        createSubscriptionOnboardingCallCenter(data)
            .then(() => {
                console.log('subscription created')
                setUserExists(true)
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    const handleSubmit = async values => {
        createUserAndSubscriptionCallcenterOnboarding(values).then(res => {
            setIsCompleted(true)
            createSubscription(res, values)
            createProfileAddress({
                address_1: values.address_1,
                address_2: values.address_2,
                state: values.state,
                city: values.city,
                zip: values.zip,
                userId: res.data.id,
            }).catch(console.error)

            if (values.insurance_provider && values.member_id)
                updateUserInsuranceInformation({
                    insurance_provider: values.insurance_provider,
                    member_id: values.member_id,
                    policy_holder: values.policy_holder || null,
                    policy_holder_dob: values.policy_holder_dob || null,
                    group_number: values.group_number || null,
                    userId: res.data.id,
                    method: 'POST',
                }).catch(console.error)
        })
    }

    return (
        <div className="support-form-container">
            <Formik
                initialValues={FormikInitialValues}
                validationSchema={valSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values)
                    setSubmitting(false)
                }}
            >
                <Form>
                    {!isCompleted && (
                        <>
                            <ClientInfoForm />
                            <AssignCoachForm />
                            <SubscriptionForm />

                            <Review />
                        </>
                    )}
                    <Completed />

                    {/** These following 2 components are listener components */}
                    <UpdateValues />
                    <StepListener />
                </Form>
            </Formik>
        </div>
    )
}

FormContainer.propTypes = {}
FormContainer.defaultProps = {}
