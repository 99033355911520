import React, { useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckDouble,
  faChevronRight,
  faClock,
  faPaperclip,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

const ActionItem = ({ data, onClick }) => {
  const due_date = data.due_date && dayjs(data.due_date);

  const getDueDateClass = () => {
    if (data.due_date) {
      if (due_date.isBefore(dayjs(new Date()))) {
        return 'due-over';
      } else if (data.marked_done || data.approved_done) {
        return 'due-completed';
      } else {
        return 'due-default';
      }
    }
  };

  const getDueDateDisplay = () => {
    if (data.due_date) {
      if (due_date.year() < dayjs(new Date()).year()) {
        return due_date.format('MMM D, YYYY');
      } else {
        return due_date.format('MMM D');
      }
    }
  };

  const completeIcon = useMemo(() => {
    if (!data.marked_done) {
      return null;
    } else if (data.resolutions.includes('requires_approval')) {
      if (data.approved_done) {
        return faCheckDouble;
      } else {
        return faClock;
      }
    } else {
      return faCheck;
    }
  }, [data]);

  return (
    <div className="action-item-row" onClick={onClick}>
      <Row className="action-item align-items-center" noGutters>
        <Col
          className={clsx(
            'ai-status',
            data.marked_done && 'green',
            !data.marked_done && 'blank',
          )}
        >
          <div className="m-auto text-white">
            {completeIcon && <FontAwesomeIcon icon={completeIcon} size="xs" />}
          </div>
        </Col>
        <Col className="mrm-ml-1">
          <div className="ai-description">
            <p className="mb-0">
              {data.title ? data.title : data.effective_description}
            </p>
            <small className={clsx(getDueDateClass())}>
              {getDueDateDisplay()}
            </small>
            {data.attachments.length > 0 && (
              <small className="file-attachments">
                <FontAwesomeIcon icon={faPaperclip} size="xs" />{' '}
                {data.attachments.length}
              </small>
            )}
          </div>
        </Col>
        <Col className="text-right d-flex align-items-center justify-content-end icon-box col-auto">
          <div className="mrm-pl-1 mrm-pr-0_75">
            <FontAwesomeIcon icon={faChevronRight} size="sm" title="Detail" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ActionItem;
