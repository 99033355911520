import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useCancelSubscriptionByCoach } from '../../user/redux/cancelSubscriptionByCoach';
import { useGetSubscriptionByCoachAndUser } from '../../user/redux/getSubscriptionByCoachAndUser';

export default function CancelClientSubscriptionModal({
  show,
  onHide,
  userPk,
}) {
  const { cancelSubscriptionByCoach } = useCancelSubscriptionByCoach();

  const { getSubscriptionByCoachAndUser } = useGetSubscriptionByCoachAndUser();

  const handleCancelSubscription = useCallback(() => {
    console.log('Cancel subscription', userPk);
    cancelSubscriptionByCoach({ user_pk: userPk })
      .then(() => {
        window.location.reload(false);
        onHide();
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [cancelSubscriptionByCoach, onHide, userPk]);

  const [lastDate, setLastDate] = useState(null);
  useEffect(() => {
    const getSubscriptionStatus = async () =>
      getSubscriptionByCoachAndUser({ id: userPk })
        .then((res) => {
          const date = new Date(res.data.current_period_end);
          // format Date to Month Day, Year
          const formattedDate = `${date.toLocaleString('default', {
            month: 'long',
          })} ${date.getDate()}, ${date.getFullYear()}`;
          setLastDate(formattedDate);
        })
        .catch((err) => {
          console.log('err', err.response);
        });
    getSubscriptionStatus();
  }, [userPk, useGetSubscriptionByCoachAndUser, show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="cancel-client-subscription-modal"
    >
      <Modal.Header>
        <Modal.Title>
          Are you sure you want to cancel your clients subscription?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your clients subscription is paid until {lastDate}. If you would like
          to proceed with canceling their subscription, please select “Cancel
          Subcription” below.
        </p>
      </Modal.Body>
      <button className="cancel" onClick={handleCancelSubscription}>
        Cancel Subcription
      </button>
      <button className="go-back" onClick={onHide}>
        Go back
      </button>
    </Modal>
  );
}
