import React, { useCallback, useEffect, useState } from 'react'
import { useGetLatestUnifiedSummary } from '../../redux/getLatestUnifiedSummary'
import DesktopRecords from './DesktopRecords'
import MobileRecords from './MobileRecords'

export default function RecordsContainer({
    params,
    setParams,
    setClientsCount,
}) {
    const [clients, setClients] = useState(null)

    const {
        getLatestUnifiedSummary,
        latestUnifiedSummary: clientsRaw,
    } = useGetLatestUnifiedSummary()

    const refetchClients = useCallback(async () => {
        setClients([])
        getLatestUnifiedSummary({ ...params, page: 1 })
    }, [params])

    useEffect(() => {
        setClients([])
        setClientsCount(0)
        setParams(prevState => ({ ...prevState, page: 1 }))

        if (params.reset) {
            refetchClients()
            setParams(prevState => ({ ...prevState, reset: false }))
            return
        }
    }, [
        params.search,
        params.eligibility_status,
        params.alert_type,
        params.reset,
        params?.has_call_scheduled,
        params.ordering,
    ])

    // if page changes, fetch more users
    useEffect(() => {
        if (params.page > 1) {
            getLatestUnifiedSummary(params)
        }
    }, [params.page])

    useEffect(() => {
        getLatestUnifiedSummary({ ...params, page: 1 })
    }, [
        params.eligibility_status,
        params.alert_type,
        params.has_call_scheduled,
        params.search,
        params.ordering,
    ])

    useEffect(() => {
        // if page is 1, set clients
        if (params.page === 1) {
            if (clientsRaw && clientsRaw.results) {
                setClientsCount(clientsRaw.count)
                setClients(clientsRaw.results)
            }
        } else {
            // if page is not 1, append clients
            if (clientsRaw && clientsRaw.results) {
                setClients(prevState => [...prevState, ...clientsRaw.results])
            }
        }
    }, [clientsRaw])

    useEffect(() => {
        if (!params.search) {
            setClients([])
            setParams(prevState => ({ ...prevState, page: 1 }))
        }

        // debounce search
        const timeout = setTimeout(() => {
            refetchClients()
        }, 500)

        return () => clearTimeout(timeout)
    }, [params.search])

    const fetchMoreUsers = () => {
        setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))
    }

    return (
        <div className="rpm-provider-records-container">
            <DesktopRecords
                clients={clients}
                clientsRaw={clientsRaw}
                params={params}
                fetchMoreUsers={fetchMoreUsers}
                refetchClients={refetchClients}
            />
            <MobileRecords
                clients={clients}
                clientsRaw={clientsRaw}
                params={params}
                fetchMoreUsers={fetchMoreUsers}
                refetchClients={refetchClients}
            />
        </div>
    )
}
