import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useFetchUser } from '../../../../../user/redux/fetchUser';

export default function SetCompanies({ setCompanies }) {
  const { fetchUser, user } = useFetchUser();

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!user) return;

    setCompanies(
      user.all_companies
        .filter((company) => company.id === user.company_id)
        .map((company) => ({
          value: company.id,
          label: company.name,
          coaching_group: company.internal_coaches_can_have_coaching_group,
        })),
    );
  }, [user]);

  return null;
}
