import React from 'react';
import Icon from '../../../common/components/Icon';
import GirlPic from '../../assets/check-in/girl.png';

export default function MyTeam() {
  return (
    <div className="my-team">
      <h3> My Team</h3>
      <div className="my-team-card-container">
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <div className="my-team-card">
              <div className="my-team-card-left">
                <img src={GirlPic} alt="girl" />
                <div className="my-team-card-left-text">
                  <h4>Whitney T.</h4>
                  <p>Your assigned professional</p>
                </div>
              </div>
              <div className="my-team-card-right">
                <Icon name="add-date" color={'#6D786E'} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
