import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Icon from '../../../common/components/Icon';
import { useWindowSize } from '../../../../common/windowsSize';
import { InvitationContext } from '../InvitationContext';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';

export default function Header() {
  const size = useWindowSize();
  const history = useHistory();
  const { t } = useTranslation();

  const { setStep, step, setAddSubscriptionForm } =
    useContext(InvitationContext);
  return (
    <>
      <div className={`${size.width > 768}`}>
        {step > 1 && step < 5 && (
          <button
            onClick={() => {
              setStep(step - 1);
              setAddSubscriptionForm(false);
            }}
            className="invitation-back"
          >
            {' '}
            <Icon name="chevronLeft" size={15} />{' '}
          </button>
        )}
      </div>
      {size.width < 768 && (
        <div className="return-cancel-header">
          <button
            onClick={() => {
              if (step === 1) {
                history.push('/');
              } else {
                setStep(step - 1);
              }
            }}
          >
            {' '}
            <Icon name="chevronLeft" size={15} />{' '}
          </button>
          <Link to="/">
            <p>{t('dashboard.onboarding.fourth_step.cancel')}</p>
          </Link>
        </div>
      )}
    </>
  );
}
