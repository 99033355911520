import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom'

import { useFetchUser } from '../user/redux/hooks'
import { TabSelector } from '../common'
import { useTranslation } from '../translations/redux/setDefaultLanguage'

export default function DashboardPage({ children }) {

    const [tabs, setTabs] = useState([])

    const { user, replaceStringWithSynonyms } = useFetchUser()

    const location = useLocation()

    const history = useHistory()

    const [activeTab, setActiveTab] = useState(null)

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (user && user.groups) {
            const tabs = []

            if (user.groups.includes('Admin')) {
                tabs.push({
                    key: 'admin',
                    label: 'Admin',
                    to: '/dashboard/admin',
                })
            }
            if (user.groups.includes('Coach')) {
                tabs.push({
                    key: 'coach',
                    label: t('dashboard.my_coaching'),
                    to: '/dashboard/coach',
                })
            }

            if (user) {
                tabs.push({
                    key: 'my-roadmaps',
                    label: t('dashboard.my_roadmaps.title'),
                    to: '/dashboard/roadmaps',
                })
            }

            setTabs(tabs)

            setActiveTab(() => {
                const currentTab = tabs.findIndex(
                    t => t.to === history.location.pathname
                )
                if (currentTab < 0) {
                    return null
                } else {
                    return tabs[currentTab].key
                }
            })

            if (history.location.pathname === '/dashboard/legacy') {
                return
            }

            if (history.location.pathname === '/dashboard/roadmap-library') {
                history.push('/dashboard/roadmap-library')
            } else if (
                history.location.pathname === '/dashboard/coach-add-user'
            ) {
                history.push('/dashboard/coach-add-user')
            } else if (
                history.location.pathname === '/dashboard/upcoming-calls'
            ) {
                history.push('/dashboard/upcoming-calls')
            } else if (
                history.location.pathname === '/dashboard/coach-add-calls'
            ) {
                history.push('/dashboard/coach-add-calls')
            } else if (history.location.pathname === '/dashboard/payments') {
                history.push('/dashboard/payments')
                // if you want to add a new route, you have to add it below this line
            } else if (
                history.location.pathname ===
                '/dashboard/subscriptions/create-subscription'
            ) {
                history.push('/dashboard/subscriptions/create-subscription')
            } else if (history.location.pathname === '/dashboard/clients') {
                history.push('/dashboard/clients')
            } else if (
                history.location.pathname === '/dashboard/connect-to-get-paid'
            ) {
                history.push('/dashboard/connect-to-get-paid')
            } else if (
                history.location.pathname === '/dashboard/onboarding-invitation'
            ) {
                history.push('/dashboard/onboarding-invitation')
            } else if (
                history.location.pathname === '/dashboard/subscriptions'
            ) {
                history.push('/dashboard/subscriptions')
            } else if (history.location.pathname === '/dashboard/payouts') {
                history.push('/dashboard/payouts')
            } else if (
                history.location.pathname.match('/dashboard/coach-edit-calls/')
            ) {
                history.push(history.location.pathname)
            } else if (
                !tabs.map(t => t.to).includes(history.location.pathname)
            ) {
                history.push(tabs[0].to)
            }
        }
    }, [user, replaceStringWithSynonyms, history, history.location.pathname])

    return (
        <div className="dashboard-dashboard-page">
            {tabs.length > 1 &&
                location.pathname !== '/dashboard/roadmap-library' &&
                !location.pathname.startsWith('/dashboard/clients') &&
                location.pathname !== '/dashboard/payouts' &&
                !location.pathname.startsWith('/dashboard/subscriptions') &&
                location.pathname !== '/dashboard/connect-to-get-paid' && (
                    <>
                        {user.groups.includes('Admin') && (
                            <div className="tab-selector-container d-lg-none">
                                <TabSelector
                                    className="dashboard-tab-selector mrm-pt-0_25 d-lg-none"
                                    activeTab={activeTab}
                                    onTabChange={setActiveTab}
                                    tabs={tabs}
                                />
                            </div>
                        )}
                    </>
                )}
            {children}
        </div>
    )
}

DashboardPage.propTypes = {}
DashboardPage.defaultProps = {}
