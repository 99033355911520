import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SUPPORT_GET_ACTIVE_CONTRACTS_BEGIN,
  SUPPORT_GET_ACTIVE_CONTRACTS_SUCCESS,
  SUPPORT_GET_ACTIVE_CONTRACTS_FAILURE,
  SUPPORT_GET_ACTIVE_CONTRACTS_DISMISS_ERROR,
} from './constants';

import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import axios from 'axios';

export function getActiveContracts(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: SUPPORT_GET_ACTIVE_CONTRACTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let url = `${config.apiRootUrl}/subscriptions/get-active-contracts`;
      if (args.company) url += `?company=${args.company}`;

      const doRequest = axios.get(url, createAxiosConfigWithAuth(getState()));

      doRequest.then(
        (res) => {
          dispatch({
            type: SUPPORT_GET_ACTIVE_CONTRACTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: SUPPORT_GET_ACTIVE_CONTRACTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetActiveContractsError() {
  return {
    type: SUPPORT_GET_ACTIVE_CONTRACTS_DISMISS_ERROR,
  };
}

export function useGetActiveContracts() {
  const dispatch = useDispatch();

  const { getActiveContractsPending, getActiveContractsError } = useSelector(
    (state) => ({
      getActiveContractsPending: state.support.getActiveContractsPending,
      getActiveContractsError: state.support.getActiveContractsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getActiveContracts(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetActiveContractsError());
  }, [dispatch]);

  return {
    getActiveContracts: boundAction,
    getActiveContractsPending,
    getActiveContractsError,
    dismissGetActiveContractsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SUPPORT_GET_ACTIVE_CONTRACTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getActiveContractsPending: true,
        getActiveContractsError: null,
      };

    case SUPPORT_GET_ACTIVE_CONTRACTS_SUCCESS:
      // The request is success
      return {
        ...state,
        getActiveContractsPending: false,
        getActiveContractsError: null,
      };

    case SUPPORT_GET_ACTIVE_CONTRACTS_FAILURE:
      // The request is failed
      return {
        ...state,
        getActiveContractsPending: false,
        getActiveContractsError: action.data.error,
      };

    case SUPPORT_GET_ACTIVE_CONTRACTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getActiveContractsError: null,
      };

    default:
      return state;
  }
}
