export const calls = {
    add_new_call: 'Add new call',
    edit_call: 'Edit call',
    personal: 'Personal',
    third_party: 'Third party',
    call_name: 'Call name',
    date_time: 'Date/Time',
    participants: 'Participants',
    select_participants: 'Select participants',
    meeting_link: 'Meeting link',
    using_upheal_link: 'Using Upheal link',
    use_upheal_link: 'Use Upheal link',
    recurs_weekly: 'Recurs weekly',
    edit: 'Edit',
    save: 'Save',
    delete_call: 'Delete call',
    are_you_sure_you_want_to_delete_this_call:
        'Are you sure you want to delete this call?',
    delete_call: 'Delete call',
    common: {
        cancel: 'Cancel',
    },
}
