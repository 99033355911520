export const USER_FETCH_USER_BEGIN = 'USER_FETCH_USER_BEGIN';
export const USER_FETCH_USER_SUCCESS = 'USER_FETCH_USER_SUCCESS';
export const USER_FETCH_USER_FAILURE = 'USER_FETCH_USER_FAILURE';
export const USER_FETCH_USER_DISMISS_ERROR = 'USER_FETCH_USER_DISMISS_ERROR';
export const USER_UPDATE_USER_BEGIN = 'USER_UPDATE_USER_BEGIN';
export const USER_UPDATE_USER_SUCCESS = 'USER_UPDATE_USER_SUCCESS';
export const USER_UPDATE_USER_FAILURE = 'USER_UPDATE_USER_FAILURE';
export const USER_UPDATE_USER_DISMISS_ERROR = 'USER_UPDATE_USER_DISMISS_ERROR';
export const USER_UPDATE_PROFILE_PHOTO_BEGIN =
  'USER_UPDATE_PROFILE_PHOTO_BEGIN';
export const USER_UPDATE_PROFILE_PHOTO_SUCCESS =
  'USER_UPDATE_PROFILE_PHOTO_SUCCESS';
export const USER_UPDATE_PROFILE_PHOTO_FAILURE =
  'USER_UPDATE_PROFILE_PHOTO_FAILURE';
export const USER_UPDATE_PROFILE_PHOTO_DISMISS_ERROR =
  'USER_UPDATE_PROFILE_PHOTO_DISMISS_ERROR';
export const USER_DELETE_USER_BEGIN = 'USER_DELETE_USER_BEGIN';
export const USER_DELETE_USER_SUCCESS = 'USER_DELETE_USER_SUCCESS';
export const USER_DELETE_USER_FAILURE = 'USER_DELETE_USER_FAILURE';
export const USER_DELETE_USER_DISMISS_ERROR = 'USER_DELETE_USER_DISMISS_ERROR';
export const USER_SWITCH_ACTIVE_COMPANY_BEGIN =
  'USER_SWITCH_ACTIVE_COMPANY_BEGIN';
export const USER_SWITCH_ACTIVE_COMPANY_SUCCESS =
  'USER_SWITCH_ACTIVE_COMPANY_SUCCESS';
export const USER_SWITCH_ACTIVE_COMPANY_FAILURE =
  'USER_SWITCH_ACTIVE_COMPANY_FAILURE';
export const USER_SWITCH_ACTIVE_COMPANY_DISMISS_ERROR =
  'USER_SWITCH_ACTIVE_COMPANY_DISMISS_ERROR';
export const USER_DELETE_COACH_BEGIN = 'USER_DELETE_COACH_BEGIN';
export const USER_DELETE_COACH_SUCCESS = 'USER_DELETE_COACH_SUCCESS';
export const USER_DELETE_COACH_FAILURE = 'USER_DELETE_COACH_FAILURE';
export const USER_DELETE_COACH_DISMISS_ERROR =
  'USER_DELETE_COACH_DISMISS_ERROR';
export const USER_INVITE_COACH_BEGIN = 'USER_INVITE_COACH_BEGIN';
export const USER_INVITE_COACH_SUCCESS = 'USER_INVITE_COACH_SUCCESS';
export const USER_INVITE_COACH_FAILURE = 'USER_INVITE_COACH_FAILURE';
export const USER_INVITE_COACH_DISMISS_ERROR =
  'USER_INVITE_COACH_DISMISS_ERROR';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_BEGIN =
  'USER_FETCH_NOTIFICATIONS_SETTINGS_BEGIN';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS =
  'USER_FETCH_NOTIFICATIONS_SETTINGS_SUCCESS';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_FAILURE =
  'USER_FETCH_NOTIFICATIONS_SETTINGS_FAILURE';
export const USER_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR =
  'USER_FETCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN =
  'USER_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS =
  'USER_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE =
  'USER_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE';
export const USER_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR =
  'USER_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_BEGIN =
  'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_BEGIN';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_SUCCESS =
  'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_SUCCESS';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_FAILURE =
  'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_FAILURE';
export const USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR =
  'USER_BULK_SWITCH_NOTIFICATIONS_SETTINGS_DISMISS_ERROR';
export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_DISMISS_ERROR = 'CHANGE_PASSWORD_DISMISS_ERROR';
export const SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR';
export const USER_GET_PAYMENT_METHOD_BEGIN = 'USER_GET_PAYMENT_METHOD_BEGIN';
export const USER_GET_PAYMENT_METHOD_SUCCESS =
  'USER_GET_PAYMENT_METHOD_SUCCESS';
export const USER_GET_PAYMENT_METHOD_FAILURE =
  'USER_GET_PAYMENT_METHOD_FAILURE';
export const USER_GET_PAYMENT_METHOD_DISMISS_ERROR =
  'USER_GET_PAYMENT_METHOD_DISMISS_ERROR';
export const USER_GET_SUBSCRIPTION_STATUS_BEGIN =
  'USER_GET_SUBSCRIPTION_STATUS_BEGIN';
export const USER_GET_SUBSCRIPTION_STATUS_SUCCESS =
  'USER_GET_SUBSCRIPTION_STATUS_SUCCESS';
export const USER_GET_SUBSCRIPTION_STATUS_FAILURE =
  'USER_GET_SUBSCRIPTION_STATUS_FAILURE';
export const USER_GET_SUBSCRIPTION_STATUS_DISMISS_ERROR =
  'USER_GET_SUBSCRIPTION_STATUS_DISMISS_ERROR';
export const USER_CHECK_USER_EXISTS_BY_EMAIL_BEGIN =
  'USER_CHECK_USER_EXISTS_BY_EMAIL_BEGIN';
export const USER_CHECK_USER_EXISTS_BY_EMAIL_SUCCESS =
  'USER_CHECK_USER_EXISTS_BY_EMAIL_SUCCESS';
export const USER_CHECK_USER_EXISTS_BY_EMAIL_FAILURE =
  'USER_CHECK_USER_EXISTS_BY_EMAIL_FAILURE';
export const USER_CHECK_USER_EXISTS_BY_EMAIL_DISMISS_ERROR =
  'USER_CHECK_USER_EXISTS_BY_EMAIL_DISMISS_ERROR';
export const USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_BEGIN =
  'USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_BEGIN';
export const USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_SUCCESS =
  'USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_SUCCESS';
export const USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_FAILURE =
  'USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_FAILURE';
export const USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_DISMISS_ERROR =
  'USER_GET_SUBSCRIPTION_STATUS_BY_EMAIL_DISMISS_ERROR';
export const USER_ASSIGN_COACH_TO_STUDENT_BEGIN =
  'USER_ASSIGN_COACH_TO_STUDENT_BEGIN';
export const USER_ASSIGN_COACH_TO_STUDENT_SUCCESS =
  'USER_ASSIGN_COACH_TO_STUDENT_SUCCESS';
export const USER_ASSIGN_COACH_TO_STUDENT_FAILURE =
  'USER_ASSIGN_COACH_TO_STUDENT_FAILURE';
export const USER_ASSIGN_COACH_TO_STUDENT_DISMISS_ERROR =
  'USER_ASSIGN_COACH_TO_STUDENT_DISMISS_ERROR';
export const USER_CANCEL_SUBSCRIPTION_BEGIN = 'USER_CANCEL_SUBSCRIPTION_BEGIN';
export const USER_CANCEL_SUBSCRIPTION_SUCCESS =
  'USER_CANCEL_SUBSCRIPTION_SUCCESS';
export const USER_CANCEL_SUBSCRIPTION_FAILURE =
  'USER_CANCEL_SUBSCRIPTION_FAILURE';
export const USER_CANCEL_SUBSCRIPTION_DISMISS_ERROR =
  'USER_CANCEL_SUBSCRIPTION_DISMISS_ERROR';
export const USER_UPDATE_PRODUCT_SUBSCRIPTION_BEGIN =
  'USER_UPDATE_PRODUCT_SUBSCRIPTION_BEGIN';
export const USER_UPDATE_PRODUCT_SUBSCRIPTION_SUCCESS =
  'USER_UPDATE_PRODUCT_SUBSCRIPTION_SUCCESS';
export const USER_UPDATE_PRODUCT_SUBSCRIPTION_FAILURE =
  'USER_UPDATE_PRODUCT_SUBSCRIPTION_FAILURE';
export const USER_UPDATE_PRODUCT_SUBSCRIPTION_DISMISS_ERROR =
  'USER_UPDATE_PRODUCT_SUBSCRIPTION_DISMISS_ERROR';
export const USER_LIST_SUBSCRIPTION_BY_COACH_BEGIN =
  'USER_LIST_SUBSCRIPTION_BY_COACH_BEGIN';
export const USER_LIST_SUBSCRIPTION_BY_COACH_SUCCESS =
  'USER_LIST_SUBSCRIPTION_BY_COACH_SUCCESS';
export const USER_LIST_SUBSCRIPTION_BY_COACH_FAILURE =
  'USER_LIST_SUBSCRIPTION_BY_COACH_FAILURE';
export const USER_LIST_SUBSCRIPTION_BY_COACH_DISMISS_ERROR =
  'USER_LIST_SUBSCRIPTION_BY_COACH_DISMISS_ERROR';
export const USER_CANCEL_SUBSCRIPTION_BY_COACH_BEGIN =
  'USER_CANCEL_SUBSCRIPTION_BY_COACH_BEGIN';
export const USER_CANCEL_SUBSCRIPTION_BY_COACH_SUCCESS =
  'USER_CANCEL_SUBSCRIPTION_BY_COACH_SUCCESS';
export const USER_CANCEL_SUBSCRIPTION_BY_COACH_FAILURE =
  'USER_CANCEL_SUBSCRIPTION_BY_COACH_FAILURE';
export const USER_CANCEL_SUBSCRIPTION_BY_COACH_DISMISS_ERROR =
  'USER_CANCEL_SUBSCRIPTION_BY_COACH_DISMISS_ERROR';
export const USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_BEGIN =
  'USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_BEGIN';
export const USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_SUCCESS =
  'USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_SUCCESS';
export const USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_FAILURE =
  'USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_FAILURE';
export const USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_DISMISS_ERROR =
  'USER_GET_SUBSCRIPTION_BY_COACH_AND_USER_DISMISS_ERROR';
export const USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_BEGIN =
  'USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_BEGIN';
export const USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_SUCCESS =
  'USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_SUCCESS';
export const USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_FAILURE =
  'USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_FAILURE';
export const USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_DISMISS_ERROR =
  'USER_GET_PRODUCT_BY_STRIPE_PRICE_ID_DISMISS_ERROR';
export const USER_CHANGE_PAYMENT_METHOD_CARD_BEGIN =
  'USER_CHANGE_PAYMENT_METHOD_CARD_BEGIN';
export const USER_CHANGE_PAYMENT_METHOD_CARD_SUCCESS =
  'USER_CHANGE_PAYMENT_METHOD_CARD_SUCCESS';
export const USER_CHANGE_PAYMENT_METHOD_CARD_FAILURE =
  'USER_CHANGE_PAYMENT_METHOD_CARD_FAILURE';
export const USER_CHANGE_PAYMENT_METHOD_CARD_DISMISS_ERROR =
  'USER_CHANGE_PAYMENT_METHOD_CARD_DISMISS_ERROR';
export const USER_SUBSCRIPTION_INVITATION_CHECKOUT_BEGIN =
  'USER_SUBSCRIPTION_INVITATION_CHECKOUT_BEGIN';
export const USER_SUBSCRIPTION_INVITATION_CHECKOUT_SUCCESS =
  'USER_SUBSCRIPTION_INVITATION_CHECKOUT_SUCCESS';
export const USER_SUBSCRIPTION_INVITATION_CHECKOUT_FAILURE =
  'USER_SUBSCRIPTION_INVITATION_CHECKOUT_FAILURE';
export const USER_SUBSCRIPTION_INVITATION_CHECKOUT_DISMISS_ERROR =
  'USER_SUBSCRIPTION_INVITATION_CHECKOUT_DISMISS_ERROR';
export const USER_GET_SUBSCRIPTION_INVITATIONS_BEGIN =
  'USER_GET_SUBSCRIPTION_INVITATIONS_BEGIN';
export const USER_GET_SUBSCRIPTION_INVITATIONS_SUCCESS =
  'USER_GET_SUBSCRIPTION_INVITATIONS_SUCCESS';
export const USER_GET_SUBSCRIPTION_INVITATIONS_FAILURE =
  'USER_GET_SUBSCRIPTION_INVITATIONS_FAILURE';
export const USER_GET_SUBSCRIPTION_INVITATIONS_DISMISS_ERROR =
  'USER_GET_SUBSCRIPTION_INVITATIONS_DISMISS_ERROR';
export const USER_CREATE_REFUND_FOR_CLIENT_BEGIN =
  'USER_CREATE_REFUND_FOR_CLIENT_BEGIN';
export const USER_CREATE_REFUND_FOR_CLIENT_SUCCESS =
  'USER_CREATE_REFUND_FOR_CLIENT_SUCCESS';
export const USER_CREATE_REFUND_FOR_CLIENT_FAILURE =
  'USER_CREATE_REFUND_FOR_CLIENT_FAILURE';
export const USER_CREATE_REFUND_FOR_CLIENT_DISMISS_ERROR =
  'USER_CREATE_REFUND_FOR_CLIENT_DISMISS_ERROR';
export const USER_GET_CHARGES_BY_CUSTOMER_BEGIN =
  'USER_GET_CHARGES_BY_CUSTOMER_BEGIN';
export const USER_GET_CHARGES_BY_CUSTOMER_SUCCESS =
  'USER_GET_CHARGES_BY_CUSTOMER_SUCCESS';
export const USER_GET_CHARGES_BY_CUSTOMER_FAILURE =
  'USER_GET_CHARGES_BY_CUSTOMER_FAILURE';
export const USER_GET_CHARGES_BY_CUSTOMER_DISMISS_ERROR =
  'USER_GET_CHARGES_BY_CUSTOMER_DISMISS_ERROR';
export const USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_BEGIN =
  'USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_BEGIN';
export const USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_SUCCESS =
  'USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_SUCCESS';
export const USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_FAILURE =
  'USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_FAILURE';
export const USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_DISMISS_ERROR =
  'USER_MAKE_PAYMENT_TO_SUBSCRIPTION_UNPAID_DISMISS_ERROR';
export const USER_USER_AGREEMENT_BEGIN = 'USER_USER_AGREEMENT_BEGIN';
export const USER_USER_AGREEMENT_SUCCESS = 'USER_USER_AGREEMENT_SUCCESS';
export const USER_USER_AGREEMENT_FAILURE = 'USER_USER_AGREEMENT_FAILURE';
export const USER_USER_AGREEMENT_DISMISS_ERROR =
  'USER_USER_AGREEMENT_DISMISS_ERROR';
export const USER_PROFILE_SETTINGS_BEGIN = 'USER_PROFILE_SETTINGS_BEGIN';
export const USER_PROFILE_SETTINGS_SUCCESS = 'USER_PROFILE_SETTINGS_SUCCESS';
export const USER_PROFILE_SETTINGS_FAILURE = 'USER_PROFILE_SETTINGS_FAILURE';
export const USER_PROFILE_SETTINGS_DISMISS_ERROR =
  'USER_PROFILE_SETTINGS_DISMISS_ERROR';
