import React, { Fragment, useEffect } from 'react'
import Icon from '../../../../common/components/Icon'
import { useTranslation } from '../../../../translations/redux/setDefaultLanguage'

export default function ContractDetails({ user }) {
    const { t } = useTranslation()
    return (
        <Fragment>
            <div className="profile-details-section__header mrm-mt-2">
                <p className="profile-details-section__header__title">
                    {t('client_profile.contract')}
                </p>
                <p
                    className="profile-details-section__header__edit"
                    role="button"
                ></p>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="payouts" size={20} color="#595959" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            {t('client_profile.contract_type')}
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {user.subscription.name || 'N/A'}
                    </p>
                </div>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon
                                name="modern-calendar"
                                color="#595959"
                                size={20}
                            />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            {t('client_profile.expires')}
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {user.subscription.expires &&
                            new Date(
                                user.subscription.expires
                            ).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}
                    </p>
                </div>
            </div>
        </Fragment>
    )
}
