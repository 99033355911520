import React, { Fragment, useEffect } from 'react'
import RecordCard from './RecordCard'

import { useFetchUnifiedSummary } from '../../../redux/fetchUnifiedSummary'
import Icon from '../../../../common/components/Icon'
export default function Records() {
    const { fetchUnifiedSummary, unifiedSummary } = useFetchUnifiedSummary()

    useEffect(() => {
        fetchUnifiedSummary()
    }, [fetchUnifiedSummary])

    if (!unifiedSummary?.results) return null

    return (
        <div className="health-data-record">
            {unifiedSummary.results &&
                unifiedSummary.results.map((record) => (
                    <Fragment>
                        <p className="date">{record.date}</p>
                        <div className="health-data-record-content">
                            <RecordCard
                                title="Daily Steps"
                                score={record?.activity_summary?.steps | 0}
                                date={record.date}
                                icon={<Icon name="steps" />}
                            />
                            <RecordCard
                                title="Sleep"
                                score={
                                    record?.sleep?.total_time_in_bed
                                        ? `${Math.floor(
                                              record?.sleep?.total_time_in_bed /
                                                  60
                                          )}H`
                                        : '0'
                                }
                                date={record.date}
                                icon={<Icon name="sleep" />}
                            />
                            {record.hrv && record.hrv.length > 0 && (
                                <RecordCard
                                    title="HRV"
                                    score={
                                        Math.floor(record.hrv[0].daily_rmssd) ||
                                        0
                                    }
                                    date={record.date}
                                    icon={<Icon name="hrv" size={18} />}
                                />
                            )}
                        </div>
                    </Fragment>
                ))}
            <p className="end-reached">
                {' '}
                You've reached the beginning of your health record{' '}
            </p>
        </div>
    )
}
