export const MESSAGES_FETCH_RECIPIENTS_BEGIN =
  'MESSAGES_FETCH_RECIPIENTS_BEGIN';
export const MESSAGES_FETCH_RECIPIENTS_SUCCESS =
  'MESSAGES_FETCH_RECIPIENTS_SUCCESS';
export const MESSAGES_FETCH_RECIPIENTS_FAILURE =
  'MESSAGES_FETCH_RECIPIENTS_FAILURE';
export const MESSAGES_FETCH_RECIPIENTS_DISMISS_ERROR =
  'MESSAGES_FETCH_RECIPIENTS_DISMISS_ERROR';
export const MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_BEGIN =
  'MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_BEGIN';
export const MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_SUCCESS =
  'MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_SUCCESS';
export const MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_FAILURE =
  'MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_FAILURE';
export const MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_DISMISS_ERROR =
  'MESSAGES_CREATE_UPDATE_PEER_LAST_READ_MESSAGE_TIMESTAMP_DISMISS_ERROR';
export const MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_BEGIN =
  'MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_BEGIN';
export const MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_SUCCESS =
  'MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_SUCCESS';
export const MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_FAILURE =
  'MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_FAILURE';
export const MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_DISMISS_ERROR =
  'MESSAGES_CREATE_PEER_TO_PEER_MESSAGE_DISMISS_ERROR';
export const MESSAGES_FETCH_CONVERSATIONS_BEGIN =
  'MESSAGES_FETCH_CONVERSATIONS_BEGIN';
export const MESSAGES_FETCH_CONVERSATIONS_SUCCESS =
  'MESSAGES_FETCH_CONVERSATIONS_SUCCESS';
export const MESSAGES_FETCH_CONVERSATIONS_FAILURE =
  'MESSAGES_FETCH_CONVERSATIONS_FAILURE';
export const MESSAGES_FETCH_CONVERSATIONS_DISMISS_ERROR =
  'MESSAGES_FETCH_CONVERSATIONS_DISMISS_ERROR';
export const MESSAGES_FETCH_CONVERSATION_MESSAGES_BEGIN =
  'MESSAGES_FETCH_CONVERSATION_MESSAGES_BEGIN';
export const MESSAGES_FETCH_CONVERSATION_MESSAGES_SUCCESS =
  'MESSAGES_FETCH_CONVERSATION_MESSAGES_SUCCESS';
export const MESSAGES_FETCH_CONVERSATION_MESSAGES_FAILURE =
  'MESSAGES_FETCH_CONVERSATION_MESSAGES_FAILURE';
export const MESSAGES_FETCH_CONVERSATION_MESSAGES_DISMISS_ERROR =
  'MESSAGES_FETCH_CONVERSATION_MESSAGES_DISMISS_ERROR';
export const MESSAGES_FETCH_GROUP_CHATS_BEGIN =
  'MESSAGES_FETCH_GROUP_CHATS_BEGIN';
export const MESSAGES_FETCH_GROUP_CHATS_SUCCESS =
  'MESSAGES_FETCH_GROUP_CHATS_SUCCESS';
export const MESSAGES_FETCH_GROUP_CHATS_FAILURE =
  'MESSAGES_FETCH_GROUP_CHATS_FAILURE';
export const MESSAGES_FETCH_GROUP_CHATS_DISMISS_ERROR =
  'MESSAGES_FETCH_GROUP_CHATS_DISMISS_ERROR';
export const MESSAGES_FETCH_GROUP_CHAT_MESSAGES_BEGIN =
  'MESSAGES_FETCH_GROUP_CHAT_MESSAGES_BEGIN';
export const MESSAGES_FETCH_GROUP_CHAT_MESSAGES_SUCCESS =
  'MESSAGES_FETCH_GROUP_CHAT_MESSAGES_SUCCESS';
export const MESSAGES_FETCH_GROUP_CHAT_MESSAGES_FAILURE =
  'MESSAGES_FETCH_GROUP_CHAT_MESSAGES_FAILURE';
export const MESSAGES_FETCH_GROUP_CHAT_MESSAGES_DISMISS_ERROR =
  'MESSAGES_FETCH_GROUP_CHAT_MESSAGES_DISMISS_ERROR';
export const MESSAGES_CREATE_GROUP_CHAT_MESSAGE_BEGIN =
  'MESSAGES_CREATE_GROUP_CHAT_MESSAGE_BEGIN';
export const MESSAGES_CREATE_GROUP_CHAT_MESSAGE_SUCCESS =
  'MESSAGES_CREATE_GROUP_CHAT_MESSAGE_SUCCESS';
export const MESSAGES_CREATE_GROUP_CHAT_MESSAGE_FAILURE =
  'MESSAGES_CREATE_GROUP_CHAT_MESSAGE_FAILURE';
export const MESSAGES_CREATE_GROUP_CHAT_MESSAGE_DISMISS_ERROR =
  'MESSAGES_CREATE_GROUP_CHAT_MESSAGE_DISMISS_ERROR';
export const MESSAGES_CREATE_GROUP_CHAT_BEGIN =
  'MESSAGES_CREATE_GROUP_CHAT_BEGIN';
export const MESSAGES_CREATE_GROUP_CHAT_SUCCESS =
  'MESSAGES_CREATE_GROUP_CHAT_SUCCESS';
export const MESSAGES_CREATE_GROUP_CHAT_FAILURE =
  'MESSAGES_CREATE_GROUP_CHAT_FAILURE';
export const MESSAGES_CREATE_GROUP_CHAT_DISMISS_ERROR =
  'MESSAGES_CREATE_GROUP_CHAT_DISMISS_ERROR';
export const MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_BEGIN =
  'MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_BEGIN';
export const MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_SUCCESS =
  'MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_SUCCESS';
export const MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_FAILURE =
  'MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_FAILURE';
export const MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_DISMISS_ERROR =
  'MESSAGES_CREATE_UPDATE_GROUP_LAST_READ_MESSAGE_TIMESTAMP_DISMISS_ERROR';
export const MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_BEGIN =
  'MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_BEGIN';
export const MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_SUCCESS =
  'MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_SUCCESS';
export const MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_FAILURE =
  'MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_FAILURE';
export const MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_DISMISS_ERROR =
  'MESSAGES_DELETE_PEER_TO_PEER_MESSAGE_DISMISS_ERROR';
export const MESSAGES_DELETE_GROUP_CHAT_MESSAGE_BEGIN =
  'MESSAGES_DELETE_GROUP_CHAT_MESSAGE_BEGIN';
export const MESSAGES_DELETE_GROUP_CHAT_MESSAGE_SUCCESS =
  'MESSAGES_DELETE_GROUP_CHAT_MESSAGE_SUCCESS';
export const MESSAGES_DELETE_GROUP_CHAT_MESSAGE_FAILURE =
  'MESSAGES_DELETE_GROUP_CHAT_MESSAGE_FAILURE';
export const MESSAGES_DELETE_GROUP_CHAT_MESSAGE_DISMISS_ERROR =
  'MESSAGES_DELETE_GROUP_CHAT_MESSAGE_DISMISS_ERROR';
export const MESSAGES_GET_GROUP_CHAT_BEGIN = 'MESSAGES_GET_GROUP_CHAT_BEGIN';
export const MESSAGES_GET_GROUP_CHAT_SUCCESS =
  'MESSAGES_GET_GROUP_CHAT_SUCCESS';
export const MESSAGES_GET_GROUP_CHAT_FAILURE =
  'MESSAGES_GET_GROUP_CHAT_FAILURE';
export const MESSAGES_GET_GROUP_CHAT_DISMISS_ERROR =
  'MESSAGES_GET_GROUP_CHAT_DISMISS_ERROR';
export const MESSAGES_EDIT_GROUP_CHAT_BEGIN = 'MESSAGES_EDIT_GROUP_CHAT_BEGIN';
export const MESSAGES_EDIT_GROUP_CHAT_SUCCESS =
  'MESSAGES_EDIT_GROUP_CHAT_SUCCESS';
export const MESSAGES_EDIT_GROUP_CHAT_FAILURE =
  'MESSAGES_EDIT_GROUP_CHAT_FAILURE';
export const MESSAGES_EDIT_GROUP_CHAT_DISMISS_ERROR =
  'MESSAGES_EDIT_GROUP_CHAT_DISMISS_ERROR';
export const MESSAGES_DELETE_GROUP_CHAT_BEGIN =
  'MESSAGES_DELETE_GROUP_CHAT_BEGIN';
export const MESSAGES_DELETE_GROUP_CHAT_SUCCESS =
  'MESSAGES_DELETE_GROUP_CHAT_SUCCESS';
export const MESSAGES_DELETE_GROUP_CHAT_FAILURE =
  'MESSAGES_DELETE_GROUP_CHAT_FAILURE';
export const MESSAGES_DELETE_GROUP_CHAT_DISMISS_ERROR =
  'MESSAGES_DELETE_GROUP_CHAT_DISMISS_ERROR';
export const MESSAGES_CREATE_MEDIA_MESSAGE_BEGIN =
  'MESSAGES_CREATE_MEDIA_MESSAGE_BEGIN';
export const MESSAGES_CREATE_MEDIA_MESSAGE_SUCCESS =
  'MESSAGES_CREATE_MEDIA_MESSAGE_SUCCESS';
export const MESSAGES_CREATE_MEDIA_MESSAGE_FAILURE =
  'MESSAGES_CREATE_MEDIA_MESSAGE_FAILURE';
export const MESSAGES_CREATE_MEDIA_MESSAGE_DISMISS_ERROR =
  'MESSAGES_CREATE_MEDIA_MESSAGE_DISMISS_ERROR';
export const MESSAGES_UPLOAD_MEDIA_BEGIN = 'MESSAGES_UPLOAD_MEDIA_BEGIN';
export const MESSAGES_UPLOAD_MEDIA_SUCCESS = 'MESSAGES_UPLOAD_MEDIA_SUCCESS';
export const MESSAGES_UPLOAD_MEDIA_FAILURE = 'MESSAGES_UPLOAD_MEDIA_FAILURE';
export const MESSAGES_UPLOAD_MEDIA_DISMISS_ERROR =
  'MESSAGES_UPLOAD_MEDIA_DISMISS_ERROR';
export const MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_BEGIN =
  'MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_BEGIN';
export const MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_SUCCESS =
  'MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_SUCCESS';
export const MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_FAILURE =
  'MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_FAILURE';
export const MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_DISMISS_ERROR =
  'MESSAGES_GET_MEDIA_MESSAGES_BY_THREAD_DISMISS_ERROR';
export const MESSAGES_GET_LATEST_MEDIA_MESSAGES_BEGIN =
  'MESSAGES_GET_LATEST_MEDIA_MESSAGES_BEGIN';
export const MESSAGES_GET_LATEST_MEDIA_MESSAGES_SUCCESS =
  'MESSAGES_GET_LATEST_MEDIA_MESSAGES_SUCCESS';
export const MESSAGES_GET_LATEST_MEDIA_MESSAGES_FAILURE =
  'MESSAGES_GET_LATEST_MEDIA_MESSAGES_FAILURE';
export const MESSAGES_GET_LATEST_MEDIA_MESSAGES_DISMISS_ERROR =
  'MESSAGES_GET_LATEST_MEDIA_MESSAGES_DISMISS_ERROR';
export const MESSAGES_SEND_PUSH_NOTIFICATIONS_BEGIN =
  'MESSAGES_SEND_PUSH_NOTIFICATIONS_BEGIN';
export const MESSAGES_SEND_PUSH_NOTIFICATIONS_SUCCESS =
  'MESSAGES_SEND_PUSH_NOTIFICATIONS_SUCCESS';
export const MESSAGES_SEND_PUSH_NOTIFICATIONS_FAILURE =
  'MESSAGES_SEND_PUSH_NOTIFICATIONS_FAILURE';
export const MESSAGES_SEND_PUSH_NOTIFICATIONS_DISMISS_ERROR =
  'MESSAGES_SEND_PUSH_NOTIFICATIONS_DISMISS_ERROR';
