import { useEffect } from 'react';

export function useReplaceBadLinks({ editorRef }) {
  const replaceBadLinks = (ref) => {
    const intervalId = setInterval(() => {
      if (!!ref.current) {
        clearInterval(intervalId);
        const badLinks = ref.current.querySelectorAll('a:not([href^="http"])'); // this is a hack to fix links that are not absolute

        badLinks.forEach(function (link) {
          link.setAttribute('href', `http://${link.getAttribute('href')}`);
        });
      }
    }, 10);

    return intervalId;
  };

  useEffect(() => {
    const intervalId = replaceBadLinks(editorRef);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { replaceBadLinks };
}
