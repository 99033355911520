import React from "react";

export default function SleepIcon({
    size = 13,
    color = "#343434",
    ...props
}) {
    return <svg width={size} height={size * 1.444444} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clip-path="url(#clip0_1129_7173)">
            <path d="M0.511225 3.65856C0.799588 3.31829 1.19094 3.06459 1.62657 2.93268V1.71965C1.62657 0.771401 2.44346 0 3.44758 0H9.55242C10.5565 0 11.3734 0.771401 11.3734 1.71965V2.93268C11.8091 3.06459 12.2004 3.31829 12.4888 3.65856C12.8183 4.04786 13 4.53658 13 5.03463V8.61595C13 8.82782 12.8175 9 12.5932 9C12.3689 9 12.1866 8.82763 12.1866 8.61595V8.40525H0.813389V8.61595C0.813389 8.82782 0.630897 9 0.406592 9C0.182287 9 -2.28031e-07 8.82763 -2.28031e-07 8.61595V5.03463C-2.28031e-07 4.53638 0.181668 4.04767 0.511432 3.65856H0.511225ZM12.1866 7.63696V5.41887H0.813389V7.63696H12.1866ZM9.71843 2.6644C9.71843 2.47899 9.55881 2.32821 9.36251 2.32821H7.92626C7.72997 2.32821 7.57034 2.47899 7.57034 2.6644V2.8284H9.71864V2.6644H9.71843ZM5.54459 2.6644C5.54459 2.47899 5.38496 2.32821 5.18888 2.32821H3.75242C3.55613 2.32821 3.3965 2.47899 3.3965 2.6644V2.8284H5.5448V2.6644H5.54459ZM2.43996 2.8284H2.5829V2.6644C2.5829 2.05545 3.10752 1.55992 3.75221 1.55992H5.18867C5.83337 1.55992 6.35778 2.05545 6.35778 2.6644V2.8284H6.75654V2.6644C6.75654 2.05545 7.28115 1.55992 7.92585 1.55992H9.3621C10.0068 1.55992 10.5314 2.05545 10.5314 2.6644V2.8284H10.5596V1.71965C10.5596 1.19494 10.1077 0.768288 9.55201 0.768288H3.44758C2.89207 0.768288 2.43996 1.19514 2.43996 1.71965V2.8284ZM2.33553 3.59669C1.64037 3.59669 1.04717 4.03619 0.868384 4.65039H12.1316C11.9528 4.03619 11.3594 3.59669 10.6645 3.59669H2.33574H2.33553Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_1129_7173">
                <rect width={size} height={size * 1.444444} fill="white" transform="matrix(-1 0 0 1 13 0)" />
            </clipPath>
        </defs>
    </svg>

}
