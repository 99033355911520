import React from 'react'

export default function ChevronDownIcon({
    size = 8,
    color = '#40413F',
    style,
    className,
    ...props
}) {
    return (
        <svg
            width={size}
            height={size * 0.625}
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
            {...props}
        >
            <path
                d="M0.156675 0.157161C-0.0522249 0.366061 -0.0522249 0.702858 0.156675 0.911758L3.69944 4.45452C3.86571 4.62079 4.13429 4.62079 4.30056 4.45452L7.84333 0.911758C8.05223 0.702858 8.05223 0.366061 7.84333 0.157161C7.63443 -0.0517383 7.29763 -0.0517383 7.08873 0.157161L3.99787 3.24376L0.907008 0.152899C0.702371 -0.0517373 0.361311 -0.0517383 0.156675 0.157161Z"
                fill={color}
            />
        </svg>
    )
}
