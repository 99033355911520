import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { Fragment, useCallback, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Icon from '../../../common/components/Icon'
import { CompetencyContext } from '../../CompetencyPage'
import { useSetActionItemDetails } from '../../redux/setActionItemDetails'
import useSetLocalStorage from './hooks/useSetLocalStorage'

export default function ResponseInput({ ...props }) {
  const location = useLocation()
  const params = queryString.parse(location.search)

  const {
    responseMessage,
    setResponseMessage,
    fetchActionItemResponses,
    setCenterEdit,
    isMobile,
    actionItemResponses,
    hasResponse,
    hasNoResponses,
  } = props

  const { setActionItemDetails } = useSetActionItemDetails()

  const {
    selectedActionItem,
    roadmapId,
    stageId,
    competencyId,
    actionItemId,
    showActionItemContainer,
  } = useContext(CompetencyContext)

  const inputRef = React.useRef(null)

  useEffect(() => {
    // AUTO RESIZE TEXTAREA
    const autoResize = () => {
      const textarea = inputRef.current
      if (!textarea) return

      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight - 10}px`
    }
    autoResize()
  }, [responseMessage])

  const updateActionItem = useCallback(
    data => {
      return setActionItemDetails({
        roadmapId,
        stageId,
        competencyId,
        actionItemId: actionItemId
          ? actionItemId
          : showActionItemContainer.actionItemId,
        data,
      })
        .then(() => {
          fetchActionItemResponses()
        })
        .catch(() => {})
    },
    [roadmapId, stageId, competencyId, actionItemId, setActionItemDetails]
  )

  const {
    addResponseToLocalStorage,
    removeResponseFromLocalStorage,
  } = useSetLocalStorage({
    actionItemId: selectedActionItem.id,
    responseMessage,
    setResponseMessage,
  })

  const handleTextChange = useCallback(
    e => {
      setResponseMessage({
        ...responseMessage,
        message: e.target.value,
      })

      if (e.target.value) {
        addResponseToLocalStorage(e)
      }
    },
    [responseMessage, setResponseMessage]
  )

  const handleTextSubmit = useCallback(
    e => {
      e.preventDefault()
      setCenterEdit(false)

      fetchActionItemResponses('POST').then(() => {
        setResponseMessage({ message: '' })
        removeResponseFromLocalStorage()
      })
    },
    [setResponseMessage, fetchActionItemResponses, setCenterEdit]
  )

  useEffect(() => {
    if (selectedActionItem) {
      console.log('selectedActionItem', selectedActionItem)
      console.log('hasResponse', hasResponse)
      console.log('hasNoResponses', hasNoResponses)
      console.log('isMobile', isMobile)
    }
  }, [selectedActionItem, hasResponse, hasNoResponses, isMobile])

  if (!selectedActionItem || !actionItemResponses) return null

  const requiresResponse =
    selectedActionItem && selectedActionItem.resolutions.includes('input_text')

  if (hasResponse && isMobile) return null

  if (hasNoResponses && !isMobile && requiresResponse) return null

  return (
    <div className="ai-response-input-container">
      {((requiresResponse && isMobile) || (!isMobile && hasResponse)) && (
        <Fragment>
          {!params.user && (
            <p className="ai-response-input-description">
              <strong> Add your response: </strong> Once you have completed the
              exercise mark it as complete.
            </p>
          )}

          <form
            className="send-action-item-response"
            style={{
              borderRadius: isMobile ? '0' : '2rem 2rem 0.6rem 0',
            }}
          >
            <textarea
              style={{ resize: 'none' }}
              type="text"
              placeholder="Add a response"
              className="action-item-response-input"
              value={responseMessage.message}
              ref={inputRef}
              onChange={handleTextChange}
            />
            <button
              type="submit"
              className="action-item-response-button"
              onClick={handleTextSubmit}
              disabled={responseMessage.message.length === 0}
            >
              <Icon name="send" color="var(--primary-dark-green)" size={20} />
            </button>
          </form>
        </Fragment>
      )}

      {!params.user && (
        <button
          className="ai-response-mark-complete"
          disabled={requiresResponse && actionItemResponses.length === 0}
          style={{
            backgroundColor:
              requiresResponse && actionItemResponses.length === 0
                ? '#c2c2c2'
                : 'var(--primary-dark)',
          }}
          onClick={() => {
            updateActionItem({
              marked_done: !selectedActionItem.marked_done,
              date_marked_done: selectedActionItem.marked_done
                ? null
                : dayjs().format('YYYY-MM-DD'),
            })
          }}
        >
          {selectedActionItem?.marked_done
            ? 'Mark as incomplete'
            : 'Mark as complete'}
        </button>
      )}
    </div>
  )
}
