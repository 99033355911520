import React from 'react'

export default function StepsIcon({ size = 18, color = '#343434', ...props }) {
    return (
        <svg
            width="18"
            height="23"
            viewBox="0 0 18 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                scale: `${size / 18}`,
            }}
        >
            <g clip-path="url(#clip0_467_778)">
                <path
                    d="M17.3785 8.2075C16.6589 6.8659 15.3419 5.99354 13.8526 5.8741C12.54 5.76746 11.1641 6.45425 10.1654 7.7084C8.96178 9.21849 8.60854 11.1658 9.21908 12.9169C9.87104 14.7811 10.1611 15.8433 10.2897 16.4042C10.3093 16.4938 10.342 16.577 10.3791 16.6581C8.35342 19.4586 8.88328 20.8151 9.38479 21.3739C10.015 22.0799 11.0921 22.498 12.1715 22.498C12.4222 22.498 12.6708 22.4766 12.9172 22.4297C13.9791 22.2271 14.7706 21.5957 15.0912 20.6978C15.5491 19.4138 15.7475 18.2428 15.8282 17.6584C15.8456 17.5347 15.8347 17.4088 15.8064 17.2873C15.8652 17.2211 15.9197 17.1508 15.9655 17.074C17.1735 15.0264 18.9615 11.153 17.3829 8.2075H17.3785ZM11.1968 8.49544C11.8706 7.65081 12.7733 7.14531 13.591 7.14531C13.6433 7.14531 13.6935 7.14531 13.7458 7.15171C14.7859 7.23489 15.7104 7.85344 16.2207 8.80258C16.7745 9.8349 17.3283 12.1982 14.8295 16.4362C14.8186 16.4533 14.8055 16.4682 14.7881 16.4789L11.6024 16.176C11.5849 16.1632 11.5718 16.1462 11.5675 16.1248C11.4323 15.5319 11.1292 14.4228 10.4576 12.5053C9.98879 11.1637 10.2657 9.6664 11.1968 8.49544ZM12.6664 21.1756C11.827 21.3355 10.8392 21.0604 10.3704 20.5335C9.99315 20.1112 10.4293 18.8272 11.4105 17.4494C11.4475 17.4558 11.4868 17.4664 11.526 17.4686L14.4915 17.7501C14.3999 18.3324 14.2146 19.2666 13.8548 20.2797C13.6433 20.8727 13.0916 21.0945 12.6664 21.1756Z"
                    fill={color}
                />
                <path
                    d="M8.37082 7.79521C9.30843 6.18914 9.33678 4.21193 8.44713 2.50561C7.70794 1.08936 6.48469 0.16155 5.17857 0.0229117C3.69147 -0.13279 2.22836 0.479353 1.26458 1.66311C-0.854866 4.26739 0.156884 8.40096 0.946224 10.6341C0.976751 10.7173 1.016 10.7962 1.06179 10.873C1.00946 10.986 0.976751 11.1076 0.968029 11.2356C0.933141 11.8221 0.904795 13.0102 1.1054 14.356C1.24495 15.2966 1.90128 16.0623 2.90649 16.4569C3.38184 16.6446 3.9008 16.7342 4.41103 16.7342C5.21782 16.7342 6.00716 16.506 6.57627 16.0709C7.1759 15.6144 7.95652 14.3795 6.50867 11.2548C6.561 11.1823 6.60679 11.1055 6.64386 11.0223C6.87936 10.4955 7.36779 9.5058 8.36864 7.79521H8.37082ZM2.28941 2.46082C2.97191 1.62046 4.0011 1.18748 5.03902 1.29626C5.89377 1.38584 6.75289 2.07263 7.28275 3.08576C7.97178 4.40603 7.95434 5.92892 7.23696 7.15961C6.20994 8.92139 5.69753 9.95371 5.44895 10.5104C5.44023 10.5296 5.42278 10.5445 5.40316 10.5531L2.22181 10.263C2.20655 10.2502 2.19347 10.2353 2.18475 10.2139C0.547193 5.59192 1.54804 3.37157 2.28941 2.46082ZM5.77384 15.0599C5.21128 15.4886 4.18862 15.5782 3.39492 15.2668C2.99371 15.1089 2.49438 14.7911 2.40062 14.1683C2.24144 13.1083 2.23926 12.1549 2.26324 11.5662L5.2309 11.8371C5.27015 11.8413 5.3094 11.8413 5.34865 11.8435C6.04423 13.3749 6.22521 14.7165 5.77384 15.0599Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_467_778">
                    <rect width="18" height="22.5" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
