import React, { useEffect } from 'react'
import ContractDetails from './profile-details-section/ContractDetails'
import EmployerCoverageInformation from './profile-details-section/EmployerCoverageInformation'
import InsuranceDetails from './profile-details-section/InsuranceDetails'
import SubscriptionDetails from './profile-details-section/SubscriptionDetails'
import UphealInformation from './profile-details-section/UphealInformation'
import UserDetails from './profile-details-section/UserDetails'

export default function ProfileDetailsSection({ user, fetchUser }) {
    useEffect(() => {
        console.log('USER', user)
    }, [user])

    if (!user) return null

    return (
        <div className="profile-details-section">
            <UserDetails user={user} fetchUser={fetchUser} />
            <InsuranceDetails user={user} fetchUser={fetchUser} />
            {user.subscription && user.subscription.type === 'stripe' && (
                <SubscriptionDetails user={user} fetchUser={fetchUser} />
            )}
            {user.subscription && user.subscription.type === 'contract' && (
                <ContractDetails user={user} />
            )}

            {user &&
                user.upheal_information &&
                user?.upheal_information?.account_created && (
                    <UphealInformation user={user} />
                )}

            {user && <EmployerCoverageInformation user={user} />}
        </div>
    )
}
