import React from 'react'

export default function ModernCalendar({
    size = 18,
    color = '#595959',
    ...props
}) {
    return (
        <svg
            width={size}
            height={size / 1.055555556}
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.8186 17.6973H3.73561C2.83337 17.6958 1.9685 17.3367 1.33053 16.6988C0.692548 16.0608 0.33349 15.1959 0.332031 14.2937V4.70631C0.33349 3.80407 0.692548 2.93921 1.33053 2.30123C1.9685 1.66325 2.83337 1.30419 3.73561 1.30273H15.2647C16.167 1.30419 17.0318 1.66325 17.6698 2.30123C18.3078 2.93921 18.6668 3.80407 18.6683 4.70631V9.85304C18.6639 11.9331 17.8353 13.9266 16.364 15.3969C14.8927 16.8671 12.8986 17.6944 10.8186 17.6973ZM3.73561 2.95763C3.27183 2.95763 2.82705 3.14187 2.4991 3.46981C2.17116 3.79775 1.98693 4.24253 1.98693 4.70631V14.2937C1.98693 14.7575 2.17116 15.2023 2.4991 15.5302C2.82705 15.8581 3.27183 16.0424 3.73561 16.0424H10.8186C12.4601 16.0409 14.0341 15.3885 15.1954 14.2283C16.3567 13.068 17.0105 11.4946 17.0134 9.85304V4.70631C17.0134 4.47667 16.9682 4.24928 16.8803 4.03712C16.7924 3.82496 16.6636 3.63219 16.5012 3.46981C16.3389 3.30743 16.1461 3.17862 15.9339 3.09074C15.7218 3.00286 15.4944 2.95763 15.2647 2.95763H3.73561Z"
                fill={color}
            />
            <path
                d="M11.7893 9.01452H5.93096C5.71151 9.01452 5.50105 8.92734 5.34587 8.77216C5.19069 8.61699 5.10352 8.40652 5.10352 8.18707C5.10352 7.96762 5.19069 7.75715 5.34587 7.60197C5.50105 7.4468 5.71151 7.35962 5.93096 7.35962H11.7893C12.0088 7.35962 12.2192 7.4468 12.3744 7.60197C12.5296 7.75715 12.6168 7.96762 12.6168 8.18707C12.6168 8.40652 12.5296 8.61699 12.3744 8.77216C12.2192 8.92734 12.0088 9.01452 11.7893 9.01452Z"
                fill={color}
            />
            <path
                d="M8.86014 12.8097H5.93096C5.71151 12.8097 5.50105 12.7225 5.34587 12.5673C5.19069 12.4122 5.10352 12.2017 5.10352 11.9822C5.10352 11.7628 5.19069 11.5523 5.34587 11.3971C5.50105 11.242 5.71151 11.1548 5.93096 11.1548H8.86014C9.07959 11.1548 9.29005 11.242 9.44523 11.3971C9.60041 11.5523 9.68758 11.7628 9.68758 11.9822C9.68758 12.2017 9.60041 12.4122 9.44523 12.5673C9.29005 12.7225 9.07959 12.8097 8.86014 12.8097Z"
                fill={color}
            />
            <path
                d="M5.93096 3.94507C5.71151 3.94507 5.50105 3.8579 5.34587 3.70272C5.19069 3.54754 5.10352 3.33708 5.10352 3.11762V1.13726C5.10352 0.91781 5.19069 0.707345 5.34587 0.552168C5.50105 0.396992 5.71151 0.309814 5.93096 0.309814C6.15042 0.309814 6.36088 0.396992 6.51606 0.552168C6.67124 0.707345 6.75841 0.91781 6.75841 1.13726V3.11762C6.75841 3.33708 6.67124 3.54754 6.51606 3.70272C6.36088 3.8579 6.15042 3.94507 5.93096 3.94507Z"
                fill={color}
            />
            <path
                d="M13.0691 3.94507C12.8497 3.94507 12.6392 3.8579 12.4841 3.70272C12.3289 3.54754 12.2417 3.33708 12.2417 3.11762V1.13726C12.2417 0.91781 12.3289 0.707345 12.4841 0.552168C12.6392 0.396992 12.8497 0.309814 13.0691 0.309814C13.2886 0.309814 13.4991 0.396992 13.6542 0.552168C13.8094 0.707345 13.8966 0.91781 13.8966 1.13726V3.11762C13.8966 3.33708 13.8094 3.54754 13.6542 3.70272C13.4991 3.8579 13.2886 3.94507 13.0691 3.94507Z"
                fill={color}
            />
        </svg>
    )
}
