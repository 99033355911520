import React from 'react'

export default function MinimalistCheck({
    size = 16,
    color = 'white',
    active = false,
    borderColor="#999999",
    ...rest
}) {
    if (active) {
        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...rest}
            >
                <rect
                    opacity="0.5"
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="4.5"
                    stroke={color}
                />
                <rect x="4" y="4" width="8" height="8" rx="2" fill={borderColor} />
            </svg>
        )
    }

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                opacity="0.5"
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="4.5"
                stroke={color}
            />
        </svg>
    )
}
