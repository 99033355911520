import { useContext, useEffect, useState } from 'react';
import { useFetchUpdates } from '../../common/redux/fetchUpdates';
import { useFetchUser } from '../../user/redux/fetchUser';
import { AudioPlayingContext } from '../components/contexts/AudioPlayingProvider';
import { useGetLatestMediaMessages } from '../redux/getLatestMediaMessages';
import { useGetMediaMessagesByThread } from '../redux/getMediaMessagesByThread';
import {
  useFetchConversationMessages,
  useFetchConversations,
  useFetchGroupChatMessages,
  useFetchGroupChats,
} from '../redux/hooks';

export default function Conversations({
  setConversationsList,
  setSelectedMessages,
  selectedTab,
  selectedConversation,
  searchText,
  selectedMessages,
}) {
  // conversations
  const { conversations, fetchConversations } = useFetchConversations();
  const { groupChats, fetchGroupChats } = useFetchGroupChats();
  const { getLatestMediaMessages, latestMediaMessages } =
    useGetLatestMediaMessages();

  const {
    setRecordingAudio,
    setMessagePlaying,
    setUploadedFile,
    recordingState,
  } = useContext(AudioPlayingContext);

  const { mediaBlobUrl, status, bruteForceStopRecording } = recordingState;

  useEffect(() => {
    setRecordingAudio(false);
    setMessagePlaying('');
    setUploadedFile(null);

    if (status === 'stopped' && mediaBlobUrl) {
      bruteForceStopRecording();
    }
  }, [selectedConversation]);

  const { user } = useFetchUser();

  const { updates } = useFetchUpdates(); // these are the updates from the server when a message is sent

  const { groupChatMessages, fetchGroupChatMessages } =
    useFetchGroupChatMessages();

  const { conversationMessages, fetchConversationMessages } =
    useFetchConversationMessages();

  const { getMediaMessagesByThread } = useGetMediaMessagesByThread();

  const [mediamessages, setMediamessages] = useState([]);

  useEffect(() => {
    if (!conversations && !groupChats?.results?.length && !user) return;

    getLatestMediaMessages({
      userId: user?.username,
      groupIds: groupChats?.results?.map((group) => group.id),
    });
    console.log('latestMediaMessages', latestMediaMessages);
  }, [user, conversations, groupChats]);

  // uncomment all ocurrences of this function to activate media messages
  const getMediaMessages = async ({ threadId, type, username }) => {
    getMediaMessagesByThread({
      threadId,
      username,
      isChatThread: type === 'p2p',
    })
      .then((messages) => {
        console.log('getMediaMessagesByThread', messages);
        setMediamessages(() => {
          let list = [];
          messages.data.forEach((message) => {
            list.push({
              id: message.id,
              timestamp: message.timestamp,
              user: message?.user ||
                message?.data?.user || {
                  id: 1,
                  first_name: 'a',
                  last_name: 'b',
                  photo: null,
                  username: message.userId,
                },
              type,
              media: message.type,
              mediaUrl: message.assetURL,
              text: 'none',
            });
          });
          return list;
        });
      })
      .catch((err) => {
        console.error('getMediaMessagesByThread', err.message);
      });
  };

  // This effect is used to filter the conversations based on the selected tab
  useEffect(() => {
    if (!conversations || !user) return;

    console.log('peerconversations', conversations);
    console.log('groupChats', groupChats);

    if (selectedTab === 'all') {
      setConversationsList(() => {
        let list = [];
        conversations?.results.forEach((conversation) => {
          const conv = {
            id: conversation.message_id,
            timestamp: conversation.timestamp,
            user:
              conversation.sender.id === user.id
                ? conversation.receiver
                : conversation.sender,
            type: 'p2p',
            read:
              conversation.timestamp === conversation.last_read_msg_timestamp ||
              conversation.sender.id === user.id,
            last_read_msg_timestamp: conversation.last_read_msg_timestamp,
            lastSender: conversation.sender.id === user.id,
          };
          list.push(conv);
        });
        groupChats?.results.forEach((groupChat) => {
          const message = {
            id: groupChat.id,
            timestamp: groupChat.last_message?.timestamp || 0,
            participants: groupChat.participants,
            name: groupChat.name,
            type: 'group',
            read:
              groupChat.last_message?.timestamp ===
                groupChat?.last_read_msg_timestamp ||
              groupChat.last_message?.user.id === user.id,
            last_read_msg_timestamp: groupChat.last_read_msg_timestamp,
            lastSender: groupChat.last_message?.sender?.id,
          };
          list.push(message);
        });

        return list;
      });
    } else if (selectedTab === 'messages') {
      setConversationsList(() => {
        let list = [];
        conversations.results.forEach((conversation) => {
          const message = {
            id: conversation.message_id,
            timestamp: conversation.timestamp,
            user:
              conversation.sender.id === user.id
                ? conversation.receiver
                : conversation.sender,
            type: 'p2p',
            read:
              conversation.timestamp ===
                conversation?.last_read_msg_timestamp ||
              conversation.sender.id === user.id,
            last_read_msg_timestamp: conversation.last_read_msg_timestamp,
            lastSender: conversation.sender.id,
          };
          list.push(message);
        });
        return list;
      });
    } else if (selectedTab === 'group') {
      setConversationsList(() => {
        let list = [];
        groupChats.results.forEach((groupChat) => {
          const message = {
            id: groupChat.id,
            timestamp: groupChat.last_message?.timestamp || 0,
            participants: groupChat.participants,
            name: groupChat.name,
            type: 'group',
            read:
              groupChat.last_message?.timestamp ===
                groupChat?.last_read_msg_timestamp ||
              groupChat.last_message?.user.id === user.id,
            last_read_msg_timestamp: groupChat.last_read_msg_timestamp,
            lastSender: groupChat.last_message?.sender?.id,
          };
          list.push(message);
        });
        return list;
      });
    }

    // iterate through conversationslist and compare the timestamp with the latestMediaMessages
    // if the timestamp is greater than the latestMediaMessages timestamp, then modify the conversation
    // to include the media messages
    setConversationsList((list) => {
      return list.map((conversation) => {
        if (conversation.type === 'p2p') {
          const mediaMessages = latestMediaMessages?.find((mediaMessage) => {
            return mediaMessage.counterpartId === conversation.user.username;
          });

          if (
            mediaMessages &&
            mediaMessages.timestamp > conversation.timestamp
          ) {
            conversation.timestamp = mediaMessages.timestamp;
            conversation.mediaMessages = mediaMessages.messages;
            conversation.read =
              mediaMessages.timestamp ===
                conversation.last_read_msg_timestamp ||
              mediaMessages.data.user.id === user.id;
          }
        } else if (conversation.type === 'group') {
          const mediaMessages = latestMediaMessages?.find((mediaMessage) => {
            return mediaMessage.counterpartId == conversation.id;
          });

          if (
            mediaMessages &&
            mediaMessages.timestamp > conversation.timestamp
          ) {
            console.log('mediaMessages', mediaMessages);

            conversation.timestamp = mediaMessages.timestamp;
            conversation.mediaMessages = mediaMessages.messages;
            conversation.read =
              mediaMessages.timestamp === conversation.last_read_msg_timestamp;
          }
        }

        return conversation;
      });
    });

    // sort the conversations by timestamp
    setConversationsList((list) => {
      return list.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    });
  }, [conversations, user, updates, selectedTab]);

  // This effect is used to fetch the messages for the selected conversation
  useEffect(() => {
    if (!user || !conversations) return;

    if (selectedConversation?.type === 'p2p') {
      fetchConversationMessages({
        peerId: selectedConversation?.user?.username,
      });
      getMediaMessages({
        threadId: selectedConversation.user.username,
        username: user.username,
        type: 'p2p',
      });
    }
  }, [selectedConversation, updates]);

  // This effect is used to fetch the messages for the selected group conversation
  useEffect(() => {
    if (selectedConversation?.type === 'group') {
      fetchGroupChatMessages({ chatId: selectedConversation?.id });
      getMediaMessages({
        threadId: selectedConversation?.id,
        type: 'group',
      });
    }
  }, [selectedConversation, updates]);

  // This effect is used to search conversations
  useEffect(() => {
    fetchConversations({ page: 0, search: searchText });
    fetchGroupChats({ page: 0, search: '' });
  }, [searchText]);

  useEffect(() => {
    fetchConversations({ page: 0, search: '' });
    fetchGroupChats({ page: 0, search: '' });
  }, [updates, selectedTab]);

  // This effect is used to fetch the messages for the selected conversation
  useEffect(() => {
    if (!conversations || !user || !conversationMessages || !groupChatMessages)
      return;

    let groupMessagesLength = groupChatMessages[
      selectedConversation?.id
    ]?.messages?.concat(mediamessages || [])?.length;

    let p2pMessagesLength = conversationMessages[
      selectedConversation?.user?.username
    ]?.messages?.concat(mediamessages || [])?.length;

    if (selectedConversation?.type === 'group') {
      if (selectedMessages?.length === groupMessagesLength) {
        console.log('group messages are equal');
        return;
      }
      setSelectedMessages(
        groupChatMessages[selectedConversation?.id]?.messages
          ?.concat(mediamessages || []) // sort by timestamp
          .sort((a, b) => {
            return a.timestamp - b.timestamp;
          }),
      );
    } else {
      if (selectedMessages?.length === p2pMessagesLength) {
        console.log('p2p messages are equal');
        return;
      }
      setSelectedMessages(
        conversationMessages[selectedConversation?.user?.username]?.messages
          .concat(mediamessages || [])
          .sort((a, b) => {
            return a.timestamp - b.timestamp;
          }),
      );
    }
  }, [
    conversationMessages,
    selectedConversation,
    mediamessages,
    groupChatMessages,
  ]);

  return null;
}
