// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { InternalSubscriptions, Contracts, Default } from './';

export default {
  path: 'subscriptions',
  childRoutes: [
    { path: 'internal-subscriptions', component: InternalSubscriptions },
    { path: 'contracts', component: Contracts },
    { path: 'default/:company/:type', component: Default },
  ],
};
