import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Roadmap } from '..';
import Icon from '../../common/components/Icon';
import { useAssignUserRoadmap } from '../../manage/redux/assignUserRoadmap';
import { useFetchUserRoadmaps } from '../../manage/redux/fetchUserRoadmaps';
import { useDeleteUserRoadmap } from '../../manage/redux/deleteUserRoadmap';

export default function AssignRoadmapModal({ show, handleClose, client }) {
  const { fetchUserRoadmaps } = useFetchUserRoadmaps();
  const { assignUserRoadmap } = useAssignUserRoadmap();
  const { deleteUserRoadmap } = useDeleteUserRoadmap();

  const [roadmaps, setRoadmaps] = useState(null);

  // add roadmap to user
  const handleAssignRoadmap = useCallback(
    (id) => () => {
      setRoadmaps((s) =>
        s.map((i) => ({
          ...i,
          assigned: id === i.id ? 'requesting' : i.assigned,
        })),
      );
      assignUserRoadmap({
        roadmapId: id,
        userId: client,
      });
    },
    [assignUserRoadmap, client],
  );

  // remove roadmap from user
  const handleArchiveRoadmapYes = useCallback(
    (id) => () => {
      setRoadmaps((s) =>
        s.map((i) => ({
          ...i,
          assigned: id === i.id ? false : i.assigned,
        })),
      );
      deleteUserRoadmap({ roadmapId: id, userId: client });
    },
    [deleteUserRoadmap, client],
  );

  useEffect(() => {
    if (client) {
      Promise.all([
        fetchUserRoadmaps({ userId: client, type: 'assigned' }),
        fetchUserRoadmaps({ userId: client, type: 'available' }),
      ]).then((values) => {
        setRoadmaps(
          values[0].data
            .map((i) => ({ ...i, assigned: true }))
            .concat(values[1].data.map((i) => ({ ...i, assigned: false })))
            .sort((a, b) => a.id - b.id),
        );
      });
    }
  }, [fetchUserRoadmaps, client]);

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <div className="rdmps-header-modal">
        <h3>Assign a roadmap</h3>
        <button onClick={handleClose}>
          <Icon name="close" size={23} color="#343434" />
        </button>
      </div>

      <div className="assign-roadmap-modal">
        {roadmaps && (
          <>
            {roadmaps
              .filter((r) => r.assigned)
              .map((r) => (
                <Roadmap
                  data={r}
                  key={r.id}
                  hideProgress={true}
                  onDeleteClick={handleArchiveRoadmapYes(r.id)}
                />
              ))}
            {roadmaps
              .filter((r) => !r.assigned)
              .map((r) => (
                <Roadmap
                  data={r}
                  key={r.id}
                  onAssignClick={handleAssignRoadmap(r.id)}
                  hideProgress={true}
                />
              ))}
          </>
        )}
      </div>
    </Modal>
  );
}
