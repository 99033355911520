import React from 'react';

export default function SendIcon({ size, color = '#343434' }) {
  return (
    <svg
      width={size}
      height={size * 1.67}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1189 10.5068L9 15.2801L0.86853 20.067L2.93287 10.5068L0.86853 0.933044L9 5.71991L17.1189 10.5068Z"
        stroke={color}
        strokeWidth="1.39"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.44897 10.5068H7.60282"
        stroke={color}
        strokeWidth="1.39"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
