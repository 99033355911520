import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_SET_DEFAULT_LANGUAGE } from './constants'

export function setDefaultLanguage(language) {
    return {
        type: AUTH_SET_DEFAULT_LANGUAGE,
        data: language || 'en',
    }
}

export function useSetDefaultLanguage() {
    const dispatch = useDispatch()
    const { defaultLanguage } = useSelector(state => state.auth)

    const boundAction = useCallback(
        (...params) => dispatch(setDefaultLanguage(...params)),
        [dispatch]
    )

    // get default language from query string (?lang=)
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const language = urlParams.get('lang')

        if (language) {
            boundAction(language)
        }
    }, [boundAction])

    return { defaultLanguage, setDefaultLanguage: boundAction }
}

export function reducer(state, action) {
    switch (action.type) {
        case AUTH_SET_DEFAULT_LANGUAGE:
            return {
                ...state,
                defaultLanguage: action.data,
            }

        default:
            return state
    }
}
