import React, { useEffect } from 'react'
import { DesktopHeader } from '../common'
import Switch from 'react-switch'
import { useFetchNotificationsSettings } from './redux/fetchNotificationsSettings'
import { useFetchUser } from '../user/redux/fetchUser'
import { useUpdateNotificationsSettings } from './redux/updateNotificationsSettings'
// import PropTypes from 'prop-types';

export default function Settings() {
    const [settings, setSettings] = React.useState({
        message: {
            email: true,
            push: true,
        },
        roadmap: {
            email: true,
            push: true,
        },
        assessment: {
            email: true,
            push: true,
        },
        individualCallReminder: {
            email: true,
            push: true,
        },
    })

    const {
        fetchNotificationsSettings,
        notificationsSettings,
    } = useFetchNotificationsSettings()
    const { updateNotificationsSettings } = useUpdateNotificationsSettings()

    const { user } = useFetchUser()

    useEffect(() => {
        if (!user?.username) return
        fetchNotificationsSettings({ userId: user.username })
    }, [user?.username])

    useEffect(() => {
        if (!notificationsSettings) return

        console.log('notificationsSettings', notificationsSettings)

        // set settings based on notificationsSettings
        const newSettings = {
            message: {
                email: !Boolean(notificationsSettings?.newDirectMessageEmail),
                push: !Boolean(notificationsSettings?.newDirectMessagePush),
            },
            roadmap: {
                email: !Boolean(notificationsSettings?.newRoadmapEmail),
                push: !Boolean(notificationsSettings?.newRoadmapPush),
            },
            assessment: {
                email: !Boolean(
                    notificationsSettings?.newAssessmentResultsEmail
                ),
                push: !Boolean(notificationsSettings?.newAssessmentResultsPush),
            },
            individualCallReminder: {
                email: !Boolean(
                    notificationsSettings?.individualCallReminderEmail
                ),
                push: !Boolean(
                    notificationsSettings?.individualCallReminderPush
                ),
            },
        }

        setSettings(newSettings)
    }, [notificationsSettings])

    const toggleSwitch = (setting, type) => {
        return checked => {
            switch (setting) {
                case 'message':
                    if (type === 'email') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'newDirectMessageEmail',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    if (type === 'push') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'newDirectMessagePush',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    break

                case 'roadmap':
                    if (type === 'email') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'newRoadmapEmail',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    if (type === 'push') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'newRoadmapPush',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    break

                case 'assessment':
                    if (type === 'email') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'newAssessmentResultsEmail',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    if (type === 'push') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'newAssessmentResultsPush',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    break

                case 'individualCallReminder':
                    if (type === 'email') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'individualCallReminderEmail',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    if (type === 'push') {
                        updateNotificationsSettings({
                            userId: user.username,
                            notificationType: 'individualCallReminderPush',
                            notificationValue: !checked,
                        }).then(() => {
                            fetchNotificationsSettings({
                                userId: user?.username,
                            })
                        })
                    }
                    break

                default:
                    break
            }

            setSettings({
                ...settings,
                [setting]: {
                    ...settings[setting],
                    [type]: checked,
                },
            })
        }
    }

    if (!user?.username) return null

    return (
        <div className="notifications-settings">
            <DesktopHeader />

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Notifications</th>
                            <th className="type">
                                <p>Email</p>
                            </th>
                            <th className="type">
                                <p>Push</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    paddingLeft: '2.8125rem',
                                }}
                            >
                                New Message
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch('message', 'email')}
                                    checked={settings.message.email}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch('message', 'push')}
                                    checked={settings.message.push}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    paddingLeft: '2.8125rem',
                                }}
                            >
                                New Roadmap
                            </td>
                            <td className="type">
                                <Switch
                                    onChange={toggleSwitch('roadmap', 'email')}
                                    checked={settings.roadmap.email}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch('roadmap', 'push')}
                                    checked={settings.roadmap.push}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    paddingLeft: '2.8125rem',
                                }}
                            >
                                New Assessment Result
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch(
                                        'assessment',
                                        'email'
                                    )}
                                    checked={settings.assessment.email}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch(
                                        'assessment',
                                        'push'
                                    )}
                                    checked={settings.assessment.push}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                        </tr>{' '}
                        <tr>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    paddingLeft: '2.8125rem',
                                }}
                            >
                                Meeting Reminder
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch(
                                        'individualCallReminder',
                                        'email'
                                    )}
                                    checked={settings.individualCallReminder.email}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                            <td>
                                <Switch
                                    onChange={toggleSwitch(
                                        'individualCallReminder',
                                        'push'
                                    )}
                                    checked={settings.individualCallReminder.push}
                                    onColor="#9DA16B"
                                    className="switch"
                                    height={16}
                                    width={32}
                                />
                            </td>
                        </tr>{' '}
                        <tr>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                            <td>
                                <hr />
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

Settings.propTypes = {}
Settings.defaultProps = {}
