import React from 'react';

export default function AssessmentsIcon({ size = 19, color = '#343434' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.2122 0H9.78775C4.39115 0 0 4.35864 0 9.71528V53.8109C0 59.1675 4.39115 63.5261 9.78775 63.5261H54.2122C59.6088 63.5261 64 59.1675 64 53.8109V9.71528C64 4.35864 59.6088 0 54.2122 0ZM57.8442 53.8109C57.8442 55.7967 56.2129 57.4159 54.2122 57.4159H9.78775C7.78711 57.4159 6.15582 55.7967 6.15582 53.8109V9.71528C6.15582 7.72945 7.78711 6.11024 9.78775 6.11024H54.2122C56.2129 6.11024 57.8442 7.72945 57.8442 9.71528V53.8109Z"
        fill={color}
      />
      <path
        d="M26.0185 16.2634L19.9858 22.2514L18.6007 20.8766C17.4004 19.6851 15.451 19.6851 14.2506 20.8766C13.0502 22.0681 13.0502 24.003 14.2506 25.1945L17.8107 28.7283C18.4161 29.3291 19.1958 29.6244 19.9858 29.6244C20.7758 29.6244 21.5658 29.3291 22.1609 28.7283L30.3789 20.5813C31.5793 19.3898 31.5793 17.4549 30.3789 16.2634C29.1785 15.0719 27.2291 15.0719 26.0288 16.2634H26.0185Z"
        fill={color}
      />
      <path
        d="M45.9532 23.168H34.6778C32.9747 23.168 31.5999 24.5326 31.5999 26.2231C31.5999 27.9136 32.9747 29.2782 34.6778 29.2782H45.9532C47.6563 29.2782 49.0311 27.9136 49.0311 26.2231C49.0311 24.5326 47.6563 23.168 45.9532 23.168Z"
        fill={color}
      />
      <path
        d="M26.0185 32.5676L19.9858 38.5557L18.6007 37.1809C17.4004 35.9894 15.451 35.9894 14.2506 37.1809C13.0502 38.3724 13.0502 40.3073 14.2506 41.4988L17.8107 45.0325C18.3853 45.6028 19.1753 45.9287 19.9858 45.9287C20.7963 45.9287 21.5863 45.6028 22.1609 45.0325L30.3686 36.8753C31.569 35.6838 31.569 33.7489 30.3686 32.5574C29.1682 31.3659 27.2189 31.3659 26.0185 32.5574V32.5676Z"
        fill={color}
      />
      <path
        d="M45.9532 39.4823H34.6778C32.9747 39.4823 31.5999 40.8469 31.5999 42.5374C31.5999 44.2279 32.9747 45.5925 34.6778 45.5925H45.9532C47.6563 45.5925 49.0311 44.2279 49.0311 42.5374C49.0311 40.8469 47.6563 39.4823 45.9532 39.4823Z"
        fill={color}
      />
    </svg>
  );
}
