export const coach_dashboard = {
  upcoming_calls: 'Upcoming Calls',
  search: 'Search',
  refine: 'Refine',
  apply_filters: 'Apply Filters',
  reset_filters: 'Reset Filters',
  eligibility: 'Eligibility',
  eligible: 'Eligible',
  ineligible: 'Ineligible',
  search_results_for: 'Search results for',
  clients: 'Clients',
  columns: {
    name: 'Name',
    clients: 'Clients',
    status: 'Status',
    diagnosis: 'Diagnosis',
    alerts: 'Alerts',
  },
  pending: 'Pending',
  resend_invitation: 'Resend Invitation',
  touch_points: 'Touch Points',
  engagement: 'Engagement',
  eligibility_period: 'Eligibility Period',
  days_left: 'Days Left',
  not_available: 'Not Available',
  ends: 'ends',
  of: 'of',
  clear_all: 'Clear All',
  view_profile: 'View Profile',
  alerts: 'Alerts',
  alert: 'Alert',
  no_alerts: 'No Alerts',
  view_all: 'View All',
  edit: 'Edit',
  delete: 'Delete',
  calls: 'Calls',
  has_call_scheduled: 'Has Call Scheduled',
  no_call_scheduled: 'No Call Scheduled',
  alert_type: 'Alert Type',
  mood: 'Mood',
  assessment: 'Assessment',
}
