import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import config from '../../common/config'
import { useHasActiveSubcription } from '../../common/permissions'
import { useGetSettingsByName } from '../auth/redux/getSettingsByName'
import { useSetDefaultLanguage } from '../auth/redux/setDefaultLanguage'
import Icon from '../common/components/Icon'
import { useLogout } from '../common/redux/logout'
import CheckoutForm from '../home/components/CheckoutForm'
import { useGetInvoicesStripe } from '../home/redux/getInvoicesStripe'
import { useGetProductByStripePriceId } from '../user/redux/getProductByStripePriceId'
import SelfSignupProgress from '../auth/components/SelfSignupProgress'

export default function Default() {
  const { logout } = useLogout()
  const { isActive } = useHasActiveSubcription()
  const { getProductByStripePriceId } = useGetProductByStripePriceId()
  const { getInvoicesStripe } = useGetInvoicesStripe()

  const { getSettingsByName, settings: data } = useGetSettingsByName()

  const history = useHistory()
  const location = useLocation()
  // get company from pathname
  const { company: name, type } = useParams()

  useEffect(() => {
    if (isActive) history.push('/')
  }, [isActive])

  useEffect(() => {
    getSettingsByName({ name, type })
  }, [name])

  useEffect(() => {
    const navbar = document.getElementsByClassName('navbar')[0]
    navbar.style.display = 'none'
  }, [])

  const [productInfo, setProductInfo] = useState({})

  const price_id = data?.subscription.defaultProduct || null
  const stripePromise = loadStripe(config.publishableKey)

  const [status, setStatus] = useState('paying') // paying, success

  const clientSecret =
    'pi_3Jz7R2GvgjKLpHZZ0N4AU8ZI_secret_9L5jftnEOimi0Xz2QTUoNHbfH'

  const appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      fontFamily: 'Poppins, sans-serif',
      colorText: '#6D786E',
      borderRadius: '10px',
      spacingUnit: '2.8px',
    },
  }

  const options = {
    appearance,
    clientSecret,
  }

  const [invoiceInfo, setInvoiceInfo] = useState({
    date: 1640107167,
  })

  useMemo(() => {
    if (status === 'success' && !invoiceInfo?.dueMonthly) {
      getInvoicesStripe().then(res => {
        const invoice = res.data.data.Invoices.data[0]
        setInvoiceInfo({
          ...invoiceInfo,
          billingPeriod: invoice.lines.data[0].plan.interval,
          billingDate: invoice.lines.data[0].period.start * 1000,
          dueMonthly: invoice.lines.data[0].plan.amount / 100,
        })
      })
    }
  }, [status])

  useMemo(
    () =>
      setInvoiceInfo({
        ...invoiceInfo,
        date: new Date(invoiceInfo.billingDate).getDate(),
      }),
    [invoiceInfo.billingDate]
  )

  // this useEffect helps change the checkout component according to the company
  useEffect(() => {
    if (!data && !productInfo) return

    if (status === 'paying' && !isNaN(productInfo?.unit_amount / 100)) {
      const submitButton = document.getElementById('submit')
      submitButton.style.backgroundColor = data.globals.colors.primary
    }
  }, [status, data, productInfo])

  useEffect(() => {
    if (price_id) {
      getProductByStripePriceId({ priceId: price_id })
        .then(res => {
          setProductInfo(res.data)
          console.log(res.data, 'productInfo')
        })
        .catch(err => console.log(err))
    }
  }, [price_id, getProductByStripePriceId, setProductInfo])

  const handleLogOut = useCallback(() => {
    logout()
    history.push('/log-in')
  }, [logout, history])

  const { defaultLanguage } = useSetDefaultLanguage()

  if (!data || isNaN(productInfo?.unit_amount / 100)) return <></>

  return (
    <div className="subscriptions-default">
      <div className="subscriptions-default-nav">
        <img src={data.globals.logo} alt="logo" />

        {/*    <p className="log-out" onClick={handleLogOut}>
                    {data.pages[defaultLanguage].payment.content.log_out}
                </p> */}

        <SelfSignupProgress settings={data} />
      </div>
      <div className="content-container">
        {data.pages[defaultLanguage].payment.media.background_video && (
          <video autoPlay muted loop className="video-background">
            <source
              src={data.pages[defaultLanguage].payment.media.background_video}
              type="video/mp4"
            />
          </video>
        )}

        {data.pages[defaultLanguage].payment.media.background_image && (
          <img
            src={data.pages[defaultLanguage].payment.media.background_image}
            alt="background"
            className="background-image"
          />
        )}

        <div className="payments-container">
          <h1 className="title">
            {status === 'paying'
              ? data.pages[defaultLanguage].payment.content.title
              : data.pages[defaultLanguage].payment.content.success}
          </h1>
          <p className="description">
            {status === 'paying'
              ? data.pages[defaultLanguage].payment.content.description
              : data.pages[defaultLanguage].payment.content.coach_prompt}
          </p>
          <div className="product-name-and-price">
            <h3 className="product">
              {status === 'paying'
                ? productInfo?.product?.name || ''
                : data.pages[defaultLanguage].payment.content
                    .billing_information || 'Billing Information'}
            </h3>
            {status === 'paying' && (
              <div className="flex flex-col" style={{ alignItems: 'flex-end' }}>
                {data.subscription.trial_end && (
                  <p
                    style={{
                      fontSize: '0.6rem',
                      marginBottom: '.2rem',
                      textAlign: 'right',
                    }}
                  >
                    {
                      data.pages[defaultLanguage].payment.content
                        .after_trial_ends
                    }
                  </p>
                )}
                <h3 className="price">${productInfo?.unit_amount / 100}/mo</h3>
              </div>
            )}{' '}
          </div>
          {status === 'paying' && (
            <Fragment>
              <div className="credit-cards">
                <h3 className="d-none d-lg-block">
                  {data.pages[defaultLanguage].payment.content.credit_card}
                </h3>
                <div className="brands">
                  <Icon name="visa" size={30} />
                  <Icon name="mastercard" />
                  <Icon name="amex" />
                  <Icon name="stripe" />
                </div>
              </div>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  iconColor="#6D786E"
                  productInfo={productInfo}
                  onSubmit={() => {
                    setStatus('success')
                  }}
                  {...(data?.subscription.trial_end && {
                    trial: data.subscription.trial_end,
                  })}
                  customText={data.pages[defaultLanguage].payment.content}
                />
              </Elements>
            </Fragment>
          )}
          {status === 'success' && (
            <div className="w-full">
              <div className="flex flex-col w-full gap-1 mrm-pb-1_5 bill-grid">
                <div className="flex items-center space-between w-full">
                  <p className="title">
                    {data.pages[defaultLanguage].payment.content.billing_period}
                  </p>
                  <p>monthly</p>
                </div>
                <div className="flex items-center space-between w-full">
                  <p className="title">
                    {data.pages[defaultLanguage].payment.content.billing_date}
                  </p>
                  <p>{invoiceInfo.date}</p>
                </div>
                <div className="flex items-center space-between w-full">
                  <p className="title">
                    {data.pages[defaultLanguage].payment.content.due_monthly}
                  </p>
                  <p>${invoiceInfo.dueMonthly}</p>
                </div>
              </div>
              <div className="flex flex-col">
                <a
                  className="terms"
                  href={data.pages[defaultLanguage].payment.content.terms_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  {data.pages[defaultLanguage].payment.content.terms_text}
                </a>
                <Link
                  to={
                    (location.pathname.split('/').includes('v2')
                      ? `/auth/v2/${name}/${type}/calendar`
                      : `/auth/${name}/${type}/calendar`) +
                    '?lang=' +
                    defaultLanguage
                  }
                >
                  <button
                    className="go-to-dashboard"
                    style={{
                      backgroundColor: '#BF5F4B',
                    }}
                  >
                    {
                      data.pages[defaultLanguage].payment.content
                        .go_to_dashboard_button
                    }
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Default.propTypes = {}
Default.defaultProps = {}
