import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback, useState, useContext } from 'react';
import { CompetencyContext } from '../../CompetencyPage';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useLocation } from 'react-router-dom';
import { useUnauthorizedErrorHandler } from '../../../../common/apiHelpers';
import {
  CurrentUserIsCoachOrAdmin,
  CurrentUserIsOnlyUser,
} from '../../../../common/roles';
import Icon from '../../../common/components/Icon';
import CustomDialog from '../../../common/CustomDialog';
import {
  useAddActionItem,
  useDeleteActionItem,
  useSetActionItemDetails,
} from '../../redux/hooks';
import ActionItem from './ActionItem';
import ActionItemDialog from './ActionItemDialog';

// import PropTypes from 'prop-types';

export default function ActionItems({
  className,
  roadmapId,
  competency,
  actionItems,
  user,
  studentId,
}) {
  const { setShowActionItemContainer } = useContext(CompetencyContext);

  const { addActionItem } = useAddActionItem();

  const { setActionItemDetails } = useSetActionItemDetails();

  const { deleteActionItem } = useDeleteActionItem();

  const [actionItemDialog, setActionItemDialog] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleAddActionItemClick = useCallback(
    () => setActionItemDialog('new'),
    [],
  );

  const handleAddClose = useCallback(() => setActionItemDialog(false), []);

  const handleDeleteClose = useCallback(() => setDeleteDialog(false), []);

  const handleEditClick = useCallback(
    (ai) => () => setActionItemDialog(ai),
    [],
  );

  const handleDeleteClick = useCallback((ai) => () => setDeleteDialog(ai), []);

  const handleSaveClick = useCallback(
    () =>
      ({ description, due_date }) => {
        const createNewActionItem = actionItemDialog === 'new';
        const action = createNewActionItem
          ? addActionItem
          : setActionItemDetails;
        const args = {
          roadmapId,
          stageId: competency.stage,
          competencyId: competency.id,
          actionItemId: actionItemDialog.id,
          data: {
            description,
            due_date: dayjs(due_date).format('YYYY-MM-DD'),
            competency: competency.id,
            parent: null,
          },
        };
        if (createNewActionItem && studentId && studentId !== user.id)
          args.data.student = studentId;
        setActionItemDialog(false);
        action(args).catch(useUnauthorizedErrorHandler);
      },
    [
      addActionItem,
      setActionItemDetails,
      actionItemDialog,
      setActionItemDialog,
      roadmapId,
      competency.id,
      competency.stage,
      user,
      studentId,
    ],
  );

  const handleDeleteYes = useCallback(
    (ai) => () => {
      const args = {
        roadmapId,
        stageId: competency.stage,
        competencyId: competency.id,
        actionItemId: ai.id,
        data: {
          student: studentId,
        },
      };
      setDeleteDialog(false);
      deleteActionItem(args).catch(useUnauthorizedErrorHandler);
    },
    [competency.stage, competency.id, roadmapId, deleteActionItem, studentId],
  );

  const location = useLocation();

  const isOnlyUser = CurrentUserIsOnlyUser();
  const isCoachOrAdmin = CurrentUserIsCoachOrAdmin();

  return (
    <Row
      className={clsx(
        'roadmap-components-competency-page-action-items',
        className,
      )}
    >
      <Col>
        <div className="header">
          <div className="text">
            {actionItems.length > 0 && isOnlyUser ? (
              <>
                <h2>Action Items</h2>
                <span className="">
                  {`(${competency.done_action_item_assessments_count} of ${actionItems.length} done)`}
                </span>
              </>
            ) : isCoachOrAdmin && actionItems.length > 0 ? (
              <>
                <h2>Action Items</h2>
                <span className="">
                  {`(${competency.done_action_item_assessments_count} of ${actionItems.length} done)`}
                </span>
              </>
            ) : isCoachOrAdmin && actionItems.length === 0 ? (
              <>
                <h2>Action Items</h2>
                <span className="">{`(0 of 0 done)`}</span>
              </>
            ) : (
              <></>
            )}
          </div>
          {isCoachOrAdmin && (
            <Link
              className="add-action-item"
              onClick={handleAddActionItemClick}
              to={location}
            >
              <Icon name="plus" color="#FFFFFF" size={13} />
            </Link>
          )}
        </div>

        <div className="action-item-container mrm-mb-1">
          {
            // Global action items
            actionItems
              .filter((item) => item.parent != null)
              .sort((a, b) => a.order - b.order || a.id - b.id)
              .map((ai) => (
                <ActionItem
                  key={ai.id}
                  data={ai}
                  onEdit={handleEditClick(ai)}
                  onDelete={handleDeleteClick(ai)}
                  canEdit={
                    user && user.features.can_add_action_items && !ai.parent
                  }
                  onClick={() => {
                    setShowActionItemContainer({
                      roadmapId,
                      stageId: competency.stage,
                      competencyId: competency.id,
                      actionItemId: ai.id,
                    });
                  }}
                />
              ))
          }

          {
            // User action items
            actionItems
              .filter((item) => item.parent === null)
              .sort((a, b) => a.id - b.id)
              .map((ai) => (
                <ActionItem
                  key={ai.id}
                  data={ai}
                  onClick={() => {
                    setShowActionItemContainer({
                      roadmapId,
                      stageId: competency.stage,
                      competencyId: competency.id,
                      actionItemId: ai.id,
                    });
                  }}
                  onEdit={handleEditClick(ai)}
                  onDelete={handleDeleteClick(ai)}
                  canEdit={
                    user && user.features.can_add_action_items && !ai.parent
                  }
                />
              ))
          }
        </div>

        <ActionItemDialog
          show={!!actionItemDialog}
          onHide={handleAddClose}
          onSave={handleSaveClick(actionItemDialog)}
          data={actionItemDialog}
        />

        <CustomDialog
          show={!!deleteDialog}
          onHide={handleDeleteClose}
          onYes={handleDeleteYes(deleteDialog)}
          text={{
            caption:
              'Deleting an action item is permanent. There is no way to undo this.',
            yes: 'Delete Action Item',
          }}
        />
      </Col>
    </Row>
  );
}

ActionItems.propTypes = {};
ActionItems.defaultProps = {};
