export const isValidDate = (year, month, day) => {
    month = Number(month)
    day = Number(day)
    year = Number(year)

    console.log(month, day, year)

    if (
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31 ||
        year < 1900 ||
        year > 2100 ||
        (month === 2 && day > 29) ||
        (month === 4 && day > 30) ||
        (month === 6 && day > 30) ||
        (month === 9 && day > 30) ||
        (month === 11 && day > 30) ||
        isNaN(month) ||
        isNaN(day) ||
        isNaN(year)
    ) {
        return false
    }

    return true
}
