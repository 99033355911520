import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserAvatar } from '../../../common';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';
export default function MyClients({
  title,
  user,
  type = 'students',
  desktop = true,
}) {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div
      className="clients-coaches-container"
      style={{ padding: desktop === true ? '2rem' : '1rem 1.5rem' }}
    >
      <p className="title">{title}</p>
      <div className="clients-coaches-grid">
        {user[type].map((student, idx) => (
          <div className="clients-coaches-grid-item" key={idx}>
            <div className="picture-name">
              <UserAvatar user={student} size="md" className="avatar" />
              <p>
                {student.first_name} {student.last_name}
              </p>
            </div>
            {type === 'students' && (
              <p
                className="view"
                role="button"
                onClick={() => {
                  history.push({
                    pathname: `/manage/user/${student.id}/`,
                    state: { backLink: history.location },
                  });
                  // reload the page to get the latest data
                  window.location.reload();
                }}
              >
                {t('profile.view_profile')}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
