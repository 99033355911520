import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    AUTH_GET_EMPLOYER_COVERAGE_BEGIN,
    AUTH_GET_EMPLOYER_COVERAGE_SUCCESS,
    AUTH_GET_EMPLOYER_COVERAGE_FAILURE,
    AUTH_GET_EMPLOYER_COVERAGE_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function getEmployerCoverage(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: AUTH_GET_EMPLOYER_COVERAGE_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.get(
                config.apiRootUrl + `/users/${args.userId}/employer-coverage`,
                {
                    ...createAxiosConfigWithAuth(getState()),
                }
            )
            doRequest.then(
                res => {
                    dispatch({
                        type: AUTH_GET_EMPLOYER_COVERAGE_SUCCESS,
                        data: res.data,
                    })
                    resolve(res.data)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: AUTH_GET_EMPLOYER_COVERAGE_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissGetEmployerCoverageError() {
    return {
        type: AUTH_GET_EMPLOYER_COVERAGE_DISMISS_ERROR,
    }
}

export function useGetEmployerCoverage() {
    const dispatch = useDispatch()

    const {
        getEmployerCoveragePending,
        getEmployerCoverageError,
        employerCoverage,
    } = useSelector(
        state => ({
            getEmployerCoveragePending: state.auth.getEmployerCoveragePending,
            getEmployerCoverageError: state.auth.getEmployerCoverageError,
            employerCoverage: state.auth.employerCoverage,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(getEmployerCoverage(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissGetEmployerCoverageError())
    }, [dispatch])

    return {
        employerCoverage, 
        getEmployerCoverage: boundAction,
        getEmployerCoveragePending,
        getEmployerCoverageError,
        dismissGetEmployerCoverageError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case AUTH_GET_EMPLOYER_COVERAGE_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                getEmployerCoveragePending: true,
                getEmployerCoverageError: null,
            }

        case AUTH_GET_EMPLOYER_COVERAGE_SUCCESS:
            // The request is success
            return {
                ...state,
                employerCoverage: action.data,
                getEmployerCoveragePending: false,
                getEmployerCoverageError: null,
            }

        case AUTH_GET_EMPLOYER_COVERAGE_FAILURE:
            // The request is failed
            return {
                ...state,
                getEmployerCoveragePending: false,
                getEmployerCoverageError: action.data.error,
            }

        case AUTH_GET_EMPLOYER_COVERAGE_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                getEmployerCoverageError: null,
            }

        default:
            return state
    }
}
