import React from 'react';

export default function RoadmapIcon({
  size = 23,
  color = '#343434',
  style = {},
  active = false,
}) {
  if (active) {
    return (
      <svg
        style={{ ...style }}
        width={size * 1.39}
        height={size}
        viewBox="0 0 78 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.18744 1.54029L2.18745 1.5403C2.77388 1.1785 3.50516 1.14145 4.1267 1.45096C4.1269 1.45106 4.12711 1.45116 4.12731 1.45126L21.11 9.8807L38.0918 1.4517C38.0924 1.45144 38.0929 1.45117 38.0934 1.45091C38.7143 1.14115 39.4467 1.17887 40.0326 1.5403L40.0343 1.54137C40.1137 1.59063 40.187 1.64417 40.2553 1.70132L57.1654 9.8865L74.1588 1.4517C74.1591 1.45157 74.1593 1.45144 74.1596 1.45132C74.7806 1.14112 75.5135 1.17871 76.0995 1.5403L76.1031 1.54249L76.1031 1.5425C76.6866 1.90693 77.0441 2.54683 77.0441 3.23807V43.8897C77.0441 44.648 76.6149 45.3409 75.9346 45.6783C75.9344 45.6783 75.9343 45.6784 75.9341 45.6785L58.0639 54.5505L58.0619 54.5514C57.7841 54.6884 57.4812 54.7583 57.1757 54.7583C56.8794 54.7583 56.5816 54.6927 56.3055 54.5588L2.18744 1.54029ZM2.18744 1.54029L2.18489 1.54188C1.59949 1.90626 1.24167 2.54654 1.24167 3.23807V43.8897C1.24167 44.6468 1.66994 45.3424 2.35273 45.6791C2.3529 45.6791 2.35307 45.6792 2.35324 45.6793L20.2187 54.5483C20.2189 54.5484 20.2191 54.5485 20.2193 54.5486C20.7783 54.8278 21.4374 54.8286 21.9965 54.5488C21.9972 54.5485 21.9979 54.5481 21.9986 54.5478L38.9254 46.1457L56.3047 54.5584L2.18744 1.54029ZM40.04 42.1965L40.0473 42.2013C40.1151 42.2453 40.1855 42.2957 40.2552 42.3536L57.1653 50.5381L73.0512 42.6519V6.45816L58.0648 13.8971C58.0647 13.8972 58.0646 13.8973 58.0644 13.8973L58.0639 13.8976L57.7267 13.2184L40.04 42.1965ZM40.04 42.1965L40.0326 42.1919M40.04 42.1965L40.0326 42.1919M40.0326 42.1919C39.4476 41.831 38.7121 41.7921 38.0923 42.1031C38.0915 42.1035 38.0908 42.1039 38.0901 42.1042L21.11 50.5336L5.23453 42.6531V6.45816L20.2199 13.8966C20.2203 13.8968 20.2206 13.897 20.2209 13.8971M40.0326 42.1919L20.2209 13.8971M21.9998 13.8961L38.9267 5.49402L56.3049 13.905C56.3053 13.9052 56.3056 13.9053 56.306 13.9055L21.9998 13.8961ZM21.9998 13.8961C21.9993 13.8964 21.9988 13.8967 21.9982 13.897C21.4391 14.1769 20.78 14.1763 20.2209 13.8971M21.9998 13.8961L20.2209 13.8971"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path
          d="M55.1794 52.7617C55.1794 53.8635 56.0718 54.7582 57.1758 54.7582C58.2798 54.7582 59.1722 53.8635 59.1722 52.7617V12.1101C59.1722 11.0083 58.2798 10.1137 57.1758 10.1137C56.0718 10.1137 55.1794 11.0083 55.1794 12.1101V52.7617Z"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path
          d="M36.9656 44.1215C36.9656 45.2232 37.8581 46.1179 38.962 46.1179C40.066 46.1179 40.9585 45.2232 40.9585 44.1215V3.4686C40.9585 2.36685 40.066 1.47217 38.962 1.47217C37.8581 1.47217 36.9656 2.36685 36.9656 3.4686V44.1215Z"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path
          d="M19.1133 52.7617C19.1133 53.8635 20.0058 54.7582 21.1098 54.7582C22.212 54.7582 23.1062 53.864 23.1062 52.7617V12.1101C23.1062 11.0083 22.2137 10.1137 21.1098 10.1137C20.0058 10.1137 19.1133 11.0083 19.1133 12.1101V52.7617Z"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path d="M5 5.5L19.5 13V49.5L5 42V5.5Z" fill="#595959" />
        <path d="M40 6L54.5 13.5V50L40 42.5V6Z" fill="#595959" />
        <path d="M38 6L23 13.5V50L38 42.5V6Z" fill="#595959" />
        <path d="M74 6L59 13.5V50L74 42.5V6Z" fill="#595959" />
      </svg>
    );
  } else {
    return (
      <svg
        style={{ ...style }}
        width={size * 1.39}
        height={size}
        viewBox="0 0 78 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.18744 1.54029L2.18745 1.5403C2.77388 1.1785 3.50516 1.14145 4.1267 1.45096C4.1269 1.45106 4.12711 1.45116 4.12731 1.45126L21.11 9.8807L38.0918 1.4517C38.0924 1.45144 38.0929 1.45117 38.0934 1.45091C38.7143 1.14115 39.4467 1.17887 40.0326 1.5403L40.0343 1.54137C40.1137 1.59063 40.187 1.64417 40.2553 1.70132L57.1654 9.8865L74.1588 1.4517C74.1591 1.45157 74.1593 1.45144 74.1596 1.45132C74.7806 1.14112 75.5135 1.17871 76.0995 1.5403L76.1031 1.54249L76.1031 1.5425C76.6866 1.90693 77.0441 2.54683 77.0441 3.23807V43.8897C77.0441 44.648 76.6149 45.3409 75.9346 45.6783C75.9344 45.6783 75.9343 45.6784 75.9341 45.6785L58.0639 54.5505L58.0619 54.5514C57.7841 54.6884 57.4812 54.7583 57.1757 54.7583C56.8794 54.7583 56.5816 54.6927 56.3055 54.5588L2.18744 1.54029ZM2.18744 1.54029L2.18489 1.54188C1.59949 1.90626 1.24167 2.54654 1.24167 3.23807V43.8897C1.24167 44.6468 1.66994 45.3424 2.35273 45.6791C2.3529 45.6791 2.35307 45.6792 2.35324 45.6793L20.2187 54.5483C20.2189 54.5484 20.2191 54.5485 20.2193 54.5486C20.7783 54.8278 21.4374 54.8286 21.9965 54.5488C21.9972 54.5485 21.9979 54.5481 21.9986 54.5478L38.9254 46.1457L56.3047 54.5584L2.18744 1.54029ZM40.04 42.1965L40.0473 42.2013C40.1151 42.2453 40.1855 42.2957 40.2552 42.3536L57.1653 50.5381L73.0512 42.6519V6.45816L58.0648 13.8971C58.0647 13.8972 58.0646 13.8973 58.0644 13.8973L58.0639 13.8976L57.7267 13.2184L40.04 42.1965ZM40.04 42.1965L40.0326 42.1919M40.04 42.1965L40.0326 42.1919M40.0326 42.1919C39.4476 41.831 38.7121 41.7921 38.0923 42.1031C38.0915 42.1035 38.0908 42.1039 38.0901 42.1042L21.11 50.5336L5.23453 42.6531V6.45816L20.2199 13.8966C20.2203 13.8968 20.2206 13.897 20.2209 13.8971M40.0326 42.1919L20.2209 13.8971M21.9998 13.8961L38.9267 5.49402L56.3049 13.905C56.3053 13.9052 56.3056 13.9053 56.306 13.9055L21.9998 13.8961ZM21.9998 13.8961C21.9993 13.8964 21.9988 13.8967 21.9982 13.897C21.4391 14.1769 20.78 14.1763 20.2209 13.8971M21.9998 13.8961L20.2209 13.8971"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path
          d="M55.1794 52.7617C55.1794 53.8635 56.0718 54.7582 57.1758 54.7582C58.2798 54.7582 59.1722 53.8635 59.1722 52.7617V12.1101C59.1722 11.0083 58.2798 10.1137 57.1758 10.1137C56.0718 10.1137 55.1794 11.0083 55.1794 12.1101V52.7617Z"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path
          d="M36.9656 44.1215C36.9656 45.2232 37.8581 46.1179 38.962 46.1179C40.066 46.1179 40.9585 45.2232 40.9585 44.1215V3.4686C40.9585 2.36685 40.066 1.47217 38.962 1.47217C37.8581 1.47217 36.9656 2.36685 36.9656 3.4686V44.1215Z"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path
          d="M19.1133 52.7617C19.1133 53.8635 20.0058 54.7582 21.1098 54.7582C22.212 54.7582 23.1062 53.864 23.1062 52.7617V12.1101C23.1062 11.0083 22.2137 10.1137 21.1098 10.1137C20.0058 10.1137 19.1133 11.0083 19.1133 12.1101V52.7617Z"
          fill={color}
          stroke={color}
          strokeWidth="1.51667"
        />
        <path d="M5 5.5L19.5 13V49.5L5 42V5.5Z" fill="white" />
        <path d="M40 6L54.5 13.5V50L40 42.5V6Z" fill="white" />
        <path d="M38 6L23 13.5V50L38 42.5V6Z" fill="white" />
        <path d="M74 6L59 13.5V50L74 42.5V6Z" fill="white" />
      </svg>
    );
  }
}
