import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MESSAGES_CREATE_MEDIA_MESSAGE_BEGIN,
  MESSAGES_CREATE_MEDIA_MESSAGE_SUCCESS,
  MESSAGES_CREATE_MEDIA_MESSAGE_FAILURE,
  MESSAGES_CREATE_MEDIA_MESSAGE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';

export function createMediaMessage(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: MESSAGES_CREATE_MEDIA_MESSAGE_BEGIN,
    });

    const { type, data, userId, threadId, counterpartId } = args;

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        `${config.messagesMediaUrl}/mediamessages/${threadId}`,
        {
          type,
          userId,
          data,
          counterpartId,
        },
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: MESSAGES_CREATE_MEDIA_MESSAGE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MESSAGES_CREATE_MEDIA_MESSAGE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateMediaMessageError() {
  return {
    type: MESSAGES_CREATE_MEDIA_MESSAGE_DISMISS_ERROR,
  };
}

export function useCreateMediaMessage() {
  const dispatch = useDispatch();

  const { createMediaMessagePending, createMediaMessageError } = useSelector(
    (state) => ({
      createMediaMessagePending: state.messages.createMediaMessagePending,
      createMediaMessageError: state.messages.createMediaMessageError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(createMediaMessage(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateMediaMessageError());
  }, [dispatch]);

  return {
    createMediaMessage: boundAction,
    createMediaMessagePending,
    createMediaMessageError,
    dismissCreateMediaMessageError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MESSAGES_CREATE_MEDIA_MESSAGE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createMediaMessagePending: true,
        createMediaMessageError: null,
      };

    case MESSAGES_CREATE_MEDIA_MESSAGE_SUCCESS:
      // The request is success
      return {
        ...state,
        createMediaMessagePending: false,
        createMediaMessageError: null,
      };

    case MESSAGES_CREATE_MEDIA_MESSAGE_FAILURE:
      // The request is failed
      return {
        ...state,
        createMediaMessagePending: false,
        createMediaMessageError: action.data.error,
      };

    case MESSAGES_CREATE_MEDIA_MESSAGE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createMediaMessageError: null,
      };

    default:
      return state;
  }
}
