import React from 'react';

export default function WalletIcon({ size = 20, color = '#6D786E' }) {
  return (
    <svg
      width={size}
      height={size / 1.26}
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_593_1440)">
        <path
          d="M31.5716 5.2V3.51258C31.5677 2.5821 31.1729 1.69077 30.4731 1.03282C29.7733 0.374867 28.8254 0.00362764 27.8357 0H3.73585C2.74829 0.00985507 1.80414 0.383094 1.10579 1.03971C0.407444 1.69632 0.0104815 2.58404 0 3.51258V22.4874C0.00385822 23.4179 0.398694 24.3092 1.09847 24.9672C1.79824 25.6251 2.74623 25.9964 3.73585 26H27.8724C28.862 25.9964 29.81 25.6251 30.5097 24.9672C31.2095 24.3092 31.6044 23.4179 31.6082 22.4874V15.6C32.0195 15.4276 32.3694 15.1477 32.6161 14.7935C32.8629 14.4394 32.9962 14.026 33 13.6026V7.19735C32.9817 6.77277 32.8385 6.3613 32.5863 6.00868C32.3341 5.65606 31.9829 5.37612 31.5716 5.2ZM31.0222 13.6026C31.0212 13.7028 30.9785 13.7987 30.9031 13.8695C30.8278 13.9404 30.7259 13.9806 30.6193 13.9815H19.778C19.6715 13.9806 19.5695 13.9404 19.4942 13.8695C19.4188 13.7987 19.3761 13.7028 19.3751 13.6026V7.19735C19.3761 7.09716 19.4188 7.00133 19.4942 6.93049C19.5695 6.85964 19.6715 6.81944 19.778 6.81854H30.6193C30.7259 6.81944 30.8278 6.85964 30.9031 6.93049C30.9785 7.00133 31.0212 7.09716 31.0222 7.19735V13.6026ZM27.4009 23.6934H4.20728C3.7316 23.6924 3.27568 23.5144 2.93932 23.1981C2.60296 22.8819 2.41357 22.4532 2.41261 22.0059V4.99338C2.41357 4.54613 2.60296 3.15449 2.93932 2.83824C3.27568 2.52198 3.7316 2.34391 4.20728 2.343H27.4009C27.8766 2.34391 28.3325 2.52198 28.6689 2.83824C29.0052 3.15449 29.1946 4.54613 29.1956 4.99338H19.778C19.1575 4.99699 18.5635 5.23035 18.1248 5.6429C17.686 6.05544 17.4378 6.61393 17.434 7.19735V13.5682C17.4378 14.1516 17.686 14.7101 18.1248 15.1227C18.5635 15.5352 19.1575 15.7686 19.778 15.7722H29.1956V22.0059C29.1862 22.4507 28.9942 22.8748 28.6596 23.1894C28.325 23.5039 27.874 23.6845 27.4009 23.6934Z"
          fill="#6D786E"
        />
        <path
          d="M23.0011 9.09131C22.7258 9.09131 22.4567 9.16806 22.2279 9.31185C21.999 9.45564 21.8206 9.66002 21.7153 9.89914C21.6099 10.1383 21.5824 10.4014 21.6361 10.6552C21.6898 10.9091 21.8223 11.1422 22.017 11.3252C22.2116 11.5083 22.4596 11.6329 22.7296 11.6834C22.9996 11.7339 23.2794 11.708 23.5337 11.6089C23.788 11.5099 24.0054 11.3421 24.1583 11.1269C24.3113 10.9117 24.3929 10.6587 24.3929 10.3999C24.3929 10.0529 24.2463 9.72 23.9852 9.47459C23.7242 9.22918 23.3702 9.09131 23.0011 9.09131Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_593_1440">
          <rect width={size} height={size / 1.26} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
