import { useHistory, useParams } from 'react-router-dom'
import { useConfirmResetPassword } from '../../../../home/redux/confirmResetPassword'
import { useFetchAuthToken } from '../../../../home/redux/fetchAuthToken'
import { useUserAgreement } from '../../../../user/redux/userAgreement'

export default function useConfirmPassword({ email, isCoach = false }) {
    
    const { confirmResetPassword } = useConfirmResetPassword()
    const { fetchAuthToken } = useFetchAuthToken()
    const { userAgreement: signAgreement } = useUserAgreement()
    const { uid, token } = useParams()

    const history = useHistory()

    const handleSubmit = async (values, setFieldError) => {
        confirmResetPassword({
            new_password1: values.password,
            new_password2: values.confirmPassword,
            uid,
            token,
        })
            .then(() => {
                fetchAuthToken({
                    email,
                    password: values.password,
                }).then(() => {
                    signAgreement({
                        privacy_policy: true,
                        terms_and_conditions: true,
                        dac: isCoach,
                    })
                        .then(() => {
                            if (isCoach) {
                                window.location.href = '/rpm/coach-dashboard'
                                return;
                            }
                            history.push('/rpm/signup/about-yourself')
                        })
                        .catch(() => {})
                })
            })
            .catch(err => {
                const errorMessage = Object.values(err.response.data)[0][0]

                setFieldError(
                    'confirmPassword',
                    errorMessage || 'Please try with a stronger password.'
                )
            })
    }

    return { handleSubmit }
}
