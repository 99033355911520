import { useCallback } from 'react';

export function useHandleSaveClick({
  photoFile,
  user,
  setIsLoading,
  userExists,
  assignCoachToStudent,
  firstVals,
  selectedRoadmaps,
  bulkAssignUserRoadmaps,
  subSelected,
  subscriptionInvitationCheckout,
  setStep,
  handleError,
  addUser,
  onSuccessfulSave,
}) {
  const handleSaveClick = useCallback(
    (values) => {
      setIsLoading(true);
      const data = new FormData();
      data.append('groups', 'User');
      data.append('is_approved', true);
      data.append('creation_origin', 'invited');

      console.log(values);
      Object.keys(values).forEach((key) => {
        if (['cohort'].includes(key)) {
          if (values[key].length > 0) {
            values[key].map((i) => data.append(key, i.id));
          }
        } else if (key === 'photo') {
          !!photoFile && data.append('photo', photoFile);
        } else {
          data.append('coach_write_only', user.id);
          data.append(key, values[key]);
        }
      });
      if (userExists) {
        assignCoachToStudent({ email: firstVals?.email })
          .then((res) => {
            setIsLoading(true);
            if (selectedRoadmaps.length > 0) {
              return bulkAssignUserRoadmaps({
                userId: res.data.id,
                ids: selectedRoadmaps.map((i) => i.id),
              });
            } else return true;
          })
          .then((res) => {
            console.log(subSelected.price.id);
            subscriptionInvitationCheckout({
              email: firstVals?.email,
              price_id: subSelected.price.id,
            })
              .then(() => {
                setStep(5);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err.response);
                handleError(err?.response.data);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        addUser(data)
          .then((res) => {
            setIsLoading(true);
            if (selectedRoadmaps.length > 0) {
              return bulkAssignUserRoadmaps({
                userId: res.id,
                ids: selectedRoadmaps.map((i) => i.id),
              });
            } else {
              return true;
            }
          })
          .then((res) => {
            if (typeof onSuccessfulSave === 'function') {
              onSuccessfulSave();
            } else {
              subscriptionInvitationCheckout({
                email: firstVals?.email,
                price_id: subSelected.price.id,
              }).then(() => {
                setStep(5);
                setIsLoading(false);
              });
            }
          })
          .catch((e) => {
            console.log(e.response);
          });
      }
    },
    [
      subSelected,
      subscriptionInvitationCheckout,
      bulkAssignUserRoadmaps,
      addUser,
      onSuccessfulSave,
      photoFile,
      handleError,
      selectedRoadmaps,
      firstVals,
      userExists,
      assignCoachToStudent,
      user,
      setIsLoading,
      setStep,
    ],
  );
  return handleSaveClick;
}
