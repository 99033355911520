import React, { useCallback, useState } from 'react';
import OnboardingInvitation from './components/OnboardingInvitation';
import { useWindowSize } from '../../common/windowsSize';
import { useTranslation } from '../translations/redux/setDefaultLanguage';
// import PropTypes from 'prop-types';

export default function OnboardingInvitationPage() {
  const size = useWindowSize();
  const [saveButtonProps, setSaveButtonProps] = useState({});
  const { t } = useTranslation();

  const renderSaveButton = useCallback(
    () => (
      <button
        className={'invitation-submit ' + (size.width < 768 ? 'mrm-mb-3' : '')}
        {...saveButtonProps}
      >
        {t('dashboard.onboarding.fourth_step.send_invitation')}
      </button>
    ),
    [saveButtonProps, size],
  );

  return (
    <div className="dashboard-onboarding-invitation-page">
      <OnboardingInvitation
        setSaveButtonProps={setSaveButtonProps}
        saveButton={renderSaveButton()}
      />
    </div>
  );
}

OnboardingInvitationPage.propTypes = {};
OnboardingInvitationPage.defaultProps = {};
