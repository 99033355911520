import { Widget } from '@typeform/embed-react'
import React from 'react'
import { useFetchUser } from '../user/redux/fetchUser'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

export default function Typeform() {
  const { user } = useFetchUser()

  const { id } = useParams()

  if (!user) return null

  return (
    <div className="home-typeform">
      <Widget
        id={id}
        fullScreen
        hidden={{ user_id: user.id, app_signature: '9c7c82b4e7c827b81f24' }}
        style={{ width: '100vw', margin: '0 auto', height: '100vh' }}
        className="my-form"
        onSubmit={() => (window.location.href = '/rpm/download-app')}
      />
    </div>
  )
}

Typeform.propTypes = {}
Typeform.defaultProps = {}
