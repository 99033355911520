import { useCallback, useEffect } from 'react';

export default function useSetLocalStorage({
  actionItemId,
  responseMessage,
  setResponseMessage,
}) {
  const addResponseToLocalStorage = useCallback(
    (e) => {
      localStorage.setItem(
        `responseMessage-${actionItemId}`,
        JSON.stringify({
          ...responseMessage,
          message: e.target.value,
        }),
      );
    },
    [actionItemId, responseMessage],
  );

  const removeResponseFromLocalStorage = useCallback(() => {
    localStorage.removeItem(`responseMessage-${actionItemId}`);
  }, [actionItemId]);

  useEffect(() => {
    // if there is text in local storage, set it as the response message
    const responseMessageFromStorage = localStorage.getItem(
      `responseMessage-${actionItemId}`,
    );
    if (responseMessageFromStorage) {
      setResponseMessage(JSON.parse(responseMessageFromStorage));
    }
  }, [actionItemId, setResponseMessage]);

  return {
    addResponseToLocalStorage,
    removeResponseFromLocalStorage,
  };
}
