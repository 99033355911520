import React from 'react';
import Icon from '../../../../common/components/Icon';

export default function RecordCard({
  title = '',
  date = null,
  score = 0,
  icon = <></>
}) {
  return (
    <div className="health-data-record-card">
      <div className="health-data-record-card-left">
       {icon}
        <div className="title-and-text">
          <div className="title-container">
            <h3>{title}</h3>
            <Icon name="faq" color="#A4A2A1" size={14} />
          </div>
          {
            date && (
              <p>Valid for {date}</p>
            )
          }
        </div>
      </div>
      <div className="health-data-record-card-right">
        <p>{score}</p>
      </div>
    </div>
  );
}
