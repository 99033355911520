import React, { useState } from 'react';
import { Spinner, Row, Form, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../common/components/Icon';
import useWindowSize from '../../common/useWindowResize';
import AgreementsCheckbox from './AgreementsCheckbox';

export default function SignUpForm({
  isLoading,
  validated,
  handleSubmit,
  passwordError,
  firstNameError,
  emailError,
  emailExists,
  lastNameError,
  ...props
}) {
  const { agreement, setAgreement } = props;

  const [formState, setFormState] = useState({});

  const size = useWindowSize();

  const handleFormFieldChange = (event) => {
    const updatedFormState = Object.assign({}, formState);
    updatedFormState[event.target.id] = event.target.value;
    setFormState(updatedFormState);
  };

  const ErrorIcon = (
    <Icon
      name="info"
      color="#E8744F"
      size={22}
      style={{
        transform: 'rotate(180deg)',
        right: 30,
        marginTop: '0.6rem',
        marginRight: size.width < 991 ? '0.5rem' : '',
      }}
    />
  );

  return (
    <Row>
      {isLoading ? (
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Submitting...</span>
          </Spinner>
        </Col>
      ) : (
        <Col className="mrm-mt-0_5">
          {/*<h1 className="mrm-mb-2_5">{signupCompanyName ? `${signupCompanyName} Sign up` : "Sign up"}</h1>*/}
          <Form
            className="mrm-mb-1_5"
            validated={validated}
            onSubmit={(event) => handleSubmit(event, formState)}
          >
            <p className="form-title">Setup Account</p>
            <Form.Group controlId="formFirstName">
              <Icon name="user" color="#6D786E" />
              {firstNameError !== ' ' && ErrorIcon}
              <Form.Control
                type="text"
                placeholder="First Name"
                value={formState.formFirstName || ''}
                onChange={handleFormFieldChange}
                className={`${firstNameError !== ' ' ? 'is-invalid-form' : ''}`}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Icon name="user" color="#6D786E" />
              {lastNameError !== ' ' && ErrorIcon}
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={formState.formLastName || ''}
                onChange={handleFormFieldChange}
                className={`${lastNameError !== ' ' ? 'is-invalid-form' : ''}`}
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="email-form">
              <Icon
                name="email"
                color="#6D786E"
                size={13}
                style={{
                  marginTop: '0.9rem',
                }}
              />
              {emailError !== ' ' && ErrorIcon}
              <Form.Control
                placeholder="Email"
                value={formState.formEmail || ''}
                onChange={handleFormFieldChange}
                className={`${emailError !== ' ' ? 'is-invalid-form' : ''} `}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Icon
                name="lock"
                color="#6D786E"
                style={{
                  marginTop: '0.65rem',
                }}
              />
              {passwordError !== ' ' && ErrorIcon}
              <Form.Control
                type="password"
                placeholder="Password"
                value={formState.formPassword || ''}
                onChange={handleFormFieldChange}
                className={`${passwordError !== ' ' ? 'is-invalid-form' : ''}`}
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Icon
                name="lock"
                color="#6D786E"
                style={{
                  marginTop: '0.65rem',
                }}
              />
              {passwordError !== ' ' && ErrorIcon}

              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={formState.formConfirmPassword || ''}
                onChange={handleFormFieldChange}
                isInvalid={passwordError !== ' '}
                className={`${passwordError !== ' ' ? 'is-invalid-form' : ''}`}
              />
              {passwordError !== ' ' && (
                <p className="validation">{passwordError}</p>
              )}
              {firstNameError !== ' ' && (
                <p className="validation">{firstNameError}</p>
              )}
              {emailError !== ' ' && <p className="validation">{emailError}</p>}
              {lastNameError !== ' ' && (
                <p className="validation">{lastNameError}</p>
              )}
              {emailExists && <p className="validation">{emailExists}</p>}
            </Form.Group>

            <AgreementsCheckbox
              agreement={agreement}
              onChange={() => setAgreement(!agreement)}
              style={{
                margin: '0',
              }}
              isCoach
            />

            <Button
              style={{
                gridColumn: 'span 2 / auto',
              }}
              variant="primary"
              type="submit"
              disabled={!agreement}
            >
              Create Account
            </Button>
            <Link
              to="/log-in"
              style={{
                gridColumn: 'span 2 / auto',
              }}
            >
              <p className="aha">
                Already have an account? <span>Sign in</span>
              </p>
            </Link>
          </Form>
        </Col>
      )}
    </Row>
  );
}
