import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CurrentUserIsAdmin } from '../../common/roles';
// import PropTypes from 'prop-types';
import Icon from '../common/components/Icon';
import CallsModal from './components/CallsModal';
import Card from './components/Card';
import { useOpensupportgroup } from './redux/opensupportgroup';

export default function Calls() {
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const isAdmin = CurrentUserIsAdmin();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [callToUpdate, setCallToUpdate] = useState(null);

  const { opensupportgroup, supportGroups: calls } = useOpensupportgroup();

  useEffect(() => {
    opensupportgroup({
      method: 'get',
    });
  }, []);

  useLayoutEffect(() => {
    if (!calls) return;

    // make all cards height equal
    const dropInCards = document.querySelectorAll('.drop-in-card');
    let maxHeight = 0;
    dropInCards.forEach((card) => {
      const height = card.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    dropInCards.forEach((card) => {
      card.style['min-height'] = `${maxHeight}px`;
    });
  }, [calls]);

  return (
    <div className="support-group-calls">
      <div className="container-header">
        <h2>
          Drop-in Groups{' '}
          <strong className="text-muted">({calls ? calls.length : '0'})</strong>
        </h2>
        {isAdmin && (
          <button className="plus-btn" onClick={() => setShowModal(true)}>
            <Icon name="plus" color="var(--pure-white)" size={13.5} />
          </button>
        )}
      </div>
      {calls && calls.length === 0 && (
        <div className="no-calls">
          <div className="icon-container">
            <Icon name="group" size={15} color="var(--primary-gray-lightest)" />
          </div>
          <p>No drop-in groups.</p>
        </div>
      )}

      {calls && calls.length > 0 && (
        <div className="group-calls-list">
          {calls.map((call, idx) => {
            return (
              <Card
                key={idx}
                call={call}
                canEdit={isAdmin}
                onEdit={() => {
                  setCallToUpdate(call);
                  setShowUpdateModal(true);
                }}
                onDelete={() => {
                  setCallToUpdate(call);
                  setShowUpdateModal(true);
                  setShowDelete(true);
                }}
              />
            );
          })}
        </div>
      )}

      {showUpdateModal && (
        <CallsModal
          update
          show={showUpdateModal}
          setShow={setShowUpdateModal}
          call={callToUpdate}
          showDelete={showDelete}
          setShowDelete={setShowDelete}
        />
      )}

      <CallsModal show={showModal} setShow={setShowModal} />
    </div>
  );
}

Calls.propTypes = {};
Calls.defaultProps = {};
