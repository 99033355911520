import React from 'react';
import { components } from 'react-select';
import Icon from '../../../common/components/Icon';

// this component lets you add an icon to the select dropdown
// of the react-select component

export default function ValueContainer({ children, ...props }) {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <Icon
            name={props.selectProps.icon}
            size={12}
            className="custom-icon"
            color={props.selectProps.iconColor}
          />
        )}
        {children}
      </components.ValueContainer>
    )
  );
}
