import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFetchUser } from '../../../../user/redux/fetchUser';
import FitbitLogo from '../../../assets/connect-your-device/fitbit-logo.png';
import Background from '../../../assets/signup-background.png';

import useEntranceAnimation from '../../../../common/hooks/useEntranceAnimation';
import { useListDevices } from '../../../redux/listDevices';

export default function DesktopConnect() {
  const { user } = useFetchUser();
  const history = useHistory();

  const { initialOpacity } = useEntranceAnimation();

  const { devices } = useListDevices()


  if (!user || !devices) return null;

  return (
    <div
      className="rpm-connect-your-device-desktop"
      style={{
        opacity: initialOpacity,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      <div className="rpm-connect-card">
        <div className="rpm-connect-card-header">
          <img className="background" src={Background} alt="background" />
          <div className="overlay" />
          <img
            src={
              user.photo ||
              `https://ui-avatars.com/api/?name=${user.first_name[0] + user.last_name[0]
              }&background=343434&color=ffffff&size=128`
            }
            alt="user"
            className="user-photo"
          />
          <h1>Connect Your Device to Add Your Health Data</h1>
          <a>View eligible devices</a>

          <div className="rpm-progress-bar">
            <div className="rpm-progress-bar-fill" />
          </div>
        </div>
        <div className="rpm-connect-card-body">
          <div className="watch-options">
            {/*  <button
              className="watch-option"
              onClick={() => history.push('/rpm/fitbit-connection')}
            >
              <img src={FitbitLogo} alt="Fitbit Logo" />
            </button> */}
            {devices &&
              devices.results.map(device => (
                <button
                  className="watch-option"
                  onClick={() => history.push(`/rpm/${device.description}-connection`)}
                >
                  <img src={device.logo} alt={`${device.name} logo`} />
                </button>
              ))
            }
            <p className="footer-text">More devices coming soon!</p>
            <button className="no-device">I don't have a device</button>
          </div>
        </div>
      </div>
    </div>
  );
}
