import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import useOutsideAlerter from '../../../../common/useOutsideAlerter'
import Icon from '../../../common/components/Icon'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../../../user/redux/fetchUser'
import { useDeleteCptLog } from '../../redux/deleteCptLog'
import { useGetBilling } from '../../redux/getBilling'
import AddCptCodeModal from './AddCptCodeModal'

export default function BillingSection({ userId }) {
    const { getBilling, billing, getBillingPending } = useGetBilling()
    const { deleteCptLog } = useDeleteCptLog()

    const [tooltipShown, setTooltipShown] = React.useState(-1)
    const ref = useRef(null)
    useOutsideAlerter(ref, () => setTooltipShown(-1))

    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    const [selectedRecord, setSelectedRecord] = React.useState(null)
    const [showEditModal, setShowEditModal] = React.useState(false)

    useEffect(() => {
        getBilling({ userId })
    }, [])

    const { user } = useFetchUser()
    const { t } = useTranslation()

    return (
        <div className="rpm-client-profile__container__right-body__billing">
            {getBillingPending &&
                Array.from({ length: 10 }).map((_, idx) => (
                    <BillingCardLoader key={idx} />
                ))}

            {billing &&
                billing.results &&
                billing.results.map(
                    (
                        { id, date, call_length, notes, cpt_info, created_by },
                        idx
                    ) => {
                        const { title, code, description } = cpt_info
                        return (
                            <div
                                className="rpm-client-profile__container__right-body__billing__record"
                                key={idx}
                            >
                                <div className="rpm-client-profile__container__right-body__billing__record__header">
                                    <p className="rpm-client-profile__container__right-body__billing__record__header__title">
                                        <strong> {title} </strong>- {code}
                                    </p>
                                    {/* <p
                                        className="rpm-client-profile__container__right-body__billing__record__header__dots"
                                        role="button"
                                    >
                                        ...
                                    </p> */}
                                </div>
                                <div className="rpm-client-profile__container__right-body__billing__record__header-2">
                                    <div className="rpm-client-profile__container__right-body__billing__record__header-2__left">
                                        <p className="rpm-client-profile__container__right-body__billing__record__header-2__left__title">
                                            <strong>
                                                {t('client_profile.created_by')}
                                                :{' '}
                                            </strong>{' '}
                                            {created_by}
                                        </p>
                                        <p className="rpm-client-profile__container__right-body__billing__record__header-2__left__subtitle">
                                            {date}
                                        </p>
                                    </div>
                                    <div className="rpm-client-profile__container__right-body__billing__record__header-2__right">
                                        <p className="rpm-client-profile__container__right-body__billing__record__header-2__right__text">
                                            {t('client_profile.length_of_call')}
                                            : {call_length || 0}
                                            min
                                        </p>
                                        {/* <p className="rpm-client-profile__container__right-body__billing__record__header-2__right__text">
                                            Time Monitored: 30 min.
                                        </p> */}
                                    </div>
                                </div>
                                <div className="rpm-client-profile__container__right-body__billing__record__body">
                                    <p className="rpm-client-profile__container__right-body__billing__record__body__title">
                                        {t('client_profile.notes')}:
                                    </p>
                                    <p className="rpm-client-profile__container__right-body__billing__record__body__text">
                                        {notes}
                                    </p>
                                </div>
                                {user.user_is_internal_coach &&
                                    (user.groups.includes('Coach') ||
                                        user.groups.includes('Admin')) && (
                                        <button
                                            className="rpm-client-profile__container__right-body__billing__record__dots"
                                            onClick={() => {
                                                setTooltipShown(
                                                    tooltipShown === idx
                                                        ? -1
                                                        : idx
                                                )
                                            }}
                                            type="button"
                                            style={{
                                                outline: 'none',
                                            }}
                                        >
                                            <Icon
                                                name={'threedots'}
                                                color="#C4C4C4"
                                                size={3}
                                            />
                                        </button>
                                    )}

                                {tooltipShown === idx && (
                                    <div
                                        className="rpm-client-profile__container__right-body__billing__record__tooltip"
                                        ref={ref}
                                    >
                                        <div
                                            className="rpm-client-profile__container__right-body__billing__record__tooltip__item"
                                            role="button"
                                            onClick={() => {
                                                setSelectedRecord({
                                                    cpt_id: cpt_info.id,
                                                    id,
                                                    month: Number(
                                                        date.split('-')[1]
                                                    ),
                                                    day: Number(
                                                        date.split('-')[2]
                                                    ),
                                                    year: Number(
                                                        date.split('-')[0]
                                                    ),
                                                    call_length:
                                                        call_length || '',
                                                    notes: notes || '',
                                                })
                                                setTooltipShown(-1)
                                                setShowEditModal(true)
                                            }}
                                            style={{
                                                borderBottom:
                                                    '1px solid rgba(196, 196, 196, 0.3)',
                                            }}
                                        >
                                            <p className="rpm-client-profile__container__right-body__billing__record__tooltip__item__text">
                                                {t('client_profile.edit')}
                                            </p>
                                        </div>
                                        <div
                                            className="rpm-client-profile__container__right-body__billing__record__tooltip__item"
                                            role="button"
                                            onClick={() => {
                                                setTooltipShown(-1)
                                                setShowDeleteModal(true)
                                                setSelectedRecord({
                                                    id,
                                                })
                                            }}
                                        >
                                            <p
                                                className="rpm-client-profile__container__right-body__billing__record__tooltip__item__text"
                                                style={{
                                                    color: '#E8744F',
                                                }}
                                            >
                                                {t('client_profile.remove')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    }
                )}

            <Modal
                className="delete-cpt-code-modal"
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
            >
                <h3>{t('client_profile.delete_cpt_record_question')}</h3>
                <p>
                    {' '}
                    {t(
                        'client_profile.are_you_sure_you_want_to_delete_this_record'
                    )}
                </p>
                <div className="delete-cpt-code-modal__buttons">
                    <button
                        className="delete-cpt-code-modal__buttons__button"
                        onClick={() => {
                            setShowDeleteModal(false)
                        }}
                    >
                        {t('client_profile.cancel')}
                    </button>
                    <button
                        className="delete-cpt-code-modal__buttons__button"
                        onClick={() => {
                            deleteCptLog({ id: selectedRecord.id }).then(() => {
                                setShowDeleteModal(false)
                                setTooltipShown(-1)
                                setSelectedRecord(null)
                                getBilling({ userId })
                            })
                        }}
                        style={{
                            backgroundColor: '#E8744F',
                            color: '#fff',
                            border: 'none',
                        }}
                    >
                        {t('client_profile.delete')}
                    </button>
                </div>

                <button
                    className="delete-cpt-code-modal__close"
                    style={{ outline: 'none' }}
                    onClick={() => setShowDeleteModal(false)}
                >
                    <Icon name="close" color="#343434" size={20} />
                </button>
            </Modal>

            <AddCptCodeModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                userId={userId}
                updateData={selectedRecord}
                showDeleteModal={() => setShowDeleteModal(true)}
            />
        </div>
    )
}

const BillingCardLoader = () => (
    <div className="billing-card-skeleton">
        <div className="billing-card-skeleton__title" />
        <div className="billing-card-skeleton__description-1" />
        <div className="billing-card-skeleton__description-2" />
        <div className="billing-card-skeleton__divider" />
        <div className="billing-card-skeleton__footer-1" />
        <div className="billing-card-skeleton__footer-2" />
        <div className="billing-card-skeleton__footer-3" />
        <div className="billing-card-skeleton__footer-4" />
    </div>
)
