import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function SelfSignupProgress({ settings }) {
  const history = useHistory()

  const [steps, setSteps] = React.useState([
    {
      label: 'Create Account',
      current: true,
      complete: false,
      pathIncludes: 'signup',
    },
    {
      label: 'Complete payment',
      current: false,
      complete: false,
      pathIncludes: 'subscription',
    },
    {
      label: 'Schedule First Call',
      current: false,
      complete: false,
      pathIncludes: 'calendar',
    },
    {
      label: 'Download the App',
      current: false,
      complete: false,
      pathIncludes: 'download',
    },
  ])

  React.useEffect(() => {
    if (!settings) return

    const path = history.location.pathname
    const newSteps = steps.map(step => {
      if (path.includes(step.pathIncludes)) {
        return {
          ...step,
          current: true,
        }
      }
      return {
        ...step,
        current: false,
      }
    })

    // mark all steps before the current step as complete
    const currentStepIndex = newSteps.findIndex(step => step.current)
    setSteps(steps =>
      newSteps.map((step, index) => {
        if (index < currentStepIndex) {
          return {
            ...step,
            complete: true,
          }
        }
        return step
      })
    )
  }, [history.location.pathname, settings])

  React.useEffect(() => {
    if (!settings) return

    if (settings.subscription.isDefaultStripe === false) {
      setSteps(steps => steps.filter(step => !step.label.includes('payment')))
    }
  }, [settings])

  return (
    <div className="self-signup-progress">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step ${step.current ? 'current' : ''} ${
            step.complete ? 'complete' : ''
          }`}
        >
          <div className="step-number-and-label">
            <div
              className="step-number"
              style={{
                backgroundColor: step.complete ? '#ebb551' : 'transparent',
                borderColor:
                  !step.current && !step.complete && 'rgba(52, 52, 52, 0.3)',
              }}
            >
              <p
                style={{
                  color:
                    !step.current && !step.complete
                      ? 'rgba(52, 52, 52, 0.3)'
                      : step.complete
                      ? 'white'
                      : '#ebb551',
                }}
              >
                {index + 1}
              </p>
              <div className="step-line"></div>
            </div>
            <div
              className="step-label"
              style={{
                color: step.current
                  ? 'rgba(52, 52, 52, 0.6)'
                  : 'rgba(52, 52, 52, 0.3)',
              }}
            >
              {step.label}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
