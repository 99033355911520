import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import { useFetchRoadmaps } from '../../../dashboard/redux/fetchRoadmaps';
import { ReactSelectStyles } from '../../../support/components/formContainer/utils/ReactSelectStyles';

export default function AssignRoadmapsForm() {
  const [selectedRoadmaps, setSelectedRoadmaps] = useState([]);
  const { fetchRoadmaps, roadmaps } = useFetchRoadmaps();
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    fetchRoadmaps();
  }, [fetchRoadmaps]);

  useEffect(() => {
    // if is editing an existing user, update the selected roadmaps
    if (values?.id && values.roadmaps_info?.length > 0) {
      console.log('values.roadmaps_info', values.roadmaps_info);
      setSelectedRoadmaps(
        values.roadmaps_info.map((roadmap) => ({
          id: roadmap.id,
          text: roadmap.title,
        })),
      );
    }
  }, [values?.id]);

  const handleChange = (inputValue) => {
    if (roadmaps.results.length > 0) {
      setSelectedRoadmaps(inputValue);
      setFieldValue(
        'roadmaps_info',
        inputValue?.reduce((acc, item) => {
          acc.push(item);
          return acc;
        }, []) || [],
      );
    }
  };

  if (!roadmaps?.results) return null;

  return (
    <div className="form-group">
      <Select
        isMulti
        defaultOptions
        options={
          roadmaps
            ? roadmaps.results
                .filter((r) => r.is_published === true)
                .map((item) => ({
                  id: item.id,
                  text: item.title,
                }))
            : null
        }
        name="roadmaps_info"
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.id}
        onChange={handleChange}
        value={selectedRoadmaps?.length > 0 ? selectedRoadmaps : null}
        styles={{
          ...ReactSelectStyles,
          valueContainer: (base) => ({
            ...ReactSelectStyles.valueContainer(base),
            paddingLeft: '1rem',
            height: 'auto',
          }),
          control: (base) => ({
            ...ReactSelectStyles.control(base),
            borderRadius: '0.8rem',
          }),
          placeholder: (base) => ({
            ...ReactSelectStyles.placeholder(base),
          }),
        }}
        placeholder="Select Roadmaps"
      />
    </div>
  );
}
