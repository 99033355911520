import React from 'react';

export default function PhoneIcon({
  className,
  color = '#343434',
  size,
  style = {},
}) {
  return (
    <svg
      width={size}
      height={size * 1.42}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ ...style }}
    >
      <path
        d="M4.99997 15.6667H2.99997V17.0001H4.99997V15.6667Z"
        fill={color}
      />
      <path
        d="M7.99997 15.6667H5.99997V17.0001H7.99997V15.6667Z"
        fill={color}
      />
      <path d="M11 15.6667H8.99997V17.0001H11V15.6667Z" fill={color} />
      <path
        d="M13.6666 2.00016C13.6666 1.55814 13.491 1.13421 13.1785 0.82165C12.8659 0.50909 12.442 0.333496 12 0.333496H1.99998C1.55795 0.333496 1.13403 0.50909 0.821472 0.82165C0.508912 1.13421 0.333313 1.55814 0.333313 2.00016V18.0002C0.333313 18.4422 0.508912 18.8661 0.821472 19.1787C1.13403 19.4912 1.55795 19.6668 1.99998 19.6668H12C12.442 19.6668 12.8659 19.4912 13.1785 19.1787C13.491 18.8661 13.6666 18.4422 13.6666 18.0002V2.00016ZM1.99998 1.66683H12C12.0445 1.66388 12.0892 1.67048 12.1309 1.68619C12.1727 1.7019 12.2107 1.72636 12.2422 1.75792C12.2738 1.78948 12.2982 1.82742 12.3139 1.8692C12.3297 1.91097 12.3363 1.95563 12.3333 2.00016V13.0002H1.66665V2.00016C1.6637 1.95563 1.67031 1.91097 1.68601 1.8692C1.70172 1.82742 1.72617 1.78948 1.75773 1.75792C1.78929 1.72636 1.82724 1.7019 1.86902 1.68619C1.9108 1.67048 1.95544 1.66388 1.99998 1.66683ZM12 18.3335H1.99998C1.95544 18.3364 1.9108 18.3298 1.86902 18.3141C1.82724 18.2984 1.78929 18.274 1.75773 18.2424C1.72617 18.2108 1.70172 18.1729 1.68601 18.1311C1.67031 18.0894 1.6637 18.0447 1.66665 18.0002V14.3335H12.3333V18.0002C12.3363 18.0447 12.3297 18.0894 12.3139 18.1311C12.2982 18.1729 12.2738 18.2108 12.2422 18.2424C12.2107 18.274 12.1727 18.2984 12.1309 18.3141C12.0892 18.3298 12.0445 18.3364 12 18.3335Z"
        fill={color}
      />
    </svg>
  );
}
