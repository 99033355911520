import React from 'react';
import { Container } from 'react-bootstrap';
import { DesktopHeader } from '../common';
import { useGetActiveContracts } from '../support/redux/getActiveContracts';
import { useFetchUser } from '../user/redux/fetchUser';
import ContractModal from './components/ContractModal';
import Sidebar from './components/Sidebar';
import SubscriptionTable from './components/SubscriptionTable';

// import PropTypes from 'prop-types';

export default function Contracts() {
  const { getActiveContracts } = useGetActiveContracts();
  const { user } = useFetchUser();

  const [tableData, setTableData] = React.useState([
    {
      name: 'Loading...',
    },
  ]);

  const [showModal, setShowModal] = React.useState(false);
  const [contract, setContract] = React.useState(null);

  React.useEffect(() => {
    const getContracts = () => {
      try {
        getActiveContracts({
          company: user.company_id,
        }).then((res) => {
          setTableData(res.data.results);
        });
      } catch {
        console.error('Error fetching products');
      }
    };
    getContracts();
  }, [getActiveContracts, user]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
      },
      {
        Header: 'Status',
        accessor: 'active',
      },
    ],
    [],
  );

  const data = React.useMemo(
    () =>
      tableData.map((data) => {
        const parseDate = (date) => {
          const newDate = new Date(date);
          const parseDate = newDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          return parseDate;
        };

        return {
          id: data.id,
          name: data.name,
          description: data.description,
          active: data.active,
          start_date: parseDate(data.start_date),
          end_date: parseDate(data.end_date),
          company: data.company,
        };
      }),
    [tableData],
  );

  function onRowClick(row) {
    return () => {
      const parseDate = (date) => {
        const newDate = new Date(date);
        return newDate.toISOString().split('T')[0];
      };

      setContract({
        id: row.id,
        name: row.name,
        description: row.description,
        active: row.active,
        start_date: parseDate(row.start_date),
        end_date: parseDate(row.end_date),
        company: row.company,
      });

      setShowModal(true);
    };
  }

  return (
    <div className="subscriptions-contracts">
      <DesktopHeader />
      <Container>
        <div className="subscriptions-contracts wrapper">
          <Sidebar title="Company Contracts" />
          <SubscriptionTable
            columns={columns}
            data={data}
            style={{ height: '800px' }}
            title="Contract"
            modal={ContractModal}
            onRowClick={onRowClick}
          />

          {contract && (
            <ContractModal
              update
              contract={contract}
              setContract={setContract}
              showModal={showModal}
              setShowModal={() => setShowModal(false)}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

Contracts.propTypes = {};
Contracts.defaultProps = {};
