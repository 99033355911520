import { createContext, useReducer } from 'react'



const constants = {
    SELECTING_CONVERSATION: 'SELECTING_CONVERSATION',
    EDITING_GROUP: 'EDITING_GROUP',
    CREATING_CONVERSATION: 'CREATING_CONVERSATION',
    CREATING_GROUP: 'CREATING_GROUP',
    WRITING_MESSAGE: 'WRITING_MESSAGE',

    // Selected tabs
    ALL_TAB: 'ALL_TAB',
    GROUPS_TAB: 'GROUPS_TAB',
    PEER_TAB: 'PEER_TAB',
}

const initialState = {
    selectedConversation: null,
    status: constants.SELECTING_CONVERSATION,
    selectedTab: constants.ALL_TAB,
    conversations: [],
}

const ChatContext = createContext()

export default function ChatProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const reducer = (state, action) => {
        switch (action.type) {
        }
    }

    return (
        <ChatContext.Provider value={{ state, dispatch }}>
            {children}
        </ChatContext.Provider>
    )
}
