import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import { useFetchAssignedUsers } from '../../../dashboard/redux/fetchAssignedUsers'
import { useUpdateUser } from '../../../manage/redux/updateUser'
import { ReactSelectStyles } from '../../../support/components/formContainer/utils/ReactSelectStyles'
import { initialValues } from '../../../user/EditProfile'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'

export default function AddCoachModal({ show, onHide, user, fetchUser }) {
  const [selectedCoaches, setSelectedCoaches] = useState([])
  const stateRef = useRef(selectedCoaches)
  const { assignedUsers, fetchAssignedUsers } = useFetchAssignedUsers()

  useEffect(() => {
    stateRef.current = selectedCoaches
  }, [selectedCoaches])

  useEffect(() => {
    if (user?.id && user.coach?.length > 0) {
      setSelectedCoaches(
        user.coach.map(coach => ({
          id: coach.id,
          value: `${coach.first_name} ${coach.last_name}`,
          label: `${coach.first_name} ${coach.last_name}`,
        }))
      )
    }
  }, [user?.id])

  useEffect(() => {
    console.log('user', user)
  }, [user])

  const loadCoaches = async inputValue => {

    return fetchAssignedUsers({
      search: inputValue,
    })
      .then(res => {
        console.log('internal coaches', res)
        return res.results.map(user => ({
          value: `${user.first_name} ${user.last_name}`,
          label: `${user.first_name} ${user.last_name}`,
          id: user.id,
        }))
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleChange = (inputValue, setFieldValue) => {
    if (assignedUsers?.results.length > 0) {
      setSelectedCoaches(inputValue)
      setFieldValue(
        'coach_write_only',
        inputValue?.reduce((acc, item) => {
          acc.push(item.id)
          return acc
        }, []) || []
      )
    }
  }

  const { updateUser } = useUpdateUser()

  const handleSave = values => {
    if (!user) return
    updateUser({
      userId: user.id,
      data: {
        coach_write_only: values.coach_write_only,
      },
    }).then(() => {
      fetchUser()
      onHide()
    })
  }

  const { t } = useTranslation()
  return (
    <Modal show={show} onHide={onHide} className="add-coach-modal">
      <h3>{t('client_profile.edit_care_team')}</h3>

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          //alert(JSON.stringify(values, null, 2));
          console.log('values', values)
          handleSave(values)
          setSubmitting(false)
        }}
      >
        {({ values, setFieldValue, errors, touched, handleSubmit }) => (
          <Form
            style={{
              marginTop: '2rem',
            }}
          >
            <AsyncSelect
              cacheOptions
              id="coach_write_only"
              isMulti
              placeholder={t('client_profile.assign_a_coach_to_your_client')}
              value={selectedCoaches}
              getOptionValue={option => option.id}
              defaultOptions
              loadOptions={loadCoaches}
              onChange={inputValue => handleChange(inputValue, setFieldValue)}
              styles={{
                ...ReactSelectStyles,
                valueContainer: base => ({
                  ...ReactSelectStyles.valueContainer(base),
                  paddingLeft: '1rem',
                  height: 'auto',
                }),
                control: base => ({
                  ...ReactSelectStyles.control(base),
                  borderRadius: '0.8rem',
                }),
                placeholder: base => ({
                  ...ReactSelectStyles.placeholder(base),
                }),
              }}
            />
            {errors.coach_write_only && touched.coach_write_only && (
              <div className="error-message">{errors.coach_write_only}</div>
            )}
            <button
              type="submit"
              className="submit-btn"
              onClick={() => handleSubmit()}
              style={{
                marginTop: '2rem',
              }}
            >
              {t('client_profile.save')}
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
