import React from 'react';

export default function VisaIcon({ size = 20 }) {
  return (
    <svg
      width={size * 1.3}
      height={size}
      viewBox="0 0 39 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7415 16.875H13.3222C12.8174 16.875 12.3333 16.6775 11.9764 16.3258C11.6195 15.9742 11.4189 15.4973 11.4189 15C11.4189 14.5027 11.6195 14.0258 11.9764 13.6742C12.3333 13.3225 12.8174 13.125 13.3222 13.125H24.7415C25.2463 13.125 25.7304 13.3225 26.0873 13.6742C26.4442 14.0258 26.6448 14.5027 26.6448 15C26.6448 15.4973 26.4442 15.9742 26.0873 16.3258C25.7304 16.6775 25.2463 16.875 24.7415 16.875Z"
        fill="#D6D6D6"
      />
      <rect
        x="2.5"
        y="3.5"
        width="34"
        height="23"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6253 19.2582H10.5049L8.91495 13.1924C8.83949 12.9133 8.67925 12.6667 8.44354 12.5504C7.85531 12.2582 7.20712 12.0257 6.5 11.9084V11.6749H9.91567C10.3871 11.6749 10.7406 12.0257 10.7996 12.4331L11.6245 16.8086L13.7438 11.6749H15.8052L12.6253 19.2582ZM16.9838 19.2582H14.9813L16.6302 11.6749H18.6327L16.9838 19.2582ZM21.2234 13.7757C21.2823 13.3673 21.6359 13.1337 22.0483 13.1337C22.6965 13.0751 23.4026 13.1924 23.9919 13.4835L24.3454 11.8508C23.7562 11.6173 23.108 11.5 22.5198 11.5C20.5762 11.5 19.162 12.5504 19.162 14.0082C19.162 15.1173 20.1637 15.6996 20.8708 16.0504C21.6359 16.4002 21.9305 16.6338 21.8716 16.9835C21.8716 17.5082 21.2823 17.7418 20.6941 17.7418C19.9869 17.7418 19.2798 17.5669 18.6327 17.2747L18.2791 18.9085C18.9862 19.1996 19.7512 19.3169 20.4584 19.3169C22.6376 19.3745 23.9919 18.3251 23.9919 16.75C23.9919 14.7665 21.2234 14.6502 21.2234 13.7757ZM31 19.2582L29.41 11.6749H27.7022C27.3486 11.6749 26.9951 11.9084 26.8772 12.2582L23.9329 19.2582H25.9943L26.4058 18.1502H28.9386L29.1743 19.2582H31ZM27.9968 13.7171L28.585 16.5751H26.9361L27.9968 13.7171Z"
        fill="#172B85"
      />
    </svg>
  );
}
