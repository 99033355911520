import React, { Fragment, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Icon from '../../../../common/components/Icon'
import { useCancelSubscriptionByCoach } from '../../../../user/redux/cancelSubscriptionByCoach'
import { useGetSubscriptionByCoachAndUser } from '../../../../user/redux/getSubscriptionByCoachAndUser'

export default function SubscriptionDetails({ user, fetchUser }) {
    const {
        subscription,
        getSubscriptionByCoachAndUser,
    } = useGetSubscriptionByCoachAndUser()

    const { cancelSubscriptionByCoach } = useCancelSubscriptionByCoach()

    useEffect(() => {
        if (!user) return
        getSubscriptionByCoachAndUser({ id: user.id })
    }, [user])

    useEffect(() => {
        console.log('SUBSCRIPTION', subscription, 'USER', user)
    }, [subscription])

    const [showCancelModal, setShowCancelModal] = React.useState(false)

    if (!subscription) return null

    return (
        <Fragment>
            <div className="profile-details-section__header mrm-mt-2">
                <p className="profile-details-section__header__title">
                    Subscription
                </p>
                <p
                    className="profile-details-section__header__edit"
                    role="button"
                ></p>
            </div>

            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="payouts" size={20} color="#595959" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            Subscription Name
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {subscription?.plan?.name || 'N/A'}
                    </p>
                </div>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon
                                name="modern-calendar"
                                color="#595959"
                                size={20}
                            />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            Start Date
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {subscription?.current_period_start
                            ? new Date(
                                  subscription?.current_period_start
                              ).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                              })
                            : 'N/A'}
                    </p>
                </div>
            </div>
            <div className="profile-details-section__body">
                <div className="profile-details-section__body__row">
                    <div className="profile-details-section__body__row__label">
                        <div className="profile-details-section__body__row__label__icon">
                            <Icon name="payouts" size={20} color="#595959" />
                        </div>
                        <p className="profile-details-section__body__row__label__title">
                            Rate
                        </p>
                    </div>

                    <p className="profile-details-section__body__row__value">
                        {subscription?.plan?.unit_amount
                            ? `$${subscription?.plan?.unit_amount / 100}.00`
                            : 'N/A'}
                    </p>
                </div>
            </div>
            {(subscription?.current_state === 'active' ||
                subscription?.current_state === 'canceled but active') && (
                <button
                    className="cancel-subscription-btn"
                    type="button"
                    onClick={() => setShowCancelModal(true)}
                >
                    Cancel Subscription
                </button>
            )}

            <Modal
                className="cancel-subscription-modal"
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}
            >
                <h3>Cancel Subscription</h3>
                <p>Are you sure you want to cancel your subsription?</p>

                <div className="cancel-subscription-modal__warning">
                    <p className="red-text">
                        Canceling your subscription will remove your coach from
                        your account. You will still have access to your roadmap
                        and journal entries.
                    </p>
                </div>

                <div className="cancel-subscription-modal__buttons">
                    <button
                        className="cancel-subscription-modal__buttons__button"
                        onClick={() => {
                            setShowCancelModal(false)
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="cancel-subscription-modal__buttons__button"
                        onClick={() => {
                            cancelSubscriptionByCoach({
                                user_pk: user.id,
                            }).then(() => {
                                fetchUser().then(() => {
                                    setShowCancelModal(false)
                                })
                            })
                        }}
                        style={{
                            backgroundColor: '#E8744F',
                            color: '#fff',
                            border: 'none',
                        }}
                    >
                        Cancel Sub
                    </button>
                </div>

                <button
                    className="cancel-subscription-modal__close"
                    style={{ outline: 'none' }}
                    onClick={() => setShowCancelModal(false)}
                >
                    <Icon name="close" color="#343434" size={20} />
                </button>
            </Modal>
        </Fragment>
    )
}
