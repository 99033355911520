import React from 'react'

export default function ProtocolIcon({
    color = '#40413F',
    size = 14,
    ...props
}) {
    return (
        <svg
            width={size}
            height={size * 1.4285714285714286}
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="0.6"
                y="2.6"
                width="12.8"
                height="16.8"
                rx="1.4"
                stroke={color}
                stroke-width="1.2"
            />
            <rect
                x="2.6"
                y="0.6"
                width="8.8"
                height="3.8"
                rx="1.4"
                fill="white"
                stroke={color}
                stroke-width="1.2"
            />
            <line
                x1="3.6"
                y1="8.4"
                x2="10.4"
                y2="8.4"
                stroke={color}
                stroke-width="1.2"
                stroke-linecap="round"
            />
            <line
                x1="3.6"
                y1="11.4"
                x2="10.4"
                y2="11.4"
                stroke={color}
                stroke-width="1.2"
                stroke-linecap="round"
            />
            <line
                x1="3.6"
                y1="14.4"
                x2="10.4"
                y2="14.4"
                stroke={color}
                stroke-width="1.2"
                stroke-linecap="round"
            />
        </svg>
    )
}
