import React from 'react';
import Form from 'react-bootstrap/Form';
import Icon from './components/Icon';

const SearchBar = ({ value, onSearch }) => {
  return (
    <div className="common-search-bar">
      <Form.Control
        className="search mrm-pl-2_5"
        placeholder="Search"
        value={value}
        onChange={onSearch}
      />
      <div className="search-icon">
        <Icon name="search" />
      </div>
    </div>
  );
};

export default SearchBar;

SearchBar.propTypes = {};
SearchBar.defaultProps = {};
