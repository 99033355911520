import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const PlusIcon = ({ size = 14, color = 'white', style = {} }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1118_5735)">
        <path
          d="M1.41418 6.92261H12.4309"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.92255 1.41425V12.431"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1118_5735">
          <rect
            width="9.79"
            height="9.79"
            fill={color}
            transform="translate(0 6.92261) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusIcon;
