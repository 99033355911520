import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { uniqBy } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, Row, Container } from 'react-bootstrap'
import Masonry from 'react-masonry-css'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useUnauthorizedErrorHandler } from '../../common/apiHelpers'
import { ActionMenu, CustomDialog, DesktopHeader, Loader } from '../common'
import Icon from '../common/components/Icon'
import { useFetchUpdates } from '../common/redux/fetchUpdates'
import useQuery from '../common/useQuery'
import useWindowResize from '../common/useWindowResize'
import { useFetchAssignedUsers } from '../dashboard/redux/fetchAssignedUsers'
import { DesktopInlineAdder } from '../manage/index'
import { useAddCompetency, useDeleteUserRoadmap } from '../manage/redux/hooks'
import { useFetchUser } from '../user/redux/hooks'
import ReviewingCard from './components/CompetencyPage/ReviewingCard'
import woman from './images/woman_with_cat.png'
import {
    useFetchRecentCompetency,
    useFetchRoadmap,
    useFetchRoadmapStagesInfinite,
    useFetchStageCompetencies,
} from './redux/hooks'
import { useSetSelectedCompetency } from './redux/setSelectedCompetency'
import Stage from './components/RoadmapPage/Stage'
import { createRef } from 'react'

export default function RoadmapPage() {
    const { fetchAssignedUsers, assignedUsers } = useFetchAssignedUsers()
    const history = useHistory()
    const location = useLocation()
    const { roadmapId } = useParams()
    const query = useQuery()
    const listContainer = useRef(null)
    const [scrolled, setScrolled] = useState(false)
    const [roadmapDetail, setRoadmapDetail] = useState(false)
    const [archiveDialog, setArchiveDialog] = useState(false)
    const { deleteUserRoadmap } = useDeleteUserRoadmap()
    const { selectedCompetency } = useSetSelectedCompetency()

    const unauthorizedErrorHandler = useUnauthorizedErrorHandler()
    const { roadmaps, fetchRoadmap } = useFetchRoadmap()
    const {
        stages,
        fetchRoadmapStagesInfinite,
    } = useFetchRoadmapStagesInfinite()
    const { competencies, fetchStageCompetencies } = useFetchStageCompetencies()
    const { updates } = useFetchUpdates()
    const { user, replaceStringWithSynonyms } = useFetchUser()
    const {
        recentCompetencies,
        fetchRecentCompetency,
    } = useFetchRecentCompetency()

    const { addCompetency, addCompetencyPending } = useAddCompetency()

    const [stageData, setStageData] = useState([])
    const [recentCompData, setRecentCompData] = useState([])
    const studentId = query && Number(query.get('user'))
    const [
        openCompetencyInlineAdders,
        setOpenCompetencyInlineAdders,
    ] = useState([])

    const scrollToStageId = query && Number(query.get('scrollToStage'))
    const [scrollPending, setScrollPending] = useState(true)

    const [stagesCount, setStagesCount] = useState(null)
    const windowSize = useWindowResize()

    useEffect(() => {
        const totalStages = Object.keys(stages)
            .map(key => stages[key])
            .filter(item => item.roadmap === Number(roadmapId)).length

        setStagesCount(totalStages)
    }, [stages, roadmapId])

    function updateStageData(stage, competencies) {
        stage.competencies = uniqBy(competencies, 'id').sort((a, b) => {
            if (a.order != null && b.order != null)
                return a.order > b.order ? 1 : -1
            return 0
        })

        setStageData(s =>
            uniqBy([...s, stage], 'id').sort((a, b) =>
                a.order > b.order ? 1 : -1
            )
        )
    }

    useEffect(() => {
        if (!roadmapId) history.push('/dashboard/roadmaps')
    }, [roadmapId, history])

    // Scrolling

    useEffect(() => {
        if (scrollToStageId === 0) {
            window.scrollTo(0, 0)
        }
    }, [scrollToStageId])

    useEffect(() => {
        let intervalId = 0

        if (scrollPending && !!scrollToStageId) {
            intervalId = setInterval(() => {
                const stageScroll = document.getElementById(
                    `stage-${scrollToStageId}`
                )

                if (
                    !!listContainer.current &&
                    !!stageScroll &&
                    listContainer.current.childElementCount === stagesCount
                ) {
                    clearInterval(intervalId)
                    setScrollPending(false)

                    stageScroll.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    }) //smooth
                }
            }, 10)
        }

        return () => {
            clearInterval(intervalId)
        }
    }, [scrollToStageId, listContainer, stagesCount, scrollPending])

    useEffect(() => {
        setTimeout(() => {
            if (listContainer.current && selectedCompetency) {
                var item = document
                    .getElementsByClassName(`scroll-${selectedCompetency}`)
                    .item(0)
                if (item) {
                    item.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    setScrolled(true)
                }
            }
        }, 1)
    }, [selectedCompetency, scrolled])

    useEffect(() => {
        if (!roadmaps[roadmapId]) return
        const newStageData = roadmaps[roadmapId].stage_ids
            .map(sid => stages[sid])
            .filter(s => !!s)
        newStageData.forEach(s => {
            s.competencies = s.competency_ids
                .map(cid => competencies[cid])
                .filter(c => !!c)
            s.competencies.sort((a, b) => {
                if (a.order != null && b.order != null)
                    return a.order > b.order ? 1 : -1
                return 0
            })
        })
        newStageData.sort((a, b) => (a.order > b.order ? 1 : -1))
        setStageData(newStageData)
    }, [roadmapId, roadmaps, stages, competencies])

    async function fetchRoadmapData() {
        const effectiveStudentId = studentId || user.id

        await fetchRoadmap({ roadmapId })
        const stages = await fetchRoadmapStagesInfinite({
            roadmapId,
            studentId: effectiveStudentId,
            ordering: 'order',
        })
        // TODO: omg, lol we need to remove this soon
        for (const stage of stages) {
            stage.competencies = await fetchStageCompetencies({
                roadmapId,
                studentId: effectiveStudentId,
                stageId: stage.id,
            })
            setStageData(s =>
                uniqBy([...s, stage], 'id').sort((a, b) =>
                    a.order > b.order ? 1 : -1
                )
            )
        }
    }

    useEffect(() => {
        if (!roadmapId || !query || !user?.id) {
            return
        }

        fetchRoadmapData()
            .catch(unauthorizedErrorHandler)
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    A('/dashboard/roadmaps')
                } else {
                    throw err
                }
            })
    }, [roadmapId, query, user])

    const userIsCoachOrAdmin =
        user && (user.groups.includes('Admin') || user.groups.includes('Coach'))

    useEffect(() => {
        if (!roadmapId || !query || !user || (userIsCoachOrAdmin && studentId))
            return

        fetchRecentCompetency()
            .catch(unauthorizedErrorHandler)
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    history.push('/dashboard/roadmaps')
                } else {
                    throw err
                }
            })
    }, [
        roadmapId,
        fetchRecentCompetency,
        unauthorizedErrorHandler,
        query,
        user,
        studentId,
        history,
        userIsCoachOrAdmin,
    ])

    useEffect(() => {
        if (
            !recentCompetencies ||
            !stageData ||
            (userIsCoachOrAdmin && studentId)
        ) {
            return
        }
        const comps = []
        stageData.forEach(stage => {
            const recents = stage.competencies.filter(
                comp =>
                    recentCompetencies.filter(
                        recent => recent.competency === comp.id
                    ).length > 0
            )
            if (recents.length > 0) {
                comps.push(...recents)
            }
        })
        setRecentCompData(comps)
    }, [recentCompetencies, stageData, userIsCoachOrAdmin, studentId])

    const roadmap = roadmaps[roadmapId]

    const handleArchiveRoadmapMenu = useCallback(() => {
        setRoadmapDetail(false)
        setArchiveDialog(true)
    }, [])

    const handleArchiveRoadmapYes = useCallback(() => {
        deleteUserRoadmap({ roadmapId, userId: user.id }).then(() => {
            history.push('/dashboard/roadmaps')
        })
    }, [deleteUserRoadmap, roadmapId, user, history])

    const handleArchiveDialogHide = useCallback(
        () => setArchiveDialog(false),
        []
    )

    const roadmapDetailItems = useCallback(() => {
        const items = []

        if (user.features.can_assign_specific_coaches_for_specific_roadmaps) {
            items.push({
                label: replaceStringWithSynonyms('Edit Assigned Coaches'),
                to: `/roadmap/${roadmapId}/edit-assigned-coaches`,
            })
        }
        if (user.features.can_assign_roadmaps) {
            items.push({
                label: user.features.roadmaps_are_archived
                    ? 'Archive Roadmap'
                    : 'Unassign Roadmap',
                className: 'text-danger',
                onClick: handleArchiveRoadmapMenu,
            })
        }

        return items
    }, [user, roadmapId, handleArchiveRoadmapMenu, replaceStringWithSynonyms])

    const handleRoadmapDetailHide = useCallback(
        () => setRoadmapDetail(false),
        []
    )

    // Competency inline adder
    const showCompetencyInlineAdder = useCallback(
        stageId => {
            if (!openCompetencyInlineAdders.includes(stageId)) {
                setOpenCompetencyInlineAdders([
                    ...openCompetencyInlineAdders,
                    stageId,
                ])
            }
        },
        [openCompetencyInlineAdders]
    )

    const hideCompetencyInlineAdder = useCallback(
        stageId => {
            if (openCompetencyInlineAdders.includes(stageId)) {
                setOpenCompetencyInlineAdders(
                    openCompetencyInlineAdders.filter(id => id !== stageId)
                )
            }
        },
        [openCompetencyInlineAdders]
    )

    const handleInlineCompetencyAdd = useCallback(
        stageId => title => {
            const student_id = !!studentId ? studentId : user?.id

            addCompetency({
                roadmapId,
                title,
                student: student_id,
                stage: stageId,
            }).then(newCompetency => {
                hideCompetencyInlineAdder(stageId)
                const stage = stageData.find(s => s.id === stageId)
                const competencies = [...stage.competencies, newCompetency]
                updateStageData(stage, competencies)
            })
        },
        [
            roadmapId,
            addCompetency,
            stageData,
            studentId,
            user,
            hideCompetencyInlineAdder,
        ]
    )

    const [studentUser, setStudentUser] = useState(null)

    useEffect(() => {
        if (!studentId) {
            return
        }
        fetchAssignedUsers({ userId: studentId })
    }, [fetchAssignedUsers, studentId])

    useEffect(() => {
        if (assignedUsers) {
            setStudentUser(assignedUsers.results.find(u => u.id === studentId))
        }
    }, [assignedUsers, studentId])

    const [currentStage, setCurrentStage] = useState(null)
    // this useEffect iterates through stageData and stageData.competencies and finds \
    // the latest competency that has last_assessment_status
    // if the last_assessment_status is not null, then set the currentStage to that stage
    useEffect(() => {
        if (stageData) {
            const latestStage = stageData.reduce((acc, stage) => {
                const latestCompetency = stage.competencies.reduce(
                    (acc, competency) => {
                        if (
                            competency.last_assessment_status &&
                            competency.last_assessment_status.status !== null
                        ) {
                            return competency
                        }
                        return acc
                    },
                    null
                )
                if (latestCompetency) {
                    return stage
                }
                return acc
            }, null)
            setCurrentStage(latestStage)
        }
    }, [stageData])

    const [selectedStage, setSelectedStage] = useState(null)

    const isDesktop = windowSize.width > 991
    const isMobile = windowSize.width < 991

    useEffect(() => {
        // console.log('stagedata', stageData);
        if (history.location?.state?.stageId && !selectedStage && stageData) {
            setSelectedStage(
                stageData.find(s => s.id === history.location?.state?.stageId)
            )
        }
    }, [history.location.state, stageData, selectedStage])

    const stagesRef = useRef([])
    stagesRef.current = stageData
        .filter(stage => {
            if (isDesktop) {
                return stage.id === selectedStage?.id
            } else return true
        })
        .map((_, idx) => stagesRef.current[idx] ?? createRef())

    useEffect(() => {
        if (isMobile && selectedStage) {
            // scroll to the selected stage
            stagesRef.current[
                stageData.findIndex(s => s.id === selectedStage.id)
            ].current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [isMobile, selectedStage])

    if (!roadmap || !user) {
        return <Loader />
    }

    const unseenCompetencies =
        updates?.unseen_activity?.[studentId || user.id]?.competencies

    return (
        <div className="roadmap-default-page">
            <DesktopHeader />
            <div className="container-lg roadmap-container">
                <ReviewingCard
                    studentId={studentId}
                    studentUser={studentUser}
                    showActionItemContainer={selectedStage}
                    byRoadmap
                />

                <Masonry
                    breakpointCols={{ default: 2, 991: 1 }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    <div
                        className="table-of-contents-container"
                        style={{
                            width: selectedStage
                                ? '100%'
                                : !isDesktop
                                ? '100%'
                                : '120%',
                            margin: '0 auto',
                            transform: selectedStage
                                ? 'translateX(0)'
                                : `${
                                      isDesktop
                                          ? 'translateX(35%)'
                                          : 'translateX(0)'
                                  }`,
                            transition: 'transform 0.5s ease-in-out',
                        }}
                    >
                        <div
                            style={{
                                backgroundImage: `url(${roadmap?.icon ||
                                    woman})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: '100% 5%',
                                backgroundSize: 'cover',
                                height: '40vh',
                                backgroundColor: '#718371',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            className="image-container"
                        >
                            <div className="title-content">
                                <h1>{roadmap.title}</h1>
                                {roadmap && (
                                    <>
                                        <div className="progress">
                                            <div
                                                className="progress-bar"
                                                style={{
                                                    width: `${roadmap.stats.total_progress}%`,
                                                }}
                                            />
                                        </div>
                                        <div className="progress-text text-center font-size-smaller mrm-mt-0_25">
                                            <p>
                                                {roadmap.stats.total_progress.toFixed()}
                                                % complete
                                            </p>
                                        </div>
                                    </>
                                )}
                                {recentCompData && recentCompData.length > 0 && (
                                    <>
                                        {recentCompData.map(comp => (
                                            <Link
                                                key={comp.id}
                                                className="no-format continue-btn"
                                                to={{
                                                    pathname: `/roadmap/${roadmap.id}/stage/${comp.stage}/competency/${comp.id}`,
                                                    search: studentId
                                                        ? `user=${studentId}`
                                                        : null,
                                                    state: {
                                                        backLink: location,
                                                    },
                                                }}
                                            >
                                                <svg
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="20"
                                                        fill="#343434"
                                                    />
                                                    <path
                                                        d="M25.545 18.6393C26.1995 19.0265 26.1995 19.9735 25.545 20.3607L21.5021 22.7522L17.5083 25.1096C16.8417 25.5031 16 25.0226 16 24.2485V19.5V14.7515C16 13.9774 16.8417 13.4969 17.5083 13.8904L21.5021 16.2478L25.545 18.6393Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                Continue
                                            </Link>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="table-of-contents">
                            <h3 className="mrm-pb-1">Content</h3>
                            {stageData.map((stage, idx) => (
                                <Card
                                    key={idx}
                                    onClick={() => {
                                        setSelectedStage(stage)
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                    }}
                                >
                                    <div className="content">
                                        <div
                                            className={`stage-title ${
                                                stage === currentStage
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            {stage === currentStage && (
                                                <Icon
                                                    name="circle"
                                                    size={9}
                                                    color="var(--primary-orange)"
                                                />
                                            )}
                                            <h2>{stage.title}</h2>
                                        </div>
                                        <Icon name="chevronRight" />
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </div>

                    <div
                        className={`my-story`}
                        // if selectedStage is null and changes to true, show my-story after a delay
                        style={{
                            opacity: selectedStage
                                ? 1
                                : !selectedStage && isDesktop
                                ? 0
                                : 1,
                            transition: 'opacity 0.75s ease-in-out',
                        }}
                    >
                        <div ref={listContainer}>
                            {stageData
                                .filter(stage => {
                                    if (isDesktop) {
                                        return stage.id === selectedStage?.id
                                    } else return true
                                })
                                .map((stage, idx) => (
                                    <div
                                        className={clsx('stage mrm-px-1', {
                                            card: isDesktop,
                                        })}
                                        key={stage.id}
                                        ref={stagesRef.current[idx]}
                                    >
                                        <div
                                            className={`stage-scroll-to ${
                                                !!studentId
                                                    ? 'with-student-header'
                                                    : ''
                                            } stage-${stage.id}`}
                                            id={`stage-${stage.id}`}
                                        ></div>

                                        <h2>{stage.title}</h2>
                                        {stage.description && (
                                            <p>{stage.description}</p>
                                        )}
                                        {userIsCoachOrAdmin &&
                                            stage.coach_notes && (
                                                <p className="coach-notes">
                                                    {stage.coach_notes}
                                                </p>
                                            )}

                                        {stage.competencies.length > 0 &&
                                            stage.competencies
                                                .sort((a, b) => {
                                                    return (
                                                        a.user_defined -
                                                            b.user_defined ||
                                                        a.order - b.order ||
                                                        a.id - b.id
                                                    )
                                                })
                                                .map(comp => (
                                                    <Link
                                                        key={comp.id}
                                                        className="no-format"
                                                        to={{
                                                            pathname: `/roadmap/${roadmap.id}/stage/${comp.stage}/competency/${comp.id}`,
                                                            search: studentId
                                                                ? `user=${studentId}`
                                                                : null,
                                                            state: {
                                                                backLink: location,
                                                            },
                                                        }}
                                                    >
                                                        <Stage
                                                            showNonStudentAssessment={
                                                                user.features
                                                                    .coach_or_admin_can_assess_objectives
                                                            }
                                                            comp={comp}
                                                            studentId={
                                                                studentId
                                                            }
                                                            user={user}
                                                            replaceStringWithSynonyms={
                                                                replaceStringWithSynonyms
                                                            }
                                                            unseenCompetencies={
                                                                unseenCompetencies
                                                            }
                                                        />
                                                    </Link>
                                                ))}
                                        {userIsCoachOrAdmin &&
                                            !!studentId &&
                                            user.features
                                                .coach_admin_can_assign_user_specific_competencies && (
                                                <Link
                                                    to={{
                                                        pathname: `/manage/roadmaps/${roadmapId}/stages/${stage.id}/competencies/add`,
                                                        search: `student=${studentId}`,
                                                        editCompetency: true,
                                                        state: {
                                                            backLink: location,
                                                        },
                                                    }}
                                                    className="d-lg-none"
                                                >
                                                    <Button
                                                        variant="white"
                                                        className="btn-center mrm-my-1 w-100"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                            className="mrm-mr-0_5"
                                                            size="sm"
                                                        />
                                                        Add Student Specific
                                                        Competency
                                                    </Button>
                                                </Link>
                                            )}
                                        {!openCompetencyInlineAdders.includes(
                                            stage.id
                                        ) &&
                                            userIsCoachOrAdmin &&
                                            !!studentId &&
                                            user.features
                                                .coach_admin_can_assign_user_specific_competencies && (
                                                <Button
                                                    variant="white"
                                                    className="btn-center mrm-mb-1 mrm-mt-0_5 mrm-white-on-white-box-shadow w-100 d-none d-lg-block"
                                                    onClick={() =>
                                                        showCompetencyInlineAdder(
                                                            stage.id
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="mrm-mr-0_5"
                                                        size="sm"
                                                    />
                                                    Add Student Specific
                                                    Competency
                                                </Button>
                                            )}
                                        {openCompetencyInlineAdders.includes(
                                            stage.id
                                        ) && (
                                            <DesktopInlineAdder
                                                maxLength={500}
                                                onCancel={() =>
                                                    hideCompetencyInlineAdder(
                                                        stage.id
                                                    )
                                                }
                                                onAdd={handleInlineCompetencyAdd(
                                                    stage.id
                                                )}
                                                loading={addCompetencyPending}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                </Masonry>
            </div>

            <Container className="normal-width-container">
                <Row></Row>
                <ActionMenu
                    show={roadmapDetail}
                    onHide={handleRoadmapDetailHide}
                    items={roadmapDetailItems()}
                />
            </Container>

            <CustomDialog
                show={archiveDialog}
                text={{
                    caption: user.features.roadmaps_are_archived
                        ? 'Archive Roadmap?'
                        : 'Unassign Roadmap? Your progress will be saved.',
                    yes: 'Yes',
                }}
                onHide={handleArchiveDialogHide}
                onYes={handleArchiveRoadmapYes}
            />
        </div>
    )
}

RoadmapPage.propTypes = {}
RoadmapPage.defaultProps = {}
