import dayjs from 'dayjs'
import React from 'react'
import Icon from '../../../common/components/Icon'
import ProfilePictureContainer from './ProfilePictureContainer'
import { useTranslation } from '../../../translations/redux/setDefaultLanguage'
import { useFetchUser } from '../../redux/fetchUser'

export default function BasicInfo({
  user,
  onEdit,
  cohortNames,
  groupNames,
  desktop,
  styles,
  horizontal = false,
  subscription = false,
  onClick,
  disabled,
}) {
  const { t } = useTranslation()

  const { user: viewer } = useFetchUser()

  if (!user) return null

  if (horizontal === false) {
    return (
      <div
        className={`profile-grid-container ${styles}`}
        style={{ padding: desktop === true ? '3rem' : '1rem 1.5rem' }}
      >
        <div className="profile-grid-container-header">
          <h1>{t('profile.my_profile')}</h1>
          <button onClick={onEdit}>
            <p>{t('profile.edit')}</p>
          </button>
        </div>
        {desktop === true ? (
          <ProfilePictureContainer user={user} onEdit={onEdit} />
        ) : (
          ''
        )}
        <div className="profile-grid-container-body">
          <div className="profile-items-container">
            <div className="profile-item">
              <Icon name="user" color="#6D786E" />
              <p>{t('profile.account_type')}</p>
            </div>
            <p>{groupNames}</p>
          </div>
          <hr />
          {(viewer.email === user.email || viewer.groups.includes('Admin')) && (
            <div className="profile-items-container">
              <div className="profile-item">
                <Icon name="email" color="#6D786E" size={14} />
                <p>{t('profile.email')}</p>
              </div>
              <p>{user.email}</p>
            </div>
          )}
          <hr />
          {(viewer.email === user.email || viewer.groups.includes('Admin')) && (
            <div className="profile-items-container">
              <div className="profile-item">
                <Icon name="phone" size={15} color="#6D786E" />
                <p>Phone</p>
              </div>
              <p>{user.phone_number ? user.phone_number : 'None'}</p>
            </div>
          )}
          <hr />
          <div className="profile-items-container">
            <div className="profile-item">
              <Icon name="date" size={18} color="#6D786E" />
              <p>{t('profile.date_joined')}</p>
            </div>
            <p>{dayjs(user.date_joined).format('MMM D, YYYY')}</p>
          </div>
          <hr />

          <div className="profile-items-container">
            <div className="profile-item">
              <Icon name="date" size={18} color="#6D786E" />
              <p>{t('profile.last_login')}</p>
            </div>
            <p>{dayjs(user.last_login).format('MMM D, YYYY')}</p>
          </div>
          <hr />
          {subscription && (
            <div className="profile-items-container">
              <div className="profile-item sub-grid-start">
                <Icon name="subscription" size={18} color="#6D786E" />
                <div className="subscription-invitation">
                  {' '}
                  <p>{t('profile.subscription')}</p>{' '}
                  <button onClick={onClick} disabled={disabled}>
                    {t('profile.resend_invitation')}
                  </button>
                </div>
              </div>
              {/** TODO: Pull this data from stripe */}
              <div className="subscription-status">
                <p>Noble Guided</p>
                <p style={{ fontWeight: 300 }}>Pending</p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={`profile-grid-container  ${styles}`}
        style={{
          padding: desktop === true ? '3rem' : '1rem 1.5rem',
          width: '100%',
        }}
      >
        <div
          className="profile-grid-container-body"
          style={{
            display: 'grid',
            width: '100%',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '18rem',
          }}
        >
          <div
            style={{
              display: 'grid',
              gridGap: '1.8rem',
            }}
          >
            <div className="profile-items-container">
              <div className="profile-item">
                <Icon name="user" color="#6D786E" />
                <p>{groupNames}</p>
              </div>
            </div>
            {(viewer.email === user.email ||
              viewer.groups.includes('Admin')) && (
              <div className="profile-items-container">
                <div className="profile-item">
                  <Icon name="email" color="#6D786E" size={14} />
                  <p>{user.email}</p>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridGap: '1.8rem',
            }}
          >
            {(viewer.email === user.email ||
              viewer.groups.includes('Admin')) && (
              <div className="profile-items-container">
                <div className="profile-item">
                  <Icon name="phone" size={15} color="#6D786E" />
                  <p>{user.phone_number ? user.phone_number : 'None'}</p>
                </div>
              </div>
            )}
            <div className="profile-items-container">
              <div className="profile-item">
                <Icon name="date" size={18} color="#6D786E" />
                <p>
                  {dayjs(user.date_joined).format('MMM D, YYYY')} (date joined)
                </p>
              </div>
            </div>
            <div className="profile-items-container">
              <div className="profile-item">
                <Icon name="date" size={18} color="#6D786E" />
                <p>
                  {user?.last_seen
                    ? dayjs(user?.last_seen).format('MMM D, YYYY')
                    : 'Never'}{' '}
                  (last login)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
