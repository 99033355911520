import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  RPM_GET_BILLING_BEGIN,
  RPM_GET_BILLING_SUCCESS,
  RPM_GET_BILLING_FAILURE,
  RPM_GET_BILLING_DISMISS_ERROR,
} from './constants';
import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function getBilling(args = {}) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: RPM_GET_BILLING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(`${config.apiRootUrl}/rpm/billing`, {
        params: {
          user_id: args.userId,
        },
        ...createAxiosConfigWithAuth(getState()),
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: RPM_GET_BILLING_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: RPM_GET_BILLING_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetBillingError() {
  return {
    type: RPM_GET_BILLING_DISMISS_ERROR,
  };
}

export function useGetBilling() {
  const dispatch = useDispatch();

  const { getBillingPending, getBillingError, billing } = useSelector(
    state => ({
      getBillingPending: state.rpm.getBillingPending,
      getBillingError: state.rpm.getBillingError,
      billing: state.rpm.billing,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getBilling(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetBillingError());
  }, [dispatch]);

  return {
    billing,
    getBilling: boundAction,
    getBillingPending,
    getBillingError,
    dismissGetBillingError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_GET_BILLING_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getBillingPending: true,
        getBillingError: null,
      };

    case RPM_GET_BILLING_SUCCESS:
      // The request is success
      return {
        ...state,
        billing: action.data,
        getBillingPending: false,
        getBillingError: null,
      };

    case RPM_GET_BILLING_FAILURE:
      // The request is failed
      return {
        ...state,
        getBillingPending: false,
        getBillingError: action.data.error,
      };

    case RPM_GET_BILLING_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getBillingError: null,
      };

    default:
      return state;
  }
}
