import React from 'react';
import Select from 'react-select';
import { ReactSelectStyles } from '../utils/ReactSelectStyles';
import ValueContainer from '../ValueContainer';

const CustomSelect = ({ options, ...props }) => {
  return (
    <div className={`${props.className || ''} custom-select-input-container`}>
      <div className={` support-input-container`}>
        <Select
          styles={ReactSelectStyles}
          components={{
            ValueContainer,
          }}
          options={options}
          {...props}
        />
      </div>
      {!!props.error && props.touched && (
        <div className={`error `}>{props.error}</div>
      )}
    </div>
  );
};

export default CustomSelect;
