import React, { useCallback, useEffect } from 'react'
import { useGetClients } from '../../redux/getClients'

import DesktopRecords from './DesktopRecords'
import MobileRecords from './MobileRecords'

export default function RecordsContainer({
    params,
    setClientsCount,
    setParams,
}) {
    const {
        getClients,
        clients: clientsRaw,
        getClientsPending,
    } = useGetClients()

    const [clients, setClients] = React.useState(null)

    const refetchClients = useCallback(async () => {
        setClients([])
        getClients({ ...params, page: 1 })
    }, [params])

    useEffect(() => {
        setClients([])
        setClientsCount(0)
        setParams(prevState => ({ ...prevState, page: 1 }))

        if (params.reset) {
            refetchClients()
            setParams(prevState => ({ ...prevState, reset: false }))
            return
        }
    }, [
        params.search,
        params.eligibility_status,
        params.alert_type,
        params.reset,
        params?.has_call_scheduled,
        params.ordering,
    ])

    useEffect(() => {
        if (params.page > 1) {
            getClients(params)
        }
    }, [params.page])

    useEffect(() => {
        // if page is 1, set clients
        if (params.page === 1) {
            if (clientsRaw && clientsRaw.results) {
                setClientsCount(clientsRaw.count)
                setClients(clientsRaw.results)
            }
        } else {
            // if page is not 1, append clients
            if (clientsRaw && clientsRaw.results) {
                setClients(prevState => [...prevState, ...clientsRaw.results])
            }
        }
    }, [clientsRaw])

    useEffect(() => {
        getClients({ ...params, page: 1 })
    }, [
        params.eligibility_status,
        params.alert_type,
        params.has_call_scheduled,
        params.search,
        params.ordering,
    ])

    useEffect(() => {
        if (!params.search) {
            setClients([])
            setParams(prevState => ({ ...prevState, page: 1 }))
        }

        // debounce search
        const timeout = setTimeout(() => {
            refetchClients()
        }, 500)

        return () => clearTimeout(timeout)
    }, [params.search])

    const fetchMoreUsers = () => {
        setParams(prevState => ({ ...prevState, page: prevState.page + 1 }))
    }

    return (
        <div className="rpm-provider-records-container">
            <DesktopRecords
                clients={clients}
                hasMore={(clientsRaw?.next ? true : false) && !params.search}
                refetchClients={refetchClients}
                fetchMoreUsers={fetchMoreUsers}
                setParams={setParams}
                getClientsPending={getClientsPending}
            />
            <MobileRecords
                clients={clients}
                hasMore={(clientsRaw?.next ? true : false) && !params.search}
                fetchMoreUsers={fetchMoreUsers}
                refetchClients={refetchClients}
                setParams={setParams}
                getClientsPending={getClientsPending}
            />
        </div>
    )
}
