import qs from 'qs'
import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useWindowSize } from '../../common/windowsSize'
import * as ZapierApi from '../../common/zapier'
import { useUserAgreement } from '../user/redux/userAgreement'
import InvitationForm from './components/InvitationForm'
import chat from './images/chat_image.png'
import logo from './images/logo.png'
import people from './images/people.png'
import { useConfirmResetPassword, useFetchAuthToken } from './redux/hooks'

export default function Invitation() {
  const pathname = window.location.pathname

  const [validated, setValidated] = useState(false)

  const { fetchAuthToken } = useFetchAuthToken()
  const { userAgreement } = useUserAgreement()

  const { confirmResetPassword, confirmResetPasswordError } =
    useConfirmResetPassword()
  const history = useHistory()

  const { uid, token } = useParams()
  const location = useLocation()

  const { p } = qs.parse(location.search.replace('?', ''))
  let params = {}
  try {
    params = JSON.parse(atob(p))
  } catch {}

  const email = params.email

  let name = params.user.first_name.toLowerCase()
  name = name.charAt(0).toUpperCase() + name.slice(1)

  let userIsCoach = params.user.groups.includes('Coach')

  const [invalidPassword, setInvalidPassword] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [agreement, setAgreement] = useState(false)

  const confirmReset = async (form) => {
    confirmResetPassword({
      new_password1: form.elements.formPassword.value,
      new_password2: form.elements.formConfirmPassword.value,
      uid,
      token,
    })
      .then(() => {
        fetchAuthToken({
          email: email,
          password: form.elements.formPassword.value,
        })
          .then(() => {
            if (!pathname.match('/reset-password')) {
              userAgreement({
                privacy_policy: true,
                terms_and_conditions: true,
                dac: false,
              })
            }
          })
          .then(() => {
            if (!pathname.match('/reset-password')) {
              ZapierApi.sendContactForm(
                name,
                params?.user?.last_name,
                email,
                userIsCoach ? 'bulk-invitation' : 'confirm-password'
              )
            }
          })
          .then(() => {
            setValidated(true)
            history.push('/rpm/download-app')
          })
      })
      .catch((err) => {
        let errorMessage = Object.values(err.response.data)[0][0]
        console.log(errorMessage)
        setSubmitError(
          errorMessage ||
            'Password is too weak. Please try with a harder password.'
        )

        setValidated(false)
      })
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setInvalidPassword('Please enter a valid password')
    } else {
      event.preventDefault()
      confirmReset(form)
    }
  }

  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)

  const size = useWindowSize()

  useEffect(() => {
    if (confirmPassword !== null && password !== confirmPassword) {
      setSubmitError(false)
      const timerId = setTimeout(() => {
        if (confirmPassword.length && confirmPassword.length < 8) {
          setInvalidPassword('Password must be at least 8 characters long')
        } else if (password !== confirmPassword) {
          setInvalidPassword('Passwords do not match')
        }
        if (confirmPassword?.length < 8 && password !== confirmPassword) {
          setInvalidPassword('Passwords do not match')
        }
      }, 100)
      return () => {
        clearTimeout(timerId)
      }
    } else setInvalidPassword(false)
  }, [confirmResetPasswordError, password, confirmPassword])

  return (
    <div className="home-signup">
      <div
        className="signup-sidebar mrm-mx-10"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          minHeight: '100vh',
          backgroundImage: size.width < 992 ? `url(${people})` : '',
        }}
      >
        <Link to="/">
          <img src={logo} className="logo" alt="company-logo" />
        </Link>
        <div className="signup_form">
          <div className="form_c">
            <h3>Welcome, {name} </h3>

            {pathname.match('/reset-password') ? (
              <p>Enter a new password for your account.</p>
            ) : (
              <p>
                We’re so glad you’re here. We’re certain that we can help you
                help others.
              </p>
            )}
            <InvitationForm
              validated={validated}
              email={email}
              handleSubmit={handleSubmit}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              invalidPassword={invalidPassword}
              submitError={submitError}
              confirmPassword={confirmPassword}
              password={password}
              creatingAnAccount={!pathname.match('/reset-password')}
              agreement={agreement}
              setAgreement={setAgreement}
            />
          </div>
        </div>
      </div>
      <div className="s-content">
        <div className="login_content">
          <img src={chat} className="illustration" alt="Two women chatting" />
          <h1>Using technology to enhance, not replace, human connection.</h1>
          <p>
            Your clients will gain access to automated Roadmaps, assessments,
            and in-app messaging - tools that will help you and your clients
            gain a better understanding of their progress between sessions, so
            you can tailor your one-on-one sessions to their needs more
            effectively.
          </p>
        </div>
      </div>
    </div>
  )
}

Invitation.propTypes = {}
Invitation.defaultProps = {}
