import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  RPM_TRACK_CALL_EVENT_BEGIN,
  RPM_TRACK_CALL_EVENT_SUCCESS,
  RPM_TRACK_CALL_EVENT_FAILURE,
  RPM_TRACK_CALL_EVENT_DISMISS_ERROR,
} from './constants'
import axios from 'axios'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function trackCallEvent(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: RPM_TRACK_CALL_EVENT_BEGIN,
    })

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.post(
        config.apiRootUrl + '/upcoming-calls/track-event',
        {
          call_id: args.callId,
        },
        createAxiosConfigWithAuth(getState())
      )

      doRequest.then(
        res => {
          dispatch({
            type: RPM_TRACK_CALL_EVENT_SUCCESS,
            data: res,
          })
          resolve(res)
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        err => {
          dispatch({
            type: RPM_TRACK_CALL_EVENT_FAILURE,
            data: { error: err },
          })
          reject(err)
        }
      )
    })

    return promise
  }
}

export function dismissTrackCallEventError() {
  return {
    type: RPM_TRACK_CALL_EVENT_DISMISS_ERROR,
  }
}

export function useTrackCallEvent() {
  const dispatch = useDispatch()

  const { trackCallEventPending, trackCallEventError } = useSelector(
    state => ({
      trackCallEventPending: state.rpm.trackCallEventPending,
      trackCallEventError: state.rpm.trackCallEventError,
    }),
    shallowEqual
  )

  const boundAction = useCallback(
    (...args) => {
      return dispatch(trackCallEvent(...args))
    },
    [dispatch]
  )

  const boundDismissError = useCallback(() => {
    return dispatch(dismissTrackCallEventError())
  }, [dispatch])

  return {
    trackCallEvent: boundAction,
    trackCallEventPending,
    trackCallEventError,
    dismissTrackCallEventError: boundDismissError,
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case RPM_TRACK_CALL_EVENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        trackCallEventPending: true,
        trackCallEventError: null,
      }

    case RPM_TRACK_CALL_EVENT_SUCCESS:
      // The request is success
      return {
        ...state,
        trackCallEventPending: false,
        trackCallEventError: null,
      }

    case RPM_TRACK_CALL_EVENT_FAILURE:
      // The request is failed
      return {
        ...state,
        trackCallEventPending: false,
        trackCallEventError: action.data.error,
      }

    case RPM_TRACK_CALL_EVENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        trackCallEventError: null,
      }

    default:
      return state
  }
}
