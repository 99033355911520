import React from 'react';
// import Svg, { Path } from 'react-native-svg';
// import { colors } from '../../../theme';

const SettingsIcon = ({
  size = 22,
  color = '#343434',
  style = {},
  active = false,
}) => {
  if (active) {
    return (
      <svg
        style={{ ...style }}
        width={size}
        height={size}
        viewBox="0 0 66 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.81633 13.7372H8.12245V18.1364C8.12245 18.8958 8.41917 19.6242 8.94733 20.1613C9.4755 20.6983 10.1918 21 10.9388 21C11.6857 21 12.4021 20.6983 12.9302 20.1613C13.4584 19.6242 13.7551 18.8958 13.7551 18.1364V13.9862H63.1837C63.9306 13.9862 64.647 13.6845 65.1751 13.1474C65.7033 12.6104 66 11.882 66 11.1225C66 10.363 65.7033 9.63467 65.1751 9.09763C64.647 8.5606 63.9306 8.25889 63.1837 8.25889H13.7551V2.86364C13.7551 2.10415 13.4584 1.37578 12.9302 0.83874C12.4021 0.301704 11.6857 0 10.9388 0C10.1918 0 9.4755 0.301704 8.94733 0.83874C8.41917 1.37578 8.12245 2.10415 8.12245 2.86364V8.00988H2.81633C2.06939 8.00988 1.35305 8.31158 0.824883 8.84862C0.296719 9.38566 0 10.114 0 10.8735C0 11.633 0.296719 12.3614 0.824883 12.8984C1.35305 13.4354 2.06939 13.7372 2.81633 13.7372Z"
          fill={color}
        />
        <path
          d="M63.1837 31.1856H36.1225V26.7545C36.1225 26.024 35.8257 25.3233 35.2976 24.8068C34.7694 24.2902 34.0531 24 33.3061 24C32.5592 24 31.8428 24.2902 31.3147 24.8068C30.7865 25.3233 30.4898 26.024 30.4898 26.7545V31.1856H2.81633C2.06939 31.1856 1.35305 31.4758 0.824883 31.9924C0.296719 32.509 0 33.2096 0 33.9401C0 34.6707 0.296719 35.3713 0.824883 35.8878C1.35305 36.4044 2.06939 36.6946 2.81633 36.6946H30.4898V41.2455C30.4898 41.976 30.7865 42.6767 31.3147 43.1932C31.8428 43.7098 32.5592 44 33.3061 44C34.0531 44 34.7694 43.7098 35.2976 43.1932C35.8257 42.6767 36.1225 41.976 36.1225 41.2455V36.6946H63.1837C63.5535 36.6946 63.9197 36.6234 64.2614 36.4849C64.6031 36.3465 64.9136 36.1436 65.1751 35.8878C65.4366 35.6321 65.6441 35.3284 65.7856 34.9942C65.9272 34.66 66 34.3018 66 33.9401C66 33.5784 65.9272 33.2202 65.7856 32.886C65.6441 32.5518 65.4366 32.2482 65.1751 31.9924C64.9136 31.7366 64.6031 31.5337 64.2614 31.3953C63.9197 31.2569 63.5535 31.1856 63.1837 31.1856V31.1856Z"
          fill={color}
        />
        <path
          d="M63.1446 54.8775H59.3103V50.3363C59.2276 49.692 58.8977 49.0986 58.3829 48.6681C57.8681 48.2377 57.204 48 56.5161 48C55.8282 48 55.1641 48.2377 54.6493 48.6681C54.1344 49.0986 53.8045 49.692 53.7219 50.3363V54.7235H2.81459C2.06811 54.7235 1.35221 55.0033 0.824373 55.5013C0.296536 55.9993 0 56.6747 0 57.3789C0 58.0832 0.296536 58.7586 0.824373 59.2566C1.35221 59.7546 2.06811 60.0344 2.81459 60.0344H53.7219V64.3446C53.7219 65.0488 54.0184 65.7243 54.5463 66.2222C55.0741 66.7202 55.79 67 56.5365 67C57.2829 67 57.9988 66.7202 58.5267 66.2222C59.0545 65.7243 59.351 65.0488 59.351 64.3446V60.1883H63.1854C63.555 60.1883 63.921 60.1196 64.2625 59.9862C64.604 59.8527 64.9143 59.6571 65.1756 59.4105C65.437 59.164 65.6443 58.8712 65.7858 58.5491C65.9272 58.2269 66 57.8816 66 57.5329C66 57.1842 65.9272 56.8389 65.7858 56.5167C65.6443 56.1945 65.437 55.9018 65.1756 55.6552C64.9143 55.4086 64.604 55.213 64.2625 55.0796C63.921 54.9461 63.555 54.8775 63.1854 54.8775H63.1446Z"
          fill={color}
        />
      </svg>
    );
  } else {
    return (
      <svg
        style={{ ...style }}
        width={size}
        height={size}
        viewBox="0 0 66 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.81633 13.7372H27.7143V18.1364C27.7143 18.8958 28.011 19.6242 28.5392 20.1613C29.0673 20.6983 29.7837 21 30.5306 21C31.2776 21 31.9939 20.6983 32.5221 20.1613C33.0502 19.6242 33.3469 18.8958 33.3469 18.1364V13.9862H63.1837C63.9306 13.9862 64.647 13.6845 65.1751 13.1474C65.7033 12.6104 66 11.882 66 11.1225C66 10.363 65.7033 9.63467 65.1751 9.09763C64.647 8.5606 63.9306 8.25889 63.1837 8.25889H33.3469V2.86364C33.3469 2.10415 33.0502 1.37578 32.5221 0.83874C31.9939 0.301704 31.2776 0 30.5306 0C29.7837 0 29.0673 0.301704 28.5392 0.83874C28.011 1.37578 27.7143 2.10415 27.7143 2.86364V8.00988H2.81633C2.06939 8.00988 1.35305 8.31158 0.824883 8.84862C0.296719 9.38566 0 10.114 0 10.8735C0 11.633 0.296719 12.3614 0.824883 12.8984C1.35305 13.4354 2.06939 13.7372 2.81633 13.7372Z"
          fill={color}
        />
        <path
          d="M63.1837 31.1856H56.449V26.7545C56.449 26.024 56.1523 25.3233 55.6241 24.8068C55.0959 24.2902 54.3796 24 53.6327 24C52.8857 24 52.1694 24.2902 51.6412 24.8068C51.113 25.3233 50.8163 26.024 50.8163 26.7545V31.1856H2.81633C2.06939 31.1856 1.35305 31.4758 0.824883 31.9924C0.296719 32.509 0 33.2096 0 33.9401C0 34.6707 0.296719 35.3713 0.824883 35.8878C1.35305 36.4044 2.06939 36.6946 2.81633 36.6946H50.8163V41.2455C50.8163 41.976 51.113 42.6767 51.6412 43.1932C52.1694 43.7098 52.8857 44 53.6327 44C54.3796 44 55.0959 43.7098 55.6241 43.1932C56.1523 42.6767 56.449 41.976 56.449 41.2455V36.6946H63.1837C63.5535 36.6946 63.9197 36.6234 64.2614 36.4849C64.6031 36.3465 64.9136 36.1436 65.1751 35.8878C65.4366 35.6321 65.6441 35.3284 65.7856 34.9942C65.9272 34.66 66 34.3018 66 33.9401C66 33.5784 65.9272 33.2202 65.7856 32.886C65.6441 32.5518 65.4366 32.2482 65.1751 31.9924C64.9136 31.7366 64.6031 31.5337 64.2614 31.3953C63.9197 31.2569 63.5535 31.1856 63.1837 31.1856V31.1856Z"
          fill={color}
        />
        <path
          d="M63.1837 54.4502H13.6327V49.749C13.6327 49.0199 13.3359 48.3207 12.8078 47.8052C12.2796 47.2896 11.5633 47 10.8163 47C10.0694 47 9.35305 47.2896 8.82488 47.8052C8.29672 48.3207 8 49.0199 8 49.749V54.2908H2.81633C2.06939 54.2908 1.35305 54.5805 0.824883 55.096C0.296719 55.6115 0 56.3108 0 57.0398C0 57.7689 0.296719 58.4681 0.824883 58.9837C1.35305 59.4992 2.06939 59.7888 2.81633 59.7888H8V64.251C8 64.9801 8.29672 65.6793 8.82488 66.1948C9.35305 66.7104 10.0694 67 10.8163 67C11.5633 67 12.2796 66.7104 12.8078 66.1948C13.3359 65.6793 13.6327 64.9801 13.6327 64.251V59.9482H63.1837C63.5535 59.9482 63.9197 59.8771 64.2614 59.739C64.6031 59.6008 64.9136 59.3983 65.1751 59.143C65.4366 58.8878 65.6441 58.5847 65.7856 58.2512C65.9272 57.9177 66 57.5602 66 57.1992C66 56.8382 65.9272 56.4807 65.7856 56.1472C65.6441 55.8137 65.4366 55.5106 65.1751 55.2554C64.9136 55.0001 64.6031 54.7976 64.2614 54.6595C63.9197 54.5213 63.5535 54.4502 63.1837 54.4502V54.4502Z"
          fill={color}
        />
      </svg>
    );
  }
};

export default SettingsIcon;
