import { Formik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { useFetchUser } from '../../../../user/redux/fetchUser'
import clipboard from '../../../assets/signup/clipboard.svg'
import { useCreateProfileAddress } from '../../../redux/createProfileAddress'
import { contactInformationSchema } from '../validationSchemas'

export default function MobileContactInformation({ setStep }) {
    const { user, fetchUser } = useFetchUser()

    useEffect(() => {
        fetchUser()
    }, [])

    const [method, setMethod] = useState('POST')
    const [editing, setEditing] = useState(true)

    useEffect(() => {
        if (user && user.full_address) {
            setMethod('PUT')
            setEditing(false)
        }
    }, [user])

    const { createProfileAddress } = useCreateProfileAddress()

    return (
        <div className="content center">
            <img src={clipboard} alt="RPM Logo" id="rpm-logo" />
            <h1 className="title">Add your contact information </h1>

            <Formik
                initialValues={{
                    address_1: '',
                    address_2: '',
                    state: '',
                    city: '',
                    zip: '',
                }}
                validationSchema={contactInformationSchema}
                onSubmit={values => {
                    console.log('here')
                    createProfileAddress(values, method).then(() => {
                        window.location.href = '/rpm/download-app'
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form"
                        style={{
                            height: '70vh',
                        }}
                    >
                        {!editing ? (
                            <div className="address-container">
                                <p className="address-title">Address</p>
                                <p className="address">
                                    {user.full_address.address_line_1}
                                    <br />
                                    {user.full_address.address_line_2}
                                    <br />
                                    {user.full_address.city},{' '}
                                    {user.full_address.state}{' '}
                                    {user.full_address.zip_code}
                                </p>
                                <button
                                    onClick={() => {
                                        setFieldValue(
                                            'address_1',
                                            user.full_address.address_line_1 ||
                                                ''
                                        )
                                        setFieldValue(
                                            'address_2',
                                            user.full_address.address_line_2 ||
                                                ''
                                        )
                                        setFieldValue(
                                            'city',
                                            user.full_address.city || ''
                                        )
                                        setFieldValue(
                                            'state',
                                            user.full_address.state || ''
                                        )
                                        setFieldValue(
                                            'zip',
                                            user.full_address.zip_code || ''
                                        )
                                        setEditing(true)
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        ) : (
                            <Fragment>
                                <div className="inputs-wrapper">
                                    <p className="form-title">Contact</p>
                                    <div className="input-wrapper no-icon">
                                        <input
                                            type="text"
                                            name="address_1"
                                            placeholder="Address 1"
                                            className="simple-input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address_1}
                                        />
                                    </div>
                                    {errors.address_1 && touched.address_1 && (
                                        <p className="error-message">
                                            {errors.address_1}
                                        </p>
                                    )}

                                    <div className="input-wrapper no-icon">
                                        <input
                                            type="text"
                                            name="address_2"
                                            placeholder="Address 2 (optional)"
                                            className="simple-input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address_2}
                                        />
                                    </div>

                                    {errors.address_2 && touched.address_2 && (
                                        <p className="error-message">
                                            {errors.address_2}
                                        </p>
                                    )}

                                    <div className="input-wrapper no-icon">
                                        <input
                                            type="text"
                                            name="state"
                                            placeholder="State"
                                            className="simple-input"
                                            value={values.state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                    {errors.state && touched.state && (
                                        <p className="error-message">
                                            {errors.state}
                                        </p>
                                    )}

                                    <div className="input-wrapper no-icon">
                                        <input
                                            type="text"
                                            name="city"
                                            placeholder="City"
                                            className="simple-input"
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                    {errors.city && touched.city && (
                                        <p className="error-message">
                                            {errors.city}
                                        </p>
                                    )}

                                    <div className="input-wrapper no-icon">
                                        <input
                                            type="text"
                                            placeholder="Zip"
                                            className="simple-input"
                                            name="zip"
                                            onChange={e => {
                                                if (
                                                    e.target.value.match(
                                                        /^[0-9]*$/
                                                    ) &&
                                                    e.target.value.length < 6
                                                ) {
                                                    setFieldValue(
                                                        'zip',
                                                        e.target.value
                                                    )
                                                }
                                            }}
                                            value={values.zip}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    {errors.zip && touched.zip && (
                                        <p className="error-message">
                                            {errors.zip}
                                        </p>
                                    )}
                                </div>
                            </Fragment>
                        )}

                        <button
                            type="submit"
                            // disabled={isSubmitting}
                            onClick={() => {
                                if (editing) {
                                    handleSubmit()
                                } else {
                                    setStep(4)
                                }
                            }}
                            className="submit-button"
                        >
                            Next
                        </button>
                    </form>
                )}
            </Formik>
        </div>
    )
}
