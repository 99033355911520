import React, { useContext } from 'react';
import Icon from '../../../common/components/Icon';
import { SupportContext } from '../../Dashboard';

export default function Step({
  state = 'inactive',
  step,
  description,
  onClick,
}) {
  const stepState =
    state === 'inactive'
      ? 'inactive'
      : state === 'active'
      ? 'active'
      : state === 'done'
      ? 'done'
      : '';

  const { steps } = useContext(SupportContext);

  const activeStep = Object.keys(steps).find(
    (key) => steps[key].state === 'active',
  );

  const [isStepHovered, setIsStepHovered] = React.useState(false);

  return (
    <div
      className={`support-sidebar-body-step`}
      onClick={activeStep !== step ? onClick : null}
      style={{
        cursor: step > activeStep ? '' : 'pointer',
      }}
      onMouseEnter={() => {
        setIsStepHovered(true);
      }}
      onMouseLeave={() => {
        setIsStepHovered(false);
      }}
    >
      <div className={`step-number ${stepState}`}>
        {state === 'done' ? (
          <Icon
            name={isStepHovered ? 'pen' : 'simplecheck'}
            size={12}
            color="white"
          />
        ) : (
          <p>{step}</p>
        )}
      </div>
      <p
        className={`step-description ${stepState}`}
        style={{
          opacity: step < activeStep && isStepHovered ? 0.7 : 1,
        }}
      >
        {description}
      </p>
    </div>
  );
}
