import axios from 'axios';

export const sendContactForm = (firstName, lastName, email, type) => {
  return axios
    .post(
      type === 'new-coach'
        ? 'https://hooks.zapier.com/hooks/catch/1629481/b90vmxk/'
        : type === 'confirm-password'
        ? 'https://hooks.zapier.com/hooks/catch/1629481/b5m9utj/'
        : type === 'checkout'
        ? 'https://hooks.zapier.com/hooks/catch/1629481/b5m9tdj/'
        : type === 'bulk-invitation'
        ? 'https://hooks.zapier.com/hooks/catch/1629481/b58kh68/'
        : '',
      {
        fname: firstName,
        lname: lastName,
        email: email,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then(function (response) {
      console.log(response);
    })

    .catch(function (error) {
      console.log(error);
    });
};
