import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN,
    NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
    NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE,
    NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import axios from 'axios'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'

export function updateNotificationsSettings(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.post(
                `${config.notificationSettingsApiHost}/notificationsettings/${args.userId}/toggle`,
                {
                    type: args.notificationType,
                    value: args.notificationValue,
                },
                {
                    ...createAxiosConfigWithAuth(getState()),
                    headers: {
                        origin: 'https://api.noble.health',
                    },
                }
            )

            doRequest.then(
                res => {
                    dispatch({
                        type: NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissUpdateNotificationsSettingsError() {
    return {
        type: NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR,
    }
}

export function useUpdateNotificationsSettings() {
    const dispatch = useDispatch()

    const {
        updateNotificationsSettingsPending,
        updateNotificationsSettingsError,
    } = useSelector(
        state => ({
            updateNotificationsSettingsPending:
                state.notifications.updateNotificationsSettingsPending,
            updateNotificationsSettingsError:
                state.notifications.updateNotificationsSettingsError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(updateNotificationsSettings(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissUpdateNotificationsSettingsError())
    }, [dispatch])

    return {
        updateNotificationsSettings: boundAction,
        updateNotificationsSettingsPending,
        updateNotificationsSettingsError,
        dismissUpdateNotificationsSettingsError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                updateNotificationsSettingsPending: true,
                updateNotificationsSettingsError: null,
            }

        case NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS:
            // The request is success
            return {
                ...state,
                updateNotificationsSettingsPending: false,
                updateNotificationsSettingsError: null,
            }

        case NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_FAILURE:
            // The request is failed
            return {
                ...state,
                updateNotificationsSettingsPending: false,
                updateNotificationsSettingsError: action.data.error,
            }

        case NOTIFICATIONS_UPDATE_NOTIFICATIONS_SETTINGS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                updateNotificationsSettingsError: null,
            }

        default:
            return state
    }
}
