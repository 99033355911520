import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  HOME_GET_ACTIVE_SUBSCRIPTION_BEGIN,
  HOME_GET_ACTIVE_SUBSCRIPTION_SUCCESS,
  HOME_GET_ACTIVE_SUBSCRIPTION_FAILURE,
  HOME_GET_ACTIVE_SUBSCRIPTION_DISMISS_ERROR,
} from './constants';
import config from '../../../common/config';
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers';
import axios from 'axios';

export function getActiveSubscription(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    dispatch({
      type: HOME_GET_ACTIVE_SUBSCRIPTION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${config.apiRootUrl}/subscriptions/get-active-subscription`,
        createAxiosConfigWithAuth(getState()),
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_GET_ACTIVE_SUBSCRIPTION_SUCCESS,
            data: res.data.data.Subscription.status,
          });
          resolve(res.data.data.Subscription.status);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: HOME_GET_ACTIVE_SUBSCRIPTION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetActiveSubscriptionError() {
  return {
    type: HOME_GET_ACTIVE_SUBSCRIPTION_DISMISS_ERROR,
  };
}

export function useGetActiveSubscription() {
  const dispatch = useDispatch();

  const { getActiveSubscriptionPending, getActiveSubscriptionError } =
    useSelector(
      (state) => ({
        getActiveSubscriptionPending: state.home.getActiveSubscriptionPending,
        getActiveSubscriptionError: state.home.getActiveSubscriptionError,
      }),
      shallowEqual,
    );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getActiveSubscription(...args));
    },
    [dispatch],
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetActiveSubscriptionError());
  }, [dispatch]);

  return {
    getActiveSubscription: boundAction,
    getActiveSubscriptionPending,
    getActiveSubscriptionError,
    dismissGetActiveSubscriptionError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_ACTIVE_SUBSCRIPTION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getActiveSubscriptionPending: true,
        getActiveSubscriptionError: null,
      };

    case HOME_GET_ACTIVE_SUBSCRIPTION_SUCCESS:
      // The request is success
      return {
        ...state,
        getActiveSubscriptionPending: false,
        getActiveSubscriptionError: null,
      };

    case HOME_GET_ACTIVE_SUBSCRIPTION_FAILURE:
      // The request is failed
      return {
        ...state,
        getActiveSubscriptionPending: false,
        getActiveSubscriptionError: action.data.error,
      };

    case HOME_GET_ACTIVE_SUBSCRIPTION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getActiveSubscriptionError: null,
      };

    default:
      return state;
  }
}
