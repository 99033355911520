import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useFetchCohorts } from '../../../dashboard/redux/fetchCohorts';
import { ReactSelectStyles } from '../../../support/components/formContainer/utils/ReactSelectStyles';

export default function AssignCohortsForm() {
  const { fetchCohorts, cohorts } = useFetchCohorts();

  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const { setFieldValue, errors, touched, values } = useFormikContext();

  useEffect(() => {
    fetchCohorts();
  }, [fetchCohorts]);

  useEffect(() => {
    if (values?.id && values.cohort?.length > 0) {
      setSelectedCohorts(
        values.cohort.map((cohort) => ({
          id: cohort.id,
          text: cohort.text,
        })),
      );
    }
  }, [values?.id]);

  const handleChange = (inputValue) => {
    if (cohorts.results.length > 0) {
      setSelectedCohorts(inputValue);
      setFieldValue(
        'cohort',
        inputValue?.reduce((acc, item) => {
          acc.push(item.id);
          return acc;
        }, []) || [],
      );
    }
  };

  if (!cohorts) return null;

  return (
    <div className="form-group">
      <Select
        isMulti
        defaultOptions
        options={cohorts.results.map((item) => ({
          id: item.id,
          text: item.name,
        }))}
        onChange={handleChange}
        name="cohort"
        styles={{
          ...ReactSelectStyles,
          valueContainer: (base) => ({
            ...ReactSelectStyles.valueContainer(base),
            paddingLeft: '1rem',
            height: 'auto',
          }),
          control: (base) => ({
            ...ReactSelectStyles.control(base),
            borderRadius: '0.8rem',
          }),
          placeholder: (base) => ({
            ...ReactSelectStyles.placeholder(base),
          }),
        }}
        components={{
          IndicatorSeparator: null,
          ClearIndicator: null,
        }}
        value={selectedCohorts?.length > 0 ? selectedCohorts : null}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.text}
        placeholder="Assign a cohort to your client"
      />
      {errors.cohort && touched.cohort && (
        <div className="error-message">{errors.cohort}</div>
      )}
    </div>
  );
}
