import React, { useContext } from 'react';
import { useWindowSize } from '../../../../common/windowsSize';
import { useTranslation } from '../../../translations/redux/setDefaultLanguage';
import { InvitationContext } from '../InvitationContext';
import Roadmap from '../Roadmap';

export default function Step2({ body = false }) {
  const {
    handleAssignRoadmap,
    selectedRoadmaps,
    handleDeleteRoadmap,
    setStep,
    allRoadmaps,
    step,
  } = useContext(InvitationContext);
  const size = useWindowSize();

  const { t } = useTranslation();

  if (step === 2) {
    if (body) {
      return (
        <>
          <div className={`add-rm-modal ${step === 2 ? '' : ' d-none'}`}>
            {allRoadmaps && (
              <>
                {selectedRoadmaps?.map((r) => (
                  <Roadmap
                    data={r}
                    key={r.id}
                    hideProgress={true}
                    onDeleteClick={handleDeleteRoadmap(r)}
                    goToRoadmapPage={false}
                    style={{
                      border:
                        size.width < 768 ? '1px solid rgba(0, 0, 0, 0.07)' : '',
                    }}
                  />
                ))}
                {allRoadmaps.map((r) => (
                  <Roadmap
                    data={r}
                    key={r.id}
                    onAssignClick={handleAssignRoadmap(r)}
                    hideProgress={true}
                    goToRoadmapPage={false}
                    style={{
                      border:
                        size.width < 768 ? '1px solid rgba(0, 0, 0, 0.07)' : '',
                    }}
                  />
                ))}
              </>
            )}
          </div>

          <div className="invitation-button-container">
            {step === 2 && (
              <button
                onClick={() => setStep(3)}
                className={`invitation-submit ${
                  size.width < 768 ? 'mrm-mt-2_5' : 'mrm-mt-1_5'
                }`}
              >
                {t('dashboard.onboarding.second_step.next')}
              </button>
            )}
          </div>
        </>
      );
    } else {
      return (
        <div style={{ padding: size.width < 768 ? '0 1rem' : '0' }}>
          <h1>{t('dashboard.onboarding.second_step.title')}</h1>
          <p className="description">
            {t('dashboard.onboarding.second_step.description')}
          </p>
          <p className="mrm-mt-1">
            {t('dashboard.onboarding.second_step.featured_roadmaps')}
          </p>
        </div>
      );
    }
  } else {
    return <></>;
  }
}
