import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
    AUTH_CREATE_USER_CHILD_INVITATION_BEGIN,
    AUTH_CREATE_USER_CHILD_INVITATION_SUCCESS,
    AUTH_CREATE_USER_CHILD_INVITATION_FAILURE,
    AUTH_CREATE_USER_CHILD_INVITATION_DISMISS_ERROR,
} from './constants'
import config from '../../../common/config'
import { createAxiosConfigWithAuth } from '../../../common/apiHelpers'
import axios from 'axios'

export function createUserChildInvitation(args = {}) {
    return (dispatch, getState) => {
        // optionally you can have getState as the second argument
        dispatch({
            type: AUTH_CREATE_USER_CHILD_INVITATION_BEGIN,
        })

        const promise = new Promise((resolve, reject) => {
            const doRequest = axios.post(
                `${config.apiRootUrl}/users/create-user-child-invitation/`,
                args,
                createAxiosConfigWithAuth(getState())
            )

            doRequest.then(
                res => {
                    dispatch({
                        type: AUTH_CREATE_USER_CHILD_INVITATION_SUCCESS,
                        data: res,
                    })
                    resolve(res)
                },
                // Use rejectHandler as the second argument so that render errors won't be caught.
                err => {
                    dispatch({
                        type: AUTH_CREATE_USER_CHILD_INVITATION_FAILURE,
                        data: { error: err },
                    })
                    reject(err)
                }
            )
        })

        return promise
    }
}

export function dismissCreateUserChildInvitationError() {
    return {
        type: AUTH_CREATE_USER_CHILD_INVITATION_DISMISS_ERROR,
    }
}

export function useCreateUserChildInvitation() {
    const dispatch = useDispatch()

    const {
        createUserChildInvitationPending,
        createUserChildInvitationError,
    } = useSelector(
        state => ({
            createUserChildInvitationPending:
                state.auth.createUserChildInvitationPending,
            createUserChildInvitationError:
                state.auth.createUserChildInvitationError,
        }),
        shallowEqual
    )

    const boundAction = useCallback(
        (...args) => {
            return dispatch(createUserChildInvitation(...args))
        },
        [dispatch]
    )

    const boundDismissError = useCallback(() => {
        return dispatch(dismissCreateUserChildInvitationError())
    }, [dispatch])

    return {
        createUserChildInvitation: boundAction,
        createUserChildInvitationPending,
        createUserChildInvitationError,
        dismissCreateUserChildInvitationError: boundDismissError,
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case AUTH_CREATE_USER_CHILD_INVITATION_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                createUserChildInvitationPending: true,
                createUserChildInvitationError: null,
            }

        case AUTH_CREATE_USER_CHILD_INVITATION_SUCCESS:
            // The request is success
            return {
                ...state,
                createUserChildInvitationPending: false,
                createUserChildInvitationError: null,
            }

        case AUTH_CREATE_USER_CHILD_INVITATION_FAILURE:
            // The request is failed
            return {
                ...state,
                createUserChildInvitationPending: false,
                createUserChildInvitationError: action.data.error,
            }

        case AUTH_CREATE_USER_CHILD_INVITATION_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                createUserChildInvitationError: null,
            }

        default:
            return state
    }
}
