import React from 'react';

export default function AttachmentIcon({
  size = 19,
  color = 'var(--primary-dark)',
}) {
  return (
    <svg
      width={size * 0.52}
      height={size}
      viewBox="0 0 10 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 0C7.24464 0 8.19821 0.400267 8.90179 1.11286C9.60536 1.82545 10 2.79209 10 3.8V13.9333C10 15.7436 9.04714 17.4159 7.5 18.3211C5.95286 19.2262 4.04714 19.2262 2.5 18.3211C0.952857 17.4159 0 15.7432 0 13.9333V1.9C0 1.67381 0.119286 1.46463 0.3125 1.35135C0.505714 1.23808 0.744286 1.23844 0.9375 1.35135C1.13071 1.46463 1.25 1.67345 1.25 1.9V13.9333C1.25 15.2908 1.96464 16.5456 3.125 17.2241C4.28536 17.9027 5.71464 17.9027 6.875 17.2241C8.03536 16.5452 8.75 15.2908 8.75 13.9333V3.8C8.75 2.89488 8.27357 2.05851 7.5 1.60613C6.72643 1.15375 5.77357 1.15375 5 1.60613C4.22643 2.05851 3.75 2.89488 3.75 3.8V13.3C3.75 13.7524 3.98821 14.1707 4.375 14.3969C4.76179 14.6231 5.23821 14.6231 5.625 14.3969C6.01179 14.1707 6.25 13.7524 6.25 13.3V5.06667C6.25 4.84048 6.36929 4.63129 6.5625 4.51802C6.75571 4.4051 6.99429 4.4051 7.1875 4.51802C7.38071 4.63129 7.5 4.84011 7.5 5.06667V13.3C7.5 14.2051 7.02357 15.0415 6.25 15.4939C5.47643 15.9462 4.52357 15.9462 3.75 15.4939C2.97643 15.0415 2.5 14.2051 2.5 13.3V3.8C2.5 2.79209 2.895 1.82581 3.59821 1.11286C4.30179 0.400267 5.25536 0 6.25 0Z"
        fill={color}
      />
    </svg>
  );
}
