import { useFetchAssignedUsers } from '../../../redux/fetchAssignedUsers';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

export const useGetAssignedUsers = () => {
  const { fetchAssignedUsers } = useFetchAssignedUsers();

  const history = useHistory();
  const type =
    history.location.pathname === '/dashboard/admin' ? 'Admin' : 'Coach';

  const [assignedUsersState, setAssignedUsersState] = useState({
    assignedUsers: [],
    loading: true,
    error: null,
    search: '',
    role: '',
    ordering: '',
    count: 0,
    pageSize: 14,
    hasMore: false,
    filters: {
      roadmaps: [],
      status: [],
    },
    page: 0,
  });

  useEffect(() => {
    getAssignedUsers();
  }, [
    assignedUsersState.search,
    assignedUsersState.role,
    assignedUsersState.filters,
    assignedUsersState.sortBy,
  ]);

  const fetchMoreUsers = async () => {
    if (assignedUsersState.count > assignedUsersState.assignedUsers.length) {
      console.log('fetching more users', assignedUsersState);
      const newData = await fetchAssignedUsers({
        role: type.toLowerCase(),
        search: assignedUsersState.search,
        ordering: assignedUsersState.sortBy,
        pageSize: assignedUsersState.pageSize,
        page: assignedUsersState.page + 1,
      });

      setAssignedUsersState((prevState) => ({
        ...prevState,
        assignedUsers: [...prevState.assignedUsers, ...newData.results],
        page: prevState.page + 1,
        count: newData.count,
        hasMore: newData.next ? true : false,
      }));
    } else {
      setAssignedUsersState((prevState) => ({
        ...prevState,
        hasMore: false,
      }));
    }
  }

  const getAssignedUsers = useCallback(async () => {
    fetchAssignedUsers({
      role: type.toLowerCase(),
      search: assignedUsersState.search,
      ordering: assignedUsersState.sortBy,
      pageSize: assignedUsersState.pageSize,
      roadmaps: assignedUsersState.filters.roadmaps,
      status: assignedUsersState.filters.status,
    })
      .then((res) => {
        setAssignedUsersState((prevState) => ({
          ...prevState,
          assignedUsers: res.results || [],
          loading: false,
          count: res.count,
          hasMore: res.next ? true : false,
        }));
      })
      .catch((err) => {
        setAssignedUsersState((prevState) => ({
          ...prevState,
          error: err,
          loading: false,
        }));
      })
      .finally(() => {
        setAssignedUsersState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  }, [
    assignedUsersState.search,
    assignedUsersState.role,
    assignedUsersState.filters,
    assignedUsersState.sortBy,
  ]);

  return {
    getAssignedUsers,
    assignedUsersState,
    setAssignedUsersState,
    fetchMoreUsers,
  };
};
